import {
  Box,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export interface IInfoParameterDetailsProps {
  onCloseModal: any;
  isOpen: any;
  Item: any;
  handleSave: any;
  grade: any;
  handleChange: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30vw",
  maxWidth: "80vw",
  // height: '80vh',
  Height: "20vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function AddGradeModal(props: IInfoParameterDetailsProps) {
  const {
    onCloseModal,
    isOpen,
    Item,
    handleSave,
    grade,
    handleChange,
  } = props;


  return (
    <div>
      <Modal open={isOpen}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Enter Material Grade for {Item?.alias}
            </Typography>
            {/* <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            /> */}
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ height: "15vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <TextField
              placeholder="Material Grade"
              name="grade"
              value={grade}
              variant="standard"
              onChange={handleChange}
              sx={{
                width: "100%",
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
            <Box sx={{mt:2,float:'right'}}>
            <LoadingButton variant="contained" onClick={()=>{
              handleSave()
              onCloseModal()
              }}>Save</LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
