// react
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import Modal from "@mui/material/Modal";

// styles
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from '@react-spring/web';
import CopyPartHeirarchyCosting from './CopyPartHeirarchyCosting';
import successIcon from "../../Assets/images/success.png"
import { Box, Divider, Typography, InputLabel } from "@mui/material";
import { useHistory } from "react-router-dom";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";

import { useBaseModal } from '../SearchPage/useBaseModal';

// interfaces and types

// lazy

// ----------------------------------------------------------------------------------


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
      display: 'none',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: 'block',
    },
  });

  return (
    <animated.div style={style} className='Tempdekho'>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));


interface CopySourceToDestModalProps {
  isOpen: boolean;
  onClose: () => void;

  sourceId?: any;
  handleAnchorClose: () => void;

}

const CopySourceToDestModal: React.FC<CopySourceToDestModalProps> = (props) => {
  const { isOpen, onClose, sourceId, handleAnchorClose} = props;
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState<any>();
  const [projectList, setProjectList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [subsystem, setSubsytem] = useState<any>();
  const [subsystemList, setSubsytemList] = useState<any>([]);
  const [expanded, setExpanded] = useState<any>([parseInt(selectedProduct)])
  const [HierarchyAllData, setHierarchyAllData] = useState(null);
  const ErrorModalFiles = useBaseModal();
  const [checkboxvalue, setcheckboxvalue] = useState<any>([]);
  const [CheckboxData, setCheckboxData] = useState<any>();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers



  // ----------------------------------------------------------------------------------
  // jsx

  const closeModal = (e: any) => {
    e?.stopPropagation();
    onClose();
  }

  const getProjectList = () => {
    API.get(
      "/xcpep/project_list_new/",
      {

        module: "Costing",

      },
      0
    )
      .then((res: any) => {

        setProjectList(res?.data)

      })
      .catch((err: any) => { });
  };


  useEffect(() => {
    getProjectList();
  }, [])

  const getProductList = (id: any) => {
    API.get(
      "/customer/compare_module/",
      {

        project: id,
        product_list: true,
        module: 2,
        path: null

      },
      0
    )
      .then((res: any) => {

        setProductList(res?.data);

      })
      .catch((err: any) => { });

  }

  const getSubsystemList = (id: any) => {
    API.get(
      "/xcpep/vault_info/",
      {

        top_vault: id,

      },
      0
    )
      .then((res: any) => {

        setSubsytemList(res?.data);

      })
      .catch((err: any) => { });

  }

  const getSubsystemHirearcy = (id: any) => {
    API.get(
      "/cost/costing_Subsytem_hirearchy/",
      {

        system_id: id,

      },
      0
    )
      .then((res: any) => {

        // console.log(res?.data?.data?.[0])
        setHierarchyAllData(res?.data?.data?.[0]);

      })
      .catch((err: any) => { });

  }

  const handleCopy = (e:any) => {
    setLoading(true);
    API.post(
      "/cost/costing_copy/",
      {
        target: checkboxvalue,
        multi_select: true,
        source: sourceId

      },
      0
    )
      .then((res: any) => {
        setLoading(false);
        console.log("done");
          Swal.fire({

          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          <br />
          <p style="font-size: 1.5rem">Copied Successfully</p>
           </div>`,


      })
        closeModal(e);
        handleAnchorClose();
      })
      .catch((err: any) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:"red">Costing already exists for this vault.please check and try again.</p>   
           </div>`,
        });
        closeModal(e);
        handleAnchorClose();
      })
  }

  const handleChangeInputSubsystem = (e: any) => {
    setSubsytem(e.target.value);
    getSubsystemHirearcy(e.target.value)
  }

  const handleChangeInput = (e: any) => {
    setSelectedProject(e.target.value);
    getProductList(e.target.value)
    setProductList([]);
    setSubsytemList([]);

  }

  const handleChangeInputProduct = (e: any) => {
    setSelectedProduct(e.target.value);
    getSubsystemList(e.target.value);

  }




  

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}

      >
        <Box sx={{ width: "60%", height: "80%", position: "absolute", top: "10%", left: "20%", backgroundColor: "#fff", borderRadius: "5px", margin: "0.5rem auto" }}>
          <Box sx={{ width: '100%', height: '90%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Copy to Other Project/Product
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={(e: any) => closeModal(e)} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ width: '100%', overflowY: 'scroll', display: "flex", height: '90%' }}>
              <Box sx={{ width: '50%', overflow: 'hidden', display: "flex", flexDirection: "column", padding: '0 1rem' }}>
                {/* @ts-ignore */}
                <FormControl sx={{ width: "100%", margin: "1rem", display: "flex" }} variant='standard' >
                  <InputLabel sx={{ color: "#00dfff", fontSize: "1rem" }} >
                    Project
                  </InputLabel>
                  <Select
                    size="small"
                    name="category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProject}
                    label="Project"
                    placeholder="Project"


                    onChange={handleChangeInput}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "12px",
                        paddingBottom: "0",

                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {projectList &&
                      projectList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "12px" }}
                            value={item?.id}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>


                </FormControl>
                <FormControl sx={{ width: "100%", margin: "1rem", display: "flex" }} variant='standard'>
                  <InputLabel sx={{ color: "#00dfff", fontSize: "1rem" }} >
                    Product
                  </InputLabel>
                  <Select
                    size="small"
                    name="category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProduct}
                    label="Product"



                    onChange={handleChangeInputProduct}
                    sx={{

                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "12px",


                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {productList &&
                      productList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "12px" }}
                            value={item?.id}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>


                </FormControl>
                <FormControl sx={{ width: "100%", margin: "1rem", display: "flex" }} variant='standard' >
                  <InputLabel sx={{ color: "#00dfff", fontSize: "1rem" }} >
                    Subsystem
                  </InputLabel>
                  <Select
                    size="small"
                    name="category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subsystem}
                    label="Subsystem"



                    onChange={handleChangeInputSubsystem}
                    sx={{

                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "12px",


                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {subsystemList &&
                      subsystemList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "12px" }}
                            value={item?.sub_system}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>


                </FormControl>

              </Box>
              <Divider sx={{
                orientation: "vertical",
                border: '1px solid',
                borderColor: "primary.light",
                margin: "0 1rem",
                height: '100%',

              }} />

              <Box sx={{ width: "50%" }}>

                {HierarchyAllData !== null ? <CopyPartHeirarchyCosting
                  HierarchyData={HierarchyAllData}
                  sourceID={sourceId}
                  checkboxvalue={checkboxvalue}
                  setcheckboxvalue={setcheckboxvalue}
                /> : <Box>Select project, product and subsystem to see this hierarchy</Box>}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", padding: "1rem", gap: "1rem", alignItems: "center" }} >
            <Typography sx={{ color: "red" }}>Maximum you can select 5 Parts*</Typography>
            <LoadingButton sx={{ justifySelf: "flex-end" }} variant="contained" loading={loading} disabled={checkboxvalue.length > 5 || checkboxvalue.length === 0} onClick={(e:any) => handleCopy(e)}>Apply</LoadingButton></Box>
        </Box>
      </Modal>
    </>
  );
};

export default CopySourceToDestModal;
