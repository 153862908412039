import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { API } from "../../api-services";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Skeleton, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";

interface GraphShowModalProps {
  Open: any;
  Close: any;
  id: any;
  analysisType: any
}

const GraphShowModal = (props: GraphShowModalProps) => {
  const {
    Open,
    Close,
    id,
    analysisType
  } = props;

  const [graphData, setGraphData] = useState<any>();
  const [graphDataTime, setGraphDataTime] = useState<any>();
  const [graphDataLoader, setGraphDataLoader] = useState<boolean>(true);
  const { height, width } = useWindowDimensions();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: analysisType == "moduleAnalysis" ? '53vw' : '43vw',
    width:'53vw',
    height:'48vh',
    // height: '48vh',
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    // paddingBottom: '1rem'
  };

  useEffect(() => {
    setGraphDataLoader(true);
    if (analysisType == 'loginAnalysis') {
      API.get(
        `/customer/activity_log_status/`, { graph: true, day_type: id }, 0)
        .then((res: any) => {
          setGraphDataLoader(false);
          setGraphData(res?.data);
        })
    }
    else if (analysisType == 'timeAnalysis') {
      API.get(
        `/customer/activity_log_session/`, { graph: true, day_type: id }, 0)
        .then((res: any) => {
          setGraphDataLoader(false);
          setGraphData([res?.data[0]]);
          setGraphDataTime([res?.data[1]])
        })
    }
    else if (analysisType == 'moduleAnalysis') {
      API.get(
        `/customer/module_activity_status/`, { graph: true, day_type: id }, 0)
        .then((res: any) => {
          setGraphDataLoader(false);
          setGraphData(res?.data);
        })
    }
  }, [analysisType, id])

  return (
    <Modal
      open={Open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem',
              marginTop: '0.5rem'
            }}
          >
            {analysisType == "loginAnalysis" ? `Logins & Activity (${id == '1' ? 'Today' : id}Days)` : analysisType == `timeAnalysis (${id == '1' ? 'Today' : id}Days)` ? `Time spent (${id == '1' ? 'Today' : id}Days)` : `Module activity (${id == '1' ? 'Today' : id}Days)`}
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={Close} />
        </Box>
        {!graphDataLoader ?
          <Box sx={analysisType == "timeAnalysis" ? { display: 'grid', gridTemplateColumns: '1fr 1fr', margin: '0 auto', width: '53vw', height: '48vh',padding:'1rem 1rem' } : { margin: '0 auto', width: '53vw',height:'48vh',padding:'1rem 1rem' }}>
            <ResponsiveContainer>
              <BarChart
                // width={analysisType == "moduleAnalysis" ? width > 1400 ? 940 : 660 : analysisType == "timeAnalysis" ? width > 1400 ? 365 : 205 : width > 1400 ? 730 : 500}
                // height={width > 1400 ? 420 : 270}
                data={graphData && graphData}
                margin={{
                  top: 5, left: 5, right: 5, bottom: 2
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar barSize={100} dataKey="Internal" fill="#007fff" />
                <Bar barSize={100} dataKey="External" fill="#a3c182" />
              </BarChart>
            </ResponsiveContainer>
            {graphDataTime &&
              <ResponsiveContainer>
                <BarChart data={graphDataTime && graphDataTime}
                 margin={{
                  top: 5, left: 5, right: 5, bottom: 2
                }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar barSize={100} dataKey="Internal" fill="#007fff" />
                  <Bar barSize={100} dataKey="External" fill="#a3c182" />
                </BarChart>
              </ResponsiveContainer>}
          </Box> :
          <Box sx={{ margin: '0 auto', width: '100%', height: '100%', padding: '1rem 2rem 0' }}>
            <Skeleton variant="rectangular" height='85%' />
          </Box>
        }
      </Box>
    </Modal>
  );
};

export default GraphShowModal;
