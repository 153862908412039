
import { IconButton, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function SwitchInput({ label, setChecked, checked }: any) {

  const handleChange = (event: any) => {
    setChecked(!checked);

  };
  return (
    <>
      <Tooltip title="List View">
        <IconButton onClick={handleChange} sx={{padding:'3px'}}>
          <FormatListBulletedIcon
            sx={{ color: checked ? "#007FFF" : "grey", cursor: "pointer" }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default SwitchInput