import { useHistory, useParams } from "react-router-dom";
// import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
// import Typography from "@material-ui/core/Typography";
// import Typography from 'material-ui/styles/typography';
import React, { useEffect, useState } from "react";
// import styles from "./ViewComponents.module.scss";
import { makeStyles } from "@mui/styles";
import { API } from "../api-services";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ReplyIcon from "@mui/icons-material/Reply";
import styles from "./FeatureModule.module.scss";
import { Table, TableRow, TableCell, Skeleton } from "@mui/material";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#1976d2 !important",
    marginTop: "1% !important",
    color: " white !important",
    height: "1rem !important",
  },
  details: {
    padding: " 0px !important",
  },
  imageContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: " space-around",
    gap: "0.5rem",
    padding: "0.2rem",
    height: "60vh",
    overflowY: "scroll",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ minHeight: "35px" }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "12px" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme?.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme?.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme?.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export interface FeatureModuleProps { }
export default function FeatureModule(props: FeatureModuleProps) {
  // @ts-ignoreTopLevel
  const { projectId, topVault, vault, treeabbr } = useParams<any>();

  const [GroupName, setGroupName] = useState<any>();
  const [GroupData, setGroupData] = useState<any>();
  const [ActionType, setActionType] = useState<any>();
  const [GroupId, setGroupId] = useState<any>();
  const [FeatureTopVault, setFeatureTopVault] = useState<any>();
  const [FeatureData, setFeatureData] = useState<any>();
  const [Loader, setLoader] = useState<any>(false);

  const history = useHistory();

  const toggleTopvault = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const GroupIdTopVault = (groupID: any) => {
    if (groupID == null) {
      return { group_id: "null" };
    } else {
      return { group_id: groupID };
    }
  };





  const GroupInformation =
    (groupID: any, type: any) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {


        if (type == "feature") {
          setGroupId(newExpanded ? groupID : false);
          API.get("customer/view_data/", {
            feature_type: 0,
            ...GroupIdTopVault(groupID),
            ...toggleTopvault(),
          }).then((res: any) => {
            setGroupData(res?.data);
          });
        } else if (type == "Specification") {
          setGroupId(newExpanded ? groupID : false);
          API.get("customer/view_data/", {
            feature_type: 1,
            ...GroupIdTopVault(groupID),
            ...toggleTopvault(),
          }).then((res: any) => {
            setGroupData(res?.data);
          });
        } else if (type == "Information") {
          setGroupId(newExpanded ? groupID : false);
          API.get("customer/view_data/", {
            feature_type: 2,
            ...GroupIdTopVault(groupID),
            ...toggleTopvault(),
          }).then((res: any) => {
            setGroupData(res?.data);
          });
        }
      };

  const handleChange =
    (type: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (type == "feature") {
        setGroupId(false);

        setActionType(newExpanded ? type : false);
        API.get("customer/view_data/", {
          feature_type: 0,
          ...toggleTopvault(),
        }).then((res: any) => {

          setGroupName(res?.data);
        });
      } else if (type == "Specification") {
        setGroupId(false);
        setActionType(newExpanded ? type : false);
        API.get("customer/view_data/", {
          feature_type: 1,
          ...toggleTopvault(),
        }).then((res: any) => {

          setGroupName(res?.data);
        });
      } else if (type == "Information") {
        setGroupId(false);
        setActionType(newExpanded ? type : false);
        API.get("customer/view_data/", {
          feature_type: 2,
          ...toggleTopvault(),
        }).then((res: any) => {

          setGroupName(res?.data);
        });
      }
    };

  useEffect(() => {
    setGroupId([]);
    setActionType(false);
  }, [topVault, vault]);

  useEffect(() => {
    setLoader(true);
    API.get("/customer/feature_view_data/", {
      project: projectId,
      analysis_type: 19,
      subsystem_list: true,
    })
      .then((res: any) => {
        setFeatureTopVault(res.data?.allowed_top_vault);
        setFeatureData(res?.data?.sub_system);
        setLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [projectId]);

  const topVaultKey: any =
    FeatureTopVault &&
    FeatureTopVault?.map((tvault: any) => {
      return tvault?.id;
    });
  const topVaults: any = topVaultKey?.slice(0, 5);
  const FeatureVault: any =
    FeatureData &&
    FeatureData.filter((item: any, ind: any) => {
      return item.abbreviation == treeabbr;
    });

  var DataFilterFeatureId =
    FeatureVault && FeatureVault[0]?.abbreviation == "product"
      ? FeatureVault && FeatureVault[0]?.top_vault_id
      : FeatureVault && FeatureVault[0]?.vault_id;
  var FilterFeatureId = FeatureVault && FeatureVault[0]?.id;





  const redireactFeature = () => {
    sessionStorage.setItem("feature",window.location.href);

    API.get("/analytics/fetch_features/",{sub_system:DataFilterFeatureId, top_vault:treeabbr=="product"? true:false, new_ui: true,get_first_feature:true,category:0}).then((res:any)=>{

       history.push(
       `/featurelandingPage/${projectId}/${topVaults?.toString()}/${DataFilterFeatureId}/${treeabbr}/0/${FilterFeatureId}/${res?.data?.id}/${res?.data?.feature_name}`
     );
    
    })
   
  };

  const FeatureRoute = (analysisId: any, groupID: any, groupName: any, FeatureType: any) => {
    sessionStorage.setItem("feature",window.location.href);
    history.push(
      `/featurelandingPage/${projectId}/${topVaults?.toString()}/${DataFilterFeatureId}/${treeabbr}/${FeatureType}/${analysisId}/${groupID}/${groupName}`
    );
    // groupItem?.analysis_id,groupItem?.id,groupItem?.name

  }

  return (
   
    <div>
      {/* <div className={styles.header}>Feature</div> */}
      <div className={styles.header}>
        <Typography style={{ fontSize: '12px', lineHeight: '1' }}>Feature / Specification / Information</Typography>
        <span className={styles.pageView}><ReplyIcon style={{cursor:'pointer', transform: 'scaleX(-1)' }} onClick={() => redireactFeature()} titleAccess="Go to Feature"/></span>
      </div>
      <div>
    {Loader ? (
      // <SubBouncingLoader />
      <div>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      </div>
    ) : (<>
      <Accordion
        className=""
        expanded={ActionType === "feature"}
        // onChange={handleChange(1)}
        onChange={handleChange("feature")}
      >
        <AccordionSummary
          sx={{ minHeight: "35px" }}
          // expandIcon="nonde"
          aria-controls="panel1a-content"
          id="panel1a-header"

        // className={classes.root}
        >
          <Typography style={{ fontSize: "12px", marginLeft: '8px' }}>Features</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={styles.accordDetails}
          // sx={{ height: "58.6rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
          sx={{paddingLeft:'3%'}}
        >
          {GroupName &&
            GroupName.map((groupItem: any, index: any) => {
              return (
                <Box sx={{paddingLeft:'3%'}}>
                  <Accordion
                    className=""
                    onChange={GroupInformation(groupItem?.grup_id, "feature")}
                    expanded={groupItem?.grup_id === GroupId}
                  // onChange={handleChange(1)}
                  >
                    <AccordionSummary
                      sx={{ minHeight: "35px" }}
                      // expandIcon="nonde"
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    // className={classes.root}
                    >
                      {groupItem?.grup_id == null ? (
                        <Typography
                          style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {"Uncategorized"}
                        </Typography>
                      ) : (
                        <Typography
                            style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {groupItem?.grup_name}
                        </Typography>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                      className={styles.accordDetails}
                      
                    sx={{ ".MuiAccordionDetails-root":{
                      border:'none',
                    } }}
                    >
                      {/* className={classes.details} */}
                      <div
                        style={{ marginBottom: "0.5rem",    paddingLeft: '3%' }}
                      // className={classes.imageContainer}
                      >
                        <Box
                          className={styles.featureName}
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                            cursor: "pointer"
                          }}

                        >
                          <Table>
                            {GroupData &&
                              GroupData.map((groupItem: any, index: any) => {
                                return (
                                  <>
                                    <TableRow className={styles.tableRowlink}  onClick={() => { FeatureRoute(groupItem?.analysis_id, groupItem?.id, groupItem?.name, 0) }}>
                                      <TableCell className={styles.tableCell}>{groupItem?.name}</TableCell>
                                      <TableCell className={styles.tableCell}>{groupItem?.feature_cost}</TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </Table>

                        </Box>

                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {/* <h1>{groupItem?.grup_name}</h1> */}
                </Box>
              );
            })}
          {/* className={classes.details} */}
          <div
          // className={classes.imageContainer}
          >
            {/* {MediaData &&
                  MediaData.map((medialItem: any, index: any) => {
                    return (
                      <>
                        <img src={medialItem?.original} className={styles.imagePostTD} />
                      </>
                    );
                  })} */}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className=""
        expanded={ActionType === "Specification"}
        // expanded={expanded === 1}
        // onChange={handleChange(1)}
        onChange={handleChange("Specification")}
      >
        <AccordionSummary
          sx={{ minHeight: "35px" }}
          // expandIcon="nonde"
          aria-controls="panel1a-content"
          id="panel1a-header"
        // className={classes.root}
        >
          <Typography style={{ fontSize: "12px", marginLeft: '8px' }}>Specification</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={styles.accordDetails}
          // sx={{ height: "58.6rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
          
        >
          {GroupName &&
            GroupName.map((groupItem: any, index: any) => {
              return (
                <Box sx={{paddingLeft:'3%'}}>
                  <Accordion
                    className=""
                    onChange={GroupInformation(
                      groupItem?.grup_id,
                      "Specification"
                    )}
                    expanded={groupItem?.grup_id === GroupId}
                  // expanded={expanded === 1}
                  // onChange={handleChange(1)}
                  >
                    <AccordionSummary
                      // expandIcon="nonde"
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                    // className={classes.root}
                    >
                      {groupItem?.grup_id == null ? (
                        <Typography
                          style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {"Uncategorized"}
                        </Typography>
                      ) : (
                        <Typography
                            style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {groupItem?.grup_name}
                        </Typography>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                      className={styles.accordDetails}
                      sx={{ ".MuiAccordionDetails-root":{
                        border:'none',
                      } }}
                    >
                      {/* className={classes.details} */}
                      <div
                        style={{ marginBottom: "0.5rem" }}
                      // className={classes.imageContainer}
                      >

                        <Box
                          className={styles.featureName}
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                            cursor: "pointer"
                          }}

                        >
                          <Table>
                            {GroupData &&
                              GroupData.map((groupItem: any, index: any) => {
                                return (
                                  <>
                                    <TableRow className={styles.tableRowlink} onClick={() => { FeatureRoute(groupItem?.analysis_id, groupItem?.id, groupItem?.name, 1) }}>
                                      <TableCell className={styles.tableCell}>{groupItem?.name}</TableCell>
                                      <TableCell className={styles.tableCell}>{groupItem?.feature_cost}</TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </Table>
                        </Box>

                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {/* <h1>{groupItem?.grup_name}</h1> */}
                </Box>
              );
            })}
          {/* className={classes.details} */}
          <div
          // className={classes.imageContainer}
          >
            {/* {MediaData &&
                  MediaData.map((medialItem: any, index: any) => {
                    return (
                      <>
                        <img src={medialItem?.original} className={styles.imagePostTD} />
                      </>
                    );
                  })} */}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className=""
        expanded={ActionType === "Information"}
        onChange={handleChange("Information")}
      // expanded={expanded === 1}
      // onChange={handleChange(1)}
      >
        <AccordionSummary
          sx={{ minHeight: "35px" }}
          // expandIcon="nonde"
          aria-controls="panel1a-content"
          id="panel1a-header"
        // className={classes.root}
        >
          <Typography style={{ fontSize: "12px", marginLeft: '8px' }}>Information</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={styles.accordDetails}
          // sx={{ height: "58.6rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"          
        >
          {GroupName &&
            GroupName.map((groupItem: any, index: any) => {
              return (
                <Box sx={{paddingLeft:'3%'}}>
                  <Accordion
                    className=""
                    // expanded={expanded === 1}
                    onChange={GroupInformation(
                      groupItem?.grup_id,
                      "Information"
                    )}
                    expanded={groupItem?.grup_id === GroupId}
                  //  onClick={()=>GroupInformation(groupItem?.grup_id,"Information")}
                  >
                    <AccordionSummary
                      // expandIcon="nonde"
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                    // className={classes.root}
                    >
                      {groupItem?.grup_id == null ? (
                        <Typography
                          style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {"Uncategorized"}
                        </Typography>
                      ) : (
                        <Typography
                            style={{ lineHeight: "1.2", fontSize: "12px", marginLeft: '8px' }}
                        >
                          {groupItem?.grup_name}
                        </Typography>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                      sx={{ height: "39rem",
                      ".MuiAccordionDetails-root":{
                        border:'none',
                      }
                     }}
                     
                    >
                      {/* className={classes.details} */}
                      <div style={{ marginBottom: "0.5rem" }}>

                        <Box
                          className={styles.featureName}
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                            cursor: "pointer"
                          }}
                        ><Table>
                            {GroupData &&
                              GroupData.map((groupItem: any, index: any) => {
                                return (
                                  <>
                                    <TableRow className={styles.tableRowlink}  onClick={() => { FeatureRoute(groupItem?.analysis_id, groupItem?.id, groupItem?.name, 2) }}>
                                      <TableCell className={styles.tableCell}>{groupItem?.name}</TableCell>
                                      <TableCell className={styles.tableCell}>{groupItem?.feature_cost}</TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </Table>
                        </Box>

                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {/* <h1>{groupItem?.grup_name}</h1> */}
                </Box>
              );
            })}
          {/* className={classes.details} */}
          <div
          // className={classes.imageContainer}
          >
            {/* {MediaData &&
                  MediaData.map((medialItem: any, index: any) => {
                    return (
                      <>
                        <img src={medialItem?.original} className={styles.imagePostTD} />
                      </>
                    );
                  })} */}
          </div>
        </AccordionDetails>
      </Accordion>
      </>
    )}
    </div>
    </div>
  );
}
