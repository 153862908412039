import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

interface CostingConfigSyncModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  category_id: any;
  project_id: any;
  setParentCount: any;
  setSyncCount: any;
  headingName?: any;
  type?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};

function PerformCostingModal(props: CostingConfigSyncModalProps) {
  const {
    isOpen,
    onCloseModal,
    project_id,
    category_id,
    setParentCount,
    setSyncCount,
    headingName,
    type
  } = props;
  const { categoryId } = useRouteParams<any>();
  const [data, setData] = useState<any>([]);
  const [parts, setParts] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [page, setPage] = useState<any>(1);
  const [hideClose, setHideClose] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [allCategorySelected, setAllCategorySelected] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [costLoader, setCostLoader] = useState(false);

  // const parts = [
  //   {
  //     id: 1,
  //     name: "Part A",
  //     part_no: "WGT-001",
  //     quantity: 10,
  //     weight: 1.5,
  //     drop_down: ["DC"],
  //   },
  //   {
  //     id: 2,
  //     name: "Part B",
  //     part_no: "GRB-002",
  //     quantity: 5,
  //     weight: 2.0,
  //     dcBop: ["BOP"],
  //   },
  //   {
  //       id: 3,
  //       name: "Part B",
  //       part_no: "GRB-002",
  //       quantity: 5,
  //       weight: 2.0,
  //       dcBop: ["BOP", "DC"],
  //     },

  // ];

  useEffect(() => {
    const updatedParts = data?.map((part: any) => {
      if (part?.drop_down?.length === 1) {
        return { ...part, selected: part?.drop_down?.[0] };
      }
      return part;
    });

    setParts(updatedParts); // Use separate state for modified parts
  }, [data]); // Only depends on data

  // const getPageCount = () => {
  //   API.get(
  //     `cost/perform_costing/`,
  //     {
  //       page_count: true,
  //       page: typeof page === "number" ? page : Number(page),
  //       project: type === "Project" ? project_id : null,
  //       costing_category: type === "Category" ? categoryId : null,
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       setTotalPages(res.data?.total_page);
  //       getPartsData()
  //     })
  //     .catch((err: any) => {
  //       if (err && err.response && err?.response?.data) {
  //         Swal.fire({
  //           title: "Error!",
  //           text: err?.response?.data[0],
  //           icon: "error",
  //           confirmButtonText: "Ok",
  //         });
  //       }
  //     })
  //     .finally(() => {
  //     });
  // }


  const getPartsData = () => {
    setLoading(true);
    setData([]);
    setParts([]);
    setSelectedItems([])
    API.get(
      `cost/perform_costing/`,
      {
        page: page,
        project: type === "Project" ? project_id : null,
        costing_category: type === "Category" ? categoryId : null,
      },
      0
    )
      .then((res: any) => {
        setData(res.data?.data);
        setTotalPages(res.data?.total_page);
      })
      .catch((err: any) => {
        if (err && err.response && err?.response?.data) {
          Swal.fire({
            title: "Error!",
            text: err?.response?.data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });


  }
  useEffect(() => {
    getPartsData();
    // getPageCount()
  }, [page]);

  const handleDCChange = (isChecked: boolean) => {
    setParts((prevParts: any) =>
      prevParts?.map((part: any) => {
        if (part?.drop_down?.length === 2) {
          return { ...part, selected: isChecked ? "DC" : undefined };
        } else if (part?.drop_down?.length === 1) {
          return { ...part, selected: part?.drop_down?.[0] }; // Set to the first item
        }
        return part; // Return the part unchanged if neither condition is met
      })
    );
  };

  const handleBOPChange = (isChecked: boolean) => {
    setParts((prevParts: any) =>
      prevParts?.map((part: any) => {
        if (part?.drop_down?.length === 2) {


          return { ...part, selected: isChecked ? "BOP" : undefined };
        } else if (part?.drop_down?.length === 1) {

          return { ...part, selected: part?.drop_down?.[0] }; // Set to the first item
        }
        return part; // Return the part unchanged if neither condition is met
      })
    );
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const allVaultIds = parts?.map((item: any) => {
        if (item?.selected !== undefined) {
          return item;
        }
      });
      setSelectedItems(allVaultIds);
    } else {
      setSelectedItems([]);
    }
  };

  const filteredParts = parts?.filter((part: any) => part?.drop_down?.length > 1);
  const filtereDCdParts = parts?.filter((part: any) => part?.drop_down?.length === 1 && part?.drop_down?.[0] === "DC");
  const filteredBOPParts = parts?.filter((part: any) => part?.drop_down?.length === 1 && part?.drop_down?.[0] === "BOP");

  const areAllDCChecked = filteredParts?.length > 0 ? filteredParts?.every((part: any) => part?.selected === "DC") : parts?.every((part: any) => part?.selected === "DC");
  const areAllBOPChecked = filteredParts?.length > 0 ? filteredParts?.every((part: any) => part?.selected === "BOP") : parts?.every((part: any) => part?.selected === "BOP");



  // console.log("selectedItems", selectedItems);

  // console.log(parts, "data");

  // console.log(totalPages, "totalPages");

  // console.log(loading, "loading");



  // Inside your PerformCostingModal component
  const handleDropdownChange = (itemId: number, selectedValue: string) => {
    setParts((prevData: any) =>
      prevData.map((item: any) =>
        item.id === itemId ? { ...item, selected: selectedValue } : item
      )
    );
  };

  const handleSubmit = () => {
    setHideClose(true);
    setCostLoader(true);

    // Create the object with IDs as keys and selected values as values
    const obj = selectedItems?.reduce((accumulator: any, item: any) => {
      accumulator[item?.id] = item?.selected;
      return accumulator;
    }, {} as Record<number, string | undefined>);

    //Hit api with the created object



    API.post(
      `cost/perform_costing/`,
      {
        project: type === "Project" ? project_id : null,
        costing_category: type === "Category" ? categoryId : null, vault_list: obj,
      },
      0
    )
      .then(() => {
        setCostLoader(false);
        ADMIN.toast.success("Costing created successfully");
        getPartsData();
        // getPageCount();
        setParentCount((prev: any) => prev + 1);
        // setSyncCount((prev: any) => prev + 1);
        // onCloseModal();
      })
      .catch((err) => {
        setCostLoader(false);
        console.log(err);

        //   Swal.fire({
        //     title: "Error!",
        //     text: "",
        //     icon: "error",
        //     confirmButtonText: "Ok",
        //   });
      })
      .finally(() => {
        setCostLoader(false);
        setHideClose(false)
      });
  };

  const arr = [
    {
      drop_down: ["BOP", "DC"],
      id: 107451,
      name: "Rocker Arm Bearing",
      part_no: "2420-EN-026-030",
      quantity: 2,

      weight: 10.7,
    },
    {
      drop_down: ["BOP", "DC"],
      id: 105451,
      name: "Badge Bearing",
      part_no: "2420-EN-026-030",
      quantity: 2,

      weight: 10.7,
    },
    {
      drop_down: ["BOP", "DC"],
      id: 105451,
      name: "Badge Bearing",
      part_no: "2420-EN-026-030",
      quantity: 2,

      weight: 10.7,
    },
  ];

  const checkAllDropdownSelected = () => {
    if (!parts || !Array.isArray(parts)) {
      // If parts is null, undefined, or not an array, return false
      return false;
    }
    return parts?.every((item: any) => item?.selected !== undefined);
  };


  useEffect(() => {
    const checked = checkAllDropdownSelected()
    setAllCategorySelected(checked);



  }, [parts])

  const allSingleDropdown = parts?.every((part: any) => part?.drop_down?.length === 1) || false;


  const handlePrev = () => {
    setPage((page: any) => page - 1)
  }
  const handleNext = () => {
    setPage((page: any) => page + 1)
  }

  console.log(Array.isArray(parts), parts);


  return (
    <Modal
      open={isOpen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Perform Costing
            {headingName ? " for" : ""} {headingName || ""}
          </Typography>
          <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
            {selectedItems?.length > 0 && (
              <Box sx={{ fontSize: "1.1rem" }}>
                Selected Parts: {selectedItems?.length}/{parts?.length}
              </Box>
            )}

            {hideClose === false && <CancelIcon sx={{ cursor: "pointer" }} onClick={onCloseModal} />}
          </Box>
        </Box>

        <TableContainer
          sx={{ maxHeight: { lg: "58vh", xl: "61vh" } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {loading ? <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: '1rem',
              flexDirection: "column",
              marginTop: "3rem",
              height: "10rem",
            }}
          >
            <CircularProgress size={36} />
            <Typography>Fetching parts...</Typography>
          </Box> :
            <>

              {Array.isArray(parts) && parts?.length > 0 ?
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "primary.main",
                      position: "sticky",
                      top: "0",
                      zIndex: "100",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        <Tooltip title="Select All">
                          <Checkbox
                            sx={{
                              color: "#fff",
                              "&.Mui-checked": {
                                color: "#fff",
                              },
                              "&.Mui-checked:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            //   disabled={!allCategorySelected}
                            checked={
                              selectedItems?.length === parts?.length &&
                              parts?.length > 0
                            }
                            onChange={handleSelectAllChange}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        Part Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        Part No.
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        Weight {parts?.length > 0 && parts?.[0]?.weight_unit && `(${parts?.[0]?.weight_unit})`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          color: "white",
                        }}
                      >
                        {/* {filteredParts?.length === 0 && filtereDCdParts?.length > 0 && filteredBOPParts?.length > 0 ?  */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.25rem",
                            }}
                          >
                            DC
                            <Checkbox
                              onChange={(e) => handleDCChange(e.target.checked)}
                              checked={areAllDCChecked}
                              disabled={filteredParts?.length === 0}
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                                "&.Mui-checked:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            />
                          </Box>
                          /
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.25rem",
                            }}
                          >
                            BOP
                            <Checkbox
                              onChange={(e) => handleBOPChange(e.target.checked)}
                              checked={areAllBOPChecked}
                              disabled={filteredParts?.length === 0}
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                                "&.Mui-checked:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            />
                          </Box>
                        </div>
                        {/* : "DC/BOP"} */}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {parts &&
                      parts?.map((item: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              <Checkbox
                                checked={selectedItems.some(
                                  (selectedItem: any) => selectedItem?.id === item?.id
                                )}
                                disabled={!item?.selected}
                                onChange={() => {
                                  if (
                                    selectedItems.some(
                                      (selectedItem: any) =>
                                        selectedItem?.id === item?.id
                                    )
                                  ) {
                                    setSelectedItems(
                                      selectedItems.filter(
                                        (selectedItem: any) =>
                                          selectedItem.id !== item?.id
                                      )
                                    ); // Deselect
                                  } else {
                                    setSelectedItems([...selectedItems, item]); // Select whole object
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              {item?.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              {item?.part_no}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              {item?.quantity}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              {item?.weight}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                              }}
                            >
                              {item?.drop_down && item?.drop_down?.length > 0 ? (
                                item?.drop_down?.length === 2 ? (
                                  // Dropdown for both options
                                  <FormControl fullWidth variant="standard">
                                    <InputLabel sx={{ color: "primary.main" }}>
                                      Category
                                    </InputLabel>
                                    <Select
                                      defaultValue=""
                                      value={item.selected || ""}
                                      onChange={(e) =>
                                        handleDropdownChange(item.id, e.target.value)
                                      }
                                    >
                                      {item?.drop_down?.map((option: string) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  // Disabled dropdown with the single value selected
                                  // <FormControl fullWidth disabled variant="standard">
                                  //   <InputLabel>{item?.drop_down?.[0]}</InputLabel>
                                  //   <Select defaultValue={item?.drop_down?.[0]}>
                                  //     <MenuItem value={item?.drop_down?.[0]}>
                                  //       {item?.drop_down?.[0]}
                                  //     </MenuItem>
                                  //   </Select>
                                  // </FormControl>
                                  ""
                                  // <Typography sx={{color:"grey"}}>
                                  // {item?.drop_down?.[0]}
                                  // </Typography>
                                )
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>

                </Table> : <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "3rem",
                    height: "10rem",
                  }}
                >
                  <Typography>No parts found</Typography>
                </Box>}
            </>
          }
        </TableContainer>

        {Array.isArray(parts) && parts?.length > 0 && <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Box>
            {costLoader && <>
              <Typography sx={{ fontSize: "1.1rem" }}>
                Please wait, your request is being processed...
              </Typography>
              <LinearProgress sx={{ width: "22rem", color: "red", marginTop: "0.1rem" }} />
            </>}
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Typography>Page: {page} of {totalPages}</Typography>
            <LoadingButton
              disabled={page == 1}

              variant="contained"
              color="primary"
              onClick={handlePrev}
            >
              Prev
            </LoadingButton>
            <LoadingButton
              disabled={selectedItems?.length === 0}
              loading={costLoader}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Proceed
            </LoadingButton>
            <LoadingButton
              disabled={page == totalPages}

              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Next
            </LoadingButton>
          </Box>
        </Box>}
      </Box>
    </Modal>
  );
}

export default PerformCostingModal;
