import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { API } from "../../api-services";
import { Bar, BarChart, Cell, Label, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Skeleton, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

interface AnalysisCostingTableGraphModalProps {
  Open: any;
  Close: any;
  id: any;
  graphType: any
}

const colors: any = [
    "#dd6b4d",
    "#ffce76",
    "#716f96",
    "#2b82bf",
    "#003d68",
    "#eaba51",
    "#ff9d9a",
    "#d3d3d3",
    "#a0cbe8",
    "#4e79a7",
    "#d9b364",
    "#e5cd99",
    "#c7e5e3",
    "#91cdc7",
    "#58b4ac",
    "#00688b",
    "#00bfff",
    "#ffa500",
    "#cd6600",
    "#3c0000",
    "#d22323",
    "#5a4b50",
    "#ffd2d7",
    "#1e64af",
    "#faaf96",
    "#9bb996",
    "#aae6cd",
    "#aaa5a5",
    "#782d2d",
    "#1e4b55",
    "#69785f",
    "#ffd28c",
    "#419bd2",
    "#f57382",
    "#dcebc3",
    "#b44641",
    "#91e6cd",
    "#be6e82",
    "#a5236e",
    "#f5dc50",
    "#2d969b",
    "#fa913c",
    "#ff4164",
    "#64505a",
    "#9b5a64",
    "#376ea5",
    "#c86469",
    "#5f7896",
    "#e1b496",
    "#a0a5a0",
    "#e1e6e6",
    "#6e6437",
    "#2d2d1e",
    "#00324b",
    "#e1d2af",
    "#82dce1",
    "#fff5af",
    "#cddcdc",
    "#f57ddc",
    "#ffb946",
    "#facdaf",
    "#aa96b4",
    "#191423",
    "#beaf5f",
    "#6d6d6d",
    "#3bf210",
    "#666666",
    "#cccc99",
    "#669900",];

const AnalysisCostingTableGraphModal = (props: AnalysisCostingTableGraphModalProps) => {
  const {
    Open,
    Close,
    id,
    graphType
  } = props;

  const [graphData, setGraphData] = useState<any>();
  const [graphDataLoader, setGraphDataLoader] = useState<boolean>(true);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:'95%',
    height:'80vh',
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
  };

  useEffect(() => {
    setGraphDataLoader(true);
    API.get("/cost/cost_subsystem_graph/", {
        currency_module:"Analysis",
        top_vault:id,
        graph_type:graphType=="Count"?'count':graphType=="Weight (Kg)" ? 'weight':'cost'
      }).then((res: any) => {
        setGraphDataLoader(false);
        setGraphData(res?.data)
      }).catch((err: any) => {
        setGraphDataLoader(false);
      })
  }, [graphType, id])

  const getPath = (x:any, y:any, width:any, height:any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
  };
  
  const TriangleBar = (props:any) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <Modal
      open={Open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem',
              marginTop: '0.5rem'
            }}
          >
           {graphType} Graph </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={Close} />
        </Box>
        {!graphDataLoader ?
          <Box sx={{ margin: '0 auto', width: '100%',height:'80vh',padding:'1rem 1rem' }}>
            <ResponsiveContainer>
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 200,
              }}
            >
              
              <XAxis dataKey="name" interval={0} angle={270} textAnchor="end"/>
              <YAxis>
              <Label
                style={{
                  textAnchor: "middle",
                  fontWeight: '600',
                }}
                angle={270}
                dx={-45}
                value={graphType}
              />
              </YAxis>
              <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                {graphData?.map((entry:any, index:any) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                ))}
              </Bar>
            </BarChart>
              </ResponsiveContainer>
          </Box> :
          <Box sx={{ margin: '0 auto', width: '100%', height: '100%', padding: '1rem 2rem 0' }}>
            <Skeleton variant="rectangular" height='85%' />
          </Box>
        }
      </Box>
    </Modal>
  );
};

export default AnalysisCostingTableGraphModal;
