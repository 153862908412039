import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { isNull } from 'lodash';
import { AccordionDetails, Box, styled, Typography } from '@mui/material';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';

interface SystemNameTabProps {
  SystemData: any;
  SubsystemHanlder: any
  subystemEditHanlder: any;
  deleteHanlder: any;
  ParentsCounter: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));



const FeatureNameTab = (props: SystemNameTabProps) => {
  const { categoryId, subsystemId, search, Featuretype } = useParams<any>();
  const {
    SystemData,
    SubsystemHanlder,
    subystemEditHanlder,
    deleteHanlder, ParentsCounter
  } = props
  const infoModal = useBaseModal();
  const [SystemDataList, setSystemDataList] = useState<any>(SystemData && SystemData)
  const [SelectedId, setSelectedId] = useState<any>([])
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [expanded, setExpanded] = React.useState<string | false>('0');
  const [DataList, setDataList] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    const updatedList = [
      'Feature',
      'Specification',
      'Information',
    ];
    setDataList(updatedList); // This will correctly update the state
  }, []);


  useEffect(() => {
    {
      setSystemDataList(SystemData && SystemData)
    }
  }, [SystemData, ParentsCounter]);
  useEffect(() => {
    if (subsystemId && subsystemId != null && subsystemId != 0) {
      let element: any = !isNull(subsystemId) && document.getElementById(String(subsystemId));

      let b: any = element && element.scrollIntoView(true);
    }

  }, []);

  const InfoHandler = (Id: any) => {
    infoModal?.open()
    setSelectedId(Id)
  }

  const handleChange = (panel: string, item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    const newExpandedValue = newExpanded ? panel : false;
    setExpanded(newExpandedValue);
    setSelectedId(item?.id);
    setSelectedTab(0);

    sessionStorage.setItem('storedExpanded', newExpandedValue ? String(newExpandedValue) : 'false');
    sessionStorage.setItem('storedSelectedId', `${item?.id}`); // Store the selected ID in localStorage

    history.push(`/data-base/feature-names-database/${categoryId}/${item?.id}/0/0`);
  };

  console.log("SelectedId", SelectedId);

  const DataTypeHandler = (index: any) => {
    setSelectedTab(index);
    sessionStorage.setItem('storedSelectedTab', `${index}`);
    history.push(`/data-base/feature-names-database/${categoryId}/${SelectedId}/0/${index}`);
  }

  useEffect(() => {
    const storedExpanded = sessionStorage.getItem('storedExpanded');
    const storedSelectedTab = sessionStorage.getItem('storedSelectedTab');
    const storedSelectedId = sessionStorage.getItem('storedSelectedId');

    if (storedExpanded && storedExpanded !== 'false') {
      setExpanded(storedExpanded);
    }

    if (storedSelectedTab) {
      setSelectedTab(Number(storedSelectedTab));
    }

    if (storedSelectedId) {
      setSelectedId(storedSelectedId); // Set the selected ID from localStorage
    }
  }, [])



  //http://localhost:3000/#/data-base/feature-names-database/11/237/0/0
  return (
    <>

      {/* {infoModal.isOpen &&
        <FeatureInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={SelectedId && SelectedId}
        />
      } */}
      <Box sx={{ width: "20%", height: { lg: '80vh', xl: '86vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {SystemDataList &&
          SystemDataList?.map((item: any, index: any) => {
            // console.log("item", item);
            return (
              <Accordion expanded={expanded === `${index}`} onChange={handleChange(`${index}`, item)} sx={{ width: '100%', border: 'none', }}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', padding: '0.2rem 0.5rem', }}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                    sx={{ padding: '0', minHeight: '24px', width: '100%', }}
                    expandIcon={expanded === `${index}` ? (<img src={openFolder} style={{ height: '1.2rem', }} />)
                      : (<FolderIcon sx={{ fontSize: '1.5rem', color: 'primary.main', }} />)
                    }>
                    <Box sx={{ marginLeft: '0.3rem', width: '100%', display: 'flex', alignItems: 'center', }}>
                      <Typography title={item?.commodity_name} sx={{ width: '100%', lineHeight: '1.1', color: 'text.primary' }}>
                        {item?.name} ({item?.abbreviation})
                      </Typography>
                      <Typography sx={{ padding: "0 2px", color: 'text.primary' }}>({item?.feature_count})</Typography>
                    </Box>
                  </AccordionSummary>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      columnGap: "0.5rem",
                      whiteSpace: 'nowrap',
                      alignItems: "center",
                      padding: "0 3px",
                    }}
                  >
                    {sessionStorage.getItem("DBPermission")?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      onClick={() => subystemEditHanlder(item)}
                    />}
                    {item?.mapped_status ? (
                      <InfoIcon onClick={() => InfoHandler(item?.id)} />
                    ) : (
                      sessionStorage.getItem("DBPermission")?.includes("D") && <DeleteIcon
                        titleAccess="Delete"
                        onClick={() => deleteHanlder(item?.id)}
                      />
                    )}
                  </Box> */}
                </Box>
                <AccordionDetails>
                  <Box
                    sx={{
                      marginLeft: '1.3rem',
                      marginRight: '0.5rem',
                      borderLeft: '1px solid',
                      borderColor: 'primary.main',
                    }}>
                    {DataList &&
                      DataList?.map(
                        (
                          item: any,
                          indx: any
                        ) => {
                          console.log("item tab", item, indx, selectedTab);
                          return (
                            <>
                              <Typography
                                key={indx}
                                sx={{
                                  backgroundColor:
                                    indx === selectedTab
                                      ? "primary.main"
                                      : 'white',
                                  color:
                                    indx === selectedTab
                                      ? "white"
                                      : "text.primary",
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '1rem',
                                  columnGap: '2px',
                                  cursor: 'pointer',
                                  marginBottom: '3px',
                                  padding: indx === selectedTab ? '2px 0.3rem 2px 0' : '0rem 0.3rem 0 0',
                                  // border: indx === selectedTab ? '2px solid #000' : '',
                                  borderRadius: indx === selectedTab ? '5px' : '',
                                }}
                                onClick={(e: any) => { DataTypeHandler(indx) }}
                              >
                                {' '}
                                <span style={{ whiteSpace: 'nowrap' }}>--</span>
                                <span style={{ lineHeight: '1.2', width: '85%', textTransform: 'capitalize' }}>{
                                  item
                                }</span>
                              </Typography>
                            </>
                          );
                        }
                      )}
                  </Box>
                </AccordionDetails>
              </Accordion>
              // <Box
              //   id={item?.id}
              //   key={index}
              //   sx={{ marginLeft: '0.5rem', marginRight: '0.5rem', borderLeft: '1px solid', }}
              //   // className={styles.Data_card}
              //   onClick={() => SubsystemHanlder(item?.id)}>
              //   <div
              //     className={
              //       (search == 0 || search == "0") &&
              //         subsystemId == item?.id
              //         ? styles.active_box_container
              //         : styles.box_container
              //     }>
              //     <div
              //       style={{
              //         display: "flex",   
              //         justifyContent: 'space-between',                   
              //         width: "100%",
              //         fontSize: "1rem",
              //         paddingRight: '0.5rem',
              //       }}>
              //       <span>
              //         <span>--</span>
              //         {item?.name} ({item?.abbreviation})
              //       </span>
              //       <span> ({item?.feature_count})</span>
              //     </div>
              //     <div
              //       style={{
              //         display: "flex",                      
              //         flexDirection: "column",
              //         justifyContent: 'space-between',
              //       }}>
              //       {/* {sessionStorage.getItem("DBPermission")?.includes("U") &&<EditIcon
              //         titleAccess="Edit"
              //         onClick={() => subystemEditHanlder(item)}
              //       />}
              //       {item?.mapped_status ? (
              //         <InfoIcon onClick={() => InfoHandler(item?.id)} />
              //       ) : (
              //         sessionStorage.getItem("DBPermission")?.includes("D") &&<DeleteIcon
              //           titleAccess="Delete"
              //           onClick={() => deleteHanlder(item?.id)}
              //         />
              //       )} */}
              //     </div>                 
              //   </div>
              // </Box>

            );
          })}
      </Box>
    </>
  )
}

export default FeatureNameTab;