import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { useGraphBaseModal } from "../ActivityLogs/useGraphBaseModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../SearchPage/useBaseModal";
import {ADMIN} from "../../Redux/Services/admin.service";
import { DialogComponent } from "../../utlis/DialogComponent";

const csvOptions: GridCsvExportOptions = {
  fileName: "Costing Subsystem-wise Summary In CSV",
  delimiter: ",",
  utf8WithBom: true,
  allColumns: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);
interface AnalysisCostingTableI {}
const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span
      style={{
        position: "absolute",
        right: "0rem",
        fontSize: "1rem",
        paddingRight: "1rem",
      }}
    >
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface Params {
  topVault: any;
  projectId: any;
}

const AnalysisMaterialBOMTable: React.FC<AnalysisCostingTableI> = (props) => {
  const { projectId, topVault } = useRouteParams<Params>();
  const [analysisCostData, setAnalysisCostData] = useState<any>();
  const [analysisCostDataLoader, setAnalysisCostDataLoader] =
    useState<boolean>(true);
  const [contentText, setContentText] = React.useState<any>(
    "Report download is running in background"
  );
  const [iconText, setIconText] = React.useState<any>("success");
  const openStatusContent = useBaseModal();

  let [startCombinationIndex, setstartCombinationIndex] = useState<any>(0);
  let [endCombinationIndex, setendCombinationIndex] = useState<any>(3);
  let MAX_LENGTH_COMBINATION: any = 3;

  const graphBaseModal = useGraphBaseModal();
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];

  // console.log(startCombinationIndex, endCombinationIndex,topVaultsParam?.slice(0,startCombinationIndex?.length),"endCombinationIndex",topVaultsParam?.slice(0,startCombinationIndex?.length)?.length > 3? 3 : topVaultsParam?.slice(0,endCombinationIndex?.length)?.length)

  let COMBINATION_MAP_LIST: any = topVaultsParam?.slice(
    startCombinationIndex,
    endCombinationIndex
  );

  const handleDownload = () => {
    setIconText("firstdownload");
    setContentText(
      "We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    );
    openStatusContent.open();
    API.get(
      "cost/cost_summary_download/",
      {
        top_vault: topVault?.toString(),
      },
      0
    )
      .then((res: AxiosResponse) => {
        window.open(res?.data, "_self");
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  const btnCombinationHandler = (key: any, type: string) => {
    //   type === 'prev' ? setPageNo( ( prev: number ) => prev - 1 ) : setPageNo( ( prev: number ) => prev + 1 );
    switch (key) {
      case 1:
        startCombinationIndex = endCombinationIndex;
        endCombinationIndex = startCombinationIndex + MAX_LENGTH_COMBINATION;
        setstartCombinationIndex(startCombinationIndex);
        setendCombinationIndex(endCombinationIndex);
        COMBINATION_MAP_LIST = topVaultsParam?.slice(
          startCombinationIndex,
          endCombinationIndex
        );
        setAnalysisCostDataLoader(true);
        API.get(
          `/customer/analysis_summary/`,
          {
            // top_vault:COMBINATION_MAP_LIST.toString(),
            vehicle_ids: COMBINATION_MAP_LIST.toString(),
            analysis_type: "material_distribution",
          },
          0
        ).then((res: any) => {
          setAnalysisCostDataLoader(false);
                setAnalysisCostData(res?.data);
        })
        .catch((err: any) => {
          ADMIN.toast.throwError(err, `Could not fetch parameter`);
        });
        break;
      case 2:
        endCombinationIndex = endCombinationIndex - MAX_LENGTH_COMBINATION;
        startCombinationIndex = startCombinationIndex - MAX_LENGTH_COMBINATION;
        setstartCombinationIndex(startCombinationIndex);
        setendCombinationIndex(endCombinationIndex);
        COMBINATION_MAP_LIST = topVaultsParam?.slice(
          startCombinationIndex,
          endCombinationIndex
        );
        setAnalysisCostDataLoader(true);
        API.get(
          `/customer/analysis_summary/`,
          {
            // top_vault:COMBINATION_MAP_LIST.toString(),
            vehicle_ids: COMBINATION_MAP_LIST.toString(),
            analysis_type: "material_distribution",
          },
          0
        ).then((res: any) => {
          setAnalysisCostDataLoader(false);
                setAnalysisCostData(res?.data);
        })
        .catch((err: any) => {
          ADMIN.toast.throwError(err, `Could not fetch parameter`);
        });
        break;
    }
  };

  useEffect(() => {
    setAnalysisCostDataLoader(true);
      API.get(
    `/customer/analysis_summary/`,
    { vehicle_ids: topVault && typeof topVault == "string"
    ? topVault?.split(",")?.map(Number)?.slice(0, 3)?.toString()
    : topVault?.toString(), analysis_type: "material_distribution" },
    0
  )
  .then((res: any) => {
    setAnalysisCostDataLoader(false);
          setAnalysisCostData(res?.data);
  })
  .catch((err: any) => {
    ADMIN.toast.throwError(err, `Could not fetch parameter`);
  });
    
    
  }, [topVault]);


  const GraphOpenId = (period: any, type: any) => {
    graphBaseModal.open(period, type);
  };

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push(`/analysis/${projectId}/${topVault}`);
  };

  const gridDataVal = (index: number) => {
    let columns: any;
    let rows: any = [];
    if (index == 0) {
      columns = [
        {
          field: "SNo",
          headerName: "SNo.",
          width: width > 1380 ? 60 : 50,
          // flex: 1,
          sortable: false,
        },
        {
          field: "categoryName",
          headerName: "Category Name",
          headerAlign: "left",
          minWidth: width > 1380 ? 180 : 140,
          flex: 1,
          sortable: false,
        },
        {
          field: "count",
          headerAlign: "right",
          minWidth: 80,
          flex: 1,
          type: "number",
          align: "right",
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams) => (
            <span style={{ fontWeight: "500" }}>
              {"Count"}
            </span>
          ),
        },
        
        {
          field: "contribution",
          headerName: "Contribution %",
          headerAlign: "right",
          minWidth: 80,
          flex: 1,
          align: "right",
          sortable: false,
        },
      ];

      // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

      let dataGridDataGenerator: any =
        analysisCostData &&
        analysisCostData?.map((ideaItem: any, indexInner: any) => {
          let objShared: any = {
            id: indexInner,
            SNo: indexInner + 1,
            categoryName: ideaItem?.["data_by_vehicle"]?.[index]?.name,
            count: ideaItem?.["data_by_vehicle"]?.[index]?.value,
            contribution: ideaItem?.["data_by_vehicle"]?.[index]?.contribution,
          };
          rows.push(objShared);
        });
    } else {
      columns = [
        {
          field: "categoryName",
          headerName: "Category Name",
          headerAlign: "left",
          align: "left",
          minWidth: width > 1380 ? 180 : 140,
          flex: 1,
          hide: true,
        },
        {
          field: "count",
          headerAlign: "right",
          minWidth: 80,
          flex: 1,
          type: "number",
          align: "right",
          sortable: false,
          renderHeader: (params: GridColumnHeaderParams) => (
            <span style={{ fontWeight: "500" }}>
              {"Count"}
              
            </span>
          ),
        },
        
        {
          field: "contribution",
          headerName: "Contribution %",
          headerAlign: "right",
          minWidth: 80,
          flex: 1,
          align: "right",
          sortable: false,
        },
      ];

      let dataGridDataGenerator: any =
        analysisCostData &&
        analysisCostData?.map((ideaItem: any, indexInner: any) => {
          let objShared: any = {
            id: indexInner,
            categoryName: ideaItem?.["data_by_vehicle"]?.[index]?.name,
            count: ideaItem?.["data_by_vehicle"]?.[index]?.value,
            
            contribution: ideaItem?.["data_by_vehicle"]?.[index]?.contribution,
          };
          rows.push(objShared);
        });
    }

    let dataStructureDataGrid: any = {
      columns: columns,
      rows: rows,
    };

    return (
      <div
        style={
          analysisCostData && analysisCostData[0]?.data_by_vehicle?.length > 1
            ? index == 0
              ? analysisCostData &&
                analysisCostData[0]?.data_by_vehicle?.length == 2
                ? index == 0
                  ? { height: "81vh", width: "67%" }
                  : { height: "81vh", width: "55%" }
                : { height: "81vh", width: "50%" }
              : { height: "81vh", width: "33%" }
            : { height: "81vh", width: "100%" }
        }
      >
        <div
          style={{
            // marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            fontWeight: "600",
          }}
        >
          <h3 style={{ margin: 0 }}>
            {analysisCostData[0]?.data_by_vehicle?.[index]?.top_vault_name}
          </h3>
        </div>
        <DataGrid
          headerHeight={48}
          rowHeight={36}
          {...dataStructureDataGrid}
          loading={analysisCostDataLoader}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar();
            },
          }}
          style={{ marginTop: "-2rem", marginRight: "0.5rem" }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "#045DE9 !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
            "& .even": {
              backgroundColor: "#ddefff !important",
              fontWeight: "450 !important",
            },
          }}
          density="compact"
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowClassName={"odd"}
          hideFooter={true}
          hideFooterPagination={true}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
        />
      </div>
    );
  };

  return (
    <>
      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}
      <Box sx={{ display: "flex", margin: "0 0.5rem", alignItems: "center" }}>
        <IconButton
          sx={{ cursor: "pointer", float: "left" }}
          title="Go Back"
          onClick={backButtonHandler}
        >
          <ArrowBackIcon color="primary" titleAccess="Go Back" />
        </IconButton>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Typography sx={{ fontWeight: "600" }}>
            Material Distribution Summary
          </Typography>
        </div>
        {/* <Button variant="contained" onClick={handleDownload}>
          Download
        </Button> */}
      </Box>
      {!analysisCostDataLoader ? (
        <>
          <div style={{ display: "flex", padding: "0 0.5rem" }}>
            {analysisCostData &&
              analysisCostData[0]?.data_by_vehicle?.map(
                (item: any, index: number) => {
                  return gridDataVal(index);
                }
              )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0rem 1rem",
              marginTop: "0.5rem",
            }}
          >
            {startCombinationIndex != 0 ? (
              <span
                style={{
                  cursor: "pointer",
                  background: "#007fff",
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "0.8rem",
                }}
                onClick={() => btnCombinationHandler(2, "prev")}
              >{`< Prev ${startCombinationIndex > 0 && "3"} Vehicles`}</span>
            ) : (
              <span></span>
            )}
            {topVaultsParam?.length > endCombinationIndex ? (
              <span
                style={{
                  cursor: "pointer",
                  background: "#007fff",
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "0.8rem",
                }}
                onClick={() => btnCombinationHandler(1, "next")}
              >{`Next ${
                topVaultsParam?.slice(
                  endCombinationIndex,
                  endCombinationIndex?.length
                )?.length > 3
                  ? "3 Vehicles >"
                  : topVaultsParam?.slice(
                      endCombinationIndex,
                      endCombinationIndex?.length
                    )?.length == 1
                  ? "1 Vehicle >"
                  : `${
                      topVaultsParam?.slice(
                        endCombinationIndex,
                        endCombinationIndex?.length
                      )?.length
                    } Vehicles >`
              }`}</span>
            ) : (
              <span></span>
            )}
          </div>
        </>
      ) : (
        <Box sx={{ padding: "0 1rem" }}>
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
          <Skeleton sx={{ height: "5vh", width: "100%" }} />
        </Box>
      )}
    </>
  );
};
export default AnalysisMaterialBOMTable;
