import React, { useEffect, useState } from "react";
import ProjectTitleSection from "./ProjectTitleSection";
import styles from "./Landing.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Header } from "../Header/Header";
import { Box } from "@mui/material";
import { API } from "../../api-services";



interface LandingProps { }
const Landing: React.FC<LandingProps> = () => {
  const [wallpaperData, setWallpaperData] = useState<any>();
  const [wallpaperLoader, setWallpaperLoader] = useState<boolean>();

  const [checked, setChecked] = React.useState(true);
  const { url } = useRouteMatch();
  sessionStorage.removeItem("ProjectId");
  sessionStorage.removeItem("ActiveIndex");
  sessionStorage.removeItem("SearchKey");
  sessionStorage.removeItem("projectKey");
  sessionStorage.removeItem("ProjectIds");
  sessionStorage.removeItem("ExactSearchKey");
  sessionStorage.removeItem("VoultId");
  // @ts-ignore
  sessionStorage.setItem("FreeseStatus", false);
  const history = useHistory();
  {
    sessionStorage.removeItem("recentKey");
  }


  // useEffect(()=>{
  //   url.includes("/landing") && setChecked(false)
  // },[url])

  // const switchpage = (event: React.ChangeEvent<HTMLInputElement>) => {event.target.checked==true && history.push("/internallanding")};

  useEffect(() => {
    setWallpaperLoader(false);
    API.get("customer/profile/", { landing_page_wallpaper: true },
      0).then((res: any) => {
        setWallpaperLoader(true);
        setWallpaperData(res?.data);
      });
  }, []);

  return (

    <Box className={styles.projectMainDiv}>
      <Box sx={{ backgroundImage: `url(${wallpaperData?.selected_wallpaper})`, backgroundSize: "cover", minHeight: "100%", minWidth: { xs: '364px', sm: "1024px" }  }}>
        {/* <Box sx={{ backgroundImage: `url(${wall4})`,backgroundSize:"cover",minHeight:"100%",minWidth:"1024px"}}> */}
        <Header />
        <Box sx={{ height: '1rem' }}></Box>
        {/* {AUTH.user_category == "Internal" && <Box sx={{ width: '100%', margin: '0 auto', padding: '0 2rem', display: 'flex', justifyContent: 'space-between' }}>
          <Box><Switch size="small" onChange={switchpage} checked={checked}/></Box>
          <Box></Box>
        </Box>} */}
        <Box
          className={styles.landingPage}
          adl-scrollbar="true"
          adl-scrollbar-width="0.6"
        >
          <ProjectTitleSection />
        </Box>
      </Box>
    </Box>
    // </LetSuspense>
  );
};

export default Landing;
