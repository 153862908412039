import * as React from 'react';
import { MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { UserAction } from '../../IdeaGenerationViewLanding';
import { rejectedStatus } from '../../IdeaViewpage';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';


export interface IIdeaTabSingleSelectInputBoxTableProps {
    itmm: any;
    key1: any;
    dataBody: any;
    data: any;
    val: any;
    count: any;
    setDataForUpdate: any;
    target: any;
    AllData: any;
    selectedOptions: any;
    allOptions: any;
    setColorButton: any;
    setShowButton: any;
}

export default function IdeaTabSingleSelectInputBoxTable (props: IIdeaTabSingleSelectInputBoxTableProps) {
    const {
        itmm,
        key1,
        dataBody,
        data,
        val,
        count,
        setDataForUpdate,
        target,
        AllData,
        selectedOptions,
        allOptions,
        setColorButton,
        setShowButton,
      } = props;
      const { copyStatus } = useParams<any>();
      const userAction = React.useContext<any>(UserAction);
      let UserActionParams = userAction && userAction?.action;
      const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
      const [value, setValue] = React.useState<any>("");
      const [options, setOptions] = React.useState<any>([]);
    
      React.useEffect(() => {
        if (allOptions?.length > 0) {
          setOptions(allOptions);
        }
      }, [allOptions]);
    
      React.useEffect(() => {
        if (allOptions?.length > 0) {
          setOptions(allOptions);
        }
      }, [allOptions]);
    
      React.useEffect(() => {
        if (val) {
          setValue(val);
        } else {
          setValue("");
        }
      }, [val]);
    
      const handleChange = (event: any) => {
        setShowButton(true);
        setColorButton("red");
        const { value } = event.target;
        setValue(value);
        AllData.filter((item: any, index: any) => index === target)[0][key1] = value
          ? value
          : "";
        setDataForUpdate(AllData);
      };


  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      variant="standard"
      onChange={handleChange}
      // disabled={
      //   (copyStatus == false || copyStatus == "false") &&
      //   (UserAction?.includes("U") || UserAction?.includes("C"))
      //     ? false
      //     : true
      // }
      disabled={(!rejectedStatusActionParams && UserActionParams?.includes("U"))?false:true}
      name={key1}
      value={value || ""}
      label="Calibration Required"
      sx={{
        width: "100%",
        fontSize: "1rem",
        "&:before": {
          borderBottomColor: "primary.light",
        },
        ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
      }}
    >
      {options?.map((option: any) => {
        return <MenuItem value={option}>{option}</MenuItem>;
      })}
    </Select>
  );
}
