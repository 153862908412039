import { Box, IconButton, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { API } from '../../../api-services';

export interface IRawMaterialActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	history?: any;
	tab?: any;
	year?: any;
	month?: any;
	categoryName?: any;
	status?: any;
	search?: any;
	useraction?: any;
	getMaterialData?: any;
	infoRawMaterialModal?: any;
	graphModal?: any;
	moveModal?: any;
	setAnchorEl?: any;
	typeId?:any
                      revisionId?:any
}

export default function RawMaterialActionComponents(
	props: IRawMaterialActionComponentsProps
) {
	const {
		cellValues,
		rowId,
		history,
		tab,
		year,
		month,
		categoryName,
		status,
		search,
		useraction,
		getMaterialData,
		graphModal,
		infoRawMaterialModal,
		moveModal,
		setAnchorEl,
		typeId,
                      revisionId
	} = props;


	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			{ (
				<Typography
					title='Material Usage'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main',textDecoration:'underline' }}
					onClick={() => infoRawMaterialModal.open(rowId)}>
						Usage
				</Typography>
			)}

			<IconButton
				title='Graph'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() => graphModal.open(rowId)}>
				<EqualizerIcon />
			</IconButton>

			<IconButton
				title='Redirect'
				sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
				onClick={() =>
					history.push(
						`/data-base/revision-database/${tab}/${year}/${month}/${revisionId || 1}/${typeId || 1}/rmDB/${categoryName}/${status}/${search}/0`
					)
				}>
				<LaunchIcon sx={{ cursor: 'pointer' }} />
			</IconButton>

			{rowId?.material_file_flag && (
				<IconButton
					title='Flag'
					sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
					onClick={() =>
					{
						// window.open(rowId?.material_file, '_blank', ' noopener')
						API.get("/api/db/material_cost/",{
							download_flag:true,
							id:rowId?.id
						},0).then((res:any)=>{
							window.open(res.data, '_blank', ' noopener')
						}).catch((e:any)=>{})
					}
					}>
					{' '}
					<FlagIcon sx={{ color: 'green' }} />
				</IconButton>
			)}
			{rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('U') && (
					<IconButton
						title='Move'
						sx={{ padding: '0.5rem', cursor: 'pointer', color: 'primary.main' }}
						onClick={() => {
							moveModal.open(rowId);
							setAnchorEl(null);
						}}>
						<img
							src={move}
							style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem' }}
						/>
					</IconButton>
				)}
		</Box>
	);
}
