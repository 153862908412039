import React, { useEffect, useState } from 'react'
import { API } from '../../api-services'
import {
  DataGrid,
} from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import StarIcon from "@mui/icons-material/Star";
import { IconButton, Skeleton, Typography } from '@mui/material';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface StarRatingreviewProps { }

const StarRatingreview = (props: StarRatingreviewProps) => {

  const [RatingData, setRatingData] = useState<any>()
  const [Loader, SetLoader] = useState<any>(false)
  const history = useHistory();

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push("/activity-logs/activity/all");
  };


  useEffect(() => {
    SetLoader(true)
    API.get("customer/customer_rating/", { get_rating_logs: true }).then((res: any) => {
      SetLoader(false)
      setRatingData(res?.data)
    }).catch((err: any) => {
      // console.log("Server error")
      SetLoader(false)
    })
  }, [])


  const renderStar = (starCount: any) => {

    return Array.from({ length: starCount }, (count: any, index: any) => {
      return (<>
        <StarIcon
          key={index}
          sx={{ color: "#E88D0E", cursor: "pointer", marginRight: "0.5rem", fontSize: "1.5rem" }}
        />
      </>

      )
    })
  }

  let columns: any = [


    { field: "id", hide: true },
    {
      field: "by_user", headerName: " Name",
      minWidth:300,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "usr_category", headerName: "User Type",
      minWidth:75,
     
      // minWidth: width > 1400 ? 70 : 110, 

      // flex: 1
    },
    {
      field: "customer_comment", headerName: "Comment",
      // minWidth: width > 1400 ? 70 : 110, 
      width: 200,
      flex: 1
    },

    {
      field: "module", headerName: "Module",
      // minWidth: width > 1400 ? 70 : 110, 
      minWidth:150,
      // flex: 1
    },
    {
      field: "rated_on_date", headerName: "Date",
      // minWidth: width > 1400 ? 70 : 110, 
      minWidth:70,
      // flex: 1
    },
    {
      field: "rating", headerName: "Rating",
      minWidth:200,
      // minWidth: width > 1400 ? 70 : 110, 

      renderCell: (cellValues: any) => {
        // console.log(cellValues?.row, "cellValues")

        return (
          <>
            {/* @ts-ignore */}

            {renderStar(cellValues?.row?.rating)}

          </>
        );
      },
      // flex: 1
    },

  ]






  var TableRows = () => {

    var rowdata = RatingData && RatingData?.map((item: any, index: any) => ({ ...item, id: index + 1 }))

    // console.log(rowdata, "rowdatarowdata")
    return rowdata;
  }



  const dataRender: any = {
    columns: columns,
    rows: TableRows() && TableRows(),
  };
  // console.log(TableRows() && TableRows(), "TableRowsTableRows")

  return (
    <Box sx={{ padding: '0 1rem' }}>
      {Loader ? <>
        <Box>
          <Skeleton variant='rectangular' width="100%" height='20%' />
          <SkeletonLoader />
          <SkeletonLoader />
        </Box>
      </> : <>
        <Box sx={{display:'flex',alignItems:'center',columnGap:'10px'}}>
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Typography sx={{ fontSize: '1rem' }}>User Ratings</Typography>
        </Box>
        <Box
          sx={{
            height: { sm: '90vh', md: '89vh', lg: '90vh', xl: '91vh' },
            width: "100%",
          }}
        >
          {TableRows() && TableRows() &&
            <DataGrid
              {...dataRender}
              pageSize={59}
              rowsPerPageOptions={[59]}
              density={"compact"}
            />
          }

        </Box>
      </>
      }
    </Box>
  )
}
export default StarRatingreview;