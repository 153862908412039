import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import * as SpriteSpin from "spritespin";
import { useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./BomThreeSixtyModal.module.scss";
import { API } from "../../api-services";
import "./Threesixty.scss";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";

import TagTransitionablePortal from "./TagTransitionablePortal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
export interface BomThreeSixtyModalProps {
  isOpen: any;
  onCloseModal: any;
  ThreeSixityImages?: any;
  query: any;
  ImageType: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const BomThreeSixtyModal = (props: BomThreeSixtyModalProps) => {
  const { isOpen, onCloseModal, query, ThreeSixityImages, ImageType } = props;
  const ref = useRef<any>();
  const [count, setCount] = useState<number>(0);
  const [FilterImages, setFilterImages] = useState<any>();
  const [currentFrame, setCurrentFrame] = useState(0);
  const [vistedId, setVistedId] = useState<any>(0);
  const [loadImage, setLoadImage] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);
  const [isTagging, setIsTagging] = useState(false);
  const [load, setLoad] = useState(false);
  const { width } = useWindowDimensions();
  const [imageUrl, setImageUrl] = useState<any>("");
  const [canavas, setCanavas] = useState<HTMLCanvasElement | null>(null);
  const [tags, setTags] = useState<any>([]);
  const tagDataModal:any = useBaseModal();
  const [tagData, setTagData] = useState<any>();

  // ************************************Threesixty Images*****************************************
  const ThreesixtyImagesData = () => {
    let entries = ThreeSixityImages && ThreeSixityImages["three_sixity"][0];

    let keyArr = Object.keys(entries).filter((item: any) => item === ImageType);
    let result = entries[keyArr[0]];

    return result[0];
  };

  // ********************************* Paramerter Id get Handler***************************************
  const ThreesixtyParameter = () => {
    let entries = ThreeSixityImages && ThreeSixityImages["three_sixity"][0];

    let keyArr = Object.keys(entries).filter((item: any) => item === ImageType);
    let result = entries[keyArr[0]];

    return result;
  };

  // ***************************Create ThreeSixty Images****************************************************

  useEffect(() => {
    if (!isOpen) return;
    SpriteSpin.create({
      target: "#" + ref.current?.id,
      source: ThreesixtyImagesData(),
      width: width > 600 ? 620 : 350,
      height: width > 600 ? 400 : 300,
      frames: 30,
      framesX: 6,
      framesY: 10,
      frameTime: 30,
      sense: -1,
      senseLane: 10,
      reverse: true,
      animate: false,
      fillMode: "scale-down",
      renderMode: "canvas",
      plugins: [
        "360",
        "drag",
        "wheel",
        // "progress",
        "swipe",
      ],

      onFrame: function (e, data) {
       
        setCurrentFrame(Number(data.frame));
      },
      //   onFrameChanged: function (e, data) {
      //     // setCreateTag(false);
      //     // infoMenu.close();
      //   },
      onComplete: function (e, data) {
        setLoad(true);
      },
      onDestroy: function (e, data) {
        setLoad(false);
        setIsTagging(false);
      },
    });

    return () => {
      SpriteSpin.destroy("#" + ref.current?.id);
      // dispatch(resetthreeSixty());
      setVistedId(0);
      // infoModal.close();
    };
  }, [isOpen, ThreeSixityImages, width]);

  //******************Tags API Call****************************************************

  useEffect(() => {
    API.get("/xcpep/360_image_view/", {
      ...query,
      parameter: ThreesixtyParameter()?.parameter_id,
    }).then((res: any) => {
      setTags(res.data);
    });
  }, [query]);

  // Tags Click Handler

  const tagClick = async (canvas: any, e: any) => {
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    var mydivs = document.getElementById("tooltip") as HTMLElement;

    for (let i = 0; i < tags.length; i++) {
      const diff =
        Math.abs(tags[i].x_value - x) + Math.abs(tags[i].y_value - y);
      if (diff < 10 && tags[i].frame_no === currentFrame) {
        if (tags[i].tag_type === 1) {
          window.open(tags[i].text, "_blank");
          setVistedId(tags[i].id);
          mydivs.style.left = "-1000000px";
        } else {
          mydivs.style.left = "-1000000px";
          setTagData(tags[i]);

        
          // setEdit(true);
          setVistedId(tags[i].id);
          tagDataModal.open();
        }
      }
    }
  };


  //  Tags Mouse Hover Handler
  const handleToolTip = (canvas: any, e: any) => {
    if (!load) return;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    var mydivs = document.getElementById("tooltip") as HTMLElement;
    var pTag = document.getElementById("tooltip-text") as HTMLElement;
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    var hit = false;

    for (let i = 0; i < tags.length; i++) {
      const diff =
        Math.abs(tags[i].x_value - x) + Math.abs(tags[i].y_value - y);
      if (diff < 10 && tags[i].frame_no === currentFrame) {
        let dot = tags[i];
        hit = true;
        mydivs.style.left = x + 700 + "px";
        mydivs.style.top = y + 100 + "px";
        mydivs.style.zIndex = "2222";
        mydivs.style.width = "10px";
       
        pTag.innerHTML = dot.text;
        setImageUrl(dot.image_url);
        canvas.style.cursor = "pointer";
      }
    }
    if (!hit) {
      pTag.innerHTML = "";
      mydivs.style.left = "-1000000px";
      canvas.style.cursor = "grab";
      setImageUrl("");
      setLoadImage(false);
    }
  };
  useEffect(() => {
    if (!load) return;
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    setCanavas(canvas);

    if (canvas) {
      canvas.onmousedown = function (e) {
        setDragging(true);
        // canvas.style.cursor = "grabbing";
      };

      canvas.onmouseup = function () {
        setDragging(false);
        canvas.style.cursor = "grab";
      };
    }
  }, [load]);

  //  Creating Hotspot point ion images

  useEffect(() => {
    //spritespin-canvas
    // if (!canavas) return;
    if (width < 1190) return;
    if (!load) return;
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
   
    const ctx = canvas?.getContext("2d") as any;

    // if (!ctx) return;
    if (tags.length == 0) return;
    tags
      .filter((tag: any) => tag.frame_no === currentFrame)
      .forEach((tag: any) => {
        ctx.fillStyle = vistedId == tag.id ? "green" : "red";
        ctx.beginPath();
        ctx.arc(tag.x_value, tag.y_value, 7, 0, 2 * Math.PI);
        ctx.fill();
      });
  }, [currentFrame, tags, isTagging, load, vistedId, dragging, width]);

  return (
    <div className={styles.dataView}>
      {isOpen && (
        <>
          <TagTransitionablePortal
          isOpen={tagDataModal.isOpen}
          onClose={tagDataModal.close}
          tagData={tagData}
          setVistedId={setVistedId}
        />
          <Box sx={{backgroundColor: '#00000059'}} className={styles.closedImgs}>
            <ClearIcon
            titleAccess="Close"
              sx={{color:'common.white',marginLeft: "1rem"} }
              onClick={() => onCloseModal()}
            />
          </Box>
          <div id="tooltip">
            {imageUrl !== "" ? (
              // eslint-disable-next-line jsx-a11y/alt-text, jsx-a11y/img-redundant-alt
              <img
                src={ imageUrl}
                style={{
                  width: "100%",
                  maxHeight: "20rem",
                  objectFit: "cover",
                  padding:"2rem"
                }}
                alt="image"
                onLoad={() => setLoadImage(true)}
              />
            ) : null}
            <div>
              <span id="tooltip-text"></span>
            </div>
          </div>
          <div
            id="threesixsty"
            className={styles.threesixstyInnerContainer}
            ref={ref}
            onClick={(e) => {
              tagClick(canavas, e);
            }}
            onMouseMove={(e) => handleToolTip(canavas, e)}
          ></div>
        </>
      )}
    </div>
  );
};

export default BomThreeSixtyModal;
