import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Autocomplete, Divider, Skeleton, TextField, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid } from '@mui/x-data-grid';
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 80vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: 0
};


interface FetchItemFromDBPopUpProps {
  isOpen: any;
  onCloseModal: any;
  apiCallAccordianInsideData: any;
  setApiCallAccordianInsideData: any;
  apiCallAccordianData: any;
  setApiCallAccordianData: any;
}

const FetchItemFromDBPopUp = (props: FetchItemFromDBPopUpProps) => {
  const { revisionId, typeId, databaseName, categoryName } = useRouteParams<any>();
  const { isOpen, onCloseModal, apiCallAccordianInsideData, setApiCallAccordianInsideData, apiCallAccordianData, setApiCallAccordianData } = props;
  const [listOfTools, setListOfTools] = useState<any>();
  const [getSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [saveFetchToolsLoader, setSaveFetchToolsLoader] = useState<boolean>(false);
  const [CommodityList, setCommodityList] = useState<any>([]);
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [CommodityListLoader, setCommodityListLoader] = useState<boolean>(true);
  const [listofToolsLoader, setListofToolsLoader] = useState<boolean>(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setGetSelectedIds([]);
    setCommodityListLoader(true);
    if (databaseName == "tools") {
      API.get("/api/db/commodity/", { revision: revisionId, tool_commodity: true }).then((res: any) => {
        let data: any = res?.data?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" })
        setCommodityList(data);
        setDataTypeValues(data && data?.filter((item: any, index: any) => { return item?.id == categoryName }).length > 0 ? data && data?.filter((item: any, index: any) => { return item?.id == categoryName })[0] : data && data[0]);
        setCommodityListLoader(false)
        res?.data?.length > 0 && API.get('/api/db/tool/', { revision: revisionId, commodity: data && data?.filter((item: any, index: any) => { return item?.id == categoryName }).length > 0 ? categoryName : data && data[0]?.id }, 0).then((res: any) => {
          setListOfTools(res?.data)
        }).catch((err: any) => {
          console.log("Server Error");
        });
      }).catch((err: any) => { return console.log("Server Error"), setCommodityListLoader(false) })
    }
    else {
      setCommodityListLoader(true)
      API.get("/api/db/category_fetch_operation/", { revision: revisionId }).then((res: any) => {
        let data: any = res?.data?.filter((item: any, index: any) => { return item?.category !== "UnCategorised" })
        setCommodityList(data);
        setDataTypeValues(data && data?.filter((item: any, index: any) => { return item?.name == categoryName }).length > 0 ? data && data?.filter((item: any, index: any) => { return item?.name == categoryName })[0] : data && data[0]);

        res?.data?.length > 0 && API.get('/api/db/stroke_rate/', { revision: revisionId, category: data && data?.filter((item: any, index: any) => { return item?.name == categoryName }).length > 0 ? categoryName : data && data[0]?.name }, 0).then((res: any) => {
          setListOfTools(res?.data)
          setCommodityListLoader(false);
        }).catch((err: any) => {
          console.log("Server Error");
          setCommodityListLoader(false);
        });
      }).catch((err: any) => { return console.log("Server Error"), setCommodityListLoader(false) })
    }

  }, [isOpen])

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = databaseName == "tools" ? [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "toolCode", headerName: "Tool Code", width: width > 1370 ? 180 : 170, },
    {
      field: "toolName",
      headerName: "Tool Name",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 200 : 180,
      flex: 1,
    },
    {
      field: "specification",
      headerName: "Specification",
      width: width > 1370 ? 130 : 120,
      flex: 1,
    },
    { field: "material", headerName: "Material", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "toolCost", headerName: "Tool Cost (INR)", minWidth: width > 1370 ? 180 : 150, align: 'right', },
  ] : [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "operationCode", headerName: "Operation Code", width: width > 1370 ? 180 : 160, },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 100 : width < 900 ? 180 : 260,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: width > 1370 ? 270 : 250,
      //   flex: 1,
    },
    { field: "rate", headerName: "Rate/Unit (INR)", width: width > 1370 ? 140 : 120, align: 'right', },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: width > 1370 ? 120 : 80,
    }
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = listOfTools && listOfTools?.map((ideaItem: any, index: any) => {
    let objShared: any = databaseName == "tools" ? {
      id: ideaItem?.id,
      sno: index + 1,
      toolCode: ideaItem?.code,
      toolName: ideaItem?.name,
      specification: ideaItem?.specification,
      material: ideaItem?.material,
      toolCost: ideaItem?.price,
    } : {
      id: ideaItem?.id,
      sno: index + 1,
      operationCode: ideaItem?.code,
      description: ideaItem?.description,
      remark: ideaItem?.remark,
      unit: ideaItem?.unit,
      rate: ideaItem?.rate_per_unit,
    }
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  const handleSaveToolFetch = () => {
    setSaveFetchToolsLoader(true);
    if (databaseName == "tools") {
      API.post('/api/db/tool_cost/', { revision: revisionId, tools_id: getSelectedIds }).then((res: any) => {
        setSaveFetchToolsLoader(false);
        setApiCallAccordianData(!apiCallAccordianData);
        setApiCallAccordianInsideData(!apiCallAccordianInsideData);
        onCloseModal();
      });
    }
    else {
      API.post('/api/db/stroke_rate_revision/', { revision: revisionId, strok_rate_id: getSelectedIds }).then((res: any) => {
        setSaveFetchToolsLoader(false);
        setApiCallAccordianData(!apiCallAccordianData);
        setApiCallAccordianInsideData(!apiCallAccordianInsideData);
        onCloseModal();
      })
    }
  }

  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
    setListOfTools(undefined)
    setGetSelectedIds([]);
    if (databaseName == "tools") {
      setListofToolsLoader(true)
      API.get('/api/db/tool/', { revision: revisionId, commodity: item?.id }, 0).then((res: any) => {
        setListOfTools(res?.data)
        setListofToolsLoader(false)
      }).catch((err: any) => {
        console.log("Server Error");
        setListofToolsLoader(false)
      });
    }
    else {
      setListofToolsLoader(true)
      API.get('/api/db/stroke_rate/', { revision: revisionId, category: item?.name }, 0).then((res: any) => {
        setListOfTools(res?.data)
        setListofToolsLoader(false)
      }).catch((err: any) => {
        console.log("Server Error");
        setListofToolsLoader(false)
      });
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Fetch {databaseName == "tools" ? "Tools" : "Operations"}
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box>
              {CommodityListLoader ?
                <>
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                  <Skeleton height="3rem" width="100%" />
                </>
                : <>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 1rem', marginBottom: '0.5rem' }}>
                    <Autocomplete
                      sx={{
                        width: '300px',
                        '.MuiSvgIcon-root': { color: 'primary.main' },
                      }}
                      size="small"
                      id="combo-box-demo"
                      //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
                      value={DataTypeValues}
                      getOptionLabel={(option) =>
                        option?.name == undefined ? "" : option?.name
                      }
                      onChange={(event, newValue, situation) =>
                        handleSelectdatatype(newValue)
                      }
                      options={CommodityList && CommodityList}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                          {option?.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          sx={{
                            fontSize: "1rem",
                            borderBottom: "none",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                          {...params}
                          placeholder="Commodity Fetch"
                        />
                      )}
                    />
                    <LoadingButton loading={saveFetchToolsLoader}
                      size="small"
                      variant="contained"
                      sx={{
                        padding: "0.5rem",
                        lineHeight: 1,
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                        },
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }} disabled={getSelectedIds.length == 0} onClick={handleSaveToolFetch}>Add</LoadingButton>

                  </Box>
                  <div style={{ height: "83vh", width: '100%', padding: '0 0.5rem' }}>

                    {!listofToolsLoader ? <>
                      {listOfTools && <DataGrid
                        headerHeight={48}
                        rowHeight={40}
                        {...dataStructureDataGrid}
                        disableSelectionOnClick={true}
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          setGetSelectedIds(ids);
                        }}
                        sx={{
                          "& ::-webkit-scrollbar": {
                            width: "0.3rem",
                            height: "0.3rem",
                          },
                          "& ::-webkit-scrollbar-thumb": {
                            // background: $thumb-color;
                            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                            borderRadius: "10px",
                          },
                          "& ::- webkit-scrollbar-thumb: hover ": {
                            // width: "4px",
                            backgroundColor: "#045DE9 !important",
                          },
                          "::-webkit-scrollbar-track": {
                            backgroundColor: "#e1e1f3 !important",
                          },
                          "&.MuiDataGrid-root": {
                            border: 'none',
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            borderBottomColor: "primary.light",
                          },
                        }}
                        density={"compact"}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        checkboxSelection
                      />}</>
                      : <Box sx={{ padding: '0 0.5rem' }}>
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                        <Skeleton height="3rem" width="100%" />
                      </Box>
                    }
                  </div></>}
            </Box>
          </Box>
        </>
      </Modal>
    </div >
  );
};

export default FetchItemFromDBPopUp;
