import React, {  } from "react";


import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Box,  Divider,  Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";




const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50vw",
  height: "60vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const colors = [
      "#f1f1f1",
      "#ffe699",
      "#bbdbf7",
      "#7afba9",
      "#fbe5d6",
      "#eb9ba0",
    ];
export interface IdeaGraphModalProps {
  isOpen: any;
  onCloseModal: any;
  subSystem:any;
  abbrev:any
  
}

const IdeaGraphModal = (props: IdeaGraphModalProps) => {
  const { isOpen, onCloseModal, subSystem, abbrev } = props;
  const { abbrevID, topVault } = useRouteParams<any>();
    const [statisticsDataValue, setStatisticsDataValue] = React.useState<any>([]);
    const [statisticsDataCount, setStatisticsDataCount] = React.useState<any>([]);
  const classes = useStyles();

  const getStatisticsDataValue = () => {
    API.get(`/idea/idea_graph_data/`, {
        top_vault: topVault,
        abbreviation: abbrev,
        type: "value",
    }, 0)
    .then((res:any) => {
        // console.log(res?.data?.[0]?.data);
        setStatisticsDataValue(res?.data?.[0]?.data);
    })
    .catch((err:any) => {
        console.log(err);
    });

  }
  const getStatisticsDataCount = () => {
    API.get(`/idea/idea_graph_data/`, {
        top_vault: topVault,
        abbreviation: abbrev,
        type: "count",
    }, 0)
    .then((res:any) => {
        // console.log(res?.data?.[0]?.data);
        setStatisticsDataCount(res?.data?.[0]?.data);
    })
    .catch((err:any) => {
        console.log(err);
    });

  }

  const getPath = (x: any, y: any, width: any, height: any) => {
        return `M${x},${y + height}C${x + width / 3},${y + height} ${
          x + width / 2
        },${y + height / 3}
            ${x + width / 2}, ${y}
            C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
          y + height
        } ${x + width}, ${y + height}
            Z`;
      };

    React.useEffect(() => {
        getStatisticsDataValue();
        getStatisticsDataCount();
    }, []);
      const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}><Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Idea Statistics 
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{display:"grid", gridTemplateColumns: "1fr 1fr"}}>
          
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    margin: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Idea Count
                </Typography>
                <Box
                  sx={{
                    
                      
                          margin: "0rem",
                          width: '45vw',
                          height: "45vh",
                          padding: "1rem 1rem",
                        
                      
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={statisticsDataCount}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="type"
                        interval={0}
                        angle={statisticsDataCount.length > 4 ? -25 : 0 }
                        textAnchor={
                            statisticsDataCount.length > 4 ? "end" : "middle"
                        }
                      />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={-35 }
                          value={`Idea Count`}
                        />
                      </YAxis>
                      <Bar
                        dataKey="value"
                        fill="#8884d8"
                        shape={<TriangleBar />}
                        label={{ position: "top",fill:'#6c6c6c' }}
                      >
                        {statisticsDataCount?.map((entry: any, index: any) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % 20]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "1rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Savings Value
                  </Typography>
                  <Box
                    sx={{
                      margin: "0rem",
                      width: '45vw',
                      height: "45vh",
                      padding: "1rem 1rem",
                    }}
                  >
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsDataValue}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 25,
                          bottom: 55,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="type"
                          interval={0}
                          angle={statisticsDataValue.length > 4 ? -25 : 0}
                          textAnchor={
                            statisticsDataValue.length > 4 ? "end" : "middle"
                          }
                        />
                        <YAxis>
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "100%",
                              fontWeight: "600",
                            }}
                            angle={270}
                            dx={-35}
                            value={"Savings value "}
                          />
                        </YAxis>
                        <Bar
                          dataKey="value"
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top",fill:'#6c6c6c' }}
                        >
                          {statisticsDataValue?.map((entry: any, index: any) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </div>
              
          </Box>
          
          </Box>
      </Modal>
    </div>
  );
};

export default IdeaGraphModal;

