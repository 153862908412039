import Autocomplete from "@mui/material/Autocomplete";
// import styles from "./InwarLandingPage.module.scss";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useHistory, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { Box, Chip, Skeleton, TextField, IconButton, Button, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import { useSelector } from "react-redux";
import sendIcon from "../Assets/images/sendOutline.png";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import swal from "sweetalert";
import { LoadingButton } from "@mui/lab";
import styles from "./DispatchEmail.module.scss";
import resetIcon from "../Assets/images/reset.png";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { API } from "../api-services";
import { userSelectors } from "../Redux/Reducers/user.reducer";
import ErrorModalFilesMail from "../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { PreviewMailModal } from "../ComponentUI/ProjectMailModule/PreviewMailModal";
import { MailSucessModal } from "../ComponentUI/ProjectMailModule/MailSuccessModal";
import MailAttachDocsS3Upload from "./MailAttachDocsS3Upload";
import './DispatchEmail.scss';
import { useDocumentTitle } from "../ui-reusable-component/useDocumentTitle";


const editorConfig = {

  readonly: false,
  toolbar: true,
  // @ts-ignore
  spellcheck: true,
  saveSelectionOnFocus: true,
  saveSelectionOnBlur: true,
  language: "en",
  // toolbarButtonSize: 'medium',
  // toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,

  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },

  controls: {
    font: {
      command: "fontname",
      list: {
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Consolas,monaco,monospace": "Consolas",
      },
    },
  },

}
interface InwardEmailProps { }

const DispatchEmail = (props: InwardEmailProps) => {
  const { projectId, projectname, productIds, status, InwardId } = useParams<any>();
  useDocumentTitle( "Dispatch Email" );
  const [content, setContent] = useState("");
  const [Productvalue, setProductvalue] = useState<any>([]);
  const [outwardsFiles, setOutwardsFiles] = useState<any>([]);
  const [ProductList, setProductList] = useState<any>([]);
  const [SelectedIds, setSelectedIds] = useState<any>(productIds === "0" ? [] : productIds?.split(",")?.map(Number));
  const [AttchmentDatalist, setAttchmentDatalist] = useState<any>([]);
  const [SubjectData, setSubjectData] = useState<any>("");
  const [value, setValue] = React.useState([]);

  const [disableProduct, setDisableProduct] = useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const [updateCounter, setUpdateCounter] = useState<any>(0);
  const [SendEmail, setSendEmail] = useState<any>(false);
  const [userList, setUserList] = useState<any>([]);
  const [ImageUploadCounter, setImageUploadCounter] = useState<any>(0);
  const [showDataFlag, setShowDataFlag] = useState<any>(false);
  const [attachLoader, setAttchLoader] = useState<any>(false);
  const [UserManualEamil, setUserManualEamil] = useState<any>();

  const { height, width } = useWindowDimensions();

  const [ContentLoader, setConentLoader] = useState<any>(false);
  const [usersCC, setUsersCC] = React.useState<any>([]);
  const InputRef = useRef<any>();
  const editor = useRef(null);
  const history = useHistory<any>();
  const PreviewModal = useBaseModal();
  const successModal = useBaseModal();
  var ErrorModalFiles = useBaseModal();
  const handleSelectChangeProduct = (Productitem: any, situation: any) => {
    var collectedallIds =
      Productitem &&
      Productitem.map((item: any, index: any) => {
        return item.id;
      });
    setProductvalue([...Productitem]);
    setSelectedIds(collectedallIds);
  };
  const filehandlechnage = (e: any) => {
    var compreFileSize = 7564320;

    // 9437184

    var datalength = AttchmentDatalist?.length + e.target.files?.length;
    // if (datalength <= 4)

    {
      var fileSizeData = Object.values(e.target.files).map((item: any, index: any) => {
        return item.size;
      });

      var getFileSize =
        AttchmentDatalist &&
        AttchmentDatalist?.map((item: any, index: any) => {
          {
            return item?.file_size;
          }
        });

      var GetTotalFileSize = getFileSize?.reduce((accumulator: any, currentValue: any) => {
        return accumulator + currentValue;
      }, 0);
      const TotalFileSize = fileSizeData.reduce((accumulator: any, currentValue: any) => {
        return accumulator + currentValue;
      }, 0);
      var finalSize = GetTotalFileSize + TotalFileSize;

      if (finalSize <= compreFileSize) {
        MailAttachDocsS3Upload([...e?.target?.files], projectId, setImageUploadCounter, TotalFileSize, setAttchLoader, 1);
      } else {
        setErrorModalFileMsg("You can select upto 7.5Mb files.");
        ErrorModalFiles.open();
      }
    }

    // else {

    //   setErrorModalFileMsg("You can select upto 4 files.")
    //   ErrorModalFiles.open()
    // }
  };

  useEffect(() => {
    setAttchLoader(true);
    API.get("/analytics/store_attachment_details/", { project: projectId, mail_type: 5 })
      .then((res: any) => {
        setAttchmentDatalist(res?.data);
        setAttchLoader(false);
      })
      .catch((err: any) => {
        setAttchLoader(false);
        console.log("Server Error");
      });
  }, [projectId, ImageUploadCounter]);
  ///vehicle_inward/inward_view/?product_list=true&project_id=4
  var TouserListOption = () => {
    let ccemailId = usersCC?.map((itm: any) => itm.email);
    let tomailId: any = value?.map((itm: any) => itm.email);
    return (
      userList &&
      userList
        ?.filter((item: any) => ![...ccemailId, ...tomailId]?.includes(item.email))
        ?.map((item: any, index: any) => {
          return { ...item, id: index + 1 };
        })
    );
  };

  const SubjectHandler = (e: any) => {
    setSubjectData(e?.target?.value);
  };

  useEffect(() => {
    return () => setShowDataFlag(false);
  }, []);

  const handleSelectChange = (touser: any) => {
    var ToIds =
      touser &&
      touser?.map((item: any, index: any) => {
        return item?.id;
      });

    // @ts-ignore
    setValue([...touser]);
  };

  const PreviewHandler = () => {
    PreviewModal.open();
  };
  const handleSelectChangeCC = (ccuser: any) => {
    // @ts-ignore

    var CCIds =
      ccuser &&
      ccuser?.map((item: any, index: any) => {
        return item?.id;
      });

    setUsersCC([...ccuser]);
  };

  const handleDelete = (FileIds: any) => {
    setAttchLoader(true);
    API.delete("/analytics/store_attachment_details/", { id: FileIds, mail_type: 5 }, 0).then((res: any) => {
      setImageUploadCounter((prev: any) => prev + 1);
    });
  };
  useEffect(() => {
    if (status == "true" || status == true) {
      var projectnameandIds =
        Productvalue &&
        Productvalue?.map((item: any, index: any) => {
          return `${item?.product_name} (${item?.product_id})`;
        }).toString();
      var subj = projectnameandIds.length > 0 ? `${projectname}: Parts Dispatched: [${projectnameandIds}] ` : "";
      setSubjectData(subj);
    }
  }, [status, Productvalue]);
  const browreFilehandler = () => {
    return InputRef?.current?.click();
  };

  const maillogdirection = () => {
    window.open(`/#/Project-mail-logs/${projectId}/${5}/${"Product Outward"}`, "_blank", "noopener");
  };

  const PartNameHandler = (item: any) => {
    setUserManualEamil(item);
  };

  const NormalUser = (userData: any, domain: any) => {
    if (domain == "advancedstructures.in") {
      var newdata = { email: userData, full_name: "", user_color: "#a6f0b9" };
      var emailfilter =
        userList &&
        userList?.filter((item: any, index: any) => {
          return item?.email !== userData;
        });

      //  console.log(emailfilter,"emailfilteremailfilter")
      setUserList([newdata, ...emailfilter]);
    } else {
      var newdata = { email: userData, full_name: "", user_color: "#fbe5d6" };
      var emailfilter =
        userList &&
        userList?.filter((item: any, index: any) => {
          return item?.email !== userData;
        });
      setUserList([newdata, ...emailfilter]);
    }
  };

  const ManualEmailHandler = (e: any) => {
    if (e.key == "Enter") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      var EmailValidation = regex.test(UserManualEamil);
      const domain = UserManualEamil?.split("@")?.pop();
      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com";

      if (EmailValidation) {
        if (!domindata) {
          if (domain == "advancedstructures.in") {
            NormalUser(UserManualEamil, domain);
          } else {
            NormalUser(UserManualEamil, domain);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (productIds === "0") {
      setValue([]);
      setUsersCC([]);
    } else {
      getDocsData();
    }
  }, [productIds]);

  const PasteEmailHandler = (data: any) => {
    if (data) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      var EmailValidation = regex.test(data);
      const domain = data?.split("@")?.pop();
      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com";

      if (EmailValidation) {
        if (!domindata) {
          if (domain == "advancedstructures.in") {
            NormalUser(data, domain);
          } else {
            NormalUser(data, domain);
          }
        }
      }
    }
  };

  const getDocsData = () => {
    API.get("/xcpep/dispatch/", { get_mail_contents: projectId, products: SelectedIds.toString() || productIds?.split(",")?.map(Number) }, 0).then((res: any) => {
      if (res.status == 200) {
        setShowDataFlag(true);
        setImageUploadCounter((prev: any) => prev + 1);
        setOutwardsFiles(res.data?.outward_files);
        setDisableProduct(false);
        history.push(`/dispatchemail/${projectId}/${projectname}/${true}/${SelectedIds.toString()}`);
      }
    });
  };

  const FinalEmailSendHandler = () => {
    var ToIds =
      value &&
      value?.map((item: any, index: any) => {
        return item?.email;
      });
    var CCuser =
      usersCC &&
      usersCC?.map((item: any, index: any) => {
        return item?.email;
      });

    var attachedFile =
      AttchmentDatalist &&
      AttchmentDatalist?.map((item: any, index: any) => {
        return item?.file_path;
      });
    setSendEmail(true);

    successModal.open();
    API.post(
      "/xcpep/email_send_function/",
      {
        to_mail: ToIds,
        cc_mail: CCuser,
        data: content,
        project: projectId,
        subject: SubjectData,
        // dash_data: EmailDatalist,
        attachment: attachedFile,
        mail_type: 5,
      },
      {},
      0
    )
      .then((res: any) => {
        setProductvalue([]);
        setSubjectData("");
        setAttchmentDatalist([]);

        API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 5 })
          .then((res: any) => {
            setAttchmentDatalist([]);
            setSendEmail(false);

            setAttchLoader(false);
          })
          .catch((err: any) => {
            setAttchLoader(false);
            console.log("Server Error");
          });
      })
      .catch((err: any) => {
        setSendEmail(true);
      });
  };

  const EmailContentGenerateHandler = () => {
    setDisableProduct(true);
    getDocsData();
  };
  var ResetEmailContent = () => {
    swal({
      title: "Reset Email",
      text: "Are you sure you want to reset mail content?",
      buttons: ["No", "Ok"],
    }).then((confirm) => {
      if (confirm) {
        setValue([]);
        setUsersCC([]);
        setShowDataFlag(false);

        setProductvalue([]);
        setSubjectData(null);
        setAttchmentDatalist([]);

        API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 5 })
          .then((res: any) => {
            setAttchmentDatalist([]);
          })
          .catch((err: any) => {
            setAttchLoader(false);
            console.log("Server Error");
          });

        window.open(`/#/dispatchemail/${projectId}/${projectname}/0/${false}`, "_blank", " noopener");
        setAttchLoader(false);
      } else {
      }
    });
  };
  const userProfilePic = useSelector(userSelectors.selectAll);

  useEffect(() => {
    API.get("/analytics/user_access_list/", { project_id: projectId }).then((res: any) => {
      setUserList(res?.data);
    });
  }, [projectId]);
  useEffect(() => {
    API.get("/vehicle_inward/inward_view/", { project_id: projectId, product_list: true, product_email_list: true }).then((res: any) => {
      const topVaultsParam: any = productIds && typeof productIds == "string" ? productIds?.split(",")?.map(Number) : [productIds];

      var FiltertheslectedProductIds =
        res?.data &&
        res?.data?.filter((item: any, index: any) => {
          return topVaultsParam && topVaultsParam?.includes(item?.id);
        });
      if (FiltertheslectedProductIds?.length > 0) {
        setProductvalue(FiltertheslectedProductIds);
      }
      setProductList(res?.data);
    });
  }, [projectId, productIds]);

  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>
      {status == "true" && productIds !== "0" ? (
        <div>
          <Box>
            <Box>
              <Typography sx={{ fontSize: "1rem" }}>Dear User,</Typography> <br />
            </Box>
            <Typography sx={{ fontSize: "1rem" }}>
              I hope this email finds you well. I am writing to inform you that we have successfully completed the project {projectname} [{" "}
              {Productvalue &&
                Productvalue?.map((item: any, index: any) => {
                  return `${item?.product_name} (${item?.product_id})`;
                }).toString()}
              ] as per the agreed-upon specifications and timelines. We have dispatched parts to your location and they are expected to arrive at your facility by today .
              <br />
              <ol>
                We have included the following documents along with this Email:
                <li>
                  E-way Bill: This document serves as proof of shipment and contains details such as the consignment's origin, destination, and transportation details. Please review the enclosed e-way
                  bill for your reference.
                </li>
                <li>
                  Gate Pass: The gate pass allows access to the delivery area at your premises. It ensures that the delivery is authorized and prevents any inconvenience during the unloading process.
                  The gate pass is attached to this email.
                </li>
                <li>
                  Delivery Challan: The delivery challan outlines the quantity and description of the dispatched parts. It serves as a receipt for the goods being delivered and is used for
                  record-keeping purposes. You will find the delivery challan attached to this email.
                </li>
                <li>
                  Verified Parts List: We have meticulously verified and cross-checked all the parts included in the shipment to ensure accuracy and quality. The verified parts list is enclosed for
                  your reference. Kindly review the list and notify us immediately if you identify any discrepancies.
                </li>
              </ol>
            </Typography>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <ol>
                <p>Please find the attachment link below: </p>
                {outwardsFiles &&
                  outwardsFiles?.map((item: any) => {
                    return (
                      <li>
                        <a href={item?.file_path}>{item?.name}</a>
                      </li>
                    );
                  })}
              </ol>
            </div>
            <Typography sx={{ fontSize: "1rem" }}>
              If you have any queries or concerns during the delivery process, please don't hesitate to reach out to our customer support team. They will be more than happy to assist you.
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              Once the parts arrive, we kindly request you to inspect them thoroughly to ensure they meet your expectations. Should you have any concerns or require further assistance, please contact
              us immediately. We are committed to your satisfaction and will
            </Typography>

            <Typography sx={{ fontSize: "1rem" }}>address any issues promptly.</Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "1rem" }}>Thanks & Regards,</Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              {userProfilePic && `${userProfilePic[0]?.first_name} ${userProfilePic[0]?.last_name} `}
              <br />
              {userProfilePic && `${userProfilePic[0]?.email}`}
              <br />
              {/* @ts-ignore */}
              {userProfilePic && `${userProfilePic[0]?.country_code}-${userProfilePic[0]?.phone} `}
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              <a href="https://advancedstructures.in/">www.advancedstructures.in</a>
            </Typography>
          </Box>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );

  useEffect(() => {
    if (productIds !== "0") {
      setContent(htmlElement);
    } else {
      setContent("");
    }
  }, [htmlElement, productIds]);

  // console.log(SubjectData, "SubjectDataSubjectData")
  return (
    <Box sx={{ height: "90vh" }}>
      <input
        type="file"
        id="files"
        name="files"
        style={{ display: "none" }}
        onChange={(e: any) => filehandlechnage(e)}
        ref={InputRef}
        multiple
      />
      <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />

      {PreviewModal.isOpen && (
        <PreviewMailModal
          isOpen={PreviewModal.isOpen}
          onCloseModal={PreviewModal.close}
          Tousers={value && value}
          CCusers={usersCC}
          Subject={SubjectData}
          AttchmentData={AttchmentDatalist}
          emailContent={content}
          FinalEmailSendHandler={FinalEmailSendHandler}
        //  ErrorMsg={ErrorModalFileMsg}
        />
      )}
      {successModal.isOpen && (
        <MailSucessModal
          isOpen={successModal.isOpen}
          onCloseModal={successModal.close}
          SendEmail={SendEmail}
          parentsClosed={PreviewModal.close}
          Project={projectId}
          ProjectName={projectname}
          emailType={"5"}
          ProductId={InwardId}
        />
      )}

      <div className={styles.ProductContainer}>
        <div className={styles.ProductInnerContainer}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackOutlinedIcon sx={{ cursor: "pointer", color: "primary.main" }} />
          </IconButton>
          <Typography sx={{ whiteSpace: "nowrap", fontSize: "1rem" }}>Dispatch email ({projectname})</Typography>
        </div>
        <div style={{ marginRight: "2.1rem", display: "flex", columnGap: "2rem", alignItems: "center" }}>
        <Typography variant='body2' sx={{color: "#ef5350"}}>Max 50 recipients*
 </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={() => maillogdirection()}
            sx={{
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
            }}>
            Email logs
          </Button>
          {/* {(value?.length > 0 && content?.length > 0 && SubjectData?.length > 0) && */}
          <img
            src={sendIcon}
            title="Preview Email"
            style={{
              width: "1.7rem",
              height: "auto",
              cursor: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? "pointer" : "not-allowed",
              opacity: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? "1" : "0.4",
            }}
            onClick={() => value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 && PreviewHandler()}
          />
        </div>
      </div>
      <div className={styles.parents_userContainer}>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: "1rem", marginRight: "1rem", whiteSpace: "nowrap", width: "10rem", textAlign: "right" }}>Select Product :</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              disableClearable
              // disabled={status == false || status == "false" ? false : true}
              id="multiple-limit-tags"
              limitTags={5}
              disableCloseOnSelect
              sx={{
                minWidth: { lg: "84.5vw", xl: "88vw" },
                ".MuiSvgIcon-root": { color: "primary.main" },
              }}
              value={Productvalue}
              onChange={(event, newValue, situation) => handleSelectChangeProduct(newValue, situation)}
              options={ProductList && ProductList?.filter((item: any) => !Productvalue?.map((itm: any) => itm.id)?.includes(item?.id))}
              // @ts-ignore
              getOptionLabel={(option) => option?.product_name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    sx={{ background: "#007fff14", fontSize: "1rem" }}
                    label={option?.product_name}
                    {...getTagProps({ index })}
                  // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ fontSize: "1rem" }}
                  {...props}>
                  {option.product_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
                    "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
                  }}
                />
              )}
            />
            <Box>
              {status == "false" || status == false ? (
                <LoadingButton
                  loading={disableProduct}
                  sx={{
                    marginRight: "-0.5rem",
                    minWidth: "1rem",
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}>
                  <ShortcutIcon
                    titleAccess="Generate the email content"
                    sx={{
                      transform: "rotate(90deg)",
                      fontSize: "2rem",
                      cursor: Productvalue?.length > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={() => Productvalue?.length > 0 && EmailContentGenerateHandler()}
                  />
                </LoadingButton>
              ) : (
                <img
                  src={resetIcon}
                  onClick={() => ResetEmailContent()}
                  title="Reset mail content"
                  style={{ width: "1.5rem", cursor: "pointer", margin: "0 0.5rem" }}
                />
              )}
            </Box>
          </Box>
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: "1rem", marginRight: "1rem", whiteSpace: "nowrap", width: "10rem", textAlign: "right" }}>To :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            value={value}
            disabled={status == "false" || status == false  ? true  : false}
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: { lg: "87.5vw", xl: "93vw" } }}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue);
            }}
            onChange={(event, newValue) => {
              handleSelectChange(newValue);
            }}
            onPaste={(event: any) => {
              PasteEmailHandler(event.clipboardData.getData("text"));
            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  //  @ts-ignore
                  sx={{ color: "primary.main", background: `${option?.user_color}`, fontSize: "1rem" }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            onKeyDown={(e: any) => ManualEmailHandler(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                  // @ts-ignore
                  // backgroundColor: `${params?.user_color}`,
                }}
              //   InputProps={{
              //     // background: `${params?.user_color}`
              //     // @ts-ignore
              //     style: "background-color: ${params?.user_color",

              //  }}
              />
            )}
          />
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: "1rem", marginRight: "1rem", whiteSpace: "nowrap", width: "10rem", textAlign: "right" }}>Cc :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            disabled={status == "false" || status == false  ? true : false}
            limitTags={6}
            disableCloseOnSelect
            
            // disabled={disableProduct == "false" ? true : false}
            sx={{ width: { lg: "87.5vw", xl: "93vw" } }}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue);
            }}
            value={usersCC}
            onChange={(event, newValue) => {
              handleSelectChangeCC(newValue);
            }}
            onKeyDown={(e: any) => ManualEmailHandler(e)}
            onPaste={(event: any) => {
              PasteEmailHandler(event.clipboardData.getData("text"));
            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            // renderOption={(props, option) => (
            //   <Box
            //     component="li"
            //     sx={{
            //       // color:`${option?.user_color}`,

            //       marginBottom: "0.5rem",
            //       background: "white",
            //     }}
            //     {...props}>
            //     {option.email}
            //   </Box>
            // )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  //  @ts-ignore
                  size="small"
                  sx={{ color: "#007fff", background: `${option?.user_color}`, fontSize: "1rem" }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            // style={{ width: "93vw" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  ".MuiSvgIcon-root": { color: "primary.main" },
                  "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
                  "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.userContainersubject}>
        <Typography sx={{ fontSize: "1rem", marginRight: "1rem", whiteSpace: "nowrap", width: "10rem", textAlign: "right" }}>Subject :</Typography>
        <TextField
          disabled={status == "false" || status == false ? true : false}
          value={SubjectData}
          onChange={(e: any) => SubjectHandler(e)}
          // defaultValue="Small"
          size="small"
          variant="standard"
          InputProps={{
            style: { fontSize: "1rem" },
          }}
          sx={{
            width: { lg: "87.5vw", xl: "93vw" },
            "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
            "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
          }}
        />
      </div>
      <div className={styles.attchmentcontainer}>
        <Typography sx={{ fontSize: "1rem", marginRight: "1rem", whiteSpace: "nowrap", width: "10rem", textAlign: "right" }}>Attachments :</Typography>
        <div style={{ width: "95%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", columnGap: "1rem" }}>
            {attachLoader ? (
              <>
                <Skeleton
                  animation="wave"
                  sx={{ width: "98%" }}
                />{" "}
              </>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                  {AttchmentDatalist && AttchmentDatalist?.length == 0 && <span style={{ color: "#007fff80" }}>User can attach file upto 7.5mb.</span>}
                  {productIds !== "0" && status === "true" && (
                    <>
                      {AttchmentDatalist &&
                        status === "true" &&
                        AttchmentDatalist.map((item: any, index: any) => {
                          return (
                            <>
                              <Chip
                                size="small"
                                label={item?.file_name}
                                variant="outlined"
                                onClick={() => window.open(item?.file_url, "_blank")}
                                onDelete={() => handleDelete(item?.id)}
                                sx={{ borderColor: "primary.main", fontSize: "1rem" }}
                              />
                            </>
                          );
                        })}
                    </>
                  )}
                </Box>
                <Box>
                  {status == "false" || status == false ? (
                    ""
                  ) : (
                    <AttachFileIcon
                      titleAccess={"Attachments"}
                      onClick={() => browreFilehandler()}
                      sx={{ cursor: "pointer", color: "primary.main", marginRight: "1.6rem" }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </div>
      <div style={{ padding: "0 1rem" }}>
        {ContentLoader ? (
          <>
            <Skeleton
              variant="text"
              height={"10rem"}
              sx={{ fontSize: "1rem", heigth: "10rem !important" }}
            />
            <Skeleton
              variant="rounded"
              height={"20rem"}
            />
          </>
        ) : (
          // @ts-ignore
          <JoditEditor
            ref={editor}
            value={content}
            config={editorConfig}
            className="DispatchEmailEditor"
            onBlur={(newContent) => setContent(newContent)}
          />
        )}
      </div>
    </Box>
  );
};

export default DispatchEmail;
