import * as React from 'react';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import { API } from '../../../../api-services';
import { UserAction } from '../IdeaGenerationViewLanding';


interface Params{
    projectId:any,
    projectName:any,
    topVault:any,
    vault:any,
    treeabbr:any,
    workstation:any,
    isLeaf:any,
    vault_id:any
  }


export interface IIdeagenAddPdfModelProps {
    onCloseModal: any;
    isOpen: any;
    setApiCallDocumentBOMData:any;
    apiCallDocumentBOMData:any;
    documentBOMData:any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:'40vw',xl:'30vw'},
    height: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};


export function IdeagenAddPdfModel(props: IIdeagenAddPdfModelProps) {
    const { onCloseModal, isOpen, setApiCallDocumentBOMData,apiCallDocumentBOMData,documentBOMData} = props;
    const userAction = React.useContext<any>(UserAction);
    let UserActionParams = userAction && userAction?.action;
    const {projectId,projectName,topVault,vault,treeabbr,workstation,isLeaf,vault_id} = useRouteParams<Params>();
    const [fileList,setFileList] = useState<any>();
    const [fileListLoader,setFileListLoader] = useState<boolean>(false);
    const [parameterIds, setParameterIds] = useState<any>(0);

    const inputElRefPdf = useRef<any>();

    const UploadPDF = (e: any) => {
        setFileList(e?.target?.files[0])
    };

    const handleAddWorkstationEIR = () => {
        setFileListLoader(true);
        const formData = new FormData();
        formData.append(parameterIds, fileList);
        API.put(`/idea/pool/${vault_id}/`, formData , {document:true}, 0)
        .then((res: any) => {
          setFileListLoader(false);
          setApiCallDocumentBOMData(!apiCallDocumentBOMData);
          onCloseModal();
        })
        .catch((err: any) => {
          console.log(err, "Unable to delete file. Server Error");
        });
    }

    const selectParameterIdHandler = ( e: any ) => {
        setParameterIds( Number(e.target.value) );
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                           {`Add Idea Document`}
                        </Typography>

                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ height: 'fit-content' }}>
                        <Box>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
                                 <InputLabel sx={{ color: 'primary.main', marginTop: '0.5rem'}} shrink>Select a Document Parameter</InputLabel>
                                 <Select
                                   labelId="demo-simple-select-standard-label"
                                   id="demo-simple-select-standard"
                                   value={parameterIds}
                                   disabled={UserActionParams?.includes("U")?false:true}
                                   onChange={selectParameterIdHandler}
                                   sx={{"&:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                                    fontSize: '1rem'
                                  },
                                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                                }}
                                 >
                                    {documentBOMData && documentBOMData?.filter((item:any)=>{ return item?.value == ""})?.map((item:any)=>{
                                        return (<MenuItem value={item?.key} disabled={item?.editable?false:true}>{item?.paramter_name}</MenuItem>)
                                    })}
                                 </Select>
                            </FormControl>
                            <Box sx={{ margin: 'auto', alignItems: 'center', padding: '0 1rem', marginBottom: '1rem' }}>
                                <label style={{ marginRight: '1rem',fontSize:'0.87rem' }}>Select File (PDF only) : </label>
                                <input
                                    accept=".pdf"
                                    id="PDF Upload"
                                    type="file"
                                    disabled={UserActionParams?.includes("U")?false:true}
                                    style={{ marginTop:'0.5rem', }}
                                    onChange={(e: any) => UploadPDF(e)}
                                    ref={inputElRefPdf}
                                />
                            </Box>
                            <Box sx={{ width: '100%', textAlign: 'right',padding: '0 1rem', }}>
                                <LoadingButton variant='contained'
                                    loading={fileListLoader}
                                    size='small'
                                    disabled={fileList==undefined || parameterIds==0}
                                    onClick={()=>handleAddWorkstationEIR()}
                                    sx={{
                                        lineHeight:1,
                                        padding:'0.5rem 1rem',
                                        marginLeft: '1rem',
                                        minWidth: '1rem',
                                        '.MuiCircularProgress-root': {
                                            color: 'primary.main',
                                        },
                                        "&:hover": {
                                            transform: 'Scale(1.05)',
                                            transition: 'transform 0.5s ease',
                                          }
                                    }}>
                                    Add File
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}
