import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import RViewerJS from "viewerjs-react";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import QuickCompareGraphModal from "./QuickCompareGraphModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import lodingImage from "../../Assets/images/loading-screen.gif";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from '@mui/icons-material/Cancel';
import Footer from "../../ViewModule/Footer";
// import ReplyIcon from '@mui/icons-material/Reply';


type Anchor = "top" | "left" | "bottom" | "right";

interface ICompareCombinationProps {
  // setCloseCounts: any
}

function CompareCombination(props: ICompareCombinationProps) {
  // const { setCloseCounts } = props;
  const [isActive, setIsActive] = useState(true);
  const store = useSelector((state) => state);
  const [searchHistory, setSearchHistory] = React.useState<any>([]);
  const [compareOptions, setCompareOptions] = useState<any>([]);
  const [closeCount, setCloseCount] = useState<any>(0);
  const { projectId } = useRouteParams<any>();
  const dispatch = useDispatch();

  const { combinationId } = useRouteParams<any>();
  const graphModal = useBaseParamsModal();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [BOMDetails, setBOMDetails] = React.useState<any>([]);
  const [isShowBOM, setIsShowBOM] = React.useState<any>(false);
  const [isShowCosting, setIsShowCosting] = React.useState<any>(false)
  const [deleteCount, setDeleteCount] = React.useState<any>(0);
  const [isShowImage, setIsShowImage] = React.useState<any>(false)
  const [CostingDetails, setCostingDetails] = React.useState<any>([]);
  const [ImageDetails, setImageDetails] = React.useState<any>([]);
  const [productsName, setProductsName] = React.useState<any>([]);
  const [chipData, setChipData] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);



  const getChipData = () => {
    API.get("/customer/compare_cart/", { combination_id: combinationId }, 0).then((res: any) => {
      setChipData(res.data)
    }).catch((err: any) => {
      console.log(err);
    })
  }

  React.useEffect(() => {
    getChipData();
  }, [combinationId])

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };


  const historyCard = () => {
    API.get(
      "/customer/compare_cart/",
      {
        compare_history: true,
      },
      0
    )
      .then((res: any) => {
        setSearchHistory(res.data);
      })
      .catch((err: any) => { });
  };


  const getCompareBOMData = () => {
    setIsShowBOM(true);
    API.get("/customer/compare_combination/", { compare_id: combinationId }, 0)
      .then((res: any) => {
        setBOMDetails(res.data);
        setIsShowBOM(false);
      })
      .catch((err: any) => {
        setIsShowBOM(false);
      });
  };
  const getCompareCostingData = () => {
    setIsShowCosting(true);
    API.get(
      "/customer/compare_combination/",
      { compare_id: combinationId, module: "Costing" },
      0
    )
      .then((res: any) => {
        setCostingDetails(res.data);
        setIsShowCosting(false);
      })
      .catch((err: any) => {
        setIsShowCosting(false);
      });
  };

  const getImageData = () => {
    setIsShowImage(true);
    API.get(
      "/customer/compare_combination/",
      { compare_id: combinationId, image: true },
      0
    )
      .then((res: any) => {
        setIsShowImage(false);
        setImageDetails(res.data);
      })
      .catch((err: any) => {
        setIsShowImage(false);
      });
  };

  const getProductName = () => {
    API.get("/customer/compare_cart/", {
      compare_id: combinationId
    }, 0).then((res: any) => {
      setProductsName(res.data)
    }).catch((err: any) => {
      console.log(err);
    })
  }

  React.useEffect(() => {
    getCompareBOMData();
    getCompareCostingData();
    getImageData();
    getProductName();

  }, [combinationId]);
  React.useEffect(() => {
    historyCard()
  }, [combinationId, deleteCount])

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <Typography>Add Prject </Typography>
      </Box>
    </Box>
  );

  const history = useHistory();

  const backButtonHandler: any = () => {
    history.goBack();
  };

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const getEmptyProductData=(count:any)=>{
    let Arr = [];
    if (!isNaN(count)) {
      for (let i = 1; i <= count; i++) {
        Arr.push(
          <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        color: "#ffffff",
                        textAlign: "center",
                        borderLeft: '1px solid ',
                        borderLeftColor: 'primary.light',
                        width: '20vw'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '1rem', marginRight: '0.5rem', lineHeight: '1' }}>-</Typography>
                      </Box>
                    </TableCell>
        )
      }
    }
    return Arr
  }

  const getEmptyDetailsData=(count:any)=>{
    let Arr:any = [];
    if (!isNaN(count)) {
      for (let i = 1; i <= count; i++) {
        Arr.push(
          <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottom: "none",
            heigth: "5rem",
            borderLeft: '1px solid ',
            borderLeftColor: 'primary.light',
            width: '20vw'
          }}
        >
          <Typography>-</Typography>
        </TableCell>
        )
      }
    }
    return Arr;
  }

  const getEmptyImageData =(count:any)=>{
    let Arr:any = [];
    if (!isNaN(count)) {
      for (let i = 1; i <= count; i++) {
        Arr.push(
          <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottom: "none",
            heigth: "5rem",
            borderLeft: '1px solid ',
            borderLeftColor: 'primary.light',
            width: '20vw',
          }}
        >
          <Box>
            {/* @ts-ignore */}
            <RViewerJS>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns:
                    "repeat(auto-fit, minmax(7vw, 1fr))",
                  gridGap: "1rem",
                }}
              >
                
              </Box>
            </RViewerJS>
          </Box>
        </TableCell>
        )
      }
    }
    return Arr;
  }

  const getEmptyBOMData=(count:any)=>{
    let Arr:any = [];
    if (!isNaN(count)) {
      for (let i = 1; i <= count; i++) {
        Arr.push(<TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  borderLeft: '1px solid ',
                                  borderLeftColor: 'primary.light',
                                  width: '20vw'
                                }}
                              >-
                              </TableCell>)

      }}
      return Arr;
  }

  const handleDelete = (idd: any) => {
    let arr: any = [];
    let filteredData = productsName?.filter((item: any) => {
      if (item.vault_id !== idd) {
        arr.push(item?.vault_id)
      }
    })
    setProductsName((prev: any) => prev.filter((item: any) => item.vault_id !== idd))
    API.put("/customer/compare_cart/", { vaults: arr }, {}, 0).then((res: any) => {
      history.push(`/CompareCombination/${res.data.id}`)
    }).catch((err: any) => {

    })

  };


  return (
    <>

      {graphModal.isOpen && (
        <QuickCompareGraphModal
          Open={graphModal.isOpen}
          Close={graphModal.close}
          ParameterNames={graphModal.propsId}
        />
      )}
     

      <Box sx={{ width: "100%", padding: "0 0.5rem", marginTop: "0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        ><Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
              {/* {chipData &&
                  chipData?.map((item:any)=>{
                    return <Chip
                    size="small"
                    label={item.name}
                    variant="outlined"
                    sx={{ borderColor: 'primary.main' }}
                    onClick={handleClick}
                    onDelete={()=>handleDelete(item.id)}
                  />
                  })} */}

            </Box>
          </Box>
          {/* <Box>
            <Button onClick={() => histroyModal.open()} 
            sx={{"&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
              }}}>
              Compare History
            </Button>
            <Button 
            sx={{"&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
              }}}>
              Download <FileDownloadIcon sx={{ color: "primary.main" }} />
            </Button>
          </Box> */}
        </Box>
      </Box>

      <Box sx={{ width: "100%", padding: "0 1rem" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "2rem",backgroundColor:'primary.main' }}></Box>
          <Box sx={{ width: "100%" }}>
            <Table>
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      color: "#ffffff",
                      width: "20vw",
                    }}
                  >
                    Parameter
                  </TableCell>
                  {productsName && productsName?.map((item: any, index: any) => {
                    return <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        color: "#ffffff",
                        textAlign: "center",
                        borderLeft: '1px solid ',
                        borderLeftColor: 'primary.light',
                        width: '20vw'
                      }}
                      key={index}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '1rem', marginRight: '0.5rem', lineHeight: '1' }}>  {item?.name} </Typography>
                        {productsName && productsName?.length>2 &&<CancelIcon onClick={() => handleDelete(item?.vault_id)} sx={{ cursor: "pointer" }} />}
                      </Box>
                    </TableCell>
                  })}
                  {getEmptyProductData(4-productsName?.length)}

                </TableRow>
              </TableHead>
            </Table>
          </Box>
        </Box>
        <Box
          sx={{ height: "82vh" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box sx={{ display: "flex", marginBottom: "1rem" }}>
            <Box
              sx={{
                minHeight: "10rem",
                width: "2rem",
                backgroundColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1.2",

              }}
            >
              <Typography
                sx={{
                  transform: "rotate(270deg)",
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  display: "flex",

                }}
              >
                General Details
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        heigth: "5rem",
                        width: "20vw",
                      }}
                    >
                      Part Details
                    </TableCell>
                    {!isShowImage && ImageDetails &&
                      ImageDetails?.length > 0 ?
                      Object.keys(ImageDetails[0]).map(
                        (item: any, index: any) => {
                          return (
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                heigth: "5rem",
                                borderLeft: '1px solid ',
                                borderLeftColor: 'primary.light',
                                width: '20vw'
                              }}
                              key={index}
                            >
                              <Typography sx={{fontSize:'1rem'}}>{item}</Typography>
                            </TableCell>
                          );
                        }
                      ) : <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <Skeleton animation="pulse" sx={{height:'11rem',width:'11rem'}}/>
                          <Skeleton animation="pulse" sx={{height:'11rem',width:'11rem'}}/>
                          <Skeleton animation="pulse" sx={{height:'11rem',width:'11rem'}}/>
                          <Skeleton animation="pulse" sx={{height:'11rem',width:'11rem'}}/>
                          <Skeleton animation="pulse" sx={{height:'11rem',width:'11rem'}}/> */}
                      </Box>}
                      {getEmptyDetailsData(4-productsName?.length)}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        heigth: "5rem",
                        width: "20vw",
                      }}
                    >
                      Images
                    </TableCell>
                    {!isShowImage && ImageDetails &&
                      ImageDetails?.length > 0 ?
                      Object.keys(ImageDetails[0]).map(
                        (item: any, index: any) => {
                          return (
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                heigth: "5rem",
                                borderLeft: '1px solid ',
                                borderLeftColor: 'primary.light',
                                width: '20vw',
                              }}
                              key={index}
                            >
                              <Box>
                                {/* @ts-ignore */}
                                <RViewerJS>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns:
                                        "repeat(auto-fit, minmax(7vw, 1fr))",
                                      gridGap: "1rem",
                                    }}
                                  >
                                    {ImageDetails[0][item]?.map(
                                      (img: any, ind: any) => {
                                        return (
                                          <>
                                            {/* <CardMedia
                              component="img"
                              height="100"
                              width="7vw"
                              image={auto}
                              style={{ objectFit: 'contain' }}
                              alt="Paella dish"
                            /> */}
                                            <Image
                                              src={img?.value}
                                              placeholderImg={lodingImage}
                                              alt={img?.param_name}
                                              title={img?.param_name}
                                              style={{
                                                // objectFit: "contain",
                                                // height: "auto",
                                                // width: "7vw",
                                                // cursor: "pointer",
                                                objectFit: "contain",
                                                height: '100%',
                                                width: '7vw',
                                                cursor: 'pointer',
                                                margin: 'auto',
                                                maxHeight: '7vw',
                                                verticalAlign: 'middle',
                                                borderRadius: '8px'
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                  </Box>
                                </RViewerJS>
                              </Box>
                            </TableCell>
                          );
                        }
                      ) :
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <Skeleton animation="pulse" sx={{ height: '11rem', width: '11rem' }} />
                        <Skeleton animation="pulse" sx={{ height: '11rem', width: '11rem' }} />
                        <Skeleton animation="pulse" sx={{ height: '11rem', width: '11rem' }} />
                        <Skeleton animation="pulse" sx={{ height: '11rem', width: '11rem' }} /> */}
                      </Box>}
                      {getEmptyImageData(4-productsName?.length)}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginBottom: "1rem" }}>
            <Box
              sx={{
                height: "auto",
                width: "2rem",
                backgroundColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1.2",
                minHeight:'10rem',
              }}
            >
              <Typography
                sx={{
                  transform: "rotate(270deg)",
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  display: "flex",
                  gap: "1rem"
                }}
              >
                BOM
                {/* <ReplyIcon sx={{
                  transform: "rotate(180deg)"
                }} /> */}

              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Table>
                <TableBody>
                  {isShowBOM===false && BOMDetails &&
                    BOMDetails?.length > 0 ?
                    Object.keys(BOMDetails[0]).map((item: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "20vw",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {item}{" "}
                              {BOMDetails[0][item][0]?.data_type === "int" && (
                                <EqualizerIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    graphModal.open({
                                      module_type: "Bom",
                                      name: item,
                                    })
                                  }
                                />
                              )}
                            </Box>
                          </TableCell>
                          {BOMDetails[0][item]?.map((data: any, index: any) => {
                            return (
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  borderLeft: '1px solid ',
                                  borderLeftColor: 'primary.light',
                                  width: '20vw'
                                }}
                                key={index}
                              >
                                {data?.param_value}
                              </TableCell>
                            );
                          })}
                           {getEmptyBOMData(4-productsName?.length)}
                         
                        </TableRow>
                      );
                    }) :
                   ( isShowBOM  ?<Box sx={{ marginLeft: '6rem', marginRight: '6ren' }}>
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                    </Box>:<Typography sx={{textAlign:"center",width:'100%'}}>{"Oops! You do not have permission to BOM module for this project"}</Typography>)
                    }
                    {/* {getEmptyBOMData(4-productsName?.length)} */}
                </TableBody>
              </Table>
              <Box sx={{ margin: "1rem 0" }}></Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginBottom: "1rem" }}>
            <Box
              sx={{
                height: "auto",
                width: "2rem",
                backgroundColor: "primary.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1.2",
                minHeight:'10rem',
              }}
            >
              <Typography
                sx={{
                  transform: "rotate(270deg)",
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Costing
                {/* <ReplyIcon sx={{
                  transform: "rotate(180deg)"
                }} /> */}
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Table>
                <TableBody>
                  {!isShowCosting && CostingDetails &&
                    CostingDetails?.length > 0 ?
                    Object.keys(CostingDetails[0])?.map(
                      (item: any, index: any) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                width: "20vw",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item}
                                <EqualizerIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    graphModal.open({
                                      module_type: "Costing",
                                      name: item,
                                    })
                                  }
                                />
                              </Box>{" "}
                            </TableCell>
                            {CostingDetails[0][item]?.map(
                              (data: any, index: any) => {
                                return (
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      borderLeft: '1px solid ',
                                      borderLeftColor: 'primary.light',
                                      width: '20vw'
                                    }}
                                    key={index}
                                  >
                                    {data?.param_value}
                                  </TableCell>
                                );
                              }
                            )}
                            {getEmptyBOMData(4-productsName?.length)}
                          </TableRow>
                        );
                      }
                    ) :
                    (isShowCosting  ?<Box sx={{ marginLeft: '6rem', marginRight: '6ren' }}>
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                      <Skeleton animation="pulse" />
                    </Box>:<Typography sx={{textAlign:"center",width:'100%'}}>{"Oops! You do not have permission to Costing module for this project"}</Typography>)}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
export default CompareCombination;
