import React, { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styles from "../CostingCreateSystemTable.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { CurrencyChange } from "../../../ViewModule/CostingView/CurrencyChange";
import { DialogComponent } from "../../../utlis/DialogComponent";
import CostingScenarioCreateSystemTableRows from "./CostingScenarioCreateSystemTableRows";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const colors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "red",
  "pink",
  "#2d969b",
  "#fa913c",
  "#ff4164",
  "#64505a",
  "#9b5a64",
  "#376ea5",
  "#c86469",
  "#5f7896",
  "#e1b496",
  "#a0a5a0",
  "#e1e6e6",
  "#6e6437",
  "#2d2d1e",
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
interface Params {
  projectId: any;
  projectName: any;
  expandTable: any;
  initialScenarioId: any;
  scenarioIds: any;
  topVaultId: any;
}

interface ICostingScenarioCreateSystemTableProps {}

const CostingScenarioCreateSystemTable: React.FC<
  ICostingScenarioCreateSystemTableProps
> = (props) => {
  const {
    projectId,
    projectName,
    scenarioIds,
    expandTable,
    initialScenarioId,
    topVaultId,
  } = useRouteParams<Params>();
  const [totalCostProduct, setTotalCostProduct] = useState<any>([]);
  const [totalCostCalculationData, setTotalCostCalculationData] = useState<any>(
    []
  );
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [openStatisticsModel, setOpenStatisticsModel] = React.useState(false);
  const [partInfo, setPartInfo] = React.useState<any>({});
  const [statisticsData, setStatisticsData] = React.useState<any>([]);
  const [subsystemName, setSubsystemName] = React.useState<any>("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [count, setCount] = React.useState<any>(0);
  const [paginationProduct, setPaginationProduct] = useState<any>();
  const [
    totalCostWithoutCalculationDataLoader,
    setTotalCostWithoutCalculationDataLoader,
  ] = useState<boolean>(false);
  const [totalCostCalculationDataLoader, setTotalCostCalculationDataLoader] =
    useState<boolean>(false);
  const [totalCostProductLevelLoader, setTotalCostProductLevelLoader] =
    useState<boolean>(false);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const openDownload = Boolean(anchorEl);

  const openStatusContent = useBaseModal();
  const currencyModal = useBaseModal();
  const history = useHistory();

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "costing_create" },
      0
    ).then((res: any) => {
      sessionStorage.setItem("costingtable", res.data[0]?.current_currency);
      sessionStorage.setItem(
        "actionkey_costingtable",
        res.data[0]?.is_manual ? "mannual" : "auto"
      );
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",
          res.data[0]?.conversion_factor
        );
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",
          (1 / res.data[0]?.conversion_factor).toString()
        );
      }
    });
  }, [count]);

  useEffect(() => {
    setTotalCostCalculationDataLoader(true);
    setTotalCostWithoutCalculationDataLoader(true);
    setTotalCostProductLevelLoader(true);

    // API.get(`/cost/cost_info_total_count_copy`, { project: projectId, scenario: 0, top_vault: scenarioIds }, 0)
    //   .then((res: AxiosResponse) => {
    //     setTotalCostProduct(res.data);
    //   })
    //   .catch((err: AxiosError) => {
    //     console.log(err, "error");
    //   });

    API.get(
      `/cost/scenario_product/`,
      { top_vault: topVaultId, total_cost: true, scenario_list: scenarioIds },
      0
    )
      .then((res: AxiosResponse) => {
        setTotalCostProduct(res.data);
        setTotalCostProductLevelLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    // API.get(`/cost/cost_info_calculation/`, { project: projectId, scenario: 0, top_vault: scenarioIds }, 0)
    //   .then((res: AxiosResponse) => {
    //     setTotalCostCalculationData(res.data);
    //     setTotalCostCalculationDataLoader(false);
    //   })
    //   .catch((err: AxiosError) => {
    //     console.log(err, "error");
    //   });

    API.get(
      `/cost/scenario_system_info/`,
      { top_vault: topVaultId, total_cost: true, scenario_list: scenarioIds },
      0
    )
      .then((res: AxiosResponse) => {
        setTotalCostCalculationData(res.data);
        setTotalCostCalculationDataLoader(false);
        setTotalCostWithoutCalculationDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    API.get(
      `/cost/costing_create_ui/`,
      {
        get_costing_scenario: topVaultId,
        start_scenario: initialScenarioId,
        series: scenarioIds,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setPaginationProduct(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [projectId, scenarioIds, count]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
    setPartInfo({});
  };

  const handleTooltipOpen = (partIndoData: any) => {
    setOpenTooltip(true);
    setPartInfo(partIndoData);
  };

  const handleStatisticsClose = () => {
    setOpenStatisticsModel(false);
    setSubsystemName("");
    setStatisticsData([]);
  };

  const handleStaticticsOpen = (subSystem: any, type: any) => {
    setStatisticsDataLoader(true);
    API.get(
      `/cost/scenario_graph/`,
      { top_vault: topVaultId, total_cost: true },
      0
    )
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setSubsystemName(type);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
      y + height
    } ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const redirectPaginationPush = (type: any) => {
    if (type == "prev") {
      history.push(
        `/costingscenariosystemlanding/${projectId}/${projectName}/${topVaultId}/${initialScenarioId}/${
          paginationProduct &&
          paginationProduct?.[0]?.previous_index?.toString()
        }/${expandTable}`
      );
    } else {
      history.push(
        `/costingscenariosystemlanding/${projectId}/${projectName}/${topVaultId}/${initialScenarioId}/${
          paginationProduct && paginationProduct?.[0]?.next_index?.toString()
        }/${expandTable}`
      );
    }
  };

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          top_vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          top_vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  return (
    <>
      {currencyModal.isOpen && (
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />
      )}

      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}

      <Dialog
        open={openTooltip}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            // maxWidth: '80vw',
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "24rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Parts Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleTooltipClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
              Width: "100%",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Total Parts
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.total_parts}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Validated
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.validated}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Completed
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.completed}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                In-progress
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.in_progress}
              </TableCell>
            </TableRow>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openStatisticsModel}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            maxWidth: "95vw",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "95vw" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Statistics ( {subsystemName} )
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleStatisticsClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />
          <Box
            sx={{
              padding: "1rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              height: "80vh",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ margin: "1rem", textAlign: "center", fontWeight: "bold" }}
              >
                Cost
              </Typography>
              <Box
                sx={
                  subsystemName != "Product"
                    ? {
                        margin: "0rem",
                        width: "45vw",
                        height: "55vh",
                        padding: "1rem 1rem",
                      }
                    : {
                        margin: "0rem",
                        width: "90vw",
                        height: "55vh",
                        padding: "1rem 1rem",
                      }
                }
              >
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={statisticsData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 25,
                      bottom: 55,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      angle={statisticsData.length > 4 ? -25 : 0}
                      textAnchor={statisticsData.length > 4 ? "end" : "middle"}
                    />
                    <YAxis>
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "100%",
                          fontWeight: "600",
                        }}
                        angle={270}
                        dx={subsystemName == "Product" ? -46 : -28}
                        value={`Cost ( ${sessionStorage.getItem(
                          "costingtable"
                        )} )`}
                      />
                    </YAxis>
                    <Bar
                      dataKey="cost"
                      fill="#8884d8"
                      shape={<TriangleBar />}
                      label={{ position: "top" }}
                    >
                      {statisticsData?.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </div>
            {subsystemName != "Product" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    margin: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Share
                </Typography>
                <Box
                  sx={{
                    margin: "0rem",
                    width: "45vw",
                    height: "55vh",
                    padding: "1rem 1rem",
                  }}
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={statisticsData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        interval={0}
                        angle={statisticsData.length > 4 ? -25 : 0}
                        textAnchor={
                          statisticsData.length > 4 ? "end" : "middle"
                        }
                      />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={subsystemName == "Product" ? -46 : -28}
                          value={"Share ( % )"}
                        />
                      </YAxis>
                      <Bar
                        dataKey="share"
                        fill="#8884d8"
                        shape={<TriangleBar />}
                        label={{ position: "top" }}
                      >
                        {statisticsData?.map((entry: any, index: any) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % 20]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {totalCostWithoutCalculationDataLoader
        ? (String(scenarioIds)?.includes(",")
            ? [...scenarioIds?.split(","), topVaultId]
            : [scenarioIds, topVaultId]
          )?.map((item: any) => (
            <span style={{ width: "90%", margin: "0 auto" }}>
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
            </span>
          ))
        : totalCostProduct?.map((item: any, index: any) => {
            return (
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  borderRightColor: "#ddefff",
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: 0,
                    zIndex: 3,
                  }}
                  key={index + 1}
                >
                  <TableRow>
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                          position: "sticky",
                          left: "0",
                          zIndex: 3,
                        }}
                        rowSpan={2}
                      >
                        <Button
                          variant="contained"
                          className={styles.downloadBtn}
                          size="small"
                          endIcon={<ArrowDropDownIcon />}
                          style={{
                            fontSize: "12px",
                            marginRight: "1rem",
                            minWidth: "3rem",
                          }}
                          onClick={() => currencyModal.open()}
                        >
                          {sessionStorage.getItem("costingtable")}
                        </Button>
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        backgroundColor: "primary.light",
                      }}
                      colSpan={5}
                    >
                      <span
                        style={
                          index == 0
                            ? {
                                display: "flex",
                                columnGap: "0.5rem",
                                justifyContent: "space-between",
                              }
                            : index == totalCostProduct?.length - 1
                            ? {
                                display: "flex",
                                columnGap: "0.5rem",
                                justifyContent: "space-between",
                              }
                            : {
                                display: "flex",
                                columnGap: "0.5rem",
                                justifyContent: "center",
                              }
                        }
                      >
                        {index == 0 && (
                          <LoadingButton
                            sx={{ p: 0 }}
                            disabled={
                              !(
                                paginationProduct &&
                                paginationProduct?.[0]?.previous
                              )
                            }
                            onClick={() => redirectPaginationPush("prev")}
                            title="Previous Vehicle"
                          >
                            <ChevronLeftIcon />
                          </LoadingButton>
                        )}
                        {index == totalCostProduct?.length - 1 && <span></span>}
                        <span
                          style={
                            index == 0
                              ? { marginRight: "4rem" }
                              : index == totalCostProduct?.length - 1
                              ? { marginLeft: "0rem" }
                              : {}
                          }
                          title={item?.name}
                        >{`${item?.name}`}</span>
                        {index == 0 && <span></span>}
                        {index == totalCostProduct?.length - 1 && (
                          <LoadingButton
                            sx={{ p: 0, minWidth: "3rem" }}
                            disabled={
                              !(
                                paginationProduct &&
                                paginationProduct?.[0]?.next
                              )
                            }
                            onClick={() => redirectPaginationPush("next")}
                            title="Next Vehicle"
                          >
                            <ChevronRightIcon />
                          </LoadingButton>
                        )}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          whiteSpace: "nowrap",
                          backgroundColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        Count
                      </TableCell>
                    )}
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          whiteSpace: "nowrap",
                          backgroundColor: "primary.light",
                          textAlign: "right",
                        }}
                      >
                        Weight (Kg)
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "right",
                      }}
                    >
                      Cost ({sessionStorage.getItem("costingtable")})
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "right",
                      }}
                    >
                      Share
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        width: "5rem",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    style={{
                      height: "3rem",
                      position: "sticky",
                      top: "4.3rem",
                      backgroundColor: "#c4efb3 ",
                      zIndex: 3,
                    }}
                  >
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          fontWeight: "bold",
                          position: "sticky",
                          left: "0",
                          zIndex: 3,
                          backgroundColor: "#c4efb3",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            columnGap: "0.5rem",
                          }}
                        >
                          Product{" "}
                          <LoadingButton
                            loading={statisticsDataLoader}
                            sx={{
                              p: 0,
                              minWidth: "1rem",
                              ".MuiCircularProgress-root": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <LeaderboardIcon
                              titleAccess="Statistics"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleStaticticsOpen(item?.name, "Product")
                              }
                            />
                          </LoadingButton>
                        </span>
                      </TableCell>
                    )}
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        {totalCostProductLevelLoader ? (
                          <Skeleton />
                        ) : (
                          item?.total_count
                        )}
                      </TableCell>
                    )}
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "right",
                        }}
                      >
                        {totalCostProductLevelLoader ? (
                          <Skeleton />
                        ) : (
                          item?.total_weight?.toFixed(2)
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "right",
                      }}
                    >
                      {totalCostProductLevelLoader ? (
                        <Skeleton />
                      ) : (
                        item?.total_cost?.toFixed(3)
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "right",
                      }}
                    >
                      {totalCostProductLevelLoader ? <Skeleton /> : "100%"}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          columnGap: "0.5rem",
                        }}
                      >
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            openDownload ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openDownload ? "true" : undefined}
                          disableElevation
                          onClick={handleClick}
                          style={{ padding: "0rem", minWidth: "1rem" }}
                          title="Download"
                        >
                          <CloudDownloadIcon sx={{ cursor: "pointer" }} />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={openDownload}
                          onClose={handleClose}                          
                        >
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                            }}
                            onClick={() =>
                              handleRedirect("w", item?.top_vault_id)
                            }
                          >
                            Consolidated with images
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                            }}
                            onClick={() =>
                              handleRedirect("w/o", item?.top_vault_id)
                            }
                          >
                            Consolidated w/o images
                          </MenuItem>
                        </StyledMenu>
                      </span>
                    </TableCell>
                  </TableRow>
                  {totalCostCalculationData &&
                    totalCostCalculationData?.map(
                      (internalData: any, indexInternal: number) => {
                        return totalCostCalculationDataLoader ? (
                          <CostingScenarioCreateSystemTableRows
                            key={indexInternal + 1}
                            item={item}
                            index={index}
                            setHoveredIndex={setHoveredIndex}
                            internalData={internalData}
                            indexInternal={indexInternal}
                            hoveredIndex={hoveredIndex}
                            handleTooltipOpen={handleTooltipOpen}
                            openStatusContent={openStatusContent}
                            setContentText={setContentText}
                            setIconText={setIconText}
                            setStatisticsData={setStatisticsData}
                            setSubsystemName={setSubsystemName}
                            setOpenStatisticsModel={setOpenStatisticsModel}
                            totalCostCalculationDataLoader={
                              totalCostCalculationDataLoader
                            }
                          />
                        ) : (
                          <CostingScenarioCreateSystemTableRows
                            key={indexInternal + 1}
                            item={item}
                            index={index}
                            setHoveredIndex={setHoveredIndex}
                            internalData={internalData}
                            indexInternal={indexInternal}
                            hoveredIndex={hoveredIndex}
                            handleTooltipOpen={handleTooltipOpen}
                            openStatusContent={openStatusContent}
                            setContentText={setContentText}
                            setIconText={setIconText}
                            setStatisticsData={setStatisticsData}
                            setSubsystemName={setSubsystemName}
                            setOpenStatisticsModel={setOpenStatisticsModel}
                          />
                        );
                      }
                    )}
                </TableBody>
              </Table>
            );
          })}
    </>
  );
};

export default CostingScenarioCreateSystemTable;
