import * as React from "react";
import { Box, Button, ButtonProps, IconButton } from "@mui/material";
import { ScanandVerifyModal } from "./ScanandVerifyModal";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { CheckSystemWeightandVerifyModal } from "./CheckSystemWeightandVerifyModal";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import AddEditDocumentModal from "./AddEditDocumentModal";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';


export interface IDispatchDocumentationProps { }
const csvOptions: GridCsvExportOptions = {
  fileName: "Documents data In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "90vw", xl: "80vw" },
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
export function DispatchDocumentation(props: IDispatchDocumentationProps) {
  const ScanModal = useBaseModal();
  const { projectId } = useRouteParams<any>();
  const CheckSystem = useBaseModal();
  const addDocument = useBaseParamsModal();
  const [documentData, setDocumentData] = React.useState<any>([]);
  const [tableDataLoading, setTableDataLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
  const scanandverifyHandler = () => {
    ScanModal.open();
  };
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  const getDocumentsData = () => {
    setTableDataLoading(true);
    API.get(
      "/xcpep/dispatch/",
      {
        get_outward_documentation: projectId,
      },
      0
    )
      .then((res: any) => {
        setTableDataLoading(false);
        setDocumentData(res.data);
      })
      .catch((err: any) => {
        setTableDataLoading(false);
      });
  };
  React.useEffect(() => {
    getDocumentsData();
  }, [projectId]);

  const checksystemandweightHandler = () => {
    CheckSystem.open();
  };

  const COLUMNS = [
    { field: "sno", headerName: "S.No", width: width > 1370 ? 50 : width < 900 ? 50 : 50, headerClassName: "super-app-theme--header" },

    {
      field: "description",
      headerName: "Descriptions",
      minWidth: width > 1370 ? 200 : 200,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Document",
      minWidth: width > 1370 ? 220 : 220,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "path",
      headerName: "Document Type",
      sortable: false,
      minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 120,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues: any) => {
        return (
          <>
            <a onClick={() => handleOpenLinkReference(cellValues?.row?.path)}>{cellValues.row?.type}</a>
          </>
        );
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle",
      minWidth: width > 1370 ? 300 : width < 1200 ? 250 : 250,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "expiry_date",
      headerName: "Transfer Date",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 120 : width < 1200 ? 90 : 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      width: width > 1370 ? 100 : width < 1200 ? 100 : 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            {
              <IconButton
                title="Delete"
                sx={{ padding: "0", cursor: "pointer", color: "primary.main" }}
                onClick={() => {
                  Swal.fire({
                    title: "Delete document",
                    text: "Are you sure you want to delete ?",
                    icon: "error",
                    confirmButtonText: "Yes, delete it!",
                    showCancelButton: true,
                  }).then((confirm) => {
                    if (confirm) {
                      API.delete(`/drive/file/${cellValues.row.id}/`, {}, 0)
                        .then((res: any) => {
                          getDocumentsData();
                        })
                        .catch((err: any) => { });
                    }
                  });
                }}>
                <DeleteIcon />
              </IconButton>
            }
            <EditIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => addDocument.open(cellValues.row)}
            />
          </>
        );
      },
    },
  ];
  let rows: any = [];
  let dataGridDataGenerator: any =
    documentData &&
    documentData?.map((item: any, index: any) => {
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        description: item?.description,
        name: item?.name,
        type: item?.type,
        path: item?.path,
        vehicle: item?.vehicle,
        expiry_date: item?.expiry_date,
        vehicle_name: item?.vehicle_name,
      };
      rows.push(objShared);
    });

  let dataStructureDataGrid: any = {
    columns: COLUMNS,
    rows: rows && rows,
  };

  return (
    <div>
      <Box sx={{ padding: '0 1rem', marginBottom: '-1.5rem' }}>
        <Button
          variant="contained"
          startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
          sx={{ lineHeight: 1,padding:'0.3rem 1rem',zIndex:1 }}
          onClick={() => addDocument.open("add")}>
          Add Document
        </Button>
      </Box>
      {ScanModal.isOpen && (
        <ScanandVerifyModal
          onCloseModal={ScanModal.close}
          isOpen={ScanModal.open}
        />
      )}

      {addDocument.isOpen && (
        <AddEditDocumentModal
          isOpen={addDocument.isOpen}
          onCloseModal={addDocument.close}
          actions={addDocument.propsId}
          getDocumentsData={getDocumentsData}
        />
      )}

      {CheckSystem.isOpen && (
        <CheckSystemWeightandVerifyModal
          onCloseModal={CheckSystem.close}
          isOpen={CheckSystem.open}
        />
      )}
      <Box
        sx={{ display: "flex", alignItems: "flex-start", height: { lg: "86vh", xl: "90vh" }, width: { lg: '76vw', xl: '81vw' }, }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <DataGrid
          headerHeight={42}
          rowHeight={36}
          {...dataStructureDataGrid}
          loading={tableDataLoading}
          components={{
            Toolbar: () => {
              return CustomToolbar();
            },
          }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "#045DE9 !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
          }}
        />
        {/* </Box> */}
      </Box>
    </div>
  );
}
