import { useRouteMatch } from 'react-router-dom';
import styles from './FromError.module.scss';

interface ErrorProps {
    error?: any; //string
    align?: "start" | "center" | "end";
}

const FormError = ( props: ErrorProps ) => {
    const { error, align = "start" } = props;
    const { url } = useRouteMatch();
    return (
        <>
            {
                error && (
                    <span
                  
                        className={ styles.errorMsg }
                        style={ { maxHeight:url?.includes("bomgen/de/")?"5rem": "3rem", fontSize: '1.1rem', marginBottom: url?.includes("bomgen/de/")?"0rem":'1rem' } }
                        title={ error }
                    >
                        {/* <WarningIcon color="warning" mr-2 /> */}
                        { error }
                    </span>
                )
            }
        </>
    );
};

export default FormError;
