import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import successIcon from "../../Assets/images/success.png";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import swal from "sweetalert";
import { getArchImages } from '../../Redux/Actions/architecture.action';
import { useAppDispatch } from '../../../AppRouter';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
interface ArchProductlevelDeleteModalProps {
  onCloseModal: any;
  isOpen: any;
  setselectedType: any;
  setRecycleBinCounter: any;
  userpermission:any,

  Item?: any;

}

const ArchProductlevelDeleteModal = (props: ArchProductlevelDeleteModalProps) => {
  const { onCloseModal, isOpen, Item, setselectedType, setRecycleBinCounter,userpermission } = props
  const [ProductList, setProductList] = useState<any>([])
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
    groupName,
  } = useRouteParams<any>();

  const history=useHistory<any>()
  const [loader, setLoader] = useState(false)


  const dispatch = useAppDispatch()
  useEffect(() => {

    API.get(
      "/circuit_diagram/arch_download/",
      {
        project: projectId,
        group_id: groupId,
        categories: 3,
        abbreviation: abbr,
        download_image_count: true,
      },
      0
    ).then((res: any) => {
      setProductList(res.data)
    })
  }, [isOpen])

  const Deletehandler = (id: any) => {
    swal({
      title: "Delete Diagrams",
      text: "Are you sure you want to delete this diagrams?",
      buttons: ["Cancel", "Yes"],

      icon: "error",
    }).then((confirm) => {
      if (confirm) {

        setLoader(true)
        API.delete(`/circuit_diagram/archs/`, {
          top_vault: id?.toString(),
          categories: 3,
          abbreviation: abbr,
          group: groupId,
        }, 0)
          .then((res: any) => {
            dispatch(
              getArchImages({
                project: projectId,

                abbreviation: abbr,

                page: pageNumber,
                col_page: productpage,
                group_id: groupId,
              })
            );

            API.get(`/circuit_diagram/arch_groups/`, {
              project: projectId,
              abbreviation: abbr,
            }).then((res:any)=>{
              var FilterData=res?.data?.filter((item:any,index:any)=>{return item?.id==groupId})[0]
              history.push(
                `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${productpage}/${groupName}/${FilterData?.image_count}`
            );
            })
            Swal.fire({
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
            <br />
            <p style="color:#007fff;">Diagrams deleted successfully </p>   
             </div>`,
            });
            onCloseModal()
            setselectedType((prev: any) => prev + 1)
            setRecycleBinCounter((prev: any) => prev + 1)
            setLoader(false)
          })
          .catch((err: any) => {
            setLoader(false)
            console.log("Sever Error");
          });
      }
    });


  }


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Architecture   Delete
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            {loader ?
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
              :
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Product Name</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Diagrams Count</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    ProductList && ProductList?.map((item: any, index: any) => {
                      var filterPermission=userpermission && userpermission?.filter((permissionItem:any,index1:any)=>{return (permissionItem?.action!==null && permissionItem?.top_vault!=="all")  && permissionItem?.top_vault==item?.top_vault_id })[0]?.action

                      return (<>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.name}</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.count}</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '5rem' }}>
                            {item?.count !== 0 &&

                              
                              <LoadingButton disabled={filterPermission &&filterPermission?.includes("D")?false:true} title='Delete' sx={{ cursor: "pointer", minWidth: '1rem' }} onClick={() => Deletehandler(item?.top_vault_id)} endIcon={<DeleteIcon sx={{ marginLeft: '-1rem' }} />} />
                            }
                          </TableCell>
                        </TableRow>
                      </>)
                    })
                  }
                </TableBody>
              </Table>
            }
          </Box>
        </Box>
      </Modal>

    </div>
  )
}
export default ArchProductlevelDeleteModal;