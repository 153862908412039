// vendors
import {
  createEntityAdapter,
  createReducer,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import {
  resetImageRepository,
} from "../Actions/imageRepository.actions";
import { RootState } from "../Store/storeConfigurations";
import { getArchImages } from "../Actions/architecture.action";

// -----------------------------------------------------------------------------------
// helpers

// const rearrangeImages = ( state: IArchitectureReducer, sequenceObject: Record<string, number> ) => {
//     const updates: Update<ArchImage>[] = [];
//     for ( let [id, sequence] of Object.entries( sequenceObject ) ) {
//         updates.push( { id: +id, changes: { sequence } } );
//     }
//     adaptor.updateMany( state, updates );
// };

// -----------------------------------------------------------------------------------
// entity adaptor

export interface ArchImage {
  id: ID;

  abbreviation: any;

  allowed_action: any;

  count: any;

  images: any;

  part_no: any;

  product_name: any;

  top_vault: any;

  vault: any;

  vault_status: any;
}

const adaptor = createEntityAdapter<ArchImage>({
   selectId: (ArchImage) => ArchImage.id,
//   sortComparer: (a, b) => a.images?.sequence - b.images?.sequence,
});

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IArchitectureReducer = EntityState<ArchImage> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IArchitectureReducer = Object.assign(
  {},
  adaptor.getInitialState(),
  additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const ArchitectureReducer = createReducer(initialState, (builder) => {
  // get
  builder
    .addCase(getArchImages.fulfilled, (state, action: any) => {

     
      adaptor.setAll(state, action.payload);
    })
    .addCase(getArchImages.rejected, (_state, action) => {
      // ADMIN.toast.throwError( action.payload, "Could not fetch media" );
    });

  // reset
  builder.addCase(resetImageRepository, () => initialState);
});

// -----------------------------------------------------------------------------------
// selectors
const selectors = adaptor.getSelectors<RootState>( ( state ) => state.archimageRepository );

// const selectImagesBySequence = createSelector(
//     selectors.selectAll,
//     ( _state: RootState, sequence: number ) => sequence,
//     ( _state: RootState, _sequence: number, group: any ) => group,
//     ( _state: RootState, _sequence: number, _group: any,imageCategory:any ) => imageCategory,
//     ( repoImages, sequence, group,imageCategory ) => {
//         return repoImages
//             .filter( repoImage => repoImage?.image?.sequence === sequence && repoImage.group == group  && repoImage.image_category == imageCategory);
//     }
// );

// const selectArchImagesBySequence = createSelector(
//     selectors.selectAll,
//     ( _state: RootState, sequence: number ) => sequence,
//     ( _state: RootState, _sequence: number, group: any ) => group,
//     ( repoImages, sequence, group ) => {
//         return repoImages
//             .filter( repoImage => repoImage.sequence === sequence && repoImage.group == group && repoImage.image_category == null );
//     }
// );

const selectByTopVaultId = createSelector(
  selectors.selectAll,
  (_state: RootState, topVaultId: ID) => topVaultId,
  (repoImages, topVaultId) => {
    return repoImages.filter((repoImage) => repoImage.top_vault === topVaultId);
  }
);
const selectAllImages = createSelector(
  selectors.selectAll,
  (_state: RootState, topVaultId: any) => topVaultId,
  (repoImages, topVaultId) => {
    return repoImages;
  }
);

const selectIdsByTopVaultId = createSelector(selectByTopVaultId, (repoImages) =>
  repoImages.map(adaptor.selectId)
);

// const selectMaxCount = createSelector(
//     selectors.selectAll,
//     ( repoImages ) => {

//         const countArray = repoImages.map( repoImage => repoImage.sequence ),
//             maxCount = max( countArray );
//         /** 1 is added as sequence starts from 0 */

//         return isUndefined( maxCount ) ? 0 : maxCount + 1;
//     }
// );

// const selectCurrentCount = createSelector(
//     selectors.selectAll,
//     ( _state: RootState, size: number ) => size,
//     ( _state: RootState, size: number, pageNumber: number ) => pageNumber,
//     ( repoImages, pageNumber, size ) => {
//         const countArray = repoImages.map( repoImage => repoImage.sequence - ( ( pageNumber - 1 ) * size ) ),
//             maxCount = max( countArray );
//         /** 1 is added as sequence starts from 0 */

//         return isUndefined( maxCount ) ? 0 : maxCount + 1;
//     }
// );

// const selectBySequence = createSelector(
//     selectors.selectAll,
//     ( _state: RootState, topVaultId: ID ) => topVaultId,
//     ( _state: RootState, _topVaultId: ID, sequence: number ) => sequence,
//     ( _state: RootState, __topVaultId: ID, _sequence: number, group: any ) => group,
//     ( _state: RootState, __topVaultId: ID, _sequence: number, _group: any,imageCategory:any ) => imageCategory,
//     ( repoImages, topVaultId, sequence, group,imageCategory ) => {
//         return repoImages.find( repoImage => {
//             return repoImage.top_vault === topVaultId && repoImage.sequence === sequence && repoImage.group === group && repoImage.image_category === imageCategory
//         } );
//     }
// );

// const selectByArchSequence = createSelector(
//     selectors.selectAll,
//     ( _state: RootState, topVaultId: ID ) => topVaultId,
//     ( _state: RootState, _topVaultId: ID, sequence: number ) => sequence,
//     ( _state: RootState, __topVaultId: ID, _sequence: number, group: any ) => group,
//     ( repoImages, topVaultId, sequence, group ) => {
//         return repoImages.find( repoImage => {
//             return repoImage.top_vault === topVaultId && repoImage.sequence === sequence && repoImage.group === group && repoImage.image_category == null
//         } );
//     }
// );

const ArchRepositorySelectors = Object.assign(
  {},
  { },
  selectors
);

// -----------------------------------------------------------------------------------
// exports

export {
  ArchitectureReducer,
  initialState as archimageRepositoryInit,
  ArchRepositorySelectors,
  selectAllImages,
};
