import { Autocomplete, FormControl, TextField } from "@mui/material";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { LoadingButton } from "@mui/lab";
// import IdeaPCBExpandPopupModal from "./IdeaTabPCBExpandModal";
import { API } from "../../../../../api-services";
import { useBaseParamsModal } from "../../../../../CustomHook/useBaseParamsModal";
import { ADMIN } from "../../../../../Redux/Services/admin.service";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import IdeaTabPCBExpandPopupModal from "../IdeaTabPCBExpandPopupModal";
import { UserAction } from "../../IdeaGenerationViewLanding";
import { rejectedStatus } from "../../IdeaViewpage";

export interface IIdeaTabTableOptionsInputProps {
    expandStatus: any;
    tableName: any;
    item: any;
    getTableData?: any;
    dataForUpdate?: any;
    tableData?: any;
    setTableData?: any;
    dataForCheck?: any;
    setSelectedOptionsTable?: any;
    setColorButton?: any;
    colorButton?: any;
    setSelectedTableId?: any;
    selectedTableId?: any;
    getTableDataAll?: any;
    tableDataLoader?: any;
    setShowButton: any;
    showButton: any;
    // BOMValidationStatus?: any;
}


export default function IdeaTabTableOptionsInput (props: IIdeaTabTableOptionsInputProps) {
    const {
        tableName,
        item,
        getTableData,
        dataForUpdate,
        tableData,
        setTableData,
        dataForCheck,
        setSelectedOptionsTable,
        expandStatus,
        setColorButton,
        colorButton,
        setSelectedTableId,
        selectedTableId,
        getTableDataAll,
        tableDataLoader,
        setShowButton,
        showButton,
        // BOMValidationStatus
      } = props;
      const userAction = React.useContext<any>(UserAction);
      let UserActionParams = userAction && userAction?.action;
      const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
      const { topVault, vault, workstation, copyStatus,vault_id } = useRouteParams<any>();
      const [tableOptions, setTableOptions] = React.useState<any>([]);
      const [allData, setAllData] = React.useState<any>([]);
      const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
      const [isSave, setIsSave] = React.useState<any>(false);
      const [isAdding, setIsAdding] = React.useState<any>(false);

      const getObjKeyName = () => {
        if (vault == 0) {
          return { top_vault: topVault };
        } else {
          return { vault: vault };
        }
      };
    
      React.useEffect(() => {
        if(vault_id !=0){
        API.get(
          "/idea/idea_create_view/",
          {
            top_vault:topVault,
            table: true,
            id:vault_id
          },
          0
        )
          .then((res: any) => {
            setAllData(res.data);
            // setAllData(res.data.filter((itm:any)=>itm.key===item)[0]?.data)
          })
          .catch((err: any) => {});
        }
      }, [vault_id]);
      const getTableOPtions = (tableId: any) => {
        API.get(
          "/idea/idea_create_view/",
          {
            top_vault:topVault,
            table: true,
            table_id: tableId,
            unseleted_params: true,
            id:vault_id
          },
          0
        )
          .then((res: any) => {
            setTableOptions(res.data);
          })
          .catch((err: any) => {});
      };
      React.useEffect(() => {
        if (item && vault_id !=0) {
          getTableOPtions(item);
        }
      }, [item,vault_id]);
    
      const removeDuplicateKeys = (object: any) => {
        const newObject: any = {};
    
        for (const key in object) {
          if (!newObject.hasOwnProperty(key)) {
            newObject[key] = object[key];
          }
        }
    
        return newObject;
      };
    
      React.useEffect(() => {
        var obj: any = {};
        const dataHeaderOld = tableData
          .filter((itm: any) => itm.key === item)[0]
          .header_item?.map((d: any) => d.key);
        const addedKey = selectedOptions?.map((ii: any) => (obj[ii?.key] = ""));
        if (Object.keys(obj)?.length > 0) {
          if (
            tableData?.filter((itm: any) => itm.key === item)[0]?.data?.length > 0
          ) {
            tableData
              ?.filter((itm: any) => itm.key === item)[0]
              ?.data?.map((part: any) => {
                let temp = removeDuplicateKeys({ ...part, ...obj });
                Object.keys(temp)?.map((i: any) => {
                  part[i] = part[i] || "";
                });
              });
          } else {
            tableData?.filter((itm: any) => itm.key === item)[0]?.data?.push(obj);
          }
          setTableData([...tableData]);
        }
      }, [selectedOptions]);
    
      const handleSelectChangecategory = (event: any, newSystemValue: any) => {
        setShowButton(false);
        // setColorButton("red");
        setSelectedTableId(item);
        setSelectedOptionsTable(newSystemValue);
        setSelectedOptions(newSystemValue);
        if (newSystemValue.length > 0) {
          const dataHeaderOld = tableData.filter((itm: any) => itm.key === item)[0]
            .header_item;
          const dataBodyOld = tableData.filter((itm: any) => itm.key === item)[0]
            .data;
          const dataHeaderAll = allData.filter((itm: any) => itm.key === item)[0]
            ?.header_item;
          const dataBodyNewAll = allData.filter((itm: any) => itm.key === item)[0]
            ?.data;
          const newDataHeader = [...dataHeaderOld, ...newSystemValue];
          const set = new Set(newDataHeader);
          const arr = Array.from(set);
          tableData.filter((itm: any) => itm.key === item)[0].header_item = arr;
          const finalSet = new Set(
            tableData.filter((itm: any) => itm.key === item)[0].header_item
          );
          const finalArr = Array.from(finalSet);
    
          setTableData([...tableData]);
        } else {
          getTableData();
        }
      };
      const IdeaPCBModal = useBaseParamsModal();
      const IdeaPCBModalHandler = () => {
        IdeaPCBModal.open();
      };
      const AddParams = () => {
        setIsAdding(true);
        // if (
        //   (copyStatus == false || copyStatus == "false") &&
        //   (UserAction?.includes("U") || UserAction?.includes("C"))
        // ) {
          if (!showButton) {
            API.get(
              "/idea/idea_create_view/",
              {
                id: vault_id && vault_id,
                table: true,
                top_vault:topVault,
              },
              0
            )
              .then((res: any) => {
                let data: any = res.data?.filter((itm: any) => itm.key === item)[0]
                  ?.data;
                let bodyObj: any = {};
                res.data
                  ?.filter((itm: any) => itm.key === item)[0]
                  ?.header_item?.map((itmm: any) => (bodyObj[itmm.key] = ""));
                let obj: any = {};
    
                Object.keys(data[0])?.map((itm: any) => {
                  obj[itm] = "";
                });
                API.put(
                  `/idea/pool/${vault_id}/`,
                  {...{ parameter_json:{[`${item}`]: [...data, bodyObj]} }},
                  {
                    top_vault:topVault
                  },
                  0
                )
                  .then((res: any) => {
                    setSelectedTableId(item);
                    ADMIN.toast.info("Row added successfully");
    
                    getTableData();
                    setIsAdding(false);
                    getTableOPtions(item);
                    getTableDataAll();
                    // BOMValidationStatus();
                  })
                  .catch((err: any) => {
                    setIsAdding(false);
                  });
              })
              .catch((err: any) => {
                setIsAdding(false);
              });
          } else {
            API.put(
              `/idea/pool/${vault_id}/`,
              { ...{parameter_json:{[`${item}`]: [...dataForUpdate]} }},
              {
                top_vault:topVault
              },
              0
            )
              .then((res: any) => {
                Swal.fire({
                  icon: "success",
                  html: `<div>
                      <br />
                      <p style="color:"green">saved Successfully</p>   
                       </div>`,
                  customClass: {
                    container: "swal2Container",
                  },
                });
                API.get(
                  "/idea/idea_create_view/",
                  {
                    table: true,
                    id:vault_id && vault_id,
                    top_vault:topVault,
                  },
                  0
                )
                  .then((res: any) => {
                    let data: any = res.data?.filter(
                      (itm: any) => itm.key === item
                    )[0]?.data;
                    let bodyObj: any = {};
                    res.data
                      ?.filter((itm: any) => itm.key === item)[0]
                      ?.header_item?.map((itmm: any) => (bodyObj[itmm.key] = ""));
                    let obj: any = {};
    
                    Object.keys(data[0])?.map((itm: any) => {
                      obj[itm] = "";
                    });
                    API.put(
                      `/idea/pool/${vault_id}/`,
                      { ...{parameter_json:{[`${item}`]: [...data, bodyObj]}} },
                      {                        
                        top_vault:topVault
                      },
                      0
                    )
                      .then((res: any) => {
                        setSelectedTableId(item);
                        ADMIN.toast.info("Row added successfully");
    
                        setIsAdding(false);
                        getTableOPtions(item);
                        getTableDataAll();
                      })
                      .catch((err: any) => {
                        setIsAdding(false);
                      });
                  })
                  .catch((err: any) => {
                    setIsAdding(false);
                  });
                getTableData();
                getTableDataAll();
                getTableOPtions(item);
                setSelectedOptions([]);
                setColorButton("");
                setShowButton(false);
                setIsSave(false);
              })
              .catch((err: any) => {
                setIsSave(false);
                const { data } = err.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
                  <br />
                  <p style="color:"green">${data[0]}</p>   
                   </div>`,
                  customClass: {
                    container: "swal2Container",
                  },
                });
              });
          }
        
      };

  return (
    <div>
        {IdeaPCBModal.isOpen && (
        <IdeaTabPCBExpandPopupModal
          isOpen={IdeaPCBModal.isOpen}
          onCloseModal={IdeaPCBModal.close}
          expandStatus={false}
          idddd={item}
          tableName={tableName}
          getTableData={getTableData}
          dataForUpdate={dataForUpdate}
          tableData={tableData}
          setTableData={setTableData}
          dataForCheck={item?.header_item}
          setSelectedOptionsTable={setSelectedOptions}
          setColorButton={setColorButton}
          colorButton={colorButton}
          setSelectedTableId={setSelectedTableId}
          selectedTableId={selectedTableId}
          setShowButton={setShowButton}      />
      )}
     
            
     {dataForCheck && dataForCheck?.length > 0 && (  <LoadingButton
                sx={{
                  padding: "0.2rem 0.5rem",
                  lineHeight: 1,
                  minWidth: "2rem",
                  marginRight:'0.5rem',
                  cursor: "pointer",
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                loading={isAdding}
                disabled={(!rejectedStatusActionParams &&UserActionParams?.includes("U")) ? false : true}
                startIcon={
                  <AddIcon titleAccess="Add" sx={{ marginRight: "-1rem" }} />
                }
                onClick={() => AddParams()}
              ></LoadingButton>)}
            
          
        
      <FormControl sx={{ width: "20rem" }}>
        <Autocomplete
          id="tags-standard"
          disableClearable
          disableCloseOnSelect
          multiple          
          limitTags={1}
          sx={{
            ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
              {
                color: "primary.main",
              },
            ".MuiButtonBase-root.MuiChip-root": {
              backgroundColor: "primary.light",
              height: "26px",
            },
          }}
          options={tableOptions && tableOptions}
          disabled={(!rejectedStatusActionParams &&UserActionParams?.includes("U")) ? false : true}
          onPaste={(event: any) => {
            //   PasteEmailHandler(event.clipboardData.getData('text'))
          }}
          getOptionLabel={(option) =>
            // @ts-ignore
            option?.paramter_name == undefined ? "" : option?.paramter_name
          }
          value={selectedOptions}
          onChange={(event: any, newSystemValue: any) => {
            handleSelectChangecategory(event, newSystemValue);
          }}
          // getOptionDisabled={getOptionDisabled}
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: "0.87rem" }}>
              {option.paramter_name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select Material"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "0.87rem !important",
                },
                ".MuiSvgIcon-root": { color: "primary.main" },
              }}
            />
          )}
        />
      </FormControl>
      
          <LoadingButton
            sx={{
              padding: "0.2rem 0.5rem",
              lineHeight: 1,
              minWidth: "2rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isSave}
            disabled={!showButton || !rejectedStatusActionParams && !UserActionParams?.includes("U")}
            startIcon={
              <SaveIcon
              
                titleAccess="Save"
                sx={{
                  cursor: "pointer",
                  color: isSave
                    ? ""
                    : selectedTableId == item
                    ? colorButton
                    : "",
                  marginRight: "-1rem",
                }}
              />
            }
            onClick={() => {
              setIsSave(true);
              API.put(
                `/idea/pool/${vault_id}/`,
                { ...{parameter_json:{[`${item}`]: [...dataForUpdate]}} },
                {
                  top_vault:topVault,
                },
                0
              )
                .then((res: any) => {
                  ADMIN.toast.info("Saved successfully");
                  getTableData();
                  getTableDataAll();
                  getTableOPtions(item);
                  setSelectedOptions([]);                  
                  setColorButton("");
                  setShowButton(false);
                  setIsSave(false);
                })
                .catch((err: any) => {
                  setIsSave(false);
                  if(err.response){
                  const { data } = err.response;
                  Swal.fire({
                    icon: "error",
                    html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
                    customClass: {
                      container: "swal2Container",
                    },
                  });
                }
                });
            }}
          ></LoadingButton>
       
      {expandStatus == false ? (
        ""
      ) : (
        <ZoomOutMapIcon
          titleAccess="Expand PCB Table"
          sx={{ cursor: "pointer" }}
          onClick={() => IdeaPCBModalHandler()}
        />
      )}
    </div>
  );
}
