import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import swal from "sweetalert";
import {
  Avatar,
  Box,
  Divider,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import {
  RoleList,
  RoleListAll,
  RoleListIdea,
} from "../../Constants/projectspecificmodules.constants";
import { AUTH } from "../../Redux/Services/auth.service";
import { USER } from "../../Redux/Services/user.service";
import { useState } from "react";
import useFetch from "../../Core/CustomHooks/useFetch";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { EditRole } from "./EditRole";
import { EditProduct } from "./EditProduct";
import { useSelector } from "react-redux";
import AddAllModulePermissionModal from "./AddAllModulePermissionModal";
import { useBaseModal } from "../SearchPage/useBaseModal";

export interface IPermissionModuleAccordionProps {
  modules?: any;
  setCount?: any;
  isModuleSuccess?: any;
}
// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={
//       <ArrowForwardIosSharpIcon
//         sx={{ fontSize: "0.9rem", color: "primary.main" }}
//       />
//     }
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, .05)"
//       : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   color: "primay.main",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//     color: "primay.main",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: "1px solid rgba(0, 0, 0, .125)",
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export function PermissionModuleAccordion(
  props: IPermissionModuleAccordionProps
) {
  const { modules, setCount, isModuleSuccess } = props;
  
  const { projectId, moduleId, filterOPtion, routeType } =
    useRouteParams<any>();
  const history = useHistory();
  const addModal=useBaseModal()
  const store: any = useSelector((state) => state);
  const [users, setUsers] = React.useState<any>([]);
  const [module, setModule] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false>(
    moduleId == 0 ? false : moduleId
  );
  const [userList, setUserList] = React.useState<any>([]);
  const [productList, setProductList] = React.useState<any>([]);
  const [products, setProducts] = React.useState<any>([]);
  const [roles, setRoles] = React.useState<any>([]);
  const [permissionList, setPermissionList] = React.useState<any>([]);
  const [permissionApiCallCount, setPermissionApiCallCount] =
    React.useState<any>(0);
  const [isPermissionListLoading, setIsPermissionListLoading] =
    React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState('');
    const [selectedModule, setSelectedModule] = React.useState('');
  React.useEffect(() => {
    setCount((prev: any) => prev + 1);
  }, [expanded]);

  React.useEffect(() => {
    if (modules?.length > 0 && moduleId == 0) {
      // setExpanded(modules[0]?.module);
      setSelectedTab(modules[0]?.module)
      history.push(
        `/user-permissions/${projectId}/${modules[0]?.module}/${filterOPtion}/${routeType}`
      );
    } else {      
      history.push(
        `/user-permissions/${projectId}/${moduleId}/${filterOPtion}/${routeType}`
      );
    }
  }, [modules]);
  // const handleModuleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     history.push(
  //       `/user-permissions/${projectId}/${panel}/${filterOPtion}/${routeType}`
  //     );
  //   };

  const handleModuleChange = (selectedmodule: any) => {
      setSelectedTab(selectedmodule);
      history.push(
        `/user-permissions/${projectId}/${selectedmodule}/${filterOPtion}/${routeType}`
      );
    };

    React.useEffect(()=>{
      handleModuleChange(moduleId)      
      // setSelectedTab(moduleId)
    },[permissionApiCallCount])

  const getUserList = () => {
    API.get(
      "/auth/user/",
      {
        is_active: true,
        is_superuser: false,
        sub_module: moduleId,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setUserList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const getProductList = () => {
    API.get("/xcpep/product_list/", { project: projectId }, 0)
      .then((res: any) => {
        setProductList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getUserList();    
  }, [permissionApiCallCount, moduleId]);
  React.useEffect(() => {
    getProductList();
  }, [projectId]);

  // React.useEffect(() => {
  //   if (moduleId === "Project Specific Modules") {
  //     // setExpanded(false);
  //     // setSelectedTab(moduleId);
  //   }
  // }, [moduleId]);

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setUsers(value);
  };

  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setModule(value);
  };

  const handleSelectChangeProduct = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setProducts(value);
  };

  // console.log(moduleId,"moduleIdmoduleId")
  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };
  React.useEffect(() => {
    setUsers([]);
    setRoles([]);
    setProducts([]);
    setModule([]); 
    getPermissionsData();  
  }, [projectId, moduleId]);

  const handleSubmit = () => {
    API.post(
      "/auth/assign_permission/",
      {
        user_list: users,
        submodule_list: 
          moduleId === "Project Specific Modules" ? module : [moduleId],
        products: products,
        action: roles,
      },
      {},
      0
    )
      .then((res: any) => {
        setPermissionApiCallCount((prev: any) => prev + 1);
        setCount((prev: any) => prev + 1);
        setUsers([]);
        setRoles([]);
        setProducts([]);
        setModule([]);
      })
      .catch((err: any) => {
        console.log("permission error: " + err);
      });
  };
  const getPermissionsData = () => {
    setIsPermissionListLoading(true);
    API.get(
      "/auth/assign_permission/",
      {
        submodule: moduleId,
        project: projectId,
        user_type: filterOPtion,
        action:
          store?.is_compare?.permission_filter?.length > 0
            ? store?.is_compare?.permission_filter?.toString()
            : undefined,
        user_id:
          typeof store?.is_compare?.selected_userId !== "string"
            ? store?.is_compare?.selected_userId
            : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionList(res.data);
        setIsPermissionListLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsPermissionListLoading(false);
      });
  };
  // console.log(moduleId,"moduleIdmoduleId");
  React.useEffect(() => {
    if (moduleId !== "0") {
      getPermissionsData();
    }
  }, [
    // moduleId,
    selectedTab,
    permissionApiCallCount,
    // expanded,
    // selectedTab,
    store?.is_compare?.permission_filter,
    filterOPtion,
    store?.is_compare?.selected_userId 
  ]);

  const [reloadUserData, setReloadUserData] = useState<boolean>(true);

  const { 0: load, 1: userData } = useFetch({
    method: USER.getUsers,
    args: [AUTH.user],
    fallBackErrMsg: "Could not fetch the user Details",
    dependencies: [reloadUserData],
    condition: AUTH.user != null,
  });

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");
    const FirstLatter = first?.charAt(0);
    const lastWord = last?.charAt(0);
    return `${FirstLatter}${lastWord}`;
  };

  return (
    <Box sx={{width:'100%',display:'flex',alignItems:'flex-start',paddingRight:{lg:'0.5rem',xl:''}}}>
         {addModal.isOpen && (
        <AddAllModulePermissionModal
          isOpen={addModal.isOpen}
          onCloseModal={addModal.close}
          setPermissionApiCallCount={setPermissionApiCallCount}
          setCount={setCount}
          permissionApiCallCount={permissionApiCallCount}
          moduleId={selectedModule}
        />
      )}
      {!isModuleSuccess ? (<Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection:'column',
          columnGap: '0.5rem',
          width: {lg:'21%',xl:'17%'},
          maxHeight:{lg:'54vh',xl:"66vh"},
          // borderRight: '1px solid',
          rowGap:'1px',
        }} adl-scrollbar='true'
        adl-scrollbar-width='0.2'>          
            {modules?.map((item: any, index: number) => {
              return (<>  
              <Box
											key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
                        item?.module == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
                          item?.module == selectedTab ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													whiteSpace: 'nowrap',
													alignItems: 'center',
													textOverflow: 'ellipsis',
                          overflow: 'hidden',
													textTransform: 'capitalize',
												}}
												onClick={() => handleModuleChange(item?.module)}>
												{item?.module}{' '}                         
											</Typography>
                                                   
                      <Box
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          columnGap: "0.5rem",
                          padding: '0 0.5rem',
                          width: "7rem",
                          color:item?.module == selectedTab ? 'white': 'primary.main'
                        }}
                      >
                         <Typography sx={{ fontSize: "1rem",display:'flex',alignItems:'center',marginLeft:'5px' }} onClick={() => handleModuleChange(item?.module)}>
                         (Ext: 
                              <Typography sx={{ fontSize: "1rem",width:'1.6rem',textAlign:'right',marginRight:'0.5rem', }}>
                                {item.external},
                                </Typography>
                                Int: 
                                <Typography sx={{ fontSize: "1rem",width:'1.6rem',textAlign:'right' }}>
                                  {item?.internal})
                                </Typography>
                          </Typography>                          
                        <AddIcon
                        titleAccess="Add All Module Permission"
                        sx={{ cursor: "pointer", color:item?.module == selectedTab ? 'white': 'primary.main' }}
                        onClick={() => {
                          // event.stopPropagation();
                          setSelectedModule(item?.module);
                          addModal.open();
                        }}
                      />
                        <CloseIcon
                        titleAccess="Delete All Module Permission"
                          sx={{ color: "red", cursor: "pointer" }}
                        
                          onClick={(event: any) => {
                            event.stopPropagation();
                            swal({
                              title: "Are you sure?",
                              text: "All user permissions will be deleted from this module.",
                              // icon: "warning",
                              buttons: ["No", "Yes"],
                            }).then((isData: any) => {
                              if (isData) {
                            API.delete(
                                "/auth/assign_permission/",
                                { submodule: item?.module, project: projectId },
                                0
                              )
                                .then((res: any) => {
                                  setPermissionApiCallCount(
                                    (prev: any) => prev + 1
                                  );
                                  setCount((prev: any) => prev + 1);
                                })
                                .catch((err: any) => {
                                  console.log(err);
                                });
                              }
                            }).catch((e: any) => {
                            });
                          }}
                        />
                      </Box>
                       
										</Box>        
                
                </> );
            })}
      </Box>)
          : (
            <Box sx={{width:{lg:'21%',xl:'17%'},padding:'0 0.5rem'}}>
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
            </Box>
          )}
      <Divider orientation="vertical" variant="middle" flexItem  sx={{borderColor: "primary.main", margin: "0 0.5rem",}} />
      {!isPermissionListLoading ? (
      <Box
                        sx={{ maxHeight: {lg:'54vh',xl:"66vh"},marginBottom:'0.5rem',width:{lg:'80%',xl:'82%'}}}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        {permissionList?.length > 0 ? (<Table>
                          <TableBody>                          
                            {permissionList?.map((item: any, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: "2vw",
                                    }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: "2vw",
                                    }}
                                  >
                                    {item && item.image ? (
                                      <Image
                                        alt="UserImg"
                                        placeholderImg={LoadingImage}
                                        src={item?.image}
                                        style={{
                                          width: "2rem",
                                          height: "2rem",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    ) : (
                                      <Avatar
                                        src="/broken-image.jpg"
                                        sx={{
                                          width: { xs: "2rem", sm: "2rem" },
                                          height: { xs: "2rem", sm: "2rem" },
                                          fontSize: "1rem !important",
                                          objectFit: "cover",
                                          backgroundColor: "#007fff !important",
                                          textTransform: "uppercase",
                                          " .MuiAvatar-root.MuiAvatar-circular": {
                                            backgroundColor: "primary.main",
                                          },
                                        }}
                                      >
                                        {AvatarName(item?.fullname)}
                                      </Avatar>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: "10vw",
                                      textAlign: "left",
                                    }}
                                  >
                                    <Typography sx={{ fontSize: "1rem",lineHeight:1.2 }}>
                                      {item?.fullname}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    width: "20vw",
                                  }}
                                >
                                  {item?.submodule}
                                </TableCell> */}
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: "24vw",
                                      verticalAlign:'bottom',
                                    }}
                                  >
                                    <EditProduct
                                      productList={productList && productList}
                                      selectedProducts={item?.product}
                                      itemId={item.user}
                                    />
                                    {/* {item?.product_name} */}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: {lg:"12vw",xl:'10vw'},
                                      verticalAlign:'bottom',
                                    }}
                                  >
                                    <EditRole
                                      action={item.actions}
                                      itemId={item.user}
                                      roleOptions={ moduleId === "BOM-Create" ||
                                        moduleId === "Product Inward" || moduleId === "Product Outward" ||  moduleId === "Media-Create"|| moduleId === "Costing-Create" ||  moduleId === "Idea-Create" || moduleId=== "Architecture-Create"||moduleId=== "Feature-Create" || moduleId === 'Costing-Scenario'
                                          ? RoleListAll : moduleId === "Idea-View" ? RoleListIdea
                                          : RoleList
                                      }
                                    />
                                    {/* {item?.actions} */}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width: "2vw",
                                      textAlign: "right",
                                      // paddingRight: {lg:'',xl:"1rem"},
                                    }}
                                  >
                                    <CloseIcon
                                      titleAccess="Remove Permission"
                                      sx={{ color: "red", cursor: "pointer" }}
                                      onClick={() => {
                                        API.delete(
                                          "/auth/assign_permission/",
                                          {
                                            user_id: item.user,
                                            submodule: moduleId,
                                            project: projectId,
                                          },
                                          0
                                        ).then((res: any) => {
                                          setPermissionApiCallCount(
                                            (prev: any) => prev + 1
                                          );
                                          setCount((prev: any) => prev + 1);
                                        });
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>) : (                              
                                  <Box
                                    sx={{
                                      fontSize: "1rem",
                                      color: "primary.main",
                                      fontWeight: "bold",
                                      height:{lg:'67vh',xl:"76vh"},
                                      width:'100%',
                                      display:'flex',
                                      alignItems:'center',
                                      justifyContent:'center',
                                    }}
                                  >
                                    No Data Found
                                  </Box>
                               
                            )}
                      </Box>) : (
                    <Box
                      sx={{ height:'100%',width:'80%' }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Box
                        sx={{
                          display: "flex",                      
                          alignItems:'center',
                          columnGap:'1rem',
                          margin: "0.4rem 0",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width="2rem"
                          height="2rem"
                          sx={{ margin:{lg:'0 2rem',xl:'0 3rem'} }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%" 
                          sx={{marginRight:'1rem'}}                    
                        />                   
                      </Box>
                      <Box
                        sx={{
                          display: "flex",                      
                          alignItems:'center',
                          columnGap:'1rem',
                          margin: "0.4rem 0",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width="2rem"
                          height="2rem"
                          sx={{ margin:{lg:'0 2rem',xl:'0 3rem'} }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%" 
                          sx={{marginRight:'1rem'}}                    
                        />                   
                      </Box>
                      <Box
                        sx={{
                          display: "flex",                      
                          alignItems:'center',
                          columnGap:'1rem',
                          margin: "0.4rem 0",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width="2rem"
                          height="2rem"
                          sx={{ margin:{lg:'0 2rem',xl:'0 3rem'} }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%" 
                          sx={{marginRight:'1rem'}}                    
                        />                   
                      </Box>
                      <Box
                        sx={{
                          display: "flex",                      
                          alignItems:'center',
                          columnGap:'1rem',
                          margin: "0.4rem 0",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width="2rem"
                          height="2rem"
                          sx={{ margin:{lg:'0 2rem',xl:'0 3rem'} }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%" 
                          sx={{marginRight:'1rem'}}                    
                        />                   
                      </Box>
                      <Box
                        sx={{
                          display: "flex",                      
                          alignItems:'center',
                          columnGap:'1rem',
                          margin: "0.4rem 0",
                          width: "100%",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width="2rem"
                          height="2rem"
                          sx={{ margin:{lg:'0 2rem',xl:'0 3rem'} }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="100%" 
                          sx={{marginRight:'1rem'}}                    
                        />                   
                      </Box>
                    </Box>
                  )}
    </Box>
  );
}
