import { Box, Skeleton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import CancelIcon from "@mui/icons-material/Cancel";
const Plot = createPlotlyComponent(Plotly);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 'auto',
  minWidth:'25%',
  minHeight:'25%',
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: '10px',
  // p: 4,
};

export interface FeatureModalProp {
  isOpen: any;
  onCloseModal: any;
  GraphData: any;
  graphLoader: any;
}

const FeatureModal = (prop: FeatureModalProp) => {
  const { isOpen, onCloseModal, GraphData, graphLoader } = prop;

  const [BarGraphs, setBarGraphs] = useState<any>([]);

  useEffect(() => {
    setBarGraphs(GraphData);
  }, [GraphData]);

  // @ts-ignore

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}

        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {graphLoader ? (
            // <BouncingLoader />
            <Skeleton />
          ) : (
            <>
              <Box sx={{width:'100%',textAlign:'right'}} className="selected-parameters-plot">
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                <div className="plotgraph" style={{ fontWeight: 600 }}>
                  <Plot
                    // layout={ el.graphLayout }

                    data={
                      BarGraphs &&
                      BarGraphs?.map((values: any) => {
                        return {
                          x: [values?.x[0]],
                          y: [values?.y[0]],
                          type: `${values?.type}`,
                          text: `${(values?.y)
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                          textposition: "auto",

                          hoverinfo: "name",
                          showlegend: true,
                          name: values?.name,
                        };
                      })
                    }
                    layout={{
                      xaxis: {
                        visible: true,
                        showticklabels: false,
                        fixedrange: true,
                        title: {
                          text: BarGraphs && BarGraphs[0]?.x[0],
                        },
                      },
                      yaxis: { fixedrange: true },
                      height: 350,
                      width: 900,
                      margin: {
                        l: 40,
                        t: 20,
                        r: 20,
                        b: 30,
                        pad: 0,
                      },
                      font: {
                        // @ts-ignore
                        size: "10",
                      },
                      showlegend: true,
                      // @ts-ignore
                      legend: { orientation: "t" },
                    }}
                    config={{
                      modeBarButtonsToRemove: [
                        "zoom2d",
                        "pan2d",
                        "select2d",
                        "lasso2d",
                        "zoomIn2d",
                        "zoomOut2d",
                        "autoScale2d",
                        "resetScale2d",
                        "hoverClosestGl2d",
                        "hoverClosestPie",
                        "toggleHover",
                        "resetViews",
                        "sendDataToCloud",
                        //   "resetViewMapbox",
                        "hoverClosestCartesian",
                        "hoverCompareCartesian",
                        "toggleSpikelines",
                      ],
                      displaylogo: false,
                    }}
                  />
                </div>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default FeatureModal;
