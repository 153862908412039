import * as React from 'react';
import { Box, CircularProgress, TextField, debounce } from '@mui/material';
import { useSelector } from 'react-redux';
import { useRouteParams } from '../../../../../ui-reusable-component/useRouteParams';
import { customValidationSelectors } from '../../../../../Redux/Reducers/customValidation.reducer';
import { parameterConfigSelectors } from '../../../../../Redux/Reducers/parameterConfig.reducer';
import { RootState } from '../../../../../Redux/Store/storeConfigurations';
import { workstationSelectors } from '../../../../../Redux/Reducers/workstation.reducer';
import { ID } from '../../../../../utlis/commonInterfaces';
import validateValues from '../../../../BOMCreate/validateValues';
import { customValidate } from '../../../../../utlis/CustomValidate';
import { API } from '../../../../../api-services';
import { ADMIN } from '../../../../../Redux/Services/admin.service';
import FormError from '../../../../../Core/FromError';
import { UserAction } from '../../IdeaGenerationViewLanding';
import { rejectedStatus } from '../../IdeaViewpage';
// import { customValidationSelectors } from '../../../../Redux/Reducers/customValidation.reducer';
// import { parameterConfigSelectors } from '../../../../Redux/Reducers/parameterConfig.reducer';
// import { workstationSelectors } from '../../../../Redux/Reducers/workstation.reducer';
// import { RootState } from '../../../../Redux/Store/storeConfigurations';
// import validateValues from '../validateValues';
// import { ID } from '../../../../utlis/commonInterfaces';
// import { customValidate } from '../../../../utlis/CustomValidate';
// import FormError from '../../../../Core/FromError';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
// import { API } from '../../../../api-services';
// import { ADMIN } from '../../../../Redux/Services/admin.service';
// import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
// import validateValues from '../../../BOMCreate/validateValues';


export interface IIdeaTabTextInputBoxProps {
    item: any,
    details: any,
    // BOMValidationStatus?: any;
    allValues: any,
    getAllValues: any
}

export default function IdeaTabTextInputBox (props: IIdeaTabTextInputBoxProps) {
    const { item, details, allValues,getAllValues } = props;
  const { vault, workstation, topVault, copyStatus,vault_id } = useRouteParams<any>();
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
  const [value, setValue] = React.useState<any>('');
  const [oldValue,setOldValue] = React.useState<any>('');
  const [loading, setLoading] = React.useState<any>(false)
  const [fieldError, setFieldError] = React.useState<any>(undefined);
  const customValidations = useSelector(customValidationSelectors.selectAll);
  const parameterConfigs = useSelector(
    parameterConfigSelectors.selectNonImageNonTableChildernConfigs
  );

  const workstationList = useSelector((state: RootState) => {
    return workstationSelectors.selectById(state, +workstation || 0);
  });


  const getObj = (vlt: any) => {
    if (vlt == 0) {
      return { top_vault: topVault }
    } else {
      return { vault: vlt }
    }
  }
 


  const configToDbMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.parameter_id;
    return result;
  }, {} as Record<string, ID>)

  const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.data_type;
    return result;
  }, {} as Record<string, string>);
  const categoryIncludedInWorkstion = workstationList
    ? workstationList.categories.includes(1)
    : false;

  const allInputData = parameterConfigs.filter(data => data.data_type !== 'table').filter(data => data.data_type !== 'document');
  const allowedParameters = categoryIncludedInWorkstion
    ? workstationList?.allowed_parameters
    : undefined;

  var errors = validateValues(
    allInputData,
    { [item?.key]: value },
    allowedParameters,
    "parameter_id"
  );
  React.useEffect(() => {
    setValue(item?.value)
    setOldValue(item?.value)
  }, [item])

  const validateField = (parameter_id: ID, value?: any) => {
    const val = value ?? { [item?.key]: value }[parameter_id];
    let error: string | undefined;
    const config: any = parameterConfigs.find(
      config => config.parameter_id === parameter_id
    );

    if (config)
      error = validateValues([config], { [parameter_id]: val })[
        parameter_id
      ];

    // custom validations
    const relation = customValidations?.find(
      relation => relation.parameter_id === parameter_id
    );

    allValues[item?.key] = value

    if (relation)
      error = customValidate(
        relation.parameter_id,
        configToDataTypeMap,
        relation.validation,
        undefined,
        allValues,
        details,
        configToDbMap
      );
    setFieldError(error!);
  };

  const updateDataVaultParams = async (name: any, val: any, vlt: any) => {
    setLoading(true)
    API.put(`/idea/pool/${vault_id}/`, {
      ...{parameter_json:{[name]: val}}
    }, {
      ...getObj(vlt),
      module:15
    }, 0).then((res: any) => {
      ADMIN.toast.info("Updated Successfully")
      setOldValue(val)
      setLoading(false)
      // BOMValidationStatus();
      ADMIN.toast.info("Updated Successfully")
    }).catch((err: any) => {
      // const { data } = err.response;
      setLoading(false)
     
    })
  }

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any) => {
      await updateDataVaultParams(name, value, idd);
    }, 1000)
  ).current;

  const handleChangeNumber = (event: any) => {
    const { name, value } = event.target;
    validateField(item?.key, value);
    // ExactDebounceParameters(value, name, vault);
    setValue(value);

  }


  return (
    <div>
        <Box sx={{ display: 'flex' }}>
        <TextField
          variant="standard"
          name={item?.key}
          disabled={(!rejectedStatusActionParams && UserActionParams?.includes("U") && item?.editable)? false : true}
          // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
          type="text"
          onChange={handleChangeNumber}
          value={value}
          placeholder="Enter Value"
          sx={{
            width:'100%',
            ".MuiInputBase-root.MuiInput-root": {
              fontSize: "12px",
              backgroundColor:'rgba(255,255,255,0.3)',
              paddingLeft:'0.3rem' ,
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "transparent",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.light",
            },
          }}
          onBlur={(event: any) => {
            if(event.target.value !== oldValue){
            ExactDebounceParameters(event.target.value, event.target.name, vault)
            }
          }}
        />
        {loading && <CircularProgress size={20} sx={{ margin: '5px' }} />}
      </Box>
      {fieldError && (
        <FormError error={fieldError} align="center" />
      )}
      
    </div>
  );
}
