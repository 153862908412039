import * as React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import dispatchcode from '../Assets/images/QRCodedispatch.png';
import verifyweight from '../Assets/images/Verifyweight.svg';
import downarrowfill from '../Assets/images/downarrowfill.svg';
import downarrowoutline from '../Assets/images/downarrowoutline.svg';
import { ScanandVerifyModal } from './ScanandVerifyModal';
import { useBaseModal } from '../ComponentUI/SearchPage/useBaseModal';
import { CheckSystemWeightandVerifyModal } from './CheckSystemWeightandVerifyModal';
import { DispatchVerification } from './DispatchVerification';
import { DispatchDocumentation } from './DispatchDocumentation';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API } from '../api-services';


export interface IDispatchpageProps { }

export function Dispatchpage(props: IDispatchpageProps) {
  const ScanModal = useBaseModal();
  const CheckSystem = useBaseModal();
  const ScannerModalOpen = useBaseModal();
  const [counter, setCounter] = React.useState<any>(0);
  const [isActive, setIsActive] = useState(true);
  const history = useHistory();
  const { projectId, projectname, productId } = useParams<any>();
  const [projectName, setProjectName] = useState<any>(projectname);
  const [validation, setValidation] = React.useState<any>({});
  const getValidations = () => {
    API.get("/xcpep/dispatch/", { project_dispatch_validation: projectId }, 0)
      .then((res: any) => {
        setValidation(res.data[0]);
      })
      .catch((err: any) => { });
  };
  const scanandverifyHandler = () => {
    ScanModal.open();
  }

  React.useEffect(() => {
    getValidations();
  }, [projectId]);

  const checksystemandweightHandler = () => {
    CheckSystem.open();
  };

  const switchHandler = () => {
    setIsActive(!isActive);
  };

  const dispatchmailHandler = () => {
    if (validation?.email) {
      history.push(`/dispatchemail/${projectId}/${projectName}/${false}/0`);
    }
  };
  const OpenScannerModal = () => {
    ScannerModalOpen.open();
  };

  return (
    <div>
      {ScanModal.isOpen && (
        <ScanandVerifyModal
          onCloseModal={ScanModal.close}
          isOpen={ScanModal.open}
        />
      )}

      {CheckSystem.isOpen && (
        <CheckSystemWeightandVerifyModal
          onCloseModal={CheckSystem.close}
          isOpen={CheckSystem.open}
          setCounter={setCounter}
        />
      )}

      <Box sx={{ display: "flex", alignItems: "flex-start", width: { xs: '80vw', sm: "97vw" } }}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{isActive ? <DispatchVerification counter={counter} /> : <DispatchDocumentation />}</Box>
        <Box
          sx={{ width: { lg: "20vw", xl: "15vw" }, padding: "0 1rem", height: {xs:'87vh', lg: "86vh", xl: "90vh" }, textAlign: "center" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "primary.main",
              color: "white",
              borderRadius: "3rem",
              padding: "0.3rem 1rem",
              border: "1px solid",
              cursor: 'pointer',
              height: '2.5rem',
            }}
            onClick={() => {
              switchHandler();
            }}>
            <Typography sx={{ fontSize: "1rem", cursor: "pointer" }}>Verification</Typography>
            {Object.keys(validation)?.length > 0 && (
              <Checkbox
                style={{ padding: "5px" }}
                checkedIcon={
                  <Box sx={{ backgroundColor: "white", borderRadius: "50%", height: "1rem", width: "1rem" }}>
                    <VerifiedIcon sx={{ color: "#39FF14", position: "relative", margin: "-0.35rem -0.25rem 0" }} />
                  </Box>
                }
                checked={validation?.is_verified}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              />
            )}
          </Box>
          <img
            src={downarrowfill}
            style={{ height: "2rem" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: isActive ? "white" : "primary.main",
              color: isActive ? "primary.main" : "white",
              borderRadius: "3rem",
              padding: "0.3rem 1rem",
              border: "1px solid",
              cursor: "pointer",
              height: '2.5rem',
            }}
            onClick={() => {
              switchHandler();
            }}>
            <Typography sx={{ fontSize: "1rem", cursor: "pointer" }}>Documentation</Typography>
            {Object.keys(validation)?.length > 0 && validation?.documentation && (
              <Checkbox
                style={{ padding: "5px" }}
                checkedIcon={
                  <Box sx={{ backgroundColor: "white", borderRadius: "50%", height: "1rem", width: "1rem" }}>
                    <VerifiedIcon sx={{ color: "#39FF14", position: "relative", margin: "-0.35rem -0.25rem 0" }} />
                  </Box>
                }
                checked={validation?.documentation}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              />
            )}
          </Box>
          {!isActive ? (
            <img
              src={downarrowfill}
              style={{ height: "2rem" }}
            />
          ) : (
            <img
              src={downarrowoutline}
              style={{ height: "2rem" }}
            />
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              color: "primary.main",
              borderRadius: "3rem",
              padding: "0.3rem 1rem",
              border: "1px solid",
              height: '2.5rem',
              marginBottom: "2.5rem",
              cursor: validation?.email ? "pointer" : "not-allowed"
            }}
            onClick={() => {
              dispatchmailHandler();
            }}>
            <Typography sx={{ fontSize: "1rem" }}>Email</Typography>
            {Object.keys(validation)?.length > 0 && validation?.email && (
              <Checkbox
                style={{ padding: "5px" }}
                checkedIcon={
                  <Box sx={{ backgroundColor: "white", borderRadius: "50%", height: "1rem", width: "1rem" }}>
                    <VerifiedIcon sx={{ color: "#39FF14", position: "relative", margin: "-0.35rem -0.25rem 0" }} />
                  </Box>
                }
                checked={validation?.email}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              borderRadius: "2rem",
              padding: "1rem",
              marginBottom: "2.5rem",
              textAlign: "center",
              backgroundColor: "primary.light",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
            }}
            onClick={() => scanandverifyHandler()}>
            <Box sx={{ padding: "1rem", cursor: 'pointer' }}>
              <img
                src={dispatchcode}
                style={{ width: "70%", height: "auto", objectFit: "cover" }}
              />
            </Box>
            <Typography sx={{ fontSize: "1rem", width: "100%", textAlign: "center" }}>Scan & Verify </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              borderRadius: "2rem",
              padding: "1rem",
              marginBottom: "2.5rem",
              textAlign: "center",
              backgroundColor: "primary.light",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
              cursor: 'pointer',
            }}
            onClick={() => checksystemandweightHandler()}>
            <Box sx={{ padding: "1rem" }}>
              <img
                src={verifyweight}
                style={{ width: "70%", height: "auto", objectFit: "cover" }}
              />
            </Box>
            <Typography sx={{ fontSize: "1rem", width: "100%", textAlign: "center" }}>Check System Weight & Verify</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
