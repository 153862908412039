import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, Divider, FormControl, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageIcon from "@mui/icons-material/Image";
import RViewerJS from "viewerjs-react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: '10px',
  p: 1,
};

export interface IMediaImageFetchModelProps {
  onCloseModal: any;
  isOpen: any;
  handleFetchImage?: any;
  loaderForFetch?: any;
}
const PhotoComponent = ({ photo }: any) => {
  const [showInsidePhoto, setShowInsidePhoto] = React.useState(false);
  const [selectedPhoto, setSelectedPhoto] = React.useState<any>();
  const [selectedImage, setSelectedImage] = React.useState<any>("");
  const handleClick = (id: any) => {
    setShowInsidePhoto(!showInsidePhoto);
    if (id !== 0) {
      setSelectedPhoto(id);
    }
  };

  if (showInsidePhoto) {
    return (
      <div style={{ marginLeft: "1rem" }}>
        <div style={{ border: '1px solid #007fff', borderRadius: '10px', padding: '3px' }}>
          <ArrowBackIcon
            onClick={() => {
              handleClick(0);
            }}
            sx={{ cursor: "pointer" }}
          />

          {photo?.is_file && <FolderOpenIcon sx={{ cursor: "pointer", color: "green" }} />}
          {/* @ts-ignore */}
          <RViewerJS>
            <div>
              <Image
                src={photo?.image}
                placeholderImg={LoadingImage}
                title={photo?.image_name}
                style={{ width: "90%", height: "10rem", objectFit: "contain", marginLeft: "1rem" }}
                alt={photo?.image_name}
                key={photo.id}
              />
              <Typography sx={{ fontSize: '1rem' }} align="center">{photo?.image_name}</Typography>
            </div>
          </RViewerJS>
        </div>
        <>
          {/* @ts-ignore */}
          <RViewerJS>
            {photo.data?.length > 0 &&
              [...photo.data, ...photo?.image_file].map((child: any) => (
                // <img src={child.image} key={child.id} />
                <PhotoComponent
                  key={child.id}
                  photo={child}
                />
              ))}
          </RViewerJS>
        </>
      </div>
    );
  } else {
    return (
      <div style={{ border: '1px solid #ddefff', borderRadius: '10px', padding: '3px' }}>
        {photo?.is_file ? (
          <FolderOpenIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleClick(photo?.id);
            }}
          />
        ) : (
          <ImageIcon sx={{ cursor: "pointer" }} />
        )}
        <div>
          <Image
            src={photo?.image}
            placeholderImg={LoadingImage}
            style={{ width: "100%", height: "10rem", objectFit: "contain", backgroundColor: photo?.image === selectedImage ? '#007fff' : '' }}
            alt={photo?.image_name}
            title={photo?.image_name}
            key={photo.id}
          // onClick={() => {
          //   setSelectedImage(photo?.image);
          //   sessionStorage.setItem("selectedfetchMediaimage", photo?.image);
          // }}
          />
          <Typography sx={{ fontSize: '1rem' }} align="center">{photo?.image_name}</Typography>
        </div>
      </div>
    );
  }
};

export function ArchImageRepository(props: IMediaImageFetchModelProps) {
  const { onCloseModal, isOpen, handleFetchImage, loaderForFetch } = props;
  const { projectId } = useRouteParams<any>();
  const Modules = ["Inward", "Architecture", "BOM", "Media", "Features"];
  const [selectedModule, setSelectedModule] = React.useState<any>("");
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [selectedProductDetails, setSelectedProductDetails] = React.useState<any>();
  const [imageData, setImageData] = React.useState<any>([]);
  const [imageDataLoading, setImageDataLoading] = React.useState<any>(false);
  const [allProductList, setAllProductList] = React.useState<any>([]);

  const handleChangeModule = (ed: any, e: any) => {
    const { value } = ed.target;
    if (value != selectedModule) {
      setSelectedProduct([]);
      setImageData([]);
    }
    API.get(
      "/xcpep/product_list/",
      {
        project: projectId,
        module: value,
      },
      0
    )
      .then((res: any) => {
        setAllProductList(res.data);
      })
      .catch((err: any) => { });
    setSelectedModule(value);
  };

  const handleChangeProduct = (ed: any) => {
    const { value } = ed.target;
    setSelectedProduct(value);
    setSelectedProductDetails(allProductList.filter((option: any) => option.id == value));
    let obj = allProductList.filter((option: any) => option.id == value)[0];
    setImageDataLoading(true);
    API.get("/xcpep/fetch_module_image/", { project: projectId, module: selectedModule, id: value, abbreviation: obj?.abbreviation }, 0)
      .then((res: any) => {
        setImageData(res.data);
        setImageDataLoading(false);
      })
      .catch((err: any) => {
        setImageDataLoading(false);
      });
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
                marginRight: "-2rem",
              }}>
              Image Repository
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", padding: '0 1rem', marginBottom: '0.5rem' }}>
              <FormControl variant="standard">
                {/* <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select Module</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedModule}
                  placeholder="Select Module"
                  sx={{
                    minWidth: '15rem',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                  onChange={handleChangeModule}>
                  {Modules?.map((item: any) => {
                    return <MenuItem sx={{ fontSize: '1rem' }} value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl variant="standard">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProduct}
                  placeholder="Select Product/System"
                  sx={{
                    minWidth: '15rem',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                  onChange={handleChangeProduct}>
                  {allProductList?.map((item: any) => {
                    return <MenuItem sx={{ fontSize: '1rem' }} value={item?.id}>{item?.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            {/* @ts-ignore */}
            <RViewerJS>
              <Box sx={{ height: { lg: "72vh", xl: "80vh" } }}>
                {imageDataLoading ?
                  <Box sx={{ width: '100%' }}>
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                    <Skeleton sx={{ height: "3rem", width: "100%" }} />
                  </Box> :
                  <>
                    {imageData?.length == 0 ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit' }}>{selectedModule == "" || selectedProduct?.length == 0 ? selectedModule == "" ? <h2>Please Select Module</h2> : <h2>Please Select Product/System</h2> : <h2>No Images Found</h2>}</Box>
                      : <>

                        <Box
                          sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "1rem", height: { lg: "72vh", xl: "76vh" } }}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3">
                          {imageData &&
                            imageData?.map((item: any) => {
                              return (
                                <Box sx={{ borderRadius: "5px" }}>

                                  <PhotoComponent
                                    key={item.id}
                                    photo={item}
                                  />
                                </Box>
                              );
                            })}

                        </Box>

                        {/* <Box sx={{ width: '100%', textAlign: 'right', padding: '0 1rem' }}>
                <LoadingButton
                  size="small"
                  variant="contained"
                  loading={loaderForFetch}
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1,
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => {
                    let str = sessionStorage.getItem("selectedfetchMediaimage");
                    return handleFetchImage(str?.substring(str?.indexOf(".net") + 4));} 
                  }>
                  Fetch
                </LoadingButton>
              </Box> */}
                      </>}
                  </>}
              </Box>
            </RViewerJS>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
