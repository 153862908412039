import { TextField, TextareaAutosize } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import { API } from "../../api-services";
import { useDispatch } from "react-redux";

export interface ParamsInputTextProps {
  value: any;
  paramsId: any;
  parentId: any;
  placeholderName: any;
  dataType: any;
  condition:any;
}

export function CreateFeatureParamsInput(props: ParamsInputTextProps) {
  const { value, paramsId, parentId, placeholderName, dataType ,condition} = props;
  const dispatch = useDispatch();
  const [paramsValue, setParamsValue] = React.useState<any>("");
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${parentId}/`, { [paramsId]: value }, {}, 0)
        .then((res: any) => {
           
        })
        .catch((err: any) => {});
    }, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  React.useEffect(() => {
    if (value) {
      setParamsValue(value);
    }
  }, [value]);

  const handleonChange = (e: any) => {
    const { value } = e.target;
    setParamsValue(value);
    ExactDebounce(value);
  };
  const getInputBoxbyType = () => {
    switch (dataType) {
      case "string":
        return (
          <TextField
          disabled={condition?.id?false:true}
            placeholder={placeholderName}
            variant="standard"
            sx={{
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
            }}
            value={paramsValue}
            onChange={handleonChange}
          />
        );
      case "textarea":
        return (
          <TextareaAutosize
          disabled={condition?.id?false:true}
            placeholder={placeholderName}
            minRows={3}
            style={{
              border: "1px solid #bbdefb",
              borderRadius: "3px",
              height: "7.25rem",
              maxHeight: "7.25rem",
              width: "100%",
              color: "#007fff",
              fontFamily: "Roboto",
              // ".MuiInputBase-root.MuiInput-root": {
              //   fontSize: "1rem !important",
              // },
              // "& .MuiInput-underline:before": { borderBottomColor: "white" },
              // "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
            }}
            value={paramsValue}
            onChange={handleonChange}
          />
        );
      case "float":
        return (
          <TextField
          disabled={condition?.id?false:true}
            placeholder={placeholderName}
            variant="standard"
            sx={{
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
            }}
            value={paramsValue}
            onChange={handleonChange}
            type="number"
          />
        );
      //   case "singleselect":
      //     return <Select
      //     variant="standard"
      //     sx={{
      //       ".MuiInputBase-root.MuiInput-root": {
      //         fontSize: "1rem !important",
      //       },
      //       "& .MuiInput-underline:before": { borderBottomColor: "white" },
      //       "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
      //     }}
      //     value={paramsValue}
      //     onChange={handleonChange}
      //   />
      default:
        return (
          <TextField
          disabled={condition?.id?false:true}
            placeholder={placeholderName}
            variant="standard"
            sx={{
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
            }}
            value={paramsValue}
            onChange={handleonChange}
          />
        );
    }
  };
  return <div>{getInputBoxbyType()}</div>;
}
