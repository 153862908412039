import { Box, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { API } from '../../../api-services';

export interface ILabourHourRateActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	InfoHandler?: any;
	DeleteHandler?: any;
	GraphHandler?: any;
	redirectionHandler?: any;
	handleShareWith?: any;
	useraction?: any;
	setAnchorEl?: any;
}

export default function LabourHourRateActionComponents(
	props: ILabourHourRateActionComponentsProps
) {
	const {
		cellValues,
		rowId,
		InfoHandler,
		DeleteHandler,
		GraphHandler,
		redirectionHandler,
		handleShareWith,
		useraction,
		setAnchorEl,
	} = props;

	// console.log("cellValues", cellValues);
	// console.log('rowId', rowId);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			{rowId.status ? (
				<IconButton
					title='Labour Info'
					sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
					onClick={() => InfoHandler(rowId)}>
					<InfoIcon style={{ cursor: 'pointer' }} />
				</IconButton>
			) : (
				rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('D') && (
					<IconButton
						title='Delete'
						sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
						onClick={() => DeleteHandler(rowId)}>
						<DeleteIcon style={{ cursor: 'pointer' }} />
					</IconButton>
				)
			)}
			<IconButton
				title='Graph'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => GraphHandler(rowId)}>
				<EqualizerIcon />
			</IconButton>
			<IconButton
				title='Redirect'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => redirectionHandler(rowId)}>
				<LaunchIcon style={{ cursor: 'pointer' }} />
			</IconButton>
			{rowId.flag !== null && (
				<IconButton
					title='flag'
					sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}>
					<FlagIcon
						onClick={() => 
						{

							API.get("/api/db/labour_cost/",{
								id:rowId?.id,
								download_flag:true,
							},0).then((res:any)=>{
								window.open(res.data, '_blank')
							}).catch((err:any)=>{})
							
						}
						}
						sx={{ color: 'green' }}
					/>
				</IconButton>
			)}

			{rowId?.lock_status == false &&
				useraction &&
				useraction?.includes('U') && (
					<IconButton
						title='Move'
						sx={{ color: '#007fff', padding: '0.5rem', cursor: 'pointer' }}
						onClick={() => {
							handleShareWith(rowId);
							setAnchorEl(null);
						}}>
						<img
							src={move}
							style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem' }}
						/>
					</IconButton>
				)}
		</Box>
	);
}
