import * as React from "react";
import {Box, Table, TableBody, TableCell, TableHead, TableRow, FormControl, MenuItem} from "@mui/material";
import { AxiosError, AxiosResponse } from 'axios';
import { Backdrop, Divider, Modal, Skeleton, Typography } from "@mui/material";
import { API } from "../../api-services";
import styles from './IdeaCreatepage.module.scss';
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Params{
	topVault:any;
  projectId:any;
  projectName:any;
}


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "95vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};


interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;

}

function IdeaSummaryModal(props: IWatermarkModelProps) {
    const {topVault, projectId, projectName} = useRouteParams<Params>()
  const { isOpen, onCloseModal,  } = props;
  const [productList, setProductList] = React.useState<any>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(true);

  const getSummaryModelData = (topVault: any) => {
    setLoading(true);
    API.get(
      `/idea/idea_summary/`,
      { top_vault: topVault},
      0
    )
      .then((res: AxiosResponse) => {
        setLoading(false);
        setProductList(res?.data);
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        console.log(err, "error");
      });
  };
  
  React.useEffect(() => {
    getSummaryModelData(topVault);
  }, [topVault]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOptions(event.target.value);
  }

  const handleRedirection = (item:any) => {
    const url = `/#/idea-generation-view/${projectId}/${projectName}/${topVault}/${item?.abbreviation}/${item?.id}/0/0/${item?.id}/0/0/0/0`
    window.open( url, '_blank')
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <FormControl variant='standard'>                
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOptions || 'value'}                 
                  size='small'                  
                  onChange={handleChange}
                  sx={{
                    minWidth:'8rem',
                    "&:before": {
                      borderBottomColor: "transparent",
                      borderRadius: "5px", 
                    }, 
                    "&:after": {
                      borderBottomColor: "transparent",
                      borderRadius: "5px", 
                    },                    
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                      fontSize:'1rem',                      
                      borderRadius: "5px", 
                      backgroundColor: "primary.main",                     
                      padding: {lg:"0.4rem 0.5rem",xl:'0.25rem 0.5rem'},
                      color:"white",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon':{color:'white'},	
                    overflow: "hidden",
                  }}
                >
                  <MenuItem sx={{fontSize:'1rem'}} value="count">Count</MenuItem>
                  <MenuItem sx={{fontSize:'1rem'}} value="value">Value</MenuItem>
                </Select>
              </FormControl>
            <Typography
              style={{
                fontSize: "1rem",
                flexGrow: 1,
                fontWeight: "600",
                // width: "100%",
                textAlign: "center",
                marginRight: '-2rem',
              }}
            >
              Ideas Summary
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider />
          <Box sx={{maxHeight:'85vh'}}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3">
          {loading ? <Box>
            <Table>
            <TableBody>
					<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
		<TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		      <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
		  <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>
				</TableBody> 
        </Table>
          </Box> : (<Table className={styles.landTable}>
          <TableHead>
					<TableRow>
						<TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff"}}colSpan={1}><Box className={styles.columnDash}></Box></TableCell>
						<TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff",borderRight:'1px solid'}}colSpan={2}>Media</TableCell>
						<TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff",borderRight:'1px solid'}}colSpan={2}>Architecture</TableCell>
						<TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff",borderRight:'1px solid'}}colSpan={4}>Features</TableCell>
            <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff",borderRight:'1px solid'}}colSpan={4}>BOM</TableCell>
						<TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light', textAlign:'center',backgroundColor:'primary.main', color: "#fff",}}colSpan={4}>Costing</TableCell>				
					</TableRow>
          <TableRow>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'primary.main', color: "#fff",borderRight:'1px solid #007fff !important'}} align='center'>System Names</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Initiated</TableCell>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f7e1f4' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>Manual Submitted</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Initiated</TableCell>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f7e1f4' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>Manual Submitted</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Initiated</TableCell>
            <TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Submitted</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f1f9ff' , color:"primary.main"}} align='center'>Auto Initiated</TableCell>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>Auto Submitted</TableCell>
            <TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Initiated</TableCell>
            <TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Submitted</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f1f9ff' , color:"primary.main"}} align='center'>Auto Initiated</TableCell>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>Auto Submitted</TableCell>
            <TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Initiated</TableCell>
            <TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f7e1f4' , color:"primary.main"}} align='center'>Manual Submitted</TableCell>
						<TableCell className={styles.columnSHeaderValue} sx={{backgroundColor:'#f1f9ff' , color:"primary.main"}} align='center'>Auto Initiated</TableCell>
						<TableCell className={styles.columnSHeaderCount} sx={{backgroundColor:'#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>Auto Submitted</TableCell>
					</TableRow>
				</TableHead>
        <TableBody>
        {productList?.map((row: any, index: number) => {
            return (
                <TableRow key={index}>
                    <TableCell className={styles.columnCount} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#ffffff', textDecoration:"underline", cursor:"pointer",borderRight:'1px solid #007fff !important'}} align='center' onClick={() => handleRedirection(row)} >{ row?.abbreviation}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Media?.MInitiatedcount : row?.Media?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>{selectedOptions === "count" ? row?.Media?.MSubmittedcount : row?.Media?.MSubmittedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Architecture?.MInitiatedcount : row?.Architecture?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>{selectedOptions === "count" ? row?.Architecture?.MSubmittedcount : row?.Architecture?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Feature?.MInitiatedcount : row?.Feature?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Feature?.MSubmittedcount : row?.Feature?.MSubmittedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Feature?.AInitiatedcount : row?.Feature?.AInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>{selectedOptions === "count" ? row?.Feature?.AInitiatedcount : row?.Feature?.AInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.BOM?.MInitiatedcount : row?.BOM?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.BOM?.MSubmittedcount : row?.BOM?.MSubmittedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.BOM?.AInitiatedcount : row?.BOM?.AInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>{selectedOptions === "count" ? row?.BOM?.AInitiatedcount : row?.BOM?.AInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Costing?.MInitiatedcount : row?.Costing?.MInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f7e1f4' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Costing?.MSubmittedcount : row?.Costing?.MSubmittedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main"}} align='center'>{selectedOptions === "count" ? row?.Costing?.AInitiatedcount : row?.Costing?.AInitiatedvalue}</TableCell>
                    <TableCell className={styles.columnValue} sx={{backgroundColor:row?.abbreviation =='Total' ? 'primary.light' : '#f1f9ff' , color:"primary.main",borderRight:'1px solid #007fff !important'}} align='center'>{selectedOptions === "count" ? row?.Costing?.AInitiatedcount : row?.Costing?.AInitiatedvalue}</TableCell>
                </TableRow>  )  

        })}
        </TableBody>
          </Table>)}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default IdeaSummaryModal;
