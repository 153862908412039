import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import {
  Box,
  Button,
  Divider,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "../BOM/BomTemplatedHeader";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderwhite.svg';


const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "80%", xl: "80%" },
  height: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  transition: "none !important", // Disable the transition animation
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
interface FeatureParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  parameterValidation: any;
  setcategoryCount: any;
  showAll?: any;
}

const AddFeatureParameterModal = (props: FeatureParameterModalProps) => {
  const { isOpen, onCloseModal, parameterValidation, setcategoryCount, showAll } = props;
  const { projectId, categoryId, paramType } = useRouteParams<any>();
  const classes = useStyles();
  const [paramsData, setParamsData] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<any>();
  const [dataTypes, setDataTypes] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
  const [selecteParameter, setselecteParameter] = useState<any>([]);
  const [counter, setCounter] = useState<any>(0);
  const [searchKeys, setSearchKeys] = useState<any>(null);
  const [searchvalue, setSearchvalue] = useState<any>(null);
  const [searchLoader, setSearchLoader] = useState<any>(false);
  const [tempselectedParameters, setTempSelectedParameters] =
    React.useState<any>([]);
  const [selectedTab, setSelectedTab] = React.useState('');


  const getTypeName = (type: any) => {
    switch (type) {
      case 1:
        return "parameter";
      case 2:
        return "value";
      case 3:
        return "others";
      default:
        return "parameter";
    }
  };

  const getTypeFullName = (type: any) => {
    switch (type) {
      case 1:
        return "Name Parameter";
      case 2:
        return "Value Parameter";
      case 3:
        return "Others Parameter";
      default:
        return "Name Parameter";
    }
  };

  const handleChange = (name: any) => {
    setSelectedTab(name)
  };

  const accordianHanlder = (name: any) => {
    setExpanded(name !== expanded ? name : null);
    setSelectedTab(name);
    setIsLoading(true);
    setParamsData([]);
    API.get(
      "/api/db/parameter/",
      {
        project: projectId,
        module: 17,
        data_types: name,
        category: categoryId,
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false);
        setParamsData(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const getDataTypes = () => {
    API.get(
      "/config/parameter_types/",
      {
        category: categoryId,
        project: projectId,
        module: 17,
      },
      0
    )
      .then((res: any) => {
        setDataTypes(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getDataTypes();
  }, [projectId, counter, selectedTab]);

  const handleChangeCheck = (item: any) => {
    if (tempselectedParameters.includes(item?.id)) {
      setTempSelectedParameters((prev: any) =>
        prev.filter((itm: any) => itm !== item?.id)
      );
      setselecteParameter((prev: any) =>
        prev.filter((itm: any) => itm !== item?.id)
      );
    } else {
      setselecteParameter((prev: any) => [...prev, item]);
      setTempSelectedParameters((prev: any) => [...prev, item?.id]);
    }
  };

  const SaveHandler = () => {
    setIsLoadingSave(true);

    var SelecteData =
      tempselectedParameters &&
      tempselectedParameters?.map((item: any, index: any) => ({
        parameter_id: item,
        required: false,
      }));

    setExpanded("");
    API.post(
      "/config/parameter_mapping/",
      {
        project: projectId,
        module: 17,
        category: categoryId,
        parameters: SelecteData,
      },
      {},
      0
    )
      .then((res: any) => {
        parameterValidation("Uncheck");
        setCounter((prev: any) => prev + 1);
        setcategoryCount((prev: any) => prev + 1);
        setTempSelectedParameters([]);
        setSearchvalue(null);
        setSearchKeys(null);
        setIsLoadingSave(false);
      })
      .catch((err: any) => {
        setIsLoadingSave(false);
      });
  };

  const handleSearch = (event: any) => {
    setSearchKeys(event?.target.value);
    if (event?.target.value.length == 0) {
      getDataTypes();
      setSearchKeys(null);
      setSearchvalue(null);
    }
  };
  const searchClick = () => {
    if (searchKeys !== null) {
      setSearchLoader(true);
      API.get(`/config/parameter_mapping_search/`, {
        module: 17,
        category: categoryId,
        project: projectId,
        search_key: searchKeys?.trim(),
      })
        .then((res: any) => {
          setDataTypes(res?.data);
          setSearchLoader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setSearchLoader(false);
        });
      setSearchvalue(searchKeys);
      setSearchKeys(searchKeys);
    }
  };

  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      if (searchKeys !== null) {
        API.get(`/config/parameter_mapping_search/`, {
          module: 17,
          category: categoryId,
          project: projectId,
          search_key: searchKeys?.trim(),
        })
          .then((res: any) => {
            setDataTypes(res?.data);
          })
          .catch((err: any) => {
            console.log("Server Error");
          });
        setSearchvalue(searchKeys);
        setSearchKeys(searchKeys);
      }
    }
  };
  const columnsFiled = CellNameOne.includes(expanded) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", color: "white" }}
        ></TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Unit
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Not Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Default Value
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Guideline Doc
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameSecond.includes(expanded) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", color: "white" }}
        ></TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Name
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Description
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Guideline Doc
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameThird.includes(expanded) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", color: "white" }}
        ></TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Unit
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Allowed Values
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Guideline Doc
        </TableCell>
      </TableRow>
    </TableHead>
  ) : (
    <TableHead
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", color: "white" }}
        ></TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Description
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", color: "white" }}>
          Guideline Doc
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <Modal
      open={isOpen}
      className={classes.ModalRoot}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={popstyled}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Add Feature Parameter
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "1rem",
            width: "100%",
            marginBottom: "0.5rem",
            padding: "0 1rem",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "1rem", marginLeft: "2rem" }}>
              Data Type
            </Typography>
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <Box
              sx={{
                marginBottom: "0.5rem",
                padding: "0 1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                type="search"
                value={searchKeys == null ? "" : searchKeys}
                onKeyDown={(event: any) => handlechangeDown(event)}
                onChange={(e: any) => handleSearch(e)}
                id="standard-basic"
                placeholder="Search Parameter Name"
                variant="standard"
              />
              <SearchIcon
                sx={{ cursor: "pointer" }}
                onClick={() => searchClick()}
              />
            </Box>

            <Typography sx={{ fontSize: "1rem" }}>
              {tempselectedParameters?.length} Selected
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                ":hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
              onClick={() => setTempSelectedParameters([])}
            >
              Clear Selected
            </Button>

            {tempselectedParameters?.length > 0 && (
              <LoadingButton
                variant="contained"
                size="small"
                loading={isLoadingSave}
                sx={{
                  ":hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                  backgroundColor: "#5bad06",
                }}
                onClick={() => SaveHandler()}
              >
                Save
              </LoadingButton>
            )}
          </Box>
        </Box>
        <Box
          sx={{ height: { lg: "85%", xl: "87%" }, width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {searchvalue ? (
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "primary.light",
                  position: "sticky",
                  top: "0",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Parameter Name
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Exceptions
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Allowed Values
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Prohibited Values
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Default Values
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Guideline
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {searchLoader ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {" "}
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      sx={{
                        cursor: "pointer",
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {dataTypes &&
                      dataTypes?.map((rowItem: any, index: any) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    padding: "10px",
                                    cursor: "pointer",
                                    borderColor: "primary.light",
                                  }}
                                  // size="small"
                                  key={index}
                                  checked={
                                    tempselectedParameters.includes(rowItem.id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) =>
                                    handleChangeCheck(rowItem)
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem?.name}
                              </TableCell>

                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem?.description}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem.unit}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem?.exceptions}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem?.allowed_values?.length == 0
                                  ? "_"
                                  : rowItem?.allowed_values?.toString()}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {" "}
                                {rowItem?.not_allowed_values?.length == 0
                                  ? "_"
                                  : rowItem?.not_allowed_values?.toString()}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {rowItem?.default_value}
                                {rowItem?.default_value == ""
                                  ? "_"
                                  : rowItem?.default_value}
                              </TableCell>
                              <TableCell
                                sx={{
                                  cursor: "pointer",
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                                onClick={() =>
                                  rowItem?.info_doc != null &&
                                  window.open(rowItem?.info_doc, "_blank")
                                }
                              >
                                {rowItem?.info_doc == null ? (
                                  "-"
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </>
                )}
              </TableBody>
            </Table>
          ) : (
            <>
              <Box
                sx={{ display: 'grid', gridTemplateColumns: '17rem 1fr', columnGap: '0.5rem', height: "100%", width: "100%" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    width: '100%',
                    flexDirection: 'column',
                    rowGap: '2px',
                  }} adl-scrollbar="true"
                  adl-scrollbar-width="0.2">
                  {dataTypes &&
                    dataTypes?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{
                              padding: "0 0.3rem",
                              display: "flex",
                              // display: showAll !== false ? 'flex' : item?.name == selectedTab ? 'flex' : 'none',
                              alignItems: "center",
                              justifyContent: "flex-start",
                              height: '2rem',
                              minHeight: '2.1rem',
                              width: "100%",
                              cursor: "pointer",
                              backgroundColor:
                                item?.name == expanded
                                  ? 'primary.main'
                                  : item?.parameter_group === 1 ? '#cef0ff'
                                    : item?.parameter_group === 2 ? '#d0f1aa'
                                      : item?.parameter_group === 3 ? '#fbdddd'
                                        : item?.parameter_group === 4 ? '#ffee93'
                                          : '#d1cfe2',
                            }}
                            onClick={() => accordianHanlder(item.name)}
                          >
                            {item?.name == selectedTab ? (
                              <img
                                src={
                                  openFolder
                                }
                                style={{
                                  height:
                                    '1.2rem',
                                }}
                              />
                            ) : (
                              <FolderIcon
                                sx={{
                                  fontSize:
                                    '1.5rem',
                                  color:
                                    'primary.main',
                                }}
                              />
                            )}
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                              <Typography
                                sx={{
                                  color:
                                    item?.name == expanded ? 'white' : 'primary.main',
                                  padding: '0 0.5rem',
                                  fontSize: "1rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography sx={{
                                color: item?.name == expanded ? 'white' : 'primary.main', fontSize: "1rem"
                              }}>
                                ({item?.prameter_count ? item?.prameter_count : "-"})
                              </Typography>
                            </Box>
                          </Box>

                        </>
                      );
                    })}
                </Box>

                <Box
                  sx={{
                    paddingRight: "0.5rem",
                    height: "100%",
                    width: "100%",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  {isLoading ? (
                    <>
                      <Table>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "3vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "5vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "10vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "7vw",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </>
                  ) : (
                    <>
                      <Table>
                        {columnsFiled}
                        <TableBody>
                          {paramsData &&
                            paramsData?.map((item: any, index: any) => {
                              return CellNameOne.includes(
                                item?.data_type
                              ) ? (
                                <TableRow key={index + 1}>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    <input
                                      key={index + 1}
                                      type="checkbox"
                                      style={{
                                        color: "primary.main",
                                        padding: "10px",
                                        cursor: "pointer",
                                        borderColor: "primary.light",
                                      }}
                                      // size="small"

                                      checked={
                                        tempselectedParameters &&
                                          tempselectedParameters?.includes(
                                            item.id
                                          )
                                          ? true
                                          : false
                                      }
                                      onClick={(e: any) =>
                                        handleChangeCheck(item)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.unit &&
                                      item?.unit?.length > 0
                                      ? item?.unit
                                      : "-"}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.exceptions?.length > 0
                                      ? item?.exceptions
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.description &&
                                      item?.description?.length !== null
                                      ? item?.description
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                      width: "10vw",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        width: "10vw",
                                        fontSize: "1rem",
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {" "}
                                      {item?.allowed_values?.length > 0
                                        ? item?.allowed_values.join(
                                          ", "
                                        )
                                        : "-"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.not_allowed_values?.length >
                                      0
                                      ? item?.not_allowed_values
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.default_value?.length > 0
                                      ? item?.default_value
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.info_doc ? (
                                      <VisibilityIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            item?.info_doc,
                                            "_blank"
                                          )
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : CellNameSecond.includes(
                                item?.data_type
                              ) ? (
                                <TableRow key={index + 1}>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    <input
                                      key={index + 1}
                                      type="checkbox"
                                      style={{
                                        color: "primary.main",
                                        padding: "10px",
                                        cursor: "pointer",
                                        borderColor: "primary.light",
                                      }}
                                      // size="small"

                                      checked={
                                        tempselectedParameters &&
                                          tempselectedParameters?.includes(
                                            item.id
                                          )
                                          ? true
                                          : false
                                      }
                                      onClick={(e: any) =>
                                        handleChangeCheck(item)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.description &&
                                      item?.description?.length !== null
                                      ? item?.description
                                      : "-"}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.info_doc ? (
                                      <VisibilityIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            item?.info_doc,
                                            "_blank"
                                          )
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : CellNameThird.includes(
                                item?.data_type
                              ) ? (
                                <TableRow key={index + 1}>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    <input
                                      key={index + 1}
                                      type="checkbox"
                                      style={{
                                        color: "primary.main",
                                        padding: "10px",
                                        cursor: "pointer",
                                        borderColor: "primary.light",
                                      }}
                                      // size="small"

                                      checked={
                                        tempselectedParameters &&
                                          tempselectedParameters?.includes(
                                            item.id
                                          )
                                          ? true
                                          : false
                                      }
                                      onClick={(e: any) =>
                                        handleChangeCheck(item)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.unit &&
                                      item?.unit?.length > 0
                                      ? item?.unit
                                      : "-"}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.exceptions?.length > 0
                                      ? item?.exceptions
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.description &&
                                      item?.description?.length !== null
                                      ? item?.description
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                      width: "10vw",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        width: "10vw",
                                        fontSize: "1rem",
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {" "}
                                      {item?.allowed_values?.length > 0
                                        ? item?.allowed_values.join(
                                          ", "
                                        )
                                        : "-"}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.info_doc ? (
                                      <VisibilityIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            item?.info_doc,
                                            "_blank"
                                          )
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow key={index + 1}>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    <input
                                      key={index + 1}
                                      type="checkbox"
                                      style={{
                                        color: "primary.main",
                                        padding: "10px",
                                        cursor: "pointer",
                                        borderColor: "primary.light",
                                      }}
                                      // size="small"

                                      checked={
                                        tempselectedParameters &&
                                          tempselectedParameters?.includes(
                                            item.id
                                          )
                                          ? true
                                          : false
                                      }
                                      onClick={(e: any) =>
                                        handleChangeCheck(item)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.description &&
                                      item?.description?.length !== null
                                      ? item?.description
                                      : "-"}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor:
                                        "primary.light",
                                    }}
                                  >
                                    {item?.info_doc ? (
                                      <VisibilityIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            item?.info_doc,
                                            "_blank"
                                          )
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </>
                  )}
                </Box>
              </Box>

            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default AddFeatureParameterModal;
