import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Typography,
  } from "@mui/material";
  import * as React from "react";
  import Modal from "@mui/material/Modal";
  import Select, { SelectChangeEvent } from "@mui/material/Select";
  import { useBaseModal } from "../../../SearchPage/useBaseModal";
  import OtherIdeaDetailsModal from "../../IdeaCreateForm/OtherIdeaDetailsModal";
  import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
  import BOMEditForm from "./BOMEditForm";
    import FeatureEditForm from "./FeatureEditForm";
    import CostingEditForm from "./CostingEditForm";
    import ArchitectureEditForm from "./ArchitectureForm";
    import MediaEditForm from "./MediaEditForm";
  
  import { IdeaOriginList, IdeaOriginListProduct } from "../../../../utlis/Idea.constant";
  import swal from "sweetalert";
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "60vw", xl: "50vw" },
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };
  
  interface FilmOptionType {
    title: string;
    year: number;
  }
  interface Params {
    projectId: any;
    projectName: any;
    topVault: any;
    subSystem: any;
    ideaId: any;
    abbrevID: any;
    subSystemId: any;
  }
  export interface IIdeaEditFormModalProps {
    onCloseModal: any;
    isOpen: any;
    getIdeaPromotedDetails?: any;
    getIdeaDetailedData?: any;
    getIdeaInitData?: any;
    getCategoryList?: any;
    getIdeaDetailsData?:any;
  }
  
  
  export default function IdeaEditFormModal(props: IIdeaEditFormModalProps) {
    const { onCloseModal, isOpen, getIdeaPromotedDetails, getIdeaDetailsData } = props;
    const {subSystem, abbrevID, subSystemId} = useRouteParams<Params>();

    const [originChanged, setOriginChanged] = React.useState<any>(false);
    const [ideaOrigin, setIdeaOrigin] = React.useState<any>(getIdeaPromotedDetails?.origin);
  
    const [value, setValue] = React.useState<FilmOptionType | null>(null);
    React.useEffect(() => {
      if (getIdeaPromotedDetails?.origin) {
        setIdeaOrigin(getIdeaPromotedDetails?.origin);
      }
    }, [getIdeaPromotedDetails?.origin])
  
    const OriginHandleChange = (event: SelectChangeEvent) => {
      let changedOrigin = event.target.value;
      swal({
        title: `Want to change the Idea Origin?`,
        text: `After changing origin partially data will be lost from the idea form. Are you sure?`,
        icon: "info",
        //@ts-ignore
        buttons: {
          cancel: "Cancel",
          permanent: {
            text: `Yes, Change Origin`,
            value: `changeOrigin`,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(async(value) => {
        if (value === "changeOrigin") {
          console.log("changedOrigin");
          console.log(changedOrigin);
          setOriginChanged(true);
              setIdeaOrigin(+changedOrigin);
          
        }
      }).catch((err) => {
        console.log(err, "err");
      })
      
    };

    console.log(getIdeaPromotedDetails, "getIdeaPromotedDetails");

    const data = {
      name : getIdeaPromotedDetails?.combination?.combination_base,
      id: getIdeaPromotedDetails?.base?.id,
    }

    const getOriginList=(data:any)=>{
      
        if(data === "PRODUCT"){
          return IdeaOriginListProduct
        }else{
          return IdeaOriginList
        }
      }

    console.log(abbrevID, "abbrevID");
    console.log(data, subSystem, "aaa")
  
    const getFormDetails = () => {
      switch (ideaOrigin) {
        case 1:
          return (
            <>
              <BOMEditForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={1}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
                data={data}
                getIdeaDetailsData={getIdeaDetailsData}
              />
            </>
          );
  
          case 2:
        return (
          <ArchitectureEditForm
            onCloseModal={onCloseModal}
            isOpen={isOpen}
            subSystem={subSystem}
            ideaOrigin={2}
            subSystemId={subSystemId}
            getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
            data={data}
            getIdeaDetailsData={getIdeaDetailsData}
            
          />
        );

        case 3:
          return (
            <>
              <FeatureEditForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={3}
                subSystemId={subSystemId}
                getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
                data={data}
                getIdeaDetailsData={getIdeaDetailsData}
                
              />
            </>
          );
        case 4:
          return (
            <>
              <CostingEditForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={4}
                subSystemId={subSystemId}
                getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
                data={data}
                getIdeaDetailsData={getIdeaDetailsData}
              />
            </>
          );

          case 5:
        return (
          <>
            <MediaEditForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={5}
              subSystemId={abbrevID}
              getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
              getIdeaDetailsData={getIdeaDetailsData}
              
              
              
            />
          </>
        );
  
        case 7:
          return (
            <>
              <FeatureEditForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={7}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={originChanged ? {} :  getIdeaPromotedDetails}
                data={data}
                getIdeaDetailsData={getIdeaDetailsData}
              />
            </>
          );
      }
    };
  
    const OtherIdeaModal = useBaseModal();
    const OtherIdeaFormHandler = () => {
      OtherIdeaModal.open();
    };
  
    return (
      <div>
        {OtherIdeaModal.isOpen && (
          <OtherIdeaDetailsModal
            onCloseModal={OtherIdeaModal.close}
            isOpen={OtherIdeaModal.open}
            subSystem={subSystem}
            closeParentModal={onCloseModal}
          />
        )}
        <Modal
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  // marginRight: '-2rem'
                }}
              >
                Idea Form Entry ({subSystem})
              </Typography>
              {/* <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} /> */}
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "100%",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <FormControl
                  required
                  sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  fullWidth
                  variant="standard"
                  
                >
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Origin
                  </InputLabel>
                  <Select
                    id="Origin"
                    name="Origin"
                    labelId="demo-select-small"
                    required
                    onChange={OriginHandleChange}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    value={ideaOrigin}
                  >
                    {getOriginList(subSystem)?.filter((item:any)=>item.value !== 7 )?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          value={item?.value}
                          
                        >
                          {item?.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard"></FormControl>
              </Box>
              {getFormDetails()}
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
  