import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect } from "react";
import CostParametersHeirarchy from "./CostParametersHeirarchy";
import { API } from "../../api-services";

export interface IScenarioAnalysisModelProps {
  isOpen: any;
  onCloseModal: any;
  graphModal: any;
  finalCostData: any;
  vault: any;
  checkboxvalue: any;
  setcheckboxvalue: any
  disabledNodes: any
  setDisabledNodes: any
  title: any;
  graphType: any;
  datas: any;
  topVault: any;
  onCloseGraphModal: any;
  selectedScenarios: any;
  getAnalysis: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxwidth: "60vw",
  width: "fit-content",
  height: "fit-content",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  paddingX: 2,
  paddingTop: 0,
  overflowY: "auto",
};

export const ParametersHierarchyModal = (
  props: IScenarioAnalysisModelProps
) => {
  const { isOpen, onCloseModal, vault, checkboxvalue, setcheckboxvalue, disabledNodes,
    setDisabledNodes, title,
    datas,
    graphType,
    onCloseGraphModal,
    topVault,
    graphModal,
    selectedScenarios,
    getAnalysis } = props;
  // const [checkboxvalue, setcheckboxvalue] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [finalData, setFinalData] = React.useState<any>(null)


  const getScenarioTreeData = () => {
    setLoading(true);

    // setPendingRequests((prev) => prev + 1);
    API.get(
      `cost/scenario_analysis/`,
      {
        vault_id: vault,
        get_analysis_hierarchy: true
      },
    )
      .then((res: any) => {
        // console.log("4443")
        // console.log("res",res?.data)
        setFinalData(res?.data)
      })
      .catch(() => {

      })
      .finally(() => {
        setLoading(false);

      });
  };

  // console.log("res2",finalData)
  React.useEffect(() => {
    getScenarioTreeData()
  }, []);


  const onSelectCallback = (data: any) => {
    // if (checkboxvalue?.includes(data?.id)) {
    //     setCheckboxData({});
    // } else {
    //     API.get('/xcpep/vault_copy_info/', {
    //         root_id: data?.id,
    //         source_id: +getData?.id,
    //     })
    //         .then((res: any) => {
    //             setCheckboxData(data);
    //             setcheckboxvalue([data?.id]);
    //         })
    //         .catch((err: any) => {
    //             setcheckboxvalue([]);
    //             const { data } = err.response;
    //             console.log(data, 'datadata');
    //             ErrorModalFiles.open();
    //             setErrorModalFileMsg(data);
    //             console.log('Server Error');
    //         });
    // }
  };

  useEffect(() => {
    sessionStorage.setItem("ScenarioParamSelected", JSON.stringify([-1]))
  }, [])

  return (
    <Modal open={isOpen}>

      <Box sx={style}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            justifyContent: "center",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            padding: 1,
            zIndex: 10
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "1rem",
              textAlign: "center",
              width: "100%",
            }}
          >
            Select Parameters
          </Typography>
          <CancelIcon
            onClick={onCloseModal}
            sx={{ color: "#f27474", fontSize: "1.5rem", cursor: "pointer" }}
          />
        </Box>

        {loading ?
          <Box >
            <Skeleton sx={{ width: "400px" }} />
            {Array.from({ length: 20 }).map((ele) =>
              <Skeleton />
            )}
          </Box> :
          <>
            <div>
              <CostParametersHeirarchy
                HierarchyData={finalData?.data[0]}
                onSelectCallback={onSelectCallback}
                checkboxvalue={checkboxvalue}
                setcheckboxvalue={setcheckboxvalue}
                disabledNodes={disabledNodes}
                setDisabledNodes={setDisabledNodes}
              />
            </div>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                justifyContent: "flex-end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
                paddingTop: 1,
                zIndex: 10
              }}
            >
              <Button
                variant="contained"
                size="small"
                // startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                title="Select Parameter"
                // disabled={getDisableStatus()}
                // onClick={() => AddScenarioHandler()}
                // onClick={onCloseModal}
                disabled={selectedScenarios?.length < 1 || checkboxvalue.length < 1}
                onClick={() => {
                  onCloseModal()
                  getAnalysis()
                  graphModal.open()
                }}
                sx={{
                  padding: "0.8rem 1rem",
                  minWidth: "fit-content",
                  lineHeight: 1,
                  marginBottom: 2,
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
              >Analyze</Button>
            </Box>
          </>
        }


      </Box>
    </Modal>
  );
};
