import {
    Box,
    Divider,
    Modal,
    Typography,
  } from "@mui/material";
  // import { LoadingButton } from "@mui/lab";
  import BOMRobotoFlowFetchDataFailedTable from "./BOMRobotoFlowFetchDataFailedTable";
  
  export interface IBOMImageBasedSearchModalProps {
    onCloseModal: any;
    isOpen: any;
    project:any;
  }
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    height: "90vh",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };
  
  export default function BOMPcbRoboflowFailedReportModal(
    props: IBOMImageBasedSearchModalProps
  ) {
    const { onCloseModal, isOpen, project } = props;
  
    return (
      <div>
        <Modal
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "2.5rem",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
  
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Failed Dataset Report
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                // marginBottom: "1rem",
              }}
            />
            <Box>
              <BOMRobotoFlowFetchDataFailedTable onCloseModal={onCloseModal} project={project}/>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }