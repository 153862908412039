import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { Box, Button, Divider, FormControl, TextField, Typography, Stepper, Step, StepLabel, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import React, { useState } from "react";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../../Redux/Services/admin.service";
import Chip from "@material-ui/core/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import { IdeaCombinationTypesList, IdeaFilterList, costEstimationParametersCountNew, costEstimationParametersNew } from "../../../utlis/Idea.constant";
import InfoIcon from '@mui/icons-material/Info';
import { CircularProgress } from "@mui/material";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50vw",
    // minHeight: "50vh",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


// @ts-ignore

const useStyles = makeStyles((theme) => ({
    /** Changed modalStyle */
    ModalRoot: {
        backgroundColor: "rgba(111, 126, 140, 0.2) !important",
        backdropFilter: "blur(1px) !important",
    },
}));


export interface AddNewCombinationModalProps {
    isOpen: any;
    onCloseModal: any;
    rowId: any;
    categoryName: any;
    categorySelected: any;
    getCategoryData: any;
    getCategoryList: any;
}

const AddNewCombination = (props: AddNewCombinationModalProps) => {
    const { isOpen, onCloseModal, rowId, categoryName, categorySelected, getCategoryData, getCategoryList } = props;
    const { abbrevID, topVault, projectId, originID, subSystem } = useRouteParams<any>();
    const classes = useStyles();
    const steps = ["Competitor Vehicles", "Analysis type", "Parameters", "Report Type"];
    const [activeStep, setActiveStep] = React.useState<any>(0);
    const [name, setName] = useState<any>("");
    const [competitorVehicles, setCompetitorVehicles] = useState<any>([]);
    const [selectedCompetitorVehicles, setSelectedCompetitorVehicles] = useState<any>([]);
    const [analysisType, setAnalysisType] = useState<any>([]);
    const [selectedAnalysisType, setSelectedAnalysisType] = useState<any>();
    const [categoriesList, setCategoriesList] = useState<any>([]);
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [parametersList, setParametersList] = useState<any>([]);
    const [selectedParameters, setSelectedParameters] = useState<any>([]);
    const [accuracy, setAccuracy] = useState<any>(0);
    const [reportType, setReportType] = useState<any>([]);
    const [selectedReportType, setSelectedReportType] = useState<any>();
    const [paretoPercentage, setParetoPercentage] = useState<any>([]);
    const [paretoPercentageSelected, setParetoPercentageSelected] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    const integer = ["positive-int", "nonzero-integer", "nonzero-positive-float", "nonzero-positive-int", "nonzero-float", "float", "percentage", "positive-float", "nonzero-negative-float",];

    const percentageOptions = [
        { key: 0, text: "100%", value: 100 },
        { key: 1, text: "90%", value: 90 },
        { key: 2, text: "80%", value: 80 },
        { key: 3, text: "70%", value: 70 },
        { key: 4, text: "60%", value: 60 },
        { key: 5, text: "50%", value: 50 }
    ]

    const handleNext = () => {
        if (activeStep === 0) {
            setLoading(true);
            setAnalysisType(IdeaCombinationTypesList[rowId]);
            API.get(`api/db/category`, {}, 0).then((res: any) => {
                setCategoriesList(res?.data);
                setLoading(false);
            })
                .catch((err: any) => {
                    console.log(err);
                })

        }
        if (activeStep === 1) {
            if (rowId === 1) {
                setLoading(true);
                API.get(`config/parameter_mapping/`,
                    {
                        top_vault: topVault,
                        module: 6,
                        categories: selectedCategories.map((item: any) => item.id).join(","),
                    }, 0).then((res: any) => {
                        let parameters: any = [];
                        res?.data.map((item: any) => {
                            parameters.push(item.parameter);
                        })
                        parameters = parameters.filter((item: any, index: any, self: any) => index === self.findIndex((e: any) => e.id === item.id && e.name === item.name));
                        if (selectedAnalysisType?.value === 7) {
                            parameters = parameters.filter((item: any) => item?.data_type === "multiselect");
                        }
                        else if (selectedAnalysisType?.value === 3) {
                            parameters = parameters.filter((item: any) => item?.name === "Material Percentage");
                        }
                        else if (selectedAnalysisType?.value === 4 || selectedAnalysisType?.value === 5) {
                            parameters = [];
                        }
                        else if (selectedAnalysisType?.value === 1) {
                            parameters = parameters.filter((item: any) => integer.includes(item?.data_type));

                        }
                        setParametersList(parameters);
                        setLoading(false);
                    }
                    ).catch((err: any) => {
                        console.log(err);
                        setLoading(false);
                    })
            }
            else if (rowId === 4) {
                setLoading(true);
                if (selectedAnalysisType?.value === 1) {
                    setParametersList(costEstimationParametersNew);
                    setLoading(false);
                }
                else {
                    setParametersList(costEstimationParametersCountNew);
                    setLoading(false);
                }
            }
            else if (rowId === 3) {
                setLoading(true);
                API.get(`idea/idea_feature_parameter/`, {
                    abbreviation: subSystem,
                    top_vault: topVault,
                    type: selectedAnalysisType?.value,
                    feature_type: 0,
                }, 0).then((res: any) => {
                    // console.log(res?.data);
                    setParametersList(res?.data);
                    setLoading(false);
                }).catch((err: any) => {
                    console.log(err);
                    setLoading(false);
                })
            }
            else {
                setLoading(true);
                API.get(`idea/idea_feature_parameter/`, {
                    abbreviation: subSystem,
                    top_vault: topVault,
                    type: selectedAnalysisType?.value,
                    feature_type: 1,
                }, 0).then((res: any) => {
                    // console.log(res?.data);
                    setParametersList(res?.data);
                    setLoading(false);
                }).catch((err: any) => {
                    console.log(err);
                    setLoading(false);
                })

            }
        }
        if (activeStep === 2) {
            setReportType(IdeaFilterList[selectedAnalysisType?.value]);
            setParetoPercentage(percentageOptions);
        }
        if (activeStep === 3) {

            if (rowId === 3 || rowId === 7) {
                if (selectedAnalysisType?.value === 1) {
                    API.post(`idea/combination/`, {
                        analysis: selectedAnalysisType?.value,
                        categories: selectedCategories?.map((item: any) => item.id),
                        competitor: selectedCompetitorVehicles?.map((item: any) => item.id),
                        filter: selectedReportType?.value,
                        match: Number(accuracy),
                        name: name,
                        origin: rowId,
                        subsystem: abbrevID,
                        percent_value: paretoPercentageSelected?.value,
                        parameters: selectedParameters?.map((item: any) => item.param_id),
                    }, {}, 0).then((res: any) => {
                        // console.log(res?.data);
                        Swal.fire({
                            title: "Success",
                            text: "Combination added successfully",
                            icon: "success",
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#007FFF",
                        }).then((res: any) => {
                            getCategoryData(originID, abbrevID)
                            getCategoryList()
                            ADMIN.toast.info("AutoCombination is in queue. Please check after some time.")
                        });
                        onCloseModal();

                    })
                        .catch((err: any) => {
                            console.log(err);
                            const { data } = err?.response;
                            Swal.fire({

                                icon: "error",
                                html: `<div>
                                            <br />
                                            <p style="color:"red">${data}</p>   
                                        </div>`,
                            })
                            onCloseModal();
                        })
                }
                else {
                    API.post(`idea/combination/`, {
                        analysis: selectedAnalysisType?.value,
                        categories: selectedCategories?.map((item: any) => item.id),
                        competitor: selectedCompetitorVehicles?.map((item: any) => item.id),
                        filter: selectedReportType?.value,
                        match: Number(accuracy),
                        name: name,
                        origin: rowId,
                        subsystem: abbrevID,
                        parameters: selectedParameters?.map((item: any) => item.param_id),
                    }, {}, 0).then((res: any) => {
                        // console.log(res?.data);
                        Swal.fire({
                            title: "Success",
                            text: "Combination added successfully!",
                            icon: "success",
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#007FFF",
                        }).then((res: any) => {
                            getCategoryData(originID, abbrevID)
                            getCategoryList()
                            ADMIN.toast.info("AutoCombination is in queue. Please check after some time.")
                        });
                        onCloseModal();

                    })
                        .catch((err: any) => {
                            console.log(err);
                            const { data } = err?.response;
                            Swal.fire({

                                icon: "error",
                                html: `<div>
                                            <br />
                                            <p style="color:"red">${data}</p>   
                                        </div>`,
                            })
                            onCloseModal();
                        })
                }
            }
            else {

                if (selectedAnalysisType?.value === 1) {
                    API.post(`idea/combination/`, {
                        analysis: selectedAnalysisType?.value,
                        categories: selectedCategories?.map((item: any) => item.id),
                        competitor: selectedCompetitorVehicles?.map((item: any) => item.id),
                        filter: selectedReportType?.value,
                        match: Number(accuracy),
                        name: name,
                        origin: rowId,
                        subsystem: abbrevID,
                        percent_value: paretoPercentageSelected?.value,
                        parameters: selectedParameters?.map((item: any) => item.id),
                    }, {}, 0).then((res: any) => {
                        // console.log(res?.data);
                        Swal.fire({
                            title: "Success",
                            text: "Combination added successfully",
                            icon: "success",
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#007FFF",
                        }).then((res: any) => {
                            getCategoryData(originID, abbrevID)
                            getCategoryList()
                            ADMIN.toast.info("AutoCombination is in queue. Please check after some time.")
                        });
                        onCloseModal();

                    })
                        .catch((err: any) => {
                            console.log(err);
                            const { data } = err?.response;
                            Swal.fire({

                                icon: "error",
                                html: `<div>
                                            <br />
                                            <p style="color:"red">${data}</p>   
                                        </div>`,
                            })
                            onCloseModal();
                        })
                }
                else {
                    API.post(`idea/combination/`, {
                        analysis: selectedAnalysisType?.value,
                        categories: selectedCategories?.map((item: any) => item.id),
                        competitor: selectedCompetitorVehicles?.map((item: any) => item.id),
                        filter: selectedReportType?.value,
                        match: Number(accuracy),
                        name: name,
                        origin: rowId,
                        subsystem: abbrevID,
                        parameters: selectedParameters?.map((item: any) => item.id),
                    }, {},0).then((res: any) => {
                        // console.log(res?.data);
                        Swal.fire({
                            title: "Success",
                            text: "Combination added successfully",
                            icon: "success",
                            confirmButtonText: "Ok",
                            confirmButtonColor: "#007FFF",
                        }).then((res: any) => {
                            getCategoryData(originID, abbrevID)
                            getCategoryList()
                            ADMIN.toast.info("AutoCombination is in queue. Please check after some time.")
                        onCloseModal();
                        });
                        

                    })
                        .catch((err: any) => {
                            console.log(err);
                            const { data } = err?.response;
                            Swal.fire({

                                icon: "error",
                                html: `<div>
                                            <br />
                                            <p style="color:"red">${err}</p>   
                                        </div>`,
                            })
                            onCloseModal();
                        })
                }
            }

        }

        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };

    const isNextDisabled = () => {
        if (activeStep === 0) {
            return selectedCompetitorVehicles.length === 0 || name === "";
        }
        if (activeStep === 1) {

            return selectedAnalysisType === undefined || selectedCategories.length === 0;
        }
        if (activeStep === 2) {
            return selectedParameters.length === 0;
        }
        if (activeStep === 3) {
            return selectedReportType === undefined || accuracy === 0;
        }
        return false;
    }

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    }

    const getCompetitorVehicles = () => {
        API.get(
            "/xcpep/top_vault/",
            {
                project: projectId,
                module: 15,
            },
            0
        )
            .then((res: any) => {
                setCompetitorVehicles(res?.data.filter((item: any) => item?.idea_base === false));
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    React.useEffect(() => {
        getCompetitorVehicles();
    }, []);

    return (
        <div>
            <Modal
                open={isOpen}
                className={classes.ModalRoot}
                // onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}><Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                    <Typography
                        style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            width: "100%",
                            textAlign: "center",
                            marginRight: "-2rem",
                        }}
                    >
                        Add Combination
                    </Typography>
                    <CancelIcon
                        titleAccess="Close"
                        sx={{ cursor: "pointer", margin: "0.5rem" }}
                        onClick={onCloseModal}
                    />
                </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ width: "100%" }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: { optional?: React.ReactNode } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>

                                            {label}

                                        </StepLabel>

                                    </Step>
                                )

                            })}
                        </Stepper>
                        {
                            activeStep === 0 && <Box sx={{ width: "100%", padding: "2rem 3rem" }}>
                                <FormControl sx={{ width: "100%",}}>
                                    <TextField                                        
                                        value={name}
                                        required
                                        variant="outlined"
                                        onChange={(e: any) => handleChangeName(e)}                                        
                                        type="text"                                        
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        label="Name"
                                        placeholder="Name"
                                        sx={{                                           
                                            ".MuiFormLabel-asterisk": { color: "red" } ,
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },                                                                                     
                                              "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                <Autocomplete                                    
                                    multiple
                                    disableCloseOnSelect
                                    id="multiple-limit-tags"
                                    options={competitorVehicles}
                                    loading={loading}
                                    getOptionLabel={(option: any) => option.name}
                                    value={selectedCompetitorVehicles}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedCompetitorVehicles(newValue);
                                    }}
                                    renderTags={(value: any, getTagProps: any) =>
                                        value.map((option: any, index: any) => (
                                            <Chip
                                                size="small"                                                
                                                // variant="outlined"
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Competitor Vehicles" 
                                        placeholder="Competitor Vehicles"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                        }} />
                                    )}
                                    sx={{ marginTop: "2rem",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'}}}
                                />
                                </FormControl>
                            </Box>
                        }
                        {
                            activeStep === 1 && <Box sx={{ width: "100%", padding: "2rem 3rem" }}>
                                <FormControl  sx={{ width: "100%" }}>
                                    <TextField
                                        value={categoryName}
                                        disabled
                                        variant="outlined"
                                        type="text"
                                        required
                                        InputLabelProps={{
                                            shrink: true,                                            
                                            style: {                                                
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        label="Origin"
                                        placeholder="Origin"
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                        }}
                                    />
                                </FormControl>
                                <Autocomplete                                    
                                    options={analysisType}
                                    getOptionLabel={(option: any) => option.text}
                                    value={selectedAnalysisType}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedAnalysisType(newValue);
                                    }}
                                    renderTags={(value: any, getTagProps: any) =>
                                        value.map((option: any, index: any) => (
                                            <Chip
                                                size="small"
                                                // variant="outlined"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField variant="outlined" {...params} label="Analysis type" placeholder="Analysis type" InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                        }}/>
                                    )}
                                    sx={{ marginTop: "2rem", width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                />

                                <Autocomplete
                                    multiple
                                    limitTags={4}
                                    id="multiple-limit-tags"
                                    disableCloseOnSelect
                                    options={categoriesList}
                                    getOptionLabel={(option: any) => option.name}
                                    loading={loading}
                                    value={selectedCategories}
                                    onChange={(event: any, newValue: any) => {
                                        console.log(newValue);
                                        setSelectedCategories(newValue);
                                    }}
                                    renderTags={(value: any, getTagProps: any) =>
                                        value.map((option: any, index: any) => (
                                            <Chip
                                                size="small"
                                                // variant="outlined"
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField variant="outlined" {...params} label="Categories" placeholder="Categories" InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                        }} />
                                    )}
                                    sx={{ marginTop: "2rem", width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                />
                                <FormControlLabel control={<Checkbox onChange={(e: any) => { e.target.checked ? setSelectedCategories(categoriesList) : setSelectedCategories([]) }} />} label="Select All Category" />
                            </Box>
                        }
                        {
                            activeStep === 2 && <Box sx={{ width: "100%", padding: "2rem 3rem" }}>

                                <>
                                    {rowId === 1 || rowId === 4 ? (
                                        <>
                                            <Box sx={{display:"flex", width:"100%", alignItems:"center", gap:"0.5rem"}}>
                                            <FormControl variant="outlined"  sx={{ width: "100%", flexGrow:"1" }}>
                                            <Autocomplete
                                                multiple
                                                limitTags={4}
                                                id="multiple-limit-tags"
                                                disabled={loading}
                                                options={parametersList}
                                                disableCloseOnSelect
                                                loading={loading}
                                                getOptionLabel={(option: any) => option.name}
                                                value={selectedParameters}
                                                onChange={(event: any, newValue: any) => {
                                                    console.log(newValue);
                                                    setSelectedParameters(newValue);
                                                }}
                                                renderTags={(value: any, getTagProps: any) =>
                                                    value.map((option: any, index: any) => (
                                                        <Chip
                                                            size="small"
                                                            // variant="outlined"
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField variant="outlined" {...params} label="Parameters"  
                                                        placeholder="Parameters"
                                                        InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            color: '#007fff',
                                                            fontSize: "1.2rem !important",
                                                        }
                                                    }}
                                                    sx={{
                                                        ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                            fontSize: "1rem !important",
                                                        },
                                                        "&:hover .MuiInputLabel-outlined": {
                                                            color: "primary.main"
                                                          },                                             
                                                         ' & .MuiOutlinedInput-notchedOutline' :{
                                                            borderColor: 'primary.light'
                                                         },
                                                        ".MuiSvgIcon-root": { color: "primary.main" },
                                                    }}/>
                                                )}
                                                sx={{ marginTop: "2rem", width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                            />
                                            </FormControl>
                                            {loading &&  <CircularProgress size={20} sx={{margin:"1rem 0.5rem",marginTop:'2.3rem'}} />  }
                                            </Box>
                                            {parametersList && parametersList.length > 0 &&  <FormControlLabel control={<Checkbox onChange={(e: any) => { e.target.checked ? setSelectedParameters(parametersList) : setSelectedParameters([]) }} />} label="Select All Parameters" />}
                                        </>) :
                                        (
                                            <>
                                                <Autocomplete
                                                    multiple
                                                    limitTags={4}
                                                    id="multiple-limit-tags"
                                                    options={parametersList}
                                                    disableCloseOnSelect
                                                    loading={loading}
                                                    getOptionLabel={(option: any) => option.param_name}
                                                    value={selectedParameters}
                                                    onChange={(event: any, newValue: any) => {
                                                        console.log(newValue);
                                                        setSelectedParameters(newValue);
                                                    }}
                                                    renderTags={(value: any, getTagProps: any) =>
                                                        value.map((option: any, index: any) => (
                                                            <Chip
                                                                size="small"
                                                                // variant="outlined"
                                                                label={option.param_name}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField variant="outlined" {...params} label="Parameters"  
                                                        placeholder="Parameters"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                color: '#007fff',
                                                                fontSize: "1.2rem !important",
                                                            }
                                                        }}
                                                        sx={{
                                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                                fontSize: "1rem !important",
                                                            },
                                                            "&:hover .MuiInputLabel-outlined": {
                                                                color: "primary.main"
                                                              },                                             
                                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                                borderColor: 'primary.light'
                                                             },
                                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                                        }}/>
                                                    )}
                                                    sx={{ marginTop: "2rem", width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                                />
                                                <FormControlLabel control={<Checkbox onChange={(e: any) => { e.target.checked ? setSelectedParameters(parametersList) : setSelectedParameters([]) }} />} label="Select All Parameters" />
                                            </>)

                                    }
                                </>

                            </Box>
                        }
                        {
                            activeStep === 3 && <Box sx={{ width: "100%", padding: "2rem 3rem",marginBottom:'2rem' }}>
                                <Box sx={{display: "flex",alignItems:'center',columnGap:'0.5rem'}}>
                                    <FormControl sx={{ width: "100%", }}>
                                        <TextField
                                            variant="outlined"
                                            type="number"                                        
                                            value={accuracy}
                                            onChange={(e: any) => setAccuracy(e.target.value)}
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#007fff',
                                                    fontSize: "1.2rem !important",
                                                }
                                            }}
                                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                                            label="Part name matching Accuracy %"
                                            placeholder="Part name matching Accuracy %"
                                            sx={{
                                                ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                    fontSize: "1.2rem !important",
                                                },
                                                "&:hover .MuiInputLabel-outlined": {
                                                    color: "primary.main"
                                                },                                             
                                                ' & .MuiOutlinedInput-notchedOutline' :{
                                                    borderColor: 'primary.light'
                                                },                                           
                                            }}
                                        />                                    
                                    </FormControl>
                                    <Tooltip title="Accuracy is the percentage of the part name that should match with the competitor part name.">
                                        <InfoIcon sx={{color:"primary.main",cursor:"pointer"}}/>
                                    </Tooltip>
                                </Box>
                                {selectedAnalysisType?.value === 1 && <Box sx={{ display: "flex",alignItems:'center',columnGap:'0.5rem',marginTop: "2rem",}}>
                                    <FormControl sx={{ width: "100%",}}>
                                    <Autocomplete
                                    options={paretoPercentage}
                                    getOptionLabel={(option: any) => option.text}
                                    value={paretoPercentageSelected}
                                    onChange={(event: any, newValue: any) => {
                                        setParetoPercentageSelected(newValue);
                                    }}
                                    renderTags={(value: any, getTagProps: any) =>
                                        value.map((option: any, index: any) => (
                                            <Chip
                                                size="small"
                                                // variant="outlined"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField  variant="outlined" {...params} label="Pareto Percentage" 
                                        placeholder="Pareto Percentage"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                        }} />
                                        
                                    )}
                                    sx={{width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                    />
                                 </FormControl>
                                    <Tooltip title="Retrieve Pareto analysis data below a specified cumulative percent value.">
                                        <InfoIcon sx={{color:"primary.main",cursor:"pointer"}}/>
                                    </Tooltip>
                                 </Box>
                                 }
                                <Autocomplete
                                    options={reportType}
                                    getOptionLabel={(option: any) => option.text}
                                    value={selectedReportType}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedReportType(newValue);
                                    }}
                                    renderTags={(value: any, getTagProps: any) =>
                                        value.map((option: any, index: any) => (
                                            <Chip
                                                // variant="outlined"
                                                label={option.text}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField variant="outlined" {...params} label="Report" InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                color: '#007fff',
                                                fontSize: "1.2rem !important",
                                            }
                                        }}
                                        sx={{
                                            ".MuiInputBase-input .MuiOutlinedInput-input": {
                                                fontSize: "1rem !important",
                                            },
                                            "&:hover .MuiInputLabel-outlined": {
                                                color: "primary.main"
                                              },                                             
                                             ' & .MuiOutlinedInput-notchedOutline' :{
                                                borderColor: 'primary.light'
                                             },
                                            ".MuiSvgIcon-root": { color: "primary.main" },
                                        }}/>
                                    )}
                                    sx={{ marginTop: "2rem",marginBottom:'2rem', width: "100%",'.MuiChip-root':{backgroundColor:'primary.light',color:'primary.main'} }}
                                />
                            </Box>
                        }
                        <Box sx={{ width: "100%",padding:'1rem 3rem',}}>
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between",alignItems:'center' }}>
                                <Button
                                 size="small"
                                    variant="contained"
                                    // color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ 
                                        "&:hover": {
                                            transform: 'Scale(1.05)',
                                            transition: 'transform 0.5s ease',
                                          },
                                     }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button size="small" variant="contained" disabled={isNextDisabled()} onClick={handleNext}
                                sx={{ 
                                    "&:hover": {
                                        transform: 'Scale(1.05)',
                                        transition: 'transform 0.5s ease',
                                      },
                                 }}>
                                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    

                </Box>
            </Modal>
        </div>
    );
};

export default AddNewCombination;
