import { TextField } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { UserContextIdea } from "./IdeaConfigurationLanding";

export interface IEditNameInputProps {
  val?: any;
  paramId?: any;
  getParamsData: any;
  setCount: any;
  type?: any;
  lockstatus?: any;
}

export function EditNameInput(props: IEditNameInputProps) {
  const { val, paramId, getParamsData, setCount, type , lockstatus} = props;
  const { projectId } = useRouteParams<any>();
  const [name, setName] = React.useState<any>(val);
  const userPremissions = React.useContext(UserContextIdea)

  //   const debouncedAPICall = debounce((value: any) => {
  //     API.put(
  //       `/config/parameter_mapping/${paramId}/`,
  //       {
  //         editable: value,
  //       },
  //       {},
  //       0
  //     )
  //       .then((res: any) => {})
  //       .catch((err: any) => {
  //         setSequence((prev: any) => prev);
  //       });
  //   }, 500);
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      
        await API.put(`/config/idea_config_cost_model/${paramId}/`,{
          name: value,
        },{},0).then((res:any)=>{
            getParamsData()
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setCount((prev:any)=>prev+1)            })
              .catch((err: any) => {      
        
              });
              setCount((prev: any) => prev + 1)
        }).catch((err: any) => {
            setName((prev: any) => prev);
        });
     }, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  return (
    <div>
      <TextField
        // id="outlined-number"
        // label="Number"
        value={name}
        type="text"
        variant="standard"
        
        disabled={lockstatus == true ? lockstatus : userPremissions && userPremissions?.includes("U") ? false : true}
        onChange={(event: any) => {
          const { value } = event.target;
          setName(value);
          ExactDebounce(value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: '4rem',
          ".MuiInputBase-root.MuiInput-root":{
            fontSize:'1rem !important'
          },	
          "& .MuiInput-underline:before": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </div>
  );
}
