import React, {  } from 'react';
import { TableCell, TableRow } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import pdf from "../../Assets/images/Report.svg";
import InfoIcon from '@mui/icons-material/Info';


interface EIRandWorkshopPageBodyRowI{
    WorkshopFileInfoHandler:any;
    lineItem:any;
    indexIncoming:any;
    ViewPdfRedirect:any;
    WorkshoppresentuploadHandler:any;
}

const EIRandWorkshopPageBodyRow:React.FC<EIRandWorkshopPageBodyRowI>=(props)=>{
  const {WorkshopFileInfoHandler,lineItem,indexIncoming,ViewPdfRedirect,WorkshoppresentuploadHandler} = props;

return(
    <TableRow sx={lineItem?.status=="Validated" ? {backgroundColor:'#437d428f'} : lineItem?.status=="Partially Validated" ? {backgroundColor:'#fbec5d'} : {backgroundColor:'#f95959a8'}}>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{indexIncoming}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.subsystem_name}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.abbreviation}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.total_file}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.file_paths_read?.length<1? 0 :lineItem?.file_paths_read?.map((item:any,index:number)=>{
          return <span title={item?.file_name} style={{cursor:'pointer', textDecoration:'underline', marginRight:'0.5rem'}} onClick={()=>ViewPdfRedirect(lineItem?.subsystem_name,index+1)}>{index+1}</span>
        })}
        </TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.total_file_size} MB</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.status}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>
          <FileUploadIcon sx={{ cursor: 'pointer' }} onClick={()=>WorkshoppresentuploadHandler(lineItem?.subsystem_name)}/>
          <InfoIcon sx={lineItem?.file_paths_read?.length < 1 ? { cursor:'not-allowed',color:'gray' } : { cursor: 'pointer' }} onClick={() => lineItem?.file_paths_read?.length > 0 && WorkshopFileInfoHandler(lineItem?.file_paths_read)} />
          <img src={pdf} style={{ height: '1.5rem', marginLeft: '0.5rem',opacity:'0.3', cursor:'not-allowed' }}/>
        </TableCell>
    </TableRow>)
}

export default EIRandWorkshopPageBodyRow;