import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { EditScequenceCostItemsMaterial } from "./EditScequenceCostItemsMaterial";
import { EditItemsName } from "./EditItemsName";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface IEditNameandSequenceModalProps {
  onCloseModal: any;
  isOpen: any;
  itemsData: any;
  getRMData: any;
  CalcculatorUpdatehandler:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function EditNameandSequenceModal(
  props: IEditNameandSequenceModalProps
) {
  const { onCloseModal, isOpen, itemsData, getRMData,CalcculatorUpdatehandler } = props;
  const {vault,scenarioId}=useRouteParams<any>();
  const [itemData, setItemData] = React.useState<any>([]);
  const [materialData, setMaterialData] = React.useState<any>([]);
  React.useEffect(() => {
    if(itemsData){
      setItemData(itemsData);
    }
  }, [itemsData]);
  const getRMDataList = () => {
    API.get(
        "/cost/cost_calculator/",
        {
            vault: vault,
            get_level: "material_calculator",
            scenario:scenarioId?scenarioId:undefined
        },
        0
    )
        .then((res: any) => {
            setMaterialData(res.data[0]);
        })
        .catch((err: any) => { });
};

React.useEffect(()=>{
  getRMDataList();
},[])
  
  return (
    <div>
      <Modal
        disableEscapeKeyDown={true}
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Material Name and Sequence
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={()=>{
                getRMData();
                onCloseModal()}}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ maxHeight: "85vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.light",
                    }}
                  >
                    {" "}
                    Sequence
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.light",
                    }}
                  >
                    {" "}
                    Material Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {materialData?.data?.map((item: any, index: any) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          width: "5rem",
                        }}
                      >
                        <EditScequenceCostItemsMaterial
                          value={item}
                          getRMData={getRMData}
                          idd={item?.id}
                          getRMDataList={getRMDataList}
                          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
                          type={"material"}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <EditItemsName
                          value={item}
                          getRMData={getRMData}
                          idd={item?.id}
                          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
                          getRMDataList={getRMDataList}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
