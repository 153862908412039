import axios from "axios";
import { API } from "../../../api-services"; 
import { ADMIN } from "../../../Redux/Services/admin.service"; 
import { s3Url } from "../../../utlis/s3url.utils"; 

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const RoboFlowImgUploadMultiple = async (
   fileList: any,
   vault: any,
   isUploadingTopImages: any,
   setIsUploadingTopImages: any,
   type?: any,
    imgUploadedTop?:any,
    setImgUploadedTop?:any
) => {
   
   const obj: Array<any> = [];
   const sortAlphaNum = (a: any, b: any) => a.localeCompare(b, 'en', { numeric: true });

   try {
      const response = await API.get("cad/cad_upload/");
      const uploadPromises = Array.from(fileList).map(async (file: any, index: any) => {
         const random: any = Math.floor(Math.random() * 1000000 + 1);
         
         

         const formData = new FormData();
         formData.append("key", `pcb_automation/${vault}/multiple_top_images/${random}_${file.name}`);
         formData.append("Content-Type", file?.type);
         formData.append("x-amz-server-side-encryption", "AES256");
         formData.append("X-Amz-Credential", response.data.data.x_amz_credential);
         formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
         formData.append("X-Amz-Date", response.data.data.x_amz_date);
         formData.append("X-Amz-Signature", response.data.data.aws_policy_signature);
         formData.append("Policy", response.data.data.aws_policy);
         formData.append("file", file, file?.name);

         try {
            const res = await axios.post(s3Url, formData, {
               onUploadProgress: data => {
                  // Set the progress value to show the progress bar
               },
            });

            if (res.status === 204) {
               obj.push(`/pcb_automation/${vault}/multiple_top_images/${random}_${file.name}`);
               console.log(`File uploaded successfully: ${file.name}`);
            }

            // Delay for 500ms before processing the next file
            await sleep(500);
         } catch (err) {
            ADMIN?.toast?.error(`File not uploaded: ${file?.name}`);
         }
      });

      // Wait for all upload promises to resolve
      await Promise.all(uploadPromises);

      if (obj.length === fileList.length) {
         await setIsUploadingTopImages(false);
         await setImgUploadedTop(obj.sort(sortAlphaNum));
       
      }
   } catch (error) {
      console.error("Error fetching upload credentials:", error);
      ADMIN?.toast?.error("Failed to fetch upload credentials.");
   }
};

// ----------------------------------------------------------------------------------

export default RoboFlowImgUploadMultiple;
