import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Divider,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "../../../api-services";
import LoaderImage from "../../../Assets/images/loading-screen-landing.gif";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import RViewerJS from "viewerjs-react";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import { CheckBoxComponent } from "./CheckBoxComponent";
import BOMImagesandVideosView from "./BOMImagesandVideos/BOMImagesandVideosView";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import BOMCalculatorValueInputModal from "./BOMCalculatorValueInputModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { DataGrid } from "@mui/x-data-grid";
import calcIcon from "../../../Assets/images/calculator_green.svg";
import { LoadingButton } from "@mui/lab";
import BOMRoboflowDataFetchComponent from "./BOMRoboflowDataFetchComponent";
import BOMRobotoflowFetchDataTable from "./BOMRobotoflowFetchDataTable";
import RoboflowImageContainer from "./RoboflowImageContainer";
import RoboflowViewImageContainer from "./RoboflowViewImageContainer";
import { ADMIN } from "../../../Redux/Services/admin.service";

export interface IBOMImageBasedSearchModalProps {
  onCloseModal: any;
  isOpen: any;
  tableId: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function BPMPCBRoboflowModal(
  props: IBOMImageBasedSearchModalProps
) {
  const { onCloseModal, isOpen, tableId } = props;
  const { vault } = useRouteParams<any>();
  const [refetchCount, setRefetchCount] = React.useState<any>(0); 
  const [refetchLoader, setRefetchLoader] = React.useState<any>(false); 
  const [data, setData] = React.useState<any>({});
  const [tableTopData, setTableTopData] = React.useState<[]>([]);
  const [tableBottomData, setTableBottomData] = React.useState<[]>([]);
  const [step, setStep] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<any>(false);
  const [viewOnly, setViewOnly] = React.useState<any>(false);
  const [fetchedDetails, setFetchedDetails] = React.useState<any>({
    top: false,
    bottom: false,
  });

  const getData = () => {
    setLoading(true);
    API.get(
      "/pcb/pcb_info_for_vault/",
      {
        vault_id: vault,
      },
      0
    )
      .then((res: any) => {
        setRefetchCount(0)
        setData(res.data);
        setViewOnly(res.data?.data_fetched_to_pcb_table);
        setFetchedDetails({
          top:res.data?.fetched_top_details,
          bottom:res.data?.fetched_bottom_details
        })
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefetch = () => {
    setRefetchLoader(true)
    
    API.delete(`pcb/pcb_info_for_vault/${vault}/`, {delete_all:true}, 0)
         .then((res: any) => {
          setStep(1);
          setRefetchCount(1);
          setData({});
          setTableTopData([]);
          
          setViewOnly(false);
          setFetchedDetails({
            top: false,
            bottom: false,
          });
           getData();
         })
         .catch((err: any) => {
           const { data }: any = err?.response;
           ADMIN.toast.error(`${data?.[0]}`);
         }).finally(()=> {
          setRefetchLoader(false)
         });
}

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(()=> {
    if (viewOnly == false && fetchedDetails?.top == true && fetchedDetails?.bottom == true) {
      setStep(2)
    }
  },[viewOnly, fetchedDetails])

  console.log(step, "image step");

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent:"space-between", height: "2.5rem" }}>
            <Box>
              {step == 2 && <Button 
              startIcon={<ArrowBackIosIcon sx={{fontSize:"1.2rem"}} />} 
              variant="contained" sx={{height:"2rem", fontSize:"1rem", textTransform:"capitalize"}}
              onClick={()=> setStep(1)}
              >
                Prev
              </Button>}
            </Box>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              PCB BOM Details
            </Typography>
            <Box sx={{display:"flex", alignItems:"center", gap:"2rem"}}>
            {step == 1 && (fetchedDetails?.top || fetchedDetails?.bottom) 
            && !viewOnly 
            && <Button 
              endIcon={<ArrowForwardIosIcon sx={{fontSize:"1.2rem"}} />} 
              variant="contained" sx={{height:"2rem", fontSize:"1rem", textTransform:"capitalize"}}
              onClick={()=> setStep(2)}
              >
                Next
              </Button>}
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
          <Box>
            {viewOnly ? (
              <RoboflowViewImageContainer
                step={step}
                setStep={setStep}
                data={data}
                tableDataBottom={tableBottomData}
                setTableDataBottom={setTableBottomData}
                tableDataTop={tableTopData}
                setTableDataTop={setTableTopData}
                loading={loading}
                handleRefetch={handleRefetch}
                refetchLoader={refetchLoader}
              />
            ) : (
              <>
                {step === 1 ? (
                  <>
                    {/* <BOMRoboflowDataFetchComponent
                getData={getData}
                data={data}
                step={step}
                setStep={setStep}
              /> */}
                    <RoboflowImageContainer
                      step={step}
                      setStep={setStep}
                      data={data}
                      tableDataBottom={tableBottomData}
                      setTableDataBottom={setTableBottomData}
                      tableDataTop={tableTopData}
                      setTableDataTop={setTableTopData}
                      loading={loading}
                      fetchedDetails={fetchedDetails}
                      setFetchedDetails={setFetchedDetails}
                      refetchCount={refetchCount}
                    />
                  </>
                ) : (
                  <BOMRobotoflowFetchDataTable
                    tableId={tableId}
                    onCloseModal={onCloseModal}
                    tableDataBottom={tableBottomData}
                    tableDataTop={tableTopData}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
