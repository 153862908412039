import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CostingScenarioTree from "./ScenarioCostingTree";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";
import { userPermissionAction } from "../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { debounce, filter } from "lodash";

import styles from "./ScenarioCostingPage.module.scss";
import ScenarioCostingCategoryDashboard from "./ScenarioCostingCategoryDashboard";
import { ScenarioCostingSection } from "./ScenarioCostingSection";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import SceanrioCostingCreateCategoryTree from "./SceanrioCostingCreateCategoryTree";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ScenarioMenuItems from "./ScenarioMenuItems";
import { ID } from "../../utlis/commonInterfaces";
import { useState } from "react";
import ScenarioHeader from "./ScenarioHeader";
import DownloadIcon from "@mui/icons-material/Download";
import { ADMIN } from "../../Redux/Services/admin.service";
import Swal from "sweetalert2";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";

export interface IScenarioCostingPageProps {
  count: any;
  setStatus: any;
  syncColor?: any;
  setSyncColor?: any;
  scenarioDashboardOpen: any;
  setScenarioDashboardOpen: any;
  costDetailsData?: any;
  setCurrentPage?: any;
  currentPage?: any;
  setTotalcount?: any;
  totalCount?: any;
  scenarioCount?: any;
  updateFinalCostChartData: (data: any) => void;
  setScenarioCount?: any;
  selectedCategory?: any;
  setAddCount?: any;
  addCount?: any;
  decimalPoints?: any;
  setDecimalPoints?: any;
  toggleHighlight?: any;
  selectedScenarios?: any;
  scenarioCostHeaderData?: any;
  showZeroRows?: any;
  filterScenario?: any;
  isFilterActive?: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const SelectableAccordionSummary = styled(AccordionSummary)({
  userSelect: "text", // Allows text selection in the summary
});

export function ScenarioCostingPage(props: IScenarioCostingPageProps) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,

  } = useRouteParams<any>();
  const {
    count,
    setStatus,
    syncColor,
    setSyncColor,
    scenarioDashboardOpen,
    setScenarioDashboardOpen,
    costDetailsData,
    setCurrentPage,
    currentPage,
    setTotalcount,
    totalCount,
    scenarioCount,
    setScenarioCount,
    updateFinalCostChartData,
    selectedCategory,
    setAddCount,
    addCount,
    decimalPoints,
    setDecimalPoints,
    toggleHighlight,
    selectedScenarios,
    scenarioCostHeaderData,
    showZeroRows,
    filterScenario,
    isFilterActive,
  } = props;
  const userProfile = useSelector(userSelectors.selectAll);
  const [treeData, setTreeData] = React.useState<any>([]);
  const [downloadLoader, setDownloadLoader] = React.useState(false);
  const [treeDataLoading, setTreeDataLoading] = React.useState<boolean>(false);
  const [selectedNode, setSelectedNode] = React.useState<any>(null);
  const [selectedNodeCheck, setSelectedNodeCheck] =
    React.useState<boolean>(false);
  const history = useHistory();
  const { width } = useWindowDimensions();

  const descRef = React.useRef<any>();
  const OuterRef = React.useRef<any>();
  const userAction = React.useContext(userPermissionAction);

  // Scenario Costing Section
  const [treeDataHeader, setTreeDataHeader] = React.useState<any>(null);
  const [treeDataMaterial, setTreeDataMaterial] = React.useState<any>(null);
  const [treeDataProcess, setTreeDataProcess] = React.useState<any>(null);
  const [treeDataOverhead, setTreeDataOverhead] = React.useState<any>(null);
  const [treeDataChildCost, setTreeDataChildCost] = React.useState<any>(null);
  const [treeexpanded, setTreeExpanded] = React.useState<string | false>(
    "panel1"
  );

  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [SearchValue, setSearchValue] = React.useState<any>([]);
  const inputRef = React.useRef<any>(null);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);
    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    } else {
      inputRef.current.style.display = "none";
      setSearchValue([]);
    }
    // setSearchValue
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/xcpep/vault/search/", {
        search: criteria,
        top_vault: topVault,
        type: scenarioType == 1 ? null : 2,
      }).then((res: any) => {
        setSearchValue(res.data);
      });
    }, 500)
  ).current;

  // Scenario Popover Menu Option
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<any>(false);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpen(false); // Ensure primary menu opens first
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  };

  const handleOpenSubMenu = () => {
    setIsSubMenuOpen(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const {
    projectname,
    productId,
    abbrebation,
    tableId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    conditionPopup,
    newUI,
    costEstimationId,
    cardtype,
  } = useRouteParams<any>();
  const [sceanrio_data, setSceanrio_data] = React.useState<any>(0);
  const [name, setName] = useState<string>("");
  const [oldName, setOldName] = React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingHeader, setLoadingHeader] = React.useState(false);
  const [loadingMaterial, setLoadingMaterial] = React.useState(false);
  const [loadingProcess, setLoadingProcess] = React.useState(false);
  const [loadingOverhead, setLoadingOverhead] = React.useState(false);
  const [loadingChildCost, setLoadingChildCost] = React.useState(false);
  const [editNameMode, setEditNameMode] = useState<boolean>(false);
  const [pendingRequests, setPendingRequests] = React.useState(0);
  const [estimationCompleted, setEstimationCompleted] = React.useState(false);
  const [hideDisable, setHideDisable] = React.useState<any>(false);
  const [showHideDisable, setShowHideDisable] = React.useState<any>(false);
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>();
  const [completedSteps, setCompletedSteps] = React.useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [Loader, setLoader] = React.useState(false);
  const [TableData, setTableData] = React.useState<any>([]);

  const [isLeaf, setISLeaf] = React.useState<boolean>(false);
  const [costStatus, setCostStatus] = React.useState<any>(null);

  const getObjj = () => {
    if (scenarioCostHeaderData?.vault !== null) {
      return { vault_id: scenarioCostHeaderData?.vault };
    } else {
      return { top_vault: scenarioCostHeaderData?.top_vault };
    }
  };

  const getScenarioTreeData = (type: any, status?: any) => {
    if (status && status == "update") {
    } else {
      if (type === "header") {
        setLoadingHeader(true);
      } else if (type === "material") {
        setLoadingMaterial(true);
      } else if (type === "process") {
        setLoadingProcess(true);
      } else if (type === "overhead") {
        setLoadingOverhead(true);
      } else if (type === "child_cost") {
        setLoadingChildCost(true);
      }
    }

    setPendingRequests((prev) => prev + 1);
    API.get(
      `cost/scenario_hierarchy_data/`,
      {
        // vault_id: vault,
        ...getObjj(),
        // refresh: calcultorId !== 0 ? +calctype || 0 : undefined,
        hide_disabled: false,
        response_type: type,
        page: currentPage + 1,
        selected_scenarios: selectedScenarios?.toString(),
        hide_zero: !showZeroRows
      },
      0
    )
      .then((res: any) => {
        if (type === "header") {
          if (TableData?.[0]?.is_leaf == false) {

          } else {
            setTreeDataHeader(res.data?.data?.[0]);
          }

          const data = res.data?.data?.[0];
          const main_costing = {
            cost: data?.cost,
            name: TableData?.[0]?.name,
            estimation_id: data?.est_id
          }

          updateFinalCostChartData({

            [data?.costing_name]: data?.cost,
            scenarios: [main_costing, ...data?.scenario_data],
          });
        } else if (type === "material") {
          setTreeDataMaterial(res.data);
        } else if (type === "process") {
          setTreeDataProcess(res.data);
        } else if (type === "overhead") {
          setTreeDataOverhead(res.data);
        } else if (type === "child_cost") {
          setTreeDataChildCost(res.data);
        }
        setTreeData(res.data?.data);
        setEstimationCompleted(res.data?.completed);
        if (res.data?.completed === true) {
          setCompletedSteps([true, true, false]);
        }
        let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
        let val: any = JSON.parse(a) || [];
        if (val?.length > 0) {
        } else {
          if (TableData?.[0]?.is_leaf == false) {
            sessionStorage.setItem(
              "Costingconfigurationnodeconfig",
              JSON.stringify([
                "0HierarchyParent",
                "1Parent",
                "2Material",
                "3RawMaterial",
                "4BoughtOutPart",
                "5Process",
                "6Overheads",
                "7ChildCost"
              ])
            );
          } else {
            sessionStorage.setItem(
              "Costingconfigurationnodeconfig",
              JSON.stringify([
                "0HierarchyParent",
                "1Parent",
                "2Material",
                "3RawMaterial",
                "4BoughtOutPart",
                "5Process",
                "6Overheads",
              ])
            );

          }

        }

        if (status && status == "update") {
        } else {
          if (type === "header") {
            setLoadingHeader(false);
          } else if (type === "material") {
            setLoadingMaterial(false);
          } else if (type === "process") {
            setLoadingProcess(false);
          } else if (type === "overhead") {
            setLoadingOverhead(false);
          } else if (type === "child_cost") {
            setLoadingChildCost(false);
          }
        }
      })
      .catch(() => {
        if (type === "header") {
          setLoadingHeader(false);
        } else if (type === "material") {
          setLoadingMaterial(false);
        } else if (type === "process") {
          setLoadingProcess(false);
        } else if (type === "overhead") {
          setLoadingOverhead(false);
        } else if (type === "child_cost") {
          setLoadingChildCost(false);
        }
      })
      .finally(() => {
        // Decrement the counter and check if all requests are done
        setPendingRequests((prev) => {
          const newCount = prev - 1;
          if (newCount === 0) {
            if (type === "header") {
              setLoadingHeader(false);
            } else if (type === "material") {
              setLoadingMaterial(false);
            } else if (type === "process") {
              setLoadingProcess(false);
            } else if (type === "overhead") {
              setLoadingOverhead(false);
            } else if (type === "child_cost") {
              setLoadingChildCost(false);
            } // Hide loader when all requests are done
          }
          return newCount;
        });
      });
  };

  const idList = filterScenario.map((item: any) => item.id).join(',');
  const getScenarioFilterTreeData = (type: any, status?: any) => {
    if (status && status == "update") {
    } else {
      if (type === "header") {
        setLoadingHeader(true);
      } else if (type === "material") {
        setLoadingMaterial(true);
      } else if (type === "process") {
        setLoadingProcess(true);
      } else if (type === "overhead") {
        setLoadingOverhead(true);
      } else if (type === "child_cost") {
        setLoadingChildCost(true);
      }
    }

    setPendingRequests((prev) => prev + 1);
    API.get(
      `cost/scenario_hierarchy_data/`,
      {
        // vault_id: vault,
        ...getObjj(),
        // refresh: calcultorId !== 0 ? +calctype || 0 : undefined,
        hide_disabled: false,
        response_type: type,
        selected_scenarios: idList,
        hide_zero: !showZeroRows
      },
      0
    )
      .then((res: any) => {
        if (type === "header") {
          if (TableData?.[0]?.is_leaf == false) {

          } else {
            setTreeDataHeader(res.data?.data?.[0]);
          }

          const data = res.data?.data?.[0];
          const main_costing = {
            cost: data?.cost,
            name: TableData?.[0]?.name,
            estimation_id: data?.est_id
          }

          updateFinalCostChartData({

            [data?.costing_name]: data?.cost,
            scenarios: [main_costing, ...data?.scenario_data],
          });
        } else if (type === "material") {
          setTreeDataMaterial(res.data);
        } else if (type === "process") {
          setTreeDataProcess(res.data);
        } else if (type === "overhead") {
          setTreeDataOverhead(res.data);
        } else if (type === "child_cost") {
          setTreeDataChildCost(res.data);
        }
        setTreeData(res.data?.data);
        setEstimationCompleted(res.data?.completed);
        if (res.data?.completed === true) {
          setCompletedSteps([true, true, false]);
        }
        let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
        let val: any = JSON.parse(a) || [];
        if (val?.length > 0) {
        } else {
          if (TableData?.[0]?.is_leaf == false) {
            sessionStorage.setItem(
              "Costingconfigurationnodeconfig",
              JSON.stringify([
                "0HierarchyParent",
                "1Parent",
                "2Material",
                "3RawMaterial",
                "4BoughtOutPart",
                "5Process",
                "6Overheads",
                "7ChildCost"
              ])
            );
          } else {
            sessionStorage.setItem(
              "Costingconfigurationnodeconfig",
              JSON.stringify([
                "0HierarchyParent",
                "1Parent",
                "2Material",
                "3RawMaterial",
                "4BoughtOutPart",
                "5Process",
                "6Overheads",
              ])
            );

          }

        }

        if (status && status == "update") {
        } else {
          if (type === "header") {
            setLoadingHeader(false);
          } else if (type === "material") {
            setLoadingMaterial(false);
          } else if (type === "process") {
            setLoadingProcess(false);
          } else if (type === "overhead") {
            setLoadingOverhead(false);
          } else if (type === "child_cost") {
            setLoadingChildCost(false);
          }
        }
      })
      .catch(() => {
        if (type === "header") {
          setLoadingHeader(false);
        } else if (type === "material") {
          setLoadingMaterial(false);
        } else if (type === "process") {
          setLoadingProcess(false);
        } else if (type === "overhead") {
          setLoadingOverhead(false);
        } else if (type === "child_cost") {
          setLoadingChildCost(false);
        }
      })
      .finally(() => {
        // Decrement the counter and check if all requests are done
        setPendingRequests((prev) => {
          const newCount = prev - 1;
          if (newCount === 0) {
            if (type === "header") {
              setLoadingHeader(false);
            } else if (type === "material") {
              setLoadingMaterial(false);
            } else if (type === "process") {
              setLoadingProcess(false);
            } else if (type === "overhead") {
              setLoadingOverhead(false);
            } else if (type === "child_cost") {
              setLoadingChildCost(false);
            } // Hide loader when all requests are done
          }
          return newCount;
        });
      });
  };


  const getHideDisableData = () => {
    API.get(
      "/cost/check_status_hide_CM/",
      {
        cost_est_id: costEstimationId,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data);

        setShowHideDisable(res?.data?.status);
      })
      .catch(() => {
        console.log("Server Error");
      });
  };

  const getTableData = () => {
    if (!isFilterActive) {
      setLoader(true);
      API.get(
        "/cost/scenario_data_header/",
        { ...getObj(), page: currentPage + 1 },
        0
      )
        .then((res: any) => {
          setTableData(res?.data);
          setISLeaf(res?.data[0]?.is_leaf);
          setCostStatus(res?.data[0]?.cost_status);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Sever Error");
          setLoader(false);
        });
    }
  };

  const getFilteredTableData = () => {
    if (isFilterActive) {
      setLoader(true);
      API.get(
        "/cost/scenario_data_header/",
        { ...getObj(), selected_scenarios: idList },
        0
      )
        .then((res: any) => {
          // console.log(res?.data);
          setTableData(res?.data);
          setISLeaf(res?.data[0]?.is_leaf);
          setCostStatus(res?.data[0]?.cost_status);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Sever Error");
          setLoader(false);
        });
    }
  };

  React.useEffect(() => {
    getFilteredTableData();
    if (vaultinfo?.vault_id !== null && vaultinfo?.vault_id !== undefined) {
      getScenarioFilterTreeData("header");
      getScenarioFilterTreeData("material");
      getScenarioFilterTreeData("process");
      getScenarioFilterTreeData("overhead");
    }

    if (TableData?.[0]?.is_leaf == false) {
      getScenarioFilterTreeData("header");
      getScenarioFilterTreeData("child_cost");
    }
  }, [filterScenario]);

  const getPageCount = () => {
    API.get(
      "/cost/scenario_data_header/",
      { ...getObj(), total_page_count: true },
      0
    )
      .then((res: any) => {
        setTotalcount(res.data?.total_count);
      })
      .catch((err: any) => {
        console.log("Sever Error");
        setLoader(false);
      });
  };

  // console.log("TableData", TableData);


  // React.useEffect(() => {
  //   const decimal = localStorage.getItem("decimalPoint");
  //   if (decimal) {
  //     setDecimalPoints(Number(decimal));
  //   } else {
  //     setDecimalPoints(3);
  //   }
  // }, [localStorage]);
  const vaultinfo = getObjj()



  React.useEffect(() => {

    if (!isFilterActive && TableData?.length > 0) {

      if (vaultinfo?.vault_id !== null && vaultinfo?.vault_id !== undefined) {
        getScenarioTreeData("header");
        getScenarioTreeData("material");
        getScenarioTreeData("process");
        getScenarioTreeData("overhead");
      }

      if (TableData?.[0]?.is_leaf == false) {
        getScenarioTreeData("header");
        getScenarioTreeData("child_cost");
      }
    }
    // }
    getHideDisableData();
  }, [
    currentPage,
    scenarioCount,
    selectedScenarios,
    hideDisable,
    tableCounter,
    hideDisable,
    TableData,
    showZeroRows,
    filterScenario, isFilterActive
  ]);

  const getCalcData = (data: any) => {
    setSelectedTreeData(data);
  };

  React.useEffect(() => {
    getCalcData(selectedTreeData);
    getTableData();
    getPageCount();
  }, [currentPage, isFilterActive]);


  const [active, setActive] = React.useState<ID>(0);
  const [calculatorData, setCalculatorData] = React.useState<any>({});
  const onSelect = (nodeId: any, id: any, HierarchyAllData: any) => {
    // setOpenForm("");
    if (HierarchyAllData?.type === "calculator") {
      setActive(HierarchyAllData?.id);
    }
  };

  // const [editMode, setEditMode] = useState(Array(5).fill(false));
  // const [scenarioValues, setScenarioValues] = useState(
  //   Array(5)
  //     .fill("")
  //     .map((_, index) => `Scenario ${index + 1}`)
  // );
  // const handleEditToggle = (index: any) => {
  //   setEditMode((prev) => {
  //     const newEditMode = [...prev];
  //     newEditMode[index] = !newEditMode[index];
  //     return newEditMode;
  //   });
  // };

  // const handleInputChange = (index: any, value: any) => {
  //   setScenarioValues((prev) => {
  //     const newValues = [...prev];
  //     newValues[index] = value;
  //     return newValues;
  //   });
  // };



  React.useEffect(() => {
    if (addCount > 0) {
      getTableData();
      getPageCount();
    }
  }, [addCount]);

  React.useEffect(() => {
    setName(TableData?.[0]?.name);
    setOldName(TableData?.[0]?.name);
  }, [TableData]);

  // const handleEditName = () => { };

  const updateDataVaultParams = async (val: any, tableData: any) => {
    if (oldName != val) {
      // setLoading(true);
      API.put(
        `/cost/scenario_data_header/`,
        {
          top_vault: tableData?.[0]?.top_vault,
          validated: false,
          main_costing_name: val,
        },
        {},
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Name updated successfully");
          setOldName(name);
          getPageCount();
          // API.get(
          //   "/cost/scenario_data_header/",
          //   { ...getObj(), page: currentPage + 1 },
          //   0
          // )
          //   .then((res: any) => {

          //     setTableData(res?.data);

          //   })
          //   .catch((err: any) => {
          //     console.log("Sever Error");
          //     setLoader(false);
          //   });
        })

        .catch((err: any) => {
          ADMIN.toast.error("Something went wrong");
        })
        .finally(() => {
          setEditNameMode(false);
        });
    }
  };

  const ExactDebounceParameters = React.useRef(
    debounce(async (val: any, tableData) => {
      await updateDataVaultParams(val, tableData);
    }, 1000)
  ).current;

  const reDirectToMainCosting = () => {
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${TableData?.[0]?.product_name}/${topVault}/${TableData?.[0]?.vault}/null/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const individualScenarioDownload = (scenId: any) => {
    setDownloadLoader(true);
    API.get(
      "customer/individual_report/",
      {
        vault: vault,
        individual_type: "Costing",
        scenario: scenId,
      },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch((err: any) => { })
      .finally(() => {
        setDownloadLoader(false);
      });
  };






  return (
    <div>
      <Box
        sx={{
          // display: "flex",
          // alignItems: "flex-start",
          width: "100%",
          border: "1px solid #ddefff",
          borderRadius: "10px",
          // overflow: "hidden",
        }}
      >
        <Box sx={{ width: "100%", padding: "0" }}>
          <Box
            sx={{
              height: { lg: "86vh", xl: "89vh" },
              width: "100%",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                backgroundColor: "primary.light",
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 0.2rem",
                  // fontWeight: "bold",
                  width: "7rem",
                  textAlign: "center",
                  backgroundColor: "primary.light",
                }}
              ><Typography sx={{ fontSize: "1rem", }}>
                  Qty</Typography>
              </Box>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  padding: "0 0.2rem",
                  // fontWeight: "bold",
                  width: "5rem",
                  textAlign: "center",
                  backgroundColor: "primary.light",
                }}
              ><Typography sx={{ fontSize: "1rem", }}>
                  Currency</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "57vw",
                  backgroundColor: "primary.light",
                  marginRight: { lg: "0.65rem", xl: "0.6rem" },
                }}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    padding: "0 0.2rem",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "primary.light",
                    borderLeft: "1px solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Tooltip title={TableData?.[0]?.name} placement="top">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        {/* {<LockOpenIcon} */}

                        {TableData?.[0]?.lock ? (
                          <LockIcon
                            sx={{ fontSize: "1.2rem", color: "green" }}
                            onClick={() => {
                              if (TableData?.[0]?.action?.includes("U")) {
                                API.put(
                                  `/cost/scenario/${TableData?.[0]?.id}/`,
                                  {
                                    lock: false,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    Swal.fire({
                                      title: "Success",
                                      text: "Scenario has been unlocked successfully",
                                      icon: "success",
                                      confirmButtonColor: "#007FFF",
                                    });
                                    getTableData();
                                  })
                                  .catch((err: any) => {
                                    const { data } = err.response;
                                    Swal.fire({
                                      title: "Error",
                                      text: data[0],
                                      icon: "error",
                                      confirmButtonColor: "#007FFF",
                                    });
                                  });
                              }
                            }}
                          />
                        ) : (
                          <LockOpenIcon
                            sx={{ fontSize: "1.2rem", color: "red" }}
                            onClick={() => {
                              if (TableData?.[0]?.action?.includes("U")) {
                                API.put(
                                  `/cost/scenario/${TableData?.[0]?.id}/`,
                                  {
                                    lock: true,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    Swal.fire({
                                      title: "Success",
                                      text: "Scenario has been unlocked successfully",
                                      icon: "success",
                                      confirmButtonColor: "#007FFF",
                                    });
                                    getTableData();
                                  })
                                  .catch((err: any) => {
                                    const { data } = err.response;
                                    Swal.fire({
                                      title: "Error",
                                      text: data[0],
                                      icon: "error",
                                      confirmButtonColor: "#007FFF",
                                    });
                                  });
                              }
                            }}
                          />
                        )}
                        {TableData?.[0]?.independent ? "(I)" : "(D)"}
                        {/* <LockIcon sx={{ fontSize: '1.2rem', color: TableData?.[0]?.lock ? 'green' : 'red' }} /> */}
                        {editNameMode ? (
                          <TextField
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            // onBlur={handleEditName}
                            onBlur={(event: any) => {
                              if (event?.target.value !== oldName) {
                                ExactDebounceParameters(name, TableData);
                              }
                            }}
                            autoFocus
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width:
                                  TableData?.length <= 4
                                    ? "100%"
                                    : TableData?.length <= 5
                                      ? { lg: "7rem", xl: "9rem" }
                                      : { lg: "4rem", xl: "7rem" },
                                textOverflow: "ellipsis",
                                fontSize: "1rem",
                              }}

                            >
                              {TableData?.[0]?.name}{" "}
                            </Typography>
                            <span>
                              <EditIcon sx={{ fontSize: "1.2rem", cursor: "pointer", marginTop: "0.4rem", marginLeft: "0.5rem" }} onClick={() => setEditNameMode(true)} />
                            </span>
                          </Box>
                        )}
                      </Box>
                    </Tooltip>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        padding: "0",
                      }}
                    >
                      {/* <DownloadIcon /> */}
                      <IconButton
                        aria-controls={open ? "primary-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickMenu}
                        sx={{ padding: "0" }}
                      >
                        <MenuIcon
                          sx={{ fontSize: "1.5rem", color: "primary.main" }}
                        />
                      </IconButton>

                      {/* First Menu */}
                      <Menu
                        id="primary-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        sx={{
                          padding: "0 !important",
                          paddingTop: "0 !important",
                          paddingBottom: "0 !important",
                          "& .MuiList-root .MuiMenu-list": {
                            padding: "0",
                          },
                        }}
                      >
                        <Box
                          // display="flex"
                          // alignItems="center"
                          // flexDirection="column"
                          padding="0 0.1rem"
                          sx={{ gap: "0.25rem" }}
                        >
                          <MenuItem
                            onClick={() => {
                              if (TableData?.[0]?.action?.includes("R")) {
                                console.log("Costing View clicked");
                              }
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: "primary.main",
                                cursor: downloadLoader ? "default" : "pointer",
                                fontSize: "1.65rem",
                              }}
                              onClick={() => {
                                if (!downloadLoader) {
                                  individualScenarioDownload(
                                    TableData?.[0]?.id
                                  );
                                }
                              }}
                            >
                              <DownloadIcon
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                  fontSize: "1.65rem",
                                }}
                              />
                              <Typography sx={{ marginLeft: "0.5rem" }}>
                                Download Report
                              </Typography>
                              {downloadLoader && (
                                <CircularProgress
                                  size="15px"
                                  sx={{ marginLeft: "0.25rem" }}
                                />
                              )}
                            </ListItemIcon>
                          </MenuItem>

                          <MenuItem
                            onClick={() => console.log("Costing View clicked")}
                          >
                            <ListItemIcon
                              sx={{
                                color: "primary.main",
                                cursor: "pointer",
                                fontSize: "1.65rem",
                              }}
                              onClick={() => {
                                if (TableData?.[0]?.action?.includes("R")) {
                                  sessionStorage.setItem("node", JSON.stringify(TableData?.[0]?.ancestors))
                                  window.open(
                                    `/#/view/${projectId}/${topVault}/${TableData?.[0]?.vault}/null/costing`
                                  );
                                }
                              }}
                            >
                              <OpenInNewIcon
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                  fontSize: "1.65rem",
                                }}
                              />
                              <Typography sx={{ marginLeft: "0.5rem" }}>
                                Costing View Page
                              </Typography>
                            </ListItemIcon>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              if (TableData?.[0]?.action?.includes("R")) {

                                sessionStorage.setItem("nodeCostingEstimate", JSON.stringify(TableData?.[0]?.ancestors))
                                // "/createcostingpart/:projectId/:projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId/:scenarioId/:conditionPopup/:newUI",
                                window.open(
                                  `/#/createcostingpart/${projectId}/${projectName}/${TableData?.[0]?.product_name}/${topVault}/${TableData?.[0]?.vault}/null/0/0/0/0/0/0/0/0/0/true`
                                );
                              }
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: "primary.main",
                                cursor: "pointer",
                                fontSize: "1.65rem",
                              }}
                            >
                              <OpenInNewIcon
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                  fontSize: "1.65rem",
                                }}
                              />
                              <Typography sx={{ marginLeft: "0.5rem" }}>
                                Costing Create Page
                              </Typography>
                            </ListItemIcon>
                          </MenuItem>
                        </Box>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
                {TableData?.slice(1).map((item: any, index: any) => {
                  return (
                    <ScenarioHeader
                      item={item}
                      getTableData={getTableData}
                      userProfile={userProfile}
                      TableData={TableData}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ zIndex: 0, position: "relative" }}>
              {/* {!loading ? ( */}
              <>
                {loadingHeader ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    ))}
                    <Box sx={{ padding: "0 0.2rem" }}>
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    </Box>
                  </>
                ) : (
                  <>
                    {treeDataHeader?.name &&
                      <SceanrioCostingCreateCategoryTree
                        HierarchyData={treeDataHeader}
                        type="header"
                        onSelectCallback={onSelect}
                        setSelectedTreeData={getCalcData}
                        // setCounterApiCall={setCounterApiCall}
                        useraction="CRUD"
                        getScenarioTreeData={isFilterActive ? getScenarioFilterTreeData : getScenarioTreeData}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        // setOpenForm={setOpenForm}
                        decimalPoints={decimalPoints}
                        TableData={TableData}
                        setScenarioCount={setScenarioCount}
                        setDecimalPoints={setDecimalPoints}
                        toggleHighlight={toggleHighlight}
                        userProfile={userProfile}
                        reDirectToMainCosting={reDirectToMainCosting}
                      />}
                  </>

                )}

                {loadingMaterial ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    ))}
                    <Box sx={{ padding: "0 0.2rem" }}>
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    </Box>
                  </>
                ) : (
                  <>
                    {treeDataMaterial?.name && <SceanrioCostingCreateCategoryTree
                      HierarchyData={treeDataMaterial}
                      type="material"
                      onSelectCallback={onSelect}
                      setSelectedTreeData={getCalcData}
                      // setCounterApiCall={setCounterApiCall}
                      useraction="CRUD"
                      getScenarioTreeData={isFilterActive ? getScenarioFilterTreeData : getScenarioTreeData}
                      calculatorData={calculatorData}
                      setCalculatorData={setCalculatorData}
                      // setOpenForm={setOpenForm}
                      decimalPoints={decimalPoints}
                      TableData={TableData}
                      setScenarioCount={setScenarioCount}
                      setDecimalPoints={setDecimalPoints}
                      toggleHighlight={toggleHighlight}
                      userProfile={userProfile}
                      reDirectToMainCosting={reDirectToMainCosting}
                      
                    // SelectedmulIds={SelectedmulIds}
                    // AddBOPRefreshTree={AddBOPRefreshTree}
                    />}
                  </>
                )}
                {loadingProcess ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    ))}
                    <Box sx={{ padding: "0 0.2rem" }}>
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    </Box>
                  </>
                ) : (
                  <>
                    {treeDataProcess?.name && <SceanrioCostingCreateCategoryTree
                      HierarchyData={treeDataProcess}
                      type="process"
                      onSelectCallback={onSelect}
                      setSelectedTreeData={getCalcData}
                      // setCounterApiCall={setCounterApiCall}
                      useraction="CRUD"
                      getScenarioTreeData={isFilterActive ? getScenarioFilterTreeData : getScenarioTreeData}
                      calculatorData={calculatorData}
                      setCalculatorData={setCalculatorData}
                      // setOpenForm={setOpenForm}
                      decimalPoints={decimalPoints}
                      TableData={TableData}
                      setScenarioCount={setScenarioCount}
                      setDecimalPoints={setDecimalPoints}
                      toggleHighlight={toggleHighlight}
                      userProfile={userProfile}
                      reDirectToMainCosting={reDirectToMainCosting}
                      
                    // SelectedmulIds={SelectedmulIds}
                    // AddBOPRefreshTree={AddBOPRefreshTree}
                    />}
                  </>
                )}

                {loadingOverhead ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    ))}
                    <Box sx={{ padding: "0 0.2rem" }}>
                      <Skeleton sx={{ height: "2rem", width: "100%" }} />
                    </Box>
                  </>
                ) : (
                  <>
                    {treeDataOverhead?.name &&
                      <SceanrioCostingCreateCategoryTree
                        HierarchyData={treeDataOverhead}
                        type="overhead"
                        onSelectCallback={onSelect}
                        setSelectedTreeData={getCalcData}
                        // setCounterApiCall={setCounterApiCall}
                        useraction="CRUD"
                        getScenarioTreeData={isFilterActive ? getScenarioFilterTreeData : getScenarioTreeData}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        // setOpenForm={setOpenForm}
                        decimalPoints={decimalPoints}
                        TableData={TableData}
                        setScenarioCount={setScenarioCount}
                        setDecimalPoints={setDecimalPoints}
                        toggleHighlight={toggleHighlight}
                        userProfile={userProfile}
                        reDirectToMainCosting={reDirectToMainCosting}
                        
                      // SelectedmulIds={SelectedmulIds}
                      // AddBOPRefreshTree={AddBOPRefreshTree}
                      />
                    }
                  </>
                )}

                {!TableData?.[0]?.is_leaf &&
                  (loadingChildCost ? (
                    <>
                      {[...Array(10)].map((_, index) => (
                        <Skeleton sx={{ height: "2rem", width: "100%" }} />
                      ))}
                      <Box sx={{ padding: "0 0.2rem" }}>
                        <Skeleton sx={{ height: "2rem", width: "100%" }} />
                      </Box>
                    </>
                  ) : (
                    <>
                      {treeDataChildCost?.name && <SceanrioCostingCreateCategoryTree
                        HierarchyData={treeDataChildCost}
                        type="child_cost"
                        onSelectCallback={onSelect}
                        setSelectedTreeData={getCalcData}
                        // setCounterApiCall={setCounterApiCall}
                        useraction="CRUD"
                        getScenarioTreeData={isFilterActive ? getScenarioFilterTreeData : getScenarioTreeData}
                        calculatorData={calculatorData}
                        setCalculatorData={setCalculatorData}
                        // setOpenForm={setOpenForm}
                        decimalPoints={decimalPoints}
                        TableData={TableData}
                        setScenarioCount={setScenarioCount}
                        setDecimalPoints={setDecimalPoints}
                        toggleHighlight={toggleHighlight}
                        userProfile={userProfile}
                        reDirectToMainCosting={reDirectToMainCosting}

                      // SelectedmulIds={SelectedmulIds}
                      // AddBOPRefreshTree={AddBOPRefreshTree}
                      />}
                    </>
                  ))}
              </>
              {/* // ) : (
              //   <>
              //     {[...Array(20)].map((_, index) => (
              //       <Skeleton sx={{ height: "2rem", width: "100%" }} />
              //     ))}
              //     <Box sx={{ padding: "0 0.2rem" }}>
              //       <Skeleton sx={{ height: "2rem", width: "100%" }} />
              //     </Box>
              //   </>
              // )} */}
            </Box>
          </Box>
          {/* @ts-ignore */}
          {/* {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] ? (
            scenarioDashboardOpen ? (
              <ScenarioCostingCategoryDashboard />
            ) : (
              <ScenarioCostingSection count={count} />
            )
          ) : (
            <ScenarioCostingCategoryDashboard />
          )} */}
        </Box>
      </Box>
    </div>
  );
}
