import React, { useEffect, useRef, useState } from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import styles from './IdeaEntryInnerPage.module.scss';
import { Box, Button, IconButton, Menu, MenuItem, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, Typography } from '@mui/material';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { MODULES } from '../../../Constants/modules.constants';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import IdeaEntryMiddlePortion from './IdeaEntryMiddlePortion';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RViewerJS from 'viewerjs-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isUndefined } from 'lodash';
import Image from '../../../ViewModule/FeatureLandingPage/ArchitectureImageCard'
import NoImgLoader from '../../../Assets/images/loading-screen.gif'
import { DialogComponent } from "../../../utlis/DialogComponent";
import DownloadIcon from '@mui/icons-material/Download';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import IdeaClientWorkflow from './IdeaClientWorkflow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface Params {
  projectId: any;
  topVaultId: any;
  abbreviation: any;
  vaultId: any;
  ideaId: any;
}

interface IdeaEntryInnerPageProps { }

const IdeaTypesList = [
  { key: 1, value: 1, text: "Alternate Material" },
  { key: 2, value: 2, text: "Supplier Change" },
  { key: 3, value: 3, text: "Mfg. Process Change" },
  { key: 4, value: 4, text: "Packaging Change" },
  { key: 5, value: 5, text: "Joinery Type Change" },
  { key: 6, value: 6, text: "Thickness Change" },
  { key: 7, value: 7, text: "Diameter Change" },
  { key: 8, value: 8, text: "Styling Change" },
  { key: 9, value: 9, text: "Routing Change" },
  { key: 10, value: 10, text: "De-Content" },
  { key: 11, value: 11, text: "Design Change" },
  { key: 12, value: 12, text: "Cosumable Reduction" },
  { key: 13, value: 13, text: "Fastener Reduce/ Change" },
  { key: 14, value: 14, text: "Coating Thickness Change" },
  { key: 15, value: 15, text: "Coating Type Change" },
  { key: 16, value: 16, text: "Part/Feature Deletion" },
].sort((a, b) => a.text.localeCompare(b.text));

const IdeaParameters = [{ name: "Est. Cost Saving (INR)", parameterName: "cost" }, { name: "Est. Weight Saving (Gram)", parameterName: "weight" }, { name: "Implementation Time (Day)", parameterName: "duration" }, { name: "Ease (1-10)", parameterName: "ease" }, { name: "Idea Source", parameterName: "origin" }, { name: "Status", parameterName: "stage" }, { name: "Parameter", parameterName: "parameter" }];

const IdeaEntryInnerPage: React.FC<IdeaEntryInnerPageProps> = (props) => {
  const { projectId, topVaultId, abbreviation, vaultId, ideaId } = useRouteParams<Params>();
  const clientProposalRef = useRef('');

  useEffect(()=>{
    sessionStorage.setItem("historyProject",projectId);
  },[projectId])
  
  const [clientProposal, setClientProposal] = useState<string>('');
  const [ideaData, setIdeaData] = useState<any>([]);
  const [clientProposalError, setClientProposalError] = useState<boolean>(false);
  const [imageIdea, setImageIdea] = useState<any>();
  const [documentIdea, setdocumentIdea] = useState<any>();
  const imageViewerElRef = useRef<any>(null);
  let [apiCallId, setApiCallId] = useState<boolean>(false);
  let [apiDataSuspense, setApiDataSuspense] = useState<boolean>(true);
  let [imageClicked, setImageClicked] = useState<any>();
  let [imageLoader, setImageLoader] = useState<any>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory<any>();
  const [isActive, setIsActive] = useState(true);
  const openDialogModal = useBaseModal();
  const [workflowState, setWorkflowState] = useState<any>(true);
  const [StatusCounter, setStatusCounter] = useState<any>(0);


  useEffect(() => {
    setApiDataSuspense(true);
    API.get(`customer/idea_data/`, { get_idea_details: ideaId, module: MODULES.IDEA_APPROVAL, vault_id: vaultId }, 0).then((res: any) => {
      setIdeaData(res?.data);
      setApiDataSuspense(false);
      setClientProposal(res?.data[0]?.parameter_json[0]?.value);
      clientProposalRef.current = res?.data[0]?.parameter_json[0]?.value;
    }).catch((err: any) => {
      //  ADMIN.toast.throwError(err, "Error while fetching idea data")
       })
  }, [ideaId,StatusCounter, apiCallId]);


  useEffect(() => {
    setImageLoader(true);
    API.get(`customer/idea_data/`, { get_idea_details: ideaId, get_data: 'images' }, 0).then((res: any) => {
      setImageIdea(res?.data);
      setImageLoader(false);
    }).catch((err: any) => { 
      // ADMIN.toast.throwError(err, "Error while fetching idea image")
      setImageLoader(false);
     })
  }, [ideaId]);

  useEffect(() => {
    API.get(`customer/idea_data/`, { get_idea_details: ideaId, get_data: 'documents' }, 0).then((res: any) => {
      setdocumentIdea(res?.data);
    }).catch((err: any) => { 
      // ADMIN.toast.throwError(err, "Error while fetching idea document") 
    
    })
  }, [ideaId]);

  const IdeaStage: string = ideaData && ideaData[0]?.stage == 0 && ideaData && ideaData[0]?.rejected == false
    ? "Initiated"
    : ideaData && ideaData[0]?.stage == 1 && ideaData && ideaData[0]?.rejected == false
      ? "In Review"
      : ideaData && ideaData[0]?.stage == 2 && ideaData && ideaData[0]?.rejected == false
        ? "Submitted"
        : ideaData && ideaData[0]?.stage == 3 && ideaData && ideaData[0]?.rejected == false
          ? "Accepted"
          : "Rejected";

  const IdeaType: string = IdeaTypesList?.filter((item: any) => {
    return item?.value == ideaData[0]?.idea_type
  })?.[0]?.text;

  //reject
  const handleReject = (rejected: boolean) => {
    swal({
      title: "Are You Sure",
      buttons: ["Cancel", "Yes, Reject idea"],
      icon: "warning",
    }).then((confirm) => {
      if (confirm) {
        if (clientProposal.toUpperCase() != "NA" && clientProposal.split(' ').length >= 5) {
          setClientProposalError(false);
          API.put(`/customer/idea_data/${ideaId}/`, { rejected: rejected, parameter_json: { [ideaData && ideaData[0]?.parameter_json[0]?.param_id]: clientProposal } }).then((res: any) => {
            setApiCallId(!apiCallId)
            setStatusCounter((prev: any) => prev + 1);
          }).catch((err) => {
            // ADMIN.toast.throwError(err, "Idea rejected error");
          });
        }
        else {
          setClientProposalError(true);
        }
      }
    });
    //
  };

  const handleCollapseClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive(!isActive);
  };

  const handleStageUpdate: any = (stageGet: any) => {
    if (clientProposal.toUpperCase() != "NA" && clientProposal.split(' ').length >= 5) {
      setClientProposalError(false);
      API.put(`/customer/idea_data/${ideaId}/`, { stage: stageGet, parameter_json: { [ideaData && ideaData[0]?.parameter_json[0]?.param_id]: clientProposal } }).then((res: any) => {
        setApiCallId(!apiCallId)
        setStatusCounter((prev: any) => prev + 1);
      }).catch((err) => {
        // ADMIN.toast.throwError(err, "Idea accepting error");
      });
    }
    else {
      setClientProposalError(true);
    }
  }

  const backButtonHandler: any  = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTextAreaChange: any = (e: any) => {
    setClientProposal(e.target.value);
  }

  const handleCallApiOnBlur: any = () => {
    if (clientProposalRef.current != clientProposal) {
      if (clientProposal.toUpperCase() != "NA" && clientProposal.split(' ').length >= 5) {
        setClientProposalError(false);
        API.put(`/customer/idea_data/${ideaId}/`, { parameter_json: { [ideaData && ideaData[0]?.parameter_json[0]?.param_id]: clientProposal } }).then((res: any) => {

        }).catch((err) => {
          // ADMIN.toast.throwError(err, "Idea proposal submitting error");
        });
        clientProposalRef.current = clientProposal;
      }
      else {
        setClientProposalError(true);
      }
    }
  }

  const handleImageClick = (e: any, image: string) => {
    setImageClicked(image);
    // @ts-ignore
// imageViewerElRef.current.src = image;
    return imageViewerElRef.current?.click?.();
  };

  let parameterKeys:any = !apiDataSuspense  && ideaData && !(ideaData[0]?.origin == 2 || ideaData[0]?.origin == 5) && ideaData[0]?.base_numeric!== null && ideaData[0]?.base_numeric!== undefined && Object?.keys(!apiDataSuspense && ideaData[0]?.base_numeric);

  const handleRedirectUrl:any=(type:any)=>{
      if(type ==='IdeaApproveTable'){
        history.push(`/idea/${projectId}/${topVaultId}/${abbreviation}/${vaultId}/`);
        handleClose();
      }
      else{
        history.push(`/idea_dashboard/${projectId}/${topVaultId}/`);
        handleClose();
      }
  }


  const handleExcelReport = async (idea_id: any) => {
    handleClose();
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          idea_id: idea_id,
          individual_type: "Idea"
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {
    }
  }

  const workflowHandler = () => {
    setWorkflowState((current: any) => !current);
  };
  


  return (<>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',paddingRight:'1rem'}} >
        <Box sx={{display:'flex'}}>
          <IconButton sx={{ cursor: 'pointer' }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              ".MuiList-root.MuiMenu-list": {
                padding: 0,
              }
            }}
            >
            <MenuItem sx={{fontSize:'1rem'}} onClick={()=>handleRedirectUrl('IdeaApproveTable')}>Idea Approve Table</MenuItem>
            <MenuItem sx={{fontSize:'1rem'}} onClick={()=>handleRedirectUrl('IdeaDashboard')}>Idea Dashboard</MenuItem>
          </Menu>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'flex-end'}}>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: "3rem",
                  lineHeight: 1,
                  padding: "0.1rem 1rem",
                  display:'flex',
                  alignItems:'center',
                  columnGap:'0.5rem',
                  backgroundColor: workflowState == false ? "primary.main" : "",
                  color: workflowState == false ? "white" : "",
                  borderColor: workflowState == false ? "primary.main" : "",
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                   }
                }}
                title={
                  workflowState == true ? "Hide Workflow" : "Show Workflow"
                }
                onClick={workflowHandler}
              >
                Workflow {workflowState == true ? <VisibilityOffIcon />:  <VisibilityIcon />}
              </Box>

            <Button              
              className="btnlogin"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() => handleExcelReport(ideaId)}
              size="small">
              <span style={{ fontSize: '1rem', fontWeight: '500', }}> Download</span> <DownloadIcon />
            </Button>
          </Box>
      </Box>
      
      <div className={styles.container}>
          <div>
            {!apiDataSuspense?<>
            <div style={{ display: "none" }}>
              {/* @ts-ignore */}
              <RViewerJS>           
                {/* @ts-ignore */}
                <Image src={imageClicked} placeholderImg={NoImgLoader} alt="image" ref={imageViewerElRef} style={{display:'none'}}/>
              </RViewerJS>
            </div>
            <Box sx={{ height: '90vh', padding: '0 1rem 0.5rem',borderRight:'2px solid aliceblue' }}>
              
              <div className={styles.ideaName}>
                <IconButton
                  size="medium"
                  sx={{ color: "primary.main", cursor: "pointer",padding:'0'  }}
                  disabled={ideaData && ideaData[0]?.prev_idea == null}
                  onClick={() => { return history.push(`/idea/${projectId}/${topVaultId}/${abbreviation}/${vaultId}/${ideaData && ideaData[0]?.prev_idea}`), setClientProposal(''), setIdeaData([]) }}
                >
                  <ArrowCircleLeftIcon />
                </IconButton>
                <span title={ideaData && !isUndefined(ideaData[0]?.idea_id) ?`${ideaData && !isUndefined(ideaData[0]?.idea_id)  ? ideaData[0]?.idea_id:"loading..."} ( ${!isUndefined(IdeaType)? IdeaType : "...."} )`:"Loading....."} className={styles.ideaNameSpan}>{ideaData && !isUndefined(ideaData[0]?.idea_id) ?`${ideaData && !isUndefined(ideaData[0]?.idea_id)  ? ideaData[0]?.idea_id:"loading..."} ( ${!isUndefined(IdeaType)? IdeaType : "...."} )`:"Loading....."}</span>
                <IconButton
                  size="medium"
                  sx={{ color: "primary.main", cursor: "pointer",padding:'0'  }}
                  disabled={ideaData && ideaData[0]?.next_idea == null}
                  onClick={() => { return history.push(`/idea/${projectId}/${topVaultId}/${abbreviation}/${vaultId}/${ideaData && ideaData[0]?.next_idea}`), setClientProposal(''), setIdeaData([]) }}
                >
                  <ArrowCircleRightIcon />
                </IconButton>
              </div>
              {ideaData && <div style={{ marginTop: '1rem' }}>
                <span className={styles.ideaRedirectionButton}>
                  {IdeaStage && IdeaStage == 'Accepted' ? <Button
                    className={styles.ideaActionButton} variant="contained" color="success">{IdeaStage}</Button> :
                    IdeaStage && IdeaStage == 'Rejected' ? <Button
                      className={styles.ideaActionButton} variant="contained" color="error">{IdeaStage}</Button>
                      : <>
                        {ideaData && ideaData[0]?.idea_approval && <Button onClick={() => handleStageUpdate(ideaData && ideaData[0]?.stage + 1)} className={styles.ideaActionSubmittedButton} variant="contained" color="success">Accept</Button>}
                        {ideaData && ideaData[0]?.idea_approval && <Button onClick={() => handleReject(ideaData && !ideaData[0]?.rejected)} className={styles.ideaActionSubmittedButton1} variant="contained" color="error">Reject</Button>}
                      </>
                  }
                </span>
              </div>}
              
              <Box sx={{ marginTop: '1rem',maxHeight: {xl:'80vh',lg:'72vh'},padding:'0 2px' }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">  
                {ideaData && ideaData[0]?.idea_approval && ideaData && ideaData[0]?.parameter_json.length > 0 && <div>
                  <Typography className={styles.clientProposalHeader}>{ideaData && ideaData[0]?.parameter_json[0]?.param_name} <span style={{ color: 'red' }}>*</span> :</Typography>
                  <TextareaAutosize
                    className={!clientProposalError ? styles.clientProposalTextarea : styles.clientProposalTextareaWithError}
                    onChange={handleTextAreaChange}
                    onBlur={handleCallApiOnBlur}
                    defaultValue={clientProposal}
                    aria-label="empty textarea"
                    placeholder={ideaData && ideaData[0]?.parameter_json[0]?.param_name}
                    disabled={IdeaStage == 'Accepted' || IdeaStage == 'Rejected' ? true : false}
                    minRows={4}
                    style={{ width: '100%', color: "#646464", borderColor: '#007FFF14' }}
                  />
                  {clientProposalError && <p className={styles.proposalError}>
                    *Please enter atleast five words to save the information and NA is not allowed in the following field.
                  </p>}
                </div>}
                {ideaData && ideaData[0]?.merge_idea.length > 0 && <div>
                  <h3 className={styles.clientProposalHeader} style={{ marginBottom: 0 }}>Merged Ideas :</h3>
                  <div>
                    {
                      ideaData && ideaData[0]?.merge_idea.length > 0 && ideaData[0]?.merge_idea?.map((item: any, index: number) => {
                        return <Box key={index} onClick={() => {
                          history.push(
                            `/idea/${projectId}/${topVaultId}/${abbreviation}/${vaultId}/${item?.id}`
                          )
                        }} sx={{ textDecoration : "underline", cursor:"pointer" }}>
                          <Typography>{item.idea_id}</Typography>
                        </Box>
                      })
                    }
                  </div>

                </div>}
                <div>
                  <h3 className={styles.clientProposalHeader} style={{ marginBottom: 0 }}>Cost Summary :</h3>
                  <TableContainer>
                    <Table sx={{ minWidth: 250 }} aria-label="simple table">
                      <TableBody>
                        {IdeaParameters && IdeaParameters?.map((item: any, index: number) => {
                          return <TableRow key={index}>
                            {(item?.name === "Parameter"&&ideaData && ideaData[0]?.parameter === "NA") ?<></>: <TableCell className={styles.tabCell1} align="left">{item?.name}</TableCell>}
                            <TableCell className={styles.tabCell2}  align="center">{item?.parameterName == 'weight' ? (ideaData && ideaData[0]?.weight * 1000).toFixed(2) : item?.parameterName == 'parameter' ? `${(ideaData && ideaData[0]?.parameter !== "NA")?ideaData && ideaData[0]?.parameter:''} ${ideaData && (ideaData[0]?.unit == '' || ideaData[0]?.unit == "NA") ? '' : `(${ideaData && ideaData[0]?.unit})`}` : item?.parameterName == 'stage' ? IdeaStage : item?.parameterName == 'origin' ? ideaData && ideaData[0]?.[item?.parameterName] == 1
                              ? "BOM"
                              : ideaData && ideaData[0]?.[item?.parameterName] == 2
                                ? "Architecture"
                                : ideaData && ideaData[0]?.[item?.parameterName] == 3
                                  ? "Feature"
                                  : ideaData && ideaData[0]?.[item?.parameterName] == 4
                                    ? "Costing"
                                    : "Media" : ideaData && ideaData[0]?.[item?.parameterName]}</TableCell>
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {(ideaData && ideaData[0]?.origin == 2 || ideaData && ideaData[0]?.origin == 5) && <TableContainer style={{ marginTop: '2rem', overflow: 'hidden' }}>
                    <Table sx={{ minWidth: 270 }} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell className={styles.tabHCell1}>
                            Base Image
                          </TableCell>
                          <TableCell className={styles.tabHCell1}>
                            Competitor Image
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{padding:'0.5rem',textAlign:'center'}} >                        
                            <Image src={ideaData && ideaData[0]?.base_image} placeholderImg={NoImgLoader} alt={"Base Image"}
                              onClick={(e: any) => handleImageClick(e, ideaData && ideaData[0]?.base_image)}
                              style={{ objectFit: 'contain', height: '100%', width: '100%', cursor: 'pointer' }}
                              />
                          </TableCell>
                          <TableCell sx={{padding:'0.5rem',textAlign:'center'}} >                        
                            <Image src={ideaData && ideaData[0]?.competitor_image} placeholderImg={NoImgLoader} alt={"competitor_image"}
                              onClick={(e: any) => handleImageClick(e, ideaData && ideaData[0]?.competitor_image)}
                              style={{ objectFit: 'contain', height: '100%', width: '100%', cursor: 'pointer' }}
                              />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>}
                  {/* parameterKeys, */}
                  {
                    !apiDataSuspense && ideaData[0]?.base_numeric && <>
                      <h3 className={styles.clientProposalHeader} style={{ marginBottom: 0, marginTop: '1rem' }}> {ideaData && ideaData[0]?.origin == 1
                        ? "BOM "
                        : ideaData && ideaData[0]?.origin == 2
                          ? "Architecture "
                          : ideaData && ideaData[0]?.origin == 3
                            ? "Feature "
                            : ideaData && ideaData[0]?.origin == 4
                              ? "Costing "
                              : "Media "} Details :</h3>
                      <TableContainer>
                        <Table sx={{ minWidth: 250 }} aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell className={styles.tabHCell2} >
                                Parameter
                              </TableCell>
                              <TableCell className={styles.tabHCell2} >
                                Base Vehicle
                              </TableCell>
                              <TableCell className={styles.tabHCell2}>
                                Competitor Vehicle
                              </TableCell>
                            </TableRow>
                            {!apiDataSuspense && ideaData && parameterKeys?.map((item: any, index: number) => {
                              return <TableRow key={index + 1}>
                                <TableCell className={styles.tabBCell2}  align="left" style={{ color: "#646464",}}>{item}</TableCell>
                                <TableCell className={styles.tabBCell2}  align="left">{ideaData[0]?.base_numeric?.[item]}</TableCell>
                                <TableCell className={styles.tabBCell2}  align="left">{ideaData[0]?.competitor_numeric?.[item]}</TableCell>
                              </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  }   
                </div>
              </Box>
            </Box>
            </>:
                <Box sx={{height:'100%',marginTop:"5rem",padding:'0 2rem'}}>                
                  <Skeleton sx={{ height: "1rem", width: "70%",marginLeft:'3rem' ,marginTop:"1rem"}} />
                  <Skeleton sx={{ height: "4rem", width: "100%",marginTop:"1rem"}} />
                  <Skeleton sx={{ height: "1rem", width: "50%" ,marginTop:"1rem" }} />
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{ height: "1rem", width: "50%" , marginTop:"1rem" }} />
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                  <Skeleton sx={{marginTop:"0.5rem" }}/>
                </Box>}
          </div>
          <Box className={styles.middlePortion} adl-scrollbar="true"
            adl-scrollbar-width="0.3">
             {workflowState == true ? ( <Box sx={{padding:'0 1rem 1rem',width:'100%'}}>  
            <IdeaClientWorkflow 
             ideaAllDetails={ideaData}
             setWorkflowState={setWorkflowState}
             StatusCounter={StatusCounter} 
             />   
             </Box>  ) : (
                ""
              )}     
            <IdeaEntryMiddlePortion />
          </Box>
          <Box className={isActive ? styles.rightContainer : styles.rightContainer_close} >
          {!imageLoader?<>
            <Box className={styles.expandIconBox}>
              <IconButton className={styles.iconBtn} onClick={() => { handleCollapseClick() }}>
                <Box sx={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%', backgroundColor: 'primary.main' }}>
                  <KeyboardArrowRightIcon titleAccess="Show Idea Images" style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)', color: 'white',marginTop:'-0.1rem' }} />
                </Box>
              </IconButton>
              <Typography onClick={() => { handleCollapseClick() }} style={{ transform: 'rotate(270deg)', cursor: 'pointer', whiteSpace: 'nowrap', marginTop: '13rem',fontSize:'1rem', display: isActive ? "block" : "block" }}>Idea Images & Documents</Typography>
            </Box>
            <Box className={styles.ideaImagesContainer}>
              <div className={documentIdea && documentIdea?.length > 0 ? styles.ideaImagesContainerInside : styles.ideaImagesContainerInsideNoDoc}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                  {/* @ts-ignore */}
                  <RViewerJS>
                {imageIdea && imageIdea?.length>0 && Object.entries(imageIdea[0])?.map(([key, value]: any) => {
                  return(
                    <div style={{ margin: '1rem 0rem' }}>
                    <Typography sx={{ margin: '1rem 0rem 0rem 0rem' }}>{key}</Typography>
                  
                    <Image  src={value} placeholderImg={NoImgLoader} alt={key}
                            onClick={(e: any) => handleImageClick(e, value)}
                            style={{ objectFit: 'contain', height: '90%', width: '90%', cursor: 'pointer' }}
                            />
                    
                  </div>)
                })
                }
                </RViewerJS>
              </div>
              {documentIdea && documentIdea?.length > 0 && 
              <div style={{marginLeft:'-2rem'}}>
                <h4 style={{ marginBottom: "0.5rem" }}>Documents</h4>
                <Box sx={{ width: {lg:'26rem',xl:"33rem"}, height: {lg:'10rem',xl:'12rem'} }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                  <Table className={styles.docTable}>
                    <TableHead className={styles.docmentHeader}>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                          Parameter
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                          Document
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                          Type
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                          Download
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {documentIdea && (documentIdea)?.map((item: any) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ padding: '0.1rem 0.5rem' }}>{item?.name}</TableCell>
                            <TableCell sx={{ padding: '0.1rem 0.5rem' }}>{(item?.document_name?.substring(item?.document_name?.indexOf('_') + 1, item?.document_name?.lastIndexOf('.')) || item?.document_name)?.toUpperCase()}</TableCell>
                            <TableCell sx={{ padding: '0.1rem 0.5rem' }}>{(item?.document_name?.substring(item?.document_name?.lastIndexOf('.') + 1, item?.document_name?.length) || item?.document_name)?.toUpperCase()}</TableCell>
                            <TableCell sx={{ padding: '0.1rem 0.5rem' }}>
                              <IconButton sx={{ position: 'relative' }} onClick={() => { return window.open(item?.url, "_blank") }}>
                                <FileDownloadIcon sx={{ color: 'primary.main' }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </div>}
          </Box>
          </>:<Skeleton variant="rectangular" width={600} height={1000} />
          }
          </Box>        
      </div>
    <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={"We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"} type={"firstdownload"} />
      </>);
}
export default IdeaEntryInnerPage;

