import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface SingleSupplierCardModelProps {
  isOpen: any;
  onCloseModal: any;
  supplierGet: any
  commodityGet: any
  categoryGet: any
  systemGet: any
  aboutGet: any
  contactUsGet: any
  contactNoGet: any
  spocNameGet: any
  spocEmailGet: any
  logoGet: any
  supplierTypeGet: any
  certificationGet: any
  idGet: any
  noOfPlant: any
  createdBy: any
  lastUpdated: any
}

const SingleSupplierCardModel = (props: SingleSupplierCardModelProps) => {
  const { isOpen, onCloseModal, supplierGet, commodityGet, categoryGet, systemGet, aboutGet, contactUsGet, contactNoGet, spocNameGet, spocEmailGet, logoGet, supplierTypeGet, certificationGet, idGet, noOfPlant, createdBy, lastUpdated } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Supplier Details
            </Typography>
            <span style={{ display: 'flex' }}>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
            </span>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            {logoGet && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5rem' }}>
              <Image
                src={logoGet}
                placeholderImg={NoImgLoader}
                alt={"Supplier Logo"}
                height='150' width='auto'
              />
            </div>}
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}>  <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Supplier Name</span><span>:</span></Typography>
              <span>{supplierGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Commodity</span><span>:</span></Typography>
              <span>{commodityGet?.join(", ")}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>About Us</span><span>:</span></Typography>
              <span>{aboutGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Contact Us</span><span>:</span></Typography>
              <span>{contactUsGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Contact Number</span><span>:</span></Typography>
              <span>{contactNoGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>SPOC Name</span><span>:</span></Typography>
              <span>{spocNameGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>SPOC Email</span><span>:</span></Typography>
              <span>{spocEmailGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Product Category</span><span>:</span></Typography>
              <span>{categoryGet?.join(", ")}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Systems</span><span>:</span></Typography>
              <span>{systemGet?.join(", ")}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>No of Plants</span><span>:</span></Typography>
              <span>{noOfPlant}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Supplier Type</span><span>:</span></Typography>
              <span>{supplierTypeGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Certifications</span><span>:</span></Typography>
              <span>{certificationGet}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Created by</span><span>:</span></Typography>
              <span>{createdBy}</span>
            </Typography>
            <Typography sx={{ fontSize: '1rem', borderBottom: '1px solid', borderColor: 'primary.light', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <Typography sx={{ display: 'flex', fontSize: '1rem', alignContent: 'center', justifyContent: 'space-between' }}> <span style={{ marginLeft: '0.5rem', display: 'inline-block', minWidth: '10rem', color: 'primary.main' }}>Last Updated</span><span>:</span></Typography>
              <span>{lastUpdated}</span>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div >
  );
};

export default SingleSupplierCardModel;
