import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Autocomplete, Divider, TextField } from '@mui/material';
import { API } from '../../../api-services';
import { useParams } from 'react-router-dom';

interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;
  handleInReview: any;


}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "31vw",
  // maxWidth: '80vw',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};

const IdeaSubmittedmodal = (props: InfoModalProps) => {



  const { onCloseModal,
    isOpen,
    Item, handleInReview } = props
  const { projectId, projectName, topVault, subSystem, subSystemId, expandTable, abbr, partId, depth, vault_id } = useParams<any>()
  const [UserList, setUserList] = useState<any>([])
  const [user, setuser] = useState<any>({})



  const handleSelectUser = (event: any, newSystemValue: any) => {
    setuser(newSystemValue);

  };


  const SubmittedHandler = () => {
    handleInReview({ user: user?.user_id, key: 2 }, "submitted")
    onCloseModal()
  }

  useEffect(() => {


    API.get(`/idea/idea_external_user/`, { project: projectId }).then((res: any) => {
      setUserList(res.data)
    }).catch((err: any) => {
      console.log("Server Error")
    })
  },
    [
      Item

    ])


  return (
    <div>


      <Modal
        open={isOpen}
      // onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            > Assign Idea to client
              
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              columnGap: "1rem",
              padding: "0 1rem",
            }}
          >
            {
              
            

            <Autocomplete

              id="size-small-standard-multi"
              // size="small"
              options={UserList && UserList}

              sx={{ width: "100%" }}
              getOptionLabel={(option) =>
                option?.full_name == undefined ||   option?.full_name==null ? "" : option?.full_name
              }
              filterSelectedOptions
              value={user && user}
              onChange={(event: any, newSystemValue: any) => {
                handleSelectUser(event, newSystemValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  // label="Idea Owner Name"
                  placeholder="Select idea owner name....."
                />
              )}
            />
              }
            {
               user && Object.keys(user)!==null && Object.keys(user)!==undefined && Object.keys(user)?.length > 0 &&

              <Button variant="contained" size='small' onClick={() => SubmittedHandler()}>Submit</Button>
            }

          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default IdeaSubmittedmodal;