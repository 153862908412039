import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { ADMIN } from "../../Redux/Services/admin.service";
import { s3Url } from "../../utlis/s3url.utils";




const uploadS3ToolbarFlag = (
    commodity:any,
    toolCode:any,
    toolName:any,
    specification:any,
    category:any,
    supplier:any,
    material:any,
    other:any,
    life: any,
    toolCost: any,
    reference:any,
    fileList:any,
    onCloseModal:any,
    pathName:any,
    type:any,
    setIsLoadingSave:any,
    setApiCallAccordianInsideData:any,
    apiCallAccordianInsideData:any,
    apiCallAccordianData:any,
    setApiCallAccordianData:any,
    setSuccessModalFileMsg:any,
    setErrorModalFileMsg:any,
    SuccessModalFiles:any,
    ErrorModalFiles:any,
    id?:any,
    search?:any
) => {
    var random: any = Math.floor( Math.random() * 1000000 + 1 );
    var editedImageName: any = `EditedImage${random}`;
    var obj: Array<any> = [];
    API.get( "cad/cad_upload/", {
        module: MODULES.CAD,
    } ).then( res => {
        const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append( "key", `${pathName}/${random}/${file.name}` );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );
            axios
                .post( s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                    },
                } )
                .then( async ( res: any ) => {
                    if ( res.status === 204 ) {
                        if(type == "add"){
                            API.post(`/api/db/tool/`, {category:category,
                                code:toolCode,
                                commodity:commodity,
                                details:reference,
                                material:material,
                                name:toolName,
                                other:other,
                                price:toolCost,
                                shots:life,
                                specification:specification,
                                supplier:supplier,
                                flag:`/${pathName}/${random}/${file.name}`}).then((res: any) => { 
                                  setIsLoadingSave(false);
                                  setSuccessModalFileMsg('Operation added successfully!')
                                  SuccessModalFiles.open();
                                  setApiCallAccordianData(!apiCallAccordianData);
                                  setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                                }).catch((err) => {
                                  setIsLoadingSave(false);
                                  setErrorModalFileMsg(err.response.data);
                                  ErrorModalFiles.open();
                                })
                        }
                        else{
                            API.put(`/api/db/tool/${id}/`, {category:category,
                                code:toolCode,
                                commodity:commodity,
                                details:reference,
                                material:material,
                                name:toolName,
                                other:other,
                                price:toolCost,
                                shots:life,
                                specification:specification,
                                supplier:supplier,
                                flag:`/${pathName}/${random}/${file.name}`}).then((res: any) => { 
                                  setIsLoadingSave(false);
                                  setSuccessModalFileMsg('Operation edited successfully!')
                                  SuccessModalFiles.open();
                                  search=='0' && setApiCallAccordianData(!apiCallAccordianData);
                                  setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                                }).catch((err) => {
                                  setIsLoadingSave(false);
                                  setErrorModalFileMsg(err.response.data);
                                  ErrorModalFiles.open();
                                })
                    }
                }
                    
                } ).catch( ( err ) => {
                    ADMIN.toast.error( "Not able to upload" );
                } );
        } );
    } );
};
// ----------------------------------------------------------------------------------
export default uploadS3ToolbarFlag;