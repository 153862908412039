import React, { useState } from "react";

import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import calculator from "../../../Assets/images/calculator.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useDispatch, useSelector } from "react-redux";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { TransitionProps } from "@mui/material/transitions";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import { animated, useSpring } from "@react-spring/web";
import Collapse from "@mui/material/Collapse";
import AddProcessModal from "../../../Configuration/Costing/AddProcessModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { API } from "../../../api-services";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { Box } from "@mui/material";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import CheckIcon from "@mui/icons-material/Check";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));



function RecursiveTreeView({
  HierarchyData,
  onSelectCallback,
  setSelectedTreeData,
  setCounterApiCall,
  selectedTreeData,
  setSingleSelectedTreeData,
  singleSelectedTreeData,
  commonCalc,
}: any) {
  const infoModal = useBaseModal();
  const history = useHistory();
  const addProcess = useBaseParamsModal();
  const store: any = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [anchorPoint, setAnchorPoint] = useState<any>({ x: 0, y: 0 });
  const [isOpen, setOpen] = useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>(null);
  const { selectedIdTree, projectname, projectId } = useRouteParams<any>();

  // Function to collect all node IDs recursively, including leaf nodes
  const getAllNodeIds = (
    nodes: any,
    stopAtFirstMultiple: boolean = false
  ): string[] => {
    let ids: string[] = [];
    const nodeId = nodes?.sequence_id || nodes?.id?.toString() || nodes?.name;
    
    

    if (nodeId) {
      ids.push(nodeId); // Add current node id
    }

    // Check if node has more than one child
    if (
      Array.isArray(nodes?.data) &&
      nodes?.data?.length > 1 &&
      stopAtFirstMultiple
    ) {
      // If a node has more than one child, add only immediate children and stop recursion
      ids = ids?.concat(
        nodes?.data?.map(
          (child: any) =>
            child?.sequence_id || child.id?.toString() || child?.name
        )
      );
    } else if (Array.isArray(nodes?.data)) {
      // If it has one child or recursion is allowed, collect all descendants
      nodes?.data?.forEach((node: any) => {
        ids = [...ids, ...getAllNodeIds(node, true)]; // Pass true to stop recursion at first multiple child node
      });
    }
    return ids;
  };

  // Function to toggle the node and its descendants
  const handleToggle = (
    nodeId: string,
    nodeeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {

    const node = findNodeById(HierarchyData, nodeId);

    // Check if the node has more than one child
    const stopAtFirstMultiple = Array.isArray(node?.data) && node?.data.length > 1;

  
    
    
    const allDescendantIds = getAllNodeIds(node, stopAtFirstMultiple);

    onSelectCallback(nodeId, id, HierarchyAllData, itemId);
    // If the node is already expanded, collapse it and all its descendants

    if (HierarchyAllData && HierarchyAllData?.type === "calculator") {
      // setSelectedTreeData((prev: any) => [...prev, HierarchyAllData]);
      if (store?.is_compare?.selcetedCalculators.includes(HierarchyAllData)) {
        setSelectedTreeData(
          [...store?.is_compare?.selcetedCalculators].filter(
            (item: any) => item !== HierarchyAllData
          )
        );
        dispatch(
          selectedCalculators(
            [...store?.is_compare?.selcetedCalculators].filter(
              (item: any) => item !== HierarchyAllData
            )
          )
        );
      } else {
        dispatch(
          selectedCalculators([
            ...store?.is_compare?.selcetedCalculators,
            HierarchyAllData,
          ])
        );
        setSingleSelectedTreeData([...[HierarchyAllData]]);
        setSelectedTreeData((prev: any) => [...prev, HierarchyAllData]);
      }
    } else {
      // setSelectedTreeData([]);
      // setSingleSelectedTreeData([]);
    }

    if (expanded.includes(nodeId)) {
      setExpanded((prevExpanded) =>
        prevExpanded?.filter((id) => !allDescendantIds?.includes(id))
      );
      setSelected((prevExpanded) =>
        prevExpanded?.filter((id) => !allDescendantIds?.includes(id))
      );
    } else {
      // Otherwise, expand the node and all its descendants
      setExpanded((prevExpanded) => [...prevExpanded, ...allDescendantIds]);
      setSelected((prevExpanded) => [...prevExpanded, ...allDescendantIds]);
    }
  };

  // Helper function to find a node by its ID
  const findNodeById = (nodes: any, nodeId: string): any => {
    if (nodes?.sequence_id === nodeId || nodes?.id?.toString() === nodeId) {
      return nodes;
    }
    if (Array.isArray(nodes.data)) {
      for (const node of nodes?.data) {
        const foundNode = findNodeById(node, nodeId);
        if (foundNode) return foundNode;
      }
    }
    return null;
  };

  // useEffect(() => {
  //   let a: any = sessionStorage.getItem("CostingCreateCalcIds");
  //   let val: any = JSON.parse(a) || [];
  //   if (val != null) {
  //     setExpanded(val);

  //   }
  // }, [sessionStorage.getItem("CostingCreateCalcIds")]);

  // Recursive function to render tree nodes
  const renderTree = (nodes: any) => {
    const isCalculatorType = nodes?.type === "calculator";
    return (
      <StyledTreeItem
        key={nodes?.sequence_id || nodes?.id?.toString() || nodes?.name}
        nodeId={nodes?.sequence_id || nodes?.id?.toString() || nodes?.name}
        // label={`${nodes?.name} (${nodes?.calc_count || ""})`}
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Render node name and calc count */}
            <span>{`${nodes?.name}`}    {nodes?.calc_count ||
                  nodes?.calc_count === 0
                    ? `(${nodes?.calc_count})`
                    : ""}</span>
  
            {/* Conditionally render CheckIcon if the node is of type 'calculator' and is selected */}
            {isCalculatorType &&
                store?.is_compare?.selcetedCalculators &&
                store?.is_compare?.selcetedCalculators
                  ?.map((item: any) => item.id)
                  ?.includes(nodes?.id) && (
              <CheckIcon sx={{ color: "green", width: "1.2rem", marginLeft: "8px" }} />
            )}
          </div>
        }
        onClick={() =>
          handleToggle(
            nodes?.sequence_id || nodes?.id?.toString() || nodes?.name,
            nodes?.name,
            nodes?.sequence_id,
            nodes,
            nodes?.id
          )
        }
        endIcon={
          isCalculatorType ? (
            <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor: singleSelectedTreeData
              ?.map((item: any) => item?.id)
              ?.includes(nodes?.id)
              ? "#cee6fa !important"
              : "white !important",
          },
        }}
      >
        {Array.isArray(nodes?.data)
          ? nodes?.data?.map((node: any) => renderTree(node))
          : null}



          
      </StyledTreeItem>
    );
  };
  const menuItemClassName = ({ hover }:any) =>
  hover ? "my-menuitem-hover" : "my-menuitem";


  

  
  

  return (
    <>
    {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}

<ControlledMenu
        anchorPoint={anchorPoint}
        state={isOpen ? "open" : "closed"}
        direction="top"
        // align="center"
        position="anchor"
        transition
        // arrow={true}
        menuClassName="my-menu"
        onClose={() => {
          // e.stopPropagation()
          setOpen(false);
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        // onSelect={(ed:any)=>{

        // }}
      >
        {HierarchyData?.type === "commodity" && (
          <MenuItem
            value={"Process"}
            className={menuItemClassName}
            disabled={
              !sessionStorage.getItem("ConfigPermission")?.includes("C")
            }
            onClick={() => {
              setSelectedCommodity(HierarchyData.id);
              addProcess.open(HierarchyData.id);
            }}
          >
            <DataSaverOnIcon
              sx={{ cursor: "pointer", marginRight: "0.5rem" }}
            />
            Add Process
          </MenuItem>
        )}
        {HierarchyData?.type === "sub_calculator_cat" && (
          <MenuItem
            value={"Process"}
            className={menuItemClassName}
            disabled={
              !sessionStorage.getItem("ConfigPermission")?.includes("C")
            }
            onClick={() => {
              API.post(
                "/cost/calculator/",
                {
                  name: "calculator1",
                  process: HierarchyData?.process,
                  commodity_mapping: HierarchyData?.commodity,
                  result: "",
                  category: HierarchyData.id,
                },
                {},
                0
              )
                .then((res: any) => {
                  history.push(
                    `/costingconfiguration/${projectId}/${projectname}/${
                      res.data.id
                    }/${"calculator"}`
                  );
                  setCounterApiCall((prev: any) => prev + 1);
                  
                })
                .catch(() => {});
            }}
          >
            <img
              src={calculator}
              style={{
                width: "13px",
                color: "#007fff",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            />
            Add Cost model
          </MenuItem>
        )}
        {HierarchyData?.type === "calculator" && (
          <Box sx={{ position: "relative", zIndex: 5 }}>
            {!HierarchyData?.mapped_status && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                disabled={
                  !sessionStorage.getItem("ConfigPermission")?.includes("D")
                }
                className={menuItemClassName}
                onClick={() => {
                  API.delete(`/cost/calculator/${selectedIdTree}/`, {}, 0)
                    .then(() => {
                      setCounterApiCall((prev: any) => prev + 1);
                    })
                    .catch(() => {});
                }}
              >
                <DeleteIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Delete
              </MenuItem>
            )}
            <MenuItem
              style={{ fontSize: "1rem" }}
              value={"calculator"}
              className={menuItemClassName}
              disabled={
                !sessionStorage.getItem("ConfigPermission")?.includes("C")
              }
              onClick={() => {
                API.post(
                  "/cost/copy_calculator/",
                  {
                    id: HierarchyData.id,
                    name: "copy_calc1",
                  },
                  {},
                  0
                )
                  .then((res: any) => {
                    setCounterApiCall((prev: any) => prev + 1);
                    history.push(
                      `/costingconfiguration/${projectId}/${projectname}/${
                        res.data.id
                      }/${"calculator"}`
                    );
                  })
                  .catch(() => {});
              }}
            >
              <FileCopyIcon
                sx={{
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  fontSize: "1.5rem",
                }}
              />
              Copy
            </MenuItem>
            {HierarchyData?.mapped_status && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                className={menuItemClassName}
                onClick={() => infoModal.open()}
              >
                <InfoIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Usage Info
              </MenuItem>
            )}
          </Box>
        )}
      </ControlledMenu>




      <TreeView
        expanded={expanded}
        defaultParentIcon={<FolderIcon fill="primary" />}
        defaultExpandIcon={<FolderIcon color="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyData &&
          HierarchyData?.type !== "calculator" && (
            // HierarchyAllData?.sequence_id == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
          // )
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor: singleSelectedTreeData
              ?.map((item: any) => item?.id)
              ?.includes(HierarchyData?.id)
              ? "#cee6fa !important"
              : "white !important",
          },
        }}
        // defaultEndIcon={
        //   HierarchyData?.type === "calculator" ? (
        //     <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
        //   ) : (
        //     <FolderIcon color="primary" />
        //   )
        // }
        disableSelection={commonCalc?.includes(HierarchyData?.id)}
        disabledItemsFocusable={commonCalc?.includes(HierarchyData?.id)}
        style={commonCalc?.includes(HierarchyData?.id) ? { opacity: 0.5 } : {}}
      >
        {renderTree(HierarchyData)}
      </TreeView>
    </>
  );
}

export default RecursiveTreeView;
