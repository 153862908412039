import { Box, Typography } from "@mui/material";
import { VerifydocumentModal } from "./VerifydocumentModal";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { VerifyWeightModal } from "./VerifyWeightModal";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";

export interface IDispatchproductsProps {
  data: any;
}

export function Dispatchproducts(props: IDispatchproductsProps) {
  const { data } = props;
  const documentmodal = useBaseParamsModal();
  const WeightModal = useBaseModal();

  const DocumentHanlder = (itemId: any, vm: any) => {
    documentmodal.open({ itemId: itemId, vm: vm });
  };

  const WeightHanlder = () => {
    WeightModal.open();
  };
  const { projectId } = useRouteParams<any>();
  const CheckSystem = useBaseModal();

  return (
    <>
      {documentmodal.isOpen && (
        <VerifydocumentModal
          onCloseModal={documentmodal.close}
          isOpen={documentmodal.isOpen}
          itemID={documentmodal.propsId}
        />
      )}
      {WeightModal.isOpen && (
        <VerifyWeightModal
          onCloseModal={WeightModal.close}
          isOpen={WeightModal.isOpen}
        />
      )}

      <Box
        sx={{
          width: { lg: '6vw', xl: '5vw' },
          textAlign: "center",
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          cursor: "pointer",
          whiteSpace: "nowrap",
          color: data.verified_count !== data.total_parts ? "red" : "green",
          textDecoration: "underline",
        }}>
        <Typography
          sx={{ fontSize: "1rem",}}
          onClick={() => DocumentHanlder(data?.id, data?.verification_method)}>
          {data.verified_count}
        </Typography>
      </Box>
      <Box sx={{ width: { lg: '6vw', xl: '5vw' }, textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap" }}>{data.total_parts}</Box>
      <Box sx={{ width: { lg: '6vw', xl: '5vw' }, textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap",borderRight: '1px solid',  }}>
        {data?.verification_method}
      </Box>

    </>
  );
}
