import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import * as React from "react";
import Modal from "@mui/material/Modal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import OtherIdeaDetailsModal from "./OtherIdeaDetailsModal";
import MediaForm from "./MediaForm";
import FeatureForm from "./FeatureForm";
import CostingForm from "./CostingForm";
import BOMForm from "./BOMForm";
import ArchitectureFrom from "./ArchitectureForm";
import { IdeaOriginList, IdeaOriginListProduct } from "../../../utlis/Idea.constant";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "60vw", xl: "50vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface FilmOptionType {
  title: string;
  year: number;
}
export interface IIdeaCreateFormModalProps {
  onCloseModal: any;
  isOpen: any;
  subSystem: any;
  subSystemId?: any;
  getTableData?: any;
  getUpdatedIdeaList?: any;
  isLeaf?: any;
}
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
];

export default function IdeaCreateFormModal(props: IIdeaCreateFormModalProps) {
  const { onCloseModal, isOpen, subSystem,subSystemId,getTableData, getUpdatedIdeaList, isLeaf } = props;
  const [ideaOrigin, setIdeaOrigin] = React.useState<any>("");



  const OriginHandleChange = (event: SelectChangeEvent) => {
    setIdeaOrigin(+event.target.value);
  };






  const getFormDetails = () => {
    switch (ideaOrigin) {
      case 1:
        return (
          <>
            <BOMForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={ideaOrigin}
              subSystemId={subSystemId?.id}
              getTableData={getTableData}
              data={subSystemId}
              getUpdatedIdeaList={getUpdatedIdeaList}
            />
          </>
        );

      case 2:
        return (
          <ArchitectureFrom
            onCloseModal={onCloseModal}
            isOpen={isOpen}
            subSystem={subSystem}
            ideaOrigin={ideaOrigin}
            subSystemId={subSystemId?.id}
            getTableData={getTableData}
            data={subSystemId}
            getUpdatedIdeaList={getUpdatedIdeaList}
          />
        );

      case 3:
        return (
          <>
            <FeatureForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={ideaOrigin}
              subSystemId={subSystemId?.id}
              getTableData={getTableData}
              data={subSystemId}
              getUpdatedIdeaList={getUpdatedIdeaList}
            />
          </>
        );
      case 4:
        return (
          <>
            <CostingForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={ideaOrigin}
              subSystemId={subSystemId?.id}
              getTableData={getTableData}
              data={subSystemId}
              getUpdatedIdeaList={getUpdatedIdeaList}
            />
          </>
        );
      case 5:
        return (
          <>
            <MediaForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={ideaOrigin}
              subSystemId={subSystemId?.id}
              getTableData={getTableData}
              data={subSystemId}
              getUpdatedIdeaList={getUpdatedIdeaList}
            />
          </>
        );

      case 7:
        return (
          <>
            <FeatureForm
              onCloseModal={onCloseModal}
              isOpen={isOpen}
              subSystem={subSystem}
              ideaOrigin={ideaOrigin}
              subSystemId={subSystemId?.id}
              getTableData={getTableData}
              data={subSystemId}
              getUpdatedIdeaList={getUpdatedIdeaList}
            />
          </>
        );
    }
  };

  const OtherIdeaModal = useBaseModal();
  const OtherIdeaFormHandler = () => {
    OtherIdeaModal.open();
  };

  const getOriginList=(data:any)=>{
    if(data?.abbreviation === "PRODUCT"){
      return IdeaOriginListProduct
    }else{
      return IdeaOriginList
    }
  }

  return (
    <div>
      {OtherIdeaModal.isOpen && (
        <OtherIdeaDetailsModal
          onCloseModal={OtherIdeaModal.close}
          isOpen={OtherIdeaModal.open}
          subSystem={subSystem}
          closeParentModal={onCloseModal}
        />
      )}
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                // marginRight: '-2rem'
              }}
            >
              Idea Form Entry ({subSystem})
            </Typography>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem", width: "100%" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <FormControl
                required
                sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                fullWidth
                variant="standard"
              >
                <InputLabel
                  sx={{ color: "primary.main" }}
                  shrink
                  id="demo-simple-select-label"
                >
                  Select Origin
                </InputLabel>
                <Select
                  id="Origin"
                  name="Origin"
                  labelId="demo-select-small"
                  required
                  onChange={OriginHandleChange}
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "1rem",
                      },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      color: "007fff",
                    },
                  }}
                  value={ideaOrigin}
                >
                  {isLeaf ? getOriginList(subSystemId)?.filter((item:any)=>item.value !== 7 && item.value !== 2 && item?.value !== 3 && item?.value !== 5)?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          value={item?.value}
                          
                        >
                          {item?.text}
                        </MenuItem>
                      );
                    }) : getOriginList(subSystemId)?.filter((item:any)=>item.value !== 7 )?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          value={item?.value}
                          
                        >
                          {item?.text}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard"></FormControl>
            </Box>
            {getFormDetails()}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
