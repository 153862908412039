import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Autocomplete, Divider, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import successIcon from "../../Assets/images/success.png"
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../AppRouter";
import { getArchImages } from "../../Redux/Actions/architecture.action";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface ArchMoveGroupProps {
  onCloseModal: any;
  isOpen: any;
  Item: any;
  setcounter: any;
}

const ArchMoveGroup = (props: ArchMoveGroupProps) => {
  const { onCloseModal, isOpen, Item, setcounter } = props;
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
  } = useRouteParams<any>();


  const [SequenceList, setSequenceList] = useState<any>([])
  const [selectedSequence, setSelectedSequence] = useState<any>([])
  const [GroupList, setGroupList] = useState<any>([])
  const [SelectedGroup, setSelectedGroup] = useState<any>({})
  const [loader, setLoader] = useState<any>(false)
  const history = useHistory<any>()
  const dispatch = useAppDispatch()
  const handleSelectChange = (item: any) => {
    setSelectedSequence(item)
  };


  const handleGroupSelectChange = (item: any) => {
    setSelectedGroup(item)

  }

  console.log(Item, "ItemItemItem")
  useEffect(() => {
    API.get("/circuit_diagram/arch_move/", {
      project: projectId,

      abbreviation: abbr,

      categories: 3,

      group_id: Item?.id,
    })
      .then((res: any) => {
        setSequenceList(res?.data)
        // console.log(res?.data, "Cdcbjdcbdcbdiuc");
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [groupId]);
  React.useEffect(() => {

    API.get(`/circuit_diagram/arch_groups/`, {
      project: projectId,
      abbreviation: abbr,
    })
      .then((res: any) => {

        var filterGroup = res?.data?.filter((item: any) => item?.id !== Item?.id)
        setGroupList(filterGroup);
      })
      .catch((err: any) => {

      });
  }, [isOpen]);

  const MoveHandler = () => {
    var SequenceList = selectedSequence?.map((item: any, index: any) => { return item?.sequence })
    setLoader(true)
    API.get(`/circuit_diagram/arch_move/`, {
      project: projectId,


      abbreviation: abbr,

      categories: 3,
      source_group: Item?.id,
      target_group: SelectedGroup?.id,
      move_sequence: SequenceList?.toString()

    }, 0).then((res: any) => {
      onCloseModal()
      setcounter((prev: any) => prev + 1)
      dispatch(
        getArchImages({
          project: projectId,

          abbreviation: abbr,

          page: pageNumber,
          col_page: productpage,
          group_id: groupId,
        })
      );
      setLoader(false)
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;">Move Successfully!</p>   
 </div>`,
      });


    }).catch((err: any) => {
      setLoader(false)
    }
    )
  }
  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Move Group
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "0.5rem",
            }}>
            <Typography sx={{ display: "flex", fontSize: '1rem', marginBottom: '0.5rem' }}>
              <Typography sx={{ width: "4rem", fontSize: '1rem', }}>Note* : </Typography>
              <Typography sx={{ color: "primary.main", fontSize: '1rem', }}>
                Moved Diagrams will not retain the sequence in the destination
                group. They will be added in the end.
              </Typography>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', columnGap: '1rem', width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '1rem' }}>Diagram rows to be moved :</Typography>
                <Autocomplete
                  id="combo-box-demo"
                  value={selectedSequence}
                  sx={{
                    width: 'auto',
                    '.MuiAutocomplete-root .MuiAutocomplete-input': {
                      fontSize: '1rem',
                    },
                    '.MuiButtonBase-root.MuiChip-root': {
                      backgroundColor: '#007fff14',
                    }
                  }}
                  multiple
                  disableClearable
                  limitTags={2}
                  disableCloseOnSelect
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue);
                  }}
                  options={SequenceList}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.sequence}

                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Sequence"
                      {...params}
                      variant="standard"
                      sx={{
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}

                    />
                  )}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '1rem' }}>Move to group :</Typography>
                <Autocomplete
                  id="combo-box-demo"
                  value={SelectedGroup}
                  sx={{
                    width: 'auto',
                    '.MuiAutocomplete-root .MuiAutocomplete-input': {
                      fontSize: '1rem',
                    }
                  }}
                  onChange={(event, newValue) => {
                    handleGroupSelectChange(newValue);
                  }}
                  options={GroupList}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.name == undefined ? "" : option?.name}

                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Group"
                      {...params}
                      variant="standard"
                      sx={{
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}

                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ mt: "1rem", width: '100%', textAlign: 'right' }}>
              <LoadingButton onClick={() => MoveHandler()} loading={loader}
                sx={{
                  padding: "0.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}
                variant="contained">
                Move
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ArchMoveGroup;
