import { Box, CircularProgress, TextField, debounce } from "@mui/material";
import * as React from "react";

import Swal from "sweetalert2";


import { useSelector } from "react-redux";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import { workstationSelectors } from "../../../../../Redux/Reducers/workstation.reducer";
import { parameterConfigSelectors } from "../../../../../Redux/Reducers/parameterConfig.reducer";
import { ID } from "../../../../../utlis/commonInterfaces";
import validateValues from "../../../../BOMCreate/validateValues";
import { customValidate } from "../../../../../utlis/CustomValidate";
import { API } from "../../../../../api-services";
import { ADMIN } from "../../../../../Redux/Services/admin.service";
import FormError from "../../../../../Core/FromError";
import { customValidationSelectors } from "../../../../../Redux/Reducers/customValidation.reducer";
import { RootState } from "../../../../../Redux/Store/storeConfigurations";
import { UserAction } from "../../IdeaGenerationViewLanding";
import { rejectedStatus } from "../../IdeaViewpage";
// import { parameterConfigSelectors } from "../../../../Redux/Reducers/parameterConfig.reducer";
// import { workstationSelectors } from "../../../../Redux/Reducers/workstation.reducer";
// import { RootState } from "../../../../Redux/Store/storeConfigurations";
// import { customValidationSelectors } from "../../../../Redux/Reducers/customValidation.reducer";
// import { ID } from "../../../../utlis/commonInterfaces";
// import FormError from "../../../../Core/FromError";
// import { customValidate } from "../../../../utlis/CustomValidate";
// import { userPermissionAction } from "../../../BOMCreate/BOMEntry/BOMEntryLandingPage";
// import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
// import { API } from "../../../../api-services";
// import { ADMIN } from "../../../../Redux/Services/admin.service";
// import validateValues from "../../../BOMCreate/validateValues";


export interface IIdeaTabDescriptionInputBoxProps {
    item: any;
    details: any;
    // BOMValidationStatus?: any;
    allValues: any;
    getAllValues: any;    
}

export default function IdeaTabDescriptionInputBox (props: IIdeaTabDescriptionInputBoxProps) {
  const { item, details, allValues, getAllValues } = props;
  const { vault, workstation, topVault, copyStatus,vault_id } = useRouteParams<any>();
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
  const [loading, setLoading] = React.useState<any>(false);

  const [value, setValue] = React.useState<any>("");
  const [oldValue, setOldValue] = React.useState<any>("");

  const getObj = (vlt: any) => {
    if (vlt == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vlt };
    }
  };

  const [fieldError, setFieldError] = React.useState<any>(undefined);
  const customValidations = useSelector(customValidationSelectors.selectAll);
  const parameterConfigs = useSelector(
    parameterConfigSelectors.selectNonImageNonTableChildernConfigs
  );

  const workstationList = useSelector((state: RootState) => {
    return workstationSelectors.selectById(state, +workstation || 0);
  });

  const configToDbMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.parameter_id;
    return result;
  }, {} as Record<string, ID>);

  const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.data_type;
    return result;
  }, {} as Record<string, string>);
  const categoryIncludedInWorkstion = workstationList
    ? workstationList.categories.includes(1)
    : false;

  const allInputData = parameterConfigs
    .filter((data) => data.data_type !== "table")
    .filter((data) => data.data_type !== "document");
  const allowedParameters = categoryIncludedInWorkstion
    ? workstationList?.allowed_parameters
    : undefined;

  var errors = validateValues(
    allInputData,
    { [item?.key]: value },
    allowedParameters,
    "parameter_id"
  );
  React.useEffect(() => {
    setValue(item?.value);
    setOldValue(item?.value);
  }, [item]);
  const validateField = (parameter_id: ID, value?: any) => {
    const val = value ?? { [item?.key]: +value }[parameter_id];
    let error: string | undefined;
    const config: any = parameterConfigs.find(
      (config) => config.parameter_id === parameter_id
    );

    if (config)
      error = validateValues([config], { [parameter_id]: val })[parameter_id];

    // custom validations
    const relation = customValidations?.find(
      (relation) => relation.parameter_id === parameter_id
    );
    // { [item?.key]: +value },
    allValues[item?.key] = value;

    if (relation)
      error = customValidate(
        relation.parameter_id,
        configToDataTypeMap,
        relation.validation,
        undefined,
        allValues,
        details,
        configToDbMap
      );
    setFieldError(error!);
  };

  const updateDataVaultParams = async (name: any, value: any, vlt: any) => {
    // if (oldValue != item?.value) {
      setLoading(true);
      API.put(
        `/idea/pool/${vault_id}/`,
        {
          ...{parameter_json:{[name]: value}}
        },
        {
          ...getObj(vlt),
          module:15
        },
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Updated Successfully");
          setLoading(false);
          // BOMValidationStatus();
          setOldValue(value);
          
        })
        .catch((err: any) => {
          setLoading(false);
          if(err?.response?.data){
            Swal.fire({
              title: 'Error!',
              text: err?.response?.data[0]|| "Please enter minimum 5 words",
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          }
          
        });
    // }
  };

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any) => {
      await updateDataVaultParams(name, value, idd);
    }, 1000)
  ).current;

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    validateField(item?.key, value);
    setValue(value);
  };


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <TextField
          multiline
          onChange={handleOnChange}
          rows={3}
          name={item?.key}
          disabled={(!rejectedStatusActionParams && UserActionParams?.includes("U") && item?.editable) ? false : true}
          // disabled={
          //   (copyStatus == false || copyStatus == "false") &&
          //   (UserAction?.includes("U") || UserAction?.includes("C"))
          //     ? false
          //     : true
          // }
          maxRows={10}
          value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder="Enter minimum five words..."
          sx={{
            width: "100%",
            padding: "0rem",
            backgroundColor: "rgba(255,255,255,0.3)",
            ".MuiInputBase-root.MuiOutlinedInput-root": {
              padding: "0.3rem",
            },
            ".MuiInputBase-input.MuiOutlinedInput-input": {
              fontSize: "12px",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "primary.light",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.light",
            },
          }}
          onBlur={(event: any) => {
            if (event?.target.value !== oldValue) {
              ExactDebounceParameters(
                event.target.value,
                event.target.name,
                vault
              );
            }
          }}
        />
        {loading && <CircularProgress size={20} sx={{ margin: "5px" }} />}
      </Box>
      {fieldError && <FormError error={fieldError} align="center" />}
    </>
  );
}
