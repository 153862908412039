import React, { useEffect, useRef, useState } from "react";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { API } from "../../../../api-services";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import styles from "./MiddleIdeaComponent.module.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useHistory } from "react-router-dom";
import Image from "../../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../../../Assets/images/loading-screen.gif";
import {
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RViewerJS from "viewerjs-react";
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import { DialogComponent } from "../../../../utlis/DialogComponent";

// Below code is used for the styling of in Accordion where group name are written

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "12px", color: "#007fff" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    width: "inherit",
    marginLeft: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface Params {
  projectId: any;
  topVaultId: any;
}

function valuetext(value: number) {
  return `${value} INR`;
}

interface MiddleIdeaComponentI {
  minMaxRange: any;
}

const MiddleIdeaComponent: React.FC<MiddleIdeaComponentI> = (props) => {
  const { minMaxRange } = props;
  const openDialogModal=useBaseModal();
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [ideaAccordianData, setIdeaAccordianData] = useState<any>();
  const [ideaAccordianDataAPI, setIdeaAccordianDataAPI] =
    useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>();
  const [imageDataIdea, setImageDataIdea] = useState<any>();
  const [imageDataIdeaAPI, setImageDataIdeaAPI] = useState<boolean>(false);
  const imageViewerElRef = useRef<any>(null);
  let [imageClicked, setImageClicked] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<string>("Default");
  const history = useHistory();
  const [value, setValue] = React.useState<number[]>([0, 0]);
  const [range, setRange] = React.useState<number[]>([0, 0]);
  const [statusHandle, setStatusHandle] = useState<string>("Default");
  const [ideaIdGet,setIdeaIdGet] = useState<any>();
  
  const [anchorElSingleIdea, setAnchorElSingleIdea] = React.useState<null | HTMLElement>(null);
  const openSingleIdea = Boolean(anchorElSingleIdea);
  
  const handleCloseSingleIdea = () => {
    setAnchorElSingleIdea(null);
  };

 const handlePDFReport = () => {
  handleCloseSingleIdea();
 }  

 const handleExcelReport = async(ideaId:any) => {
  handleCloseSingleIdea();
  openDialogModal.open();
  try {
    const { data: url } = await API.get(
      "/customer/individual_report/",
      {
        idea_id: ideaId,
        individual_type:"Idea"
      },
      0
    );
    window.open(url, "_blank", "noopener noreferrer");
  } catch (e) {
  }
 }

  const handleChangeRange = (event: Event, newValue: number | number[]) => {
    setRange(newValue as number[]);
  };

  const handleChangeStatus = (event: any) => {
    setStatusHandle(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilterHandle = () => {
    setPage(1);
    setStatus("Default");
    setStatusHandle("Default");
    Object.keys(minMaxRange).length > 0 &&
      setValue([minMaxRange.min_range, minMaxRange.max_range]);
    Object.keys(minMaxRange).length > 0 &&
      setRange([minMaxRange.min_range, minMaxRange.max_range]);
    handleClose();
  };

  const filterHandle = () => {
    statusHandle != status && setPage(1);
    value != range && setPage(1);
    setValue(range);
    setStatus(statusHandle);
    handleClose();
  };

  useEffect(() => {
    Object.keys(minMaxRange).length > 0 &&
      setValue([minMaxRange.min_range, minMaxRange.max_range]);
    Object.keys(minMaxRange).length > 0 &&
      setRange([minMaxRange.min_range, minMaxRange.max_range]);
  }, [minMaxRange]);

  useEffect(() => {
    setIdeaAccordianDataAPI(false);
    Object.keys(minMaxRange).length > 0 &&
      API.get(
        `customer/idea_data/`,
        {
          top_vault_id: topVaultId,
          idea_dashboard: "saving_ideas_summary",
          page_no: page,
          filter_cost_from: value[0],
          filter_cost_to: value[1],
          filter_status: status == "Default" ? null : status,
        },
        0
      ).then((res: any) => {
        setIdeaAccordianData(res?.data);
        setIdeaAccordianDataAPI(true);
      });
  }, [topVaultId, page, value, status]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setImageDataIdea(null);
      setImageDataIdeaAPI(false);
      API.get(
        `/customer/idea_data/`,
        {
          top_vault_id: topVaultId,
          idea_dashboard: "saving_ideas_images",
          ideaob_id: panel,
        },
        0
      )
        .then((res: any) => {
          setImageDataIdea(res?.data);
          setImageDataIdeaAPI(true);
        })
        .catch((err: any) => {
          setImageDataIdeaAPI(true);
        });
      setExpanded(newExpanded ? panel : false);
    };

  const handleImageClick = (e: any, image: string) => {
    setImageClicked(image);
    //@ts-ignore
    // imageViewerElRef?.current?.src = image;
    // return imageViewerElRef.current?.click?.();
  };

  const marks = [
    {
      value: minMaxRange && minMaxRange?.min_range,
      label: minMaxRange && minMaxRange?.min_range,
    },
    {
      value: minMaxRange && minMaxRange?.max_range,
      label: minMaxRange && minMaxRange?.max_range,
    },
  ];

  return (
    <>
    {/* <LetSuspense
      loadingPlaceholder={BouncingLoader}
      condition={ideaAccordianDataAPI}
    >  */}
    {ideaAccordianDataAPI ? <>
      <div className={styles.accordianHeader}>
        {/* <div style={{ display: "none" }}>
               
                <RViewerJS>
                    <img src={imageViewerElRef.current?.src} alt="image" ref={imageViewerElRef} />
                </RViewerJS>
        </div> */}
        <Typography>Cost Saving Ideas(High to Low)</Typography>
        <span>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <FilterListIcon />
          </Button>
        </span>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          PaperProps={{
            elevation: 0,
            sx: {
              // overflowY: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              mr: 2,
              width: "fit-content",
              top: "6rem !important",
              marginLeft: "-1rem",
              overflow: "inherit",

              "& .MuiPopover-paper": {
                overflowX: "inherit !important",
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 2,
                right: 10,
                width: 20,
                height: 18,
                bgcolor: "white",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          sx={{
            ".MuiList-root.MuiMenu-list": {
              padding: 0,
            }
          }}
        >
          <MenuItem sx={{fontSize:'1rem'}}>Status &nbsp;: &nbsp;<TextField
              select
              sx={{
                width: "100%",
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
                ".MuiList-root.MuiMenu-list": {
                  padding: 0,
                }
              }}
              variant="standard"
              size="small"
              value={statusHandle}
              onChange={handleChangeStatus}              
            >
              <MenuItem key={"Accepted"} value={"Accepted"}>
                Accepted
              </MenuItem>
              <MenuItem key={"Rejected"} value={"Rejected"}>
                Rejected
              </MenuItem>
              <MenuItem key={"Submitted"} value={"Submitted"}>
                Submitted
              </MenuItem>
              <MenuItem key={"Default"} value={"Default"}>
                All
              </MenuItem>
            </TextField>
            </MenuItem>
          
          <MenuItem sx={{fontSize:'1rem'}}>Cost Saving Range (INR):</MenuItem>
          <MenuItem sx={{fontSize:'1rem'}}>
            <Box sx={{ width: 200 }}>
              {ideaAccordianData && (
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={range}
                  step={Math.floor(ideaAccordianData[0]?.max_saving_value / 10)}
                  onChange={handleChangeRange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  max={minMaxRange && minMaxRange?.max_range}
                  marks={marks}
                  min={minMaxRange && minMaxRange?.min_range}
                />
              )}
            </Box>
          </MenuItem>
          <MenuItem
            style={{              
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" sx={{'&:hover':{transform:'Scale(1.1)',transition:'transform 0.5s ease'}}} size="small" onClick={clearFilterHandle}>Clear Filter</Button>
            <Button variant="contained" sx={{'&:hover':{transform:'Scale(1.1)',transition:'transform 0.5s ease'}}} size="small" onClick={filterHandle}>Next</Button>
          </MenuItem>
        </Menu>
      </div>
      {!ideaAccordianData && Object.keys(ideaAccordianData[0]).length == 0 ? (
        <>
          <div
            style={{
              height: "92vh",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.2rem",
            }}
          >
            <span>NO IDEA AVAILABLE</span>
          </div>
        </>
      ) : (
        <>
          <Box
            className={styles.ideaAccord}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {ideaAccordianData &&
              ideaAccordianData[0]?.data?.map((item: any) => {
                return (
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded == item?.id}
                    onChange={handleChange(item?.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        margin: "0",
                        minHeight: "32px",
                        padding: "0.2rem !important",
                      }}
                    >
                      <Box sx={{ paddingLeft: "8px", width: "100%" }}>
                        <Box sx={{fontSize:'1rem'}}>{item?.description?.[0]}</Box>
                        <Box
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <div style={{fontSize:'1rem'}}>
                            <span>Cost Saving : <span style={{fontWeight:'bold'}}>{item?.saving_value} INR per vehicle</span></span>
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-end",
                              display: "flex",
                              fontSize:'1rem',
                            }}
                          >
                            <div>Status :</div>
                            <div
                              style={{
                                width: "6rem",
                                paddingLeft: "0.5rem",
                                color:
                                  item?.idea_status == "Rejected"
                                    ? "red"
                                    : item?.idea_status == "Accepted"
                                    ? "green"
                                    : "",
                              }}
                            >
                              {item?.idea_status}
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{ height: "auto", maxHeight: "35vh" }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Box style={{position:'sticky',top:"0rem"}}>
                          <Typography
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              position: "absolute",
                              right:"0rem",
                              fontSize:'1rem',
                              marginRight: "1rem",                              
                              backgroundColor:'white',
                              marginTop: "0.12rem",
                            }}
                            onClick={() => {
                              if(item?.merge){
                                history.push(
                                  `/idea/${projectId}/${topVaultId}/${item?.abbreviation}/${item?.base || item?.base_veh}/${item?.merge?.id}`
                                )
                              }
                              else{
                                history.push(
                                  `/idea/${projectId}/${topVaultId}/${item?.abbreviation}/${item?.base || item?.base_veh}/${item?.id}`
                                )
                              }
                              
                            }}
                          >
                            {item?.merge ? item?.merge?.idea_id : "Go To Idea"}
                          </Typography>
                          <LoadingButton
                              style={{position: "absolute", right:"7rem", padding:'0rem',fontSize:'1rem',backgroundColor:'white',fontWeight:'500'}}
                              className="btnlogin"
                              id="basic-button"
                              aria-controls={openSingleIdea ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openSingleIdea ? "true" : undefined}
                              onClick={()=>handleExcelReport(item?.id)}
                              size="small">
                            Download <DownloadIcon/>
                          </LoadingButton>
                          {/* <Menu
                            id="basic-menu"
                            anchorEl={anchorElSingleIdea}
                            open={openSingleIdea}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            sx={{
                              ".MuiList-root.MuiMenu-list": {
                                padding: 0,
                              }
                            }}>
                            <MenuItem sx={{fontSize:'1rem'}} onClick={handleExcelReport}><img src={excelIcon} style={{ width: '1.5rem', paddingRight: '5px' }} /> Individual Idea</MenuItem>
                            
                          </Menu> */}
                        </Box>
                        <>
                        {/* <LetSuspense
                          loadingPlaceholder={BouncingLoader}
                          condition={imageDataIdeaAPI}
                        > */}
                           {imageDataIdeaAPI ? <>
                           {/* @ts-ignore */}
                          <RViewerJS>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                            }}
                          >
                            {imageDataIdea &&
                              imageDataIdea[0]?.images?.map((item: any) => {
                                return (
                                  <span
                                    style={{
                                      marginTop: "0.5rem",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    <span style={{ color: "grey" }}>
                                      {item?.parameter_name}
                                    </span>
                                    <span>
                                        <Image
                                          src={item?.value}
                                          placeholderImg={NoImgLoader}
                                          alt={item?.parameter_name}
                                          onClick={(e: any) =>
                                            handleImageClick(e, item?.value)
                                          }
                                          style={{
                                            objectFit: "contain",
                                            height: "90%",
                                            width: "90%",
                                            cursor: "pointer",
                                          }}
                                        />
                                    </span>
                                  </span>
                                );
                              })}  
                            </div>
                            </RViewerJS>
                            </>
                            :
                            <Skeleton sx={{ height: "10rem", width: "100%"}} />
                            }
                        {/* </LetSuspense> */}
                        </>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
          <Box sx={{ float: "right", margin: "0.5rem" }}>
            <Stack spacing={5} flexDirection="column">
              <Pagination
                page={page}
                onChange={(event: any, value: any) => {
                  setPage(value);
                }}
                sx={{ flexDirection: "column" }}
                count={ideaAccordianData && ideaAccordianData[0]?.page_count}
                size="small"
              />
            </Stack>
          </Box>
        </>
      )}
      </>
      : <Box sx={{height:'100%',marginTop:'0.5rem'}}>
      {/* sx={{ display: "flex", rowGap: "4rem",flexDirection:"row",height:'3rem' }}> */}
      <Skeleton sx={{ height: "4rem", width: "100%",margin: "0.2rem  0" }} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%",margin: "0.2rem  0" }} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
      <Skeleton sx={{ height: "4rem", width: "100%" ,margin: "0.2rem  0"}} />
    </Box>
    }
    {/* </LetSuspense> */}
    <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={"We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"} type={"firstdownload"}/>
    </>
  );
};

export default MiddleIdeaComponent;
