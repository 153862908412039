import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Skeleton,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import EditIcon from "@mui/icons-material/Edit";
  import RViewerJS from "viewerjs-react";
  import ClearIcon from "@mui/icons-material/Clear";
  import DeleteIcon from "@mui/icons-material/Delete";
  import CancelIcon from "@mui/icons-material/Cancel";
  import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
  import PCBImageEditor from "./PCBImageEditor";
  import { LoadingButton } from "@mui/lab";
  import Swal from "sweetalert2";
  import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
  import { API } from "../../../api-services";
  
  import RoboFlowImgUploadMultiple from "./RoboFlowImgUploadMultiple";
  import PCBMaskingImgEditor from "./PCBMaskingImgEditor";
  import { ADMIN } from "../../../Redux/Services/admin.service";
  
  function RoboflowViewImageContainer({
    step,
    setStep,
    data,
    loading,
    tableData,
    setTableData,
    tableDataBottom,
    setTableDataBottom,
    tableDataTop,
    setTableDataTop,
    handleRefetch,
    refetchLoader
  }: any) {
    const { vault } = useRouteParams<any>();
    const [fetchLoading, setFetchLoading] = useState<any>(false);
    const [fetchLoadingBottom, setFetchLoadingBottom] = useState<any>(false);
    const [imgUploadedTop, setImgUploadedTop] = useState<any>([]);
    const [imgUploadedBottom, setImgUploadedBottom] = useState<any>([]);
    const [topImages, setTopImages] = useState<File[]>([]);
    const [bottomImages, setBottomImages] = useState<File[]>([]);
    const [isUploadingTopImages, setIsUploadingTopImages] = useState(false);
    const [isUploadingBottomImages, setIsUploadingBottomImages] = useState(false);
    const [presignedStitchedTopImage, setPresignedStitchedTopImage] = useState<
      string | null
    >(null);
    const [presignedOverlayTopImage, setPresignedOverlayTopImage] = useState<
      string | null
    >(null);
    const [presignedOverlayBottomImage, setPresignedOverlayBottomImage] =
      useState<string | null>(null);
    const [presignedRefTopImage, setPresignedRefTopImage] = useState<
      string | null
    >(null);
    const [presignedRefBottomImage, setPresignedRefBottomImage] = useState<
      string | null
    >(null);
    const [presignedStitchedBottomImage, setPResignedStitchedBottomImage] =
      useState<string | null>(null);
    const [stitchedTopImage, setStitchedTopImage] = useState<string | null>(null);
    const [stitchedBottomImage, setStitchedBottomImage] = useState<string | null>(
      null
    );
    const [overlayTopImage, setoverlayTopImage] = useState<any>(null);
    const [overlayBottomImage, setoverlayBottomImage] = useState<any>(null);
    const [length, setLength] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);
    const [color, setColor] = useState<any>("#6999c9");
    const [isFetchingTopImage, setIsFetchingTopImage] = useState(false);
    const [isFetchingBottomImage, setIsFetchingBottomImage] = useState(false);
    const cropModal: any = useBaseParamsModal();
    const cropModal2: any = useBaseParamsModal();
    const [modalImage, setModalImage] = useState<string | null>(null);
    const [topRefImage, setTopRefImage] = useState<any>(null);
    const [bottomRefImage, setBottomRefImage] = useState<any>(null);
  
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>,
      type: "top" | "bottom",
      imgFiles?: any
    ) => {
      if (type == "top") {
        setIsUploadingTopImages(true);
      } else {
        setIsUploadingBottomImages(true);
      }
      const files = event.target.files;
  
      if (files) {
        if (type === "top") {
          setTopImages([...files]);
          RoboFlowImgUploadMultiple(
            files,
            vault,
            isUploadingTopImages,
            setIsUploadingTopImages,
            type,
            // handleImgUpload
  
            imgUploadedTop,
            setImgUploadedTop
          );
        } else {
          setBottomImages([...files]);
          RoboFlowImgUploadMultiple(
            files,
            vault,
            isUploadingBottomImages,
            setIsUploadingBottomImages,
            type,
            // handleImgUpload
            imgUploadedBottom,
            setImgUploadedBottom
          );
        }
  
        if (type == "top") {
          setIsUploadingTopImages(false);
        } else {
          setIsUploadingBottomImages(false);
        }
      }
    };
  
    const handleStitchedImageFetch = async (type: "top" | "bottom") => {
      if (type === "top") setIsFetchingTopImage(true);
      else setIsFetchingBottomImage(true);
  
      if (type === "top") getStitchedImg("top", topImages);
      else getStitchedImg("bottom", bottomImages);
    };
  
    const convertFilesToBinary = async (files: File[]): Promise<string[]> => {
      const filePromises = files?.map((file) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = () => reject(new Error("Failed to read file"));
          reader.readAsDataURL(file); // Convert to Base64
        });
      });
  
      return Promise.all(filePromises);
    };
  
    const preparePayload = async (type: "top" | "bottom", files: File[]) => {
      const convertedFiles = await convertFilesToBinary(files);
  
      const payload = {
        multiple_top_image: type === "top" ? convertedFiles : null,
        multiple_bottom_image: type === "bottom" ? convertedFiles : null,
      };
  
      return payload;
    };
  
    const getStitchedImg = (type?: any, files?: any) => {
      let imgagess: any;
  
      const cloudfrontBaseURL = data?.cdn_url;
  
      let cleanImgUploadedTop;
      let cleanImgUploadedBottom;
  
      if (cloudfrontBaseURL) {
        cleanImgUploadedTop = imgUploadedTop?.map((url: any) => {
          return url?.includes(cloudfrontBaseURL)
            ? url?.replace(cloudfrontBaseURL, "")
            : url;
        });
  
        cleanImgUploadedBottom = imgUploadedBottom?.map((url: any) => {
          return url?.includes(cloudfrontBaseURL)
            ? url?.replace(cloudfrontBaseURL, "")
            : url;
        });
      }
  
      if (type === "top") {
        topImages?.forEach((image, index) => {
          const formData = new FormData();
          formData.append("multiple_top_image", image);
  
          // formData.append("multiple_top_image", image);
        });
      } else {
        bottomImages?.forEach((image, index) => {
          const formData = new FormData();
  
          formData.append("multiple_bottom_image", image);
        });
      }
  
      const payLoad =
        type == "top"
          ? {
              multiple_top_image: cleanImgUploadedTop
                ? cleanImgUploadedTop
                : imgUploadedTop,
            }
          : {
              multiple_bottom_image: cleanImgUploadedBottom
                ? cleanImgUploadedBottom
                : imgUploadedBottom,
            };
  
      API.put(`pcb/pcb_info_for_vault/${vault}/`, payLoad, {}, 0)
        .then((res) => {
          if (type === "top") {
            setStitchedTopImage(res.data?.stitched_top_image);
            setPresignedStitchedTopImage(res.data?.presigned_stitched_top_image);
          } else {
            setStitchedBottomImage(res.data?.stitched_bottom_image);
            setPResignedStitchedBottomImage(
              res.data?.presigned_stitched_bottom_image
            );
          }
        })
        .catch((err: any) => {
          const { data }: any = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                          <br />
                          <p style="color:"red">${data[0]}</p>   
                        </div>`,
          });
        })
        .finally(() => {
          if (type === "top") {
            setTopRefImage(null);
  
            setIsFetchingTopImage(false);
          } else {
            setBottomRefImage(null);
  
            setIsFetchingBottomImage(false);
          }
        });
    };
  
    //   // -----------------------------------------------
    // };
  
    const closeEditorModal = () => {
      cropModal.close();
      setModalImage(null);
    };
  
    const handleRemoveImage = (index: number, type?: any) => {
      if (type == "top") {
        setTopImages((prev) => prev?.filter((_, i) => i !== index));
        setImgUploadedTop((prev: any) =>
          prev?.filter((_: any, i: any) => i !== index)
        );
      } else {
        setBottomImages((prev) => prev?.filter((_, i) => i !== index));
        setImgUploadedBottom((prev: any) =>
          prev?.filter((_: any, i: any) => i !== index)
        );
      }
    };
  
    // useEffect(() => {
    //   if (topImages?.length === 0) {
    //     setTopRefImage(null);
    //     setStitchedTopImage(null);
    //   }
    //   if (bottomImages?.length === 0) {
    //     setBottomRefImage(null);
    //     setStitchedBottomImage(null);
    //   }
    // }, [topImages, bottomImages]);
  
    // console.log(topImages);
    // console.log(stitchedTopImage);
  
    const imageForApp =
      cropModal2.propsId.image &&
      cropModal2.propsId.image?.replaceAll("prod.xcpep.com/", "");
    const relImageURL =
      cropModal2.propsId.image &&
      (cropModal2.propsId.image?.includes("prod.xcpep.com/") === false
        ? cropModal2.propsId.image
            ?.split("?")[0]
            ?.split(".com")[1]
            ?.replaceAll("%20", " ")
            ?.replaceAll("%28", "(")
            ?.replaceAll("%29", ")")
        : imageForApp
            ?.split("?")[0]
            ?.split(".com")[1]
            ?.replaceAll("%20", " ")
            ?.replaceAll("%28", "(")
            ?.replaceAll("%29", ")"));
  
    useEffect(() => {
      const cloudfrontBaseURL = data?.cdn_url;
      let cleanImgUploadedTop;
      let cleanImgUploadedBottom;
  
      if (cloudfrontBaseURL) {
        cleanImgUploadedTop = data?.multiple_top_images?.map((url: any) => {
          const count = (url?.match(new RegExp(cloudfrontBaseURL, "g")) || [])
            ?.length;
  
          return count > 1
            ? url?.replace(
                new RegExp(`(${cloudfrontBaseURL})+`, "g"),
                cloudfrontBaseURL
              )
            : url;
        });
  
        cleanImgUploadedBottom = data?.multiple_bottom_images?.map((url: any) => {
          const count = (url?.match(new RegExp(cloudfrontBaseURL, "g")) || [])
            ?.length;
  
          return count > 1
            ? url?.replace(
                new RegExp(`(${cloudfrontBaseURL})+`, "g"),
                cloudfrontBaseURL
              )
            : url;
        });
      }
      if (data) {
        setImgUploadedTop(
          cleanImgUploadedTop ? cleanImgUploadedTop : data?.multiple_top_images
        );
        setImgUploadedBottom(
          cleanImgUploadedBottom
            ? cleanImgUploadedBottom
            : data?.multiple_bottom_images
        );
        setStitchedTopImage(data?.stitched_top_image);
        setStitchedBottomImage(data?.stitched_bottom_image);
        setPresignedStitchedTopImage(data?.presigned_stitched_top_image);
        setPResignedStitchedBottomImage(data?.presigned_stitched_bottom_image);
        setPresignedOverlayTopImage(data?.presigned_top_image);
        setPresignedOverlayBottomImage(data?.presigned_bottom_image);
        setoverlayTopImage(data?.top_image);
        setoverlayBottomImage(data?.bottom_image);
        setTopRefImage(data?.top_reference_image);
        setBottomRefImage(data?.bottom_reference_image);
        setLength(data?.top_ref_length);
        setWidth(data?.top_ref_width);
        setColor(
          data?.top_rgb_value ? data?.top_rgb_value : data?.bottom_rgb_value
        );
        // setLength(data?.top_ref_length);
        // setWidth(data?.top_ref_width);
      }
    }, [data]);
  
    const fetchDetails = (type?: any) => {
      if (type == "top") {
        setFetchLoading(true);
      } else {
        setFetchLoadingBottom(true);
      }
      const payLoad =
        type == "top"
          ? {
              pcb_location: "top",
            }
          : {
              pcb_location: "bottom",
            };
  
      API.post(`/pcb/fetch_pcb_automation_via_xcpep/${vault}/`, payLoad, {}, 0)
        .then((res: any) => {
          // setTableData(res.data)
          setStep((prev: any) => prev + 1);
        })
        .catch((err: any) => {})
        .finally(() => {
          if (type == "top") {
            setFetchLoading(false);
          } else {
            setFetchLoadingBottom(false);
          }
        });
    };

  
  

  
    return (
      <>
        {cropModal.isOpen && (
          <PCBImageEditor
            isOpen={cropModal.isOpen}
            onCloseModal={() => {
              cropModal.close();
              setLength(null);
              setWidth(null);
              setColor(null);
            }}
            image={cropModal.propsId.image}
            setImage={cropModal.propsId.setImage}
            setPresignedImage={cropModal.propsId.setPresignedImage}
            setColor={setColor}
            color={color}
            setLength={setLength}
            length={length}
            setWidth={setWidth}
            width={width}
            vault={vault}
            type={cropModal.propsId?.type}
          />
        )}
        {cropModal2.isOpen && (
          <PCBMaskingImgEditor
            isOpen={cropModal2.isOpen}
            onCloseModal={() => {
              cropModal2.close();
            }}
            image={cropModal2.propsId.image}
            setImage={cropModal2.propsId.setImage}
            setPresignedImage={cropModal2.propsId.setPresignedImage}
            setColor={setColor}
            color={color}
            vault={vault}
            type={cropModal2.propsId?.type}
          />
        )}
  
        {loading ? (
          <>
            <Box sx={{ p: 4 }}>
              {/* Upload Sections */}
              <Grid container spacing={2} sx={{ mb: 4 }}>
                {/* Upload Top Images Skeleton */}
                <Grid item xs={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={150}
                      sx={{ mb: 2 }}
                    />
                    <Box
                      sx={{ display: "flex", gap: 1, justifyContent: "center" }}
                    >
                      {Array(5)
                        .fill(null)
                        .map((_, index) => (
                          <Skeleton
                            key={index}
                            variant="rectangular"
                            width={50}
                            height={50}
                            sx={{ borderRadius: "4px" }}
                          />
                        ))}
                    </Box>
                  </Paper>
                </Grid>
  
                {/* Upload Bottom Images Skeleton */}
                <Grid item xs={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={150}
                      sx={{ mb: 2 }}
                    />
                    <Box
                      sx={{ display: "flex", gap: 1, justifyContent: "center" }}
                    >
                      {Array(5)
                        .fill(null)
                        .map((_, index) => (
                          <Skeleton
                            key={index}
                            variant="rectangular"
                            width={50}
                            height={50}
                            sx={{ borderRadius: "4px" }}
                          />
                        ))}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
  
              {/* Image Sections Skeleton */}
              <Box sx={{display:"flex",}}>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mb: 4, width:"50%" }}
              >
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <Box key={index} sx={{ width: "32%" }}>
                      <Skeleton
                        variant="rectangular"
                        width={150}
                        height={150}
                        sx={{ borderRadius: "4px", mb: 1 }}
                      />
                    </Box>
                  ))}
              </Box>
  
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mb: 4, width:"50%" }}
              >
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <Box key={index} sx={{ width: "32%" }}>
                      <Skeleton
                        variant="rectangular"
                        width={150}
                        height={150}
                        sx={{ borderRadius: "4px", mb: 1 }}
                      />
                    </Box>
                  ))}
              </Box>
              </Box>
  
              {/* Buttons Skeleton */}
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Skeleton variant="rectangular" width={150} height={40} />
                <Skeleton variant="rectangular" width={150} height={40} />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            p={2}
            sx={{ height: "80vh", display:"flex", flexDirection:"column", justifyContent:"space-between" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box display="flex" justifyContent="space-between" mb={3}>
              {["top", "bottom"].map((type) => (
                <Box sx={{ width: "48%" }}>
                  {/* Section 1: Upload Images */}
                  <Box
                    key={type}
                    width="100%"
                    p={2}
                    border="1px dashed grey"
                    borderRadius={1}
                    textAlign="center"
                    position="relative"
                  >
                    <Typography variant="h6">
                        {/* Upload */}
                         {type == "top" ? "Top" : "Bottom"} Images</Typography>
                    {/* <input
                      type="file"
                      multiple
                      accept="image/*"
                      style={{ display: "none" }}
                      id={`upload-${type}`}
                      onChange={(e: any) => {
                        handleImageUpload(e, type as "top" | "bottom");
                        // if (type == "top") {
                        //   setTopImages(e.currentTarget.files);
                        // } else {
                        //   setBottomImages(e.currentTarget.files);
                        // }
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor={`upload-${type}`}>
                        <Button
                          variant="outlined"
                          component="span"
                          disabled={
                            type == "top"
                              ? isUploadingTopImages
                              : isUploadingBottomImages
                          }
                        >
                          {(
                            type == "top"
                              ? isUploadingTopImages
                              : isUploadingBottomImages
                          ) ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Upload Images"
                          )}
                        </Button>
                      </label>
                    </Box> */}
  
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      overflow="auto"
                      height={
                        topImages?.length > 0 || bottomImages?.length > 0
                          ? "10rem"
                          : "0"
                      }
                      gap="1rem"
                      mt={2}
                    >
                      {/* @ts-ignore */}
                      {/* <RViewerJS> */}
                      {(
                        type == "top"
                          ? isUploadingTopImages
                          : isUploadingBottomImages
                      ) ? (
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            gap: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                          <Skeleton sx={{ width: "75px", height: "75px" }} />
                        </Box>
                      ) : (
                        <>
                          {(type === "top" ? imgUploadedTop : imgUploadedBottom)
                            ?.length > 0 && (
                            <>
                              {(type === "top" ? topImages : bottomImages)
                                ?.length > 0 ? (
                                <>
                                  {(type === "top"
                                    ? topImages
                                    : bottomImages
                                  )?.map((image: any, index: any) => (
                                    //@ts-ignore 
                                    <RViewerJS> 
                                      <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt={`Preview ${index}`}
                                        style={{
                                          width: "75px",
                                          height: "75px",
                                          objectFit: "cover",
                                          margin: "5px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                      />
                                       </RViewerJS> 
                                      
                                  ))}
                                </>
                              ) : (
                                <>
                                  {(type === "top"
                                    ? imgUploadedTop
                                    : imgUploadedBottom
                                  )?.map((image: any, index: any) => (
                                    //@ts-ignore 
                                    <RViewerJS> 
                                      <img
                                        key={index}
                                        src={image}
                                        alt={`Preview ${index}`}
                                        style={{
                                          width: "75px",
                                          height: "75px",
                                          objectFit: "cover",
                                          margin: "5px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      </RViewerJS>
                                      
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {/* </RViewerJS> */}
                    </Box>
  
                    {/* <Typography variant="body2" mt={1}>
                      {type === "top"
                        ? imgUploadedTop?.length
                        : imgUploadedBottom?.length}{" "}
                      images uploaded
                    </Typography> */}
                  </Box>
                  {/* Section 2: Get Stitched Image */}
                  {/* <Box textAlign="center" mb={2} mt={2}>
                 
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      (type === "top" ? topImages : bottomImages).length === 0 ||
                      (type === "top"
                        ? isFetchingTopImage
                        : isFetchingBottomImage)
                    }
                    onClick={() =>
                      handleStitchedImageFetch(type as "top" | "bottom")
                    }
                  >
                    {(
                      type === "top" ? isFetchingTopImage : isFetchingBottomImage
                    ) ? (
                      <CircularProgress size={24} />
                    ) : (
                      `Get Stitched ${
                        type.charAt(0).toUpperCase() + type.slice(1)
                      } Image`
                    )}
                  </Button>
                 
                            
              
                </Box> */}
  
                  {/* <Box textAlign="center" mb={2} mt={2}>
                    {((type === "top" ? imgUploadedTop : imgUploadedBottom)
                      ?.length === 0 ||
                      (type === "top"
                        ? isFetchingTopImage
                        : isFetchingBottomImage)) && (
                      <Button variant="contained" color="primary" disabled>
                        {`Get Stitched ${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        } Image`}
                      </Button>
                    )}
  
                   
  
                    {(type === "top" ? imgUploadedTop : imgUploadedBottom)
                      ?.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          type === "top"
                            ? isFetchingTopImage || !!stitchedTopImage
                            : isFetchingBottomImage || !!stitchedBottomImage
                        }
                        onClick={() =>
                          handleStitchedImageFetch(type as "top" | "bottom")
                        }
                      >
                        {(
                          type === "top"
                            ? isFetchingTopImage
                            : isFetchingBottomImage
                        ) ? (
                          <CircularProgress size={24} />
                        ) : (
                          `Get Stitched ${
                            type.charAt(0).toUpperCase() + type.slice(1)
                          } Image`
                        )}
                      </Button>
                    )}
                  </Box> */}
  
                  {/* Section 3: Show Stitched Image */}
                  {(type === "top" ? stitchedTopImage : stitchedBottomImage) && (
                    <Box
                     
                      mt={4}
                      border={`1px solid ${
                        stitchedTopImage || stitchedBottomImage
                          ? "##94c3d1"
                          : "grey"
                      }`}
                      borderRadius={1}
                      textAlign="center"
                      sx={{
                        cursor: (
                          type === "top" ? stitchedTopImage : stitchedBottomImage
                        )
                          ? "pointer"
                          : "default",
                      }}
                    >
                      {(type === "top"
                        ? stitchedTopImage
                        : stitchedBottomImage) && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ width: "32%", position: "relative" }}>
                              {/* @ts-ignore*/}
                              <RViewerJS>
                                <img
                                  src={
                                    type === "top"
                                      ? stitchedTopImage!
                                      : //  "https://d1ks1attulv6al.cloudfront.net/pcb_automation/173/multiple_top_images/569828.png"
  
                                        stitchedBottomImage!
                                  }
                                  alt={`Stitched ${type}`}
                                  style={{
                                    width: "100%",
                                    maxHeight: "14rem",
                                  }}
                                />
                              </RViewerJS>
                              {/* <CancelIcon
                                sx={{
                                  color: "#d13b3b",
                                  position: "absolute",
                                  top: "-14px",
                                  right: "-15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleDeleteStitchedImage(type);
                                }}
                              /> */}
  
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "1rem",
                                }}
                              >
                                {(
                                  type === "top"
                                    ? stitchedTopImage
                                    : stitchedBottomImage
                                ) ? (
                                  <Typography>
                                    Stitched {type === "top" ? "Top" : "Bottom"}{" "}
                                    Image
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
  
                            {(
                              type === "top"
                                ? overlayTopImage !== null
                                : overlayBottomImage !== null
                            ) ? (
                              <Box sx={{ width: "32%", position: "relative" }}>
                                {/* @ts-ignore*/}
                                <RViewerJS>
                                  <img
                                    src={
                                      type === "top"
                                        ? overlayTopImage!
                                        : overlayBottomImage!
                                    }
                                    alt={`Overlayed ${type}`}
                                    style={{ width: "100%", maxHeight: "14rem" }}
                                  />
                                </RViewerJS>
  
                                {/* <CancelIcon
                                  sx={{
                                    color: "#d13b3b",
                                    position: "absolute",
                                    top: "-14px",
                                    right: "-15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleDeleteOverlayedImage(type);
                                  }}
                                /> */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  {(
                                    type === "top"
                                      ? overlayTopImage
                                      : overlayBottomImage
                                  ) ? (
                                    <Typography>
                                      Overlayed{" "}
                                      {type === "top" ? "Top" : "Bottom"} Image
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                onClick={() => {
                                  const overlayedImg =
                                    type === "top"
                                      ? presignedStitchedTopImage
                                      : presignedStitchedBottomImage;
                                  if (overlayedImg)
                                    cropModal2.open({
                                      type: type,
                                      image: overlayedImg,
                                      setImage:
                                        type === "top"
                                          ? setoverlayTopImage
                                          : setoverlayBottomImage,
                                      setPresignedImage:
                                        type === "top"
                                          ? setPresignedOverlayTopImage
                                          : setPresignedOverlayBottomImage,
                                    });
                                  // (stitchedImage);
                                }}
                                border="1px dashed grey"
                                borderRadius={1}
                                textAlign="center"
                                sx={{
                                  width: "32%",
                                  p: 2,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                Add Overlay
                              </Box>
                            )}
                            {(type === "top" ? topRefImage : bottomRefImage) ? (
                              <Box sx={{ width: "32%", position: "relative" }}>
                                {/* @ts-ignore*/}
                                <RViewerJS>
                                  <img
                                    src={
                                      type === "top"
                                        ? topRefImage!
                                        : bottomRefImage!
                                    }
                                    alt={`Referenced ${type}`}
                                    style={{ width: "100%", maxHeight: "14rem" }}
                                  />
                                </RViewerJS>
  
                                {/* <CancelIcon
                                  sx={{
                                    color: "#d13b3b",
                                    position: "absolute",
                                    top: "-14px",
                                    right: "-15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleDeleteReferencedImage(type);
                                  }}
                                /> */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  {(
                                    type === "top"
                                      ? overlayTopImage
                                      : overlayBottomImage
                                  ) ? (
                                    <Typography>
                                      Reference{" "}
                                      {type === "top" ? "Top" : "Bottom"} Image
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                key={type}
                                width="32%"
                                p={2}
                                border="1px dashed grey"
                                borderRadius={1}
                                textAlign="center"
                                position="relative"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                                onClick={() => {
                                  const stitchedImage =
                                    type === "top"
                                      ? overlayTopImage
                                        ? overlayTopImage
                                        : stitchedTopImage
                                      : overlayBottomImage
                                      ? overlayBottomImage
                                      : stitchedBottomImage;
                                  if (stitchedImage)
                                    cropModal.open({
                                      type: type,
                                      image: stitchedImage,
                                      // type === "top"
                                      // ? (overlayTopImage ? overlayTopImage : stitchedTopImage)
                                      // : (overlayBottomImage ? overlayBottomImage : stitchedBottomImage),
                                      setImage:
                                        type === "top"
                                          ? setTopRefImage
                                          : setBottomRefImage,
                                      setPresignedImage:
                                        type === "top"
                                          ? setPresignedRefTopImage
                                          : setPresignedRefBottomImage,
                                    });
                                  // (stitchedImage);
                                }}
                              >
                                Create Reference{" "}
                                {type?.charAt(0)?.toUpperCase() + type?.slice(1)}{" "}
                                Image
                              </Box>
                            )}
                          </Box>
  
                          {/* <Button
                    variant="contained"
                    component="span"
                  sx={{marginTop:"1rem"}}
                  >
                    Create Referenced {type?.charAt(0)?.toUpperCase() + type?.slice(1)}{" "} Image
                  </Button> */}
                        </>
                      )}
                    </Box>
                  )}
                  
                </Box>
              ))}
            </Box>
  
            {/* {topRefImage && bottomRefImage && ( */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <LoadingButton
                size="small"
                variant="contained"
                sx={{backgroundColor:"red"}}
                onClick={() => {
                  handleRefetch();
                }}
                loading={refetchLoader}
              >
                Delete and Refetch
              </LoadingButton>
         
            </Box>
          {/* )} */}
          </Box>
        )}
      </>
    );
  }
  
  export default RoboflowViewImageContainer;
  