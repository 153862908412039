import React, {  } from 'react';
import styles from './MediaCreateProjectSelection.module.scss';
import { Box, Checkbox, Typography } from "@mui/material";
import { useRouteMatch } from 'react-router-dom';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';


interface MediaCreateDrawerBodyProps {
    item: any;
    selectedProject: any;
    selectAllProject: any;
    getselectedProjectBackgroundColor: any;
    getSelectedProjectTextColor: any;
    setChildMediaType: any;
    mediaCount: any;
    selectedMediaType?:any;
    setSelectedMediaType?:any;
}

interface Params {
    mediaType?: any;
}

const MediaCreateDrawerBody: React.FC<MediaCreateDrawerBodyProps> = (props) => {
    const { item, selectedProject, selectAllProject, getselectedProjectBackgroundColor, getSelectedProjectTextColor, setChildMediaType, mediaCount, selectedMediaType, setSelectedMediaType } = props;
    const { mediaType } = useRouteParams<Params>();

    const { url } = useRouteMatch();

    React.useEffect(() => {
        if (url?.includes('mediacreateinternal')) {
            setSelectedMediaType([mediaType]);
        }
    }, []);

    const selectMediaType = (mediaTypeIncoming: any) => {
        setSelectedMediaType([mediaTypeIncoming]);
        setChildMediaType(mediaTypeIncoming)
    };

    const getSelectedMediaTypeBackgroundColor = (mediaTypeIncoming: any) => {
        if (selectedMediaType?.includes(mediaTypeIncoming)) {
            return "primary.main";
        } else {
            return "primary.light";
        }
    };

    const getSelectedMediaTypeTextColor = (mediaTypeIncoming: any) => {
        if (selectedMediaType?.includes(mediaTypeIncoming)) {
            return "primary.light";
        } else {
            return "primary.main";
        }
    };

    const getSelectedMediaTypeBackgroundColorSub = (idd: any) => {
        if (selectedMediaType?.includes("image")) {
            return "#2b9cf6eb";
        } else {
            return "primary.light";
        }
    };
    const getSelectedMediaTypeBackgroundColorSubVideo = (idd: any) => {
        if (selectedMediaType?.includes("video")) {
            return "#2b9cf6eb";
        } else {
            return "primary.light";
        }
    };

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                    style={{ padding: "3px" }}
                    checked={
                        selectedProject?.includes(item.id) ? true : false
                    }
                    onClick={() => { return selectAllProject(item?.id), setSelectedMediaType([]) }}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
                <Box
                    onClick={() => { return selectAllProject(item?.id), setSelectedMediaType([]) }}
                    key={item?.id}
                    className={styles.selectVehBtn}
                    sx={{
                        // margin: "0rem 2rem",
                        padding: "0.2rem 0.5rem",
                        backgroundColor: getselectedProjectBackgroundColor(
                            item.id
                        ),
                        color: getSelectedProjectTextColor(item.id),
                        textAlign: "left",
                        cursor: "pointer",
                        width: "100%",
                    }}
                >
                    <Typography
                        style={{ fontSize: "0.87rem", display: "flex" }}
                    >
                        {item.name}
                    </Typography>
                </Box>
            </Box>
            {selectedProject?.includes(item.id) && <>
                <Box sx={{ alignItems: "center", display: "flex", marginLeft: '1.5rem' }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            selectedMediaType?.includes("image") ? true : false
                        }
                        onClick={() => selectMediaType("image")}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' }, color: "#2b9cf6eb", } }}
                    />
                    <Box
                        onClick={() => selectMediaType("image")}
                        sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getSelectedMediaTypeBackgroundColorSub("image"),
                            color: getSelectedMediaTypeTextColor("image"),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize', paddingLeft: '0.5rem' }}>Images {`(${mediaCount?.images} ${mediaCount?.images == 0 || mediaCount?.images == 1 ? "Image " : "Images "})`}</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex", marginLeft: '1.5rem' }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            selectedMediaType?.includes("video") ? true : false
                        }
                        onClick={() => selectMediaType("video")}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' }, color: "#2b9cf6eb", } }}
                    />
                    <Box
                        onClick={() => selectMediaType("video")}
                        sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getSelectedMediaTypeBackgroundColorSubVideo("video"),
                            color: getSelectedMediaTypeTextColor("video"),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize', paddingLeft: '0.5rem' }}>Videos {`(${mediaCount?.videos} ${mediaCount?.videos == 0 || mediaCount?.videos == 1 ? "Video " : "Videos "})`}</Typography>
                    </Box>
                </Box>
            </>}
        </>)
}

export default MediaCreateDrawerBody;