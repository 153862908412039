import { Autocomplete, FormControl, TextField } from "@mui/material";
import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { userPermissionAction } from "./BOMEntry/BOMEntryLandingPage";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import PCBExpandPopupModal from "./PCBExpandPopupModal";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";

export interface ITableOptionsInputProps {
  expandStatus: any;
  tableName: any;
  item: any;
  getTableData?: any;
  dataForUpdate?: any;
  tableData?: any;
  setTableData?: any;
  dataForCheck?: any;
  setSelectedOptionsTable?: any;
  setColorButton?: any;
  colorButton?: any;
  setSelectedTableId?: any;
  selectedTableId?: any;
  getTableDataAll?: any;
  tableDataLoader?: any;
  setShowButton?: any;
  showButton?: any;
  BOMValidationStatus?: any;
  handleEditClick?: any;
  getTableDataAddNewRow?: any;
  setSelectedOptions?: any;
  selectedOptions?: any;
  mappKeyValue?: any;
  counter?: any;
  setTargetTable?:any;
}

export function TableOptionsInput(props: ITableOptionsInputProps) {
  const {
    tableName,
    item,
    getTableData,
    dataForUpdate,
    tableData,
    setTableData,
    dataForCheck,
    setSelectedOptionsTable,
    expandStatus,
    setColorButton,
    colorButton,
    setSelectedTableId,
    selectedTableId,
    getTableDataAll,
    tableDataLoader,
    setShowButton,
    showButton,
    BOMValidationStatus,
    handleEditClick,
    getTableDataAddNewRow,
    selectedOptions,
    setSelectedOptions,
    counter,
    mappKeyValue,
    setTargetTable
  } = props;
  const userAction = React.useContext(userPermissionAction);
  let UserAction = userAction && userAction?.action;
  const { topVault, vault, workstation, copyStatus } = useRouteParams<any>();
  const [tableOptions, setTableOptions] = React.useState<any>([]);
  const [allData, setAllData] = React.useState<any>([]);
  const [isSave, setIsSave] = React.useState<any>(false);
  const [isAdding, setIsAdding] = React.useState<any>(false);

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };


  React.useEffect(() => {
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
      },
      0
    )
      .then((res: any) => {
        setAllData(res.data);
        // setAllData(res.data.filter((itm:any)=>itm.key===item)[0]?.data)
      })
      .catch((err: any) => {});
  }, []);
  const getTableOPtions = (tableId: any) => {
    API.get(
      "/xcpep/bom_create_view/",
      {
        ...getObjKeyName(),
        table: true,
        table_id: tableId,
        unseleted_params: true,
      },
      0
    )
      .then((res: any) => {
        setTableOptions(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    if (item) {
      getTableOPtions(item);
    }
  }, [item, counter]);

  const removeDuplicateKeys = (object: any) => {
    const newObject: any = {};

    for (const key in object) {
      if (!newObject.hasOwnProperty(key)) {
        newObject[key] = object[key];
      }
    }

    return newObject;
  };

  React.useEffect(() => {
    var obj: any = {};
    const dataHeaderOld = tableData
      .filter((itm: any) => itm.key === item)[0]
      .header_item?.map((d: any) => d.key);
    const addedKey = selectedOptions?.map((ii: any) => (obj[ii?.key] = ""));
    if (Object.keys(obj)?.length > 0) {
      if (
        tableData?.filter((itm: any) => itm.key === item)[0]?.data?.length > 0
      ) {
        tableData
          ?.filter((itm: any) => itm.key === item)[0]
          ?.data?.map((part: any) => {
            let temp = removeDuplicateKeys({ ...part, ...obj });
            Object.keys(temp)?.map((i: any) => {
              part[i] = part[i] || "";
            });
          });
      } else {
        tableData?.filter((itm: any) => itm.key === item)[0]?.data?.push(obj);
      }
      setTableData([...tableData]);
    }
  }, [selectedOptions]);

  const handleSelectChangecategory = (event: any, newSystemValue: any) => {
    
    setShowButton(false);
    // setColorButton("red");
    setSelectedTableId(item);
    setSelectedOptionsTable(newSystemValue);
    // console.log(newSystemValue);
    // setSelectedOptions(newSystemValue);
    if (newSystemValue.length > 0) {
      console.log("hii");
      
      const dataHeaderOld = tableData.filter((itm: any) => itm.key === item)[0]
        .header_item;
      const dataBodyOld = tableData.filter((itm: any) => itm.key === item)[0]
        .data;
      const dataHeaderAll = allData.filter((itm: any) => itm.key === item)[0]
        ?.header_item;
      const dataBodyNewAll = allData.filter((itm: any) => itm.key === item)[0]
        ?.data;
      const newDataHeader = [...dataHeaderOld, ...newSystemValue];
      const set = new Set(newDataHeader);
      const arr = Array.from(set);
      tableData.filter((itm: any) => itm.key === item)[0].header_item = arr;
      const finalSet = new Set(
        tableData.filter((itm: any) => itm.key === item)[0].header_item
      );
      const finalArr = Array.from(finalSet);

      setTableData([...tableData]);
    } else {
      console.log("byee hii");
      getTableData();
    }
  };
  const BOMPCBModal = useBaseParamsModal();
  const BOMPCBModalHandler = () => {
    BOMPCBModal.open();
  };
  const AddParams = () => {
    setIsAdding(true);
    if (
      (copyStatus == false || copyStatus == "false") &&
      (UserAction?.includes("U") || UserAction?.includes("C"))
    ) {
      
        API.get(
          "/xcpep/bom_create_view/",
          {
            workstation: workstation,
            table: true,
            ...getObjKeyName(),
          },
          0
        )
          .then((res: any) => {
            setTargetTable(res.data?.filter((itm: any) => itm.key === item)?.[0])
            let data: any = res.data?.filter((itm: any) => itm.key === item)[0]
              ?.data;
            let bodyObj: any = {};
            res.data
              ?.filter((itm: any) => itm.key === item)[0]
              ?.header_item?.map((itmm: any) => (bodyObj[itmm.key] = ""));
            let obj: any = {};

            Object.keys(data[0])?.map((itm: any) => {
              obj[itm] = "";
            });
            API.put(
              "/xcpep/component_details/74699/",
              {
                
                table_id: item,
              },
              {
                ...getObjKeyName(),
              },
              0
            )
              .then((res: any) => {
                 setSelectedTableId(item);
                 // ADMIN.toast.info("Row added successfully");

                try{
                  getTableData();
                  setIsAdding(false);
                  getTableOPtions(item);
                  getTableDataAll();
                  BOMValidationStatus();
                  // handleEditClick('', item, 0, allData, "add");
                  getTableDataAddNewRow();
                }catch{
                  ADMIN.toast.error("Error getting table data")
                }
              })
              .catch((err: any) => {
                ADMIN.toast.throwError(err,err?.response?.data[0])
                setIsAdding(false);
              });
          })
          .catch((err: any) => {
            setIsAdding(false);
          });
      } 
    
   
  };



  // else {
  //   API.put(
  //     "/xcpep/component_details/74699/",
  //     { [`${item}`]: [...dataForUpdate] },
  //     {
  //       ...getObjKeyName(),
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       Swal.fire({
  //         icon: "success",
  //         html: `<div>
  //             <br />
  //             <p style="color:"green">saved Successfully</p>   
  //              </div>`,
  //         customClass: {
  //           container: "swal2Container",
  //         },
  //       });
  //       API.get(
  //         "/xcpep/bom_create_view/",
  //         {
  //           workstation: workstation,
  //           table: true,
  //           ...getObjKeyName(),
  //         },
  //         0
  //       )
  //         .then((res: any) => {
  //           let data: any = res.data?.filter(
  //             (itm: any) => itm.key === item
  //           )[0]?.data;
  //           let bodyObj: any = {};
  //           res.data
  //             ?.filter((itm: any) => itm.key === item)[0]
  //             ?.header_item?.map((itmm: any) => (bodyObj[itmm.key] = ""));
  //           let obj: any = {};

  //           Object.keys(data[0])?.map((itm: any) => {
  //             obj[itm] = "";
  //           });
  //           API.put(
  //             "/xcpep/component_details/74699/",
  //             { [`${item}`]: [...data, bodyObj] },
  //             {
  //               ...getObjKeyName(),
  //             },
  //             0
  //           )
  //             .then((res: any) => {
  //               setSelectedTableId(item);
  //               ADMIN.toast.info("Row added successfully");

  //               setIsAdding(false);
  //               getTableOPtions(item);
  //               getTableDataAll();
  //             })
  //             .catch((err: any) => {
  //               setIsAdding(false);
  //             });
  //         })
  //         .catch((err: any) => {
  //           setIsAdding(false);
  //         });
  //       getTableData();
  //       getTableDataAll();
  //       getTableOPtions(item);
  //       setSelectedOptions([]);
  //       setColorButton("");
  //       setShowButton(false);
  //       setIsSave(false);
  //     })
  //     .catch((err: any) => {
  //       setIsSave(false);
  //       const { data } = err.response;
  //       Swal.fire({
  //         icon: "error",
  //         html: `<div>
  //         <br />
  //         <p style="color:"green">${data[0]}</p>   
  //          </div>`,
  //         customClass: {
  //           container: "swal2Container",
  //         },
  //       });
  //     });
  // }
  return (
    <>
    
      {BOMPCBModal.isOpen && (
        <PCBExpandPopupModal
          isOpen={BOMPCBModal.isOpen}
          onCloseModal={BOMPCBModal.close}
          expandStatus={false}
          idddd={item}
          tableName={tableName}
          getTableData={getTableData}
          dataForUpdate={dataForUpdate}
          tableData={tableData}
          setTableData={setTableData}
          dataForCheck={item?.header_item}
          setSelectedOptionsTable={setSelectedOptionsTable}
          setColorButton={setColorButton}
          colorButton={colorButton}
          setSelectedTableId={setSelectedTableId}
          selectedTableId={selectedTableId}
          setShowButton={setShowButton}      />
      )}
      {(copyStatus == false || copyStatus == "false") &&
        (UserAction?.includes("C") || UserAction?.includes("U")) && (
          <>
            {selectedOptions && selectedOptions.length == 0 && (
              <LoadingButton
                sx={{
                  padding: "0.2rem 0.5rem",
                  lineHeight: 1,
                  minWidth: "2rem",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                disabled={tableData?.[0]?.data?.length == 0 && tableData?.[0]?.table_name !='PCB BOM Table' }
                loading={isAdding}
                startIcon={
                  <AddIcon titleAccess="Add" sx={{ marginRight: "-1rem" }} />
                }
                onClick={() => AddParams()}
              ></LoadingButton>
             )} 
          </>
        )}
      <FormControl sx={{ width: "20rem" }}>
        <Autocomplete
          id="tags-standard"
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          multiple
          disabled={
            (copyStatus == false || copyStatus == "false") &&
            (UserAction?.includes("U") || UserAction?.includes("C"))
              ? false
              : true
          }
          limitTags={1}
          sx={{
            ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
              {
                color: "primary.main",
              },
            ".MuiButtonBase-root.MuiChip-root": {
              backgroundColor: "primary.light",
              height: "26px",
            },
          }}
          options={tableOptions && tableOptions}
          onPaste={(event: any) => {
            //   PasteEmailHandler(event.clipboardData.getData('text'))
          }}
          getOptionLabel={(option) =>
            // @ts-ignore
            option?.paramter_name == undefined ? "" : option?.paramter_name
          }
          value={selectedOptions}
          onChange={(event: any, newSystemValue: any) => {
            handleSelectChangecategory(event, newSystemValue);
          }}
          // getOptionDisabled={getOptionDisabled}
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: "0.87rem" }}>
              {option.paramter_name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select Material"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "0.87rem !important",
                },
                ".MuiSvgIcon-root": { color: "primary.main" },
              }}
            />
          )}
        />
      </FormControl>
      {/* {(copyStatus == false || copyStatus == "false") &&
        (UserAction?.includes("U") || UserAction?.includes("C")) && (
          <LoadingButton
            sx={{
              padding: "0.2rem 0.5rem",
              lineHeight: 1,
              minWidth: "2rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isSave}
            disabled={!showButton}
            startIcon={
              <SaveIcon
                titleAccess="Save"
                sx={{
                  cursor: "pointer",
                  color: isSave
                    ? ""
                    : selectedTableId == item
                    ? colorButton
                    : "",
                  marginRight: "-1rem",
                }}
              />
            }
            onClick={() => {
              setIsSave(true);
              API.put(
                "/xcpep/component_details/74699/",
                { [`${item}`]: [...dataForUpdate] },
                {
                  ...getObjKeyName(),
                },
                0
              )
                .then((res: any) => {
                  Swal.fire({
                    icon: "success",
                    html: `<div>
                        <br />
                        <p style="color:"green">saved Successfully</p>   
                         </div>`,
                    customClass: {
                      container: "swal2Container",
                    },
                  });
                  getTableData();
                  getTableDataAll();
                  getTableOPtions(item);
                  setSelectedOptions([]);
                  BOMValidationStatus();
                  setColorButton("");
                  setShowButton(false);
                  setIsSave(false);
                })
                .catch((err: any) => {
                  setIsSave(false);
                  const { data } = err.response;
                  Swal.fire({
                    icon: "error",
                    html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
                    customClass: {
                      container: "swal2Container",
                    },
                  });
                });
            }}
          ></LoadingButton>
        )} */}
      {expandStatus == false ? (
        ""
      ) : (
        <ZoomOutMapIcon
          titleAccess="Expand PCB Table"
          sx={{ cursor: "pointer" }}
          onClick={() => BOMPCBModalHandler()}
        />
      )}
    </>
  );
}
