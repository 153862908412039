
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';
// import warning from ".../images/warning.png";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '2px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',

    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

 interface RidirectionModalProps {


    isOpen: any;
  onCloseModal: any;
  getApicallhandler:any
 }

const UserAlertsModal=(props: RidirectionModalProps)=> {

    const {onCloseModal,isOpen,getApicallhandler}=props
  

    const rejectHandler=()=>{
      // Handler()
    }
    return (
        <div>
        
          <Modal
            open={isOpen}
            // onClose={onCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 430,height:100 }}>
                <Typography style={{display: "flex", flexDirection: "row",justifyContent: "space-between"}}>

                  
                    <p>
                        {/* img src={warning} /><p id="parent-modal-description" style={{ color: "#FFA500", fontWeight: 600 }}> */}

                                 Action already taken for this notification.
                            </p>
                          
                   
              <span style={{margin: "-1rem -2rem 0rem 0rem"}}>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={getApicallhandler} />
            </span>
              
              </Typography>
            </Box>
          </Modal>
        </div>
      );

  
  
}
export default UserAlertsModal;