import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import ErrorModalFilesMail from '../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { useHistory } from 'react-router-dom';
import { ArchCreateImageUploadModal } from './ArchCreateImageUploadModal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40rem",
  height: "2orem",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};



interface AddGroupNameModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;
  setcounter: any;
  UserPermissionValue:any;

}
const AddGroupNameModal = (props: AddGroupNameModalProps) => {
  const { onCloseModal, isOpen, Item, setcounter,UserPermissionValue } = props
  const [Name, setName] = React.useState<any>(null)
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();


  const ErrorModalFiles = useBaseModal()
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
  } = useRouteParams<any>();
  const [loader, setloader] = React.useState<any>(false)
  const [GroupData, setGroupData] = React.useState<any>({})
  const history = useHistory()
  const ImageModal = useBaseModal();
  const handlechange = (e: any) => {

    setName(e.target.value)

  }


  // console.log(Item, "ItemItemItem")

  const Savehandler = () => {

    setloader(true)
    API.post(`/circuit_diagram/arch_groups/`, { project: projectId, abbreviation: Item?.abbreviation, name: Name, group_type: 3 }).then((res: any) => {
      setloader(false)
      ImageModal.open()
      setGroupData(res?.data)
      // onCloseModal()
      // history.push(
      //   `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${res?.data?.id}/${collapestatus}/${1}/${1}/${res?.data?.name}`)


      // Swal.fire({

      //     html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
      //     <br />
      //     <p style="font-size: 1.5rem">Group Added Successfully</p>
      //      </div>`,


      // })
      // setcounter((prev:any)=>prev+1)
    }).catch((err: any) => {
      const { data } = err.response;
      // console.log(data, "datadata")
      ErrorModalFiles.open()
      setErrorModalFileMsg(data)
      setloader(false)
    })

  }
  return (
    <div>

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >


        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          {
            ImageModal?.isOpen &&

            <ArchCreateImageUploadModal
            userPermissionTopvaluewise={UserPermissionValue}
              GroupData={GroupData}
              setcounter={setcounter}
              type={"new_group"}
              Item={Item}
              onCloseModalPrarent={onCloseModal}
              onCloseModal={ImageModal.close}
              isOpen={ImageModal.isOpen}
            />
          }
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              {Item?.name}
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 0.5rem' }}>
            <TextField variant="standard" placeholder='Group Name' value={Name} onChange={(e: any) => handlechange(e)}
              sx={{
                width: '100%',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
              }} />
            <Box sx={{ width: '100%', textAlign: 'right', margin: '1rem 0' }}>
              {/* {
                Name?.length > 0 && */}
              <LoadingButton
                sx={{
                  // padding: "0.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}
                variant="contained" loading={loader} onClick={() => Savehandler()}>Save & Proceed to add architecture diagrams</LoadingButton>
              {/* } */}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AddGroupNameModal;