// react

import React, { Fragment, useContext, useRef } from "react";

import { useAppDispatch } from "../../../../../../../AppRouter";

import { useSelector } from "react-redux";

import { RootState } from "../../../../../../Redux/Store/storeConfigurations";

import { inputCalculationSelectors } from "../../../../../../Redux/Reducers/inputCalculation.reducer";

import { isNumber } from "lodash";

import {
  CALCULATOR_INPUT_TYPE,
  calculatorInputBackgroundColorMap,
} from "../../../../../../Configuration/Costing/calculator.constant";

import { toExponential } from "../../../../../../utlis/toExponential";

import TableRow from "@mui/material/TableRow";

import {
  Box,
  IconButton,
  Switch,
  TableCell,
  TextField,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { ID } from "../../../../../../utlis/commonInterfaces";

import dbFetch from "../../../../../../Assets/images/database_fetch.svg";



// vendors

// import { Icon, Input, InputOnChangeData, TableCell, TableRow } from 'semantic-ui-react';

// import { useSelector } from 'react-redux';

// import { isNumber } from 'lodash';

// import clsx from "classnames";


import FlagIcon from "@mui/icons-material/Flag";

import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";


// // services

// // utils

// import { toExponential } from 'app/utils/toExponential';

// // constants

// import { calculatorInputBackgroundColorMap, CALCULATOR_INPUT_TYPE } from 'app/constants/calculator.constants';

// // factories

// // core

// // redux

// import { RootState } from 'app/Redux/storeConfigurations';

// import { inputCalculationSelectors } from 'app/Redux/Reducers/inputCalculation.reducer';

// import { flaggedAssumptionSelectors } from 'app/Redux/Reducers/flaggedAssumption.reducer';

// import { createFlaggedAssumption, deleteFlaggedAssumption } from 'app/Redux/Actions/flaggedAssumption.actions';

// // ui-components

// // others

// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';

// import { useAppDispatch } from 'AppRouter';

// // styles

import styles from "./CalculatorRow.module.scss";

import { flaggedAssumptionSelectors } from "../../../../../../Redux/Reducers/flaggedAssumption.reducer";

import {
  createFlaggedAssumption,
} from "../../../../../../Redux/Actions/flaggedAssumption.actions";

import CameraAltIcon from "@mui/icons-material/CameraAlt";


import InfoIcon from "@mui/icons-material/Info";

import RViewerJS from "viewerjs-react";
import { userPermission } from "../../../CostigngCalculatorSection";
import { useRouteParams } from "../../../../../../ui-reusable-component/useRouteParams";

import { API } from "../../../../../../api-services"
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
// // interfaces and types

// import { ID } from 'app/commonInterfaces';

// lazy

// ----------------------------------------------------------------------------------

interface CalculatorRowProps {
  inputCalculationId: ID;

  openDatabaseModal: (database: any, calIds?: any) => void;

  strokeDatabaseModal: (database: number) => void;
  Defaultdata: any;
  openTable: (inputType: number, parameterId: ID) => void;

  openCalculatorResult?: (calculatorCategory: number) => void;

  onValueChange: (value: any) => void;

  userInputValue: any;

  ConstantValue: any;

  onConstValueChange: (value: any) => void;

  openDatabaseInfoModal: (database: number, recordId: ID) => () => void;

  openStrokeDatabaseInfoModal?: (database: number, recordId: ID) => () => void;

  openFlaggedAssumptionFileModal: (flaggedAssumptionId: ID) => void;

  getToggleIds: any;

  userInputId: any;

  constantIds: any;

  setSelectedId: any;

  getoggleconst: any;

  Infohaandler: any;

  setCurrencyDBExistCheck?: any;
  isWeightInput: any;
  calculatorData?: any;
  costModelCalculatorId?: any;
  openFetchBOMTableCalc?: any;
  dataOBj?: any;
  handleSave?: any;
}

const CalculatorRow: React.FC<CalculatorRowProps> = (props) => {
  const {
    inputCalculationId,

    openDatabaseModal,

    strokeDatabaseModal,

    openTable,

    Infohaandler,

    openCalculatorResult,

    onValueChange,

    getToggleIds,

    userInputValue,

    ConstantValue,

    onConstValueChange,

    openDatabaseInfoModal,

    openStrokeDatabaseInfoModal,

    userInputId,

    constantIds,

    setSelectedId,
    Defaultdata,

    openFlaggedAssumptionFileModal,

    getoggleconst,

    setCurrencyDBExistCheck,
    isWeightInput,
    calculatorData,
    costModelCalculatorId,
    openFetchBOMTableCalc,
    dataOBj,
    handleSave

  } = props;

  // const readonly = useCostingReadonly();

  const dispatch = useAppDispatch();
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    newUI
  } = useRouteParams<any>();

  const useraction = useContext<any>(userPermission);
  const [CalcData, setCalcData] = React.useState<any>(calculatorData && calculatorData);
  const history = useHistory<any>();
  var datavalue = Math.random();

  // ---------------------------------------------------------------------------------

  // selector

  const inputCalculation = useSelector((state: RootState) => {
    return inputCalculationSelectors.selectById(state, inputCalculationId);
  });

  
  //console.log("inputCalculationinputCalculation",inputCalculation)


  const imageViewerElRef = useRef<any>();

  const flaggedAssumption = useSelector((state: RootState) => {
    return flaggedAssumptionSelectors.selectByInputId(
      state,
      inputCalculationId
    );
  });

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  // ----------------------------------------------------------------------------------

  // handlers

  const handleInputChange = (e: any) => {
    onValueChange(Number(e?.target?.value));
  };

  const handleConstant = (e: any) => {
    onConstValueChange(Number(e?.target?.value));
  };


  const handleDatabaseModalOpen = () => {
    if (!inputCalculation?.database) return;

    openDatabaseModal(inputCalculation.database, inputCalculation);
  };

  const handleStroke = () => {
    if (!inputCalculation?.database) return;

    strokeDatabaseModal(inputCalculation?.database);
  };

  React.useEffect(() => {
    setCalcData(CalcData);
  }, [CalcData]);

  const handledClicked = (e: any, inputCalculation: any) => {
    API.put(`cost/material/${tableId}/`, {
      weight_input: inputCalculation?.value,
      weight_cal_id: costModelCalculatorId,
      cal_field_id: inputCalculation?.id,
    }, 0
    )
      .then((res: any) => {
        history.push(
          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}/0/${newUI}`
        );
      })
      .catch((err: any) => {
        console.log(err);
      })
  }

  const handleTableFieldModalOpen = () => {
    if (!inputCalculation?.parameter) return;

    openTable(inputCalculation.type, inputCalculation.parameter);
  };

  const handleCalculatorResultModaOpen = () => {
    const category = inputCalculation?.calculator_category;

    if (!isNumber(category)) return;

    // openCalculatorResult( category );
  };

  // const handleInputFlag = async () => {

  //     if ( !inputCalculation ) return;

  //     if ( flaggedAssumption ) {

  //         // unflag

  //         const confirm = await swal( {

  //             buttons: ["Cancel", "Yes, Unflag"],

  //             title: "Unflag input as assumption",

  //             text: "Are you sure? This is delete the assumption file, if provided",

  //             icon: "warning"

  //         } );

  //         if ( confirm ) dispatch( deleteFlaggedAssumption( { id: flaggedAssumption.id } ) );

  //         return;

  //     }

  //     dispatch( createFlaggedAssumption( {

  //         inputId: inputCalculation.id,

  //         userInputId

  //     } ) );

  // };

  const handleInputFlag = async () => {
    if (!inputCalculation) return;

    if (!flaggedAssumption) {
      const action: any = await dispatch(
        createFlaggedAssumption({
          inputId: inputCalculation.id,

          userInputId,
        })
      );

      if (action.payload.flaggedAssumption) {
        openFlaggedAssumptionFileModal(action.payload.flaggedAssumption.id);
      }
    }

    handleFlaggedFileModal();
  };

  const handleFlaggedFileModal = () => {
    if (!flaggedAssumption) return;

    // @ts-ignore

    openFlaggedAssumptionFileModal(flaggedAssumption.id);
  };

  // ----------------------------------------------------------------------------------

  // jsx abstractions


  const openFile = (file: any) => {
    window.open(file, "_blank");
  };

  const handleChange = (value: any) => {

    // @ts-ignore

    if (constantIds && constantIds?.includes(value)) {
      var filterdata = constantIds?.filter((item: any, index: any) => {
        return item !== value;
      });

      getToggleIds(value, inputCalculation?.value, ConstantValue, false);

      setSelectedId((prev: any) =>
        prev.filter((item: any, index: any) => {
          return item !== value;
        })
      );
    } else {
      var data = [...constantIds, value];

      getToggleIds(value, inputCalculation?.value, ConstantValue, true);

      setSelectedId((prev: any) => [...prev, value]);
    }
  };

  //console.log(inputCalculation, inputCalculation?.database, "idd", inputCalculationId);
  


  const renderValue = () => {
    if (!inputCalculation) return null;

    switch (inputCalculation.type) {
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <TextField
            type='number'
            disabled={
              (useraction && useraction?.action?.includes("C")) ||
                (useraction && useraction?.action?.includes("U"))
                ? false
                : true
            }
            // name={ inputCalculation.id }

            value={userInputValue}
            onChange={handleInputChange}
            variant='standard'
            sx={{
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },

              // '& .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.light' },

              "& .MuiInput-underline:before": {
                borderBottomColor: "primary.light",
              },

              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
          />
        );

      case CALCULATOR_INPUT_TYPE.CONSTANT:
        if (constantIds?.includes(String(inputCalculationId))) {
          return (
            <TextField
              type='number'
              disabled={
                (useraction && useraction?.action?.includes("C")) ||
                  (useraction && useraction?.action?.includes("U"))
                  ? false
                  : true
              }
              // name={ inputCalculation.id }

              value={ConstantValue}
              onChange={handleConstant}
              variant='standard'
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },

                // '& .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.light' },

                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          );
        } else {
          return toExponential(inputCalculation?.constant || 0);
        }

      default:
        return toExponential(inputCalculation.value || 0);
    }
  };

  const renderIcon = () => {
    if (!inputCalculation) return null;
    switch (inputCalculation.type) {
      case CALCULATOR_INPUT_TYPE.CONSTANT:
        return (
          <>

            {((useraction && useraction?.action?.includes("C")) ||
              (useraction && useraction?.action?.includes("U"))) && (
                <>
                  {constantIds?.includes(String(inputCalculationId)) &&
                    ConstantValue != null && (
                      <InfoIcon
                        onClick={() => Infohaandler(inputCalculationId)}
                        sx={{ cursor: "pointer" }}
                      />
                    )}
                </>
              )}

            {flaggedAssumption?.reference ? (
              <FlagIcon
                titleAccess='View Flag'
                sx={{ cursor: "pointer" }}
                onClick={handleInputFlag}
              />
            ) : (
              <FlagOutlinedIcon
                titleAccess='Upload Flag'
                sx={{ cursor: "pointer" }}
                onClick={handleInputFlag}
              />
            )}

            {Boolean(inputCalculation?.image_url) && (
              <CameraAltIcon
                titleAccess='camera'
                // disabled={inputCalculation.image_url ? false : true}

                onClick={() => {
                  return imageViewerElRef.current?.click?.();
                }}
                style={
                  !inputCalculation.image_url
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }

              // className={clsx({

              //     [styles.hide]: permissionsCheck.includes("C") || permissionsCheck.includes("U") || permissionsCheck.includes("D") ? false : true && !flaggedAssumption,

              //     [styles.disabled]: permissionsCheck.includes("C") || permissionsCheck.includes("U") || permissionsCheck.includes("D") ? false : true

              // })}
              />
            )}

            {((useraction && useraction?.action?.includes("C")) ||
              (useraction && useraction?.action?.includes("U"))) && (
                <Switch
                  size='small'
                  // @ts-ignore

                  title={
                    constantIds?.includes(String(inputCalculationId))
                      ? "Change to Constant"
                      : "Change to Variable"
                  }
                  checked={
                    constantIds?.includes(String(inputCalculationId))
                      ? true
                      : false
                  }
                  onChange={() => handleChange(String(inputCalculationId))}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
          </>
        );

      // case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:

      // // case CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:

      //     if ( inputCalculation.table_element ) return (

      //         <FolderIcon

      //             // name="window maximize"

      //             // link

      //             sx={{ cursor: "pointer" }}

      //             onClick={handleTableFieldModalOpen}

      //         // className={ clsx( { [styles.hide]: readonly } ) }

      //         />

      //     );

      //     return null;

      case CALCULATOR_INPUT_TYPE.DATABASE:
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
            {
              <>
                {Boolean(userInputValue) &&
                  isNumber(inputCalculation.database) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}>
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        titleAccess='info'
                        onClick={
                          inputCalculation &&
                          openDatabaseInfoModal(
                            inputCalculation.database,
                            userInputValue
                          )
                        }
                      />

                      {flaggedAssumption?.reference ? (
                        <FlagIcon
                          titleAccess='View Flag'
                          sx={{ cursor: "pointer" }}
                          onClick={handleInputFlag}
                        />
                      ) : (
                        <FlagOutlinedIcon
                          titleAccess='Upload Flag'
                          sx={{ cursor: "pointer" }}
                          onClick={handleInputFlag}
                        />
                      )}

                      {Boolean(inputCalculation?.image_url) && (
                        <CameraAltIcon
                          titleAccess='camera'
                          // disabled={inputCalculation.image_url ? false : true}

                          onClick={() => {
                            return imageViewerElRef.current?.click?.();
                          }}
                          style={
                            !inputCalculation.image_url
                              ? { cursor: "not-allowed" }
                              : { cursor: "pointer" }
                          }
                        />
                      )}



                      {inputCalculation?.db_file && (
                        <FlagIcon
                          titleAccess='View DB Machine Cost'
                          onClick={() => openFile(inputCalculation.db_file)}
                          style={
                            !inputCalculation.db_file
                              ? { cursor: "not-allowed", color: "green" }
                              : { cursor: "pointer", color: "green" }
                          }
                        />
                      )}
                      {inputCalculation?.db_spcification_file && (
                        <FlagIcon
                          titleAccess='View DB Machine Specification'
                          onClick={() => openFile(inputCalculation.db_spcification_file)}
                          style={
                            !inputCalculation.db_spcification_file
                              ? { cursor: "not-allowed", color: "green" }
                              : { cursor: "pointer", color: "green" }
                          }
                        />
                      )}
                    </Box>
                  )}
                {((useraction && useraction?.action?.includes("C")) ||
                  (useraction && useraction?.action?.includes("U"))) && (
                    <img
                      src={dbFetch}
                      style={{ height: "1.5rem", cursor: "pointer" }}
                      title='Revisions'
                      onClick={inputCalculation && handleDatabaseModalOpen}
                    />
                  )}

                {Boolean(userInputValue) &&
                  isNumber(inputCalculation.database) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}>
                      <CancelIcon
                        sx={{ cursor: "pointer" }}
                        titleAccess='info'
                        onClick={
                          () => {
                            if (Object.keys(dataOBj?.user_input)?.includes(inputCalculation?.id?.toString())) {
                              let tempArr = Object.keys(dataOBj?.user_input)?.filter((item: any) => item != inputCalculation?.id?.toString())
                              let resObj: any = {}
                              tempArr?.map((item: any) => {
                                resObj[item] = dataOBj?.user_input[item]
                              })
                              API.put(
                                `/cost/calculation/${dataOBj?.id}/`,
                                { user_input: resObj },
                                {},
                                0
                              )
                                .then((res: any) => {
                                  handleSave();
                                })
                                .catch((err: any) => {
                                  console.log("Server error");
                                });
                            }

                          }
                        }
                      />
                    </Box>
                  )}

              </>
            }
          </Box>
        );

      // case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:

      //     return (

      //         // <button

      //         //     name="window maximize"

      //         //     onClick={ handleCalculatorResultModaOpen }

      //         //     // className={ clsx( { [styles.hide]: readonly } ) }

      //         // />

      //         <FolderIcon

      //         sx={{cursor:"pointer"}}

      //         onClick={ handleCalculatorResultModaOpen }

      //         />

      //     );

      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <Fragment>
            {flaggedAssumption?.reference ? (
              <FlagIcon
                titleAccess='View Flag'
                sx={{ cursor: "pointer" }}
                onClick={handleInputFlag}
              />
            ) : (
              <FlagOutlinedIcon
                titleAccess='Upload Flag'
                sx={{ cursor: "pointer" }}
                onClick={handleInputFlag}
              />
            )}

            {Boolean(inputCalculation?.image_url) && (
              <CameraAltIcon
                titleAccess='camera'
                // disabled={inputCalculation.image_url ? false : true}

                onClick={() => {
                  return imageViewerElRef.current?.click?.();
                }}
                style={
                  !inputCalculation.image_url
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }

              />
            )}

          </Fragment>
        );

      // case CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR:
      //   return (
      //     <Fragment>
      //       {
      //         inputCalculation?.content_type === "BOMTableCalculator" && (
      //           <IconButton

      //             onClick={() => {
      //               openFetchBOMTableCalc(inputCalculation)
      //             }}
      //           >
      //             <RuleRoundedIcon
      //               titleAccess='Fetch BOM Table Calculator Result'
      //               // disabled={inputCalculation.image_url ? false : true}                  

      //               style={
      //                 { color: '#007fff' }
      //               }
      //             />
      //           </IconButton>
      //         )

      //       }
      //     </Fragment>
      //   )

      default:
        return (
          <Fragment>
            {
              Boolean(inputCalculation?.image_url) && (
                <IconButton
                  style={
                    !inputCalculation.image_url
                      ? { cursor: "not-allowed", padding: "0" }
                      : { cursor: "pointer", padding: "0" }
                  }
                  onClick={() => {
                    return imageViewerElRef.current?.click?.();
                  }}
                  disabled={inputCalculation.image_url ? false : true}>
                  <CameraAltIcon
                    titleAccess='camera'
                    // disabled={inputCalculation.image_url ? false : true}                  

                    style={
                      !inputCalculation.image_url
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  />
                </IconButton>
              )

            }
          </Fragment>
        );
    }
  };

  // ----------------------------------------------------------------------------------

  // jsx

  if (!inputCalculation) return null;

  return (
    <TableRow
      data-testid='calculator-row'
      className={styles.container}
      style={{
        backgroundColor:
          calculatorInputBackgroundColorMap[inputCalculation.type],
      }}>
      <TableCell className={styles.tableStyle} sx={{ width: "1rem" }}>
        <Checkbox {...label} onClick={(e: any) => handledClicked(e, inputCalculation)}
          sx={{ padding: '2px', color: 'primary.main', display: !isWeightInput && CalcData?.category == 1 ? "block" : !isWeightInput && CalcData?.category == 2 ? "block" : "none" }}
          disabled={useraction && useraction?.action?.includes("C") || useraction && useraction?.action?.includes("U") ? false : true}
        />
      </TableCell>

      <TableCell className={styles.tableStyle}>
        {inputCalculation.name}
      </TableCell>
      <TableCell className={styles.tableStyle}>
        <span>{renderValue()}</span>
      </TableCell>
      <TableCell className={styles.tableStyle}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.5rem",
          }}>
          {renderIcon()}
        </span>
      </TableCell>
      <TableCell
        className={styles.tableStyle}
        sx={{ paddingLeft: { lg: "", xl: "5rem" } }}>
        {inputCalculation.unit}
      </TableCell>
      <div style={{ display: "none" }}>
        {/* @ts-ignore */}
        <RViewerJS>
          <img
            src={inputCalculation?.image_url}
            alt='image'
            ref={imageViewerElRef}
          />
        </RViewerJS>
      </div>
    </TableRow>
  );
};

// ----------------------------------------------------------------------------------

export default CalculatorRow;
