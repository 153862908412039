

import Autocomplete from "@mui/material/Autocomplete";
import styles from "./ProjectmailselectionPage.module.scss";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./ProjectmailLandingpage.scss";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ErrorModalFilesMail from "./ErrorModalFilesMail";
import MailAttchmentS3Upload from "./MailAttchmentS3Upload";
import { PreviewMailModal } from "./PreviewMailModal";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { MailSucessModal } from "./MailSuccessModal";
import sendIcon from "../../Assets/images/sendOutline.png";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import resetIcon from "../../Assets/images/reset.png";
// import editmail from "../../Assets/images/mailEdit.png"
import swal from "sweetalert";
import { AUTH } from "../../Redux/Services/auth.service";
import "./ProjectDeliverymail.scss";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
// @ts-ignore
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// // @ts-ignore
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
interface ProjectmailLandingPagerProps {
  initialValue?: any;
}

const EditorConfig = {
  readonly: false,
  toolbar: true,
  // @ts-ignore
  spellcheck: true,
  saveSelectionOnFocus: true,
  saveSelectionOnBlur: true,
  language: 'en',
  // toolbarButtonSize: 'medium',
  // toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,

  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true
  },

  controls: {
    font: {
      command: 'fontname',
      list: {
        "'Open Sans',sans-serif": 'Open Sans',
        'Helvetica,sans-serif': 'Helvetica',
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Consolas,monaco,monospace': 'Consolas'
      }
    }
  }
}
const ProjectDeliverymailLandingPage: React.FC<ProjectmailLandingPagerProps> = ({
  initialValue,
}) => {

  const { emailtype, projectId, projectname, productIds, status, ccIds, date } =
    useParams<any>();
  const { height, width } = useWindowDimensions();
  useDocumentTitle( "Email" );
  const [text, setText] = useState("");
  const [content, setContent] = useState("");
  const [Selceteddate, setSelceteddate] = useState<any>(date);

  const [disableProduct, setDisableProduct] = useState<any>(status);

  const [userList, setUserList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = React.useState<any>([]);
  const [usersCC, setUsersCC] = React.useState<any>([]);

  const [ProductList, setProductList] = useState<any>([]);
  const [SubjectData, setSubjectData] = useState<any>("");
  const [value, setValue] = React.useState([]);

  const [Productvalue, setProductvalue] = useState<any>([]);
  const [SelectedIds, setSelectedIds] = useState<any>([]);
  const [EmailDatalist, setEmaildataList] = useState<any>();

  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const [AttchmentDatalist, setAttchmentDatalist] = useState<any>([])

  const [updateCounter, setUpdateCounter] = useState<any>(0)
  const [SendEmail, setSendEmail] = useState<any>(false)

  const [ImageUploadCounter, setImageUploadCounter] = useState<any>(0)
  const [attachLoader, setAttchLoader] = useState<any>(false)
  const [UserManualEamil, setUserManualEamil] = useState<any>()

  const [ContentLoader, setConentLoader] = useState<any>(false)
  const InputRef = useRef<any>();
  const editorRef = useRef(null);
  const history = useHistory<any>();
  const PreviewModal = useBaseModal()
  const successModal = useBaseModal()

  var ErrorModalFiles = useBaseModal();


  const [personName, setPersonName] = React.useState<string[]>([]);
  const [ProductDateList, setProductDateList] = useState<any>([]);

  useEffect(() => {
    API.get("/analytics/sent_mail_list/", { project: projectId })
      .then((res: any) => {
        // console.log(res.data, "fgurfurfiugriugfrf");
        setProductDateList(res?.data);
      })
      .catch((err: any) => {
        console.log("Server ERROR");
      });
  }, [projectId]);

  useEffect(() => {
    API.get("/analytics/user_access_list/", { project_id: projectId }).then(
      (res: any) => {
        setUserList(res?.data);
      }
    );
  }, [projectId]);

  // console.log(ProductDateList, "ProductDateListProductDateList");
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const EmailContentGenerateHandler = () => {
    var date = Selceteddate
    // console.log(projectname, projectId, SelectedIds, Selceteddate, "SelceteddateSelceteddate")
    setDisableProduct(true);

    history.push(
      `/mail-module/Delivery/${projectname}/${projectId}/${SelectedIds?.toString()}/${true}/0/${Selceteddate}`
    );
  };

  useEffect(() => {
    if (productIds !== "0") {

      setConentLoader(true)
      API.get("/analytics/dashboard_mail/", {
        top_vault: productIds,
        project: projectId,
        date: Selceteddate

      }, 0).then((res: any) => {
        setEmaildataList(res?.data);
        setConentLoader(false)
        setSubjectData(res?.data?.subject);
      });
    }
  }, [productIds, updateCounter]);


  useEffect(() => {

    setAttchLoader(true)
    API.get("/analytics/store_attachment_details/", { project: projectId, mail_type: 3 }).then((res: any) => {
      setAttchmentDatalist(res?.data)
      setAttchLoader(false)
    }).catch((err: any) => {
      setAttchLoader(false)
      console.log("Server Error")
    })
  }, [projectId, ImageUploadCounter])



  useEffect(() => {
    API.get("/analytics/sent_mail_list/", { product_list: projectId }).then(
      (res: any) => {
        const topVaultsParam: any =
          productIds && typeof productIds == "string"
            ? productIds?.split(",")?.map(Number)
            : [productIds];

        var FiltertheslectedProductIds =
          res?.data &&
          res?.data?.filter((item: any, index: any) => {
            return topVaultsParam && topVaultsParam?.includes(item?.id);
          });
        if (FiltertheslectedProductIds?.length > 0) {
          setProductvalue(FiltertheslectedProductIds);
        }
        setProductList(res?.data);
      }
    );
  }, [projectId, productIds]);



  useEffect(() => {

    setSelceteddate(date)

    setUsersCC([])
    setValue([])
    setDisableProduct(status)

  }, [date])
  // console.log(Selceteddate, "Selceteddate")

  var TouserListOption = () => {
    let ccemailId = usersCC?.map((itm: any) => itm.email);
    let tomailId: any = value?.map((itm: any) => itm.email);
    return (
      userList &&
      userList?.filter((item: any) => ![...ccemailId, ...tomailId]?.includes(item.email))?.map((item: any, index: any) => {
        return { ...item, id: index + 1 };
      })
    );
  };
  const handleChangeDate = (event: any) => {
    // console.log(event?.target?.value, "efiefheifefi");
    setSelceteddate(event?.target?.value);

  };

  const SubjectHandler = (e: any) => {
    setSubjectData(e?.target?.value);
  };

  const userProfilePic = useSelector(userSelectors.selectAll);






  const handleDelete = (FileIds: any) => {
    setAttchLoader(true)
    API.delete("/analytics/store_attachment_details/", { id: FileIds, mail_type: 3 }, 0).then((res: any) => {
      setImageUploadCounter((prev: any) => prev + 1)
    })

  };
  const handleSelectChangeProduct = (Productitem: any, situation: any) => {
    var collectedallIds =
      Productitem &&
      Productitem.map((item: any, index: any) => {
        return item.id;
      });
    setProductvalue([...Productitem])
    setSelectedIds(collectedallIds);

  };

  const handleSelectChange = (touser: any) => {
    var ToIds =
      touser &&
      touser?.map((item: any, index: any) => {
        return item?.id;
      });


    // @ts-ignore
    setValue([...touser]);
  };


  const PreviewHandler = () => {
    PreviewModal.open()
  }
  const handleSelectChangeCC = (ccuser: any) => {
    // @ts-ignore

    var CCIds =
      ccuser &&
      ccuser?.map((item: any, index: any) => {
        return item?.id;
      });

    //  history.push(
    //    `/mail-module/${emailtype}/${projectname}/${projectId}/${SelectedIds?.toString()}/${toIds}/${CCIds?.toString()}/${Selceteddate}`
    //  )
    setUsersCC([...ccuser]);
  };

  const browreFilehandler = () => {
    return InputRef?.current?.click();
  };



  const PartNameHandler = (item: any) => {


    setUserManualEamil(item)
  }

  const NormalUser = (userData: any, domain: any) => {
    if (domain == "advancedstructures.in") {
      var newdata = { email: userData, full_name: "", user_color: "#a6f0b9" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })

      //  console.log(emailfilter,"emailfilteremailfilter")
      setUserList([newdata, ...emailfilter]);
    } else {
      var newdata = { email: userData, full_name: "", user_color: "#fbe5d6" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })
      setUserList([newdata, ...emailfilter]);
    }
  };



  const ManualEmailHandler = (e: any) => {

    if (e.key == "Enter") {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      var EmailValidation = regex.test(UserManualEamil)
      const domain = UserManualEamil?.split("@")?.pop();
      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com"

      if (EmailValidation) {
        if (!domindata) {


          if (domain == "advancedstructures.in") {

            NormalUser(UserManualEamil, domain)

          } else {
            NormalUser(UserManualEamil, domain)
          }

        }
      }
    }

  }


  const PasteEmailHandler = (data: any) => {

    if (data) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      var EmailValidation = regex.test(data)
      const domain = data?.split("@")?.pop();
      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com"

      if (EmailValidation) {
        if (

          !domindata


        ) {


          if (domain == "advancedstructures.in") {

            NormalUser(data, domain)

          } else {
            NormalUser(data, domain)
          }

        }
      }
    }

  }





  const getModuleDetails = (name: any, index: any, module: any) => {
    let modulesNames: any = [];
    let entries = Object.entries(
      EmailDatalist?.module_link_status[module][name]
    );

    for (const [key, value] of Object.entries(entries)) {
      modulesNames.push(
        <div>
          <a href={`${value[1]}`}>{value.join(": ")}</a>
        </div>
      );
    }

    return modulesNames;
  };
  const filehandlechnage = (e: any) => {
    var compreFileSize = 7564320;

    var datalength = AttchmentDatalist?.length + e.target.files?.length;
    if (datalength <= 4) {
      var fileSizeData = Object.values(e.target.files).map(
        (item: any, index: any) => {
          return item.size;
        }
      );

      var getFileSize = AttchmentDatalist && AttchmentDatalist?.map((item: any, index: any) => {
        { return item?.file_size }
      })

      var GetTotalFileSize = getFileSize?.reduce((accumulator: any, currentValue: any) => {
        return accumulator + currentValue;
      },
        0
      );
      const TotalFileSize = fileSizeData.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        },
        0
      );
      var finalSize = GetTotalFileSize + TotalFileSize;



      if (finalSize <= compreFileSize) {



        MailAttchmentS3Upload([...e?.target?.files], projectId, setImageUploadCounter, TotalFileSize, setAttchLoader, 3)

      }


      else {

        setErrorModalFileMsg("You can select upto 7.5Mb files.")
        ErrorModalFiles.open()
      }
    }

    else {

      setErrorModalFileMsg("You can select upto 4 files.")
      ErrorModalFiles.open()
    }

  }



  const FinalEmailSendHandler = () => {


    var ToIds = value && value?.map((item: any, index: any) => { return item?.email });
    var CCuser = usersCC && usersCC?.map((item: any, index: any) => { return item?.email });

    var attachedFile = AttchmentDatalist && AttchmentDatalist?.map((item: any, index: any) => { return item?.file_path })
    setSendEmail(true)

    successModal.open()
    API.post("/analytics/dashboard_mail/",
      {
        to_mail: ToIds,
        cc_mail: CCuser,
        data: content,
        project: projectId,
        subject: SubjectData,
        dash_data: EmailDatalist,
        attachment: attachedFile,
        mail_type: 3
      },
      {},
      0
    ).then((res: any) => {
      setSelceteddate('')
      setProductvalue([])
      setSubjectData("")
      setAttchmentDatalist([])

      API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 3 }).then((res: any) => {
        setAttchmentDatalist([])
        setAttchLoader(false)
      }).catch((err: any) => {
        setAttchLoader(false)
        console.log("Server Error")
      })
      // setUpdateCounter((prev: any) => prev + 1)
      setSendEmail(false)
    }).catch((err: any) => {
      setSendEmail(true)
      // successModal.close()
    })
  }

  var ResetEmailContent = () => {
    swal({
      title: "Reset Email",
      text: "Are you sure you want to reset mail content?",
      buttons: ["No", "Ok"],
    }).then(confirm => {
      if (confirm) {
        setValue([])
        setUsersCC([])

        setSelceteddate("0")
        setProductvalue([])
        setSubjectData("")
        setAttchmentDatalist([])

        API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 3 }).then((res: any) => {
          setAttchmentDatalist([])
          setAttchLoader(false)
        }).catch((err: any) => {
          setAttchLoader(false)
          console.log("Server Error")
        })


        window.open(`/#/mail-module/Delivery/${projectname}/${projectId}/0/${false}/0/0`, "_self", " noopener")
      }
      else {

      }
    });

  }

  // console.log(productIds, "productIdsproductIds")


  const maillogdirection = () => {
    window.open(`/#/Project-mail-logs/${projectId}/${3}/${"Delivery"}`, "_blank")
  }

  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>
      {productIds != 0 ? (
        <div>
          <div>
            <div>
              <span>Dear ______,</span>
              <br />
              <span>Good afternoon! </span>
            </div>

            <p>
              We have completed the project by
              {`${EmailDatalist?.dashboard_data?.percentage}`}%
              , the deliverables summary is shown below. Also, Work Package-wise
              xcPEP links are provided for quick access to the deliverables.
              <br />
              For work package 6, we have submitted
              {`${EmailDatalist && EmailDatalist?.idea_dashboard?.total_submitted
                }`}
              ideas with estimated savings of
              {`${EmailDatalist &&
                EmailDatalist?.idea_dashboard?.total_savings.toFixed(2)
                }`}
              INR per vehicle for your perusal.
            </p>
          </div>
          <div style={{ width: "100% !important", height: "fit-content" }}>
            {EmailDatalist &&
              EmailDatalist?.idea_dashboard?.idea_data?.length > 0 && (
                <table cellSpacing={0}>
                  <tr>
                    <th rowSpan={3} colSpan={2}
                      style={{
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>
                      Ideas summry
                    </th>
                    <th colSpan={3}
                      style={{
                        // width: "50px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>

                      Total Submitted=
                      {EmailDatalist &&
                        EmailDatalist?.idea_dashboard?.total_submitted}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={3}
                      style={{
                        // width: "50px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>
                      Total Approved=
                      {EmailDatalist &&
                        EmailDatalist?.idea_dashboard?.total_approved}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={3}
                      style={{
                        // width: "50px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>
                      Total Cost Saving =
                      {EmailDatalist &&
                        EmailDatalist?.idea_dashboard?.total_savings}
                      INR
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: "50px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}
                    >

                      S.No
                    </th>
                    <th
                      style={{
                        width: "200px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}
                    >

                      Sub-System
                    </th>
                    <th
                      style={{
                        width: "120px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                        fontSize: "1rem",
                      }}
                    >

                      Submitted
                    </th>
                    <th
                      style={{
                        width: "100px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                        fontSize: "1rem",
                      }}>Approved</th>
                    <th
                      style={{
                        width: "100px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                        fontSize: "1rem",
                      }}> Rejected</th>
                  </tr>

                  <tbody>
                    {EmailDatalist &&
                      EmailDatalist?.idea_dashboard?.idea_data.map(
                        (item: any, index: any) => {
                          return (
                            <>
                              <tr>
                                <td style={{
                                  // width: "50px",
                                  backgroundColor: "#ffffff",
                                  padding: "5px 10px",
                                  border: "1px solid #000",
                                  color: "#007fff",
                                }}>{index + 1}</td>
                                <td style={{
                                  // width: "50px",
                                  backgroundColor: "#ffffff",
                                  padding: "5px 10px",
                                  border: "1px solid #000",
                                  color: "#007fff",
                                }}>{item?.name}</td>
                                <td style={{
                                  // width: "50px",
                                  backgroundColor: "#ffffff",
                                  padding: "5px 10px",
                                  border: "1px solid #000",
                                  color: "#007fff",
                                  textAlign: 'right',
                                }}>{item?.submitted}</td>
                                <td style={{
                                  // width: "50px",
                                  backgroundColor: "#ffffff",
                                  padding: "5px 10px",
                                  border: "1px solid #000",
                                  color: "#007fff",
                                  textAlign: 'right',
                                }}>{item?.approved}</td>
                                <td style={{
                                  // width: "50px",
                                  backgroundColor: "#ffffff",
                                  padding: "5px 10px",
                                  border: "1px solid #000",
                                  color: "#007fff",
                                  textAlign: 'right',
                                }}>{item?.rejected}</td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              )}
            <p>
              Some of the ideas are variations of each other, once it is
              selected/rejected by ___________ they can be merged.
            </p>
            {EmailDatalist && EmailDatalist?.idea_submitted?.length > 0 && (
              <div>
                {
                  <table cellSpacing={0}>
                    <tr>
                      <th style={{
                        width: "50px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>S.No</th>
                      <th style={{
                        width: "140px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>Idea ID</th>
                      <th style={{
                        width: "150px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>Saving value (INR)</th>
                      <th style={{
                        width: "500px",
                        backgroundColor: "#007fff",
                        padding: "5px 10px",
                        border: "1px solid #000",
                        color: "white",
                      }}>Description</th>
                    </tr>

                    <tbody>
                      {EmailDatalist &&
                        EmailDatalist?.idea_submitted.map(
                          (item: any, index: any) => {
                            return (
                              <>
                                <tr>
                                  <td style={{
                                    // width: "50px",
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    border: "1px solid #000",
                                    color: "#007fff",
                                  }}>{index + 1}</td>
                                  <td style={{
                                    // width: "50px",
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    border: "1px solid #000",
                                    color: "#007fff",
                                  }}>
                                    <a href={item?.idea_url}>

                                      {item?.idea_id}
                                    </a>
                                  </td>

                                  <td style={{
                                    // width: "50px",
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    border: "1px solid #000",
                                    color: "#007fff",
                                    textAlign: 'right',
                                  }}>{item?.saving_value}</td>
                                  <td style={{
                                    // width: "50px",
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    border: "1px solid #000",
                                    color: "#007fff",
                                  }}>{item?.sescription}</td>
                                </tr>
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                }
              </div>
            )}
            <p>
              We will discuss the deliverables and ideas in detail during our
              weekly meeting. Meanwhile, you can go through the deliverables on
              xcPEP.
            </p>



            <span>
              <b>Deliverables Summary:</b>
            </span>
            <table cellSpacing={0}>

              <tr>
                <th colSpan={2} style={{
                  // width: "150px",
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>Project Status Summary</th>
                <th colSpan={2} style={{
                  // width: "150px",
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>Completion</th>
                <th style={{
                  // width: "150px",
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>
                  {EmailDatalist &&
                    EmailDatalist?.dashboard_data?.percentage} %
                </th>
              </tr>
              <tr>
                <th style={{
                  width: "200px",
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>Activity</th>
                <th style={{
                  width: "80px",
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                  textAlign: 'right',
                }}>Total</th>
                <th style={{
                  width: "90px",
                  backgroundColor: "green",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>Completed</th>
                <th style={{
                  width: "50px",
                  backgroundColor: "yellow",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "black",
                }}>WIP</th>
                <th style={{
                  width: "110px",
                  backgroundColor: "red",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}>Not Completed</th>
              </tr>

              <tbody>
                {EmailDatalist &&
                  EmailDatalist?.dashboard_data?.data.map(
                    (item: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td style={{
                              // width: "50px",
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              border: "1px solid #000",
                              color: "#007fff",
                            }}>{item?.activity}</td>
                            <td style={{
                              // width: "50px",
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              border: "1px solid #000",
                              color: "#007fff",
                              textAlign: 'right',
                            }}>{item?.total}</td>
                            <td style={{
                              // width: "50px",
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              border: "1px solid #000",
                              color: "#007fff",
                              textAlign: 'right',
                            }}>{item?.completed}</td>
                            <td style={{
                              // width: "50px",
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              border: "1px solid #000",
                              color: "#007fff",
                              textAlign: 'right',
                            }}>{item?.in_progress}</td>
                            <td style={{
                              // width: "50px",
                              backgroundColor: "#ffffff",
                              padding: "5px 10px",
                              border: "1px solid #000",
                              color: "#007fff",
                              textAlign: 'right',
                            }}>{item?.not_started}</td>
                          </tr>
                        </>
                      );
                    }
                  )}
                <tr style={{ backgroundColor: 'yellow', }}>
                  <td style={{
                    // width: "110px",
                    // backgroundColor: "red",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    // textAlign:'right',
                    // color: "white",
                  }}>
                    Total Activity
                  </td>
                  <td style={{
                    // width: "110px",
                    // backgroundColor: "red",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    textAlign: 'right',
                    // color: "white",
                  }}>
                    {EmailDatalist &&
                      EmailDatalist?.dashboard_data?.total_sum}
                  </td>
                  <td style={{
                    // width: "110px",
                    // backgroundColor: "red",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    textAlign: 'right',
                    // color: "white",
                  }}>
                    {EmailDatalist &&
                      EmailDatalist?.dashboard_data?.completed_sum}
                  </td>
                  <td style={{
                    // width: "110px",
                    // backgroundColor: "red",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    textAlign: 'right',
                    // color: "white",
                  }}>
                    {EmailDatalist &&
                      EmailDatalist?.dashboard_data?.in_progress_sum}
                  </td>
                  <td style={{
                    // width: "110px",
                    // backgroundColor: "red",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    textAlign: 'right',
                    // color: "white",
                  }}>
                    {EmailDatalist &&
                      EmailDatalist?.dashboard_data?.not_started_sum}
                  </td>
                </tr>
              </tbody>
            </table>
            <p>This week we are delivering the following in xcPEP</p>
            <div>
              {EmailDatalist &&
                Object.keys(EmailDatalist?.module_link_status)?.map(
                  (module: any, index: any) => {
                    return (
                      EmailDatalist &&
                      module &&
                      Object.keys(EmailDatalist?.module_link_status[module])?.map(
                        (moduleName: any, i: any) => {
                          // console.log(moduleName, "fhiefhiefefuiefuefhu")

                          return (
                            <div style={{ marginTop: "1rem", }}>
                              {i === 0 && (
                                <div
                                  style={{
                                    marginTop: "3rem",
                                    textTransform: "capitalize",
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    color: '#007fff',
                                  }}
                                >
                                  {"Subsystem level "}
                                  {module}
                                </div>
                              )}
                              <div>
                                <div style={{ fontSize: "1rem", paddingLeft: "2rem" }}>
                                  <ul style={{ textTransform: "capitalize" }}>
                                    <li style={{ color: '#007fff' }}>{moduleName}</li>
                                  </ul>
                                </div>
                                <div
                                  style={{ marginLeft: "3rem", fontSize: "1rem" }}
                                >
                                  <span>

                                    {getModuleDetails(moduleName, i, module)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    );
                  }
                )}
            </div>


            <div style={{ fontSize: "1rem" }}>
              <br />
              <span>Thanks & Regards</span>
              <br />
              <span>
                {userProfilePic && `${userProfilePic[0]?.first_name} ${userProfilePic[0]?.last_name} `}
              </span>
              <br />
              <span>
                {userProfilePic && `${AUTH.email}`}
              </span>
              <br />
              <span>
                {userProfilePic && `${AUTH.phone}`}
              </span>
              <br />
              <span>
                <a href="https://advancedstructures.in/">
                  www.advancedstructures.in
                </a>
              </span>
              <br />
            </div>
          </div>
        </div>
      ) : <div> </div>
      }

    </>
  );

  useEffect(() => {
    setContent(htmlElement);
  }, [htmlElement]);



  return (
    <Box sx={{ height: '90vh' }}>
      <input
        type="file"
        id="files"
        name="files"
        style={{ display: "none" }}
        onChange={(e: any) => filehandlechnage(e)}
        ref={InputRef}
        multiple
      />
      <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />

      {PreviewModal.isOpen &&
        <PreviewMailModal
          isOpen={PreviewModal.isOpen}
          onCloseModal={PreviewModal.close}
          Tousers={value && value}
          CCusers={usersCC}
          Subject={SubjectData}
          AttchmentData={AttchmentDatalist}
          emailContent={content}
          FinalEmailSendHandler={FinalEmailSendHandler}
        //  ErrorMsg={ErrorModalFileMsg}
        />
      }
      {successModal.isOpen &&
        <MailSucessModal

          isOpen={successModal.isOpen}
          onCloseModal={successModal.close}
          SendEmail={SendEmail}
          parentsClosed={PreviewModal.close}
          Project={projectId}
          ProjectName={projectname}
        />

      }

      <div className={styles.ProductContainer}>
        <div className={styles.ProductInnerContainer}>
          <IconButton onClick={() => history.push("/internallanding")}>
            <ArrowBackOutlinedIcon sx={{ cursor: "pointer", color: 'primary.main' }} />
          </IconButton>
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '1rem', }}>
            Delivery email ({projectname})
          </Typography>
        </div>
        <div style={{ marginRight: '2.1rem', display: 'flex', columnGap: '2rem', alignItems: 'center' }}>
        <Typography variant='body2' sx={{color: "#ef5350"}}>Max 50 recipients*
 </Typography>
          <Button size="small" variant="contained"

            onClick={() => maillogdirection()}
            sx={{
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              }
            }}>Email logs</Button>
          {/* {(value?.length > 0 && content?.length > 0 && SubjectData?.length > 0) && */}
          <img src={sendIcon} title="Preview Email" style={{
            width: '1.5rem', height: 'auto',
            cursor: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? 'pointer' : 'not-allowed',
            opacity: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? '1' : '0.4',
          }}
            onClick={() => value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 && PreviewHandler()} />
        </div>
      </div>
      <div className={styles.parents_userContainer}>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Select Product :</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', width: { lg: '87.5vw', xl: "93vw" }, }}>
            <Autocomplete
              multiple
              disableClearable
              disabled={status == "false" || status == false ? false : true}
              id="multiple-limit-tags"
              limitTags={5}
              disableCloseOnSelect
              sx={{
                width: '100%',
                //  { lg: "61.4vw", xl: '66.5vw' },
                '.MuiSvgIcon-root': { color: disableProduct == "false" ? 'primary.main' : "" },
              }}
              value={Productvalue}
              onChange={(event, newValue, situation) =>
                handleSelectChangeProduct(newValue, situation)
              }
              options={ProductList && ProductList?.filter((item: any) => !Productvalue?.map((itm: any) => itm.id)?.includes(item?.id))}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    sx={{ background: '#007fff14', fontSize: '1rem' }}
                    label={option?.name}
                    {...getTagProps({ index })}
                  // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }

              renderOption={(props, option, { selected }) => (
                <li style={{ fontSize: '1rem' }} {...props}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    fontSize: '1rem',
                    '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                    '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                  }}
                />
              )}
            />
            <Box sx={{ width: { lg: '22.4vw', xl: '20.5vw' } }}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Select
                  disabled={status == "false" || status == false ? false : true}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={Selceteddate}
                  onChange={(e) => handleChangeDate(e)}
                  sx={{
                    fontSize: '1rem',
                    color: 'primary.main',
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    // "&:after": {
                    //   borderBottomColor: "white",
                    // },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: disableProduct == "false" ? 'primary.main' : "" },
                  }}
                >
                  {ProductDateList &&
                    ProductDateList?.map((item: any, index: any) => {
                      return (
                        <MenuItem sx={{ fontSize: '1rem' }} value={item?.id}>{item?.date}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            {status == "false" || status == false ?
              <ShortcutIcon
                titleAccess="Generate the email content"
                sx={{
                  transform: "rotate(90deg)",
                  fontSize: "2rem",
                  cursor: Productvalue?.length > 0 ? "pointer" : "not-allowed",
                }}
                onClick={() => Productvalue?.length > 0 && EmailContentGenerateHandler()}
              /> : <img src={resetIcon} onClick={() => ResetEmailContent()} title="Reset mail content" style={{ width: '1.5rem', cursor: 'pointer' }} />
            }
          </Box>
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>To :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            value={value}
            disabled={disableProduct == "false" ? true : false}
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: { lg: '87.5vw', xl: "93vw" }, }}

            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            onChange={(event, newValue) => {
              handleSelectChange(newValue);
            }}

            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  //  @ts-ignore
                  sx={{ color: "primary.main", background: `${option?.user_color}`, fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            onKeyDown={(e: any) => ManualEmailHandler(e)}
            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{

                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                  '.MuiSvgIcon-root': { color: 'primary.main' },
                  // @ts-ignore
                  // backgroundColor: `${params?.user_color}`,
                }}
              //   InputProps={{
              //     // background: `${params?.user_color}`
              //     // @ts-ignore
              //     style: "background-color: ${params?.user_color",

              //  }}

              />
            )}
          />
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Cc :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            limitTags={6}
            disableCloseOnSelect
            disabled={disableProduct == "false"  ? true : false}
            sx={{ width: { lg: '87.5vw', xl: "93vw" }, }}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            value={usersCC}
            onChange={(event, newValue) => {
              handleSelectChangeCC(newValue);
            }}

            onKeyDown={(e: any) => ManualEmailHandler(e)}
            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }
            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}           
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  //  @ts-ignore
                  size="small"
                  sx={{ color: "#007fff", background: `${option?.user_color}`, fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            // style={{ width: "93vw" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.userContainersubject}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Subject :</Typography>
        <TextField
          disabled={disableProduct == "false" ? true : false}
          value={SubjectData}
          onChange={(e: any) => SubjectHandler(e)}
          // defaultValue="Small"
          size="small"
          variant="standard"
          InputProps={{
            style: { fontSize: '1rem' },
          }}
          sx={{
            width: { lg: '87.5vw', xl: "93vw" },
            '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
            '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
          }}
        />
      </div>
      <div className={styles.attchmentcontainer}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right', }}>Attachments :</Typography>
        <div style={{ width: "95%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
          <Box sx={{ width: "100%", display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            {attachLoader ? <>
              <Skeleton animation="wave" sx={{ width: '98%' }} /> </> : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                {AttchmentDatalist && AttchmentDatalist?.length == 0 &&
                  <span style={{ color: "#007fff80" }}>
                    User can attach file upto 7.5mb and max 4 files .
                  </span>}
                {productIds != 0 && <>
                  {AttchmentDatalist && AttchmentDatalist.map((item: any, index: any) => {
                    return (<>
                      <Chip
                        size="small"
                        label={item?.file_name}
                        variant="outlined"

                        onClick={() => window.open(item?.file_url, "_blank")}
                        onDelete={() => handleDelete(item?.id)}
                        sx={{ borderColor: 'primary.main', fontSize: '1rem' }} />
                    </>);
                  })}
                </>}
              </Box>
              <Box>
                {productIds != 0 &&
                  <AttachFileIcon
                    titleAccess={"Attachments"}
                    onClick={() => browreFilehandler()}
                    sx={{ cursor: "pointer", color: 'primary.main', marginRight: '1.6rem' }} />
                }
              </Box>
            </Box>
            }
          </Box>
        </div>

      </div>
      <div style={{ padding: "0 1rem" }}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'flex-end',marginBottom:'0.5rem' }}>
          <img src={editmail} style={{ width: '1.4rem',height:'auto' }} />
        </Box> */}
        {ContentLoader ? <><Skeleton variant="text" height={"10rem"} sx={{ fontSize: '1rem', heigth: "10rem !important" }} />
          <Skeleton variant="rounded" height={"30rem"} />
        </>
          :
          // @ts-ignore
          <JoditEditor
            ref={editorRef}
            config={EditorConfig}
            className="DeliverymailEditor"
            // config=
            // {{

            //   readonly: false,
            //   toolbar: true,
            //   // @ts-ignore
            //   spellcheck: true,
            //   saveSelectionOnFocus: true,
            //   saveSelectionOnBlur: true,
            //   language: 'en',
            //   // toolbarButtonSize: 'medium',
            //   // toolbarAdaptive: false,
            //   showCharsCounter: true,
            //   showWordsCounter: true,
            //   showXPathInStatusbar: false,
            //   askBeforePasteHTML: true,
            //   askBeforePasteFromWord: true,

            //   //defaultActionOnPaste: "insert_clear_html",
            //   // buttons: buttons,
            //   uploader: {
            //     insertImageAsBase64URI: true
            //   },

            //   controls: {
            //     font: {
            //       command: 'fontname',
            //       list: {
            //         "'Open Sans',sans-serif": 'Open Sans',
            //         'Helvetica,sans-serif': 'Helvetica',
            //         'Arial,Helvetica,sans-serif': 'Arial',
            //         'Georgia,serif': 'Georgia',
            //         'Impact,Charcoal,sans-serif': 'Impact',
            //         'Tahoma,Geneva,sans-serif': 'Tahoma',
            //         "'Times New Roman',Times,serif": 'Times New Roman',
            //         'Verdana,Geneva,sans-serif': 'Verdana',
            //         'Consolas,monaco,monospace': 'Consolas'
            //       }
            //     }
            //   }

            // }}

            value={content}

            onBlur={(newContent) => setContent(newContent)}
            // onChange={(newContent) => setContent(newContent)}
          />

        }
      </div>
    </Box>
  );
};

export default ProjectDeliverymailLandingPage;
