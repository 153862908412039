import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export interface ICostingTreeCloseAlertModalProps {
  onCloseModal: any;
  isOpen: any;
//   cancelHandler: any;
//   parentClose: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function CostingTreeCloseAlertModal(
  props: ICostingTreeCloseAlertModalProps
) {
  const { onCloseModal, isOpen } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ width: "100%", textAlign: "center", margin: "1rem" }}>
            <Box
              sx={{
                color: "#f27474",
                borderRadius: "50%",
                height: "6rem",
                width: "6rem",
                border: "2px solid #f27474",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 1rem",
              }}
            >
              <PriorityHighIcon sx={{ color: "#f27474", fontSize: "4rem" }} />
            </Box>
            <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>             
            Please validate BOM first to redirect to the costing page.
            </Typography>
            <Box
              sx={{
                width: "100%",
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                sx={{
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
                variant="contained"
                onClick={() => onCloseModal()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
