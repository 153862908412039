import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import * as React from "react";
import Select from "@mui/material/Select";
import { IdeaTypesList } from "../../../../utlis/Idea.constant";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "../../IdeaCreateLandingpage.module.scss"
import { getImageCategoryList } from "../../../../utlis/media.constants";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import imageRepositoryService from "../../../../Redux/Services/imageRepository.service";
import { MODULES } from "../../../../Constants/modules.constants";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export interface IMediaFormProps {
  subSystem: any;
  onCloseModal: any;
  isOpen: any;
  ideaOrigin: any;
  subSystemId?: any;
  getTableData?: any;
  data?: any;
  getIdeaPromotedDetails?: any;
  getIdeaDetailsData?: any;
}

const validationSchema = yup.object({
  origin: yup.mixed(),
  // .required("Please enter your Origin"),
  idea_type: yup.mixed().required("Please enter Idea Type"),
  reference: yup.mixed(),
  competitor_model: yup.mixed(),
  competitor: yup.mixed().required("Please enter Competitor"),
  duration: yup.mixed().required("Please enter your Implemetation time"),
  ease: yup.mixed().required("Please enter your Implemetation ease"),
  owner: yup.mixed(),
  idea_level: yup.mixed().required("Please enter your Idea Level"),
  duration_level: yup.mixed(),
  category: yup.mixed().required("Please select Category"),
});

const top100Films: any = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
];

interface FilmOptionType {
  title: string;
  year: number;
}

export default function MediaEditForm(props: IMediaFormProps) {
  const history = useHistory();
  const { projectId, topVault, projectName, system, subSystem, subSystemId, vault_id } = useRouteParams<any>();
  const [owner, setOwner] = React.useState<any>();
  const [baseImagesDropDown, setBaseImagesDropDown] = React.useState<any>([]);
  const [competitorOptions, setCompetitorOptions] = React.useState<any>([]);
  const [itemCategory, setItemCategory] = React.useState<any>([]);
  const [loadingBaseImages, setLoadingBaseImages] = React.useState<boolean>(false);
  const [loadingBaseImagesCompitetor, setLoadingBaseImagesCompitetor] = React.useState<boolean>(false);
  const [baseImagesCompitetorDropDown, setBaseImagesCompitetorDropDown] = React.useState<any>([]);
  const { onCloseModal, isOpen, getIdeaPromotedDetails, getIdeaDetailsData } = props;
  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option: any) => option.title,
  };
  let category: any ;
  let competitorData: any;


  React.useEffect(() => {
    API.get("/xcpep/top_vault/", {
      project: projectId,
      module: MODULES.IDEA_GENERATION,
      subSystem,
    }).then((res) => {
      const { data } = res;
      const competitorData: any =
        data &&
        data
          .filter((obj: any) => obj.id !== topVault)
          .map((obj: any) => ({ id: obj.id, value: obj.id, text: obj.name }));
      const permissionData: any =
        data &&
        data?.filter((obj: any) => obj.id == topVault)[0]?.allowed_action;
      setCompetitorOptions([...competitorData]);
    });
  }, []);

  const getOwnerOptions = () => {
    API.get(
      "/auth/idea_user_list/",
      {
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        if (res) {
          setOwner(
            res?.data.map((item: any, index: number) => ({
              key: item.id,
              text: item.username,
              value: item.id,
            }))
          );
        }
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getOwnerOptions();
  }, []);


  const [value, setValue] = React.useState<any | null>(null);



  const ideaLevelOptions: any = [
    {
      key: 1,
      text: "Easy",
      value: 1,
    },
    {
      key: 2,
      text: "Medium",
      value: 2,
    },
    {
      key: 3,
      text: "Complex",
      value: 3,
    },
  ];
  const durationOptions = [
    {
      key: 1,
      text: "24 Hrs",
      value: 1,
    },
    {
      key: 2,
      text: "48 Hrs",
      value: 2,
    },
    {
      key: 3,
      text: "72 Hrs",
      value: 3,
    },
  ];

  const fetchBaseImageNumber = async (topvault: any, category: any, forCompitetor: boolean) => {
    
    try {
      const { data } = await imageRepositoryService.getBaseImages(topvault, category, subSystem !== "VEH" ? subSystem : 0);
      const baseImageNumbers = data.map((obj: any) => ({ id: obj.sequence, value: obj.id, text: obj.sequence }));
      if (forCompitetor) {
        setBaseImagesCompitetorDropDown([...baseImageNumbers]);
        setLoadingBaseImagesCompitetor(false);
      }
      else{
        setBaseImagesDropDown([...baseImageNumbers]);
      setLoadingBaseImages(false);
      }
    } catch (e) {
      ADMIN.toast.throwError(e, "Could fetch Base Images");
      setLoadingBaseImages(false);
    }
  };


  const formik = useFormik({
    initialValues: {
      base: "",
      origin: "",
      idea_type: null,
      feature_type: 0,
      category: null,
      reference: null,

      sub_system: 0,
      cost: 0,
      weight: 0,
      competitor_model: null,
      competitor: null,
      duration: getIdeaPromotedDetails?.duration,
      ease: getIdeaPromotedDetails?.ease,
      owner: getIdeaPromotedDetails?.owner_name,
      idea_level: "",
      duration_level: "",
      feature_param_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {

        origin: 5,
        //@ts-ignore
        idea_type: values.idea_type?.value,
        feature_type: values.feature_type,
        //@ts-ignore
        category: values.category?.value,
        //@ts-ignore
        reference: values.reference?.value,
        //@ts-ignore

        sub_system: values.sub_system,
        cost: values.cost,
        weight: values.weight,
        //@ts-ignore
        competitor_model: values.competitor_model?.value,
        //@ts-ignore
        competitor: values.competitor?.value,
        duration: values.duration,
        ease: values.ease,
        owner: values.owner,
        idea_level: values.idea_level,
        duration_level: values.duration_level,
        //@ts-ignore
      };

      let newObj = {};
      if (subSystem === "PRODUCT") {
        newObj = {
          ...obj,
          base_veh: topVault,
        }
      }
      else {
        newObj = {
          ...obj,
          base: subSystemId,
        }
        console.log(newObj, "newObj");
      }

      if(Object.keys(getIdeaPromotedDetails).length === 0) {
        API.post(`/idea/pool/${vault_id}/`, {...newObj, edit_idea_id : vault_id}, {}, 0)
        .then((res: any) => {
          ADMIN.toast.info("Idea Added Successfully");
          onCloseModal();
          getIdeaDetailsData();
          history.push(`/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/0/0/${subSystemId}/0/${res.data?.id}/0/0`);

        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              container: "swal2Container",
            },
          });
        });

      }
      
      else {
        API.put(`/idea/pool/${getIdeaPromotedDetails?.id}/`, newObj, {}, 0)
        .then((res: any) => {
          ADMIN.toast.info("Idea Added Successfully");
          onCloseModal();
          getIdeaDetailsData();

        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              container: "swal2Container",
            },
          });
        });

      }

      
      
    },
  });

  // React.useEffect(() => {
  //   setItemCategory(getImageCategoryList(false));
  // }, [])


  React.useEffect(() => {
    if(Object.keys(getIdeaPromotedDetails).length === 0) return;
    category = getImageCategoryList(false)?.filter((item: any) => item?.value == getIdeaPromotedDetails?.base?.image_category)[0];
    competitorData = { id: getIdeaPromotedDetails?.competitor?.top_vault, value: getIdeaPromotedDetails?.competitor?.top_vault, text: getIdeaPromotedDetails?.competitor?.top_vault_name };

    const ideaType = IdeaTypesList?.filter((item: any) => item?.value == getIdeaPromotedDetails?.idea_type)[0];
    const ideaLevel = ideaLevelOptions?.filter((item: any) => item?.text == getIdeaPromotedDetails?.idea_level)[0];
    const ownerName = owner?.filter((item: any) => item?.text == getIdeaPromotedDetails?.owner_name)[0];

    
    console.log(category, competitorData, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    formik.setFieldValue("idea_type", ideaType);
    formik.setFieldValue("category", category);
    formik.setFieldValue("competitor_model", competitorData);
    formik.setFieldValue("idea_level", ideaLevel?.value);
    formik.setFieldValue("owner", ownerName?.value);
    
    formik.setFieldValue("duration_level", ideaLevel?.value)


  }, []);

  React.useEffect(() => {
    const baseImage = baseImagesDropDown.filter((item: any) => item?.text == getIdeaPromotedDetails?.base?.sequence)[0];
    formik.setFieldValue("reference", baseImage);
  }, [baseImagesDropDown])

  React.useEffect(() => {
    const competitorImage = baseImagesCompitetorDropDown.filter((item: any) => item?.text == getIdeaPromotedDetails?.competitor?.sequence)[0];
    formik.setFieldValue("competitor", competitorImage);
  }, [baseImagesCompitetorDropDown])


  React.useEffect(() => {
    if(category?.value ){
      console.log("hiiiiiiiiiiiiiiiii")
      fetchBaseImageNumber(topVault, category?.value, false)
      fetchBaseImageNumber(competitorData?.value, category?.value, true)
    }
    
    
  }, [ competitorData, topVault, itemCategory])


  return (
    <div>

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <FormControl
            fullWidth
            variant="standard"
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Idea Type<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Autocomplete
              id="idea_type"
              autoSelect
              getOptionLabel={(option: any) => option.text}
              value={formik.values.idea_type}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("idea_type", newValue);
              }}
              options={IdeaTypesList}
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="idea_type"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
              {formik.touched.idea_type && Boolean(formik.errors.idea_type) && (
                //@ts-ignore
                <p>{formik.errors.idea_type}</p>
              )}
            </div>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Image Category<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Autocomplete
              id="category"
              getOptionLabel={(option: any) => option.text}
              autoSelect
              sx={{ marginTop: "1.4rem" }}
              //@ts-ignore
              options={getImageCategoryList(false)}
              value={formik.values.category}
              onChange={(event: any, newValue: any | null) => {
                console.log(newValue, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
                formik.setFieldValue("category", newValue);
                setLoadingBaseImages(true);
                //@ts-ignore
                fetchBaseImageNumber(topVault, newValue?.value, false)
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="category"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={{display:"flex", gap:"0.5rem"}}>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Base Image Number<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Autocomplete
              id="reference"
              autoSelect
              getOptionLabel={(option: any) => option.text}
              options={baseImagesDropDown}
              value={formik.values.reference}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("reference", newValue);
              }}
              sx={{ marginTop: "1.4rem" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="reference"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </FormControl>
          {loadingBaseImages && <CircularProgress size={20} style={{ marginTop: "1.4rem" }} />}
          </Box>

        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <FormControl
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Competitor Models<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Autocomplete
              id="competitor_model"
              options={competitorOptions}
              getOptionLabel={(option: any) =>  option?.text == undefined ? "" : option?.text}
              value={formik.values.competitor_model && formik.values.competitor_model}
              onChange={(event: any, newValue: any | null) => {
                formik.setFieldValue("competitor_model", newValue);
                
                setLoadingBaseImagesCompitetor(true)
                //@ts-ignore
                fetchBaseImageNumber(newValue?.value, formik?.values?.category?.value, true)
                //  fetchCompetitorVaults( newValue?.value, 2 );
              }}
              autoSelect
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  name="competitor_model"
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </FormControl>
          <Box sx={{display:"flex", gap:"0.5rem"}}>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Competitor Image Number<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Autocomplete
              id="competitor"
              autoSelect
              getOptionLabel={(option: any) => option.text}
              value={formik.values.competitor}
              options={baseImagesCompitetorDropDown}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("competitor", newValue);
              }}
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option?.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="competitor"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </FormControl>
          {loadingBaseImagesCompitetor && <CircularProgress size={20} style={{ marginTop: "1.4rem" }} />}
          </Box>
          <FormControl
            fullWidth
            variant="standard"
          >
            <TextField
              variant="standard"
              label="Implementation Time (In Days)"
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiFormLabel-asterisk": { color: "red" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="duration"
              name="duration"
              value={formik.values.duration}
              onChange={formik.handleChange}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              //@ts-ignore
              helperText={formik.touched.duration && formik.errors.duration}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard"
          >
            <TextField
              variant="standard"
              label="Implementation Ease (1-Easy to 10-difficult)"
              value={formik.values.ease}
              required
              onChange={formik.handleChange}
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiFormLabel-asterisk": { color: "red" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="ease"
              name="ease"
              error={formik.touched.ease && Boolean(formik.errors.ease)}
              //@ts-ignore
              helperText={formik.touched.ease && formik.errors.ease}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            margin: "1rem 0",
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Name
            </InputLabel>
            <Select
              id="owner"
              name="owner"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.owner}
              onChange={formik.handleChange}
            >
              {owner &&
                owner?.map((item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.owner && Boolean(formik.errors.owner) && (
                //@ts-ignore
                <p>{formik.errors.owner}</p>
              )}
            </div>
          </FormControl>
          <FormControl
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Idea Level<sup style={{ color: "red", marginTop: "0.5rem", marginLeft: "0.4rem" }}>*</sup>
            </InputLabel>
            <Select
              id="idea_level"
              name="idea_level"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.idea_level}
              onChange={(event: any) => {
                const { value } = event.target;
                formik.setFieldValue("idea_level", value);
                formik.setFieldValue("duration_level", value);
              }}
            >
              {ideaLevelOptions &&
                ideaLevelOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.idea_level &&
                Boolean(formik.errors.idea_level) && (
                  <p>{formik.errors.idea_level}</p>
                )}
            </div>
          </FormControl>
          <FormControl
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Duration
            </InputLabel>
            <Select
              id="duration_level"
              name="duration_level"
              labelId="demo-select-small"
              disabled={true}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.duration_level}
            >
              {durationOptions &&
                durationOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.duration_level &&
                Boolean(formik.errors.duration_level) && (
                  <p>{formik.errors.duration_level}</p>
                )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <Button variant="contained" size="small" onClick={onCloseModal}>
            Close
          </Button>
          <Button variant="contained" size="small" type="submit"
            disabled={formik.values.idea_type == null || formik.values.category == null || formik.values.reference == null || formik.values.competitor_model == null || formik.values.competitor == null || formik.values.duration < 0 || formik.values.ease < 1 || formik.values.ease > 10 || formik.values.idea_level == ""}
          >
            Update
          </Button>
        </Box>
      </form>
    </div>
  );
}
