import * as React from "react";
import Table from "@mui/material/Table";
import {
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { API } from "../../api-services";
import swal from "sweetalert";
import { EditEditableAtDropdown } from "./EditEditableAtDropdown";
import { EditRequiredAtDropdown } from "./EditRequiredAtDropdown";
import { EditSequence } from "./EditSequenceInput";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { InfoParameterDetails } from "./InfoParameterDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import TableChartIcon from "@mui/icons-material/TableChart";
import IdeaParameterTableModal from "./IdeaParameterTableModal";
import { InfoCostParameterDetails } from "./InfoCostParameterDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
  CellNameFour
} from "./IdeaDatatypeHeader";
import { UserContextIdea } from "./IdeaConfigurationLanding";
import { EditNameInput } from "./EditNameInput";
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Swal from "sweetalert2";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


interface IIdeaConfigurationProps {
  setTotalParamsCount: any;
  callbackFunc: any;
  count: any;
  setCount: any;
  setDataCounter: any;
  updateDatatypeList: any;
}

function IdeaConfiguration(props: IIdeaConfigurationProps) {
  const { setTotalParamsCount, callbackFunc, count, setCount, setDataCounter, updateDatatypeList } = props;
  const { projectId, datatype, productId, validationId } = useRouteParams<any>();
  // const userProfilePic = useSelector(userSelectors.selectAll);
  const [paramsData, setParamsData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const infoModal = useBaseParamsModal();
  const infoCostModal = useBaseParamsModal();
  const tableModal = useBaseParamsModal();
  const userPremissions = React.useContext(UserContextIdea)
  const userProfilePic = useSelector(userSelectors.selectAll);
  const [rowId, setRowId] = React.useState<any>(null);
  const getParamsData = () => {
    setIsLoading(true);
    if (datatype == "Cost Model") {
      // console.log(datatype, "qwertyuiuytrewqqwerty")
      API.get(
        "/config/idea_config_cost_model/",
        {
          project: projectId,
        },
        0
      )
        .then((res: any) => {
          setParamsData(res.data);
          // console.log(res.data, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    } else {
      API.get(
        "/config/parameter_mapping/",
        {
          project: projectId,
          module: 14,
          data_type: datatype
        },
        0
      )
        .then((res: any) => {
          setParamsData(res.data);
          console.log(res.data);

          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
  };
  React.useEffect(() => {

    getParamsData();
  }, [projectId, count, datatype, updateDatatypeList]);

  const deleteMappedParameter = (idd: any) => {
    swal({
      title: "Are you sure?",
      text: "If you remove the parameter so related data will get deleted & you need to revalidate BOM.",
      // icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isData: any) => {
      if (isData) {
        API.delete(`/config/parameter_mapping/${idd}`, {}, 0)
          .then((res: any) => {
            getParamsData();
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                callbackFunc();
                getParamsData();
                setDataCounter((prev: any) => prev + 1)
              })
              .catch((err: any) => { });
          })
          .catch((err: any) => {


          });
      }
    });
  };

  const deleteCostModel = (idd: any) => {
    swal({
      title: "Are you sure?",
      text: "You need to revalidate",
      // icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isData: any) => {
      if (isData) {
        API.delete(`/config/idea_config_cost_model/${idd}`, {}, 0)
          .then((res: any) => {
            getParamsData();
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                callbackFunc();
                getParamsData();
                setDataCounter((prev: any) => prev + 1)
              })
              .catch((err: any) => { });
          })
          .catch((err: any) => {


          });
      }
    });
  };

  const TableParameter = (id: any) => {
    tableModal.open(id);
  };

  const columnsFiled = CellNameOne.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Unit
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Not Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Default Value
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline Doc
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Editable at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Required at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameSecond.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Name
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline Doc
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Editable at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Required at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameThird.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Unit
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Allowed Values
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline Doc
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Editable at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Required at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameFour.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline Doc
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Editable at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Required at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  ) : (

    <TableHead
      sx={{
        backgroundColor: "primary.light",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Editable at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Required at
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const lockparameterHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        // setLoader(true);
        API.put(`/config/parameter_mapping/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
          top_vault: validationId == 0 ? productId?.toString() : validationId?.toString(),
        }).then((res: any) => {
          setDataCounter((prev: any) => prev + 1)
          // setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  };

  return (
    <div>
      {infoModal.isOpen && (
        <InfoParameterDetails
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          item={infoModal.propsId}
        />
      )}

      {
        <IdeaParameterTableModal
          isOpen={tableModal.isOpen}
          onCloseModal={tableModal.close}
          item={tableModal.propsId}
        />
      }

      {
        infoCostModal.isOpen && (
          <InfoCostParameterDetails
            isOpen={infoCostModal.isOpen}
            onCloseModal={infoCostModal.close}
            item={infoCostModal.propsId}
          />
        )
      }
      {!isLoading && paramsData ? (
        <Table>
          {columnsFiled}
          <TableBody>
            {paramsData?.map((item: any, index: any) => {
              // console.log(item?.parameter?.data_type, "qwertyuiuytrewqqwerty");
              return CellNameOne.includes(item?.parameter?.data_type) ? (
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditSequence
                      val={item.sequence}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.unit && item?.parameter?.unit?.length > 0
                      ? item?.parameter?.unit
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.description !== null ? item?.parameter?.description : "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.exceptions?.length > 0
                      ? item?.parameter?.exceptions
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.allowed_values?.length > 0
                      ? item?.parameter?.allowed_values
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.not_allowed_values?.length > 0
                      ? item?.parameter?.not_allowed_values
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.default_value?.length > 0
                      ? item?.parameter?.default_value
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.info_doc ? (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item?.parameter?.info_doc, "_blank")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.data_type == "table" && (
                      <TableChartIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => TableParameter(item?.id)}
                      />
                    )}
                    {item?.map_status == false
                      ? (
                        <DeleteIcon
                          sx={{ cursor: "pointer" , opacity: item?.lock != true ? 1 : 0.3 }}
                          onClick={() => {
                            if (item?.lock != true) {
                              deleteMappedParameter(item.id)
                            }
                          }}
                        />
                      ) : (
                        <InfoIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => infoModal.open(item?.parameter?.id)}
                        />
                      )}

                    {item?.lock === true ? (
                      // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                        <LockIcon
                          sx={{ cursor: 'pointer', color: 'green' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      ) : (
                        // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                        <LockIcon sx={{ opacity: 0.5 }} />
                      )
                    ) : (
                      // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                        <LockOpenIcon
                          sx={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ) : CellNameSecond.includes(item?.parameter?.data_type) ? (
                <TableRow key={index + 1}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditSequence
                      val={item.sequence}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.name}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.description !== null ? item?.parameter?.description : "_"}
                  </TableCell>



                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.info_doc ? (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item?.parameter?.info_doc, "_blank")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.data_type == "table" && (<>
                      {userPremissions && userPremissions?.includes("U") ?
                        <TableChartIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => TableParameter(item?.id)}
                        /> :
                        <TableChartIcon sx={{ opacity: '0.3' }} />}

                    </>)}
                    {item?.map_status == false ? (<>
                      {userPremissions && userPremissions?.includes("D") && item?.lock != true ?
                        <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteMappedParameter(item.id)} /> :
                        <DeleteIcon sx={{ opacity: 0.3 }} />}
                    </>) : (
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => infoModal.open(item?.parameter?.id)}
                      />
                    )}
                    {item?.lock === true ? (
                      // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                        <LockIcon
                          sx={{ cursor: 'pointer', color: 'green' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      ) : (
                        // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                        <LockIcon sx={{ opacity: 0.5 }} />
                      )
                    ) : (
                      // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                        <LockOpenIcon
                          sx={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ) : CellNameThird.includes(item?.parameter?.data_type) ? (
                <TableRow key={index + 1}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditSequence
                      val={item.sequence}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.unit && item?.parameter?.unit?.length > 0
                      ? item?.parameter?.unit
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.description !== null ? item?.parameter?.description : "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.exceptions?.length > 0
                      ? item?.parameter?.exceptions
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.allowed_values?.length > 0
                      ? item?.parameter?.allowed_values
                      : "-"}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.default_value?.length > 0
                      ? item?.parameter?.default_value
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.info_doc ? (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item?.parameter?.info_doc, "_blank")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.data_type == "table" && (<>
                      {userPremissions && userPremissions?.includes("U") ?
                        <TableChartIcon sx={{ cursor: "pointer" }} onClick={() => TableParameter(item?.id)} /> :
                        <TableChartIcon sx={{ opacity: 0.3 }} />}
                    </>)}
                    {item?.map_status == false ? (<>
                      {userPremissions && userPremissions?.includes("D") && item?.lock != true ?
                        <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteMappedParameter(item.id)} /> :
                        <DeleteIcon sx={{ opacity: 0.3 }} />}
                    </>) : (
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => infoModal.open(item?.parameter?.id)}
                      />
                    )}
                    {item?.lock === true ? (
                      // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                        <LockIcon
                          sx={{ cursor: 'pointer', color: 'green' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      ) : (
                        // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                        <LockIcon sx={{ opacity: 0.5 }} />
                      )
                    ) : (
                      // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                        <LockOpenIcon
                          sx={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ) : CellNameFour.includes(item?.parameter?.data_type) ? (
                <TableRow key={index + 1}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditSequence
                      val={item.sequence}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.description !== null ? item?.parameter?.description : "_"}
                  </TableCell>


                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.info_doc ? (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(item?.parameter?.info_doc, "_blank")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.parameter?.data_type == "table" && (<>
                      {userPremissions && userPremissions?.includes("U") ?
                        <TableChartIcon sx={{ cursor: "pointer" }} onClick={() => TableParameter(item?.id)} /> :
                        <TableChartIcon sx={{ opacity: 0.3 }} />}
                    </>)}
                    {item?.map_status == false ? (<>
                      {userPremissions && userPremissions?.includes("D") && item?.lock != true?
                        <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteMappedParameter(item.id)} /> :
                        <DeleteIcon sx={{ opacity: '0.3' }} />}
                    </>) : (
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => infoModal.open(item?.parameter?.id)}
                      />
                    )}
                    {item?.lock === true ? (
                      // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                        <LockIcon
                          sx={{ cursor: 'pointer', color: 'green' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      ) : (
                        // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                        <LockIcon sx={{ opacity: 0.5 }} />
                      )
                    ) : (
                      // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                        <LockOpenIcon
                          sx={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index + 1}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditSequence
                      val={item.sequence}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditNameInput
                      val={item?.name}
                      paramId={item.id}
                      getParamsData={getParamsData}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                      setCount={setCount}
                      type={item?.parameter?.data_type || "Cost Model"}
                      lockstatus={item?.lock}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {item?.map_status == false ? (<>
                      {userPremissions && userPremissions?.includes("D") && item?.lock != true ?
                        <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteCostModel(item.id)} /> :
                        <DeleteIcon sx={{ opacity: 0.3 }} />}
                    </>) : (
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => infoCostModal.open(item?.id)}
                      />
                    )}
                    {item?.lock === true ? (
                      // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                        <LockIcon
                          sx={{ cursor: 'pointer', color: 'green' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      ) : (
                        // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                        <LockIcon sx={{ opacity: 0.5 }} />
                      )
                    ) : (
                      // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                      (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                        <LockOpenIcon
                          sx={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => lockparameterHandler(item)}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <>
          <Table>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}>
                <Skeleton animation="wave"></Skeleton>{" "}
              </TableCell>
            </TableRow>
          </Table>
        </>
      )}
    </div>
  );
}

export default IdeaConfiguration;
