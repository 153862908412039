import * as React from "react";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SubRevisionEditModal from "./SubRevisionEditModal";
import { useHistory } from "react-router-dom";
import FetchRawMaterialModals from "./FetchRawMaterialModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import InfoRawMaterialModal from "./InfoRawMaterialModal";
import RawMaterialGraph from "./RawMaterialGraph";
import RawMaterialMoveModal from "./RawMaterialMoveModal";
import LockIcon from "@mui/icons-material/Lock";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockStatusInfoModal from "./LockStatusInfoModal";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CreateRevisionDatabaseModal from "./CreateRevisionDatabaseDataModal";
import Popover from "@mui/material/Popover";
import RawMaterialActionComponents from "./ActionComponents/RawMaterialActionComponents";
import calcImageGreen from "../../Assets/images/calculator_green.svg";

export interface IRawMaterialRevisionTableProps {
  setCounter: any;
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Raw Material CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      width: { lg: "100%", xl: "100%" },
      height: "2.3rem",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "-2.6rem",
    }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function RawMaterialRevisionTable(
  props: IRawMaterialRevisionTableProps
) {
  const {
    tab,
    year,
    month,
    revisionId,
    typeId,
    databaseName,
    categoryName,
    status,
    search,
    lockCounter,
  } = useRouteParams<any>();
  const { setCounter } = props;
  const subRevisionEdit = useBaseParamsModal();
  const subRevisionEditFetch = useBaseParamsModal();
  const moveModal = useBaseParamsModal();
  const graphModal = useBaseParamsModal();
  const fetchRawMaterial = useBaseModal();
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const [expanded, setExpanded] = React.useState<string | false>(
    categoryName && categoryName
  );
  const [modalData, setModalData] = React.useState<any>(null);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [count, setCount] = React.useState<number>(0);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [accordionLoading, setAccordionLoading] = React.useState<any>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const infoRawMaterialModal = useBaseParamsModal();
  const [tabValue, setTabValue] = React.useState<any>();
  const [useraction, setuseraction] = React.useState<any>(null);
  const lockmodal = useBaseModal();
  const [LockData, SetLockData] = React.useState<any>(null);
  const userProfile = useSelector(userSelectors.selectAll);
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const [rowId, setRowId] = React.useState<any>(null);
  // Menu Items
  // const [anchorElRawRevison, setAnchorElRawRevison] = React.useState<null | HTMLElement>(null);
  // const openRawRevison = Boolean(anchorElRawRevison);
  // const handleClickRawRevison = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElRawRevison(event.currentTarget);
  // };
  // const handleCloseRawRevison = () => {
  //   setAnchorElRawRevison(null);
  // };

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);

    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedTab, setSelectedTab] = React.useState("");
  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getCategoryList = () => {
    setAccordionLoading(true);
    API.get(
      "/api/db/revision_database/",
      {
        category_type: "materialcost",
        revision_id: revisionId,
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setAccordionLoading(false);
        if (categoryName == 0) {
          setSelectedTab(res?.data[0]?.category);
          history.push(
            `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${res?.data[0]?.category}/${status}/${search}/${lockCounter}`
          );
          getMaterialData(res?.data[0]?.category);
        }
      })
      .catch((err: any) => {
        setAccordionLoading(false);
      });
  };

  // For Initial tab select on load
  // React.useEffect(() => {
  // 	if (categoryList && categoryList.length > 0) {
  // 	  // Set the selected tab to the first item in categoryList when the component loads
  // 	  setSelectedTab(categoryList[0].category);
  // 	  history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryList[0].category}/${status}/${search}`);
  // 	//   getMaterialData(categoryList[0].category);
  // 	}
  //   }, [categoryList]);

  // React.useEffect(() => {
  // 	getCategoryList();
  // }, [revisionId]);

  React.useEffect(() => {
    // if (categoryName !== '0') {
    setSelectedTab(categoryName);
    getCategoryList();
    // }
  }, [revisionId, categoryName]);

  const editSubRevision = (item: any) => {
    setModalData(item);
    subRevisionEdit.open(item);
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId,
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action);
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, [revisionId]);

  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    API.get(
      "/api/db/material_cost/",
      {
        revision: revisionId,
        category: category,
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };
  const LockHandler = (item: any, type: any) => {
    if (type == false) {
      if (
        (userProfile && userProfile[0]?.is_superuser) ||
        userProfile[0]?.is_project_admin ||
        userProfile[0]?.id == item?.locked_user_id
      ) {
        API.put(`/api/db/material_cost/${item?.id}/`, {
          lock: type,
          revision: revisionId,
          category: categoryName,
        })
          .then((res: any) => {
            getMaterialData(categoryName);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            console.log("Server error");
          });
      } else {
        SetLockData(item);
        lockmodal.open();
      }
    }
    if (type == true) {
      API.put(`/api/db/material_cost/${item?.id}/`, {
        lock: type,
        revision: revisionId,
        category: categoryName,
      })
        .then((res: any) => {
          getMaterialData(categoryName);
          setCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    }
  };

  const columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 130 : width < 1200 ? 150 : 120,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box>
                {cellValues?.row?.lock_status ? (
                  <IconButton
                    title="lock"
                    sx={{ padding: "0", cursor: "pointer", color: "green" }}
                    onClick={() => LockHandler(cellValues?.row, false)}
                  >
                    <LockIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    title="lock"
                    sx={{ padding: "0", cursor: "pointer", color: "red" }}
                    onClick={() => LockHandler(cellValues?.row, true)}
                  >
                    <LockOpenIcon />
                  </IconButton>
                )}
              </Box>
              <Box>
                {useraction &&
                  useraction?.includes("U") &&
                  cellValues?.row?.lock_status == false && (
                    <IconButton
                      title="Edit"
                      sx={{
                        padding: "0",
                        cursor: "pointer",
                        color: "primary.main",
                      }}
                      onClick={() => editSubRevision(cellValues.row)}
                    >
                      <EditIcon sx={{ cursor: "pointer" }} />
                    </IconButton>
                  )}
              </Box>
              <Tooltip title="More Items" placement="right-start">
                <IconButton
                  aria-describedby={id}
                  // onClick={handleClick(cellValues?.row?.id)}
                  onClick={(event) => handleClick(event, cellValues.row)}
                  size="small"
                  sx={{
                    padding: "2px",
                    color: rowId?.material_file_flag ? "green" : "primary.main",
                  }}
                >
                  <MenuIcon
                    sx={{
                      color: cellValues?.row?.material_file_flag
                        ? "green"
                        : "primary.main",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                elevation={1}
              >
                <RawMaterialActionComponents
                  typeId={typeId}
                  revisionId={revisionId}
                  cellValues={cellValues.row}
                  rowId={rowId}
                  history={history}
                  tab={tab}
                  year={year}
                  month={month}
                  categoryName={categoryName}
                  status={status}
                  search={search}
                  useraction={useraction}
                  getMaterialData={getMaterialData}
                  infoRawMaterialModal={infoRawMaterialModal}
                  graphModal={graphModal}
                  moveModal={moveModal}
                  setAnchorEl={setAnchorEl}
                />
              </Popover>
            </Box>
          </>
        );
      },
    },
    {
      field: "sno",
      headerName: "S.No",
      width: width > 1370 ? 50 : width < 900 ? 50 : 50,
    },
    {
      field: "material_code",
      headerName: "Material Code",
      minWidth: width > 1370 ? 140 : width < 900 ? 130 : 130,
      flex: 1,
    },
    {
      field: "material_grade",
      headerName: "Grade",
      minWidth: width > 1370 ? 100 : 80,
    },
    {
      field: "material_subgrade",
      headerName: "Sub Grade",
      type: "number",
      minWidth: width > 1370 ? 120 : 80,
    },
    {
      field: "shape",
      headerName: "Initial Shape",
      sortable: false,
      width: width > 1370 ? 100 : 80,
    },
    {
      field: "density",
      headerName: "Density kg/m3",
      title: "Density (kg/m3)",
      align: "right",
      sortable: false,
      width: width > 1370 ? 70 : 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Density (kg/m3): ${cellValues?.row?.density}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${cellValues?.row?.density?.toFixed(3)}`}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Remarks",
      sortable: false,
      minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 160,
    },
    {
      field: "material_cost",
      headerName: "Mat. Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 120 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Material cost: ${cellValues?.row?.material_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {!cellValues.row?.material_cost_is_user_input && (
              <img
                src={calcImageGreen}
                style={{
                  width: "1rem",
                  height: "1rem",
                }}
              />
            )}
            {cellValues.row?.material_cost_is_user_input
              ? `${cellValues?.row?.material_cost?.toFixed(3)}`
              : `${cellValues?.row?.material_cost_std?.toFixed(3)}`}
          </Box>
        );
      },
    },
    {
      field: "currency_code",
      headerName: "Currency/Unit",
      sortable: false,
      minWidth: width > 1370 ? 100 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${
              cellValues?.row?.currency_code
                ? cellValues?.row?.currency_code
                : "--"
            }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    {
      field: "material_source",
      headerName: "Material Source",
      sortable: false,
      minWidth: width > 1370 ? 110 : width < 1200 ? 120 : 140,
    },

    // {
    // 	field: 'unit',
    // 	headerName: 'Unit',
    // 	sortable: false,
    // 	minWidth: width > 1370 ? 100 : width < 1200 ? 100 : 100,
    // 	renderCell: (cellValues: any) => {

    // 		return (
    // 			<Box sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',columnGap:'1rem',}}>
    // 			{cellValues?.row?.unit}
    // 			 </Box>
    // 		);
    // 	},
    // },

    {
      field: "material_reference",
      headerName: "M.Ref",
      sortable: false,
      minWidth: width > 1370 ? 50 : 70,
      renderCell: (cellValues: any) => {
        return (
          <>
            {isUrl(cellValues?.row?.material_reference) ? (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() =>
                  handleOpenLinkReference(cellValues?.row?.material_reference)
                }
              >
                Link
              </span>
            ) : (
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                {cellValues?.row?.material_reference}
              </span>
            )}
          </>
        );
      },
    },

    {
      field: "scrap_cost",
      headerName: "Scrap Cost",
      cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      renderCell: (cellValues: any) => {
        return (
          <Box
            title={`Scrap Cost: ${cellValues?.row?.scrap_cost}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${cellValues?.row?.scrap_cost?.toFixed(3)}`}
          </Box>
        );
      },
    },
    {
      field: "scrap_source",
      headerName: "Scrap Source",
      sortable: false,
      minWidth: width > 1370 ? 90 : 120,
    },
    {
      field: "scrap_reference",
      headerName: "S.Ref",
      sortable: false,
      width: width > 1370 ? 50 : 70,
      renderCell: (cellValues: any) => {
        return (
          <>
            {isUrl(cellValues?.row?.scrap_reference) ? (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() =>
                  handleOpenLinkReference(cellValues?.row?.scrap_reference)
                }
              >
                Link
              </span>
            ) : (
              <span
              // style={{ textDecoration: "underline", cursor: "pointer" }}
              // onClick={() => handleOpenLinkReference(cellValues?.row?.scrap_reference)}
              >
                {cellValues?.row?.scrap_reference}
              </span>
            )}
          </>
        );
      },
    },
  ];

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     getMaterialData(panel);
  //     // typeId, databaseName,categoryName
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`);
  //   }
  // };
  const handleChange = (categoryName: any) => {
    setSelectedTab(categoryName);
    history.push(
      `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}/0/${lockCounter}`
    );
    getMaterialData(categoryName);
    // setTabValue(categoryName);
  };

  React.useEffect(() => {
    if (categoryName) {
      getMaterialData(categoryName);
    }
  }, [categoryName, lockCounter]);

  let rows: any = [];
  let dataGridDataGenerator: any =
    tableData &&
    tableData?.map((item: any, index: any) => {
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        material_code: item?.material_code,
        material_grade: item?.material_grade,
        material_subgrade: item?.material_subgrade,
        shape: item?.shape,
        density: item?.density,
        description: item?.description,
        material_cost: item?.material_cost,
        material_source: item?.material_source,
        material_reference: item?.material_reference,
        scrap_cost: item?.scrap_cost,
        scrap_source: item?.scrap_source,
        scrap_reference: item?.scrap_reference,
        mapped_status: item?.mapped_status,
        material_id: item?.material_id,
        category: item?.category,
        revision_id: item?.revision_id,
        material_file_flag: item?.material_file_flag,
        material_file: item?.material_file,
        lock_status: item?.lock_status,
        locked_on: item?.locked_on,
        locked_user_email: item?.locked_user_email,
        currency_id: item?.currency_id,
        currency_code: item?.currency_code,
        unit: item?.unit,
        locked_user_id: item?.locked_user_id,
        locked_user_name: item?.locked_user_name,
        locked_user_phone: item?.locked_user_phone,
        material_calc: item?.material_calc,
        material_cost_is_user_input: item?.material_cost_is_user_input,
        result: item?.material_result,
        material_cost_std: item?.material_cost_std,
        idd: item?.idd,
      };
      rows.push(objShared);
    });

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows && rows,
  };

  console.log(modalData);

  React.useEffect(()=> {
if (tableData?.length > 0 && (modalData !== null && modalData !== undefined)) {
   const updatedData = tableData?.find((row:any) => row?.id === modalData?.id);

   setModalData(updatedData)
}
  },[tableData])
  

  return (
    <Box sx={{ width: "100%" }}>
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          {/* <Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}} adl-scrollbar='true'
						adl-scrollbar-width='0.2'>
						{categoryList &&
							categoryList?.map((item: any, index: any) => {
								return (
									<>
										<Box
											key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
													item?.category == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
														item?.category == selectedTab ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													whiteSpace: 'nowrap',
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => handleChange(item?.category)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>
									</>
								);
							})}
					</Box> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0.3rem",
              columnGap: "0.5rem",
            }}
          >
            {useraction && useraction?.includes("C") && (
              <Box
                sx={{
                  width: "12rem",
                  // textAlign: 'right',
                }}
              >
                <Button
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  sx={{
                    whiteSpace: "nowrap",
                    zIndex: 1,
                    padding: "0.2rem 1rem",
                    "&:hover": {
                      backgroundColor: "#007fff",
                      color: "#fff",
                    },
                  }}
                  onClick={() => fetchRawMaterial.open()}
                >
                  Fetch Material
                </Button>
              </Box>
            )}
            {useraction && useraction?.includes("C") && (
              <Box
                sx={{
                  width: "12rem",
                  // textAlign: 'right',
                }}
              >
                <Button
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  sx={{
                    whiteSpace: "nowrap",
                    zIndex: 1,
                    padding: "0.2rem 1rem",
                    "&:hover": {
                      backgroundColor: "#007fff",
                      color: "#fff",
                    },
                  }}
                  onClick={() => subRevisionEditFetch.open("add")}
                >
                  Add & Fetch Material
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              height: { lg: "83vh", xl: "85vh" },
              width: "100%",
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
            }}
          >
            <DataGrid
              headerHeight={42}
              rowHeight={36}
              {...dataStructureDataGrid}
              loading={tableDataLoading}
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          </Box>
        </>
      )}

      {subRevisionEdit.isOpen && (
        <SubRevisionEditModal
          isOpen={subRevisionEdit.isOpen}
          onCloseModal={()=>{
            setModalData(null);
            subRevisionEdit.close()
          }   }
          actions={modalData}
          expanded={expanded}
          getMaterialData={getMaterialData}
         
        />
      )}
      {fetchRawMaterial.isOpen && (
        <FetchRawMaterialModals
          isOpen={fetchRawMaterial.isOpen}
          onCloseModal={fetchRawMaterial.close}
          categorydata={categoryList && categoryList}
          getMaterialData={getMaterialData}
          getCategoryList={getCategoryList}
        />
      )}
      {infoRawMaterialModal.isOpen && (
        <InfoRawMaterialModal
          isOpen={infoRawMaterialModal.isOpen}
          onCloseModal={infoRawMaterialModal.close}
          itemId={infoRawMaterialModal.propsId}
          useraction={useraction}
          getMaterialData={getMaterialData}
          setAnchorEl={setAnchorEl}
          categoryName={categoryName}
        />
      )}
      {graphModal.isOpen && (
        <RawMaterialGraph
          isOpen={graphModal.isOpen}
          onCloseModal={graphModal.close}
          rowItems={graphModal.propsId}
        />
      )}
      {moveModal.isOpen && (
        <RawMaterialMoveModal
          isOpen={moveModal.isOpen}
          onCloseModal={moveModal.close}
          rowItems={moveModal.propsId}
        />
      )}
      {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}
      {subRevisionEditFetch.isOpen && (
        <CreateRevisionDatabaseModal
          isOpen={subRevisionEditFetch.isOpen}
          onCloseModal={subRevisionEditFetch.close}
          actions={subRevisionEditFetch.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryList={getCategoryList}
        />
      )}
    </Box>
  );
}
