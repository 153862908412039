

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styles from "./FeatureAllvideo.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from '@mui/material';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: "auto",
  maxHeight: "90%",
  minWidth: '30%',
  minHeight: "30%",
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: "10px",
  boxShadow: 24,
};


interface FeatureAllvideoProps {
  isOpen: any;
  onCloseModal: any;
  FeatureParameterData: any;
  indexId: any;
}

export default function FeatureAllvideo(props: FeatureAllvideoProps) {
  const { isOpen, onCloseModal, FeatureParameterData,indexId } = props;

  const VideoContainer = () => {
    var datavlue =
      FeatureParameterData &&
      FeatureParameterData["Video"] &&
      Object.entries(FeatureParameterData["Video"][indexId]);

    var imageData =
      datavlue &&
      datavlue?.map(([key, value]: any) => {
        // { console.log(value["video"]), "hfbbbbuubb" }




        return (
          <>
            {value["video"] && value["video"]?.includes("Not") == false &&


              <Box>
                <Typography sx={{ fontSize: '1rem' }}>{key}</Typography>
                <video
                  style={{
                    height: "20rem",
                    width: "auto",
                  }}
                  // ref={ this.VideoRef }
                  controls
                  src={value["video"]}
                  // autoPlay
                // onLoadedMetadata={ this.loadedmetadataListner }
                >
                  <source type="video/mp4"></source>
                </video>
              </Box>
            }

          </>
        );

      });
    //  console.log(imageData,"imageDataimageDataimageData")


    return imageData && imageData;
  };


  const VideoContainerLength = () => {
    var datavlue =
      FeatureParameterData &&
      FeatureParameterData["Video"] &&
      Object.entries(FeatureParameterData["Video"][indexId]);
    var imageDatalength =
      datavlue &&
      datavlue?.filter(([key, value]: any) => { return value["video"] !== "Not Available" })



    // console.log(imageDatalength, "imageDataimageDataimageData")


    return imageDatalength && imageDatalength;
  };

  // console.log(VideoContainerLength() && VideoContainerLength()?.length - 1, "VideoContainer")

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Feature Videos
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box className={styles.featureVideoContainer} data-columns={VideoContainerLength() && VideoContainerLength()?.length - 1}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {VideoContainer() && VideoContainer()}
          </Box>


          {VideoContainerLength() && VideoContainerLength()?.length - 1 == 0 && <Typography sx={{textAlign:"center",width:'100%',alignItems:"center",}}>

            No Video
          </Typography>}
        </Box>
      </Modal>
    </div>





  )
}