import { Button, Skeleton, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import InfoIcon from '@mui/icons-material/Info';
import EjectIcon from '@mui/icons-material/Eject';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 100,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  abbr: any;
  partId: any;
  depth: any;
}
interface ICostingCreateLPPTableRowProps {
  itemIncoming: any;
  handleOpenHierarchy: any;
  sessionGetInto: any;
  setOpenDelta: any;
  handleOpenDelta: any;
  handleOpenGraph: any;
  handleOpenInfo: any;
  handleOpenInference: any;
  hoveredIndex: any;
  setHoveredIndex: any;
  filterIsWorking: any;
  loaderRecursiveId:any;
}

const CostingCreateLPPTableRow: React.FC<ICostingCreateLPPTableRowProps> = (props) => {
  const { itemIncoming, handleOpenHierarchy, sessionGetInto, setOpenDelta, handleOpenDelta, handleOpenGraph, handleOpenInfo, handleOpenInference, hoveredIndex, setHoveredIndex, filterIsWorking,loaderRecursiveId } = props;
  const { projectId, projectName, topVaultIds, expandTable, initialTopVault, abbr, partId, depth } = useRouteParams<Params>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openRedirect = Boolean(anchorEl);
  const [ancestorGetInfo, setAncestorGetInfo] = useState<any>();
  const [partNameOnClickRedirect,setPartNameOnClickRedirect] = useState<any>();
    const [topVaultIdOnClickRedirect,setTopVaultIdOnClickRedirect] = useState<any>();

  const history = useHistory();

  const handleClickRedirect = (event: React.MouseEvent<HTMLElement>, partName: any, topVaultIdIncoming: any, depthIncoming: any, pathIncoming:any, topVaultNameIncoming:any) => {
    setPartNameOnClickRedirect(topVaultNameIncoming);
    setTopVaultIdOnClickRedirect(topVaultIdIncoming);
    setAnchorEl(event.currentTarget);
    API.get(`cost/costing_analysis/`, { depth: depthIncoming, top_vault: topVaultIdIncoming, get_ancestors: partName, path:pathIncoming }, 0).then((res: AxiosResponse) => {
      setAncestorGetInfo(res.data?.[0]);
    }).catch((err: AxiosError) => {
      console.log(err, "error")
    }
    );
  };

  const handleCloseRedirect = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (type: any, topVaultIdIncoming: any, topVaultNameIncoming: any) => {
    if (type == 'Costing') {
      sessionStorage.setItem("CostingCreateCalc", JSON.stringify(ancestorGetInfo?.ancestor));
      window.open(`/#/createcostingpart/${projectId}/${projectName}/${topVaultNameIncoming}/${topVaultIdIncoming}/${ancestorGetInfo?.current_vault}/${itemIncoming?.abbreviation == null ? 'none' : itemIncoming?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
    }
    else {
      sessionStorage.setItem("Comparenode", JSON.stringify(ancestorGetInfo?.other_ancestor));
      window.open(`/#/newcompare/${projectId}/2/${topVaultIds}/${itemIncoming?.abbreviation}/${itemIncoming?.s_name}/${(itemIncoming?.name).toLowerCase()}/1/vehicle/${itemIncoming?.is_leaf}/${itemIncoming?.depth}/${itemIncoming?.path}`);
    }
  }

  return (
    <>
  
      
      <TableRow sx={hoveredIndex == itemIncoming?.id ? { backgroundColor: '#f7e1f4', zIndex: 1 } : {}} onMouseEnter={() => setHoveredIndex(itemIncoming?.id)} onMouseLeave={() => setHoveredIndex(null)}>
            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textTransform: 'capitalize', borderRight: '0.1rem solid #ddefff', whiteSpace: 'nowrap' }}>
              <span style={{ marginLeft: `${filterIsWorking ? "1rem" : (itemIncoming?.depth - 1)}rem`, display: 'flex', alignItems: 'center' }}>
                
                {!itemIncoming?.is_leaf && (!sessionGetInto?.includes(itemIncoming?.id) ? 
                <AddCircleOutlineIcon titleAccess='Expand' sx={{ marginRight: '0.5rem', cursor: 'pointer' }} onClick={() => handleOpenHierarchy(itemIncoming)} /> 
                : <RemoveCircleOutlineIcon titleAccess='Collapse' sx={{ marginRight: '0.5rem', cursor: 'pointer' }} onClick={() => handleOpenHierarchy(itemIncoming)} />)}
                <span style={!itemIncoming?.is_leaf ? { color: `${itemIncoming?.main_color == '#c4efb3' ? 'green' : itemIncoming?.main_color == '#fe5c5c99' ? 'red' : '#007fff'}` } : { color: `${itemIncoming?.main_color == '#c4efb3' ? 'green' : itemIncoming?.main_color == '#fe5c5c99' ? 'red' : '#007fff'}`, marginLeft: '2.3rem' }}>{itemIncoming?.parts_name}</span>
              </span>
            </TableCell>
            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', whiteSpace: 'nowrap' }}>
              <EqualizerIcon titleAccess='All Vehicle Analysis ' sx={{ cursor: 'pointer' }} onClick={() => handleOpenGraph(itemIncoming?.name, itemIncoming?.depth)} />
              {itemIncoming?.costing_data?.inference_exists ? <DescriptionIcon titleAccess='Inference' sx={{ cursor: 'pointer', color: 'green' }} onClick={() => handleOpenInference(itemIncoming?.name, itemIncoming?.depth)} /> :
                <DescriptionOutlinedIcon titleAccess='Inference' sx={{ cursor: 'pointer' }} onClick={() => handleOpenInference(itemIncoming?.name, itemIncoming?.depth)} />}
            </TableCell>
            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', borderRight: '0.1rem solid #ddefff', textAlign: 'right', whiteSpace: 'nowrap' }}>
              {itemIncoming?.costing_data?.average == '-' ? itemIncoming?.costing_data?.average : itemIncoming?.costing_data?.average == 0 ? 0 : itemIncoming?.costing_data?.average.toFixed(2)}
            </TableCell>
            {itemIncoming?.costing_data?.costing_tp_data?.map((itemInside: any) => {
              return (
                <>
                  {itemInside?.cost_per_kg != '-' && <TableCell sx={hoveredIndex == itemIncoming?.id ? { backgroundColor: '#f7e1f4', padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', whiteSpace: 'nowrap' } : { padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', backgroundColor: itemInside?.color, whiteSpace: 'nowrap' }}>
                    {itemInside?.cost == '-' ? itemInside?.cost : itemInside?.cost == 0 ? 0 : itemInside?.cost.toFixed(2)}
                  </TableCell>}
                  {itemInside?.cost_per_kg != '-' && <TableCell sx={hoveredIndex == itemIncoming?.id ? { backgroundColor: '#f7e1f4', padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', whiteSpace: 'nowrap' } : { padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', backgroundColor: itemInside?.color, whiteSpace: 'nowrap' }}>
                    {itemInside?.weight == '-' ? itemInside?.weight : itemInside?.weight == 0 ? 0 : itemInside?.weight.toFixed(2)}
                  </TableCell>}
                  {itemInside?.cost_per_kg != '-' && <TableCell sx={hoveredIndex == itemIncoming?.id ? { backgroundColor: '#f7e1f4', padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', whiteSpace: 'nowrap' } : { padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'right', backgroundColor: itemInside?.color, whiteSpace: 'nowrap' }}>
                    {itemInside?.cost_per_kg == '-' ? itemInside?.cost_per_kg : itemInside?.cost_per_kg == 0 ? 0 : itemInside?.cost_per_kg.toFixed(2)}
                  </TableCell>}
                  <TableCell colSpan={itemInside?.cost_per_kg != '-' ? 1 : 4} sx={hoveredIndex == itemIncoming?.id ? { backgroundColor: '#f7e1f4', padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', borderRight: '0.1rem solid #ddefff', textAlign: 'center', whiteSpace: 'nowrap' } : { padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', borderRight: '0.1rem solid #ddefff', backgroundColor: itemInside?.color, textAlign: 'center', whiteSpace: 'nowrap' }}>
                    {itemInside?.cost_per_kg == '-' ? 'Not Available' : <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <InfoIcon titleAccess='Costing Info' sx={{ cursor: 'pointer' }} onClick={() => handleOpenInfo(itemIncoming?.name, itemIncoming?.depth, itemInside?.tp_id)} />
                      <EjectIcon titleAccess='Cost/Kg Delta' sx={{ cursor: 'pointer' }} onClick={() => handleOpenDelta(itemIncoming?.name, itemIncoming?.depth, itemInside?.tp_id)} />
                      <Button
                        id="demo-customized-button"
                        aria-controls={openRedirect ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openRedirect ? 'true' : undefined}
                        disableElevation
                        onClick={(e: any) => handleClickRedirect(e, itemIncoming?.parts_name, itemInside?.tp_id, itemIncoming?.depth, itemIncoming?.path, itemInside?.tp_name)}
                        style={{ padding: '0rem', minWidth: '1rem' }}
                      >
                        <ShortcutIcon titleAccess='Go to Options' sx={{ cursor: 'pointer' }} />
                      </Button>
                    </span>}
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={openRedirect}
                      onClose={handleCloseRedirect}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "0.9rem",
                          color: "primary.main",
                          padding: "0.2rem",
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleRedirect('Costing', topVaultIdOnClickRedirect, partNameOnClickRedirect)}
                      >
                        Go to Costing
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "0.9rem",
                          color: "primary.main",
                          padding: "0.2rem",
                          fontWeight: 500,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleRedirect('Compare', topVaultIdOnClickRedirect, partNameOnClickRedirect)}
                      >
                        Go to Compare
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "0.9rem",
                          color: "primary.main",
                          padding: "0.2rem",
                          fontWeight: 500,
                        }}
                      >
                        Go to BOM
                      </MenuItem>
                    </StyledMenu>
                  </TableCell>
                </>
              );
            })}
          </TableRow><></>
{
   loaderRecursiveId == itemIncoming?.id  ?(
		<>
        <TableRow
        >
          <TableCell
            // className={styles?.CheckboxTable}
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
		<TableRow
        >
          <TableCell
            // className={styles?.CheckboxTable}
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
		<TableRow
        >
          <TableCell
            // className={styles?.CheckboxTable}
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
		</>
      ):<>


    
              {itemIncoming?.data?.map((item: any) => {
                return (
                  sessionGetInto?.includes(itemIncoming?.id) && <CostingCreateLPPTableRow itemIncoming={item} handleOpenHierarchy={handleOpenHierarchy} sessionGetInto={sessionGetInto} setOpenDelta={setOpenDelta} handleOpenDelta={handleOpenDelta} handleOpenGraph={handleOpenGraph} handleOpenInfo={handleOpenInfo} handleOpenInference={handleOpenInference} hoveredIndex={hoveredIndex} setHoveredIndex={setHoveredIndex} filterIsWorking={filterIsWorking} loaderRecursiveId={loaderRecursiveId} />
                );
              })}
              </>
            }
           
            

    </>
  )
}

export default CostingCreateLPPTableRow;