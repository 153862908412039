import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: '48vw', xl: " 40vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ShareWithPopUpProps {
  isOpen: any;
  onCloseModal: any;
  rowItems: any;
}

const RawMaterialMoveModal = (props: ShareWithPopUpProps) => {
  const { isOpen, onCloseModal, rowItems } = props;
  const { revisionId, typeId, databaseName, categoryName } =
    useRouteParams<any>();

  const [CommodityList, setCommodityList] = useState<any>([]);
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [loader, setloader] = useState<any>(false)
  const [loading, setLoading] = useState<any>(false)
  const [CommodityName, setCommodityName] = useState<any>(null)
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
  };

  useEffect(() => {
    setLoading(true)
    API.get("/api/db/cost_databases/",{
        database_type:"material",
    },0)
      .then((res: any) => {
        var data = res?.data?.filter((item: any, index: any) => {
          return item?.category !== "UnCategorised" && item?.category !== categoryName;
        });
        setCommodityName(res?.data?.filter((item: any, index: any) => {
          return item?.category == categoryName;
        })[0])
        setLoading(false);
        setCommodityList(data);

      })
      .catch((err: any) => {
        setLoading(false);
        console.log("Srver Error");

      });
  }, [rowItems]);

  const moveHandler = () => {
    setloader(true)
    API.post(`/api/db/move_cost_data/`, {
      target_commodity: DataTypeValues?.category,
      material: true,
      id: rowItems?.material_id,
    }).then((res: any) => {
      setloader(false)
      onCloseModal()
    }).catch((err: any) => {
      setloader(false)
    });
  };


  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box sx={style}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  Material Information
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  Move Material To
                </Typography>

              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>

            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: '0.5rem' }}>
              <Box sx={{ padding: '0 1rem' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Category Name</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {loading ? <span>Loading....</span> : <span> {categoryName}</span>}</Typography>
                </Typography>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Name</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {rowItems?.material_code}</Typography>
                </Typography>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Shape </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {rowItems?.shape}</Typography>
                </Typography>
              </Box>
              <Box sx={{ padding: '0 1rem' }}>

                <Autocomplete
                  sx={{
                    width: "100%",
                    ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                    fontSize: "1rem !important",
                  }}
                  size="small"
                  id="combo-box-demo"
                  //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
                  value={DataTypeValues}
                  getOptionLabel={(option) =>
                    option?.category == undefined ? "" : option?.category
                  }
                  onChange={(event, newValue, situation) =>
                    handleSelectdatatype(newValue)
                  }
                  options={CommodityList && CommodityList}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                      {option?.category}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      sx={{
                        fontSize: "1rem",
                        borderBottom: "none",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        '.MuiSvgIcon-root': { color: 'primary.main' },
                      }}
                      {...params}
                      placeholder="Category Name"
                    />
                  )}
                />

              </Box>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'right', marginTop: '1rem', padding: '0 1rem 0.5rem' }}>
              <LoadingButton size="small" variant="contained" sx={{
                padding: "0.5rem",
                lineHeight: 1,
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }} loading={loader} onClick={() => moveHandler()}>
                Move
              </LoadingButton>
            </Box>



          </Box>
        </>
      </Modal>
    </div >
  );
};

export default RawMaterialMoveModal;
