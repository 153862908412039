import { Box, Divider, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
} from "@mui/x-data-grid";
import useWindowDimensions from "../../../../../../ui-reusable-component/useWindowDimensions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: "35vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface SheetViewModal {
  onCloseModal: any;
  isOpen: any;
  data: any;
  setUpdateCosting?:any
  updateCosting?:any
}

export default function FetchedBucketInfo(props: SheetViewModal) {
  const { onCloseModal, isOpen, data,     setUpdateCosting,
    updateCosting } = props;
  const { height, width } = useWindowDimensions();

  


console.log(data,"Cdhcdjucbjkdcbjdbjcdbj")

  let columns: any = [
    { field: "id", headerName: "id",hide:true,  },
    {
      field: "calculation__calculator__name",
      headerName: "Cost Model Name",
      width:200
      // headerAlign: "center",
      // align: 'right',
    },
    { field: "calculator_field__name", headerName: "Cost Model field", minWidth: width > 1370 ? 300 : 200, flex: 1 },
    {
      field: "value",
      headerName: "Value",
    //   flex: 1,
    },
  ];

  let rows: any = [];

  let dataGridDataGenerator: any =
    data &&
    data?.bucket_info?.map((item: any, index: any) => {
      let objShared: any = {
        id: index+1,
        Id: item?.id,
        ...item
      };
      rows.push(objShared);
    });


  // console.log(BomparameterData())

  const dataRender: any = {
    columns: columns && columns,
    rows: rows,
  };



  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      disableEscapeKeyDown={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>


     

        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Reusable parameter fetched in {data?.name || ""} ({data?.bucket_operation})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
          sx={{ height: "60vh", width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {
            <>
           
          {(
            <DataGrid
              {...dataRender}
              headerHeight={48}
              rowHeight={36}
              density="compact"
              // autoHeight={true}
             
              // hideFooter={true}
              // hideFooterPagination={true}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
            />
          )}
           </>

}
        </Box>
      </Box>
    </Modal>
  );
}
