import { Box, Divider, Modal, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useAppDispatch } from '../../../AppRouter';
import ArchitectureMultipleUploadS3 from './ArchitectureMultipleUploadS3';
import { LoadingButton } from '@mui/lab';


interface ArchitectureImageUplaodModalProps {

  UploadData: any;
  isOpen: any;
  handleClose: any;
  setLoader: any
  setSequenceCount: any;
  SwalAlertHandler: any;


}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.paper',
  borderRadius: "10px",
  p: 1,
};

const ArchitectureImageUplaodModal = (props: ArchitectureImageUplaodModalProps) => {


  const { isOpen, handleClose, UploadData, setLoader, setSequenceCount, SwalAlertHandler } = props
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage
  } = useRouteParams<any>();
  const [GroupList, setGroupList] = React.useState<any>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<any>({});
  const [Loading, setLoading] = useState(false)
  const multipleImageRef = useRef<any>(null)
  const [FlieData, setFileData] = React.useState<any>([])
  const dispatch = useAppDispatch();
  const [FilesSize, setFilesSize] = useState<any>([])
  const [Status, setStatus] = useState<any>()





  const handleSelectFilterstate = (item: any) => {
    setSelectedGroup(item)
  }


  React.useEffect(() => {
    setFileData([])
    API.get(`/circuit_diagram/arch_groups/`, {
      project: projectId,
      abbreviation: abbr,
    })
      .then((res: any) => {
        setGroupList(res?.data);
        var filterGroup = res?.data?.filter((item: any) => item?.id == groupId)[0]
        setSelectedGroup(filterGroup)
      })
      .catch((err: any) => {

      });
  }, [isOpen]);



  const FileHandler = (e: any) => {
    setFileData(e?.target?.files)
    var data = Object?.values(e?.target?.files).map((item: any) => {
      return item?.size;
    })?.reduce((a: any, b: any) => { return a + b })

    var _size = data;
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (_size > 900) { _size /= 1024; i++; }
    var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
    setFilesSize(exactSize)
  }

  const SaveHandler = () => {
    ArchitectureMultipleUploadS3(FlieData, projectId, UploadData?.top_vault, UploadData?.vault, selectedGroup?.id, abbr, dispatch, setLoader, pageNumber, productpage, setLoading, setSequenceCount, SwalAlertHandler, handleClose)
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Add Diagrams
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box>
          <Box sx={{ display: 'inline-flex', marginBottom: "1rem", }}>
            <Typography sx={{ fontSize: "1rem", fontWeight: "600", width:'7rem' }}>Group Name </Typography>
            <Typography sx={{ fontSize: "1rem", textTransform: 'capitalize', }}>: {selectedGroup?.name?.toLowerCase()}</Typography>
          </Box>


          <Box>
            <Box sx={{ display: 'inline-flex', marginBottom: "1rem", }}>
              <Typography sx={{ fontSize: "1rem", fontWeight: "600", width:'7rem' }}>Total File Size</Typography>
              <Typography sx={{ fontSize: "1rem", textTransform: 'capitalize', }}>: {FilesSize && FilesSize}</Typography>
            </Box>
            {selectedGroup?.id == undefined ? null :
              <>
                <Typography style={{ fontSize: "1rem", fontWeight: "600", marginBottom: "1rem" }}>Diagrams : <sup style={{ color: "red" }}>*</sup></Typography>
                <input
                  accept="image/*"
                  multiple
                  style={{ cursor: "pointer", marginBottom: "1rem" }}
                  onChange={(e: any) => FileHandler(e)}
                  type='file' />
              </>

            }
          </Box>

          {FlieData.length > 0 &&
            <LoadingButton loading={Loading} variant='contained' onClick={() => SaveHandler()}>
              ADD
            </LoadingButton>
          }

        </Box>
      </Box>
    </Modal>

  )
}

export default ArchitectureImageUplaodModal;