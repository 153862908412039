import { Card, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./DashboardLandingPage.module.scss";
import { useHistory } from "react-router-dom";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// @ts-ignore
import Pdf from "react-to-pdf";
import { Bar, BarChart, ResponsiveContainer, Tooltip as TooltipRecharts, XAxis, YAxis } from "recharts";
import { AxiosError, AxiosResponse } from "axios";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AUTH } from "../../Redux/Services/auth.service";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 500,
  },
}));

interface DashboardLandingPageI { }
interface Params {
  projectId: any;
  topVaultId: any;
}

const DashboardLandingPage: React.FC<DashboardLandingPageI> = (props) => {
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [Data, setData] = useState<any>();
  const [loaderData, setLoaderData] = useState<boolean>(false);
  const [dailyStatusData, setDailyStatusData] = useState<any>();
  const [activeModule, setActiveModule] = useState<string>('Overall');
  const [ProductName, setProductName] = useState<string>(topVaultId);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const history = useHistory();
  const ref: any = React.createRef();

  useEffect(() => {
    setLoaderData(false);
    API.get(
      "analytics/dashboard_funtion/",
      {
        project: projectId,
      },
      0
    ).then((res: any) => {
      setData(res?.data);
      setLoaderData(true);
    }).catch((err: any) => {
      console.log(err, "error API");
      setLoaderData(true);
    });
  }, [projectId]);

  useEffect(() => {
    API.get(
      "analytics/daily_dashboard_status/",
      {
        project: projectId,
      },
      0
    ).then((res: any) => {
      setDailyStatusData(res?.data);
    });
  }, [projectId]);

  let TotalForModuleCreated: any =
    dailyStatusData &&
    activeModule != "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) => totalCount + currentVal?.created,
      0
    );
  let TotalForModuleValidated: any =
    dailyStatusData &&
    activeModule != "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) =>
        totalCount + currentVal?.validated,
      0
    );

  let TotalForIdeaInitiated: any =
    dailyStatusData &&
    activeModule == "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) =>
        totalCount + currentVal?.initiated,
      0
    );
  let TotalForIdeaSubmitted: any =
    dailyStatusData &&
    activeModule == "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) =>
        totalCount + currentVal?.submitted,
      0
    );
  let TotalForIdeaApproved: any =
    dailyStatusData &&
    activeModule == "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) =>
        totalCount + currentVal?.approved,
      0
    );
  let TotalForIdeaRejected: any =
    dailyStatusData &&
    activeModule == "Idea" &&
    dailyStatusData[0]?.[activeModule]?.reduce(
      (totalCount: number, currentVal: any) =>
        totalCount + currentVal?.rejected,
      0
    );

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [13, 19.9],
  };

  const ProjectName: string = Data && Data[2]?.project;

  const GetProductFilter: any =
    Data &&
    Data[0]?.inner_page?.filter((val: any) => {
      return Object.keys(val)[0] == ProductName;
    });
  const ProductData: any =
    GetProductFilter && GetProductFilter[0][`${ProductName}`];

  const keysArray: any =
    ProductData &&
    ProductData[activeModule]?.map((item: any) => {
      return Object.keys(item);
    });
  const keys: any = keysArray && keysArray.flat();

  const valuesArray: any =
    ProductData &&
    ProductData[activeModule]
      ?.map((item: any) => {

        return Object.values(item);
      })
      ?.flat();

  const DonutChange: any = (moduleSelected: any, ProductName: string) => {
    const donutData: any = [
      {
        name: `${ProjectName}`,
        completed: 0,
        inProgress: 0,
        notStarted: 0
      },
    ];

    switch (ProductName) {
      case "AllProduct":
        let etotal = Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.completed + Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.in_progress + Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.not_started;
        let completedValue = Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.completed;
        let inProgressValue = Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.in_progress;
        let notStartedValue = Data[1]?.first_page[moduleSelected][Data[1]?.first_page[moduleSelected]?.length - 1]?.not_started;
        donutData[0].completed = (completedValue / etotal) * 100;
        donutData[0].inProgress = (inProgressValue / etotal) * 100;
        donutData[0].notStarted = (notStartedValue / etotal) * 100;
        break;
      default:
        let etotalA = Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.completed + Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.in_progress + Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.not_started;

        donutData[0].completed = (Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.completed / (etotalA == 0 ? 1 : etotalA)) * 100;

        donutData[0].inProgress = (Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.in_progress / (etotalA == 0 ? 1 : etotalA)) * 100;

        donutData[0].notStarted = (Data[1]?.first_page[moduleSelected]?.filter(
          (val: any) => val?.product_id == ProductName
        )[0]?.not_started / (etotalA == 0 ? 1 : etotalA)) * 100;
    }
    return donutData;
  };

  const redirectModule: any = (module: string) => {
    setActiveModule(module);
  };

  const onClickVehicleChange: any = (id: any) => {
    setProductName(id);

    const GetProductFilterInside: any =
      Data && Data[0]?.inner_page?.filter((val: any) => {
        return Object.keys(val)[0] == id;
      });

    const ProductDataInside: any =
      GetProductFilterInside && GetProductFilterInside[0][`${id}`];

    const ProductFilteredData: any = Object.fromEntries(
      Object.entries(ProductDataInside).filter(([key, value]) => Array.isArray(value) && value.length > 0)
    );

    ProductFilteredData && Object.keys(ProductFilteredData)?.includes(activeModule) ?
      setActiveModule(activeModule) : setActiveModule("Overall");

    console.log(ProductDataInside && Object.keys(ProductDataInside), "ProductDataInside")

    history.push(
      `/dashboard/${projectId}/${id}`
    );
  };

  console.log()
  const syncAllData = () => {
    setSyncLoading(true);
    API.get(
      "analytics/dashboard_funtion/",
      { project: projectId, sync: true, url: `/#/dashboard/${projectId}/AllProduct` },
      0
    ).then((res: AxiosResponse) => {
      API.get(
        "analytics/dashboard_funtion/",
        {
          project: projectId,
        },
        0
      ).then((res: any) => {
        setData(res?.data);
        let etotal = res?.data[1]?.first_page['Overall'][res?.data[1]?.first_page['Overall']?.length - 1]?.completed + res?.data[1]?.first_page['Overall'][res?.data[1]?.first_page['Overall']?.length - 1]?.in_progress + res?.data[1]?.first_page['Overall'][res?.data[1]?.first_page['Overall']?.length - 1]?.not_started;
        let completedValue = res?.data[1]?.first_page['Overall'][res?.data[1]?.first_page['Overall']?.length - 1]?.completed;
        API.post("/analytics/set_completion_progress/", {
          progress: Math.ceil((completedValue / (etotal)) * 100),
          project: Number(projectId),
        })
          .then((res: any) => { })
          .catch((err: any) => {
            console.log(err, "Server Error");
          });
      });
      setSyncLoading(false);
    }).catch((err: AxiosError) => {
      setSyncLoading(false);
      console.log(err, "Server Error");
    }
    );
  };

  const ProjectStatusCountTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const totalCount: any = payload?.[0]?.payload?.completed + payload?.[0]?.payload?.inProgress + payload?.[0]?.payload?.notStarted
      return (
        <div style={{ backgroundColor: 'white', color: 'black', borderRadius: '5px', overflow: 'hidden', marginTop: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#007fff' }}><span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{label}</span></div>
          <Table>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>Completed</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>{((payload?.[0]?.payload?.completed / (totalCount == 0 ? 1 : totalCount)) * 100).toFixed(1)}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>In progress</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>{((payload?.[0]?.payload?.inProgress / (totalCount == 0 ? 1 : totalCount)) * 100).toFixed(1)}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>Not started</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem', borderBottomColor: 'primary.light' }}>{((payload?.[0]?.payload?.notStarted / (totalCount == 0 ? 1 : totalCount)) * 100).toFixed(1)}%</TableCell>
            </TableRow>
          </Table>
        </div>
      );

    }
    return null;
  };

  const backButtonHandler: any = () => {
    if (AUTH.user_category === "Internal") {
      history.push("/internallanding")
    } else {
      history.push(`/landing`)
    }
  };

  console.log(topVaultId,"topVaultIdtopVaultId",Data, Data && Data[0]?.inner_page?.length)

  return (
    <div style={{ backgroundColor: "aliceblue", height: "100vh",fontSize:'75%' }}>
      <div className={styles.header}>
        {Data &&
          <div>
            <span>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={backButtonHandler}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <span style={{ fontWeight: 500 }}>{ProjectName}</span>
            </span>
            <span className={styles.barHeader}>
              <span style={{ marginRight: '0.5rem' }}><span style={{ marginRight: '0.3rem' }}>Last Synced on :</span><span>{Data && Data[3]?.updated_on}</span></span>
              {" "}
              {/* @ts-ignore */}
              {Data && (
                <Pdf
                  targetRef={ref}
                  options={options}
                  filename={`${ProjectName}-Dashboard.pdf`}
                >
                  {/* @ts-ignore */}
                  {({ toPdf }) => (
                    <IconButton
                      className={styles.pdfDownload}
                      onClick={toPdf}
                      style={{ color: '#007fff', marginRight: '0.5rem' }}
                      title='Pdf Download'
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  )}
                </Pdf>
              )}
              {!syncLoading ? <IconButton onClick={syncAllData} className={styles.pdfDownload} style={{ marginRight: '0.5rem', color: '#007fff' }} title='Sync Dashboard'>
                <CloudSyncIcon />
              </IconButton> : <span className={styles.pdfDownload} style={{ marginRight: '0.5rem' }}>Syncing...</span>}
              {activeModule != "Overall" && activeModule != "Media" && <BootstrapTooltip placement="left" title={<>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Vehicle</TableCell>
                      {activeModule == "Idea" ? (
                        <>
                          <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Initiated</TableCell>
                          <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Submitted</TableCell>
                          <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Accepted</TableCell>
                          <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Rejected</TableCell>
                        </>)
                        : (
                          <>
                            <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Created</TableCell>
                            <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Validated</TableCell>
                          </>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dailyStatusData && dailyStatusData[0]?.[activeModule]?.map((item: any) => {
                      return (
                        <TableRow>
                          <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.product_id}</TableCell>
                          {activeModule == "Idea" ? (
                            <>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.initiated}</TableCell>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.submitted}</TableCell>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.approved}</TableCell>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.rejected}</TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.created}</TableCell>
                              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>{item?.validated}</TableCell>{" "}
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell sx={{ textAlign: 'right', color: '#007fff', padding: "0 0.5rem", fontSize: '0.85rem' }}>Total</TableCell>
                      {activeModule == "Idea" ? (
                        <>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForIdeaInitiated && TotalForIdeaInitiated}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForIdeaSubmitted && TotalForIdeaSubmitted}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForIdeaApproved && TotalForIdeaApproved}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForIdeaRejected && TotalForIdeaRejected}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForModuleCreated && TotalForModuleCreated}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.85rem' }}>
                            {TotalForModuleValidated &&
                              TotalForModuleValidated}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </>}><IconButton className={styles.pdfDownload} style={{ marginRight: '0.5rem' }}>
                  {AUTH.user_category == "Internal" && <InfoIcon />}
                </IconButton></BootstrapTooltip>}
            </span>
          </div>}
      </div>
      <div ref={ref}>
        {loaderData ? Data && <div style={{ height: "4rem", width: "98%", margin: '-1rem auto' }} >
          <Typography sx={{ color: 'white', fontSize: '1rem', position: 'absolute', top: '7.5rem', left: "2.85rem", zIndex: '10', fontWeight: 500 }}>{`${(DonutChange(activeModule, ProductName)?.[0]?.completed).toFixed(1)}% Completed`}</Typography>
          {/* <Box sx={{width:'98%',margin:'0 auto'}}> */}
            <ResponsiveContainer>
            <BarChart
              layout="vertical"
              data={DonutChange(activeModule, ProductName)}
            // margin={{
            //   top: 20,
            //   right: 50,
            //   left: 20,
            //   bottom: 50,
            // }}
            >
              <XAxis
                type="number"
                fontSize="0.75rem"
                stroke="#000000"
                interval={0}
                tick={false} hide
              />
              <TooltipRecharts cursor={true} content={<ProjectStatusCountTooltip />} />
              <YAxis
                type="category"
                dataKey="name"
                interval={0}
                dx={-5}
                width={180}
                fontSize="0.75rem"
                stroke="#000000"
                tick={false} hide
              />
              <Bar dataKey={"completed"} style={{ cursor: 'pointer' }} stackId="a" fill={"#437d42"} />
              <Bar dataKey={"inProgress"} style={{ cursor: 'pointer' }} stackId="a" fill={"#fbec5d"} />
              <Bar dataKey={"notStarted"} style={{ cursor: 'pointer' }} stackId="a" fill={"#f95959"} />
            </BarChart>
          </ResponsiveContainer>
          {/* </Box> */}
        </div> : <div style={{ marginLeft: '2.4rem' }}> <Skeleton sx={{ height: "2.95rem", width: "98%" }} /> </div>}
        <div
          className={styles.gridItem}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: '98%',
            margin: '1rem auto 0',
          }}
          
        >
          <div>
            {ProductData &&
              Object.keys(ProductData)?.sort()?.reverse()?.map((val: string) => {

                if( val=="Overall"){
                  return (
                    ProductData && ProductData[val].length > 0 && <span style={{ padding: "0.5rem" }} className={val == activeModule ? styles.donutoptionactive : styles.donutoption} onClick={() => { redirectModule(val); }}>
                 {val}
                    </span>
                  );
                }
              
              
              })}
          </div>
          <div>
            {topVaultId && (
              <>
                {Data && Data[1]?.first_page?.BOM?.length 
                // > 2
                 && <span
                  onClick={() => {
                    onClickVehicleChange("AllProduct");
                  }}
                  className={
                    ProductName == "AllProduct"
                      ? styles.donutoptionactive
                      : styles.donutoption
                  }
                >
                  {" "}
                  All Product
                </span>}
                {
                  Data && Data[0]?.inner_page?.length>2 &&<>
                
                {Data &&
                  Data[1]?.first_page?.BOM?.map((item: any) => {
                    return {
                      id: item?.product_id,
                      name: item?.product_name,
                    };
                  })?.map((item: any, index: any) => {
                    return (
                      (Data[1]?.first_page?.BOM?.map((item: any) => {
                        return {
                          id: item?.product_id,
                          name: item?.product_name,
                        };
                      })?.length - 1 !== index && <span
                        onClick={() => {
                          onClickVehicleChange(item?.id);
                        }}
                        className={
                          projectId && item?.id == ProductName
                            ? styles.donutoptionactive
                            : styles.donutoption
                        }
                        title={item?.name}
                      >
                          {" "}
                          {item?.id}
                        </span>)
                    );
                  })}
                  </>}
              </>
            )}
          </div>
        </div>

        <Card
          sx={{
            maxWidth: "97%",
            marginLeft: "2rem",
            backgroundColor: "white",
            position: "relative",
            top: "0.5rem",
          }}
        >
          {loaderData ?
            <div
              className={styles.dataContainer}
              // style={{ height: 1250,overflowY:'scroll',overflowX:'scroll' }}
              // ref={ref}
            >
              <div
                className={styles.mainGridItem}
                adl-scrollbar="true"
                adl-scrollbar-width="0.6"
                style={{}}
              >
                <div className={styles.gridItem}>
                  {ProductData &&
                    keys?.map((module: any) => {
                      return (
                        <div className={styles.itemData}>
                          <span className={styles.gridData}>{module}</span>
                        </div>
                      );
                    })}
                </div>
                <div className={styles.statusGridItem} style={{}}>
                  {valuesArray &&
                    valuesArray?.map((item: any) => {
                      // console.log("kjsdgkjds",item)
                      return item instanceof Object ? (
                        <div className={styles.graph}>
                          {Object?.entries(item)?.map((key: any, index: number) => (
                            <div
                              className={styles.systemContainer}
                              key={index}
                              style={{
                                height: "1.8rem",
                                color: key[1] == "White" ? 'white' : item == '#fbec5d' ? "#007fff" : key[1] == '#fbec5d' ? "#007fff" : '#ffffff',
                                backgroundColor: key[1],
                                width: `${100 / Object?.keys(item)?.flat()?.length}%`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >

                              <span className={styles.gridData} title={key[0]}>
                                {key[0]}
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : item ? (
                        <div
                          className={styles.bar}
                          style={{
                            borderRadius: "0.3rem",
                            height: "1.8rem",
                            backgroundColor: item,
                            color: item == "White" ? 'white' : item == '#fbec5d' ? "#007fff" : '#ffffff',
                          }}
                        >
                          <span className={styles.gridData}>
                            {ProjectName && ProjectName}
                          </span>
                        </div>
                      ) : (
                        <div
                          className={styles.bar}
                          style={{
                            height: "1.8rem",
                            borderRadius: "0.3rem",
                            backgroundColor: item,
                            color: item == "White" ? 'white' : item == '#fbec5d' ? "#007fff" : '#ffffff',
                          }}
                        >
                          <span className={styles.gridData}></span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            : <div style={{ marginLeft: '2.4rem' }}>
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
              <Skeleton sx={{ height: "2.95rem", width: "98%" }} />
            </div>}
        </Card>
      </div>
    </div>
  );
};

export default DashboardLandingPage;
