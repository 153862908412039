import React from "react";
// @ts-ignore
import Annotation from "react-image-annotation";
import { withRouter } from "react-router-dom";

import styles from "./ArchImageAnnotate.module.scss";

import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { API } from "../../api-services";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
type MyProps = {
  s3Url: any;
  deleteAnnotation: void;
};
type MyState = { [key: string]: string };
class ArchImageAnnotate extends React.Component<any, any> {
  //@ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      annotations: [],
      annotation: {},
      tags: "",
      open: false,
      allowTouch: true,
      annotationName: "",
      vault_id: this.props.s3Url.vault,
      project_id: "",
      CopyData: "",
      top_vault: "",
      subsystem_id: "",
      task: "",
      partNumber: "",
      permissionBOM: false,
      permissionCO: false,
      openPortal: false,
      protalLeft: 0,
      protalTop: 0,
    };
    //@ts-ignore
    this.myRef = React.createRef(false);
  }

  componentDidMount() {
    API.get("/image_repository/annotation/", {
      product: this.props.vaultId,
      project: Number(this.props.projectId),
      abbreviation: this.props.abbreviation,
      media_id: this.props.s3Url?.id,
      type: "Media",
    }).then((res: any) => {
      //@ts-ignore

      this.state.annotations = res.data.annotation;
      this.props.setDataTagging(res.data.annotation);
    });
  }

  onDragStart = (e: any) => {
    // console.log(e, "Cdkbjdkbdkbjvdkbjvbjk");
  };
  // @ts-ignore
  onChange = (annotation) => {
    // if( this?.props?.CopyData!==undefined && this?.props?.CopyData!=="" ){
    //     annotation = this?.props?.CopyData!==undefined && {...annotation,data:{...annotation.data,text:this?.props?.CopyData}}
    //     console.log(annotation,"annotationannotation",this?.props?.CopyData)
    //     //@ts-ignore
    //     this?.props?.CopyData==undefined && annotation.length && this.setState({ annotation });

    //     //@ts-ignore
    //     this.setState({ annotation });
    // }

    {
      annotation.length && this.setState({ annotation });

      //@ts-ignore
      this.setState({ annotation });
    }
  };

  toggleAllowTouch = () => {
    this.setState((prevState: any) => ({ allowTouch: !prevState.allowTouch }));
  };
  checkPermissionBOM = (id: any) => {
    API.get(
      "image_repository/check_user_action/",
      { vault_id: id, module: 9 },
      0
    )
      .then((res: any) => {
        //@ts-ignore
        this.state.permissionBOM = res?.data?.user_permission;
      })
      .catch((err: any) => {
        //@ts-ignore
        this.state.permissionBOM = false;
      });
  };
  checkPermissionCosting = (id: any) => {
    API.get(
      "image_repository/check_user_action/",
      { vault_id: id, module: 16 },
      0
    )
      .then((res: any) => {
        //@ts-ignore
        this.state.permissionCO = res?.data?.user_permission;
      })
      .catch((err: any) => {
        //@ts-ignore
        this.state.permissionCO = false;
      });
  };

  // @ts-ignore
  dragOverHandler = (annotation) => {
    // console.log(annotation, "annotationannotation");
    //@ts-ignore
    annotation.length && this.setState({ annotation });
    //@ts-ignore
    this.setState({ annotation });
  };
  navigate = (e: any) => {
    e.preventDefault();
    //@ts-ignore
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.openPortal = false;
    //@ts-ignore
    this.state.protalLeft = -100000;
    //@ts-ignore
    this.state.protalTop = -100000;

    //@ts-ignore
    this.state.task && (this.myRef.current = !this.myRef.current);
    if (document.getElementsByClassName("sc-htoDjs kMGxMA ").item(0)) {
      var tagName = document
        .getElementsByClassName("sc-htoDjs kMGxMA")
        .item(0)?.innerHTML;
      const changedTAgName = tagName?.replaceAll("&amp;", "&");
      //@ts-ignore
      this.state.task = "";
      //@ts-ignore
      this.state.showPath = true;

      const vault = API.get(`/xcpep/vault_descendants_new/`, {
        search: changedTAgName,
      })
        .then((res) => {
          if (res.data?.part_no === changedTAgName) {
            // this.checkPermissionBOM(res.data?.id);
            // this.checkPermissionCosting(res.data?.id);
            //@ts-ignore
            this.state.openPortal = true;
            //bom_permission
            // costing_permission
            //@ts-ignore
            this.props.setAncestorGet(res?.data?.ancestor_tree);
            //@ts-ignore
            this.state.permissionCO = res?.data?.costing_permission;
            //@ts-ignore
            this.state.permissionBOM = res?.data?.bom_permission;
            //@ts-ignore
            this.props.setProductId(res.data?.id);
            //@ts-ignore
            this.state.vault_id = res.data?.id;
            //@ts-ignore
            this.state.project_id = res.data?.project;
            //@ts-ignore
            this.props.setTopVaultId(res.data?.top_vault);
            //@ts-ignore
            this.state.top_vault = res.data?.top_vault;
            //@ts-ignore
            this.props.setParentId(res.data?.parent);
            //@ts-ignore
            this.state.subsystem_id = res.data?.parent;
            //@ts-ignore
            this.state.showPath = true;
            //@ts-ignore
            this.state.task = res.data?.name;
            //@ts-ignore
            this.state.protalLeft = e.clientX;
            //@ts-ignore
            this.state.protalTop = e.clientY;
          }
        })
        .catch((error: any) => {
          // ADMIN.toast.throwError(
          //   error,
          //   "Could not fetch BOM & Costing Permission"
          // );
          //@ts-ignore
          this.state.showPath = false;
        });
    }
  };
  // @ts-ignore
  onSubmit = (annotation) => {
    const { geometry, data } = annotation;

    this.setState({
      //@ts-ignore
      annotation: {},
      //@ts-ignore
      annotations: this.state.annotations.concat({
        // @ts-ignore
        geometry,
        data: {
          ...data,
          id: Math.random(),
        },
      }),
    });
  };

  //@ts-ignore
  componentDidUpdate(prevProps, prevState) {
    if (this.props.s3Url.vault != prevState.vault_id) {
      API.get("/image_repository/annotation/", {
        product: Number(this.props.vaultId),
        project: Number(this.props.projectId),
        abbreviation: this.props.abbreviation,
        media_id: Number(this.props.s3Url?.id),
        type: "Media",
      }).then((res: any) => {
        //@ts-ignore

        this.state.annotations = res.data.annotation;
        this.props.setCopyData("");

        this.props.setDataTagging(res.data.annotation);
      });
      //@ts-ignore
      this.state.vault_id = this.props.s3Url.vault;
    }
    //now componentDidUpdate will only execute logic if we have a new image
    if (this.state.annotations.length !== prevState.annotations.length) {
      this.props.setAnnotationState(this.state.annotations);
      API.post("/image_repository/annotation/", {
        product: this.props.vaultId,
        project: Number(this.props.projectId),
        abbreviation: this.props.abbreviation,
        media_id: this.props.s3Url?.id,
        annotation: this.state.annotations,
        type: "Media",
      }).then((res: any) => {
        this.props.setActiocnount((prev: any) => prev + 1);
        this.props.setCopyData("");
      });
      //@ts-ignore
      this.state.task = this.state.task;
    }
  }

  deleteHandler = (ind: any) => {
    this.setState({
      annotations: this.state.annotations.filter(function (
        data: any,
        index: any
      ) {
        return ind !== index;
      }),
    });
    this.props.setActiocnount((prev: any) => prev + 1);
  };

  render() {
    // console.log(this.state.annotations?.filter((item:any)=>{return item?.data?.text==this.state.part_no})[0]?.geometry?.x,"this.state.annotations")
    return (
      <>
        <div style={{ display: "flex" }}>
          <div
            className={styles.annotationfalse}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <div className={styles.annotation}>
              <Annotation
                style={{
                  // maxHeight: "100%",
                  maxWidth: "100%",
                  height: "100%",
                  width: "auto",
                  marginLeft: "1px solid blue",
                  Position: "relative",
                }}
                // @ts-ignore
                src={this.props?.s3Url?.original}
                alt={this.props.name}
                //@ts-ignore
                annotations={this.state.annotations}
                // @ts-ignore
                type={this.state.type}
                // @ts-ignore
                value={this.state.annotation}
                onChange={this.onChange}
                // onDrag={this.onChange}
                onSubmit={this.onSubmit}
                disableEditor={this.props?.allowed?.action?.includes("C") ? false : true}
                //@ts-ignore
                onClick={(e: any) => {
                  this.navigate(e);
                }}
              />
            </div>

            {this.state.openPortal && (
              <div
                style={{
                  left: this.state.protalLeft - 300 + "px",
                  position: "fixed",
                  top: this.state.protalTop - 68 + "px",
                  zIndex: 1000,
                  width: "25%",
                  background: "aliceblue",
                  borderRadius: "5px",
                  padding: "1rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                  }}>
                  <span>{this.state.task}</span>
                </div>

                <div className={styles.linkDiv}>
                  {this.state.permissionBOM && (
                    <div className={styles.bomLink}>
                      <span
                        className={styles.navigateButtons}
                        onClick={() => this.props.bomPath()}>
                        Go to BOM
                      </span>
                    </div>
                  )}
                  {this.state.permissionCO && (
                    <div className={styles.costingLink}>
                      <span
                        className={styles.navigateButtons}
                        onClick={() => this.props.costPath()}>
                        Go to Costing
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {
              <div className={styles.tagDetails} style={{ borderLeft: '1px solid #007fff', paddingLeft: '0.5rem' }}>

                {
                  <div className={styles.addedTags}>
                    <Typography style={{ marginLeft: "1rem" }}> Edit Tags</Typography>
                    <Box
                      className={styles.addedTagsTable} sx={{ height: { lg: '35vh', xl: '40vh' }, padding: '0 0.5rem' }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3">
                      <Table>
                        <TableBody>
                          {this.state.annotations &&
                            this.state.annotations.map(
                              (item: any, index: any) => {
                                return (
                                  <TableRow sx={{ width: "100%" }}>
                                    <TableCell
                                      className="pctab"
                                      sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: "75%" }}>
                                      {item.data.text}
                                    </TableCell>
                                    <TableCell
                                      className="pctab"
                                      sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: "25%" }}>
                                      <Button
                                        title="delete"
                                        sx={{ padding: '0.2rem 0.5rem' }}
                                        onClick={() =>
                                          this.deleteHandler(index)
                                        }>
                                        <CloseIcon
                                          titleAccess={item.data.text}
                                        />

                                        {/* <Icon
                                      title={item.data.text}
                                      name="delete"
                                      className="bicon"
                                    /> */}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                        </TableBody>
                      </Table>
                    </Box>
                  </div>
                }

                <Divider style={{ border: "2px solid #e8f0fe" }} />
                {
                  <div className={styles.sub_systems}>
                    <Typography style={{ margin: "0.5rem 1rem" }}>
                      Sub-System List:
                    </Typography>
                    <Box
                      className={styles.subsystemTable} sx={{ height: { lg: '40vh', xl: '40vh' }, padding: '0 0.5rem' }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3">
                      <Table>
                        <TableBody
                          style={{ marginTop: "0px", marginBottom: "0px" }}>
                          {console.log(this.props.partNumber, "thsubSystem")}

                          {this.props.partNumber &&
                            this.props.partNumber.map((part: any) => (
                              <TableRow
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  width: "100%",
                                }}>
                                <TableCell
                                  className="pctab"
                                  sx={{ width: "10%", padding: "0 0.5rem", borderBottomColor: 'primary.light', }}>
                                  <Button
                                    draggable={true}
                                    onDragStart={() => {
                                      this.onDragStart(part.part_no);
                                    }}
                                    onClick={() =>
                                      this.props.copyPartName(part.part_no)
                                    }>

                                    {

                                      this.props?.allowed?.action?.includes("C") &&

                                      <ContentCopyIcon
                                        sx={{
                                          color:
                                            this.props.CopyData == part.part_no
                                              ? "green"
                                              : "primary.main",
                                        }}
                                      />
                                    }
                                    {/* {!this.props.allowed && (
                                  <Popup
                                    trigger={
                                      <Icon
                                        name="copy outline"
                                        className="bicon"
                                        title={part.name}
                                      />
                                    }
                                    content="Copied!"
                                    position="right center"
                                    on="click"
                                    pinned
                                  />
                                )} */}
                                  </Button>
                                </TableCell>
                                <TableCell
                                  className="pctab"
                                  draggable={true}
                                  onDragStart={() => {
                                    this.props.copyPartName(part.part_no);
                                  }}
                                  sx={{ width: "40%", padding: "0 0.5rem", borderBottomColor: 'primary.light' }}>
                                  
                                  {part.part_no}
                                </TableCell>
                                <TableCell
                                  className="pctab"
                                  sx={{ width: "50%", padding: "0 0.5rem", borderBottomColor: 'primary.light' }}>
                                  
                                  {part.name}
                                </TableCell>
                                <TableCell
                                  className="pctab"
                                  sx={{ width: "20%", padding: "0 0.5rem", borderBottomColor: 'primary.light' }}>
                                  
                                  {part.tag_count}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box >
                  </div >
                }
              </div>
            }
          </div >
        </div>
      </>
    );
  }
}
// @ts-ignore
export default withRouter(ArchImageAnnotate);
