import * as React from 'react';
import { debounce } from '@mui/material';
// import Swal from 'sweetalert2';
// import { useQueryDispatch } from '../../../CustomHook/useQueryDispatch';
// import { getCustomValidations, resetCustomValidations } from '../../../Redux/Actions/customValidations.actions';
import { useSelector } from 'react-redux';
import { customValidationSelectors } from '../../../../Redux/Reducers/customValidation.reducer';
import { ID } from '../../../../utlis/commonInterfaces';
import { parameterConfigSelectors } from '../../../../Redux/Reducers/parameterConfig.reducer';
// import { getBOMConfigurationsByCategory, resetParameterConfigs } from '../../../Redux/Actions/parameterConfig.actions';
import { RootState } from '../../../../Redux/Store/storeConfigurations';
import { workstationSelectors } from '../../../../Redux/Reducers/workstation.reducer';
// import validateValues from '../validateValues';
// import { customValidate } from '../../../utlis/CustomValidate';
// import { pickBy } from 'lodash';
// import FormError from '../../../Core/FromError';
import { styled } from '@mui/material/styles';
// import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { userPermissionAction } from './BOMEntryLandingPage';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import { API } from '../../../../api-services';
import { ADMIN } from '../../../../Redux/Services/admin.service';
import FormError from '../../../../Core/FromError';
import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import validateValues from '../../../BOMCreate/validateValues';
import { customValidate } from '../../../../utlis/CustomValidate';
import Swal from 'sweetalert2';

export interface IIdeaBooleanInputBoxProps {
    item: any,
    details: any,
    // BOMValidationStatus?: any;
    allValues: any,
    getAllValues: any
}

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };


export default function IdeaBooleanInputBox (props: IIdeaBooleanInputBoxProps) {
    const { item, details, allValues,getAllValues } = props;
    const userAction = React.useContext(userPermissionAction)
    let UserAction = userAction && userAction?.action

    const { vault, topVault, workstation, copyStatus ,ideaId} = useRouteParams<any>();
    const [value, setValue] = React.useState<any>(false);
    const [errorMessage, setErrorMessage] = React.useState<any>('');
    const [isError, setIsError] = React.useState<any>(false);
    const [isShow, setIsShow] = React.useState<any>(true);
    const [fieldError, setFieldError] = React.useState<any>(undefined);
    const customValidations = useSelector(customValidationSelectors.selectAll);
    const parameterConfigs = useSelector(
        parameterConfigSelectors.selectNonImageNonTableChildernConfigs
    );

    const getObj = (vlt: any) => {
        if (vlt == 0) {
            return { top_vault: topVault }
        } else {
            return { vault: vlt }
        }
    }



    const workstationList = useSelector((state: RootState) => {
        return workstationSelectors.selectById(state, +workstation || 0);
    });


    const configToDbMap = parameterConfigs.reduce((result, config) => {
        result[config.id] = config.parameter_id;
        return result;
    }, {} as Record<string, ID>)

    const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
        result[config.id] = config.data_type;
        return result;
    }, {} as Record<string, string>);
    const categoryIncludedInWorkstion = workstationList
        ? workstationList.categories.includes(1)
        : false;

    const allInputData = parameterConfigs.filter(data => data.data_type !== 'table').filter(data => data.data_type !== 'document');
    const allowedParameters = categoryIncludedInWorkstion
        ? workstationList?.allowed_parameters
        : undefined;

    var errors = validateValues(
        allInputData,
        { [item?.key]: value },
        allowedParameters,
        "parameter_id"
    );








    React.useEffect(() => {
        setValue(item?.value)
        setIsError(true)
        if (item?.data_type == "nonzero-positive-int" && +value < 0 && !Number.isInteger(+value)) {
            setIsError(true)
            setErrorMessage('Please enter a non-zero positive integer')
        } else if (item?.data_type == "nonzero-positive-float" && +value < 0 && !Number.isInteger(+value)) {
            setIsError(true)
            setErrorMessage('Please enter a non-zero positive float')
        } else if (item?.data_type == "positive-float" && +value < 0 && Number.isInteger(+value)) {
            setIsError(true)
            setErrorMessage('Please enter a positive float')
        } else {
            setIsError(false)
            setErrorMessage('')
        }
    }, [item])
    React.useEffect(() => {
        if (item && typeof value === "number") {
            if (item?.data_type == "nonzero-positive-int" && +value > 0) {
                setIsShow(true)
            } else if (item?.data_type == "non-zero positive integer" && +value < 0) {
                setIsShow(true)
            }
            else if (item?.data_type == "nonzero-positive-float" && +value > 0 && Number.isInteger(value)) {
                setIsShow(true)
            } else if (item?.data_type == "positive-float" && +value >= 0 && Number.isInteger(value)) {
                setIsShow(true)
            } else {
                setIsShow(false)
            }
        } else {
            setIsShow(true)
        }
    }, [value, item])


    const validateField = (parameter_id: ID, value?: any) => {
        const val = value ?? { [item?.key]: +value }[parameter_id];
        let error: string | undefined;
        const config: any = parameterConfigs.find(
            config => config.parameter_id === parameter_id
        );

        if (config)
            error = validateValues([config], { [parameter_id]: val })[
                parameter_id
            ];

        // custom validations
        const relation = customValidations?.find(
            relation => relation.parameter_id === parameter_id
        );
        // { [item?.key]: +value },
        allValues[item?.key] = value

        if (relation)
            error = customValidate(
                relation.parameter_id,
                configToDataTypeMap,
                relation.validation,
                undefined,
                allValues,
                details,
                configToDbMap
            );
        setFieldError(error!);
    };



    const updateDataVaultParams = async (name: any, value: any, vlt: any, itm: any) => {
        if (!isError) {
            API.put(`/idea/pool/${ideaId}/`, {
                ...{parameter_json:{[name]: value}}
            }, {
                ...getObj(vlt),
                module:15
            }, 0).then((res: any) => {
                // BOMValidationStatus();
                ADMIN.toast.info("Updated Successfully")
            }).catch((err: any) => {
                if(err.response){
                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  text: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                  customClass: {
                    container: "swal2Container",
                  },
                });
            }
            })
        } else {

        }
    }

    const ExactDebounceParameters = React.useRef(
        debounce(async (value: any, name: any, idd: any, itemm: any) => {
            await updateDataVaultParams(name, value, idd, itemm);
        }, 1000)
    ).current;

    const handleChangeNumber = (event: any, itemm: any) => {
        const { name, checked } = event.target;
        validateField(itemm?.key, checked)
        ExactDebounceParameters(checked, name, vault, itemm);
        setValue(checked);
        setIsError(true)

    }

  return (
         <>
            <FormControlLabel
                control={<Android12Switch 
                    // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true} 
                    disabled={!item?.editable}
                    checked={value} onChange={(event: any) => handleChangeNumber(event, item)} name={item?.key} />}
                label=""
            />
            {fieldError && (
                <FormError error={fieldError} align="center" />
            )}
        </>
  );
}
