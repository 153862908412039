import * as React from "react";
import {
  Box,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface ICreateCostCopyCountModalProps {
  onCloseModal: any;
  isOpen: any;
  itemId: any;
  getRMData: any;
  type: any;
  getProcessData?: any;
  getOverheadsData?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function CreateCostCopyCountModal(
  props: ICreateCostCopyCountModalProps
) {
  const { onCloseModal, isOpen, itemId, getRMData, type ,getProcessData,getOverheadsData} = props;
  const [count, setCount] = React.useState<any>(0);
  const [loading, setLoading] = React.useState<any>(false);
  const handleChange = (e: any) => {
    const { value } = e.target;
    setCount(value);
  };
  const getObj = () => {
    switch (type) {
      case "material":
        return { material_id: itemId?.id };
      case "boughtout":
        return { purchase_id: itemId?.id };
      case "process":
        return { process_id: itemId?.id };
      case "overhead":
        return { overhead_id: itemId?.id };
      default:
        break;
    }
  };



  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {type=="process"?`Create ${itemId?.type} Copy`:"Create Copy"}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 0.5rem" }}>
            <FormControl fullWidth>
              <TextField
                label="Copy Count"
                variant="standard"
                size="small"
                type="number"
                value={count}
                onChange={handleChange}
                inputProps={{
                  step: "any",
                  min: 1,
                }}
                sx={{
                  width: "100%",
                  marginBottom: "1rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </FormControl>
            <Box sx={{ width: "100%", textAlign: "right" }}>
              <LoadingButton
                disabled={count === 0 ? true : false}
                loading={loading}
                variant="contained"
                size="small"
                onClick={() => {
                  setLoading(true);
                  API.post("/cost/cost_copy/", {
                    ...getObj(),
                    data: true,
                    qty: parseInt(count),
                    link: itemId?.type === "delinked"?false:true
                  })
                    .then((res: any) => {
                      setLoading(false);
                      if(type==="material" || type==="boughtout"){
                      getRMData();
                      }else if(type==="process"){
                      getProcessData();
                      }else if(type==="overhead"){
                      getOverheadsData();
                      }
                      onCloseModal();
                      Swal.fire({
                        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                      <br />
                      <p style="color:#007fff;">Copy created successfully </p>   
                       </div>`,
                      });
                      
                    })
                    .catch((err: any) => {
                      setLoading(false);
                    });
                }}
              >
                Create Copy
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
