import {
  Box,
  Divider,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
//@ts-ignore
import { LoadingButton } from "@mui/lab";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface MovePartModalProps {
  onCloseModal: any;
  isOpen: any;
  data: any;
  getAllAPIRequest: any;
}

const AddEditAPIRequestModal = (props: MovePartModalProps) => {
  const { productId } = useRouteParams<any>();
  const { onCloseModal, isOpen, data, getAllAPIRequest } = props;

  const [requestName, setRequestName] = useState<any>("");

  const handleOnChange = (e: any) => {
    const { value } = e.target;
    setRequestName(value);
  };

  React.useEffect(() => {
    if (typeof data !== "string") {
      setRequestName(data.name);
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {typeof data === "string" ? "Add" : "Edit"} API Request
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            <FormControl fullWidth>
              <TextField
                autoFocus
                variant="standard"
                InputProps={{
                  style: { color: "#007fff", fontSize: "1rem" },
                }}
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                value={requestName}
                onChange={handleOnChange}
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                label="Request Name *"
              />
            </FormControl>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ marginTop: "1rem", float: "right" }}
              onClick={() => {
                if (typeof data === "string") {
                  API.post(
                    "/xcpep/api_requestr_view/",
                    { name: requestName, top_vault: productId },
                    {},
                    0
                  )
                    .then((res: any) => {
                      getAllAPIRequest();
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      console.log(err);
                    });
                } else {
                  API.put(
                    `/xcpep/api_requestr_view/${data.id}/`,
                    { name: requestName },
                    {},
                    0
                  )
                    .then((res: any) => {
                      getAllAPIRequest();
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      console.log(err);
                    });
                }
              }}
              loading={false}
            >
              {" "}
              {typeof data === "string" ? "Add" : "Save"}{" "}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default AddEditAPIRequestModal;
