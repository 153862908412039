import React, { useState } from 'react';
import { Autocomplete, Box, Divider, Drawer, IconButton, TextField, Typography, debounce,CircularProgress, Button } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from '../CostingCreateSystemLandingPage.module.scss';
import { useHistory } from 'react-router-dom';
import { CostingCreateDrawer } from '../CostingCreateDrawer';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { API } from '../../../api-services';
import CostingCreateLPPTable from './CostingCreateLPPTable';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  abbr: any;
  partId: any;
  depth: any;
  vault_id:any;
  projectIdOld:any;
  projectNameOld:any;
}

interface ICostingCreateLPPLandingProps { }

const CostingCreateLPPLanding: React.FC<ICostingCreateLPPLandingProps> = (props) => {
  const { projectId, projectName, topVaultIds, expandTable, initialTopVault, abbr, partId, depth,vault_id,projectIdOld,projectNameOld } = useRouteParams<Params>();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [SearchValue, setSearchValue] = useState([]);
  const [inputValue, setInputValue] = React.useState(null);
  const [value, setValue] = React.useState<any>([]);
  const [assemblyPart, setAssemblyPart] = React.useState();
  const [category, setCategory] = React.useState();
  const [status, setStatus] = React.useState<any>();
  const [calculatorType, setCalculatorType] = React.useState<any>();
  const [apiCallMain, setApiCallMain] = useState<boolean>(false);
  const [getSearchResult, setGetSearchResult] = useState<any>();
  const [downloadLoading,setDownloadLoading] = useState<any>(false);

  const topVaultsParam: any =
    topVaultIds && typeof topVaultIds == "string"
      ? topVaultIds?.split(",")?.map(Number)
      : [topVaultIds];

  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, Ids: any) => {
      await API.get("cost/costing_analysis/", { master_hierarchy: projectId, level: abbr, top_vaults: Ids.toString(), search: criteria, part_id: partId, depth: depth }).then(
        (res: any) => {
          setSearchValue(res.data);
        }
      );
      //  setCharacters();
    }, 500)
  ).current;

  const PartNameHandler = (item: any) => {
    setSearchValue([])
    setInputValue(item);
    if (item.length > 0)
      ExactDebounce(item, filterDataIds)
  }

  const SearchOption = () => {
    return SearchValue?.map((item: any, index: any) => {
      return ({
        title: item.parts_name,
        id: item?.id,
        abbreviation: item?.abbreviation,
        depthNode: item?.depth,
        path: item?.path,
        is_leaf: item?.is_leaf,
      })
    })
  }

  const handleChange = (newValue: any) => {
    setValue(newValue);
    if (newValue !== null) {
      setAssemblyPart(undefined);
      setStatus(undefined);
      setCategory(undefined);
      setCalculatorType(undefined);
      setGetSearchResult(newValue?.path);
      setApiCallMain(!apiCallMain)
    }
    if (newValue == null) {
      setGetSearchResult(undefined);
      setApiCallMain(!apiCallMain)
    }
  }

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  // useEffect(() => {
  //   API.get(`/cost/costing_create_ui/`, { get_collapse_project:projectId }, 0)
  //     .then((res: AxiosResponse) => {
  //         setCompactViewInfo(res.data);
  //     })
  //     .catch((err: AxiosError) => {
  //         console.log(err, "error");
  //     });
  // },[projectId,topVaultIds]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <CostingCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );

  const handleClickBack = () => {
    history.push(`/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}`);
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    API.get(
      `/cost/cost_per_kg_download/`,
      {
        top_vaults: topVaultIds,
        abbreviation: abbr,
        project:projectId
      },
      0
    ).then((res: any) => {
      setDownloadLoading(false);
      window.open(res.data, "_blank", " noopener");
      // setSelectFromDateRange(new Date());
      // setSelectToDateRange(new Date());
      // setOpen(false);
    });
  }

  const Commoditywiseanalysis = () => {
    history.push(`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${0}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}/${projectId}/${projectName}`);
  }
 

  return (
    <>
      <Box sx={{ padding: "0 0.5rem", display: 'flex', alignItems: 'center' }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft} >
            <IconButton sx={{ cursor: "pointer", }} onClick={handleClickBack}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block' }}> Cost/Kg Analysis ( {projectName} )</Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            <Button variant='contained' size='small' onClick={()=>Commoditywiseanalysis()}>Commodity wise Cost/Kg analysis</Button>
            <Box sx={{ minWidth: '15rem', maxWidth: "30rem" }}>
              <Autocomplete
                value={value}
                id="controllable-states-demo"
                onChange={(event: any, newValue: string | null) => {
                  handleChange(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                // @ts-ignore
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  PartNameHandler(newInputValue)
                }}
                // @ts-ignore
                getOptionLabel={(option) =>
                  option?.title == undefined ? "" : option?.title
                }
                // @ts-ignore
                options={SearchOption() && SearchOption()}
                sx={{
                  width: '100%',
                  padding: "0 0.5rem",
                  '.MuiSvgIcon-root':{color:'primary.main'},	
                }}
                renderInput={(params) => <TextField {...params} variant="standard" sx={{
                  fontSize: '1rem',
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                  "& input::placeholder": {
                    fontSize: "1rem",
                  }
                }}
                  placeholder="Search..."
                />
                }
              />
            </Box>
            <IconButton title='Filter'><FilterAltIcon sx={{ color: '#007fff' }} onClick={() => setOpenFilter(true)} /></IconButton>
            <Button
              disabled={downloadLoading}
              onClick={handleDownload}
              sx={{minWidth:'3rem',padding:'0.2rem 0.5rem'}}
              endIcon={!downloadLoading && <DownloadIcon sx={{ marginLeft:'-1rem', fontSize:'1.8rem', color: '#007fff' }} />}
            >
              {downloadLoading ? <CircularProgress size={16} color="primary" /> : ''}
            </Button>
      {/* <LoadingButton loading={downloadLoading}  title='Download' onClick={()=>handleDownload()}><DownloadIcon sx={{ color: '#007fff' }} /></LoadingButton> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ maxHeight: { lg: '86vh', xl: '90vh' }, width: '98%' }}
          // sx={String(topVaultIds)?.includes(',') ? topVaultIds?.split(',')?.length == 2 ? {display:'grid', gridTemplateColumns:`1.5fr 0.5fr 1fr`, width:'98%', maxHeight:"90vh"} : {display:'grid', gridTemplateColumns:`1.5fr 0.5fr repeat(${topVaultIds?.split(',')?.length-1},1fr)`, width:'98%', maxHeight:"90vh"} : {display:'grid', gridTemplateColumns:`1fr`, width:'98%', maxHeight:"90vh"}}  
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {/* {expandTable == 'true' ?
           <CostingCreateSystemTable/> :
           <CostingCreateSystemTableCompressed/>} */}
          <CostingCreateLPPTable openFilter={openFilter} setOpenFilter={setOpenFilter} assemblyPart={assemblyPart} setAssemblyPart={setAssemblyPart} category={category} setCategory={setCategory} status={status} setStatus={setStatus} calculatorType={calculatorType} setCalculatorType={setCalculatorType} setValue={setValue} setSearchValue={setSearchValue} apiCallMain={apiCallMain} setApiCallMain={setApiCallMain} getSearchResult={getSearchResult} setGetSearchResult={setGetSearchResult} />
        </Box>
      </Box>
    </>)
}

export default CostingCreateLPPLanding;