// react
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import useWindowDimensions from '../../../utlis/useWindowDimensions';
import { useDocumentTitle } from '../../../ui-reusable-component/useDocumentTitle';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { useGanttChartModal } from './useGanttChartModal';
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, Slide, Typography } from '@mui/material';
import styles from "./GanttChart.module.scss";
import { API } from '../../../api-services';
import { getGanttChartData, resetGanttChart } from '../../../Redux/Actions/ganttChart.action';
import { ganttChartsSelectors } from '../../../Redux/Reducers/ganttChart.reducers';
import { useQueryDispatch } from '../../../CustomHook/useQueryDispatch';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch } from '../../../../AppRouter';
import GanttChartMessageModal from './GanttChartMessageModal';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface GanttChartProps extends RouteComponentProps { }

interface ITaskListColumn {
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    selectedTaskId: string;
    setSelectedTask: (taskId: string) => void;
}


const GanttChart: React.FC<GanttChartProps> = (props) => {
    const { height, width } = useWindowDimensions();
    const [viewMode, setViewMode] = React.useState<any>(ViewMode.Week);
    const [findDate, setFindDate] = React.useState<any>(new Date());
    const [graphData, setGraphData] = React.useState<any>();
    const [openFilter, setOpenFilter] = React.useState(false);
    const [allDates, setAllDates] = React.useState<any>();
    const [selectFilterActivity, setSelectFilterActivity] = React.useState<any>("All");
    const [selectFilterXcpepActivity, setSelectFilterXcpepActivity] = React.useState<any>("All");
    const [projectDetails, setProjectDetails] = React.useState<any>();
    const [generatePdf, setGeneratePdf] = React.useState<boolean>(false);

    useDocumentTitle("Gantt Chart");
    const messageBoxModal = useGanttChartModal();

    const { projectId } = useParams<{ projectId: string; }>();

    const history = useHistory();

    const dispatch = useAppDispatch();

    let columnWidth = 60;
    if (viewMode === ViewMode.Month) {
        columnWidth = 160;
    } else if (viewMode === ViewMode.Week) {
        columnWidth = 150;
    } else if (viewMode === ViewMode.Year) {
        columnWidth = 400;
    }

    const moduleColor = [
        {
            module: "BOM",
            color: "#ecdaea"
        },
        {
            module: "PM",
            color: "#bdd7ee"
        },
        {
            module: "Idea",
            color: "#c6e0b4"
        },
        {
            module: "Costing",
            color: "#f6cbad"
        },
        {
            module: "MAF",
            color: "lightgrey"
        },
        {
            module: "CAD",
            color: "#ecdaea"
        },
        {
            module: "FIN",
            color: "#ADD8E6"
        },
        {
            module: "FIN",
            color: "#c0aee0"
        },
    ];


    const remove_list_2 = [
        'Cost Database',
        'Name Analysis',
        'Cost/Kg Check'
    ]

    useEffect(() => {
        API.get("/xcpep/project/", {
            id: projectId,
        },
            0).then((res: any) => {
                setProjectDetails(res?.data);
            });
    }, [projectId])


    const ganttChartFetch = useQueryDispatch({
        query: {
            action: getGanttChartData,
            arg: { project: Number(projectId) },
        },
        cleanup: {
            action: resetGanttChart,
            args: [],
        },
        dependency: [],
    });

    const ganttData = useSelector(ganttChartsSelectors.selectAll);

    const moduleColorMap = (module: string) => {
        const color = moduleColor && moduleColor?.filter((color: any) => color?.module === module);
        // console.log(color, module, moduleColor)
        return color[0]?.color;
    };


    // dont remove null from date it will make ui laggy
    //@ts-ignore
    const maxDate = allDates && allDates?.length > 0 ? new Date(Math.min(...allDates.map((element) => { return new Date(element) }),),) : new Date();


    const ganttChartModifiedData = (graphData: any) => {
        const modifiedData: any = []
        for (let i = 0; i < graphData.length; i++) {
            const customEndDate = new Date();
            customEndDate.setDate(customEndDate.getDate() + 1);
            if (remove_list_2.includes(graphData[i].name)) {
                modifiedData.push(
                    {
                        start: graphData[i]?.start_date ? new Date(graphData[i]?.start_date) : maxDate,
                        end: graphData[i]?.end_date ? new Date(graphData[i]?.end_date) : maxDate,
                        // name: graphData[i]?.name,
                        activity_name: graphData[i]?.name,
                        id: graphData[i]?.id as string,
                        type: 'task',
                        progress: 100,
                        isDisabled: true,
                        module: graphData[i]?.module,
                        status: remove_list_2?.includes(graphData[i]?.name) ? "NA" : graphData[i]?.status,
                        color: graphData[i]?.status == "Completed" ? "#437d42" : graphData[i]?.status == "WIP" ? "#fbec5da8" : graphData[i]?.status == "Not Started" ? "#f5f5f5" : "#fa9e9e",
                        styles: { progressColor: '#007fff', progressSelectedColor: '#007fff' },
                        remove_id: graphData[i]?.id as string,
                        group: graphData[i]?.group as string,
                    },

                );

            }
            else {
                modifiedData.push(
                    {
                        start: graphData[i]?.start_date ? new Date(graphData[i]?.start_date) : maxDate,
                        end: graphData[i]?.end_date ? new Date(graphData[i]?.end_date) : maxDate,
                        // name: graphData[i].name,
                        activity_name: graphData[i]?.name,
                        id: graphData[i]?.id as string,
                        type: 'task',
                        progress: 100,
                        isDisabled: true,
                        module: graphData[i]?.module,
                        status: remove_list_2?.includes(graphData[i]?.name) ? "NA" : graphData[i]?.status,
                        color: graphData[i]?.status == "Completed" ? "b5fa6b" : graphData[i]?.status == "WIP" ? "#fbec5da8" : graphData[i]?.status == "Not Started" ? "#f5f5f5" : "#fa9e9e",
                        styles: { progressColor: '#007fff', progressSelectedColor: '#007fff' },
                        remove_id: graphData[i]?.id as string,
                        group: graphData[i]?.group as string,
                    },
                    {
                        start: graphData[i]?.start_by ? new Date(graphData[i]?.start_by) : maxDate,
                        end: graphData[i]?.end_by ? new Date(graphData[i]?.end_by) : graphData[i]?.start_by ? new Date(graphData[i]?.start_by) : maxDate,
                        name: graphData[i]?.status == "Not Started" ? "Not Started" : "",
                        activity_name: `${graphData[i]?.name} ( Actual )`,
                        id: `${graphData[i]?.name} ( Actual )`,
                        type: 'task',
                        progress: 100,
                        isDisabled: true,
                        module: graphData[i]?.module,
                        status: "-",
                        color: graphData[i]?.status == "Completed" ? "b5fa6b" : graphData[i]?.status == "WIP" ? "#fbec5da8" : graphData[i]?.status == "Not Started" ? "#f5f5f5" : "f#a9e9e",
                        styles: { progressColor: graphData[i]?.status == "Completed" ? "green" : graphData[i]?.status == "WIP" ? "#ffc107" : graphData[i]?.status == "Not Started" ? "#f5f5f5" : "red", progressSelectedColor: graphData[i]?.status == "Completed" ? "green" : graphData[i]?.status == "WIP" ? "#ffc107" : graphData[i]?.status == "Not Started" ? "#f5f5f5" : "red", },
                        remove_id: graphData[i]?.id as string,
                        group: graphData[i]?.group as string,
                    },

                );
            }

        }
        return modifiedData;
    }


    React.useEffect(() => {
        if (ganttData && ganttData) {
            setGraphData(ganttChartModifiedData(ganttData))
            const dates: any = [];
            ganttData?.forEach((ganttChart: any) => {
                dates.push(ganttChart.start_date);
                dates.push(ganttChart.end_date);
                dates.push(ganttChart.start_by);
                dates.push(ganttChart.end_by);
            });

            const filterdates = dates.filter((date: any) => date !== null);

            setAllDates(filterdates);
        }
    }, [ganttData]);

    const filter = graphData && graphData?.map((d: any) => d.remove_id)


    let milestone_id = graphData && filter?.filter((element: any, index: any) => {
        return filter.indexOf(element) !== index;
    });

    const handleDownloadExcel = () => {
        var downloadUrl = API.apiBase + `xcpep/gantt_chart_download/?project=${projectId}&milestone_id=${milestone_id.toString(",")}&activity_type=${selectFilterActivity}&work_type=${selectFilterXcpepActivity}`;
        try {
            window.open(downloadUrl, "_blank", "noopener noreferrer");

        } catch (error) {
            console.log(error, "Could not fetch download url");
        }
    }

    interface ITaskListHeader {
        headerHeight: number;
        rowWidth: string;
        fontFamily: string;
        fontSize: string;
    }

    const TaskListHeader: React.FC<ITaskListHeader> = () => {
        return (
            <div className="_1nBOt" style={{ height: "60px", }}>
                <div className="_WuQ0f" style={{ minWidth: "40px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}>SNo.</span></div>
                <div className="_WuQ0f" style={{ minWidth: "300px", border: "1px solid #007fff14" }}>
                    <div className={styles.header}>
                        <span>Planned : <span className={styles.plannedDot}></span></span>
                        <span>Actual :
                            <span className={styles.actualDot}></span>
                            <span className={styles.actualDot}></span>
                            <span className={styles.actualDot}></span>
                        </span>
                    </div>
                </div>
                <div className="_WuQ0f" style={{ minWidth: "84px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}>Status</span></div>
                <div className="_WuQ0f" style={{ minWidth: "60px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}>Action</span></div>
                <div className="_WuQ0f" style={{ minWidth: "85px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}>Start Date</span></div>
                <div className="_WuQ0f" style={{ minWidth: "85px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}>End Date</span></div>
                <div className="_WuQ0f" style={{ minWidth: "60px", border: "1px solid #007fff14", fontSize: "1rem" }}><span style={{ marginLeft: '0.3rem' }}></span></div>
            </div>
        );
    };


    const handleFilter = (id: any) => {
        const filterData = graphData && graphData?.filter((data: any) => data.remove_id !== id);

        if (graphData.length <= 2) {
            // ADMIN.toast.error("You can't remove all the activities");
        }
        else setGraphData(filterData);
    }

    const TaskListColumn: React.FC<ITaskListColumn> = ({ tasks }) => {
        return (
            <div>
                {graphData && graphData.filter((task: any) => task.status != "-").map((item: any, index: any) => {
                    return (
                        <div className="_1nBOt" style={{ height: remove_list_2.includes(item.activity_name) ? "30px" : "60px", fontSize: "1rem" }} key={index}>
                            <div className="_WuQ0f" style={{ minWidth: "40px", border: "1px solid #007fff14", padding: "0px 5px", backgroundColor: moduleColorMap(item.module) }} >{index + 1}</div>
                            <div className="_WuQ0f" style={{ minWidth: "300px", border: "1px solid #007fff14", padding: "0px 5px", backgroundColor: moduleColorMap(item.module) }} >{item.activity_name}</div>
                            <div className="_WuQ0f" style={{ minWidth: "84px", border: "1px solid #007fff14", padding: "0px 5px", backgroundColor: item.color }}>{item.status}</div>
                            <div className="_WuQ0f" style={{ minWidth: "60px", border: "1px solid #007fff14", padding: "0px 5px", backgroundColor: item.color }}>
                                <CloseIcon onClick={() => handleFilter(item.id)} style={{ cursor: 'pointer' }} />
                                {
                                    !remove_list_2.includes(item.activity_name) && (<EditIcon onClick={() => messageBoxModal.open(item)} style={{ cursor: 'pointer' }} />)
                                }

                            </div>
                            <div className="_WuQ0f" style={{ minWidth: "85px", border: "1px solid #007fff14", padding: "0px 4px", backgroundColor: item.color }}>
                                <div>{moment(item.start).format("DD/MM/YYYY")}</div>
                                <div style={{ marginTop: '8px' }}>{moment(graphData && graphData[1 + (index * 2)]?.start).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="_WuQ0f" style={{ minWidth: "85px", border: "1px solid #007fff14", padding: "0px 4px", backgroundColor: item.color }}>
                                <div>{moment(item.end).format("DD/MM/YYYY")}</div>
                                <div style={{ marginTop: '8px' }}>{moment(graphData && graphData[1 + (index * 2)]?.end).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="_WuQ0f" style={{ minWidth: "60px", border: "1px solid #007fff14", padding: "0px 4px", backgroundColor: item.color }}>
                                <div>Planned</div>
                                <div style={{ marginTop: '8px' }}>Actual</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };


    const TooltipContent: React.FC<any> = ({ task }) => {
        return (
            <Box className={styles.toolTip} >
                <Box>
                    <Typography sx={{display:'flex',alignItems:'center',fontSize:'1rem',whiteSpace:'nowrap'}}><Typography sx={{ fontSize:'1rem',color: 'black', width: '3rem',textAlign:'right',marginRight:'0.5rem', }}>Task : </Typography>{`${task.activity_name}`}</Typography>
                </Box>
                <Box>
                    <Typography sx={{display:'flex',alignItems:'center',fontSize:'1rem',whiteSpace:'nowrap'}}><Typography sx={{ fontSize:'1rem',color: 'black', width: '3rem',textAlign:'right',marginRight:'0.5rem', }}>Group : </Typography>{`${task.group}`}</Typography>
                </Box>
                <Box>
                    <Typography sx={{display:'flex',alignItems:'center',fontSize:'1rem',whiteSpace:'nowrap'}}><Typography sx={{ fontSize:'1rem',color: 'black', width: '3rem',textAlign:'right',marginRight:'0.5rem', }}>Starts : </Typography>{`${moment(task.start).format('LL')}`}</Typography>
                </Box>
                <Box>
                    <Typography sx={{display:'flex',alignItems:'center',fontSize:'1rem',whiteSpace:'nowrap'}}><Typography sx={{ fontSize:'1rem',color: 'black', width: '3rem',textAlign:'right',marginRight:'0.5rem', }}>End : </Typography>{`${moment(task.end).format('LL')}`}</Typography>
                </Box>
            </Box>
        );
    }

    const handleClose = () => {
        setOpenFilter(false);
    };

    const handleOpen = () => {
        setOpenFilter(true);
    };

    const handlefilterDataValues = async () => {
        const action = await dispatch(
            getGanttChartData({
                project: Number(projectId),
                activity_type: selectFilterActivity,
                work_type: selectFilterXcpepActivity
            })
        );
        if (getGanttChartData.fulfilled.match(action)) {
            setOpenFilter(false);
        }
    }

    const handleResetfilterDataValues = async () => {
        const action = await dispatch(
            getGanttChartData({
                project: Number(projectId)
            })
        );
        if (getGanttChartData.fulfilled.match(action)) {
            setOpenFilter(false);
            setSelectFilterActivity('All');
            setSelectFilterXcpepActivity('All');
        }
    }

    const handleChangeFilterActivity = (e: any) => {
        setSelectFilterActivity(e.target.value);
    };

    const handleChangeFilterXcpepActivity = (e: any) => {
        setSelectFilterXcpepActivity(e.target.value);
    };

    const redirectToMail = () => {
        setGeneratePdf(true);
        API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 2 }).then((res: any) => {
            API.get("/xcpep/gantt_chart_download/", {
                project: projectId,
                work_type: selectFilterXcpepActivity,
                activity_type: selectFilterActivity,
                milestone_id: milestone_id.toString(","),
                attachment: true
            },
                0).then((res: any) => {
                    setGeneratePdf(false)
                    history.push(`/ganttchart-mail/${projectId}/`)
                });
            // history.push(`/GanttChart/${projectId}`)
        }).catch((err: any) => {
            console.log("Server Error")
        })
    }

    return (
        ganttChartFetch.match("TRUE") ? <>
            <div
                data-testid="GanttChart"
                className={styles.container}
            >
                <Dialog
                    open={openFilter}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        ".MuiPaper-root.MuiDialog-paper": {
                            maxWidth: "60vw",
                            minWidth: "25vw",
                            borderRadius: '10px',
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Filter
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <DialogContent sx={{ overflowY: "unset", padding: '0 2rem 1rem 1rem' }}>
                        <FormControl variant='standard' fullWidth sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel
                                id="demo-simple-select-label-status"
                                style={{ color: "#007fff" }}
                            >
                                Internal Activity
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label-status"
                                id="demo-simple-select-status"
                                label="Select Status"
                                value={selectFilterActivity}
                                onChange={handleChangeFilterActivity}
                                sx={{
                                    width: '100%',
                                    "& .MuiSelect-select": {
                                        padding: "0.5rem",
                                        fontSize: "1rem",
                                    },
                                    "&:before": {
                                        borderBottomColor: "primary.main",
                                    },
                                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                                }}
                            >
                                <MenuItem style={{ color: "green", fontSize: '1rem' }} value={"Internal"}>
                                    Internal
                                </MenuItem>
                                <MenuItem style={{ color: "red", fontSize: '1rem' }} value={"External"}>
                                    External
                                </MenuItem>
                                <MenuItem style={{ color: "black", fontSize: '1rem' }} value={"All"}>
                                    ALL
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <h4 style={{ margin: '0', textAlign: 'center', fontSize: '1rem' }}>AND</h4>
                    <DialogContent sx={{ overflowY: "unset", padding: '0 2rem 1rem 1rem' }}>
                        <FormControl variant='standard' fullWidth sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel
                                id="demo-simple-select-label-Activity"
                                style={{ color: "#007fff" }}
                            >
                                xcPEP Activity
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label-Activity"
                                id="demo-simple-select"
                                label="Select Activity"
                                value={selectFilterXcpepActivity}
                                onChange={handleChangeFilterXcpepActivity}
                                sx={{
                                    width: '100%',
                                    "& .MuiSelect-select": {
                                        padding: "0.5rem",
                                        fontSize: "1rem",
                                    },
                                    "&:before": {
                                        borderBottomColor: "primary.main",
                                    },
                                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                                }}
                            >
                                <MenuItem
                                    style={{ color: "green", fontSize: '1rem' }}
                                    value={"ON"}
                                >
                                    On xcPEP
                                </MenuItem>
                                <MenuItem
                                    style={{ color: "red", fontSize: '1rem' }}
                                    value={"OFF"}
                                >
                                    Off xcPEP
                                </MenuItem>
                                <MenuItem style={{ color: "black", fontSize: '1rem' }} value={"All"}>
                                    ALL
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions sx={{ padding: '0 1.5rem 1rem' }}>
                        <Button variant='contained' sx={{
                            "&:hover": {
                                transform: 'Scale(1.1)',
                                transition: 'transform 0.5s ease',
                            }
                        }} size='small' onClick={handlefilterDataValues}>apply</Button>
                        <Button variant='contained' size='small' sx={{
                            "&:hover": {
                                transform: 'Scale(1.1)',
                                transition: 'transform 0.5s ease',
                            }
                        }} onClick={handleResetfilterDataValues}>clear</Button>
                    </DialogActions>
                </Dialog>
                <div className={styles.crumbSection}>
                    <div style={{ position: 'absolute', left: '0', marginLeft: '0.5rem' }}>
                        <IconButton sx={{ cursor: "pointer" }} title='Go Back' onClick={() => history.push(`/Milestone/${projectId}`)}>
                            <ArrowBackIcon color="primary" titleAccess="Go Back" />
                        </IconButton>
                        <span style={{ font: '1rem', fontWeight: 'bold' }}>{`${projectDetails && projectDetails?.[0]?.name} [ ${projectDetails && projectDetails?.[0]?.description} ] - Gantt Chart`}</span>
                    </div>
                    <Box sx={{ display: 'flex', alignItem: 'center', columnGap: '0.5rem' }}>
                        <IconButton size='small' sx={{ cursor: "pointer" }} onClick={handleOpen}>
                            <FilterAltIcon sx={{ color: '#007fff' }} />
                        </IconButton>
                        {
                            allDates && allDates.length > 0 && (
                                <div className={styles.mode}>
                                    {/* <Input type="date" value={findDate} onChange={(e) => setFindDate(new Date(e.target.value))} min={maxDate} /> */}
                                    {Object.entries(ViewMode).filter((modeType) => modeType[0] != "QuarterDay" && modeType[0] != "HalfDay" && modeType[0] != "Hour").map((mode) => <Button size='small' key={mode[1]} className={viewMode == mode[1] ? styles.active : styles.btn} onClick={() => setViewMode(mode[0])} >{mode[0]}</Button>)}
                                </div>
                            )
                        }
                        <Button size='small' onClick={() => handleDownloadExcel()} >Download <DownloadIcon sx={{ marginLeft: " 10px", fontSize: '1.5rem' }} /></Button>
                        <LoadingButton title={generatePdf ? "Generating attachment" : 'Mail Gantt chart'} size='small' onClick={redirectToMail} loading={generatePdf}
                            sx={{
                                minWidth: '1rem',
                                '.MuiCircularProgress-root': {
                                    color: 'primary.main',
                                },
                            }}>
                            <EmailOutlinedIcon />
                        </LoadingButton>
                    </Box>
                </div>
                {
                    graphData && allDates && allDates.length > 0 ?
                        <div className={styles.ganttChartContainer}>
                            <Gantt
                                tasks={graphData && graphData}
                                ganttHeight={height - 200}
                                headerHeight={60}
                                TaskListHeader={TaskListHeader}
                                TaskListTable={TaskListColumn}
                                TooltipContent={TooltipContent}
                                rowHeight={30}
                                preStepsCount={1}
                                viewMode={viewMode}
                                viewDate={findDate}
                                columnWidth={columnWidth}
                            />
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '88vh' }}>
                            <div>
                                <h1>Milestones is not mapped</h1>
                                <p style={{ textAlign: 'center' }}>Please map milestones to view gantt chart</p>
                            </div>
                        </div>
                }

                <GanttChartMessageModal
                    isOpen={messageBoxModal.isOpen}
                    onClose={messageBoxModal.close}
                    modalData={messageBoxModal.modalData}
                />
            </div>
        </> : ganttChartFetch.match("ERROR") ?
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '88vh' }}>
                <div>
                    <h1>Milestones is not configured</h1>
                    <p style={{ textAlign: 'center' }}>Please configure milestones to view gantt chart</p>
                </div>
            </div>
            : <Box>
                <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'0.5rem',}}>
                    <Skeleton variant='rectangular' animation="pulse" width={"20%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                    <Skeleton variant='rectangular' animation="pulse" width={"30%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                </Box>
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
                <Skeleton variant='rectangular' animation="pulse" width={"100%"} height={"1.5rem"} sx={{ margin: '0.5rem 1rem' }} />
            </Box>
    );
};

// ----------------------------------------------------------------------------------

export default GanttChart;