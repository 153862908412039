
import { Box, Button, Dialog, DialogContent, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from '../../CostingCreateSystemLandingPage.module.scss';
import { useHistory } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../../api-services';
import { CostingCreateDrawer } from '../../CostingCreateDrawer';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import CostingScenerioSystemInsideBody from './CostingScenerioSystemInsideBody';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useBaseModal } from '../../../SearchPage/useBaseModal';
import { CurrencyChange } from '../../../../ViewModule/CostingView/CurrencyChange';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Params {
  projectId: any;
  projectName: any;
  scenarioIds: any;
  expandTable: any;
  initialScenarioId: any;
  vault_id: any;
  abbr: any;
  scenarioName: any;
  top_vault:any;
  inside_scenario_id:any;
}

interface ICostingScenerioSystemInsideLandingProps { }

const CostingScenerioSystemInsideLanding: React.FC<ICostingScenerioSystemInsideLandingProps> = (props) => {
  const { projectId, projectName, scenarioIds, expandTable, initialScenarioId, vault_id, abbr, scenarioName, top_vault,inside_scenario_id } = useRouteParams<Params>();
  const [infoAboutCostingSubsystem, setInfoAboutCostingSubsystem] = useState<any>({});
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [pasteVaultNoList, setPasteVaultNoList] = useState<any>([]);
  const [copyVaultNo, setCopyVaultNo] = useState<any>();
  const [copyVaultCategory, setCopyVaultCategory] = useState<any>();
  const [showCheckboxCopyClicked, setShowCheckboxCopyClicked] = React.useState<boolean>(false);
  const [reloadCostingData, setReloadCostingData] = useState<boolean>(false);
  const [assemblyPart, setAssemblyPart] = React.useState();
  const [category, setCategory] = React.useState();
  const [apiCallMain, setApiCallMain] = useState<boolean>(false);
  const [calculatorType, setCalculatorType] = React.useState<any>();
  const [ancestorSetFromAPI,setAncestorSetFromAPI] = React.useState<any>([]);
  // const [costingPasteWithDataLoader,setCostingPasteWithDataLoader] = useState<boolean>(false);
  // const [costingPasteWithoutDataLoader,setCostingPasteWithoutDataLoader] = useState<boolean>(false);
  // const [costingPasteWithDataDisable,setCostingPasteWithDataDisable] = useState<boolean>(false);
  // const [costingPasteWithoutDataDisable,setCostingPasteWithoutDataDisable] = useState<boolean>(false);
  const [count, setCount] = React.useState<any>(0);

  const currencyModal = useBaseModal();

  const regex = /_/g;

  useEffect(() => {
    API.get("cost/cost_info_commodity_desc/", { parent: vault_id, module: 16, top_vault:top_vault }, 0).then((res: any) => {
      setInfoAboutCostingSubsystem(res?.data);
    })
  }, [])

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <CostingCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );

  const handleClickBack = () => {
    sessionStorage.setItem(
      "nodeCostingScenario",
      JSON.stringify(ancestorSetFromAPI)

    );
    history.push(`/costingscenario/${projectId}/${projectName}/${top_vault}/${vault_id}/1/${abbr}/0`);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  // const handleCostingPasteData = (e: any, type: any) => {
  //   if (type == 'DataWithCostModel') {
  //     e.stopPropagation();
  //     swal({
  //       title: `Paste Data with Cost Model`,
  //       text: `Are you sure to overwrite ?`,
  //       icon: "warning",
  //       //@ts-ignore
  //       buttons: {
  //         cancel: "Cancel",
  //         permanent: {
  //           text: `Paste`,
  //           value: `DataWithCostModel`,
  //         },
  //       },
  //       closeOnClickOutside: false,
  //       closeOnEsc: false,
  //       className: "warning-swal",
  //     }).then(async (type: string) => {
  //       switch (type) {
  //         case "DataWithCostModel":
  //           setCostingPasteWithDataLoader(true);
  //           setCostingPasteWithoutDataDisable(true);
  //           API.post("/cost/costing_copy/", { source: copyVaultNo, target: pasteVaultNoList, multi_select: true, scenario:inside_scenario_id }, 0)
  //             .then((res: any) => {
  //               Swal.fire({
  //                 html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  //                           <br />
  //                           <p style="color:#007fff;">The data with cost model has been pasted.
  //                           </p>   
  //                         </div>`,
  //               });
  //               setCostingPasteWithDataLoader(false);
  //               setCostingPasteWithoutDataDisable(false);
  //               setPasteVaultNoList([]);
  //               setShowCheckboxCopyClicked(false);
  //               setReloadCostingData(!reloadCostingData);
  //             })
  //             .catch((err: any) => {
  //               console.log(err, "Unable to paste. Server Error");
  //             });
  //           break;
  //         default:
  //           swal(`Data with cost model saved from pasting.`, { icon: "error" });
  //       }
  //     });
  //   }
  //   else {
  //     e.stopPropagation();
  //     swal({
  //       title: `Paste with only Cost Model`,
  //       text: `Are you sure to overwrite ?`,
  //       icon: "warning",
  //       //@ts-ignore
  //       buttons: {
  //         cancel: "Cancel",
  //         permanent: {
  //           text: `Paste`,
  //           value: `DataWithOnlyCostModel`,
  //         },
  //       },
  //       closeOnClickOutside: false,
  //       closeOnEsc: false,
  //       className: "warning-swal",
  //     }).then(async (type: string) => {
  //       switch (type) {
  //         case "DataWithOnlyCostModel":
  //           setCostingPasteWithoutDataLoader(true);
  //           setCostingPasteWithDataDisable(true);
  //           API.post("/cost/costing_copy/", { source: copyVaultNo, target: pasteVaultNoList, multi_select_only_cost_model: true, scenario:inside_scenario_id }, 0)
  //             .then((res: any) => {
  //               Swal.fire({
  //                 html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  //                           <br />
  //                           <p style="color:#007fff;"> The cost model without data has been pasted.
  //                           </p>   
  //                         </div>`,
  //               });
  //               setCostingPasteWithoutDataLoader(false);
  //               setCostingPasteWithDataDisable(false);
  //               setReloadCostingData(!reloadCostingData);
  //               setPasteVaultNoList([]);
  //               setShowCheckboxCopyClicked(false);
  //             })
  //             .catch((err: any) => {
  //               console.log(err, "Unable to paste. Server Error");
  //             });
  //           break;
  //         default:
  //           swal(`Cost model without data saved from pasting`, { icon: "error" });
  //       }
  //     });
  //   }
  // }

  const handleChangeAssemblyPart = (event: any) => {
    setAssemblyPart(event.target.value);
  };


  const handleChangeCategory = (event: any) => {
    setCategory(event.target.value);
  };

  const handleChangeCalculatorType = (event: any) => {
    setCalculatorType(event.target.value);
  };

  const handleClearFilter = () => {
    setAssemblyPart(undefined);
    setCategory(undefined);
    setCalculatorType(undefined);
    setApiCallMain(!apiCallMain);
    handleCloseFilter();
  }

  const handleFilter = () => {
    setApiCallMain(!apiCallMain);
    handleCloseFilter();
  }

  return (
    <>
      {currencyModal.isOpen &&
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />}

      <Dialog
        open={openFilter}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFilter}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            // minWidth: '40vw',
            borderRadius: '10px',
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Filter
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseFilter} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Assembly/Part</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={assemblyPart || ''}
              onChange={handleChangeAssemblyPart}
              label="Assembly/Part"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={'Assembly'}>Assembly</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={'Part'}>Part</MenuItem>
            </Select>
          </FormControl>
          <h3 style={{ textAlign: 'center' }}>AND</h3>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Select Category</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={category || ''}
              onChange={handleChangeCategory}
              label="Select Category"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              {infoAboutCostingSubsystem && infoAboutCostingSubsystem?.data?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: '1rem' }} value={`${item?.category_name}`}>{item?.category_name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <h3 style={{ textAlign: 'center' }}>AND</h3>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel id="demo-simple-select-standard-label">Costing Type</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={calculatorType || ''}
              onChange={handleChangeCalculatorType}
              label="Costing Type"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={'bop'}>Bought-out part costing</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={'dc'}>Detailed costing</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
            <FilterAltOffIcon sx={{ mr: 1, cursor: 'pointer' }} titleAccess='Clear Filter' onClick={handleClearFilter} />
            <LoadingButton variant='contained' sx={{ p: 0 }} onClick={handleFilter}>Filter</LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseInfo}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            // minWidth: '40vw',
            borderRadius: '10px',
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Costing Information ({abbr})
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseInfo} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <DialogContent>
          <Table>
            <TableHead sx={{ backgroundColor: 'primary.light' }}>
              <TableRow>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                  Category Name
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                  Count
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                  Validated
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                  Completed
                </TableCell>
                <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                  Not Started
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoAboutCostingSubsystem && infoAboutCostingSubsystem?.data?.map((item: any) => {
                return (
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>{item?.category_name}</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'center' }}>{item?.count}</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'center' }}>{item?.validated}</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'center' }}>{item?.completed}</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', textAlign: 'center' }}>{item?.not_started}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Box sx={{ padding: "0 0.5rem", display: 'flex', alignItems: 'center' }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft} >
            <IconButton sx={{ cursor: "pointer", }} onClick={handleClickBack}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block' }}>Estimate Scenerio Cost ( {`${projectName} > ${scenarioName.replace(regex, "/")} > ${abbr}`} )</Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {/* {pasteVaultNoList?.length > 0 && <span>
              <LoadingButton sx={{ padding: '0 0.5rem', mr: '1rem' }} variant='contained' loading={costingPasteWithDataLoader} disabled={costingPasteWithDataDisable} onClick={(e: any) => handleCostingPasteData(e, 'DataWithCostModel')}>
                Paste with Data & Cost Model
              </LoadingButton>
              <LoadingButton sx={{ padding: '0 0.5rem', mr: '1rem' }} variant='contained' loading={costingPasteWithoutDataLoader} disabled={costingPasteWithoutDataDisable} onClick={(e: any) => handleCostingPasteData(e, 'DataWithOnlyCostModel')}>
                Paste with only Cost Model
              </LoadingButton>
            </span>} */}
            <Button
              variant="contained"
              className={styles.downloadBtn}
              size="small"
              endIcon={<ArrowDropDownIcon />}
              style={{ fontSize: "12px", marginRight: "1rem",minWidth: '3rem' }}
              onClick={() => currencyModal.open()}
            >
              {sessionStorage.getItem("costingtable")}
            </Button>
            <IconButton title='Filter'><FilterAltIcon sx={{ color: '#007fff', cursor: 'pointer' }} onClick={() => setOpenFilter(true)} /></IconButton>
           </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        {/* <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box> */}
        <Box sx={{ width: '100%', height: { lg: '86vh', xl: "90vh" } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">

          <CostingScenerioSystemInsideBody pasteVaultNoList={pasteVaultNoList} setPasteVaultNoList={setPasteVaultNoList} setCopyVaultNo={setCopyVaultNo} setCopyVaultCategory={setCopyVaultCategory} copyVaultCategory={copyVaultCategory} setShowCheckboxCopyClicked={setShowCheckboxCopyClicked} showCheckboxCopyClicked={showCheckboxCopyClicked} reloadCostingData={reloadCostingData} setReloadCostingData={setReloadCostingData} apiCallMain={apiCallMain} category={category} assemblyPart={assemblyPart} calculatorType={calculatorType} count={count} setAncestorSetFromAPI={setAncestorSetFromAPI}/>

        </Box>
      </Box>
    </>)
}

export default CostingScenerioSystemInsideLanding;