import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, Checkbox, Chip, Divider, FormControl, FormLabel, TextField, styled } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { SelectChangeEvent } from "@mui/material/Select";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { CreateFeatureFetchModal } from "./CreateFeatureFetchModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { CreateFeatureParamsInput } from "./CreateFeatureParamsInput";
import { CreateFeParamMultiselect } from "./CreateFeParamMultiselect";
import { AdditionalInput } from "./AdditionalInputs";
import videoAudio from "../../Assets/images/VideoAudio.svg";
import videoMute from "../../Assets/images/VideoMute.svg";
import videoAudiogreen from "../../Assets/images/VideoAudio-green.svg";
import videoMutegren from "../../Assets/images/VideoMute-green.svg";

import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import { useDispatch } from "react-redux";
import { increaseCounter } from "../../Redux/Actions/compareopen.action";
import { useHistory } from "react-router-dom";
import FeatureCreateImageModal from "./FeatureCreateImageModal";
import LoaderImage from "../../Assets/images/loading-small.gif";
import RViewerJS from "viewerjs-react";
import S3FeatureVideoUploadCreate from "./S3FeatureVideoUploadCreate";
import FeatureVideoRenderModal from "./FeatureVideoRenderModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
import { LibraryParamsInput } from "./LibraryParamsInput";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { LibraryParamsDropdownBoxModal } from "./LibraryParamsDropdownBoxModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface ImageUploadProps {
  groupName: any;
  featureName: any;
}

export interface IFeatureCreateImageUploadModalProps {
  onCloseModal: any;
  isOpen: any;
  response: any;
  closeParent: any;
  addFeatureAddData: any;
  getModule: any;


}

export function FeatureCreateImageUploadModal(props: IFeatureCreateImageUploadModalProps) {
  const { onCloseModal, isOpen, response, closeParent, addFeatureAddData, getModule } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const CreateFeatureFetch = useBaseParamsModal();
  const singleVideoRenderModal = useBaseParamsModal();
  // path:'/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber',

  const { projectId, projectname, type, parent, child, featureId, feId, pageNumber } = useRouteParams<any>();
  const [productList, setProductList] = React.useState<any>([]);
  const [availability, setAvailability] = React.useState<any>("No");
  const { height, width } = useWindowDimensions();
  const [isProductListLoading, setIsProductListLoading] = React.useState<any>(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const [allParamsData, setAllParamsData] = React.useState<any>({});
  const [imageUploadLoading, setImageUploadLoading] = React.useState<any>(false);
  const [videoUploadLoading, setVideoUploadLoading] = React.useState<any>(false);
  const [selectedId, setSelectedId] = React.useState<any>();
  const [imageLoadingId,setImageLoadingId]=React.useState<any>();
  const [videoLoadingId,setVideoLoadingId]=React.useState<any>();
  const [FeatureList, setFeatureList] = React.useState<any>([])
  const ErrorModalFiles = useBaseModal()
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [isMutee, setIsMutee] = React.useState<any>(false);
  const [value, setValue] = React.useState({});
  const [UserManualEamil, setUserManualEamil] = React.useState<any>({})
  const [prodId,setProdId]=React.useState<any>();
  // console.log(addFeatureAddData, "addFeatureAddDataaddFeatureAddData")
  const getProductList = () => {
    setIsProductListLoading(true);
    API.get("/xcpep/feature_product/", { project: projectId, abbreviation: response?.abbreviation }, 0)
      .then((res) => {
        setProductList(res?.data);
        setSelectedProduct(res?.data[0]?.id);
        // getFeatureParamsData(res?.data[0]?.id);
        setIsProductListLoading(false);
      })
      .catch((err) => {
        setIsProductListLoading(false);
      });
  };

  React.useEffect(() => {
    getProductList();
  }, [projectId]);


  React.useEffect(() => {
    if (addFeatureAddData && Object.keys(addFeatureAddData).length > 0) {
      API.get(`api/db/feature_name/`, { group_id: addFeatureAddData?.group, subsystem_abbreviation: addFeatureAddData?.abbreviation, vehicle_type: addFeatureAddData?.vehicle, feature_type: type == 1 ? 0 : type == 2 ? 1 : 2 }).then((res: any) => {

        setFeatureList(res?.data)
      }).catch((err: any) => {
        console.log("Server Error")
      })
    }
  }, [addFeatureAddData])

  const getFeatureParamsData = (prodId: any) => {
    setAllParamsData([]);
    API.get(
      "/xcpep/feature_details/",
      {
        feature: response?.feature,
        abbreviation: response?.abbreviation,
        feature_instance: prodId,
        page: 1,
      },
      0
    )
      .then((res: any) => {
        setAllParamsData(res.data[0]);
        setAvailability(res.data[0]?.details?.Avalability?.value);
      })
      .catch((err: any) => { });
  };
  const inputElRefPdf = React.useRef<any>();
  const inputElRefVideo = React.useRef<any>();
  const uploadImage = (idd: any, ImageId: any) => {
    setSelectedId(idd);
    setImageLoadingId(ImageId)
    return inputElRefPdf.current?.click?.();
  };
  const uploadVideo = (idd: any, ImageId: any, is_mute: any) => {
    setSelectedId(idd);
    setVideoLoadingId(ImageId)
    setIsMutee(is_mute)
    return inputElRefVideo.current?.click?.();
  };
  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };
  const handleInputClickVideo = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any, imageId: any) => {
    FeatureCreateImageModal([e.target.files[0]], imageLoadingId, setImageUploadLoading, projectId, allParamsData?.id, getFeatureParamsData, selectedProduct, dispatch);
  };
  const UploadFileVideo = (e: any, imageId: any, is_mute: any) => {
    // if (selectedId) {
    //   setUploadingImageId(imageId);
    S3FeatureVideoUploadCreate([e.target.files[0]], videoLoadingId, setVideoUploadLoading, projectId, allParamsData?.id, getFeatureParamsData, isMutee, selectedProduct, dispatch);
    // }
  };

  const selectProductFunc = (id: any) => {
    setSelectedProduct(id);
    // getFeatureParamsData(id);
  };
  React.useEffect(() => {
    if (selectedProduct) {
      getFeatureParamsData(selectedProduct);
    }
  }, [selectedProduct]);
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
        width: 100%;        
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.2;
        padding: 8px;
        border-radius: 10px;
        borer-color:'primary.light';
        font-family:'roboto';      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );

  const fetchHandler = (idd: any, imgId: any) => {
    CreateFeatureFetch.open({ paramId: idd, imgId: imgId });
  };
  const PartNameHandler = (item: any) => {


    setUserManualEamil(item)
  }





  const ManualEmailHandler = (e: any) => {

    if (e.key == "Enter") {
      {
        API.put(`/xcpep/product_feature/${allParamsData?.feature}/`, {
          name: UserManualEamil,
          subsystem_abbreviation: addFeatureAddData?.abbreviation,
        }).then((res1: any) => {
          API.get(`api/db/feature_name/`, { subsystem_abbreviation: addFeatureAddData?.abbreviation, vehicle_type: addFeatureAddData?.vehicle, feature_type: type == 1 ? 0 : type == 2 ? 1 : 2 }).then((res: any) => {

            setFeatureList(res?.data)
          }).catch((err: any) => {
            console.log("Server Error")
          })
        }).catch((err: any) => {
          const { data } = err?.response;
          ErrorModalFiles?.open()
          setErrorModalFileMsg(data)
        })

      }

    }

  }


  const PasteEmailHandler = (data: any) => {

    if (data) {

      API.put(`/xcpep/product_feature/${allParamsData?.feature}/`, {
        name: data,
        subsystem_abbreviation: addFeatureAddData?.abbreviation,
      }).then((res1: any) => {
        API.get(`api/db/feature_name/`, { subsystem_abbreviation: addFeatureAddData?.abbreviation, vehicle_type: addFeatureAddData?.vehicle, feature_type: type == 1 ? 0 : type == 2 ? 1 : 2 }).then((res: any) => {

          setFeatureList(res?.data)
        }).catch((err: any) => {
          console.log("Server Error")
        })
      }).catch((err: any) => {
        const { data } = err?.response;
        ErrorModalFiles?.open()
        setErrorModalFileMsg(data)
      })

      API.put(`/xcpep/product_feature/${allParamsData?.feature}/`, {
        name: data,
        subsystem_abbreviation: addFeatureAddData?.abbreviation,
      }).then((res1: any) => {
        API.get(`api/db/feature_name/`, { group_id: addFeatureAddData?.group, subsystem_abbreviation: addFeatureAddData?.abbreviation, vehicle_type: addFeatureAddData?.vehicle, feature_type: type == 1 ? 0 : type == 2 ? 1 : 2 }).then((res: any) => {

          setFeatureList(res?.data)
        }).catch((err: any) => {
          console.log("Server Error")
        })
      }).catch((err: any) => {
        const { data } = err?.response;
        ErrorModalFiles?.open()
        setErrorModalFileMsg(data)
      })

    }
  }

  const handleSelectChange = (touser: any) => {

    // @ts-ignore
    setValue(touser);
    {

      {

        API.put(`/xcpep/product_feature/${allParamsData?.feature}/`, {
          name: touser?.name,
          subsystem_abbreviation: addFeatureAddData?.abbreviation,
        }).then((res1: any) => {
          API.get(`api/db/feature_name/`, { group_id: addFeatureAddData?.group, subsystem_abbreviation: addFeatureAddData?.abbreviation, vehicle_type: addFeatureAddData?.vehicle, feature_type: type == 1 ? 0 : type == 2 ? 1 : 2 }).then((res: any) => {

            setFeatureList(res?.data)
          }).catch((err: any) => {
            console.log("Server Error")
          })
        }).catch((err: any) => {
          const { data } = err?.response;
          ErrorModalFiles?.open()
          setErrorModalFileMsg(data)
          setValue({})
        })

      }
    }
  };


  return (
    <div>
      {CreateFeatureFetch.isOpen && (
        <CreateFeatureFetchModal
          onCloseModal={CreateFeatureFetch.close}
          isOpen={CreateFeatureFetch.isOpen}
          itemId={CreateFeatureFetch.propsId}
          getFeatureParamsData={getFeatureParamsData}
          selectedProductt={selectedProduct}
        />
      )}

      <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem", justifyContent: "space-between" }}>
            <Typography
              style={{
                color: "red",
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginLeft: "1rem",
                marginRight: "-2rem",
              }}>
              <sup style={{ color: "red" }}>*</sup> Please Select the {getModule} name before any feature entry.

            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem", justifyContent: "space-between" }}>

            <Box sx={{ display: "flex", alignItems: 'center' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  // textAlign: "center",
                  marginLeft: "1rem",
                  marginRight: "0.5rem",
                }}>
                {allParamsData?.abbreviation || "loading.."} {"->"} {allParamsData?.group_name || "loading.."} {"->"}

              </Typography>
              {FeatureList &&
                <Autocomplete

                  // disableClearable
                  id="User"
                  value={value}
                  limitTags={6}
                  disableCloseOnSelect

                  sx={{ width: "18vw", }}

                  onInputChange={(event, newInputValue) => {
                    PartNameHandler(newInputValue)
                  }}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue);
                  }}

                  onPaste={(event: any) => {

                    PasteEmailHandler(event.clipboardData.getData('text'))

                  }}
                  options={FeatureList && FeatureList}
                  // @ts-ignore
                  getOptionLabel={(option) =>
                    // @ts-ignore
                    option?.name == undefined ? "" : option?.name

                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        //  @ts-ignore
                        // sx={{ color: "#007fff", background: `${option?.user_color}`, fontSize: '1rem' }}
                        // @ts-ignore
                        label={option?.name}
                        {...getTagProps({ index })}
                      // disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  onKeyDown={(e: any) => ManualEmailHandler(e)}

                  renderInput={(params) => (

                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Enter Name"
                      sx={{
                        width: "250px",
                        '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                        '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                        '.MuiSvgIcon-root': { color: 'primary.main' },

                      }}

                    />
                  )}
                />
              }
            </Box>
            <Box sx={{ display: "flex" }}>
              <FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginRight: '1rem', width: '10rem', }}>
                <Checkbox
                  style={{ padding: "3px" }}
                  onClick={() => {
                    if (availability === "No") {
                      API.put(`/xcpep/feature_details/${allParamsData?.id}/`, { is_feature: "Yes" }, {}, 0)
                        .then((res: any) => {
                          setAvailability("Yes");
                        })
                        .catch((err: any) => { });
                    } else {
                      API.put(`/xcpep/feature_details/${allParamsData?.id}/`, { is_feature: "No" }, {}, 0)
                        .then((res: any) => {
                          setAvailability("No");
                        })
                        .catch((err: any) => { });
                    }
                  }}
                  checked={availability === "Yes" ? false : true}
                  title={"Not Available"}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                />
                <FormLabel sx={{ color: 'primary.main', whiteSpace: 'nowrap' }}>{"Not Available"}</FormLabel>
              </FormControl>
              {/* @ts-ignore */}
              {value && value?.name &&
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={() => {
                    onCloseModal();
                    var random: any = Math.floor(Math.random() * 1000000 + 1);
                    dispatch(increaseCounter(random));
                    // @ts-ignore
                    if (value && value?.name !== undefined) {
                      // path:'/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber',
                      history.push(
                        // @ts-ignore
                        `/createfeature/${projectId}/${projectname}/${type}/${allParamsData?.abbreviation}/${allParamsData?.group}/${value && value?.name}/${allParamsData?.feature}/${pageNumber}`
                      );
                      closeParent();
                    } else {
                      history.push(
                        // @ts-ignore
                        `/createfeature/${projectId}/${projectname}/${type}/${allParamsData?.abbreviation}/${allParamsData?.group}/${value && value?.name}/${allParamsData?.feature}/${pageNumber}`
                      );
                    }
                  }}

                />
              }
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "0.2rem", width: "100%", padding: "0.2rem 0" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {productList &&
              productList?.map((item: any, index: any) => {
                return (
                  <>
                    <Chip
                      label={item.name}
                      variant="filled"
                      onClick={() => selectProductFunc(item?.id)}
                      sx={
                        selectedProduct === item?.id
                          ? {
                            backgroundColor: "primary.main", color: "white", padding: "0 2rem", ':hover': {
                              backgroundColor: 'primary.main', color: 'white',
                            }
                          }
                          : {
                            backgroundColor: "primary.light", color: "primary.main", padding: "0 2rem", ':hover': {
                              backgroundColor: 'primary.main', color: 'white',
                            }
                          }

                      }
                    />
                    {productList?.length - 1 !== index && <ArrowRightAltIcon />}
                  </>
                );
              })}
          </Box>
          {availability === "Yes" ? (
            <Box>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{ display: { xs: "none", sm: "flex" }, alignItems: "flex-start", justifyContent: "space-evenly", width: "40vw", height: { lg: "63vh", xl: '70vh' },borderRight:'1px solid',borderColor:'primary.light', }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                  <Box sx={{ width: "100%" }}>
                    <Box>
                      <Box
                        sx={{marginBottom: '0.5rem',borderBottom:'1px solid',borderColor:'primary.light' }} //height: type == 1 ? { lg: '31vh', xl: "35vh" } : "70vh"
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        <Box sx={{ display: "flex", alignItems: "center", padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%',fontWeight:'600', }}>
                          General Parameters
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%",backgroundColor:'#f7fbff'  }}>
                          <Box sx={{ display: "flex", alignItems: "center",  padding: "0.2rem 0.5rem", borderBottom: "none", width: { sm: "10rem", md: "12rem", lg: "15rem" } }}>
                            <Box sx={{ width: "100%" }}>
                              {allParamsData &&
                                allParamsData?.details?.Parameters?.map((item: any) => {
                                  return (
                                    <Box sx={{ width: "100%" }}>
                                      <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: item?.name === "Description" ? "8rem" : "3rem" }}>
                                        {item?.name}{item?.is_required && <sup style={{ color: "red" }}>*</sup>}
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", padding: "0.2rem 0.5rem", borderBottom: "none", width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              {allParamsData &&
                                allParamsData?.details?.Parameters?.map((item: any, index: any) => {
                                  return (
                                    <Box sx={{ width: "100%" }}>
                                      <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", whiteSpace: "nowrap", height: item?.data_type === "textarea" ? "8rem" : "3rem" }}>
                                        <CreateFeatureParamsInput
                                          value={item?.value}
                                          condition={value}
                                          paramsId={item?.id}
                                          parentId={allParamsData?.id}
                                          placeholderName={item?.name}
                                          dataType={item?.data_type}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{marginBottom: '0.5rem',borderBottom:'1px solid',borderColor:'primary.light'  }} //height: type == 1 ? { lg: '31vh', xl: "35vh" } : "70vh"
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        <Box sx={{ display: "flex", alignItems: "center", padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%',fontWeight:'600', }}>
                          Feature Parameters
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%",backgroundColor:'#fffdf7'  }}>
                          <Box sx={{ display: "flex", alignItems: "center",  padding: "0.2rem 0 0.2rem 0.5rem", borderBottom: "none", width: { sm: "10rem", md: "12rem", lg: "20rem",xl:'26rem' } }}>
                            <Box sx={{ width: "100%"}}>
                              {allParamsData &&
                                allParamsData?.details?.["Feature Library"]?.map((item: any) => {
                                  return (
                                    <Box sx={{ width: "100%" ,backgroundColor:item && item?.name_type == 'numerical' ? "#fffdf7" : "#f8f8ff" ,paddingRight:'0.5rem'}}>
                                      <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: item?.name === "Description" ? "8rem" : "3rem" }}>
                                      {item?.is_required &&  <sup style={{ color: "red" }}>*</sup>}
                                      <LibraryParamsDropdownBoxModal
                                      selectedData={item}
                                      allData={allParamsData && allParamsData[0]?.details["Feature Library"]}
                                      iddd={allParamsData && allParamsData?.id}
                                      getFeatureDataWOL={getFeatureParamsData}
                                      selectedProduct={selectedProduct}
                                      /> 
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Box>
                         { Object.keys(allParamsData)?.length>0 && [allParamsData]?.map((item:any,ind:any)=>{
                          return <Box sx={{ display: "flex", alignItems: "center", padding: "0.2rem 0.5rem 0.2rem 0", borderBottom: "none", width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            {item &&
                              item?.details && item?.details?.["Feature Library"]?.map((itm: any, index: any) => {
                                return (
                                  <Box sx={{ width: "100%",backgroundColor:itm && itm?.name_type == 'numerical' ? "#fffdf7" : "#f8f8ff" ,paddingLeft:'0.5rem' }}>
                                    <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", whiteSpace: "nowrap", height: item?.data_type === "textarea" ? "8rem" : "3rem" }}>
                                         <LibraryParamsInput
                                                  value={itm?.value}
                                                  paramsId={itm?.id}
                                                  parentId={item?.id}
                                                  placeholderName={itm?.name}
                                                  dataType={itm?.name_type=="text"?"longtext":"float"}
                                                  disabled={
                                                    false
                                                  }
                                                />                                       
                                    </Box>
                                  </Box>
                                );
                              })}
                          </Box>
                        </Box>
                         })}
                        </Box>
                      </Box>
                      {type == 1 && <Box>
                        <Box sx={{fontWeight:'600', padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%'}}>
                          Feature Cost
                        </Box>
                        <Box
                        sx={{ display: "flex", alignItems: "flex-start",backgroundColor:'#f7fff8'  }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">                        
                        <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%' }}>
                         
                            
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>Cost</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>
                                  <TextField
                                    placeholder="Feature Cost"
                                    variant="standard"
                                    disabled={true}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.total_cost}
                                    type="number"
                                    sx={{
                                      width: "100%",
                                      ".MuiInputBase-root.MuiInput-root": {
                                        fontSize: "1rem !important",
                                      },
                                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>System</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "system", values: allParamsData?.details?.["Feature Cost(INR)"]?.system, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>Assembly</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "assambly", values: allParamsData?.details?.["Feature Cost(INR)"]?.assembly, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>Part</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "parts", values: allParamsData?.details?.["Feature Cost(INR)"]?.part, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>Additional Part</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>
                                  <AdditionalInput
                                    condition={value}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.additional_part}
                                    paramsId={"additional_part"}
                                    parentId={allParamsData?.id}
                                    placeholderName={"Additional Part"}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%",display:'flex',alignItems:'flex-start',columnGap:'2rem' }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'13.5rem' }}>Additional Cost</Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem",width:'100%' }}>                                  
                                  <AdditionalInput
                                    condition={value}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.input_cost}
                                    paramsId={"input_cost"}
                                    parentId={allParamsData?.id}
                                    placeholderName={"Additional Cost"}
                                  />
                                </Box>
                              </Box>
                            
                        
                        </Box>
                        {/* <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box sx={{ width: "100%" }}>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>
                                  <TextField
                                    placeholder="Feature Cost"
                                    variant="standard"
                                    disabled={true}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.total_cost}
                                    type="number"
                                    sx={{
                                      width: "100%",
                                      ".MuiInputBase-root.MuiInput-root": {
                                        fontSize: "1rem !important",
                                      },
                                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "system", values: allParamsData?.details?.["Feature Cost(INR)"]?.system, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "assambly", values: allParamsData?.details?.["Feature Cost(INR)"]?.assembly, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>
                                  {allParamsData && (
                                    <CreateFeParamMultiselect
                                      condition={value}
                                      item={{ productId: allParamsData?.product, type: "parts", values: allParamsData?.details?.["Feature Cost(INR)"]?.part, itemId: allParamsData?.id }}
                                      key={allParamsData?.id}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>
                                  <AdditionalInput
                                    condition={value}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.additional_part}
                                    paramsId={"additional_part"}
                                    parentId={allParamsData?.id}
                                    placeholderName={"Additional Part"}
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <Box sx={{ padding: "0.2rem 0.5rem", borderBottom: "none", textAlign: "right", whiteSpace: "nowrap", height: "3rem" }}>                                  
                                  <AdditionalInput
                                    condition={value}
                                    value={allParamsData?.details?.["Feature Cost(INR)"]?.input_cost}
                                    paramsId={"input_cost"}
                                    parentId={allParamsData?.id}
                                    placeholderName={"Additional Cost"}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box> */}
                      </Box>
                      </Box>}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    // display: "grid",
                    // gridTemplateColumns:'1fr 1fr',     
                    display:'flex',              
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: '0 0.5rem',
                    width: { xs: "100%", sm: "25vw",lg:'40vw' },
                    height: { xs: "70vh", sm: "42vh", md: "60vh", lg: "63vh", xl: "70vh" },
                  }}>
                  {/* <Box sx={{borderRight:'1px solid',borderColor:'primary.light',height:'100%',width:'20vw'}}>
                     
                   </Box> */}
                   <Box sx={{height:'100%',width:'40vw'}}>
                      <Box sx={{width:'100%'}}>
                          <Box sx={{fontWeight:'600', padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%'}}>
                            Images
                          </Box>
                          <Box sx={{display:'grid',gridTemplateColumns:{lg:'1fr 1fr 1fr 1fr', xl:'1fr 1fr 1fr'},gap:'0.5rem',padding: '0 0.5rem',minHeight:{lg:'17vh',xl:'20vh'}, maxHeight: { xs: "70vh", sm: "42vh", md: "31vh", lg: "19vh", xl: "20vh" },}}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3">
                              {allParamsData && allParamsData?.details?.Image?.map((item:any)=>{
                                return <Box sx={{width: "95%",height:width > 1369 ? '13rem' : '10rem'}}>
                                {item?.value === "Not Available" ? (
                                  <Box sx={{ width: "100%", }}>   
                                    <Typography title={item?.name} sx={{fontSize:'1rem',textAlign:'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>{item?.name}</Typography>                             
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "column",xl: "row", },rowGap:'0.5rem', alignItems: "center", justifyContent: "space-evenly", width: "100%",padding:'0 0.5rem' }}>                                    
                                    {imageUploadLoading && selectedId===allParamsData?.id && imageLoadingId===item?.id ?  (
                                        <Image
                                          placeholderImg={LoadingImage}
                                          src={LoaderImage}
                                          style={{ width: "auto", height:width > 1369 ? "6rem" : "5.5rem", margin: width>1369? '2rem' : '' }}
                                        />
                                      ):(                                        
                                        <AddAPhotoIcon titleAccess="Upload Image"
                                          sx={{ fontSize: {lg:"4rem",xl:'6rem'}, cursor: "pointer",marginTop:width > 1369 ? '2rem':'0.9rem',marginBottom:width > 1369 ? '2rem':'0.9rem', }}
                                          onClick={() => uploadImage(allParamsData?.id, item?.id)}
                                        />
                                      )}
                                      <input
                                        id="PDF Upload"
                                        accept="image/*"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e: any) => UploadFile(e, item?.id)}
                                        onClick={handleInputClick}
                                        ref={inputElRefPdf}
                                      />                                  
                                      <Button
                                        variant="contained"
                                        // @ts-ignore
                                        disabled={value?.id ? false : true}
                                        onClick={() => fetchHandler(allParamsData?.id, item?.id)}
                                        sx={{ borderRadius: "3rem", padding: "0.2rem 0.5rem",marginTop:width > 1369 ? '0rem':'0rem',  }}>
                                        Fetch
                                      </Button>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ width: "95%",height:'100%' }}>
                                    <Typography title={item?.name} sx={{fontSize:'1rem',textAlign:'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>{item?.name}</Typography>                            
                                    {/* @ts-ignore */}
                                    <RViewerJS>
                                      <Image
                                        placeholderImg={LoadingImage}
                                        src={item?.value}
                                        style={{ objectFit: "contain", width: "100%", height: width > 1369 ? "9rem" : "6rem" }}
                                      />
                                    </RViewerJS>
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "row",xl: "row", }, alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                                      {!imageUploadLoading ? (
                                        <AddAPhotoIcon titleAccess="Replace Image"                                   
                                          sx={{ fontSize: "2rem",color:'green', cursor: "pointer", }}
                                          onClick={() => uploadImage(allParamsData?.id, item?.id)}
                                        />
                                      ) : (
                                        selectedId===allParamsData?.id && imageLoadingId===item?.id  && <Box sx={{ textAlign: 'center' }}>
                                          <Image
                                            placeholderImg={LoadingImage}
                                            src={LoaderImage}
                                            style={{ width: "auto", height: "3rem" }}
                                          />
                                        </Box>
                                      )}
                                      <input
                                        id="PDF Upload"
                                        accept="image/*"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e: any) => UploadFile(e, item?.id)}
                                        onClick={handleInputClick}
                                        ref={inputElRefPdf}
                                      />
                                      {/* <FileUploadIcon sx={{ fontSize: "3rem" }} /> */}
                                      <Button
                                        variant="contained"
                                        onClick={() => fetchHandler(allParamsData?.id, item?.id)}
                                        sx={{backgroundColor:'green', borderRadius: "1rem", padding: "0.1rem 0.5rem"  }}>
                                        Fetch
                                      </Button>
                                    </Box>
                                  </Box>
                                )}
                                </Box>
                              }) } 
                          </Box>
                      </Box>
                      <Box sx={{width:'100%'}}>
                          <Box sx={{fontWeight:'600', padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%'}}>
                            Videos
                          </Box>
                          <Box sx={{display:'grid',gridTemplateColumns:{lg:'1fr 1fr 1fr 1fr', xl:'1fr 1fr 1fr'},gap:'0.5rem',padding: '0 0.5rem',minHeight:{lg:'17vh',xl:'20vh'}, maxHeight: { xs: "70vh", sm: "42vh", md: "31vh", lg: "19vh", xl: "20vh" },}}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3">
                            {allParamsData && allParamsData?.details?.Video?.map((item:any)=>{
                              return <Box sx={{ width: "95%",height: width > 1369 ? '14rem' : '10rem' }}>                        
                                {item?.value === "Not Available" ? (<>
                                  <Typography title={item?.name} sx={{fontSize:'1rem',textAlign:'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>{item?.name}</Typography>
                                  <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "row",xl: "row", }, alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>                                    
                                    {videoUploadLoading && videoLoadingId===item?.id && selectedId===allParamsData?.id   ? (                                     
                                      <Image
                                        placeholderImg={LoadingImage}
                                        src={LoaderImage}
                                        style={{ width: "auto", height: width > 1369 ? "6rem" : "5.5rem" }}
                                      />
                                   ) : (
                                      <img
                                    src={videoMute}
                                    title="Upload without Audio"
                                    // @ts-ignore
                                    style={{cursor:'pointer',width: width > 1369 ? '6rem' : "5rem", height: width > 1369 ? '4rem' : "3rem",marginTop:width > 1369 ? '2rem':'1rem', opacity: value && value?.id ? 1 : 0.3 }}
                                    // @ts-ignore
                                    onClick={() => value && value?.id && uploadVideo(allParamsData?.id, item?.id, true)}
                                  />
                                    )}
                                    <input
                                      id="PDF Upload"
                                      accept="video/*"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e: any) => UploadFileVideo(e, item?.id, true)}
                                      onClick={handleInputClickVideo}
                                      ref={inputElRefVideo}
                                    />
                                    {!videoUploadLoading && (
                                      <img
                                        src={videoAudio}            
                                        title="Upload With Audio"
                                        // @ts-ignore
                                        style={{cursor:'pointer', width: width > 1369 ? '6rem' : "5rem", height: width > 1369 ? '4rem' : "3rem",marginTop:width > 1369 ? '2rem':'1rem', opacity: value && value?.id ? 1 : 0.3 }}
                                        // @ts-ignore
                                        onClick={() => value && value?.id && uploadVideo(allParamsData?.id, item?.id, false)}
                                      />
                                    )}
                                    <input
                                      id="PDF Upload"
                                      accept="video/*"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e: any) => UploadFileVideo(e, item?.id, false)}
                                      onClick={handleInputClickVideo}
                                      ref={inputElRefVideo}
                                    />
                                    {/* <FileUploadIcon sx={{ fontSize: "7rem" }} /> */}
                                  </Box></>
                                ) : videoUploadLoading&& videoLoadingId===item?.id && selectedId===allParamsData?.id ? (
                                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                                  <Image
                                    placeholderImg={LoadingImage}
                                    src={LoaderImage}
                                    style={{ width: "auto", height: "6rem" }}
                                  />
                                </Box>
                                  ) : (
                                   <Box sx={{ width: '100%', textAlign: 'center' }}>
                                    <>
                                  <Typography title={item?.name} sx={{fontSize:'1rem',textAlign:'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>Video Name</Typography>
                                  <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "row",xl: "row", }, alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>                                    
                                    {item?.thumbnail && (                                      
                                      <Image
                                        placeholderImg={LoadingImage}
                                        src={item?.thumbnail}
                                        style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", maxHeight: width > 1370 ? '9rem' : '5.5rem'}}
                                        onClick={() => singleVideoRenderModal.open(item)}
                                      />
                                    )}
                                  </Box>
                                  </>
                                  </Box>
            
                                )}
                                {item?.value !== "Not Available" && (
                                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", margin: "1rem 0" }}>
                                    <img
                                      src={videoMutegren}
                                      title="Replace without Audio"
                                      style={{cursor:'pointer', height: "2rem" }}
                                      onClick={() => uploadVideo(allParamsData?.id, item?.id, true)}
                                    />
                                    <input
                                      id="PDF Upload"
                                      accept="video/*"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e: any) => UploadFileVideo(e, item?.id, true)}
                                      onClick={handleInputClickVideo}
                                      ref={inputElRefVideo}
                                    />
                                    <img
                                      src={videoAudiogreen}
                                      title="Replace With Audio"
                                      style={{cursor:'pointer', height: "2rem" }}
                                      onClick={() => uploadVideo(allParamsData?.id, item?.id, false)}
                                    />
                                    <input
                                      id="PDF Upload"
                                      accept="video/*"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e: any) => UploadFileVideo(e, item?.id, false)}
                                      onClick={handleInputClickVideo}
                                      ref={inputElRefVideo}
                                    />
                                  </Box>
                                )}
                              </Box>
                            })}
                          </Box>
                      </Box>
                      <Box sx={{width:'100%'}}>
                          <Box sx={{fontWeight:'600', padding: "0.2rem 0.5rem", borderBottom: "none", width: '100%'}}>
                          Documents
                          </Box>
                          <Box sx={{display:'grid',gridTemplateColumns:{lg:'1fr 1fr 1fr 1fr', xl:'1fr 1fr 1fr'},gap:'0.5rem',padding: '0 0.5rem',minHeight:{lg:'14vh',xl:'20vh'}, maxHeight: { xs: "70vh", sm: "42vh", md: "31vh", lg: "14vh", xl: "20vh" },}}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3">
                             {allParamsData && allParamsData?.details?.Document?.map((item:any)=>{
                                return <Box sx={{width: "95%",height:width > 1369 ? '13rem' : '8rem'}}>
                                {item?.value === "Not Available" ? (
                                  <Box sx={{ width: "100%", }}>   
                                    <Typography title={item?.name} sx={{fontSize:'1rem',textAlign:'center',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>{item?.name}</Typography>                             
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "column",xl: "row", },rowGap:'0.5rem', alignItems: "center", justifyContent: "space-evenly", width: "100%",padding:'0 0.5rem' }}>                                    
                                    {imageUploadLoading && selectedId===allParamsData?.id && imageLoadingId===item?.id ?  (
                                        <Image
                                          placeholderImg={LoadingImage}
                                          src={LoaderImage}
                                          style={{ width: "auto", height: "6rem" }}
                                        />
                                      ):(
                                        <NoteAddIcon titleAccess="Upload Document" sx={{fontSize:{lg:"4rem",xl:'6rem'},cursor:'pointer',marginTop:'1rem',}}
                                          onClick={() => uploadImage(allParamsData?.id, item?.id)}
                                        />
                                      )}
                                       <input
                                      id="PDF Upload"
                                      accept=".doc,.docx,.pdf,.odt,.rtf,.txt,image/*"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(e: any) => UploadFile(e, item?.id)}
                                      onClick={handleInputClick}
                                      ref={inputElRefPdf}
                                    />                                  
                                     
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box sx={{ width: "95%",height:'100%',alignItems:'center',
                                  display: 'flex',
                                  // justifyContent: 'center',
                                  flexDirection: 'column',
                                  rowGap: '1rem' }}>
                                    <span title={item?.name} style={{fontSize:'1rem',textAlign:'center',alignItems:'center',cursor:'pointer',color:'green',textDecoration:'underline',}}
                                     onClick={() => {
                                              window.open(
                                                item?.value,
                                                "_blank",
                                                "noopener noreferrer"
                                              );
                                            }}>{item?.name}</span>                           
                                   
                                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row",lg: "row",xl: "row", }, alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
                                      {imageUploadLoading && selectedId===allParamsData?.id && imageLoadingId===item?.id  ? (
                                        
                                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                                          <Image
                                            placeholderImg={LoadingImage}
                                            src={LoaderImage}
                                            style={{ width: "auto", height: "3rem" }}
                                          />
                                        </Box>
                                      ) : (
                                        <NoteAddIcon titleAccess="Replace Image"                                   
                                          sx={{ fontSize:{lg:"4rem",xl:'6rem'},color:'green', cursor: "pointer" }}
                                          onClick={() => uploadImage(allParamsData?.id, item?.id)}
                                        />
                                      )}
                                      <input
                                        id="PDF Upload"
                                        accept=".doc,.docx,.pdf,.odt,.rtf,.txt,image/*"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e: any) => UploadFile(e, item?.id)}
                                        onClick={handleInputClick}
                                        ref={inputElRefPdf}
                                      />                                     
                                    </Box>
                                  </Box>
                                )}
                                </Box>
                              }) } 
                          </Box>
                      </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center", width: "100%", minHeight: { lg: "70vh" } }}>Feature does not exist</Box>
          )}         
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Button disabled={allParamsData?.prev ? false : true} onClick={() => {
              setAllParamsData([]);
              API.get(
                "/xcpep/feature_details/",
                {
                  feature: allParamsData?.prev,
                  abbreviation: allParamsData?.abbreviation,
                  feature_instance: selectedProduct,
                  page: 1,
                },
                0
              )
                .then((res: any) => {
                  setAllParamsData(res.data[0]);
                  setAvailability(res.data[0]?.details?.Avalability?.value);
                })
                .catch((err: any) => { })
            }}>Previous</Button>
            <Button disabled={allParamsData?.next !== null ? false : true} onClick={() => {
              setAllParamsData([]);
              API.get(
                "/xcpep/feature_details/",
                {
                  feature: allParamsData?.next,
                  abbreviation: allParamsData?.abbreviation,
                  feature_instance: selectedProduct,
                  page: 1,
                },
                0
              )
                .then((res: any) => {
                  setAllParamsData(res.data[0]);
                  setAvailability(res.data[0]?.details?.Avalability?.value);
                })
                .catch((err: any) => { });
            }} >Next</Button>
          </Box>
        </Box>
      </Modal>
      {singleVideoRenderModal.isOpen && (
        <FeatureVideoRenderModal
          isOpen={singleVideoRenderModal.isOpen}
          onCloseModal={singleVideoRenderModal.close}
          url={singleVideoRenderModal.propsId}
        />
      )}
    </div>
  );
}
