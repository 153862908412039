import React, { useEffect, useState } from "react";
import styles from "./IdeaEntryMiddlePortion.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { DialogComponent } from "../../../utlis/DialogComponent";
import calcIcon from '../../../Assets/images/calculator.svg';
import useFetch from "../../../Core/CustomHooks/useFetch";
import { ICalculator } from "../../../Redux/Reducers/calculator.reducer";
import calculatorService from "../../../Redux/Services/calculator.service";
import IdeaCalculatorValueInputModal from "../../IdeaCreate/IdeaView/IdeaCalculatorValueInputModal";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

interface Params {
  projectId: any;
  topVaultId: any;
  abbreviation: any;
  vaultId: any;
  ideaId: any;
}

interface IdeaEntryMiddlePortionProps { }

const IdeaEntryMiddlePortion: React.FC<IdeaEntryMiddlePortionProps> = (
  props
) => {
  const { ideaId } = useRouteParams<Params>();
  const [ideaData, setIdeaData] = useState<any>([]);
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const [ideaDataTable, setIdeaDataTable] = useState<any>([]);
  const [ideaDataSuccess, setIdeaDataSuccess] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ideaIdGet, setIdeaIdGet] = useState<any>();
  const [CostTableData, setCostTableData] = React.useState<any>([])
  const [CalculatorItem, setCalculatorItem] = React.useState<any>()
  const [CounterTest, setCounterTest] = React.useState<any>(0)
  const inputModal = useBaseModal()
  const openDialogModal = useBaseModal();
  const [calculatorName, setCalculatorName] = React.useState<
    string | undefined
  >(undefined);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePDFReport = () => {
    handleClose();
  }

  const handleExcelReport = async (idea_id: any) => {
    handleClose();
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          idea_id: idea_id,
          individual_type: "Idea"
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {
    }
  }


  const calcultorRoute = (id: any) => {
    API.get(`/idea/idea_cost_calculation/`, { idea_id: ideaId, config_id: id?.id, calculation: true }, 0).then((res: any) => {
      setCalculatorName(res?.data?.calculator)
      setCounterTest((prev: any) => prev + 1)
      setCalculatorItem(id)

    })


    inputModal.open()
  }



  const { 0: gettingCalvalue, 1: calculator } = useFetch<ICalculator>({
    method: calculatorService.getByIdeadCalcultorIds,
    args: [calculatorName],
    dependencies: [calculatorName, CounterTest],
    condition: calculatorName !== undefined,
  })

  React.useEffect(() => {

    if (ideaId != 0) {

      setCostTableData([])
      API.get(`/idea/idea_cost_model/`, { idea_id: ideaId }, 0).then((res: any) => {

        setCostTableData(res.data)

      }).catch((err: any) => {

      })
    }
  }, [ideaId])

  useEffect(() => {
    setDataLoad(true);
    API.get(
      `customer/idea_data/`,
      { get_idea_details: ideaId, get_data: "data" },
      0
    )
      .then((res: any) => {
        setDataLoad(false);
        setIdeaData(res?.data);
        setIdeaDataSuccess(false);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Error while fetching idea data");
      });
    API.get(
      `customer/idea_data/`,
      { get_idea_details: ideaId, get_data: "table" },
      0
    )
      .then((res: any) => {
        setDataLoad(false);
        setIdeaDataTable(res?.data);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Error while fetching idea data");
      });
  }, [ideaId]);
  console.log(CostTableData, "CostTableDataCostTableData")

  return (
    <div className={styles.container}>
      <>


        {inputModal.isOpen &&
          <IdeaCalculatorValueInputModal
            isOpen={inputModal.isOpen}
            onClose={inputModal.close}
            fetchCalculator={gettingCalvalue}
            CalculatorItem={CalculatorItem}
            calculatorData={calculator}
            IdeaType={"Client"}

          />
        }
        {!dataLoad ? (
          <>
            {/* <Button
              style={{ float: 'right' }}
              className="btnlogin"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() => handleExcelReport(ideaId)}
              size="small">
              <span style={{ fontSize: '1rem', fontWeight: '500', }}> Download</span> <DownloadIcon />
            </Button> */}
            
            {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              ".MuiList-root.MuiMenu-list": {
                padding: 0,
              }
            }}>
            <MenuItem sx={{fontSize:'1rem'}} onClick={handleExcelReport}><img src={excelIcon} style={{ width: '1.5rem', paddingRight: '5px' }} /> Individual Idea </MenuItem>
            
          </Menu> */}
            <Table>
              <TableHead
                className={styles.tableTitleRow}
                sx={{ backgroundColor: "primary.light" }}
              >
                <TableRow>
                  <TableCell
                    className={styles.tableTitleCell}
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                  >
                    Parameter
                  </TableCell>
                  <TableCell
                    className={styles.tableTitleCell}
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ideaData &&
                  ideaData?.map((item: any, index: any) => {
                    return (
                      <TableRow>
                        <TableCell className={styles.tableBodyCell}>
                          <div className={styles.title}>
                            <span>{item?.parameter_name}</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.tableBodyCell}>
                          <div className={styles.value}>
                            <span style={{ whiteSpace: "pre-line" }}>
                              {item?.description}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
{
  CostTableData && CostTableData?.length>0 && CostTableData?.filter((item:any,index:any)=>{return item?.result!=0})?.length>0 &&

            <><h4 style={{ marginBottom: "0rem" }}>
                Cost Model
              </h4><Table>
                  <TableHead
                    className={styles.tableTitleRow}
                    sx={{ backgroundColor: "primary.light" }}
                  >
                    <TableRow>
                      <TableCell
                        className={styles.tableTitleCell}
                        sx={{ color: "primary.main", fontSize: "1rem" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className={styles.tableTitleCell}
                        sx={{ color: "primary.main", fontSize: "1rem" }}
                      >
                        Action
                      </TableCell>
                      <TableCell
                        className={styles.tableTitleCell}
                        sx={{ color: "primary.main", fontSize: "1rem" }}
                      >
                        Result
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CostTableData &&
                      CostTableData?.map((item: any, index: any) => {

                        return (
                          item?.result != 0 &&
                          <TableRow>
                            <TableCell className={styles.tableBodyCell}>
                              <div className={styles.title}>
                                <span>{item?.name}</span>
                              </div>
                            </TableCell>


                            <TableCell className={styles.tableBodyCell}>
                              <img src={calcIcon}
                                title="Calculator"
                                style={{ cursor: item?.calculator_status ? "pointer" : "not-allowed", height: '1.4rem', opacity: item?.calculator_status == false ? "0.3" : "" }}
                                onClick={() => item?.calculator_status && calcultorRoute(item)} />
                            </TableCell>

                            <TableCell className={styles.tableBodyCell}>
                               
                                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                                                        <CurrencyRupeeIcon titleAccess="rupee" sx={{ fontSize: '1.5rem' }} />
                                                        {item?.result}
                                                       
                                                    
                                                    </Box>
                                              
                              
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table></>
}
            {ideaDataTable &&
              ideaDataTable?.map((item: any) => {
                let Header: any = Object.keys(item?.[Object.keys(item)[0]][0]);
                return (
                  <div style={{ paddingBottom: "1rem" }}>
                    <h4 style={{ marginBottom: "0rem" }}>
                      {Object.keys(item)[0]}
                    </h4>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Header &&
                            Header?.map((itemInner: any) => {
                              return (
                                <TableCell
                                  style={{ color: "#646464", fontSize: "1rem" }}
                                >
                                  {itemInner}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.[Object.keys(item)[0]]?.map((valInner: any) => {
                          return (
                            <TableRow>
                              {Header &&
                                Header?.map((itemInnerTwo: any) => {
                                  return (
                                    <TableCell
                                      sx={{
                                        color: "#007fff",
                                        fontSize: "1rem",
                                        padding: '0.2rem 1rem',
                                        borderBottomColor: 'primary.light',
                                      }}
                                    >
                                      {valInner?.[itemInnerTwo]}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                );
              })}


          </>
        ) : (
          <Box sx={{ height: "100%", }}>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            {/* sx={{ display: "flex", rowGap: "4rem",flexDirection:"row",height:'3rem' }}> */}
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
            <Box
              sx={{ display: "flex", columnGap: "3rem", alignItems: "center" }}
            >
              <Skeleton sx={{ height: "5rem", width: "40%" }} />
              <Skeleton sx={{ height: "5rem", width: "100%" }} />
            </Box>
          </Box>
        )}
      </>
      <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={"We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"} type={"firstdownload"} />
    </div>
  );
};
export default IdeaEntryMiddlePortion;
