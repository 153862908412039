import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  commodityId:any;
  counterApiCall?:any;
}

function AddProcessModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal,commodityId,counterApiCall } = props;
  const { library } = useRouteParams<any>();
  const [processList, setProcessList] = React.useState<any>([]);
  const [selectedProcess, setSelectedProcess] = React.useState<any>([]);
  const history = useHistory<any>();

  const handleSelectChangeProcess = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setSelectedProcess(value);
  };
  const getProcessList = () => {
    API.get(
      "/config/costing_configuration/",
      {
        get_library:"process_library",
        commodity_mapping:commodityId
      },
      0
    )
      .then((res: any) => {
        setProcessList(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(()=>{
    getProcessList()
  },[])

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Add Process
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "2rem", textAlign: "center" }}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink sx={{color:'primary.main'}} id="demo-simple-select-label">Add Process</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedProcess && selectedProcess}
                multiple
                multiline
                //   label="User"
                placeholder="Select Products"
                onChange={handleSelectChangeProcess}
                size="small"
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {processList
                      .filter((item: any) => selected?.includes(item?.id))
                      ?.map((value: any) => (
                        <Chip
                          size="small"
                          key={value.id}
                          label={value.name}
                          onDelete={() => {
                            if (selectedProcess.length > 1) {
                              setSelectedProcess((prev: any) =>
                                prev?.filter((item: any) => value.id !== item)
                              );
                            }
                          }}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {processList &&
                  processList?.map((item: any, index: number) => {
                    return (
                      !selectedProcess?.includes(item?.id) && (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      )
                    );
                  })}
              </Select>
            </FormControl>
            <Box sx={{textAlign:'right',width:'100%'}}>
            <LoadingButton
              size="small"
              variant="contained"
              sx={{ marginTop: "1rem" }}
              onClick={() => {
                API.post("/config/costing_configuration/",{
                    id:commodityId,
                    add_data:"commodity_process_map",
                    all_process:selectedProcess.toString()
                },{},0).then((res:any)=>{
                  counterApiCall((prev:any)=>prev+1)
                  onCloseModal()
                }).catch((err:any)=>{
                })
              }}
            >
              Add
            </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddProcessModal;
