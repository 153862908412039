import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Box,
  Skeleton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { API } from "../api-services";
import RViewerJS from "viewerjs-react";

import styles from "./BomModule.module.scss";
import { useParams } from "react-router-dom";
import FallbackImg from "../Assets/images/loading-screen.gif";
import Image from "../ViewModule/FeatureLandingPage/ArchitectureImageCard";

export interface BomModuleProps {}

const BomModule = (props: BomModuleProps) => {
  const [BomData, setBomData] = useState<any>();

  const [S3URI, setS3URI] = useState<string | null>(FallbackImg);
  const [BomPparameter, setBomparameter] = useState<any>();
  const { projectId, topVault, vault, treeabbr, leafNode, viewmodule } =
    useParams<any>();
  const [Loader, setLoader] = useState<any>(false);
  // Div width
  const widthref = React.useRef(null);
  const [Customwidth, setCustomwidth] = React.useState(0);
  const [ModuleCount, setModuleCount] = useState<any>();

  const toggleTopvault = () => {
    if (topVault !== 0) {
      if (treeabbr == "product") {
        return { top_vault: topVault };
      } else {
        return { vault: vault };
      }
    }
  };

  useEffect(() => {
    API.get(
      "customer/view_data_module/",
      { ...toggleTopvault(), module_count: true },
      0
    ).then((res: any) => {
      setModuleCount(res.data);
    });
  }, [vault]);

  useEffect(() => {
    if (topVault != 0) {
      setLoader(true);
        API.get(
        "customer/view_data/",
        {
          bom: true,
          ...toggleTopvault(),
        },
        0
      )
        .then((res: any) => {
          setBomparameter(res?.data?.numeric);
          setLoader(false);
          setBomData(res?.data);
        })
        .catch((err: any) => {
          setLoader(false);
          // ADMIN.toast.throwError(err, "Server error in Bom module");
        });
    }
  }, [vault, topVault]);

  const BomImages = () => {
    let BomImageArr: any = [];
    if (BomData["image"] !== undefined && !Loader) {
      for (const [key, value] of Object.entries(BomData && BomData["image"])) {
      
        BomImageArr.push(
          <div className={styles.bomImagesCard}>
            {/* <BomImageViewer imageData={value} ModuleCount={ModuleCount} /> */}
            
            <Image
            // @ts-ignore
              src={value}
              title={key}
              alt={key}
              
              placeholderImg={FallbackImg}
              className={styles.bomImagesIn}
              // style={{
              //   width:
              //     Customwidth < 250
              //       ? "6vw"
              //       : Customwidth < 300
              //       ? "7.2vw"
              //       : Customwidth < 400
              //       ? "7.2vw"
              //       : Customwidth > 500
              //       ? "7vw"
              //       : "6vw",
              //   height:
              //     Customwidth < 250
              //       ? "6vw"
              //       : Customwidth < 300
              //       ? "7vw"
              //       : Customwidth < 400
              //       ? "7vw"
              //       : Customwidth > 500
              //       ? "7vw"
              //       : "6vw",
              //   objectFit: "contain",
              //   cursor: "pointer",
              // }}
            />
          </div>
        );
      }
      return BomImageArr;
    }
  };

  const BomParameter = () => {
    let BomDatas: any = [];

    if (BomData["numeric"] !== undefined) {
      for (let [key, parameter] of Object.entries(
        BomData && BomData["numeric"]
      )) {
        BomDatas.push(
          // @ts-ignore
          <TableRow className={styles.bomParamet}>
            <TableCell
              sx={{
                padding: "0.3rem 0.5rem",
                fontSize: "12px",
                borderBottomColor: "primary.light",
                color: "primary.main",
              }}
            >
              {key}
            </TableCell>
            <TableCell
              style={{
                padding: "0.3rem 0.5rem",
                fontSize: "12px",
                borderBottomColor: "#ddefff",
                color: "primary.main",
              }}
            >
              {/* @ts-ignore */}
              {parameter && parameter}
            </TableCell>
          </TableRow>
        );
      }

      return BomDatas;
    }
  };

  //Div Width
  React.useEffect(() => {
    // when the component gets mounted
    // @ts-ignore
    setCustomwidth(widthref?.current?.offsetWidth);
    // to handle page resize
    const getwidth = () => {
      // @ts-ignore
      setCustomwidth(widthref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, [widthref]);

  return (
    <div>
      {Loader ? (
        // <SubBouncingLoader />
        <div>
        <Skeleton animation="pulse" height={300} />
        <Skeleton animation="pulse" height={300} />
        </div>
      ) : (
        <div>
          <Box
            ref={widthref}
            sx={{height:'40vh'}}
            className={
              ModuleCount && ModuleCount?.valid_module_count > 0
                ? styles.BomImagesSec
                : styles.BomImageflex
            }
            data-columns={ModuleCount && ModuleCount?.valid_module_count}
          >
            <Box
              className={styles.BomImage}             
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {/* @ts-ignore */}
              <RViewerJS className={styles.imagegrid}>
                {BomData && BomData["image"] && BomImages()}
              </RViewerJS>
            </Box>
          </Box>
          <div
            className={styles.bom_parameter}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {BomData && BomData["numeric"] && (
              <Table sx={{ position: "sticky", top: "0" }}>
                <TableHead sx={{ position: "sticky", top: "0" }}>
                  <TableRow sx={{ backgroundColor: "primary.main" }}>
                    <TableCell
                      sx={{ padding: "0.3rem 0.5rem", fontSize: "12px" }}
                      colSpan={2}
                    >
                      <Typography style={{ fontSize: "12px" }} color="white">
                        Parameters
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {BomData && BomData["numeric"] && BomParameter()}  
             </TableBody> 
              </Table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default BomModule;
