import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { API } from '../../api-services';

import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel"

import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import { AddParameterDataBaseModal } from './AddParameterDataBaseModal';
import { useParams } from 'react-router-dom';
import { ParameterlibraryInfoModal } from './ParameterlibraryInfoModal';
import { Divider } from '@mui/material';
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  height: '90vh',
  bgcolor: 'background.paper',
  borderRadius: "10px",
  p: 1,
};
interface TableParameterModalProps {


  isOpen: any;
  onCloseModal: any;
  Item: any;
  setCounter: any;
}

export const TableParameterModal = (props: TableParameterModalProps) => {
  const { isOpen,
    onCloseModal, Item, setCounter } = props
  const { datatype } = useParams<any>()
  const [TableRows, setTableRows] = useState<any>([]);
  const parameterModal = useBaseModal()
  const parameterModalEdit = useBaseModal()
  const [loader, setLoader] = useState<any>(false);
  const [rows, setrows] = useState<any>()
  const infoModal = useBaseModal()
  const [TableCounter, setTableCounter] = useState<any>(0)
  const AddParameter = () => {
    parameterModal.open()
  }


  const EditHandler = (item: any) => {
    parameterModalEdit.open()
    setrows(item)

  }

  const InfoHandler = (item: any) => {
    infoModal?.open()
    setrows(item)
  }
  useEffect(() => {
    if (Item !== 0) {
      setLoader(true)
      setTableRows([])
      API.get(`/api/db/parameter/`, { table_id: Item }).then((res: any) => {
        setTableRows(res?.data)
        setLoader(false)
      }).catch((err: any) => [
        setLoader(false)
      ])
    }
  }, [Item, TableCounter])

  const DeleteHanlder = (id: any) => {
    swal({
      title: "Delete",
      text: "Are sure you want to delete ?",
      buttons: ["Cancel", "Ok"],
      // icon: "sucess",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/parameter/${id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1);
          API.get(`/api/db/parameter/`, { table_id: Item }).then((res: any) => {
            setTableRows(res?.data)
            setLoader(false)
          }).catch((err: any) => [
            setLoader(false)
          ])
        });
      }
    });
  };


  const columnsFiled = [
    { field: "id", hide: true },
    { field: "Ids", headerName: "Sl No.", width: 50 },
    {
      field: "name",
      headerName: "Parameter Name",
      minWidth: 250,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "data_type",
      headerName: "Data Type",
      minWidth: 180,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.description == null
              ? "_"
              : cellValues.row.description}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 100,
      renderCell: (cellValues: any) => {
        return <>{cellValues.row.unit == "" ? "_" : cellValues.row.unit}</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "exceptions",
      headerName: "Exceptions",
      minWidth: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.exceptions?.length == 0
              ? "_"
              : cellValues?.row?.exceptions.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "allowed_values",
      headerName: "Allowed Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.allowed_values?.length == 0
              ? "_"
              : cellvalues?.row?.allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "not_allowed_values",
      headerName: "Prohibited  Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.not_allowed_values?.length == 0
              ? "_"
              : cellvalues?.row?.not_allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },

    {
      field: "default_value",
      headerName: "Default Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.default_value == ""
              ? "_"
              : cellvalues?.row?.default_value}
          </>
        );
      },
    },
    {
      field: "info_doc",
      headerName: "Guideline",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.info_doc == null ? (
              "_"
            ) : (
              <VisibilityIcon
                sx={{ cursor: "pointer" }}
                onClick={() =>
                {
                  API.get("/api/db/parameter/",{
                    download_doc:true,
                    id:cellvalues.row.id
                  },0).then((res:any)=>{
                    window.open(res.data, "_blank")
                  }).catch((err:any)=>{})
                }
                  
                }
              />
            )}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "mapped_status",
      headerName: "Status",
      minWidth: 80,

      renderCell: (cellValues: any) => {
        return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.created_by == null
              ? "_"
              : cellValues.row.created_by}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "updated_on",
      headerName: "Last Updated On",
      minWidth: 150,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "actions",
      headerName: "Action",
      minWidth: 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
          {cellvalues?.row?.mapped_status ? (
              <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InfoHandler(cellvalues?.row)} />
            ) : (
              <DeleteIcon
                onClick={() => DeleteHanlder(cellvalues?.row?.id)}
                titleAccess="Delete"
                sx={{ cursor: "pointer" }}
              />
            )}
            {cellvalues?.row?.editable && <EditIcon titleAccess="Edit" sx={{ cursor: "pointer" }}

              onClick={() => EditHandler(cellvalues?.row)}

            />}

            
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
  ]
  var RowData = TableRows && TableRows?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }))
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Database Table Parameter
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          {parameterModal.isOpen &&
            <AddParameterDataBaseModal
              Modaltype={"tableAdd"}
              isOpen={parameterModal.isOpen}
              onCloseModal={parameterModal.close}
              datatype={datatype}
              TableIds={Item}
              setTableCounter={setTableCounter}
              extraType={"filterList"}



            />}
          {infoModal.isOpen &&
            <ParameterlibraryInfoModal

              isOpen={infoModal.isOpen}
              onCloseModal={infoModal.close}
              Item={rows}
            />
          }

          {parameterModalEdit.isOpen &&
            <AddParameterDataBaseModal
              Modaltype={"tableEdit"}
              isOpen={parameterModalEdit.isOpen}
              onCloseModal={parameterModalEdit.close}
              datatype={datatype}

              rows={rows}
              setTableCounter={setTableCounter}



            />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              padding: '0 1rem',
              width: '92%',
              marginBottom: '-1.5rem',

            }}>
            <Button
              onClick={() => AddParameter()}
              sx={{ cursor: "pointer",position:'relative',zIndex:1 }}
              size='small'
              startIcon={

                <>
                  <AddIcon />
                </>
              }
              variant="contained">
              ADD Parameter
            </Button>
          </Box>
          <Box sx={{ width: "90vw", height: "80vh" }}>
            {RowData && (
              <DataGrid
                headerHeight={48}
                rowHeight={40}
                rows={RowData} columns={columnsFiled} loading={loader}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                sx={{
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-cell': {
                    borderBottomColor: 'primary.light',
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },

                }}
                disableSelectionOnClick={true}
                hideFooter={true}
                hideFooterPagination={true}
              />
            )}


          </Box>
        </Box>
      </Modal>
    </div>
  )
}