import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Divider, FormControl, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dummy from "../Assets/images/imageIcon.png";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import S3WeightImage from "./S3weightImage";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";

export interface ICheckSystemWeightandVerifyModalProps {
  onCloseModal: any;
  isOpen: any;
  setCounter?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '80vw', sm: "50vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function CheckSystemWeightandVerifyModal(props: ICheckSystemWeightandVerifyModalProps) {
  const { onCloseModal, isOpen, setCounter } = props;
  const { projectId } = useRouteParams<any>();
  const [products, setProducts] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [selectedSystem, setSelectedSystem] = React.useState<any>("");
  const [system, setSystem] = React.useState<any>([]);
  const [weight, setWeight] = React.useState<any>("");
  const [userInputWeight, setUserInputWeight] = React.useState<any>("");
  const [userPic, setUserPic] = React.useState<any>("");
  const [userPicKey, setUserPicKey] = React.useState<any>();
  const inputElRef = React.useRef<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();

  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };
  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setUserPicKey(e.target.files[0]);
      // USER.updateImage( e.target.files[0], userProfilePic && userProfilePic[0]?.id )
      // .then( ( res: any ) => {
      setUserPic(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProduct(event.target.value);
    API.get("/xcpep/dispatch/", { get_all_subsystems: event.target.value }, 0)
      .then((res: any) => {
        setSystem(res.data);
      })
      .catch((err: any) => { });
  };
  const handleSystemChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedSystem(value);
    API.get("/xcpep/dispatch/", { get_subsystem_weight: value }, 0)
      .then((res: any) => {
        setWeight(res.data[0]?.weight);
      })
      .catch((err: any) => { });
  };
  const handleChangeUserWeight = (event: any) => {
    const { value } = event.target;
    setUserInputWeight(value);
  };

  const getProducts = () => {
    API.get("/xcpep/dispatch/", { get_all_products: projectId }, 0)
      .then((res: any) => {
        setProducts(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getProducts();
  }, []);
  function calculatePercentageDifference(a: any, b: any) {
    const percentage = (b / a) * 100;

    const percentageDifference = 100 - percentage;

    return percentageDifference;
  }

  const verifyHandler = () => {
    setLoading(true)
    S3WeightImage([userPicKey], selectedSystem, userInputWeight, setCounter, setLoading, onCloseModal);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Check System Weight and Verify
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ height: "fit-content", padding: "0 1rem" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label">
                    Select Product <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProduct}
                    label="Age"
                    onChange={handleChange}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                    }}>
                    {products &&
                      products?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "1rem" }}
                            value={item?.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {selectedProduct !== "" && (
                  <FormControl
                    fullWidth
                    variant="standard">
                    <InputLabel
                      sx={{ color: "primary.main" }}
                      shrink
                      id="demo-simple-select-label">
                      Select System <sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSystem}
                      label="Age"
                      onChange={handleSystemChange}
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                          fontSize: "1rem",
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                      }}>
                      {system &&
                        system?.map((itm: any) => {
                          return (
                            <MenuItem
                              sx={{ fontSize: "1rem" }}
                              value={itm?.id}>
                              {itm?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {/* <div className={styles.errormsg}>{formik.touched.revision_type && Boolean(formik.errors.revision_type) && <p>{formik.errors.revision_type}</p>}</div> */}
                  </FormControl>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: { xs: 'column', sm: 'row' }, columnGap: "1rem" }}>
                <Box sx={{ width: { xs: '100%', sm: "20vw" }, height: "30vh", textAlign: "center" }}>
                  <img
                    src={userPic || dummy}
                    style={{ width: width > 1400 ? "25rem" : width > 600 ? "20rem" : '17rem', height: "auto", maxHeight: width > 1400 ? '19.5rem' : '15rem', borderRadius: "10px", cursor: "pointer", objectFit: 'contain' }}
                    onClick={browseFiles}
                  />
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="photo"
                    style={{ display: "none" }}
                    onChange={handleImg}
                    ref={inputElRef}
                  //  disabled={ type === "to_user" ? true : false }
                  />
                  <Typography sx={{ fontSize: "1rem", width: "100%", textAlign: "center" }}>System Weight Image<sup style={{ color: "red" }}>*</sup></Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: "2rem", justifyContent: "center", height: "30vh", width: "100%" }}>
                  <FormControl
                    fullWidth
                    variant="standard">
                    <TextField
                      variant="standard"
                      placeholder="Input measured system weight before packing"
                      // autoFocus={autoFocusStatus}
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      type="number"
                      value={userInputWeight}
                      onChange={handleChangeUserWeight}
                      // InputLabelProps={{
                      //     shrink: true,
                      // }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                        "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="Input measured system weight before packing"
                      name="Input measured system weight before packing"
                    />
                  </FormControl>
                  <Typography sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: { xs: '6rem', sm: "8rem" } }}>
                      <Typography sx={{ fontSize: "1rem" }}>BOM Weight</Typography>
                      <Typography sx={{ fontSize: "1rem" }}>:</Typography>
                    </Typography>
                    {weight !== "" && <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}> {weight} grams</Typography>}
                  </Typography>
                  <Typography sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: { xs: '6rem', sm: "8rem" } }}>
                      <Typography sx={{ fontSize: "1rem" }}>Difference</Typography>
                      <Typography sx={{ fontSize: "1rem" }}>:</Typography>
                    </Typography>
                    {userInputWeight && (
                      <Typography sx={{ fontSize: "1rem", lineHeight:'1'}}>
                        {calculatePercentageDifference(weight, userInputWeight) > 1 ? (
                          <span style={{ color: "red" }}>
                            {userInputWeight} grams ({calculatePercentageDifference(weight, userInputWeight).toFixed(2)}%)
                          </span>
                        ) : (
                          (calculatePercentageDifference(weight, userInputWeight) >= -1 && (
                            <span style={{ color: "green" }}>
                              {userInputWeight} grams ({Math.sign(calculatePercentageDifference(weight, userInputWeight)) === -1 ? Math.abs(calculatePercentageDifference(weight, userInputWeight))?.toFixed(2) : calculatePercentageDifference(weight, userInputWeight).toFixed(2)}%)
                            </span>
                          )) || <span style={{ textDecoration: "none", color: "red" }}>Input weight is more then actual weight</span>
                        )}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={calculatePercentageDifference(weight, userInputWeight) >= -1 && calculatePercentageDifference(weight, userInputWeight) < 1 && userPic?.length > 0 ? false : true}
                  onClick={() => verifyHandler()}
                  sx={{
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}>
                  Verify
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
