// react

import React, { Fragment, useContext, useEffect, useRef } from "react";

import { useAppDispatch } from "../../../../../../../AppRouter";

import { useSelector } from "react-redux";

import { RootState } from "../../../../../../Redux/Store/storeConfigurations";

import { inputCalculationSelectors } from "../../../../../../Redux/Reducers/inputCalculation.reducer";

import { isNumber } from "lodash";

import {
  CALCULATOR_INPUT_TYPE,
  calculatorInputBackgroundColorMapView,
} from "../../../../../../Configuration/Costing/calculator.constant";

import { toExponential } from "../../../../../../utlis/toExponential";

import TableRow from "@mui/material/TableRow";

import { Box, IconButton, Switch, TableCell, TextField } from "@mui/material";
import { ID } from "../../../../../../utlis/commonInterfaces";

import dbFetch from "../../../../../../Assets/images/database_fetch.svg";

// vendors

// import { Icon, Input, InputOnChangeData, TableCell, TableRow } from 'semantic-ui-react';

// import { useSelector } from 'react-redux';

// import { isNumber } from 'lodash';

// import clsx from "classnames";

import FlagIcon from "@mui/icons-material/Flag";

import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

// // services

// // utils

// import { toExponential } from 'app/utils/toExponential';

// // constants

// import { calculatorInputBackgroundColorMap, CALCULATOR_INPUT_TYPE } from 'app/constants/calculator.constants';

// // factories

// // core

// // redux

// import { RootState } from 'app/Redux/storeConfigurations';

// import { inputCalculationSelectors } from 'app/Redux/Reducers/inputCalculation.reducer';

// import { flaggedAssumptionSelectors } from 'app/Redux/Reducers/flaggedAssumption.reducer';

// import { createFlaggedAssumption, deleteFlaggedAssumption } from 'app/Redux/Actions/flaggedAssumption.actions';

// // ui-components

// // others

// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';

// import { useAppDispatch } from 'AppRouter';

// // styles

import styles from "./CalculatorRow.module.scss";

import { flaggedAssumptionSelectors } from "../../../../../../Redux/Reducers/flaggedAssumption.reducer";

import { createFlaggedAssumption } from "../../../../../../Redux/Actions/flaggedAssumption.actions";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import InfoIcon from "@mui/icons-material/Info";

import RViewerJS from "viewerjs-react";
import { userPermission } from "../../../CostigngCalculatorSection";
import { useRouteParams } from "../../../../../../ui-reusable-component/useRouteParams";

import { API } from "../../../../../../api-services";
import { useHistory } from "react-router-dom";
import RuleRoundedIcon from "@mui/icons-material/RuleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { useBaseParamsModal } from "../../../../../../CustomHook/useBaseParamsModal";
import FetchedBucketInfo from "./FetchedBucketInfo";
import BucketInfoIcon from "../../../../../../Assets/images/svgs/infobucket.svg";
import Swal from "sweetalert2";
// // interfaces and types

// import { ID } from 'app/commonInterfaces';

// lazy

// ----------------------------------------------------------------------------------

interface CalculatorRowProps {
  inputCalculationId: ID;
  setUpdateCosting?:any,
  updateCosting?:any,

  openDatabaseModal: (database: any, calIds?: any) => void;

  strokeDatabaseModal: (database: number) => void;
  Defaultdata: any;
  openTable: (inputType: number, parameterId: ID) => void;

  openCalculatorResult?: (calculatorCategory: number) => void;

  onValueChange: (value: any) => void;

  userInputValue: number;

  ConstantValue: any;

  onConstValueChange: (value: any) => void;

  openDatabaseInfoModal: (database: number, recordId: ID) => () => void;

  openStrokeDatabaseInfoModal?: (database: number, recordId: ID) => () => void;

  openFlaggedAssumptionFileModal: (flaggedAssumptionId: ID) => void;

  getToggleIds: any;

  userInputId: ID;

  constantIds: any;

  setSelectedId: any;

  getoggleconst: any;

  Infohaandler: any;

  setCurrencyDBExistCheck?: any;
  isWeightInput: any;
  calculatorData?: any;
  costModelCalculatorId?: any;
  openFetchBOMTableCalc?: any;
  dataOBj?: any;
  handleSave?: any;
  PermissionData: any;
  inputList?: any;
  setInputList?: any;
  status?: any;
}

const CalculatorRowConfig: React.FC<CalculatorRowProps> = (props) => {
  const {
    inputCalculationId,

    openDatabaseModal,

    strokeDatabaseModal,

    openTable,

    Infohaandler,

    openCalculatorResult,

    onValueChange,

    getToggleIds,

    userInputValue,

    ConstantValue,

    onConstValueChange,

    openDatabaseInfoModal,

    openStrokeDatabaseInfoModal,

    userInputId,

    constantIds,

    setSelectedId,
    Defaultdata,

    openFlaggedAssumptionFileModal,

    getoggleconst,

    setCurrencyDBExistCheck,
    isWeightInput,
    calculatorData,
    costModelCalculatorId,
    openFetchBOMTableCalc,
    dataOBj,
    handleSave,
    PermissionData,
    setInputList,
    inputList,
    status,
    setUpdateCosting,
    updateCosting
  } = props;

  // const readonly = useCostingReadonly();

  const dispatch = useAppDispatch();
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    conditionPopup,
  } = useRouteParams<any>();

  const userAction = useContext<any>(userPermission);
  const [CalcData, setCalcData] = React.useState<any>(
    calculatorData && calculatorData
  );

  const infoModal = useBaseParamsModal();
  const history = useHistory<any>();
  var datavalue = Math.random();

  // ---------------------------------------------------------------------------------

  // selector

  const inputCalculation: any = useSelector((state: RootState) => {
    return inputCalculationSelectors.selectById(state, inputCalculationId);
  });

  const imageViewerElRef = useRef<any>();

  const flaggedAssumption = useSelector((state: RootState) => {
    return flaggedAssumptionSelectors.selectByInputId(
      state,
      inputCalculationId
    );
  });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // ----------------------------------------------------------------------------------

  // handlers

  const handleInputChange = (e: any) => {
    onValueChange(Number(e?.target?.value));
  };

  const handleConstant = (e: any) => {
    onConstValueChange(Number(e?.target?.value));
  };

  const getDisableStatus = (data: any) => {
    if (data?.bucket) {
      return true;
    } else if (PermissionData && !PermissionData?.action?.includes("C")) {
      return true;
    } else if (PermissionData && !PermissionData?.action?.includes("U")) {
      return true;
    } else {
      return false;
    }
  };

  const handleDatabaseModalOpen = () => {
    if (!inputCalculation?.database) return;

    openDatabaseModal(inputCalculation.database, inputCalculation);
  };

  const handleStroke = () => {
    if (!inputCalculation?.database) return;

    strokeDatabaseModal(inputCalculation?.database);
  };

  React.useEffect(() => {
    setCalcData(CalcData);
  }, [CalcData]);

  useEffect(() => {
    if (
      (inputCalculation?.type === 5 || inputCalculation?.type === 6) &&
      inputCalculation?.field_custom_validation?.condition !== null &&
      inputCalculation?.field_custom_validation?.condition !== undefined
      // &&
      // inputCalculation?.value !== null &&
      // inputCalculation?.value !== undefined
    ) {
      const condition = inputCalculation?.field_custom_validation?.condition;
      const validationValue = inputCalculation?.field_custom_validation?.value;
      const targetValue =
        inputCalculation?.type === 6
          ? userInputValue
          : toExponential(inputCalculation?.value);

      let isValid = false;

      // Step 2: Check the condition and validate
      switch (condition) {
        case "lessThan":
          isValid = targetValue >= validationValue;
          break;
        case "greaterThan":
          isValid = targetValue <= validationValue;
          break;
        case "lessThanEqual":
          isValid = targetValue > validationValue;
          break;
        case "greaterThanEqual":
          isValid = targetValue < validationValue;
          break;
        case "equality":
          isValid = targetValue !== validationValue;
          break;
        default:
          // If condition is not one of the specified values, do nothing
          return;
      }

      if (isValid) {
        const targetInput = { ...inputCalculation, value: targetValue };
        // Step 3: Update the input list if the condition is satisfied
        setInputList((prevInputs: any) => [...prevInputs, targetInput]);
      }
    }
  }, [inputCalculation]);

  const handledClicked = (e: any, inputCalculation: any) => {
    API.put(
      `cost/material/${tableId}/`,
      {
        weight_input: inputCalculation?.value,
        weight_cal_id: costModelCalculatorId,
        cal_field_id: inputCalculation?.id,
      },
      0
    )
      .then((res: any) => {
        history.push(
          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}/${conditionPopup}/true`
        );
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleTableFieldModalOpen = () => {
    if (!inputCalculation?.parameter) return;

    openTable(inputCalculation.type, inputCalculation.parameter);
  };

  const handleCalculatorResultModaOpen = () => {
    const category = inputCalculation?.calculator_category;

    if (!isNumber(category)) return;

    // openCalculatorResult( category );
  };

  const handleInputFlag = async () => {
    if (!inputCalculation) return;

    if (!flaggedAssumption) {
      const action: any = await dispatch(
        createFlaggedAssumption({
          inputId: inputCalculation.id,

          userInputId,
        })
      );

      if (action.payload.flaggedAssumption) {
        openFlaggedAssumptionFileModal(action.payload.flaggedAssumption.id);
      }
    }

    handleFlaggedFileModal();
  };

  const handleFlaggedFileModal = () => {
    if (!flaggedAssumption) return;

    // @ts-ignore

    openFlaggedAssumptionFileModal(flaggedAssumption.id);
  };

  // ----------------------------------------------------------------------------------

  // jsx abstractions

  const openFile = (file: any) => {
    window.open(file, "_blank");
  };

  const handleChange = (value: any) => {
    // @ts-ignore

    if (constantIds && constantIds?.includes(value)) {
      var filterdata = constantIds?.filter((item: any, index: any) => {
        return item !== value;
      });

      getToggleIds(value, inputCalculation?.value, ConstantValue, false);

      setSelectedId((prev: any) =>
        prev.filter((item: any, index: any) => {
          return item !== value;
        })
      );
    } else {
      var data = [...constantIds, value];

      getToggleIds(value, inputCalculation?.value, ConstantValue, true);

      setSelectedId((prev: any) => [...prev, value]);
    }
  };

  const handleShowValidationMsg = (msg: any) => {
    Swal.fire({
      title: "Validation Mismatch",
      text: `${msg}`,
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#3085d6",
    });
  };

  const renderError = (validation: any) => {
    if (!validation?.condition) return;

    // return (
    //   <>hi</>
    // )

    const targetValue =
      inputCalculation?.type === 6
        ? userInputValue
        : toExponential(inputCalculation?.value);

    if (targetValue == null || targetValue == undefined) return null;

    const errorMessage =
      validation?.condition === "lessThan"
        ? targetValue >= validation?.value
          ? `It should be less than ${validation?.value}`
          : ""
        : validation?.condition === "greaterThan"
        ? targetValue <= validation?.value
          ? `It should be greater than ${validation?.value}`
          : ""
        : validation?.condition === "lessThanEqual"
        ? targetValue > validation?.value
          ? `It should be less than equal to ${validation?.value}`
          : ""
        : validation?.condition === "greaterThanEqual"
        ? targetValue < validation?.value
          ? `It should be greater than equal to ${validation?.value}`
          : ""
        : validation?.condition === "equality"
        ? targetValue !== validation?.value
          ? `It should be ${validation?.value}`
          : ""
        : "";

    return errorMessage ? (
      <span style={{ color: "red", fontSize: "0.9rem" }}>
        <InfoIcon
          sx={{
            color: "#d1261d",
            fontSize: "1.25rem",
            marginTop: "0.5rem",
            cursor: "pointer",
          }}
          onClick={() => {
            handleShowValidationMsg(errorMessage);
          }}
        />
      </span>
    ) : null;
  };

  const renderValue = () => {
    if (!inputCalculation) return null;

    // console.log(inputCalculation.type);

    switch (inputCalculation.type) {
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <>
            <TextField
              type="number"
              disabled={getDisableStatus(inputCalculation) || status == false}
              autoFocus={true}
              // name={ inputCalculation.id }

              value={userInputValue}
              onChange={handleInputChange}
              variant="standard"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },

                // '& .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.light' },

                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </>
        );

      case CALCULATOR_INPUT_TYPE.CONSTANT:
        if (constantIds?.includes(String(inputCalculationId))) {
          return (
            <TextField
              type="number"
              disabled={
                ((PermissionData && PermissionData?.action?.includes("C")) ||
                (PermissionData && PermissionData?.action?.includes("U"))
                  ? false
                  : true) || status == false
              }
              // name={ inputCalculation.id }

              value={ConstantValue}
              onChange={handleConstant}
              variant="standard"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },

                // '& .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.light' },

                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          );
        } else {
          return toExponential(inputCalculation?.constant || 0);
        }

      case CALCULATOR_INPUT_TYPE.FORMULA:
        return <>{toExponential(inputCalculation.value || 0)}</>;

      default:
        return <>{toExponential(inputCalculation.value || 0)}</>;
    }
  };

  const renderIcon = () => {
    if (!inputCalculation) return null;
    switch (inputCalculation.type) {
      case CALCULATOR_INPUT_TYPE.CONSTANT:
        return (
          <>
            {((PermissionData && PermissionData?.action?.includes("C")) ||
              (PermissionData && PermissionData?.action?.includes("U"))) && (
              <>
                {constantIds?.includes(String(inputCalculationId)) &&
                  ConstantValue != null && (
                    <InfoIcon
                      onClick={() => status && Infohaandler(inputCalculationId)}
                      sx={{
                        cursor: status == false ? "default" : "pointer",
                        color: status == false ? "#00000061" : "inherit",
                      }}
                    />
                  )}
              </>
            )}

            {flaggedAssumption?.reference ? (
              <FlagIcon
                titleAccess="View Flag"
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
                onClick={status && handleInputFlag}
              />
            ) : (
              <FlagOutlinedIcon
                titleAccess="Upload Flag"
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
                onClick={status && handleInputFlag}
              />
            )}

            {Boolean(inputCalculation?.image_url) && (
              <CameraAltIcon
                titleAccess="camera"
                // disabled={inputCalculation.image_url ? false : true}

                onClick={() => {
                  return status && imageViewerElRef.current?.click?.();
                }}
                style={
                  !inputCalculation.image_url || status == false
                    ? { cursor: "not-allowed", color: "#00000061" }
                    : { cursor: "pointer", color: "inherit" }
                }

                // className={clsx({

                //     [styles.hide]: permissionsCheck.includes("C") || permissionsCheck.includes("U") || permissionsCheck.includes("D") ? false : true && !flaggedAssumption,

                //     [styles.disabled]: permissionsCheck.includes("C") || permissionsCheck.includes("U") || permissionsCheck.includes("D") ? false : true

                // })}
              />
            )}
            {/* {
              inputCalculation.bucket && <img src={BucketInfoIcon} title="Reusable parameter fetched info" style={{ width: "1.5rem", height: "1.5rem", color: 'primary.main',cursor:'pointer' }} onClick={()=>{
                infoModal.open(inputCalculation.bucket_info)
              }}/>
            } */}

            {((PermissionData && PermissionData?.action?.includes("C")) ||
              (PermissionData && PermissionData?.action?.includes("U"))) && (
              <Switch
                size="small"
                // @ts-ignore

                title={
                  constantIds?.includes(String(inputCalculationId))
                    ? "Change to Constant"
                    : "Change to Variable"
                }
                checked={
                  constantIds?.includes(String(inputCalculationId))
                    ? true
                    : false
                }
                disabled={status == false}
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
                onChange={() => handleChange(String(inputCalculationId))}
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </>
        );

      // case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:

      // // case CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:

      //     if ( inputCalculation.table_element ) return (

      //         <FolderIcon

      //             // name="window maximize"

      //             // link

      //             sx={{ cursor: "pointer" }}

      //             onClick={handleTableFieldModalOpen}

      //         // className={ clsx( { [styles.hide]: readonly } ) }

      //         />

      //     );

      //     return null;

      case CALCULATOR_INPUT_TYPE.DATABASE:
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {
              <>
                {Boolean(userInputValue) &&
                  isNumber(inputCalculation.database) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}
                    >
                      <InfoIcon
                        sx={{
                          cursor: status == false ? "default" : "pointer",
                          color: status == false ? "#00000061" : "inherit",
                        }}
                        titleAccess="info"
                        onClick={
                          inputCalculation &&
                          status == true &&
                          openDatabaseInfoModal(
                            inputCalculation.database,
                            userInputValue
                          )
                        }
                      />

                      {flaggedAssumption?.reference ? (
                        <FlagIcon
                          titleAccess="View Flag"
                          sx={{
                            cursor: status == false ? "default" : "pointer",
                            color: status == false ? "#00000061" : "inherit",
                          }}
                          onClick={status && handleInputFlag}
                        />
                      ) : (
                        <FlagOutlinedIcon
                          titleAccess="Upload Flag"
                          sx={{
                            cursor: status == false ? "default" : "pointer",
                            color: status == false ? "#00000061" : "inherit",
                          }}
                          onClick={status && handleInputFlag}
                        />
                      )}

                      {Boolean(inputCalculation?.image_url) && (
                        <CameraAltIcon
                          titleAccess="camera"
                          // disabled={inputCalculation.image_url ? false : true}

                          onClick={() => {
                            return (
                              status && imageViewerElRef.current?.click?.()
                            );
                          }}
                          style={
                            !inputCalculation.image_url || status == false
                              ? { cursor: "not-allowed", color: "#00000061" }
                              : { cursor: "pointer", color: "inherit" }
                          }
                          sx={{
                            cursor: status == false ? "default" : "pointer",
                            color: status == false ? "#00000061" : "inherit",
                          }}
                        />
                      )}

                      {inputCalculation?.db_file && (
                        <FlagIcon
                          titleAccess="View DB Machine Cost"
                          onClick={() =>
                            status && openFile(inputCalculation.db_file)
                          }
                          style={
                            !inputCalculation.db_file
                              ? { cursor: "not-allowed", color: "green" }
                              : { cursor: "pointer", color: "green" }
                          }
                          sx={{
                            cursor: status == false ? "default" : "pointer",
                            color: status == false ? "#00000061" : "inherit",
                          }}
                        />
                      )}

                      {inputCalculation?.db_spcification_file && (
                        <FlagIcon
                          titleAccess="View DB Machine Specification"
                          onClick={() =>
                            status &&
                            openFile(inputCalculation?.db_spcification_file)
                          }
                          style={
                            !inputCalculation?.db_spcification_file
                              ? { cursor: "not-allowed", color: "green" }
                              : { cursor: "pointer", color: "green" }
                          }
                          sx={{
                            cursor: status == false ? "default" : "pointer",
                            color: status == false ? "#00000061" : "inherit",
                          }}
                        />
                      )}
                      {
                        // inputCalculation.bucket && <img src={BucketInfoIcon} title="Reusable parameter fetched info" style={{ width: "1.5rem", height: "1.5rem", color: 'primary.main',cursor:'pointer' }} onClick={()=>{
                        //   infoModal.open(inputCalculation.bucket_info)
                        // }}/>
                      }
                    </Box>
                  )}
                {((PermissionData && PermissionData?.action?.includes("C")) ||
                  (PermissionData &&
                    PermissionData?.action?.includes("U"))) && (
                  <img
                    src={dbFetch}
                    style={{
                      height: "1.5rem",
                      cursor: status == false ? "default" : "pointer",
                      opacity: status == false ? 0.25 : 1,
                    }}
                    title="Revisions"
                    onClick={
                      inputCalculation && status && handleDatabaseModalOpen
                    }
                  />
                )}

                {Boolean(userInputValue) &&
                  isNumber(inputCalculation.database) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}
                    >
                      <CancelIcon
                        sx={{
                          cursor: status == false ? "default" : "pointer",
                          color: status == false ? "#00000061" : "inherit",
                        }}
                        titleAccess="info"
                        onClick={() => {
                          if (
                            Object.keys(dataOBj?.user_input)?.includes(
                              inputCalculation?.id?.toString()
                            ) &&
                            status
                          ) {
                            let tempArr = Object.keys(
                              dataOBj?.user_input
                            )?.filter(
                              (item: any) =>
                                item != inputCalculation?.id?.toString()
                            );
                            let resObj: any = {};
                            tempArr?.map((item: any) => {
                              resObj[item] = dataOBj?.user_input[item];
                            });
                            API.put(
                              `/cost/calculation/${dataOBj?.id}/`,
                              { user_input: resObj },
                              {},
                              0
                            )
                              .then((res: any) => {
                                handleSave();
                              })
                              .catch((err: any) => {
                                console.log("Server error");
                              });
                          }
                        }}
                      />
                    </Box>
                  )}
              </>
            }
          </Box>
        );

      // case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:

      //     return (

      //         // <button

      //         //     name="window maximize"

      //         //     onClick={ handleCalculatorResultModaOpen }

      //         //     // className={ clsx( { [styles.hide]: readonly } ) }

      //         // />

      //         <FolderIcon

      //         sx={{cursor:"pointer"}}

      //         onClick={ handleCalculatorResultModaOpen }

      //         />

      //     );

      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <Fragment>
            {flaggedAssumption?.reference ? (
              <FlagIcon
                titleAccess="View Flag"
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
                onClick={status && handleInputFlag}
              />
            ) : (
              <FlagOutlinedIcon
                titleAccess="Upload Flag"
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
                onClick={status && handleInputFlag}
              />
            )}

            {Boolean(inputCalculation?.image_url) && (
              <CameraAltIcon
                titleAccess="camera"
                // disabled={inputCalculation.image_url ? false : true}

                onClick={() => {
                  return status && imageViewerElRef.current?.click?.();
                }}
                style={
                  !inputCalculation.image_url || status == false
                    ? { cursor: "not-allowed", color: "#00000061" }
                    : { cursor: "pointer", color: "inherit" }
                }
                sx={{
                  cursor: status == false ? "default" : "pointer",
                  color: status == false ? "#00000061" : "inherit",
                }}
              />
            )}
            {inputCalculation.bucket && (
              <img
                src={BucketInfoIcon}
                title="Reusable parameter fetched info"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color: "primary.main",
                  cursor: "pointer",
                  opacity: status == false ? 0.25 : 1,
                }}
                onClick={() => {
                  status && infoModal.open(inputCalculation.bucket_info);
                }}
              />
            )}
          </Fragment>
        );

      case CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR:
        return (
          <Fragment>
            {inputCalculation?.content_type === "BOMTableCalculator" &&
              inputCalculation?.table_element_type ===
                "Individual row parameter without auto fetch" && (
                <IconButton
                  onClick={() => {
                    status && openFetchBOMTableCalc(inputCalculation);
                  }}
                >
                  <RuleRoundedIcon
                    titleAccess="Fetch BOM Table Calculator Result"
                    // disabled={inputCalculation.image_url ? false : true}

                    style={{
                      cursor: status == false ? "default" : "pointer",
                      color: status == false ? "#00000061" : "#007fff",
                    }}
                  />
                </IconButton>
              )}
            {inputCalculation.bucket && (
              <img
                src={BucketInfoIcon}
                title="Reusable parameter fetched info"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color: "primary.main",
                  cursor: "pointer",
                }}
                onClick={() => {
                  status && infoModal.open(inputCalculation.bucket_info);
                }}
              />
            )}
          </Fragment>
        );

      default:
        return (
          <Fragment>
            {Boolean(inputCalculation?.image_url) && (
              <IconButton
                style={
                  !inputCalculation.image_url || !status
                    ? { cursor: "not-allowed", padding: "0" }
                    : { cursor: "pointer", padding: "0" }
                }
                onClick={() => {
                  return imageViewerElRef.current?.click?.();
                }}
                disabled={inputCalculation.image_url ? false : true}
              >
                <CameraAltIcon
                  titleAccess="camera"
                  // disabled={inputCalculation.image_url ? false : true}

                  style={
                    !inputCalculation.image_url || status == false
                      ? { cursor: "not-allowed", color: "#00000061" }
                      : { cursor: "pointer", color: "inherit" }
                  }
                />
              </IconButton>
            )}
            {/* {
              inputCalculation.bucket && <img src={BucketInfoIcon} title="Reusable parameter fetched info" style={{ width: "1.5rem", height: "1.5rem", color: 'primary.main',cursor:'pointer' }} onClick={()=>{
                infoModal.open(inputCalculation.bucket_info)
              }}/>
            } */}
          </Fragment>
        );
    }
  };

  // ----------------------------------------------------------------------------------

  // jsx

  if (!inputCalculation) return null;

  // if ((inputCalculation?.type === 5 || inputCalculation?.type === 6) && inputCalculation?.field_custom_validation?.condition && inputCalculation?.field_custom_validation?.value) {
  //   console.log(inputCalculation, "hii");
  // }

  // console.log(inputCalculation?.name, (inputCalculation?.type === 5 || inputCalculation?.type === 6), inputCalculation?.field_custom_validation?.condition);

  return (
    <TableRow
      data-testid="calculator-row"
      className={styles.container}
      style={{
        backgroundColor:
          calculatorInputBackgroundColorMapView[inputCalculation.type],
      }}
    >
      <TableCell className={styles.tableStyle} sx={{ width: "1rem" }}>
        {/* <Checkbox {...label} onClick={(e:any) => handledClicked(e, inputCalculation)}
        sx={{padding:'2px',color:'primary.main', display: !isWeightInput && CalcData?.category == 1 ? "block" : !isWeightInput && CalcData?.category == 2 ? "block" :  "none"}}
        disabled={PermissionData && PermissionData?.action?.includes("C") || PermissionData && PermissionData?.action?.includes("U") ? false : true}
        /> */}
      </TableCell>

      <TableCell
        className={styles.tableStyle}
        sx={{
          color:
            inputCalculation?.type == 6 || inputCalculation?.type == 3
              ? ""
              : status == false
              ? "#00000061"
              : "gray",
        }}
      >
        {inputCalculation.name}
      </TableCell>
      <TableCell
        className={styles.tableStyle}
        sx={{
          color:
            inputCalculation?.type == 6 || inputCalculation?.type == 3
              ? ""
              : "gray",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
          <span
            style={{
              flex: "1",
              color: status == false ? "#00000061" : "inherit",
            }}
          >
            {renderValue()}{" "}
          </span>

          {(inputCalculation?.type === 5 || inputCalculation?.type === 6) &&
            inputCalculation?.field_custom_validation?.condition !== null &&
            inputCalculation?.field_custom_validation?.condition !==
              undefined && (
              // (inputCalculation?.field_custom_validation?.value && inputCalculation?.field_custom_validation?.value !== undefined)

              <>{renderError(inputCalculation?.field_custom_validation)}</>
            )}
        </Box>

        {/* {(CALCULATOR_INPUT_TYPE.USER_INPUT || CALCULATOR_INPUT_TYPE.FORMULA) && "reknfkerf"} */}
      </TableCell>
      <TableCell
        className={styles.tableStyle}
        sx={{
          color:
            inputCalculation?.type == 6 || inputCalculation?.type == 3
              ? ""
              : "gray",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.5rem",
            color: status == false ? "#00000061" : "inherit",
          }}
        >
          {renderIcon()}
        </span>
        {/* {
              inputCalculation.bucket && <img src={BucketInfoIcon} title="Reusable parameter fetched info" style={{ width: "1.5rem", height: "1.5rem", color: 'primary.main',cursor:'pointer' }} onClick={()=>{
                infoModal.open(inputCalculation)
              }}/>
            } */}
      </TableCell>
      <TableCell
        className={styles.tableStyle}
        sx={{
          paddingLeft: { lg: "", xl: "5rem" },
          color:
            inputCalculation?.type == 6 || inputCalculation?.type == 3
              ? ""
              : status == false
              ? "#00000061"
              : "gray",
        }}
      >
        {inputCalculation.unit}
      </TableCell>
      <div style={{ display: "none" }}>
        {/* @ts-ignore */}
        <RViewerJS>
          <img
            src={inputCalculation?.image_url}
            alt="image"
            ref={imageViewerElRef}
          />
        </RViewerJS>
      </div>
      {infoModal.isOpen && (
        <FetchedBucketInfo
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          data={inputCalculation}
          setUpdateCosting={setUpdateCosting}
          updateCosting={updateCosting}

        />
      )}
    </TableRow>
  );
};

// ----------------------------------------------------------------------------------

export default CalculatorRowConfig;
