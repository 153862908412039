import React, { useState } from "react";
import {
  Paper,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Typography,
  CircularProgress,
  Popover,
} from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
// import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../../Redux/Services/admin.service";
import ReplayIcon from "@mui/icons-material/Replay";
import RViewerJS from "viewerjs-react";
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';



export interface RowData {
  id: number;
  item_type: string | null;
  length: any;
  width: any;
  ocr: any;
  qty: any;
  image: string | null;
  location: string | null;
}

const BOMRobotoFlowFetchDataFailedTable: any = (props: any) => {
  const { onCloseModal,project } = props;
  const { vault } = useRouteParams<any>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [tableDataTop, setTableDataTop] = useState<RowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [countDetails, setCountDetails] = useState<any>({});

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [popoverData, setPopoverData] = useState<any>(null);

  const getCountDetails = ()=>{
    API.get(`/pcb/get_discrepancy_dataset/`, {
      project_id:project,
      get_count:true
    }, 0)
      .then((res: any) => {
        setCountDetails(res.data);
      })
      .catch(() => {
        ADMIN.toast.error("Could not fetch data, Please try again");
      });
  }

  React.useEffect(()=>{
    getCountDetails();
  },[])

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverData(data);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  }

  //   const getData = () => {
  //     if (activeTab === 0) {
  //       return tableDataTop;
  //     } else {
  //       return tableDataBottom;
  //     }
  //   };

  const rows = tableDataTop?.map((item: any) => {
    return {
      enabledStatus: item?.enable == true ? "true" : "false",
      ...item,
    };
  });

  const createExcelFile = (data: any[], fileName: string) => {
    if (!data || data?.length === 0) {
      console.error("No data provided for Excel export.");
      return;
    }
  
    // Flatten the JSON structure
    const flattenJSON = (data: any[], parentKey = "", result: any[] = []) => {
      return data?.map((item:any) => {
        let flattenedItem: any = {};
  
        Object.keys(item)?.forEach((key:any) => {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
  
          if (Array.isArray(item[key])) {
            // Handle array elements
            if (typeof item[key][0] === "object") {
              // Flatten nested objects inside the array
              item[key]?.forEach((obj:any, index:any) => {
                Object.keys(obj).forEach((subKey) => {
                  flattenedItem[`${newKey}[${index}].${subKey}`] = obj[subKey];
                });
              });
            } else {
              // Join primitive arrays into a single string
              flattenedItem[newKey] = item[key]?.join(", ");
            }
          } else if (typeof item[key] === "object" && item[key] !== null) {
            // Recursively flatten objects
            Object.assign(flattenedItem, flattenJSON([item[key]], newKey)[0]);
          } else {
            flattenedItem[newKey] = item[key];
          }
        });
  
        return flattenedItem;
      });
    };
  
    const flattenedData = flattenJSON(data);
  
    // Prepare the header row and rows from the data
    const headers = Object.keys(flattenedData[0]);
    const rows = flattenedData?.map((item) => headers.map((header) => item[header] || ""));
  
    // Create the Excel content in CSV format
    let csvContent = [headers.join(",")];
    rows?.forEach((row) => csvContent?.push(row.map((cell) => `"${cell}"`).join(",")));
    const csvString = csvContent?.join("\n");
  
    // Create a Blob and trigger a download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
  
    link.href = url;
    link.download = `${fileName}.csv`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getTableData = () => {
    setLoading(true);
    API.get(`/pcb/get_discrepancy_dataset/`, {
      project_id:project,
      [activeTab === 0 ?"detection_model":"classification_model"]:true
    }, 0)
      .then((res: any) => {
        setLoading(false);
        const data = res.data?.map((item: any, index: any) => {
          return {
            id: index + 1,
            ...item,
          };
        });
        setTableDataTop(data);
        //     Topdata?.map((item:any)=> {

        //       if (item?.accepted == true) {

        //        if (!acceptedIds?.includes(item?.id)) {
        //          setAcceptedIds((prevItems:any) => [...prevItems, item?.id]);
        //        }
        //       }
        //       if (item?.rejected == true) {
        //        if (!rejectedIds?.includes(item?.id)) {
        //          setRejectedIds((prevItems:any) => [...prevItems, item?.id]);
        //        }
        //       }
        //       if (item?.enable == false) {
        //        if (!mergedIdsIds?.includes(item?.id)) {
        //          setMergedIds((prevItems:any) => [...prevItems, item?.id]);
        //        }
        //       }
        //    });

        //     // let BOTTOM: RowData[] = res?.data?.BOTTOM?.map(
        //     //   (item: any, index: any) => {
        //     //     return {
        //     //       id: item?.component_id,
        //     //       item_type: item?.item_name,
        //     //       length: item?.length_mm,
        //     //       width: item?.breadth_mm,
        //     //       ocr: item?.ocr,
        //     //       qty: item?.qty || 1,
        //     //       image: item?.cropped_image,
        //     //       location: item?.location,
        //     //     };
        //     //   }
        //     // );
        //     setTableDataTop(Topdata);
        //     setHeaderDetails({
        //       topImg: res?.data?.top_detection_result,
        //       bottomImg: res.data?.bottom_detection_result,
        //       topItemCounts: res.data?.item_counts?.top_item_counts,
        //       totalTopItemCount: res.data?.item_counts?.total_top_counts,
        //       bottomItemCounts: res.data?.item_counts?.bottom_item_counts,
        //       totalBottomItemCount: res.data?.item_counts?.total_bottom_counts,
        //     });
        // setTableDataBottom(BOTTOM);
      })
      .catch(() => {
        ADMIN.toast.error("Could not fetch data, Please try again");
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTableData();
  }, [activeTab]);

  // Handle checkbox selection/unselection

  // Handle the Fetch button click

  const columns: GridColDef[] = [
    // {
    //   field: "select",
    //   headerName: "Select",
    //   width: 100,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <Checkbox
    //       checked={selectedRows.includes(params.row.id)}
    //       onChange={() => handleSelectRow(params.row.id)}
    //     />
    //   ),
    // },
    {
      field: "id",
      headerName: "SL No.",
      width: 150,
      renderHeader: () => <Box sx={{ paddingLeft: "2rem" }}>SL No.</Box>,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box
            sx={{
              paddingLeft: "2rem",
              color: params?.row?.enabledStatus == "false" ? "grey" : "inherit",
            }}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "item_name",
      headerName: "Item Type",
      width: 170,
      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "length_mm",
      headerName: "Length",
      width: 180,
      renderHeader: () => <Box>Length (mm)</Box>,
      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "breadth_mm",
      headerName: "Width",
      width: 180,
      renderHeader: () => <Box>Width (mm)</Box>,

      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "ocr",
      headerName: "OCR",
      width: 230,
      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "qty",
      headerName: "Qty.",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "location_of_component",
      headerName: "Location",
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        return <Box>{params?.value}</Box>;
      },
    },
    {
      field: "cropped_image",
      headerName: "Image",
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        //@ts-ignore
        <RViewerJS>
          <img
            src={params.row.cropped_image}
            alt={`Image ${params.row.id}`}
            style={{
              width: "70px",
              height: "50px",
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
        </RViewerJS>
      ),
    },
    {
      field: "top5_classification",
      headerName: "Top 5 Classifications",
      width: 160,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        //@ts-ignore
        <Box>
          <InfoIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={(e: any) => handlePopoverOpen(e, params.value)}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            id={id}
            sx={{
              pointerEvents: "none",
              "& .MuiPaper-root": {
                borderRadius: "8px",
                boxShadow: "4px",
              },
            }}
            elevation={0}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            
          >
            <Box
              sx={{
                fontFamily: "Arial, sans-serif",
                padding: "16px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "8px",
                maxWidth: "300px",
              }}
            >
              {popoverData &&
                popoverData?.map((item: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "14px",
                      color: "#333",
                    }}
                  >
                    <strong>{item.class}:</strong> {(item.confidence * 100)?.toFixed(6) || 0}%
                  </Box>
                ))}
            </Box>
          </Popover>
        </Box>
      ),
    },
  ];

  const toggleItem = (id: number, key: any, value: any) => {
    // console.log(id, value, "qty toggleItem");

    // if (key == "accepted") {
    setTableDataTop((prevItems: any) =>
      prevItems?.map((item: any) => {
        if (item?.id === id) {
          return { ...item, [key]: value };
        } else {
          return item;
        }
      })
    );
  };

  //  console.log(acceptedIds, "qty acceptedIds");
  //  console.log(mergedIdsIds, "qty mergedIdsIds");
  //  console.log(rejectedIds, "qty rejectedIds");
  //  console.log(acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length, "qty table");

  //  console.log(tableDataTop?.length, "qty tableDataTop");

  return (
    <Paper style={{ width: "100%", overflow: "hidden" }}>
      {/* <Box sx={{ margin: "1rem" }}> */}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ marginBottom: "1rem", color: "#007fff" }}
        >
          <Tab
            label={`PCB Detections (${countDetails?.["Detection Model"] || "0"})`}
            sx={{ color: "#007fff" }}
          />
          <Tab
            label={`PCB Classifications (${countDetails?.["Classification Model"] || "0"})`}
            sx={{ color: "#007fff" }}
          />
        </Tabs>
        <Box sx={{ width: "80%" }}>
         
       
          <Typography
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.4rem",
              cursor: "pointer",
              textDecoration: "underline",
              float: "right",
            }}
            onClick={() => getTableData()}
          >
            Retry <ReplayIcon fontSize="small" />
          </Typography>

          {!isDownloading?<Typography
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.4rem",
              cursor: "pointer",
              // textDecoration: "underline",
              float: "right",
              marginRight: "3rem",
              opacity:tableDataTop?.length>0?1:0.5
            }}
            onClick={() => {
              if(tableDataTop?.length>0){
                setIsDownloading(true);
                API.get("/pcb/download_discrepancy_dataset_images/",{
                  project_id:project,
                  [activeTab === 0 ?"detection_model":"classification_model"]:true
                },0).then((res:any)=>{
                  setIsDownloading(false);
                  window.open(res.data, "_blank");
                }).catch((err:any)=>{
                  setIsDownloading(false);
                })
              }
            }}
          >
            Download <FolderZipIcon fontSize="small" />
          </Typography>:
         <Typography
         sx={{
           whiteSpace: "nowrap",
           display: "flex",
           flexDirection: "row",
           alignItems: "center",
           gap: "0.4rem",
           cursor: "pointer",
           // textDecoration: "underline",
           float: "right",
           marginRight: "3rem",
         }}
   
       >
         Download <CircularProgress size={14} />
       </Typography>
          }

{<Typography
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.4rem",
              cursor: "pointer",
              // textDecoration: "underline",
              float: "right",
              marginRight: "3rem",
              opacity:tableDataTop?.length>0?1:0.5
            }}
            onClick={() => {
              if(tableDataTop?.length>0){
                createExcelFile(tableDataTop, `${activeTab === 0 ?"detection_model":"classification_model"}_dataset`);
              }
            }}
          >
            Export<FileDownloadIcon fontSize="small" />
          </Typography>
          }
        </Box>
      </Box>

      <Box
        sx={{
          height: "74vh",
          width: "100%",
          "& .super-app-theme--false": { bgcolor: "#e4e7eb" }, // Light Green
        }}
      >
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          // getRowClassName={(params) =>
          //   `super-app-theme--${params.row?.enabledStatus?.toLowerCase()}`
          // }
          checkboxSelection={false}
          disableSelectionOnClick
          loading={loading}
          components={{
            NoRowsOverlay: () => <Box>No Data Available</Box>,
            LoadingOverlay: () => (
              <Box>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
              </Box>
            ),
          }}
        />
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "center" , marginTop:"-1rem", }}>
        <LoadingButton
          variant="contained"
          
          isLoading={fetchLoading}
            disabled={(acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length) !== tableDataTop?.length}
          onClick={handleFetchData}
          style={{ margin: "10px", color:"#fff", 
            // backgroundColor: "green",
             backgroundColor: (acceptedIds?.length + mergedIdsIds?.length + rejectedIds?.length) !== tableDataTop?.length ? "grey" : "green"
           }}
        >
          Fetch Accepted Rows
        </LoadingButton>
      </Box> */}
    </Paper>
  );
};

export default BOMRobotoFlowFetchDataFailedTable;