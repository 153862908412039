import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./FeatureDetailsView.module.scss";
import {
  Table,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  TableBody,
  Skeleton,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { API } from "../../api-services";
import RViewerJS from "viewerjs-react";
import VideoModal from "../../ComponentUI/MediaModule/VideoModal/VideoModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import FeatureModal from "./FeatureModal";
import NumericalFeatureModal from "./NumericalFeatureModal";
import NoDataImg from "../../Assets/images/NoDataImg.png";
import Image from "../FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import FeatureAllvideo from "./FeatureAllvideo";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import featureIcon from "../../Assets/images/FeatureIcon.png";


// import SkeletonLoaderComponent from "../../ComponentUI/SkeletonLoader/SkeletonLoaderComponent";
const editorConfig={

  readonly: false,
  toolbar: true,
  // @ts-ignore
  spellcheck: true,
  saveSelectionOnFocus: true,
  saveSelectionOnBlur: true,
  language: "en",
  // toolbarButtonSize: 'medium',
  // toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,

  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },

  controls: {
    font: {
      command: "fontname",
      list: {
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Consolas,monaco,monospace": "Consolas",
      },
    },
  },

}
export interface FeatureDetailsViewProps { }

const FeatureDetailsView = (props: FeatureDetailsViewProps) => {
  const {
    projectId,
    topVault,
    vault,
    abbr,
    FeatureType,
    FeatureId,
    GroupId,
    FeatureName,
  } = useParams<any>();
  const [FeatureParameterData, setFeatureParameterData] = useState<any>();
  const [emptyData, setEmptyData] = useState<any>();
  const VideoModalRef = useRef<any>(null);
  const editor = useRef(null);
  const [content, setContent] = useState<any>("");
  const [loader, setloader] = useState<any>(false);
  const ModalOpen = useBaseModal();
  const FeatureVideoModal = useBaseParamsModal()
  const NumerialModalOpen = useBaseModal();
  const [GraphData, setGroupData] = useState<any>();
  const [NumericalGraphdata, setNumericalGraphdata] = useState<any>();
  const [graphLoader, setGraphLodaer] = useState<any>(false);
  const [NumericalGraphLodaer, setNumericalGraphLodaer] = useState<any>(false);
  const FeatureGroupHandler = (Id: any) => {
    if (Id !== "0") {
      setloader(true);
      setFeatureParameterData({});
      setEmptyData({});
      API.get("/customer/feature_view_data/", {
        feature_id: Id,
        id: FeatureId,
        top_vault_list: topVault,
      })
        .then((res: any) => {
          setFeatureParameterData(res?.data);
          setloader(false);
        })
        .catch((err: any) => {
          setloader(false);
          console.log(err);
          setFeatureParameterData({});
        });
        API.get("/customer/feature_view_data/", {
          feature_id: Id,
          id: FeatureId,
          top_vault_list: topVault,
          feature_exists: true,
        })
          .then((res: any) => {
            setEmptyData(res?.data);
            setloader(false);
          })
          .catch((err: any) => {
            setloader(false);
            console.log(err);
            setEmptyData({});
          });
    }
  };



  const inferencesApicall = () => {
    API.get("/analytics/feature_analysis_inf/", { feature_id: GroupId }).then(
      (res: any) => {
        setContent(res.data);
      }
    );
  };

  const NumerialGraphHandler = (parameterName: any) => {
    var data = NumerialModalOpen.open();
    var obj: any = {};
    obj[parameterName] = true;
    setNumericalGraphLodaer(true);
    API.get("/customer/feature_view_data/", {
      feature_id: GroupId,
      id: FeatureId,
      top_vault_list: topVault,
      ...obj,
    })
      .then((res: any) => {
        setNumericalGraphLodaer(false);
        setNumericalGraphdata(res.data);
      })
      .catch((err: any) => {
        setNumericalGraphLodaer(false);
        console.log("error");
      });
  };

  const BarGraphHandler = (graphType: any) => {
    var data = ModalOpen.open();
    setGraphLodaer(true);
    API.get("/analytics/get_graph/", {
      id: FeatureId,
      feature_id: GroupId,
      functionName: "Numerical",
      type: graphType,
    })
      .then((res: any) => {
        setGraphLodaer(false);
        setGroupData(res.data);
      })
      .catch((err: any) => {
        setGraphLodaer(false);
        console.log("error");
      });
  };

  useEffect(() => {
    inferencesApicall();
    FeatureGroupHandler(GroupId);
  }, [GroupId, topVault, FeatureType, FeatureId]);
  const EmptyRowHeader=()=>{
    var emptyHeader:any=[];
    emptyData && emptyData?.map((item:any,index:any)=>{
      emptyHeader.push(<TableCell className={styles.headerColumempty}>{item?.top_vault_name}</TableCell>)
    })
    return emptyHeader;

  }

  const DataGridHeader = () => {
    
   
    var datavalue =
      FeatureParameterData &&
      Object.keys(FeatureParameterData)?.map((item: any, index: any) => {
        return (
          FeatureParameterData &&
          Object.entries(FeatureParameterData[item][0]).map(
            ([item2, index2]: any) => {
              return (
                <>
                  <TableCell className={styles.headerColum}>{item2}</TableCell>
                  
                </>
              );
            }
          )
        );
      });
      // console.log(datavalue[0],"datavalue")
    return datavalue && datavalue[0];
  };

  const DataGridRow = (data: any, index: any) => {
    var valueArry = [];
    for (var [key, value] of Object.entries(FeatureParameterData[data][0])) {
      //  @ts-ignore

      valueArry.push(
        <TableCell className={styles.dataColumn}>
          <>           
            <TextareaAutosize                 
                style={{
                resize: "none",
                border: "none",                             
                width: "100%",
                color: "#007fff",
                fontFamily: "Roboto",               
              }}
               // @ts-ignore
              value={value} 
            />
          </>
        </TableCell>
      );
    }

    return valueArry;
  };

  const DataGridRowEmpty=()=>{
   var rowSpanCount =  Object.keys(FeatureParameterData)?.length
    var emptyValueArry:any=[];
    emptyData && emptyData?.map((item:any,index:any)=>{
      console.log(emptyData.length,EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length,"emptyData");
       emptyValueArry?.push(<TableCell className={styles.dataNoborder} rowSpan={rowSpanCount && rowSpanCount} sx={{
        minWidth: EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 2 ? '25vw' : 
        EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 3 ? '19vw' :
        EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 4 ? '15vw' : 
        EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 5 ? '13.5vw' : '13.5vw'
      }}>
        <Box sx={{width:'100%',display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',height:{lg:'81vh',xl:'85vh'},borderLeft:'1px solid #ddefff'}}>
        <img src={featureIcon} style={{width:'8rem'}} />
        <Typography sx={{fontSize:'0.87rem'}}>Feature Not Available</Typography>
        </Box>
       </TableCell>)
    })
    return emptyValueArry;
  }


  const VideoOpenHandler = (index:any) => {
    FeatureVideoModal.open(index)
  }
  

  const ImageContainer = (item:any,index:any) => {
    var datavlue =
      FeatureParameterData &&
      FeatureParameterData["Image"] &&
      Object.entries(item);
    var imageData =
      datavlue &&
      datavlue?.map(([key, value]: any) => {
        return (
          <>
            <TableCell className={styles.dataColumn}>

              {value.includes("/") ? (
                //  @ts-ignore

                <Image
                  src={value}
                  placeholderImg={NoImgLoader}
                  alt={key}
                  style={{ width: "13.5vw", cursor: "pointer" }}
                />

              ) : (
                value
              )}
            </TableCell>
          </>
        );
      });

    return imageData;
  };

  const DocumentContainer = (item:any,index:any) => {
    var datavlue =
      FeatureParameterData &&
      FeatureParameterData["Document"] &&
      Object.entries(item);
    var imageData =
      datavlue &&
      datavlue?.map(([key, value]: any) => {
        return (
          <>
            <TableCell className={styles.dataColumn}>

              {value.includes("/") ? (
                //  @ts-ignore

                <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(value, "_blank", "noopener noreferrer");
                }}
              >
                View Document
              </span>

              ) : (
                value
              )}
            </TableCell>
          </>
        );
      });

    return imageData;
  };

  const openModalInPreviewMode = (link: string,isMute:any) => {
    return VideoModalRef.current?.open([link,isMute]);
  };

  const VideoContainer = (item:any,index:any) => {
    var datavlue =
      FeatureParameterData &&
      FeatureParameterData["Video"] &&
      Object.entries(FeatureParameterData["Video"][index]);
    var imageData =
      datavlue &&
      datavlue?.map(([key, value]: any) => {
        return (
          <>
            <TableCell className={styles.dataColumn}>
              {value == "Not Available" ? (
                value
              ) : value == item?.Parameter ? (
                <>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: '0.5rem' }}>
                    <Typography sx={{ fontSize: '1rem' }}>{value}</Typography>
                    <Typography sx={{ cursor: "pointer", marginLeft: "0.3rem", lineHeight: 1 }} onClick={() => VideoOpenHandler(index)}>
                      <YouTubeIcon titleAccess={"All videos"} />
                      </Typography>
                  </Box>
                </>
              ) : (
                (value["thumbnail"] == "Not Available" ? <p> Not Available</p>
                  :

                  <Image
                    src={value["thumbnail"]}
                    onClick={() => openModalInPreviewMode(value["video"],value["is_mute"])}
                    placeholderImg={NoImgLoader}
                    style={{ width: "13.5vw", cursor: "pointer" }}
                  />
                )
              )}
            </TableCell>
          </>
        );
      });

    return imageData;
  };

  return (
    <>
      <VideoModal ref={VideoModalRef} />
      <FeatureModal
        isOpen={ModalOpen.isOpen}
        onCloseModal={ModalOpen.close}
        GraphData={GraphData && GraphData}
        graphLoader={graphLoader}
      />

     {FeatureVideoModal.isOpen && <FeatureAllvideo
        isOpen={FeatureVideoModal.isOpen}
        onCloseModal={FeatureVideoModal.close}
        FeatureParameterData={FeatureParameterData && FeatureParameterData}
        indexId={FeatureVideoModal?.propsId}
      />}

      <NumericalFeatureModal
        isOpen={NumerialModalOpen.isOpen}
        onCloseModal={NumerialModalOpen.close}
        GraphData={NumericalGraphdata && NumericalGraphdata}
        graphLoader={NumericalGraphLodaer}
      />
      {/* {FeatureParameterData && Object.keys(FeatureParameterData)?.length >0 ?<> */}
      {GroupId !== "0" &&
        (loader ? (          
          <div> <Box sx={{ padding: "0 1rem" }}>
            <Skeleton width='100%' />
            <Skeleton width='100%' height={150} />
            <Skeleton width='100%' height={55} />
            <Skeleton width='100%' height={45} />
            <Skeleton width='100%' height={150} />
            <Skeleton width='100%' height={150} /></Box>
          </div>

        ) : FeatureParameterData &&
          Object.keys(FeatureParameterData)?.length > 0 ? (
          <>
            <div
              className={styles.tablesec}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Table>
                <TableHead sx={{ position: 'sticky', top: '0' }}>
                  <TableRow sx={{ backgroundColor: "primary.light" }}>

                    {DataGridHeader()}                    
                    {/* {EmptyRowHeader()} */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {FeatureParameterData &&
                    Object.keys(FeatureParameterData)?.map(
                      (item: any, index: any) => {
                        return (
                          <>

                            <TableRow>
                              {item !== "Image" &&
                                item !== "Video" &&
                                item !== "Document" &&
                                DataGridRow(item, index)}
                            </TableRow>
                            {/* @ts-ignore */}
                            <RViewerJS className={styles.rviewjscontainer} >
                              {!index && FeatureParameterData?.Image?.map((item:any,index:any)=>{
                                return <TableRow>{ImageContainer(item,index)}</TableRow>
                              }) }
                            </RViewerJS>
                            {!index && FeatureParameterData?.Document?.map((item:any,index:any)=>{
                                return <TableRow>{DocumentContainer(item,index)}</TableRow>
                              }) }
                            {!index && FeatureParameterData?.Video?.map((item:any,index:any)=>{
                              return <TableRow>{VideoContainer(item,index)}</TableRow>
                            })}
                          </>
                        );
                      }
                    )}

                   

                  {/* {content.length !== 0 &&
                    <TableRow>
                      <TableCell className={styles.dataColumnIn}>
                        Inferences
                      </TableCell>
                      <TableCell colSpan={5} className={styles.dataColumnIn}>
                        {content.length !== 0 && (
                          <div className={styles.Inferensec}>
                           
                            <JoditEditor
                              ref={editor}
                              value={content}
                              config=
                              {editorConfig}
                              className={styles.hideBorder}
                              onBlur={(newContent) => setContent(newContent)}
                             
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  } */}
                </TableBody>
              </Table>


              {/* Empty Table Section=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
              {/* {(DataGridHeader()?.length-1)+EmptyRowHeader()?.length} */}
              {emptyData?.length>0 && <Table sx={{width: EmptyRowHeader() && DataGridHeader()&&(DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 2 ?'50%' : EmptyRowHeader() && DataGridHeader()&& (DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 3 ? '33%' : EmptyRowHeader() && DataGridHeader()&& (DataGridHeader()?.length-1)+EmptyRowHeader()?.length === 4 ? '25%': '100%' }}>
                <TableHead sx={{ position: 'sticky', top: '0' }}>
                  <TableRow sx={{ backgroundColor: "primary.light" }}>                   
                    {EmptyRowHeader()}
                  </TableRow>
                </TableHead>
                <TableBody>
                   {DataGridRowEmpty()}                
                </TableBody>
              </Table>}


            </div>
          </>
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "18%",
            }}
          >
            <img style={{ width: "13%" }} src={NoDataImg} />
          </span>
        ))}
      {/* </>:<p>No Data</p>} */}
    </>
  );
};

export default FeatureDetailsView;
