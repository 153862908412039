import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TextField,
  Checkbox,
  Skeleton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./AddProcessRevisionModal.module.scss";
import { AssignCostModeltoAddedProcessModal } from "./AssignCostModeltoAddedProcessModal";
import Chip from "@mui/material/Chip";
import { API } from "../../../../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import successIcon from "../../../../Assets/images/success.png";
import { useBaseParamsModal } from "../../../../CustomHook/useBaseParamsModal";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { AddQty } from "./AddQty";

export interface IAddProcessRevisionModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData?: any;
  getRMData?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function AddProcessRevisionModal(props: IAddProcessRevisionModalProps) {
  const { onCloseModal, isOpen, costDetailsData, getRMData } = props;
  const [commodityData, setCommodityData] = React.useState<any>([]);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>("");
  const [processList, setProcessList] = React.useState<any>([]);
  const [selectedProcessList, setSelectedProcessList] = React.useState<any>([]);
  const [selectedProcessQtyList, setSelectedProcessQtyList] =
    React.useState<any>([]);
  const [commodityLoader, setCommodityLoader] = React.useState<any>(false);
  const [SelectClickedApiCount, setSelectClickedApiCount] =
    React.useState<any>(0);
  const [addedProcessResult, setAddedProcessResult] = React.useState<any>([]);
  const [selectionLoading, setSelectionLoading] = React.useState<any>(false);
  const { vault ,scenarioId} = useRouteParams<any>();
  const getCommodityData = () => {
    setCommodityLoader(true);
    API.get("/config/commodity_mapping/", { vault: vault }, 0)
      .then((res: any) => {
        setCommodityLoader(false);
        setCommodityData(res.data);
        setSelectedCommodity(res.data[0]?.id);
      })
      .catch((err: any) => {
        setCommodityLoader(false);
      });
  };

  const getAddedProcessList = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        vault: vault,
        get_level: "process_calculator",
        calculator: true,
        scenario:scenarioId?scenarioId:undefined
      },
      0
    )
      .then((res: any) => {
        setAddedProcessResult(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    if (SelectClickedApiCount > 0) {
      getAddedProcessList();
    }
  }, [SelectClickedApiCount]);

  const getAllProcessList = () => {
    API.get(
      "/config/process/",
      {
        commodity_mapping: selectedCommodity,
      },
      0
    )
      .then((res: any) => {
        setProcessList(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getCommodityData();
  }, []);
  React.useEffect(() => {
    if (selectedCommodity) {
      getAllProcessList();
    }
  }, [selectedCommodity]);

  const AssignProcessCostModal = useBaseParamsModal();
  const AssignProcessCostModalHandler = () => {
    //   API.post(
    //     "/cost/material/",
    //     {
    //       cost_estimation: costDetailsData?.cost_estimation,
    //       materials: selectedMaterial.map((item: any) => item.material_id),
    //     },
    //     {},
    //     0
    //   )
    //     .then((res: any) => {
    //       Swal.fire({
    //         html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    // <br />
    // <p style="color:#007fff;"> Materials added successfully
    // </p>
    //  </div>`,
    //       });
    AssignProcessCostModal.open(costDetailsData);
    // })
    // .catch((err: any) => { });
  };

  // Create the handler function that will be called when a user selects a process
  const onSelectProcess = (id: any, item: any) => {
    // Check if the selected process is already in the selectedProcessList
    if (selectedProcessList.includes(id)) {
      // If it is, remove it from the list
      setSelectedProcessList((prev: any) =>
        prev.filter((item: any) => item !== id)
      );
      setSelectedProcessQtyList((prev: any) =>
        prev.filter((item: any) => item.id !== id)
      );
    } else {
      // If it isn't, add it to the list
      setSelectedProcessList((prev: any) => [...prev, id]);
      setSelectedProcessQtyList((prev: any) => [...prev, { id: id, qty: 1 }]);
    }
  };

  return (
    <div>
      {AssignProcessCostModal.isOpen && (
        <AssignCostModeltoAddedProcessModal
          isOpen={AssignProcessCostModal.open}
          onCloseModal={AssignProcessCostModal.close}
          costDetailsData={AssignProcessCostModal.propsId}
          parentClose={onCloseModal}
          getRMData={getRMData}
        />
      )}

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Process Revision
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                API.post("/cost/cost_calculator/", {
                  process: true,
                  cost_estimation: costDetailsData?.cost_estimation,
                  scenario:scenarioId?scenarioId:undefined
                }, {}, 0).then((res: any) => {

                }).catch((err: any) => { })
                getRMData();
                onCloseModal();

              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                columnGap: "1rem",
                width: "100%",
              }}
            >
              <Box
                className={styles.chipSection}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {commodityLoader ? <>
                  <Skeleton
                    variant="rectangular"
                    height="1.5rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </>
                  : <>
                    {commodityData &&
                      commodityData?.map((item: any, index: any) => {
                        return (
                          <Chip
                            className={
                              selectedCommodity === item?.id
                                ? styles.calulatorChip_active
                                : styles.calulatorChip
                            }
                            label={item?.commodity?.name}
                            onClick={() => setSelectedCommodity(item?.id)}
                            key={index}
                          />
                        );
                      })}
                  </>
                }
                {/* className={styles.calulatorChip} */}
              </Box>
            </Box>
            <Box sx={{ margin: "0.5rem" }}>
              <Typography sx={{ fontSize: "1rem" }}>
                Select Process
              </Typography>
            </Box>
            <Box className={styles.selectMaterial}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                <Box
                  sx={{
                    // width: "100%",
                    // marginBottom: "0.5rem",
                    height: { lg: "69vh", xl: "75vh" },
                    width: '25vw',
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Box sx={{ height: { lg: "62vh", xl: "70vh" }, }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              width: "3rem",
                            }}
                          >
                            Select
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              lineHeight: "1.2",
                              whiteSpace: "nowrap",
                              width: "5rem",
                            }}
                          >
                            Qty
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              lineHeight: "1.2",
                            }}
                          >
                            Process
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {processList &&
                          processList?.map((item: any, index: any) => {
                            return (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    padding: "0rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                  key={index}
                                >
                                  <Checkbox
                                    size="small"
                                    style={{ padding: "0px" }}
                                    checked={selectedProcessList.includes(item?.id)}
                                    title="Validated"
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: { lg: 22, xl: 24 },
                                      },
                                      color: "#007fff",
                                    }}
                                    onClick={() => onSelectProcess(item?.id, item)}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <AddQty
                                    index={index}
                                    selectedId={item?.id}
                                    disableStatus={
                                      !selectedProcessList.includes(item?.id)
                                    }
                                    selectedProcessQtyList={selectedProcessQtyList}
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.name}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      size="small"
                      loading={selectionLoading}
                      disabled={selectedProcessQtyList?.length > 0 ? false : true}
                      onClick={() => {
                        setSelectionLoading(true);
                        API.post(
                          "/cost/process/",
                          {
                            process_ids: selectedProcessQtyList,
                            cost_estimation: costDetailsData?.cost_estimation,
                            commodity_mapping: selectedCommodity,
                          },
                          {},
                          0
                        )
                          .then((res: any) => {
                            Swal.fire({
                              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                          <br />
                          <p style="color:#007fff;"> Process added successfully
                          </p>
                           </div>`,
                            });
                            setSelectionLoading(false);
                            setSelectClickedApiCount((prev: any) => prev + 1);
                            getRMData();
                          })
                          .catch((err: any) => {
                            setSelectionLoading(false);
                            const { data } = err.response;
                            Swal.fire({
                              icon: "error",
                              html: `<div>
                          <br />
                          <p style="color:"red">${data[0]}</p>   
                           </div>`,
                            });
                          });
                      }}
                    >
                      Create Process
                    </LoadingButton>
                  </Box>
                </Box>

                {addedProcessResult?.length > 0 && <Box
                  sx={{
                    width: "40vw",
                    // marginBottom: "0.5rem",
                    height: { lg: "69vh", xl: "75vh" },
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Box sx={{ height: { lg: "62vh", xl: "70vh" }, }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              width: "3rem",
                            }}
                          >
                            Sequence
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              lineHeight: "1.2",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Process Name
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              lineHeight: "1.2",
                            }}
                          >
                            Commodity
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              lineHeight: "1.2",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Process
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addedProcessResult &&
                          addedProcessResult?.map((item: any) => {
                            return (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <TextField
                                    variant="standard"
                                    name="sequence"
                                    type="number"
                                    disabled={true}
                                    placeholder="Seq"
                                    value={item.sequence}
                                    // onChange={handleChange(input.id)}
                                    InputProps={{
                                      style: { fontSize: "1rem" },
                                    }}
                                    sx={{
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "primary.light",
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "primary.main",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.alias}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  Commodity
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.process_name}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <LoadingButton
                      size="small"
                      variant="contained"
                      onClick={() => AssignProcessCostModalHandler()}
                      sx={{
                        ".MuiCircularProgress-root": {
                          color: "primary.main",
                        },
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      Proced to Add CM
                    </LoadingButton>
                  </Box>
                </Box>}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
