import { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider, TextField } from '@mui/material';
import { API } from '../../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from "react-redux";
import { selectedCalculators } from '../../../../Redux/Actions/compareopen.action';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "40vw",
    maxWidth: '50vw',
    maxHeight: { lg: '63vh', xl: '48vh' },
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
interface CopyCostModalProps {

    onCloseModal: any;
    isOpen: any;
    copyCMInfo: any;
    SwalAlertHandlerImages: any;

}

const CopyCostModal = (props: CopyCostModalProps) => {
    const { onCloseModal, isOpen, copyCMInfo, SwalAlertHandlerImages } = props;
    const dispatch = useDispatch();

    const [qty, setQty] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);


    const SubmitHandler = () => {
        setLoading(true);
        API.post('cost/copy_calculator/', {
            source_calculation: copyCMInfo?.id,
            category: copyCMInfo?.calculator?.category,
            quantity: qty
        })
        .then((res: any) => {
            SwalAlertHandlerImages(true);
            dispatch(selectedCalculators([]));
            setLoading(false);
            onCloseModal();
        })
        .catch((err: any) => {
            console.log(err)
            setLoading(false);
            onCloseModal();
        })
    }

    const handlechange = (e: any) => {
        setQty(e.target.value);
    }






    const closeHandaler = () => {
        onCloseModal()

    }
    return (
        <div>

            <Modal
                open={isOpen}
                //   onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>

                            <Typography
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    width: "100%",
                                    textAlign: "center",
                                    marginRight: '-2rem'
                                }}
                            >
                                Copy Cost Model
                            </Typography>

                            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={closeHandaler} />
                        </Box>
                    }
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />


                    <Box sx={{ padding: '0.5rem' }}>
                        {/* <textarea style={{
                paddingLeft: "0.3rem",
                width: "100%",
                height: "9rem",
                border: '1px solid #ddefff',
                borderRadius: '5px',
              }} value={masgdata} placeholder='write comments here......' onChange={(e: any) => handlechange(e)} /> */}
                        <Box sx={{ display: "flex", width: "100%", gap: "2rem" }}>
                            <TextField
                                size='small'
                                id="filled-read-only-input"
                                label="Name"
                                defaultValue={copyCMInfo?.calculator?.name}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { color: '#007fff' }
                                }}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="filled"
                                sx={{ width: "60%", 
                                "& .MuiInputBase-root.MuiFilledInput-root:before": {
                                    borderBottomColor: "primary.main",
                                },
                                "& .MuiInputBase-root.MuiFilledInput-root:after": {
                                    borderBottomColor: "primary.main",
                                },
                                '.MuiInputBase-root.MuiFilledInput-root':{
                                    backgroundColor: "primary.light",
                                },
                            }}
                            />

                            <TextField
                                id="filled-number"
                                label="Number"
                                type="number"
                                size='small'
                                InputLabelProps={{
                                    shrink: true,
                                    style: { color: '#007fff' }
                                }}
                                InputProps={{
                                    inputProps: { min: 1 }
                                }}
                                variant="filled"
                                value={qty}
                                onChange={(e: any) => handlechange(e)}
                                sx={{
                                    width: "40%",                                   
                                    "& .MuiInputBase-root.MuiFilledInput-root:before": {
                                        borderBottomColor: "primary.main",
                                    },
                                    "& .MuiInputBase-root.MuiFilledInput-root:after": {
                                        borderBottomColor: "primary.main",
                                    },
                                    '.MuiInputBase-root.MuiFilledInput-root':{
                                        backgroundColor: "primary.light",
                                    },
                                }}
                            />

                        </Box>

                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight:"0.5rem" }}>
                        <LoadingButton size='small' disabled={qty == undefined || qty < 1} loading={loading} variant='contained' onClick={SubmitHandler}>ADD</LoadingButton>
                    </Box>

                </Box>
            </Modal>
        </div>
    )
}
export default CopyCostModal;