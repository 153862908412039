import React, { useEffect, useState } from 'react'
import { Box, TableCell, TableRow } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from 'react-redux';
import { inputCalculationSelectors } from '../../Redux/Reducers/inputCalculation.reducer';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import SelectedDatabaseRecordInfoModal from '../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/SelectedDatabaseRecordInfoModal';

function CostingViewProcessRow({
  getShowStatus,
  value,
  calcData,
  detailsShow,
  formatNumber,
  childData,
  flag,
  flagRed,
  calculatorId,
  // getAssumptionList, getAssumptionData, 
  selectedRevision,
  assumptionDetails,
  viewtype
}: any) {
  const { vault, topVault, projectId, scenarioId } = useRouteParams<any>();
  // const [assumptionDetails, setAssumptionDetails] = useState<any>();
  // const [selectedRevision,setSelectedRevision] = useState<any>();
  const [assumptionCount, setAssumptionCount] = useState<any>({
    details: 0,
    list: 0
  });


  const [contentType, setContentType] = useState<any>(null);
  const [revisionId, setRevisionId] = useState<any>(null);
  const dbInfoModal: any = useBaseParamsModal()

  // const getAssumptionData = () => {

  //     API.get(`/cost/costing_assumption/`, {
  //         vault: vault,
  //         scenario: scenarioId,
  //       }).then((res: any) => {
  //         setAssumptionDetails(res?.data);
  //         setAssumptionCount({...assumptionCount, details:assumptionCount.details+1});
  //       });
  //   };

  //   const getAssumptionList = () => {
  //     if (assumptionDetails?.assumption) {
  //       API.get(`cost/assumption/`, { id: assumptionDetails?.assumption })
  //         .then((res: any) => {
  //           const rev = res?.data?.find((item:any)=> item?.id == assumptionDetails?.assumption)
  //           setSelectedRevision(rev);
  //           setAssumptionCount({...assumptionCount, list:assumptionCount.list+1})

  //         })
  //         .catch((err: any) => {

  //         });
  //     }

  //   }

  useEffect(() => {
    if (calcData?.content_type) {
      setContentType(calcData?.content_type)
    }
  }, [calcData])




  // useEffect(()=> {
  //   if (getShowStatus(
  //       calcData.content_type
  //     )) {
  //       if (assumptionCount.details === 0) {
  //         getAssumptionData();
  //       }

  //   }

  // },[calcData])

  // useEffect(()=> {
  //   if (assumptionDetails?.assumption && calcData?.content_type) {
  //     if (assumptionCount.list === 0) {
  //       getAssumptionList();
  //     }

  //   }

  // },[assumptionDetails])

  //   console.log(assumptionDetails?.assumption_list, "idd", selectedRevision);




  const CALCULATOR_DATABASES_MAP = {
    MATERIAL: 1,
    MACHINE: 2,
    LABOUR: 3,
    TOOL: 4,
    FASTENER: 5,
    PURCHASEING_ITEM: 6,
    STROKE_RATE: 7,
    CURRENCY: 8,
  };

  const DB_MAP = {
    1: ['Material'],
    2: ['Labour'],
    3: ['Machine'],
    4: ['Tool', 'ToolDatabase'], // Add both 'Tool' and 'ToolDatabase' here
    5: ['Operation'],
    6: ['PurchasingItem'],
  };

  // Function to get the ID
  const getIdFromMap = () => {
    if (calcData?.content_type) {
      // Find the key in the DB_MAP where the value matches calcData.content_type
      const entry = Object.entries(DB_MAP).find(
        ([, values]) => Array.isArray(values) && values.includes(calcData.content_type)
      );

      // Return the key (which is the ID) or null if not found
      return entry ? Number(entry[0]) : null;
    }
    return null;
  };

  useEffect(() => {


    if (selectedRevision && calcData?.content_type) {
      if (contentType == "Material") {
        setRevisionId(selectedRevision?.material_revision);
      } else if (contentType == "Machine") {
        setRevisionId(selectedRevision?.machine_revision);
      } else if (contentType == "Labour") {
        setRevisionId(selectedRevision?.labour_revision);
      } else if (contentType == "Tool" || contentType == "ToolDatabase") {
        setRevisionId(selectedRevision?.tools_revision);
      } else if (contentType == "Operation") {
        setRevisionId(selectedRevision?.operations_revision);
      } else if (contentType == "PurchasingItem") {
        setRevisionId(selectedRevision?.bop_revision);
      }
    }
  }, [selectedRevision, calcData, contentType]);
  // console.log(dbInfoModal, "idd",);

  console.log("idd", calcData, calcData?.content_type, getIdFromMap());


  return (
    <>
      {dbInfoModal.isOpen && (
        <SelectedDatabaseRecordInfoModal
          isOpen={dbInfoModal.isOpen}
          onClose={dbInfoModal.close}
          database={dbInfoModal?.propsId?.database}
          recordId={dbInfoModal?.propsId?.recordId}
          userInput={dbInfoModal?.propsId?.userInput}
          revisionId={dbInfoModal?.propsId?.revisionId}
          assumptionList={dbInfoModal?.propsId?.assumptionList}
          calculatorId={dbInfoModal?.propsId?.calculatorId}
          viewtype={viewtype ? null : "costingType"}
          costingcreate={viewtype ? true : false}

        />
      )}

      <TableRow>
        <TableCell
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            padding: "0.2rem 0.5rem 0.2rem 3.5rem",
            borderBottomColor: "primary.light",
            textTransform: "capitalize",
            fontSize: "12px",
          }}
        >
          {
            calcData?.name
          }
          {getShowStatus(
            calcData.content_type
          ) && (
              <Box>
                <InfoIcon
                  sx={{
                    fontSize: "1rem",
                    color: "#007fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dbInfoModal.open({
                      recordId: calcData?.revision_obj_id,
                      database: getIdFromMap(),
                      userInput: { id: calculatorId },
                      revisionId: revisionId,
                      assumptionList: assumptionDetails?.assumption_list,
                      calculatorId: calculatorId

                    })
                  }}
                />
              </Box>
            )}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            fontSize: "12px",
            width: "10rem",
            textAlign: "right",
          }}
        >
          {calcData?.value !==
            null &&
            `${typeof calcData?.value ===
              "number"
              ?
              formatNumber(calcData?.value)

              : calcData?.value
            }`}
        </TableCell>
        <TableCell
          sx={{
            padding:
              "0.2rem 0.5rem",
            borderBottomColor:
              "primary.light",
            fontSize:
              "12px",
            width:
              { lg: '12rem', xl: "18rem" },
            textAlign:
              "right",
          }}
        >
          {calcData?.unit
            ? calcData?.unit
            : ""}
        </TableCell>
        <TableCell
          sx={{
            padding:
              "0.2rem 0.5rem",
            borderBottomColor:
              "primary.light",
            fontSize:
              "12px",
            width:
              "7rem",
            textAlign:
              "center",
          }}
        >
          <Box
            sx={{
              display:
                "flex",
              alignItems:
                "center",
              justifyContent:
                "center",
              columnGap:
                "10px",
              lineHeight:
                "1",
            }}
          >
            {calcData?.flag && (
              <img
                src={
                  flag
                }
                style={{
                  width:
                    "1.5rem",
                  cursor:
                    "pointer",
                }}
                onClick={() =>
                  window.open(
                    calcData?.flag,
                    "_blank",
                    " noopener"
                  )
                }
              />
            )}
            {calcData?.db_file && (
              <img
                title="Machine Cost"
                src={
                  flagRed
                }
                style={{
                  width:
                    "1.5rem",
                  color:
                    "red",
                  cursor:
                    "pointer",
                }}
                onClick={() =>
                  window.open(
                    calcData?.db_file,
                    "_blank",
                    " noopener"
                  )
                }
              />
            )}
            {calcData?.db_spcification_file && (
              <img
                title="Machine Specification"
                src={
                  flagRed
                }
                style={{
                  width:
                    "1.5rem",
                  color:
                    "red",
                  cursor:
                    "pointer",
                }}
                onClick={() =>
                  window.open(
                    calcData?.db_spcification_file,
                    "_blank",
                    " noopener"
                  )
                }
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CostingViewProcessRow