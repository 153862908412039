import { Box, Divider, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 50rem",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };

function InputsWithValidation({isOpen, onCloseModal, inputs}:any) {
    const getConditionText = (condition: string, value: number) => {
        switch (condition) {
          case "lessThan":
            return `It should be less than ${value}`;
          case "greaterThan":
            return `It should be greater than ${value}`;
          case "lessThanEqual":
            return `It should be less than or equal to ${value}`;
          case "greaterThanEqual":
            return `It should be greater than or equal to ${value}`;
          case "equality":
            return `It should be equal to ${value}`;
          default:
            return "Unknown validation";
        }
      };
  return (
    <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Parameters with Validation Mismatch 
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />


<TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "primary.main", color: "#fff", }}>
                  <TableCell sx={{ color: "#fff", padding: "0.75rem" }}><strong>Parameter Name</strong></TableCell>
                  <TableCell sx={{ color: "#fff", padding: "0.75rem" }}><strong>Value</strong></TableCell>
                  <TableCell sx={{ color: "#fff", padding: "0.75rem" }}><strong>Validation</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody  adl-scrollbar="true"
      adl-scrollbar-width="0.3" sx={{maxHeight:"70vh"}}>
                {inputs?.map((item:any, index:any) => (
                  <TableRow key={index}>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.value}</TableCell>
                    <TableCell>{getConditionText(item?.field_custom_validation?.condition, item?.field_custom_validation?.value) || "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
      </Modal>
  )
}

export default InputsWithValidation