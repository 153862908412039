import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Button,
  IconButton,
  MenuItem,
  Typography,
  FormControl,
  Select,
  SelectChangeEvent,
  Chip,
  Skeleton,
} from "@mui/material";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AnalysisWeightLandingPage.module.scss";
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Label, Cell } from 'recharts';
import AnalyseMaterialModal from "./AnalyseMaterialModal";
import { useBaseAnalysisMaterial } from "./useBaseAnalysisMaterial";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import CancelIcon from '@mui/icons-material/Cancel';
import TableIcon from "../../Assets/images/svgs/tableIcon.svg"

//Dilalog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  topVault: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AnalysisMaterialLandingPageProps { }

const AnalysisMaterialLandingPage: React.FC<AnalysisMaterialLandingPageProps> = (props) => {
  const { projectId, topVault } = useRouteParams<Params>();
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
  const [analyseProjects, setAnalyseProjects] = useState<any>();
  const [analyseMaterialsDropdown, setAnalyseMaterialsDropdown] = useState<any>([]);
  const [dropdownOneValue, setDropdownOneValue] = useState<any>();
  const [dropdownTwoValue, setDropdownTwoValue] = useState<any>();
  const [dropdownThreeValue, setDropdownThreeValue] = useState<any>();
  const [dropdownFourValue, setDropdownFourValue] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [apiLoader, setApiLoader] = useState<boolean>(false);
  const [currentCount, setCurrentCount] = useState<any>(0)

  const [graphOneValue, setGraphOneValue] = useState<any>();
  const [graphTwoValue, setGraphTwoValue] = useState<any>();
  const [graphThreeValue, setGraphThreeValue] = useState<any>();
  const [graphFourValue, setGraphFourValue] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [analyseProduct, setAnalyseProduct] = useState<any>();

  const materialModal = useBaseAnalysisMaterial();
  const [okButtonLoader, setOKButtonLoader] = useState<boolean>(false);
  const [AllProjectdata, setAllProjectdata] = useState()
  const [Counter, setCounter] = useState(0)
  const [analysisModuleName, setAnalysisModuleName] = useState<any>();

  const [selectTwoDropdown, setSelectTwoDropdown] = useState<any>();
  const [selectOneDropdown, setSelectOneDropdown] = useState<any>();
  const [selectThreeDropdown, setSelectThreeDropdown] = useState<any>();
  const [selectFourDropdown, setSelectFourDropdown] = useState<any>();
  const [isTopVaultColorFetched, setIsTopVaultColorFetched] = useState<boolean>(false);
  const [topVaultColorMap, setTopVaultColorMap] = useState<any>();



  const [moduleApi, setModuleApi] = useState<any>();

  //Dialog BOx
  const [openDialog, setDialogOpen] = React.useState(false);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };


  const openMenu = Boolean(anchorElMenu);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { height, width } = useWindowDimensions();
  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",]

  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];
  const projectIdParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });

  var projectLength = AllProjectdata && Object.keys(AllProjectdata[0])?.length;

  const deleteProject = (item: any) => {
    setCounter(Counter + 1)
    var dataArray = [item];
    const filteredProject =
      AllProjectdata && AllProjectdata[0] && Object.keys(AllProjectdata[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = AllProjectdata[0][key];
          return obj;
        }, {});
    const FilterTopVault =
      filteredProject && Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);

    let valProject: any = [];
    let abc: any = filteredProject && Object.entries(filteredProject).forEach(([key, value]: any) => {
      valProject.push(filteredProject && filteredProject[key][0]['project_id']);
    });
    //  @ts-ignore
    history.push(`/analysis/${url?.includes('material') ? "material" : "category"}/${valProject?.length > 0 && valProject && valProject.toString()}/${FilterTopVault?.toString()}`)
  }

  const handleDelete = (item1: any) => {
    setCounter(Counter + 1)
    var ProjectIdsdata = AllProjectdata && AllProjectdata[0];
    var CollectionProjectids = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    }).flat(1).filter((item3: any, index: any) => { return item3 != item1 })

    var CollectionProjectidsABC = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    })

    let vaultsData: any = [];
    let vaultVal: any = [];
    let abc: any = ProjectIdsdata && Object.entries(ProjectIdsdata).forEach(([key, value]: any) => {
      vaultsData.push(ProjectIdsdata && ProjectIdsdata[key])
      // ?.map((item:any)=> item?.id == item1 && vaultsData.push(item)),"ProjectIdsdata");
    });

    const leftProduct: any = vaultsData?.flat()?.filter((item: any) => item?.id != item1);

    let projectIDLeft: any = [];
    let mapleftProduct: any = leftProduct && leftProduct?.map((item: any) => {
      if (projectIDLeft.includes(item?.project_id)) {
        return;
      }
      else {
        projectIDLeft.push(item?.project_id);
      }
    });
    // @ts-ignore
    history.push(`/analysis/material/${projectIDLeft.toString()}/${CollectionProjectids?.toString()}`)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getColorCode = () => {
    API.get("/customer/get_product_color/", {
      product_ids: topVault
    }, 0)
      .then((res) => {
        let topVaultColorObj: any = {};
        res?.data?.forEach((item: any, index: any) => {
          topVaultColorObj[item?.product_id] = item?.color;
        })
        setTopVaultColorMap(topVaultColorObj);
        setIsTopVaultColorFetched(true);

      })
      .catch((e) => {
        console.log(e);
      }
      )



  }

  React.useEffect(() => {
    getColorCode()
  }
    , [topVault, Counter]);

  const ChipContainer = (chipItem: any) => {
    var chipdata = AllProjectdata && AllProjectdata[0][chipItem]
    // @ts-ignore
    var mapChipData = chipdata?.map((item: any, index: any) => {
      return (<>{filterDataIds && filterDataIds?.length <= 2 ?
        <Chip
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            color: "primary.light !important",
            display: 'flex',
            columnGap: '5px',
            backgroundColor: '#007fff14 !important',
            "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChichangeOkButtonp-deleteIconColorPrimary":
            {
              fontSize: "1rem !important",
              color: 'primary.main !important',
            },
          }}
        /> :
        <Chip
          icon={
            <CancelIcon
              onClick={() => handleDelete(item?.id)}
              color="primary"
              sx={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: 'primary.main !important',
                opacity: 0.8,
                "& :hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              titleAccess="Delete"
            />
          }
          // onDelete={() => handleDelete(item?.id)}
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            margin: "2px 0",
            color: "primary.light !important",
            backgroundColor: "#007fff14 !important",
            '& :hover': {
              opacity: 1,
            }
          }}
        />}
      </>)
    })
    return mapChipData;
  }

  useEffect(() => {
    API.get(
      "/customer/analysis/",
      { get_drawer_values: "modules" },
      0
    ).then((res: any) => {
      setModuleApi(res?.data);
    });
  }, [])

  useEffect(() => {
    API.get("customer/analysis/", { product_ids: topVault, get_drawer_values: url?.includes('analysis_costing') ? 'Costing' : 'BOM' }).then((res: any) => {
      var datavalues = res?.data?.map((item: any, index: any) => {
        return Object.values(item).map((item1: any, index: any) => {
          return item1.map((item2: any, index: any) => { return item2?.project_id })
        })
      }).flat(2)
      //  @ts-ignore
      setAllProjectdata(res.data)
    })
  }, [topVault])


  const selectAllProject = (productIds: any) => {
    if (selectedProject?.includes(productIds?.toString())) {
      let letProjects: any = selectedProject && selectedProject?.filter((item: any) => item !== productIds?.toString());
      let remainingProductData: any = analyseProduct && analyseProduct?.filter((item: any) => { return selectedProduct?.includes(item?.id?.toString()) });
      let includedProductData: any = analyseProduct && remainingProductData?.filter((item: any) => { return letProjects?.includes(item?.project?.toString()) });
      let remainingProduct: any = analyseProduct && remainingProductData && includedProductData && includedProductData?.map((item: any) => { return item?.id?.toString() })
      analyseProduct && remainingProductData && includedProductData && remainingProduct && setSelectedProduct(remainingProduct);
      setSelectedProject((prev: any) =>
        prev?.filter((item: any) => item !== productIds?.toString())
      );
    } else {
      setSelectedProject((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const dropdownData: any = [];

  const dropdownDataGenerator: any = analyseMaterialsDropdown && analyseMaterialsDropdown?.map((item: any, index: number) => {
    item['key'] = index + 1;
    return dropdownData.push(item)
  })

  useEffect(() => { setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis') }, [projectId, topVault])

  useEffect(() => {
    if (selectedProject.length > 0 && selectedProject != undefined) {
      API.get(
        `customer/analysis/`,
        { get_drawer_values: "top_vault", project: selectedProject.toString(), module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' },
        0
      )
        .then((res: any) => {
          setAnalyseProduct(res?.data);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [selectedProject, analysisModuleName]);

  useEffect(() => {
    API.get(`customer/analysis/`, { get_drawer_values: "project", module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' }, 0)
      .then((res: any) => {
        setAnalyseProjects(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [analysisModuleName]);

  useEffect(() => {
    if (dropdownOneValue == undefined) {
      dropdownData[0]?.text && setDropdownOneValue(dropdownData[0]?.text)
    }
  }, [dropdownData])

  useEffect(() => {
    if (dropdownTwoValue == undefined) {
      dropdownData[1]?.text && setDropdownTwoValue(dropdownData[1]?.text)
    }
  }, [dropdownData])

  useEffect(() => {
    if (dropdownThreeValue == undefined) {
      dropdownData[2]?.text && setDropdownThreeValue(dropdownData[2]?.text)
    }
  }, [dropdownData])

  useEffect(() => {
    if (dropdownFourValue == undefined) {
      dropdownData[3]?.text && setDropdownFourValue(dropdownData[3]?.text)
    }
  }, [dropdownData])

  useEffect(() => {
    if (url?.includes("category")) {
      API.get(
        `/customer/analysis/`,
        {
          vehicle_ids: topVault,
          analysis_type: "get_all_category_part_bom"
        },
        0
      )
        .then((res: any) => {
          setAnalyseMaterialsDropdown(res?.data);
        })
        .catch((err: any) => {
          console.log(err, "error");
        })
    }
    else {
      API.get(
        `/customer/analysis/`,
        {
          vehicle_ids: topVault,
          analysis_type: "get_all_rawmaterials"
        },
        0
      )
        .then((res: any) => {
          setAnalyseMaterialsDropdown(res?.data);
          
        })
        .catch((err: any) => {
          console.log(err, "error");
        })
    }
  }, [projectId, topVault]);

  const handleNext4Item = () => {
    setCurrentCount((prev: any) => prev + 1);
    
    if(dropdownData.length > (currentCount+1)*4){
      setDropdownOneValue( dropdownData?.[(currentCount+1)*4].text);
    }
    if(dropdownData.length > (currentCount+1)*4 + 1){
      
      setDropdownTwoValue(dropdownData?.[(currentCount+1)*4 + 1].text);
    }
    if(dropdownData.length > (currentCount+1)*4 + 2){
      setDropdownThreeValue(dropdownData?.[(currentCount+1)*4 + 2].text);
    }
    if(dropdownData.length > (currentCount+1)*4 + 3){
      setDropdownFourValue(dropdownData?.[(currentCount+1)*4 + 3].text);
    }
    
    
    
    
  }

  useEffect(() => {
    if (dropdownOneValue != undefined) {
      if (url?.includes("category")) {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "category_part_analysis_subdistribution",
            category: dropdownOneValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphOneValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
      else {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "material_subdistribution",
            material: dropdownOneValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphOneValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
    }
  }, [projectId, topVault, dropdownOneValue, currentCount, topVaultColorMap, Counter, isTopVaultColorFetched]);

  

  useEffect(() => {
    if (dropdownTwoValue != undefined) {
      if (url?.includes("category")) {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "category_part_analysis_subdistribution",
            category: dropdownTwoValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphTwoValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
      else {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "material_subdistribution",
            material: dropdownTwoValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphTwoValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
    }
  }, [projectId, topVault, dropdownTwoValue, currentCount, topVaultColorMap, Counter, isTopVaultColorFetched]);

  useEffect(() => {
    if (dropdownThreeValue != undefined) {
      if (url?.includes("category")) {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "category_part_analysis_subdistribution",
            category: dropdownThreeValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphThreeValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
      else {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "material_subdistribution",
            material: dropdownThreeValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphThreeValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
    }
  }, [projectId, topVault, dropdownThreeValue, currentCount, topVaultColorMap, Counter, isTopVaultColorFetched]);

  useEffect(() => {
    if (dropdownFourValue != undefined) {
      if (url?.includes("category")) {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "category_part_analysis_subdistribution",
            category: dropdownFourValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphFourValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
      else {
        API.get(
          `/customer/analysis/`,
          {
            vehicle_ids: topVault,
            analysis_type: "material_subdistribution",
            material: dropdownFourValue,
            colors: topVault?.split(',')?.map((item: any, index: any) => {
            
              if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                return topVaultColorMap[item];
              }
              else {
                return topVaultColor[index];
              }})?.toString()
          },
          0
        )
          .then((res: any) => {
            setApiLoader(true);
            setGraphFourValue(res?.data);
          })
          .catch((err: any) => {
            setApiLoader(true);
            console.log(err, "error");
          })
      }
    }
  }, [projectId, topVault, dropdownFourValue, currentCount, topVaultColorMap, Counter, isTopVaultColorFetched]);


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setSelectedProject(projectId && projectId.toString()?.includes(',') ? projectId?.split(",") : [projectId?.toString()]);
        setSelectedProduct(topVault && topVault.toString()?.includes(',') ? topVault?.split(",") : [topVault?.toString()]);
        setState({ ...state, [anchor]: open });
        if (open) {
          setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis');
        }
        setCurrentCount(0);
      };


  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const selectAllProduct = (productIds: any) => {
    if (selectedProduct?.includes(productIds?.toString())) {
      setSelectedProduct((prev: any) =>
        prev?.filter((item: any) => item !== productIds.toString())
      );
    } else {
      setSelectedProduct((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  let disableOkBtn = () => {
    let temp = false;
    if (selectedProject?.length >= 1) {
      if (selectedProduct?.length >= 1) {
        if (selectedProduct?.length < 8) {
          temp = false;
        } else {
          temp = true;
        }
      } else {
        temp = true;
      }
    } else {
      temp = true;
    }
    return temp;
  };

  const changeOkButton = () => {
    setCurrentCount(0);
    setDropdownOneValue(dropdownData[0]?.text);
    setDropdownTwoValue(dropdownData[1]?.text);
    setDropdownThreeValue(dropdownData[2]?.text);
    setDropdownFourValue(dropdownData[3]?.text);
    if (analysisModuleName == 'analysis_costing') {
      history.push(`/${analysisModuleName}/${selectedProject.toString()}/${selectedProduct.toString()}`);
    }
    else {
      history.push(`/${analysisModuleName}/material/${selectedProject.toString()}/${selectedProduct.toString()}`);
    }
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ minWidth: 300, width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: '0' }}>
        <Box sx={{ width: '100%' }}>
          <Box style={{ display: "flex", columnGap: '5px', padding: '0 1rem', marginBottom: '0.5rem' }}>
            {moduleApi && moduleApi?.includes('BOM') &&
              <div onClick={() => { return setAnalysisModuleName('analysis'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName && analysisModuleName == 'analysis' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer', } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography style={analysisModuleName && analysisModuleName == 'analysis' ? { color: 'white', fontSize: '0.75rem' } : { color: '#007fff', fontSize: '0.75rem' }}>BOM</Typography>
              </div>}
            {moduleApi && moduleApi?.includes('Costing') &&
              <div style={analysisModuleName && analysisModuleName == 'analysis_costing' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography onClick={() => { return setAnalysisModuleName('analysis_costing'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName && analysisModuleName == 'analysis_costing' ? { color: 'white', fontSize: '0.75rem' } : { color: '#007fff', fontSize: '0.75rem' }}>Costing</Typography>{" "}
              </div>}
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: { lg: '82vh', xl: '81vh' }, padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {analyseProjects &&
              analyseProjects?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          style={{ padding: '3px' }}
                          checked={
                            selectedProject?.includes(item.id?.toString()) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id?.toString())}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: 24, xl: 30 } } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id?.toString())}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor:
                              getselectedProjectBackgroundColor(item.id?.toString()),
                            color: getSelectedProjectTextColor(item.id?.toString()),
                            textAlign: "center",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography style={{ fontSize: '0.75rem', textAlign: 'left', display: 'flex' }}>{item.name} <span style={{ marginLeft: '0.5rem', display: 'inline-block', width: '14rem' }}>[ {item.description} ]</span></Typography>
                        </Box>
                      </Box>
                      <div style={{ marginLeft: "2rem" }}>
                        {analyseProduct &&
                          analyseProduct
                            ?.filter((itemVal: any) => {
                              return itemVal?.project == item?.id;
                            })
                            ?.map((itemVal: any, indexVal: any) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      width: "18rem",
                                    }}
                                  >
                                    <Checkbox
                                      style={{ padding: '3px' }}
                                      checked={
                                        selectedProduct?.includes(
                                          itemVal?.id?.toString()
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        selectAllProduct(itemVal?.id)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: { lg: 24, xl: 30 },
                                          color: "#2b9cf6eb",
                                        },
                                      }}
                                    />
                                    <Box
                                      onClick={() =>
                                        selectAllProduct(itemVal?.id)
                                      }
                                      key={indexVal}
                                      className={styles.selectVehBtn}
                                      sx={{
                                        // margin: "0rem 2rem",
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor:
                                          getselectedProductBackgroundColor(
                                            itemVal.id
                                          ),
                                        color: getSelectedProductTextColor(
                                          itemVal.id
                                        ),
                                        textAlign: "center",
                                        cursor: "pointer",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography style={{ fontSize: '0.75rem', textAlign: 'left' }}>{itemVal.name}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                      </div>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div style={{ paddingBottom: '0.5rem', position: 'sticky', bottom: '0', height: '4rem', backgroundColor: "aliceblue" }}>
          <Box sx={{ height: "1rem" }}>
            {selectedProject?.length < 1 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select more than one project
              </Typography>
            )}
            {selectedProject?.length >= 1 && selectedProduct?.length < 1 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select more than one project
              </Typography >
            )}
            {selectedProduct?.length > 7 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select less than eight project
              </Typography >
            )}
          </Box>
          <Box sx={{ textAlign: "right", paddingRight: '1rem' }}>
            <Button
              className={styles.drawBtn}
              sx={{ marginRight: "1rem", fontSize: '0.75rem' }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton
              style={
                disableOkBtn()
                  ? { backgroundColor: "aliceblue", cursor: "not-allowed" }
                  : {}
              }
              loading={okButtonLoader}
              size="small"
              disabled={disableOkBtn()}
              onClick={changeOkButton}
            >
              <Button
                style={
                  disableOkBtn()
                    ? { backgroundColor: "#ddd", cursor: "not-allowed", color: 'grey' }
                    : {}
                }
                variant="contained"
                sx={{ fontSize: '0.75rem' }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );


  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push(`/analysis/${projectId}/${topVault}`)
  };

  const handleChangeOne = (event: SelectChangeEvent) => {
    setDropdownOneValue(event.target.value as string);
  };

  const handleChangeTwo = (event: SelectChangeEvent) => {
    setDropdownTwoValue(event.target.value as string);
  };

  const handleChangeThree = (event: SelectChangeEvent) => {
    setDropdownThreeValue(event.target.value as string);
  };

  const handleChangeFour = (event: SelectChangeEvent) => {
    setDropdownFourValue(event.target.value as string);
  };


  useEffect(() => {
    // if(dropdownOneValue!= undefined && dropdownFourValue!= undefined && dropdownThreeValue!= undefined) {
    setSelectTwoDropdown(dropdownData && dropdownData?.filter((item: any) => { return item?.text != dropdownOneValue && item?.text != dropdownFourValue && item?.text != dropdownThreeValue }))
    // }
    // if(dropdownTwoValue!= undefined && dropdownFourValue!= undefined && dropdownThreeValue!= undefined){
    setSelectOneDropdown(dropdownData && dropdownData?.filter((item: any) => { return item?.text != dropdownTwoValue && item?.text != dropdownFourValue && item?.text != dropdownThreeValue }))

    // }
    // if(dropdownOneValue!= undefined && dropdownFourValue!= undefined && dropdownTwoValue!= undefined){
    setSelectThreeDropdown(dropdownData && dropdownData?.filter((item: any) => { return item?.text != dropdownOneValue && item?.text != dropdownFourValue && item?.text != dropdownTwoValue }))

    // }
    // if(dropdownOneValue!= undefined && dropdownTwoValue!= undefined && dropdownThreeValue!= undefined){
    setSelectFourDropdown(dropdownData && dropdownData?.filter((item: any) => { return item?.text != dropdownOneValue && item?.text != dropdownTwoValue && item?.text != dropdownThreeValue }))

    // }
  }, [dropdownOneValue, dropdownThreeValue, dropdownFourValue, dropdownTwoValue, projectId, topVault])

  const CustomizedOneLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.75rem"
        fontFamily="sans-serif"
        fill={"#000000"}
        textAnchor="middle"
      >
        {url?.includes('category') ? value : `${(value / 1000).toFixed(2)} Kg`}
      </text>
    );
  };

  const CustomizedTwoLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.75rem"
        fontFamily="sans-serif"
        fill={"#000000"}
        textAnchor="middle"
      >
        {url?.includes('category') ? value : `${(value / 1000).toFixed(2)} Kg`}
      </text>
    );
  };

  const CustomizedThreeLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.75rem"
        fontFamily="sans-serif"
        fill={"#000000"}
        textAnchor="middle"
      >
        {url?.includes('category') ? value : `${(value / 1000).toFixed(2)} Kg`}
      </text>
    );
  };

  const CustomizedFourLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.75rem"
        fontFamily="sans-serif"
        fill={"#000000"}
        textAnchor="middle"
      >
        {url?.includes('category') ? value : `${(value / 1000).toFixed(2)} Kg`}
      </text>
    );
  };

  return (
    // <LetSuspense
    //   loadingPlaceholder={BouncingLoader}
    //   condition={analyseMaterialsDropdown?.length > 0}
    // >
    <>
      <AnalyseMaterialModal isOpen={materialModal.isOpen}
        onClose={materialModal.close}
        analyseMaterial={materialModal.propsData}
        materialName={materialModal.propsMaterialName}
      />
      <Box>
        <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '5px' , justifyContent: "space-between", width: "100%"}}>
            <Button variant="contained" size="small">
              Default Analysis
            </Button>
            {/* <span style={{ padding: '0.5rem', marginLeft: '1.3rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', color: 'white', backgroundColor: '#007fff', fontSize: '1rem' }}>Default Analysis</span> */}
            {/* <span style={{ padding: '0.5rem', marginLeft: '0.2rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', fontSize: '1rem' }}>+ Add More Analysis</span> */}

            {/* <Button variant="outlined" disabled size="small" onClick={handleDialogClickOpen}>
              + Add More Analysis
            </Button> */}
            <Dialog
              open={openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleDialogClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Add More Analysis
                </Typography>
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleDialogClose} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Default Analysis Content section  Default Analysis Content sectionDefault Analysis Content section
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleDialogClose}>Close</Button> */}
                <Button onClick={handleDialogClose}>Ok</Button>
              </DialogActions>
            </Dialog>
             <Button variant="contained" size="small" onClick={handleNext4Item} disabled={dropdownData.length <= currentCount*4 + 3}>Next 4 Materials</Button>
          </Box>
        </Box>
        <Box sx={{ height: '3rem', display: 'flex', alignItems: 'center', marginLeft: "4.4rem",  }}>
          <Box sx={{ display: "flex", flexDirection: "row", columnGap: '10px' }}>{/* @ts-ignore */}
            {AllProjectdata && AllProjectdata?.map((item: any, index: any) => {
              return Object.keys(item).map((key: any, index: any) => {
                return (
                  <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '1.5rem' }}>
                    {(filterDataIds && filterDataIds?.length <= 2 && projectLength && projectLength <= 2) || (projectLength && projectLength == 1 && filterDataIds?.length > 2) || (projectLength && projectLength == 2 && filterDataIds?.length < 5) ? " " :
                      <CancelIcon titleAccess="Delete" sx={{
                        "& :hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }} onClick={() => deleteProject(key)} style={{ cursor: "pointer" }} />
                    } <Typography style={{ fontSize: '1rem', paddingLeft: '5px' }}>{key}</Typography>
                    <Box sx={{ display: 'flex', columnGap: '5px' }}>
                      {ChipContainer(key)}
                    </Box>
                  </Box>)
              })
            })}
          </Box>
          
        </Box>
      </Box>
      <div style={{ display: "grid", gridTemplateColumns: '4rem 1fr' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box sx={{ padding: "1rem 0rem", paddingBottom: '0' }}>
                    <Box sx={{ position: 'sticky', top: '0' }}>
                      <Typography style={{ width: "100%", textAlign: "center", fontSize: '1rem', }}>
                        Select to Change Analysis <br /> ( Project / Products )
                      </Typography>
                      <Divider sx={{ margin: "0.5rem" }} />
                    </Box>
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "49vh",
                    cursor: "pointer",
                    fontSize: '1rem'
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select to Change Analysis ( Project / Products )
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr', md: '1fr', lg: '1fr 1fr', xl: '1fr 1fr' }, width: '100%', height: '89vh' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box sx={{ paddingRight: '1rem' }}>
            <Box>
              <Box sx={{ position: 'relative', zIndex: '1', float: 'right', display: 'flex' }}>
                {graphOneValue && graphOneValue.length > 0 && <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', }} onClick={() => { materialModal.open(graphOneValue && graphOneValue, dropdownOneValue); }} />}
                <FormControl style={{ width: '9rem', marginLeft: '2rem' }}>
                  {/* <InputLabel id="demo-simple-select-label" style={{color:'#007fff',marginLeft:'-0.8rem'}}>
              Select Material to Compare
            </InputLabel> */}
                  <>{apiLoader ? <>
                    {graphOneValue && graphOneValue.length > 0 &&
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={selectOneDropdown && selectOneDropdown[0]?.text}
                        label="dropdownData"
                        variant="standard"
                        value={dropdownOneValue}
                        onChange={handleChangeOne}
                        placeholder="Select Material for Comparison"
                        sx={{ width: '9rem', fontSize: '1rem' }}
                      >
                        {selectOneDropdown && selectOneDropdown?.map((name: any, index: any) => {
                          return <MenuItem
                            key={name.text}
                            value={name.text}
                            sx={{ padding: '0.2rem 0.5rem', fontSize: '1rem' }}
                          >
                            {name.text}
                          </MenuItem>
                        })}
                      </Select>}
                  </> :
                    <div>
                      <Skeleton animation="pulse" width={100} height={50} sx={{ marginLeft: '1.5rem' }} />
                    </div>
                  }</>
                </FormControl>
              </Box>
              <Box sx={{ width: '100%', height: '42vh' }}>
                <>{apiLoader ? <>
                  {graphOneValue && graphOneValue.length > 0 && (
                    <ResponsiveContainer >
                      <BarChart
                        margin={{
                          top: 20,
                          right: 20,
                          left: 20,
                          // bottom: width > 1370 ? 140 : 120,
                          bottom: 50
                        }}
                        data={graphOneValue && graphOneValue}
                        className={styles.BarChartAnalysis}
                      >
                        <Bar
                          barSize={100}
                          dataKey="value"
                          fill="rgb(144, 238, 144)"
                          style={{ overflow: "visible" }}
                          label={<CustomizedOneLabel />}
                        >
                          {
                            graphOneValue && graphOneValue?.map((entry: any) => {
                              return <Cell fill={entry?.color} />
                            })}
                        </Bar>
                        <XAxis dataKey="name" textAnchor="middle" stroke="#000000" fontSize="0.75rem" interval={0} />
                        <YAxis type="number" domain={[0, 100]} tickFormatter={(tick) => `${url?.includes('category') ? tick : (tick / 1000)}`} stroke="#000000" fontSize="0.75rem">
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "0.75rem",
                            }}
                            fill='black'
                            angle={270}
                            dx={-22}
                            value={url?.includes('category') ? "Parts (Qty)" : "Material (Kg)"}
                          />
                        </YAxis>
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </> :
                  <div>
                    <Skeleton animation="pulse" width={900} height={480} />
                  </div>
                }
                </>
              </Box>
            </Box>
            <Box >
              <Box sx={{ position: 'relative', zIndex: '1', float: 'right', display: 'flex' }}>
                {graphThreeValue && graphThreeValue.length > 0 && <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', }} onClick={() => { materialModal.open(graphThreeValue && graphThreeValue, dropdownThreeValue); }} />}
                <FormControl style={{ width: '9rem', marginLeft: '2rem' }}>
                  {/* <InputLabel id="demo-simple-select-label" style={{ color: '#007fff', marginLeft: '-0.8rem' }}>
                  Select Material to Compare
                </InputLabel> */}
                  <>{apiLoader ? <>
                    {graphThreeValue && graphThreeValue.length > 0 &&
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={selectThreeDropdown && selectThreeDropdown[1]?.text}
                        label="dropdownData"
                        variant="standard"
                        value={dropdownThreeValue}
                        onChange={handleChangeThree}
                        placeholder="Select Material for Comparison"
                        sx={{ width: '9rem', fontSize: '1rem' }}
                      >
                        {selectThreeDropdown && selectThreeDropdown?.map((name: any, index: any) => {
                          return <MenuItem
                            key={name.text}
                            value={name.text}
                            sx={{ padding: '0.2rem 0.5rem', fontSize: '1rem' }}
                          >
                            {name.text}
                          </MenuItem>
                        })}
                      </Select>}
                  </> :
                    <div>
                      <Skeleton animation="pulse" width={100} height={50} sx={{ marginLeft: '1.5rem' }} />
                    </div>
                  }</>
                </FormControl>
              </Box>
              <Box sx={{ width: '100%', height: '42vh' }}>
                <>{apiLoader ? <>
                  {graphThreeValue && graphThreeValue.length > 0 && (
                    <ResponsiveContainer>
                      <BarChart
                        margin={{
                          top: 20,
                          right: 20,
                          left: 20,
                          // bottom: width > 1370 ? 140 : 120,
                          bottom: 50
                        }}
                        data={graphThreeValue && graphThreeValue}
                        className={styles.BarChartAnalysis}
                      >
                        <Bar
                          barSize={100}
                          dataKey="value"
                          fill="rgb(144, 238, 144)"
                          style={{ overflow: "visible" }}
                          label={<CustomizedThreeLabel />}
                        >
                          {
                            graphThreeValue && graphThreeValue?.map((entry: any) => {
                              return <Cell fill={entry?.color} />
                            })}
                        </Bar>
                        <XAxis dataKey="name" textAnchor="middle" stroke="#000000" fontSize="0.75rem" interval={0} />
                        <YAxis type="number" tickFormatter={(tick) => `${url?.includes('category') ? tick : (tick / 1000)}`} stroke="#000000" fontSize="0.75rem" >
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "0.75rem",
                            }}
                            fill='black'
                            angle={270}
                            dx={-22}
                            value={url?.includes('category') ? "Parts (Qty)" : "Material (Kg)"}
                          />
                        </YAxis>
                      </BarChart>
                    </ResponsiveContainer>
                  )}</> :
                  <div>
                    <Skeleton animation="pulse" width={900} height={480} />
                  </div>
                }
                </>
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingRight: '1rem' }}>
            <Box>
              <Box sx={{ float: 'right', display: 'flex', position: 'relative', zIndex: '1' }}>
                {graphTwoValue && graphTwoValue.length > 0 && <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', }} onClick={() => { materialModal.open(graphTwoValue && graphTwoValue, dropdownTwoValue); }} />}
                <FormControl style={{ width: '9rem', marginLeft: '2rem' }}>
                  {/* <InputLabel id="demo-simple-select-label" style={{ color: '#007fff', marginLeft: '-0.8rem' }}>
                Select Material to Compare
              </InputLabel> */}
                  <>{apiLoader ? <>
                    {graphTwoValue && graphTwoValue.length > 0 &&
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={selectTwoDropdown && selectTwoDropdown[1]?.text}
                        label="dropdownData"
                        variant="standard"
                        value={dropdownTwoValue}
                        onChange={handleChangeTwo}
                        placeholder="Select Material for Comparison"
                        sx={{ width: '9rem', fontSize: '1rem' }}
                      >
                        {selectTwoDropdown && selectTwoDropdown?.map((name: any, index: any) => (
                          <MenuItem
                            key={name.text}
                            value={name.text}
                            sx={{ padding: '0.2rem 0.5rem', fontSize: '1rem' }}
                          >
                            {name.text}
                          </MenuItem>
                        ))}
                      </Select>}
                  </> :
                    <div>
                      <Skeleton animation="pulse" width={100} height={50} sx={{ marginLeft: '1.5rem' }} />
                    </div>
                  }</>
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", height: '42vh' }} >
                <>{apiLoader ? <>
                  {graphTwoValue && graphTwoValue.length > 0 && (
                    <ResponsiveContainer>
                      <BarChart
                        margin={{
                          top: 20,
                          right: 20,
                          left: 20,
                          // bottom: width > 1370 ? 140 : 120,
                          bottom: 50
                        }}
                        data={graphTwoValue && graphTwoValue}
                        className={styles.BarChartAnalysis}
                      >
                        <Bar
                          barSize={100}
                          dataKey="value"
                          fill="rgb(144, 238, 144)"
                          style={{ overflow: "visible" }}
                          label={<CustomizedTwoLabel />}
                        >
                          {
                            graphTwoValue && graphTwoValue?.map((entry: any) => {
                              return <Cell fill={entry?.color} />
                            })}
                        </Bar>
                        <XAxis dataKey="name" textAnchor="middle" stroke="#000000" fontSize="0.75rem" interval={0} />
                        <YAxis type="number" tickFormatter={(tick) => `${url?.includes('category') ? tick : (tick / 1000)}`} stroke="#000000" fontSize="0.75rem"  >
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "0.75rem",
                            }}
                            fill='black'
                            angle={270}
                            dx={-22}
                            value={url?.includes('category') ? "Parts (Qty)" : "Material (Kg)"}
                          />
                        </YAxis>
                      </BarChart>
                    </ResponsiveContainer>
                  )}</> :
                  <div>
                    <Skeleton animation="pulse" width={900} height={480} />
                  </div>
                }
                </>
              </Box>
            </Box>
            <Box>
              <Box sx={{ float: 'right', display: 'flex', position: 'relative', zIndex: '1' }}>
                {graphFourValue && graphFourValue.length > 0 && <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', }} onClick={() => { materialModal.open(graphFourValue && graphFourValue, dropdownFourValue); }} />}
                <FormControl style={{ width: '9rem', marginLeft: '2rem' }}>
                  {/* <InputLabel id="demo-simple-select-label" style={{ color: '#007fff', marginLeft: '-0.8rem' }}>
                Select Material to Compare
              </InputLabel> */}
                  <>{apiLoader ? <>
                    {graphFourValue && graphFourValue.length > 0 &&
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={selectFourDropdown && selectFourDropdown[1]?.text}
                        label="dropdownData"
                        variant="standard"
                        value={dropdownFourValue}
                        onChange={handleChangeFour}
                        placeholder="Select Material for Comparison"
                        sx={{ width: '9rem', fontSize: '1rem' }}
                      >
                        {selectFourDropdown && selectFourDropdown?.map((name: any, index: any) => (
                          <MenuItem
                            key={name.text}
                            value={name.text}
                            sx={{ padding: '0.2rem 0.5rem', fontSize: '1rem' }}
                          >
                            {name.text}
                          </MenuItem>
                        ))}
                      </Select>}
                  </> :
                    <div>
                      <Skeleton animation="pulse" width={100} height={50} sx={{ marginLeft: '1.5rem' }} />
                    </div>
                  }</>
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", height: '42vh' }}>
                <>{apiLoader ? <>
                  {graphFourValue && graphFourValue.length > 0 && (
                    <ResponsiveContainer>
                      <BarChart
                        margin={{
                          top: 20,
                          right: 20,
                          left: 20,
                          // bottom: width > 1370 ? 140 : 120,
                          bottom: 50
                        }}
                        data={graphFourValue && graphFourValue}
                        className={styles.BarChartAnalysis}
                      >
                        <Bar
                          barSize={100}
                          dataKey="value"
                          fill="rgb(144, 238, 144)"
                          style={{ overflow: "visible", textAlign: 'center' }}
                          label={<CustomizedFourLabel />}
                        >
                          {
                            graphFourValue && graphFourValue?.map((entry: any) => {
                              return <Cell fill={entry?.color} />
                            })}
                        </Bar>
                        <XAxis dataKey="name" textAnchor="middle" stroke="#000000" fontSize="0.75rem" interval={0} />
                        <YAxis type="number" tickFormatter={(tick) => `${url?.includes('category') ? tick : (tick / 1000)}`} stroke="#000000" fontSize="0.75rem">
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "0.75rem",
                            }}
                            fill='black'
                            angle={270}
                            dx={-22}
                            value={url?.includes('category') ? "Parts (Qty)" : "Material (Kg)"}
                          />
                        </YAxis>
                      </BarChart>
                    </ResponsiveContainer>
                  )} </> :
                  <div>
                    <Skeleton animation="pulse" width={900} height={480} />
                  </div>
                }
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
    // </LetSuspense >
  );
};
export default AnalysisMaterialLandingPage;
