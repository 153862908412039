import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Checkbox, Input, MenuItem, CircularProgress } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from '@mui/icons-material/Download';
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import { API } from "../../api-services";
import calcImage from "../../Assets/images/calculator.svg";
import calcImageGreen from "../../Assets/images/calculator_green.svg";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import DatabaseCalculatorTool from "../ParameterLibrary/DatabasecalculatorTool";
import DatabaseCalculatorConfig from "../ParameterLibrary/DatabasecalculatorConfig";
import SettingsIcon from '@mui/icons-material/Settings';
import Swal from "sweetalert2";
import { ADMIN } from "../../Redux/Services/admin.service";
interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  Item: any;
  setCounter: any;
  swalalterHandler: any;
  getToolData?:any
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const validationSchema = yup.object({
  shots: yup.string().required("Please enter Life"),
  price: yup.string().required("Please enter Tool cost"),
  details: yup.string().required("Please enter reference"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});

const ToolEditModal = (props: InfoModalProps) => {
  const openConfig = useBaseParamsModal();
  const { onCloseModal, isOpen, Item, setCounter, swalalterHandler, getToolData } = props;
  const { height, width } = useWindowDimensions();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);  
  const [downloadLoading, setDownloadLoading] = React.useState<any>(false);
  const [uploadLoading, setUploadLoading] = React.useState<any>(false);

  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [resStatus, setResStatus] = React.useState<any>(false);
  const [actionCount, setActionCount] = React.useState<any>(0);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [isCal, setIsCal] = React.useState<boolean>(
    !Item?.tool_cost_is_user_input || false
  );
  const [isuserInput, setIsUserInput] = React.useState<boolean>(false);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");
  const openCal = useBaseParamsModal();
  // ----------------------------------------------


  React.useEffect(() => {
    if (Item && actionCount === 0) {
      setIsUserInput(Item?.tool_cost_is_user_input || false);
      setActionCount(actionCount+1)
    }
  }, [Item]);



  React.useEffect(() => {
    if (Item) {
      if (Item?.currency_code) {
        setSelectedCurrencyCodes([Item?.currency_code]);
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("details", Item?.reference);
      formik.setFieldValue("price", Item?.toolCost);
      formik.setFieldValue("shots", Item?.life);
      formik.setFieldValue("unit", Item.unit);
      formik.setFieldValue("currency_id", Item?.currency_id);
      formik.setFieldValue("currency_code", Item?.currency_code);
    }
  }, [Item]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCal(event.target.checked);
    setIsUserInput(event.target.checked);
  };

  // console.log(Item);

  const formik = useFormik({
    initialValues: {
      details: "",
      price: "",
      shots: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //   setIsloading(true);
      let obj = {};
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
      //   if (values.revision === "true")

      {
        obj = {
          details: values.details,
          price: values.price,
          shots: values.shots,
          currency_id: values.currency_id,
          unit: values.unit,
          tool_cost_is_user_input: isuserInput,
        };

        API.put(`/api/db/tool_cost/${Item?.Ids}/`, obj, {}, 0)
          .then((res: any) => {
            setIsLoadingSave(false);
            swalalterHandler();
            onCloseModal();
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});



  const uploadFile = async (file: any) => {
    
    const formData = new FormData();
    formData.append("file", file); // Append the file
    formData.append("tool_id", Item?.Ids); // Append additional data
    formData.append("calc_type", "tool"); // Append additional data
  
      API.post(
        `api/db/upload_db_calculator/`,
        formData,
        {},
        0
      )
        .then((res: any) => {
          setUploadLoading(false);
          getToolData();
          ADMIN.toast.info("File uploaded successfully");
          //onCloseModal();
        })
        .catch((err: any) => {
          setIsLoadingSave(false);
        });
    };

  const handleDownload = (ed:any) => {
    
      ed.stopPropagation();
      ed.preventDefault();
      setDownloadLoading(true);
      
      API.post(
          "api/db/download_db_calculator/",
          { calc_type: "tool", tool_id:Item?.Ids },
          {},
          0
      )
          .then((res: any) => {
              setDownloadLoading(false);
              window.open(res.data);
          })
          .catch((err: any) => {
              setDownloadLoading(false);
              Swal.fire({
                  title: "Error!",
                  text: "Error in downloading file",
                  icon: "error",
                  confirmButtonText: "Ok",
              });
          });
 
  }

  // console.log(Item);
  

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Tool Revision
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Life <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="shots"
                    name="shots"
                    onChange={formik.handleChange}
                    value={formik.values.shots}
                    multiline
                    error={formik.touched.shots && Boolean(formik.errors.shots)}
                    helperText={formik.touched.shots && formik.errors.shots}
                  />
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "100%",
                  }}
                >
                <Box sx={{ display: "flex", alignItems: "center",width:'24rem', }}>
                    <Typography sx={{fontSize:'1rem',whiteSpace:'nowrap',mr:0.8}}>Tool Cost (Currency): {" "} </Typography>
                    <img
                      src={(Item?.result && Item?.result?.length>0) ? calcImageGreen : calcImage}
                      style={{ width: "1.5rem", height: "1.5rem",opacity:!isuserInput?1:0.5 ,cursor:'pointer'}}
                    
                      onClick={()=>{
                        if(!isuserInput){
                         openConfig.open()
                        }
                         
                         }}
                    />
                    {/* color:(resStatus && resStatus>0)?"green":"red" */}
                    <SettingsIcon sx={{cursor:'pointer',opacity:!isuserInput?1:0.5,mr:0.8,color:(Item?.result && Item?.result?.length>0)?"green":'red' }}   onClick={() => {
                        if(!isuserInput){
                        openCal.open()
                        }
                      
                      }}/>

{(!Item?.tool_calc || Item?.tool_calc == undefined || Item?.tool_calc == null) &&<label htmlFor="file-upload">
                    
                      <>
                        {uploadLoading ? <CircularProgress size={"15px"} sx={{marginTop:"0.5rem"}} />
                       : 
                       <UploadIcon sx={{cursor:"pointer", marginTop:"0.5rem"}}/>}
                      </>
                  

      </label>}

      {(Item?.tool_calc !== undefined && Item?.tool_calc !== null) && (
                        <>
                          {downloadLoading ? (
                            <CircularProgress
                              size={"15px"}
                              sx={{ marginTop: "0.5rem" }}
                            />
                          ) : (
                            <DownloadIcon
                              sx={{ cursor: "pointer", marginTop: "0.5rem" }}
                              onClick={(e: any) => handleDownload(e)}
                            />
                          )}
                        </>
                      )}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      // mr: "5rem"
                      // marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>
                  <Box sx={{display:'flex',alignItems:'center',}}>
                  <Checkbox onChange={handleCheckboxChange} checked={isuserInput} />
                  {/* <a>Enter cost manually</a> */}
                  </Box>
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    label={
                      <span>
                        Enter manually{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    disabled={!isuserInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="price"
                    name="price"
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    //@ts-ignore
                    helperText={formik.touched.price && formik.errors.price}
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    multiline
                  />
                 
                  
                  {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                </FormControl>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" flexDirection="column">
     
      <input
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e:any)=> uploadFile(e.currentTarget.files[0])}
      />

      
      
    </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={<span>Reference</span>}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="details"
                    name="details"
                    onChange={formik.handleChange}
                    value={formik.values.details}
                    multiline
                    error={
                      formik.touched.details && Boolean(formik.errors.details)
                    }
                    helperText={formik.touched.details && formik.errors.details}
                  />
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "100%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);

                        formik.setFieldValue(
                          "currency_id",
                          String(selectedCurIds)
                        );
                        formik.setFieldValue(
                          "currency_code",
                          String(event.target.value)
                        );
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_code &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_code &&
                        formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                >
                  {"Save"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>

      {openCal.isOpen && (
        <DatabaseCalculatorTool
          isOpen={openCal.isOpen}
          onCloseModal={openCal.close}
          id={Item.id}
          inputData={Item}
          setCounter={setCounter}
          parentClose={onCloseModal}
        />
      )}

{openConfig.isOpen && (
        <DatabaseCalculatorConfig
          isOpen={openConfig.isOpen}
          onCloseModal={openConfig.close}
          id={Item.id}
          inputData={Item}
          setCounter={setCounter}
          parentClose={onCloseModal}
          categoryName={""}
          setResStatus={setResStatus}
          typee={"Tool"}
        />
      )}
    </div>
  );
};

export default ToolEditModal;
