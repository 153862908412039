import {
  Box,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import HierarchyTree from "../../BOMCreate/HierarchyTree";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface ILocakFlowProps {}

export function LocakFlow(props: ILocakFlowProps) {
  const { vault } = useRouteParams<any>();
  const [treeData, setTreeData] = React.useState<any>();
  const [treeDataLoading, setTreeDataLoading] = React.useState<boolean>(true);
  const [topTableData, setTopTableData] = React.useState<any>({});
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const poid = open ? "simple-popover" : undefined;

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Handler for value change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const getTableData = () => {
    API.get(
      "/xcpep/part_resources_link/",
      { vault_id: vault, part_info: true },
      0
    )
      .then((res: any) => {
        setTopTableData(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getTableData();
  }, []);

  const getTreeData = () => {
    setTreeDataLoading(true);
    API.get(
      "/xcpep/part_resources_link/",
      { vault_id: vault, dependency_flow: true, show_filter: selectedValue },
      0
    )
      .then((res: any) => {
        setTreeData(res.data);
        setTreeDataLoading(false);
      })
      .catch((err: any) => {
        setTreeDataLoading(false);
      });
  };

  React.useEffect(() => {
    if (vault != 0) {
      getTreeData();
    }
  }, [selectedValue]);

  React.useEffect(() => {
    let a: any = sessionStorage.getItem("workflowNode");
    let val: any = JSON.parse(a) || [];
    if (val && val?.length > 1) {
    } else {
      sessionStorage.setItem("workflowNode", JSON.stringify([1]));
    }
  }, [treeData]);

  const onSelect = (value: any, abbreviation: any, topv: any, status: any) => {
    // console.log("statussss", status);
  };

  return (
    <Box>
      <Box sx={{ height: "10vh", width: "100vw" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "15%",
                }}
              >
                Part No
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                }}
                align="center"
              >
                Assumption Card
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "10%",
                }}
              >
                RM Cost
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "10%",
                }}
              >
                Process Cost
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "10%",
                }}
              >
                Overhead Cost
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "10%",
                }}
              >
                Child Cost
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  backgroundColor: "primary.light",
                  width: "10%",
                }}
              >
                Total Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(topTableData)?.length > 0 && (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <span title={topTableData?.part_name}>
                    {topTableData?.part_no || "--"}
                  </span>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textDecoration:
                      topTableData?.other_assumptions?.length > 0
                        ? "underline"
                        : "none",
                    cursor:
                      topTableData?.other_assumptions?.length > 0
                        ? "pointer"
                        : "default",
                  }}
                  align="center"
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{ width: "fit-content", padding: "0.2rem 0.5rem" }}
                      onClick={(ed: any) => {
                        if (topTableData?.other_assumptions?.length > 0) {
                          handlePopoverOpen(ed);
                        }
                      }}
                    >
                      {topTableData?.other_assumptions?.length > 0
                        ? topTableData?.other_assumptions?.length + 1
                        : `${topTableData?.main_assumption?.city} - ${topTableData?.main_assumption?.country} - ${topTableData?.main_assumption?.item_name}` ||
                          "--"}
                    </Box>
                  </Box>
                  <Popover
                    id={poid}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    disableRestoreFocus
                    sx={{
                      ".MuiPaper-root.MuiPopover-paper": {
                        borderRadius: "1rem",
                        padding: "1rem",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      {/* Main Assumption Card */}

                      {/* Other Assumption Card */}
                      <Box>
                        {/* <Box
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            color: "primary.main",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Other Assumption Card
                        </Box> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "1rem",
                              color: "primary.amin",
                              padding: "0.5rem",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "0.5rem",
                            }}
                          >
                            {`${topTableData?.main_assumption?.city} - ${topTableData?.main_assumption?.country} - ${topTableData?.main_assumption?.item_name}`}
                          </Box>
                          {topTableData?.other_assumptions?.map(
                            (ed: any, index: number) => (
                              <Box
                                key={index}
                                sx={{
                                  fontSize: "1rem",
                                  color: "primary.amin",
                                  padding: "0.5rem",
                                  backgroundColor: "#f5f5f5",
                                  borderRadius: "0.5rem",
                                }}
                              >
                                {`${ed.city} - ${ed.country} - ${ed.item_name}`}
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Popover>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {topTableData?.rm_cost?.toFixed(2) || "--"}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {topTableData?.process_cost?.toFixed(2) || "--"}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {topTableData?.overhead_cost?.toFixed(2) || "--"}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {topTableData?.child_cost?.toFixed(2) || "--"}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {topTableData?.cost?.toFixed(2) || "--"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          height: { lg: "82vh", xl: "83vh" },
          width: "100vw",
          overflow: "auto", // Enable scrolling if content goes beyond the view
          backgroundColor: "white",
        }}
      >
        {!treeDataLoading ? (
          <HierarchyTree
            HierarchyData={treeData && treeData}
            onSelectCallback={onSelect}
          />
        ) : (
          <>
            <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "35%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "80%" }} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
