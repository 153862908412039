import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface ICostingInfoModalProps {
  onCloseModal: any;
  isOpen: any;
  material: any;
  handleUnCheck:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '40vw',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const isUrl = (url:any) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export function CostingInfoModal(props: ICostingInfoModalProps) {
  const { onCloseModal, isOpen, material, handleUnCheck } = props;
  const { calctype } = useRouteParams<any>();
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const getBody = () => {
    console.log(material, "materialmaterial")
    if(calctype ==1){

    
    return <TableBody>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Material Code
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.material_code}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Material Category
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.category}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Grade
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.material_grade}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Sub Grade
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.material_subgrade}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Shape
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.shape}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Density(kg/m3)
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {" "}
          {material?.material?.density}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Remarks
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material?.description}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Per unit cost(INR)
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {material?.material_cost?.toFixed(2)}
        </TableCell>
        </TableRow> */}
        {material?.weight_input != null && <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Selected Parameter
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.weight_input_name} - {material?.weight_input}
            <Checkbox
                                    defaultChecked
                                    onClick={handleUnCheck}
                                  />
          </TableCell>
        </TableRow>}
      
    </TableBody>;
    }else if(calctype==2){
        return <TableBody>
        <TableRow>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            BOP Code
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.purchasing_item?.code}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            BOP Name
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.purchasing_item?.purchase_name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Item Details
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.purchasing_item?.item_detail}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Length(mm)
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
           {material?.purchasing_item?.length}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Width(mm)
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
              {material?.purchasing_item?.width}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Height(mm)
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.purchasing_item?.height}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Weight(kg)
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
           {material?.purchasing_item?.weight}
          </TableCell>
        </TableRow>
     
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Place of Mfg.
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.purchasing_item?.assumption}
          </TableCell>
        </TableRow>
      
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Reference
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {isUrl(material?.purchasing_item?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(material?.purchasing_item?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {material?.purchasing_item?.reference}
            </span>}
            
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Per unit cost(INR)
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.bop_cost?.toFixed(2)}
          </TableCell>
        </TableRow>
        {material?.weight_input != null && <TableRow>
          <TableCell
            sx={{width:'15rem', padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            Selected Parameter
          </TableCell>
          <TableCell
            sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
          >
            {material?.weight_input_name} - {material?.weight_input}
            <Checkbox
                                    defaultChecked
                                    onClick={handleUnCheck}
                                  />
          </TableCell>
        </TableRow>}
      </TableBody>
    }
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {calctype==1?"Material":"BOP"} Additional Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Table>{getBody()}</Table>
        </Box>
      </Modal>
    </div>
  );
}
