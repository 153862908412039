import React, { useEffect, useState } from "react";


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from "@mui/material/Divider";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "30vw",
  maxWidth: '40vw',

  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
export interface ICreateEstimationTreeProps {
  isOpen: any;
  handleClose: any;
  Item: any;
  callbackHandler: any;
  TabStatus: any;
  loader: any;

}

const BOMDataFlagModal: React.FC<ICreateEstimationTreeProps> = (props) => {
  const { vault, topVault, projectId, projectname, productId, abbrebation } =
    useRouteParams<any>();
  const { isOpen, handleClose, Item, callbackHandler, TabStatus, loader } = props;

  const [flagvalue, setFlagValue] = useState<any>(Item?.flag && Item?.flag);

  useEffect(() => {

    setFlagValue(Item?.flag && Item?.flag)

  }, [Item])



  const handlechange = (value: any) => {

    if (TabStatus == "Parameters") {
      setFlagValue(value)

    }
    else {
      setFlagValue(value)

    }


  }
  const handleFlagSubmit = () => {
    if (TabStatus == "Parameters") {
      setFlagValue(flagvalue)
      callbackHandler(flagvalue, Item?.param_id)
    }
    else {
      setFlagValue(flagvalue)
      callbackHandler(flagvalue, Item?.id)
    }


  }

  return (<>
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Comments
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box sx={{ padding: '0.5rem' }}>
          <textarea
            style={{ width: '100%', height: '8rem', color: "#007fff", padding: '0.5rem', border: '1px solid #ddefff', borderRadius: '10px', maxWidth: '38vw', maxHeight: '70vh' }}
            placeholder="Write comments here....."
            value={flagvalue}
            onChange={(e) => handlechange(e.target.value)} />
          <Box sx={{
            width: "100%",
            textAlign: 'right',
            marginTop: '0.5rem',
          }}>
            <LoadingButton loading={loader} sx={{ cursor: "pointer" }} variant="contained" onClick={() => handleFlagSubmit()}>Submit</LoadingButton>
          </Box>

        </Box>
      </Box>
    </Modal>

  </>)


}

export default BOMDataFlagModal;