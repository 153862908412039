import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { UserAction } from '../../IdeaGenerationViewLanding';
import { rejectedStatus } from '../../IdeaViewpage';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';

export interface IIdeaTabMultiSelectInputBoxTableProps {
    itmm: any;
    key1: any;
    dataBody: any;
    data: any;
    val: any;
    count: any;
    setDataForUpdate: any;
    target: any;
    AllData: any;
    selectedOptions: any;
    allOptions: any;
    option: any;
    getTableDataWOLoader: any;
    itmKey: any;
    setColorButton: any;
    setShowButton: any;
}

export default function IdeaTabMultiSelectInputBoxTable (props: IIdeaTabMultiSelectInputBoxTableProps) {
    const { itmm, key1, dataBody, data, val, count, setDataForUpdate, target, AllData, selectedOptions, allOptions, option, getTableDataWOLoader, itmKey,setColorButton,setShowButton } = props;
    const [value, setValue] = React.useState<any>([])
    const userAction = React.useContext<any>(UserAction);
    let UserActionParams = userAction && userAction?.action;
    const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
    const [options, setOptions] = React.useState<any>([])
    const { copyStatus } = useParams<any>()

    React.useEffect(() => {
        if (Array.isArray(allOptions)) {
            setOptions(allOptions)
        }

    }, [allOptions])


    React.useEffect(() => {
        if (Array.isArray(val) && val) {
            setValue(val)
        }else{
            setValue([])
        }
    }, [val])
    const handleChange = (event: any, newSystemValue: any) => {
        setShowButton(true)
        setColorButton('red')
        setValue(newSystemValue)
        if (AllData)
            AllData.filter((item: any, index: any) => index === target)[0][key1] = newSystemValue ? newSystemValue : "";
        setDataForUpdate(AllData)
    }

    
  return (
    <FormControl fullWidth sx={{ minWidth: 200 }}>
    {Array.isArray(value) && <Autocomplete
        multiple
        id="tags-standard"
        // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
        limitTags={1}
        disabled={(!rejectedStatusActionParams && UserActionParams?.includes("U"))?false:true}
        // onChange={handleOnChange}
        disableClearable
        disableCloseOnSelect
        sx={{
            ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
            {
                color: "primary.main",
            },
            ".MuiButtonBase-root.MuiChip-root": {
                backgroundColor: "primary.light",
                height: "26px",
            },
        }}
        options={allOptions && allOptions}
        getOptionLabel={(option: any) => option}
        value={Array.isArray(value) ? value : []}
        onChange={(event: any, newSystemValue: any) => {
            handleChange(event, newSystemValue);
        }}
        // getOptionDisabled={getOptionDisabled}
        renderOption={(props, option) => (
            <li {...props} style={{ fontSize: '12px' }}>
                {option}
            </li>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                // label="Select Material"
                placeholder="Select Material"
                sx={{
                    "& .MuiInput-underline:before": {
                        borderBottomColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px",
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        paddingLeft: '0.3rem',
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                }}

            />
        )}
    />}
    </FormControl>
  );
}
