import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import HistoryIcon from "@mui/icons-material/History";
import ClearIcon from "@mui/icons-material/Clear";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolderred from "../../Assets/images/svgs/openfolderred.svg";
import openFolderGreen from "../../Assets/images/svgs/openfoldergreen.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./ScenarioCostingTree.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import calculatorred from "../../Assets/images/calculator_red-svg.svg";
import calculatorgreen from "../../Assets/images/calculator_green.svg";
import {
  Box,
  IconButton,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "@szhsin/react-menu/dist/index.css";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
// import AddProcessModal from "./AddProcessModal";
import { API } from "../../api-services";
// import InfoTreeModal from "./InfoTreeModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
// import { CalculatorPasteModal } from "./CalculatorPasteModal";
import Swal from "sweetalert2";
// import FormulaModal from "./FormulaModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddBoughtOutRevisionModal } from "../CostingCreateModule/CostingEstimation/AddBoughtOutRevisionModal";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import ScenarioCalculatorModel from "./ScenarioCalculatorModel";
import ScenarioItem from "./ScenarioItem";
import ScenarioDeltaTable from "./ScenarioDeltaTable";
import { max } from "lodash";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface SceanrioCostingCreateCategoryTreeProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  useraction?: any;
  getScenarioTreeData?: any;
  setOpenForm?: any;
  TableData?: any;
  SelectedmulIds?: any;
  AddBOPRefreshTree?: any;
  type?: any;
  setScenarioCount?: any;
  setCalculatorData?: any;
  calculatorData?: any;
  decimalPoints?: any;
  setDecimalPoints?: any;
  toggleHighlight?: any;
  userProfile?: any;
  reDirectToMainCosting?: any;
}
const SceanrioCostingCreateCategoryTree = (
  props: SceanrioCostingCreateCategoryTreeProps
) => {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    projectName,
    productId,
    abbrebation,
    cardtype,
    tableCounter,
    condition,
    calctype,
    scenarioId,
    costEstimationId,
    treeabbr,
    newUI,
    scenarioType,
  } = useRouteParams<any>();
  const {
    HierarchyData,
    onSelectCallback,
    setSelectedTreeData,
    setCounterApiCall,
    useraction,
    getScenarioTreeData,
    setOpenForm,
    TableData,
    SelectedmulIds,
    AddBOPRefreshTree,
    type,
    setScenarioCount,
    calculatorData,
    setCalculatorData,
    decimalPoints,
    setDecimalPoints,
    toggleHighlight,
    userProfile,
    reDirectToMainCosting,
  } = props;
  const history = useHistory<any>();
  const AddBoughtOutModal = useBaseParamsModal();
  const { tableId, calcultorId, selectedIdTree } = useRouteParams<any>();

  const openFormula = useBaseParamsModal();
  const infoModal = useBaseModal();
  const costModelPaste = useBaseParamsModal();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [color, setColor] = React.useState<any>();
  const [highlightedCellIndex, setHighlightedCellIndex] = useState<
    number | null
  >(null);
  const [indexMax, setIndexMax] = useState<number | null>(null);
  const [costDetailsDataLoading, setCostDetailsDataLoading] =
    React.useState<any>(false);
  const [costDetailsData, setCostDetailsData] = React.useState<any>({});
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const [rightClickedId, setRightClickedId] = React.useState<any>("");
  const inputElRef = React.useRef<any>();
  const [uploadCalcListLoader, setUploadCalcListLoader] =
    React.useState<any>(false);

  const open = Boolean(anchorEl);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openn = Boolean(anchorEl);

  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([
    parseInt(selectedIdTree),
  ]);
  const [selected, setSelected] = React.useState<any>(selectedIdTree);

  const addProcess = useBaseParamsModal();

  const handleChange = (nodeId: any, id: any, data: any, itemId: any) => {
    setLoading(true);

    onSelectCallback(nodeId, id, data, itemId);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (data && data?.type === "calculator") {
      setSelectedTreeData(data);
      sessionStorage.setItem("calculator_type_create", data?.calculator_type);
    } else {
      setSelectedTreeData({});
      sessionStorage.removeItem("calculator_type_create");
    }
    let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(id)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != id;
      });
      let b: any = sessionStorage.setItem(
        "Costingconfigurationnodeconfig",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(id);
      // val.push(id);
      let b: any = sessionStorage.setItem(
        "Costingconfigurationnodeconfig",
        JSON.stringify(val)
      );
    }

    //  const expandedNodes = getAutoExpandedNodes(data);
    // console.log(expandedNodes, "expanded");

    //   expandedNodes?.forEach((item:any) => {

    //     if (!val?.includes(item)) {
    //       val.push(item); // Add to arr2 if not present
    //     }
    //   });

    // sessionStorage.setItem(
    //   "Costingconfigurationnodeconfig",
    //   JSON.stringify(val)
    // );

    setActiveId(id);

    const index = expanded.indexOf(id);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(id);
    } else {
      copyExpanded?.splice(index, 1);
    }

    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  // console.log(HierarchyData, "HierarchyData");
  // console.log(expanded, "expanded");

  const renderData = useMemo(() => {
    return (
      HierarchyAllData &&
      HierarchyAllData?.data?.map((subsystemData: any) => {
        return (
          <>
            <SceanrioCostingCreateCategoryTree
              HierarchyData={subsystemData}
              key={subsystemData?.sequence_id}
              useraction={useraction && useraction}
              setSelectedTreeData={setSelectedTreeData}
              onSelectCallback={onSelectCallback}
              setCounterApiCall={setCounterApiCall}
              getScenarioTreeData={getScenarioTreeData}
              setOpenForm={setOpenForm}
              TableData={TableData}
              SelectedmulIds={SelectedmulIds}
              AddBOPRefreshTree={AddBOPRefreshTree}
              type={type}
              calculatorData={calculatorData}
              setCalculatorData={setCalculatorData}
              decimalPoints={decimalPoints}
              toggleHighlight={toggleHighlight}
              userProfile={userProfile}
              reDirectToMainCosting={reDirectToMainCosting}
            />
          </>
        );
      })
    );
  }, [HierarchyAllData, decimalPoints, toggleHighlight]);

  useEffect(() => {
    let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
    let val: any = JSON.parse(a) || [];

    // console.log(val, "expanded in refresh");

    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [
    selectedIdTree,
    sessionStorage.getItem("Costingconfigurationnodeconfig"),
  ]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  const uploadCalc = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("commodity", HierarchyAllData?.commodity_id);
    formData.append("process", HierarchyAllData?.id);
    setUploadCalcListLoader(true);
    API.post("/cost/upload_calculator_data/", formData, {}, 0)
      .then(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Success!",
          text: "Calculator uploaded successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch(() => {
        setUploadCalcListLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Error in uploading calculator",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    uploadCalc(file);
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const getCostDetailsData = () => {
    setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
      },
      0
    )
      .then((res: any) => {
        setCostDetailsData(res.data[0]);

        // setUnvalidatedCategory(res.data[0]?.costing_category_name)
        // setIsShowCost(true);
        // setCostData(res.data[0]);
        // setSelectedAssumption(res.data[0]?.assumption);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        if (err?.response && err?.response?.data) {
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${err?.response?.data[0]}</p>   
           </div>`,
          });
        }
        setCostDetailsDataLoading(false);
      });
  };

  // console.log(HierarchyAllData);

  const handleDisableCalc = (calc: any) => {
    API.put(
      `/cost/calculation/${calc?.id}/`,
      {
        status: calc?.status === true ? false : true,
      },
      {},
      0
    )
      .then((res: any) => {
        getScenarioTreeData(type);
      })
      .catch((err: any) => { });
  };
  // console.log(HierarchyAllData, "HierarchyAllData");
  // console.log(expanded, "HierarchyAllData expanded");
  // console.log(selected, "HierarchyAllData selected");
  // console.log(selectedIdTree, "HierarchyAllData selectedIdTree");

  // const color = ['#482880', '#ffc107', '#608028', '#07c1ff', '#ff8964']

  const getAutoExpandedNodes = (data: any, resultArr: any = []) => {
    const nodes = Array.isArray(data) ? data : [data]; // Wrap in array if not already an array

    nodes.forEach((node: any) => {
      // Check if node has exactly one child in its data array
      if (node?.data?.length === 1) {
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Recursively continue with its single child
        getAutoExpandedNodes(node?.data, resultArr);
      } else if (node?.data?.length > 1) {
        // If node has multiple children in data array
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Push sequence_id of each immediate child
        node?.data.forEach((child: any) => resultArr.push(child?.sequence_id));
      }
    });

    return resultArr;
  };

  const [editMode, setEditMode] = useState(Array(5).fill(false));
  const [scenarioValues, setScenarioValues] = useState(
    Array(5)
      .fill("")
      .map((_, index) => `Scenario ${index + 1}`)
  );

  const handleEditToggle = (index: any) => {
    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode[index] = !newEditMode[index];
      return newEditMode;
    });
  };

  const handleInputChange = (index: any, value: any) => {
    setScenarioValues((prev) => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });
  };

  const openCalculator = useBaseParamsModal();

  const handleOpenCalculator = () => {
    openCalculator.open();
  };

  const [isHovered, setIsHovered] = useState(false);

  // console.log(HierarchyAllData, "highlight");
  // console.log(HierarchyAllData?.cost, HierarchyAllData?.scenario_data, "highlight");

  // const handleHighlight = () => {
  //   const allValues: any = [];

  //   const mainCost =
  //   type == "calculator"
  //     ? HierarchyAllData?.std_result
  //     : HierarchyAllData?.cost;

  // allValues.push(mainCost);

  //   HierarchyAllData?.scenario_data?.map((itm: any) => {
  //     const cost = itm?.scenario_cost;

  //     allValues.push(cost);

  //     // if (cost !== null && cost !== undefined && typeof cost == "number") {
  //     //   allValues.push(cost);
  //     // }
  //   });

  //   // Find the maximum value and its index
  //   let maxIndex: number | null = null;
  //   let maxValue: number | null = null;

  //    // Check if all numeric values in the array are the same
  // const numericValues = allValues.filter((value: any) => typeof value === "number");
  // const areAllValuesSame = numericValues.every((val: number) => val === numericValues[0]);

  // // console.log(numericValues, "numericValues", areAllValuesSame);

  // if (areAllValuesSame) {
  //   return null; // Return null if all values are the same
  // }

  //   allValues.forEach((value: any, index: any) => {
  //     // Convert value to a number only if it is a valid numeric type
  //     const numericValue = typeof value === "number" ? value : null;

  //     if (numericValue !== null && (maxValue === null || numericValue > maxValue)) {
  //       maxValue = numericValue;
  //       maxIndex = index; // Track the index of the maximum value
  //     }
  //   });

  //  return maxIndex
  // };

  const handleHighlight = () => {
    const allValues: any = [];

    const mainCost =
      type == "calculator"
        ? HierarchyAllData?.std_result
        : HierarchyAllData?.cost;

    allValues.push(mainCost);

    HierarchyAllData?.scenario_data?.map((itm: any) => {
      const cost = itm?.scenario_cost;
      allValues.push(cost);
    });

    // Extract numeric values
    const numericValues = allValues.filter(
      (value: any) => typeof value === "number"
    );

    // Check if all numeric values are the same
    const areAllValuesSame = numericValues.every(
      (val: number) => val === numericValues[0]
    );

    if (areAllValuesSame) {
      return null; // Return null if all values are the same
    }

    // Find the maximum value
    const maxValue = Math.max(...numericValues);

    // Get all indexes of the maximum value
    const maxIndexes = allValues
      .map((value: any, index: number) => (value === maxValue ? index : null))
      .filter((index: number | null) => index !== null);

    return maxIndexes;
  };

  // useEffect(() => {

  //   const maxIndexx = handleHighlight();

  //   setIndexMax(maxIndexx);

  //   // console.log(indexMax, HierarchyAllData?.name);

  // }, [HierarchyAllData]);

  const maxIndexx = handleHighlight();

  // console.log(maxIndexx, "numericValues",);

  //   useEffect(()=> {

  // if (toggleHighlight == true) {
  //   setHighlightedCellIndex(indexMax)

  // } else {
  //   setHighlightedCellIndex(null);
  // }

  //   },[toggleHighlight])
  // 7ChildCost
  // console.log(expanded,"expanded", HierarchyAllData);

  return (
    <>
      {openCalculator.isOpen && (
        <ScenarioCalculatorModel
          isOpen={openCalculator.isOpen}
          onCloseModal={openCalculator.close}
          calculatorData={calculatorData}
          costDetailsData={costDetailsData}
          HierarchyAllData={HierarchyAllData}
          scenario={TableData?.[0]}
          vault={vault}
          topVault={topVault}
          getCostDetailsData={getCostDetailsData}
          costEstimationId={HierarchyAllData?.id}
          parentId={HierarchyAllData?.object_id}
          revision_id={HierarchyAllData?.revision}
          assumption={HierarchyAllData?.assumption}
          getScenarioTreeData={getScenarioTreeData}
          category_type={HierarchyAllData?.content_type}
          decimalPoints={decimalPoints}
          PermissionDataa={TableData?.[0]}
          userProfile={userProfile}
          reDirectToMainCosting={reDirectToMainCosting}
          CostingType="main"
        />
      )}
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.sequence_id}
        id={HierarchyAllData?.sequence_id}
        defaultParentIcon={
          HierarchyAllData?.type !== "parent_type" &&
            HierarchyAllData?.type !== "child" ? (
            <FolderIcon
              sx={{ color: HierarchyAllData?.folder_status ? "green" : "red" }}
            />
          ) : (
            ""
          )
        }
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.type !== "calculator" && (
            <>
              {HierarchyAllData?.type !== "parent_type" &&
                HierarchyAllData?.type !== "child" ? (
                <img
                  src={
                    HierarchyAllData?.folder_status
                      ? openFolderGreen
                      : openFolderred
                  }
                  style={{
                    width: "18px",
                    color: HierarchyAllData?.folder_status ? "green" : "red",
                  }}
                />
              ) : (
                ""
              )}
            </>
          )
          // )
        }
        defaultExpandIcon={
          // HierarchyAllData?.type !== "parent_type" &&
          HierarchyAllData?.type !== "child" ? (
            <FolderIcon
              sx={{ color: HierarchyAllData?.folder_status ? "green" : "red" }}
            />
          ) : (
            ""
          )
        }
        defaultEndIcon={
          // HierarchyAllData?.type !== "parent_type" &&
          // HierarchyAllData?.type !== "parent_type" ? (

          // )
          HierarchyAllData?.type !== "child" ? (
            HierarchyAllData?.type === "calculator" ? (
              <img
                src={
                  HierarchyAllData?.folder_status
                    ? calculatorgreen
                    : calculatorred
                }
                style={{
                  width: "13px",
                  color: HierarchyAllData?.folder_status ? "green" : "red",
                }}
              />
            ) : HierarchyAllData?.type !== "parent_type" ? (
              <FolderIcon
                sx={{
                  color: HierarchyAllData?.folder_status ? "green" : "red",
                }}
              />
            ) : (
              <img
                src={
                  HierarchyAllData?.folder_status
                    ? openFolderGreen
                    : openFolderred
                }
                style={{
                  width: "18px",
                  color: HierarchyAllData?.folder_status ? "green" : "red",
                }}
              />
            )
          ) : (
            ""
          )
        }
        onNodeSelect={(e: any) => { }}
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == selectedIdTree
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
        style={{ cursor: "context-menu" }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.sequence_id}
          nodeId={HierarchyAllData?.sequence_id}
          label={
            <Typography
              onMouseEnter={() => setIsHovered(true)} // Hover starts
              onMouseLeave={() => setIsHovered(false)} // Hover ends
              className={styles.treeViewDataACC}
              sx={{
                backgroundColor: isHovered
                  ? "#f0f0f0"
                  : HierarchyAllData?.calculator?.id === calcultorId &&
                    HierarchyAllData?.object_id == tableId
                    ? " " //"#8080808f !important"
                    : "",
                color:
                  HierarchyAllData?.calculator?.id === calcultorId &&
                    HierarchyAllData?.object_id == tableId
                    ? "#000000 !important"
                    : "",
                "&:hover": {
                  backgroundColor: "#f0f0f0", // Greyish effect
                },
              }}
            >
              <div
                onMouseEnter={() => setIsHovered(true)} // Hover starts
                onMouseLeave={() => setIsHovered(false)} // Hover ends
                className={styles.treeViewText}
                style={
                  HierarchyAllData?.type === "calculator"
                    ? {
                      color:
                        HierarchyAllData?.calculator?.id === calcultorId &&
                          HierarchyAllData?.object_id == tableId
                          ? "#ffffff !important"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                      // backgroundColor: HierarchyAllData?.color,
                      backgroundColor: isHovered
                        ? "#f0f0f0"
                        : HierarchyAllData?.color,
                      // "&:hover": {
                      //           backgroundColor: "#f0f0f0", // Greyish effect
                      //         },
                    }
                    : {
                      color:
                        HierarchyAllData?.calculator?.id === calcultorId &&
                          HierarchyAllData?.object_id == tableId
                          ? "#ffffff !important"
                          : "",
                      borderBottom: "1px solid #007fff1f",
                      backgroundColor: HierarchyAllData?.color,
                    }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "#f0f0f0", // Greyish effect
                    },
                  }}
                >

                  {/* First div */}
                  <Box
                    id={HierarchyAllData?.sequence_id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: { lg: "24%", xl: "27%" },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      if (HierarchyAllData?.type !== "parent_type") {
                        if (HierarchyAllData?.type === "child") {
                          window.open(
                            `/#/costingscenario/${projectId}/${projectName}/${topVault}/${HierarchyAllData?.id}/${scenarioType}/${treeabbr}/${scenarioId}`
                          );
                        } else {
                          handleChange(
                            HierarchyAllData?.name,
                            HierarchyAllData?.sequence_id,
                            HierarchyAllData,
                            HierarchyAllData?.id
                          );
                        }
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1rem",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          // whiteSpace: "nowrap",
                          overflow: "hidden !important",
                          textOverflow: "ellipsis !important",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",
                          gap: "0.8rem",
                          color:
                            HierarchyAllData?.type === "calculator"
                              ? HierarchyAllData?.status
                                ? "inherit"
                                : "grey"
                              : "inherit",
                          textDecoration:
                            HierarchyAllData?.type === "child"
                              ? "underline"
                              : "inherit",
                        }}
                      >
                        {HierarchyAllData?.name}
                        {/* {HierarchyAllData?.name === "Bought-Out Part" && (
                          <AddCircleIcon
                            sx={{ cursor: "pointer", fontSize: "17px" }}
                            onClick={(ed: any) => {
                              ed.stopPropagation();
                              ed.preventDefault();
                              AddBoughtOutModal.open(TableData);
                            }}
                          />
                        )} */}
                        {"  "}
                      </Box>
                      
                    </div>

                    
                  </Box>

                  {/* Second div */}

                  <Box
                    id={"senarioId"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "0rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          padding: "0.3rem 0.2rem",
                          width: "7rem",
                          textTransform: "none",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          borderRight: "1px solid #007fff1f",
                          color:
                            HierarchyAllData?.type === "calculator"
                              ? HierarchyAllData?.status
                                ? "inherit"
                                : "grey"
                              : "inherit",
                        }}
                      >
                        {HierarchyAllData?.type === "calculator" ? (
                          <Tooltip
                            title={HierarchyAllData?.show_count || 0}
                            placement="left"
                          >
                            <InfoIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={HierarchyAllData?.show_count || 0}
                            placement="left"
                          >
                            <span>{HierarchyAllData?.show_count || 0}</span>
                          </Tooltip>
                        )}
                      </Box>

                      <Tooltip title={HierarchyAllData?.currency}>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0.3rem 0.2rem",
                            width: "5rem",
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            borderRight: "1px solid #007fff1f",
                            textAlign: "center",
                            color:
                              HierarchyAllData?.type === "calculator"
                                ? HierarchyAllData?.status
                                  ? "inherit"
                                  : "grey"
                                : "inherit",
                          }}
                        >
                          {HierarchyAllData?.currency || "--"}
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginRight: "0rem",
                        width: "57vw",
                      }}
                    >
                      {HierarchyAllData?.type === "calculator" ? (
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0.3rem 0.2rem",
                            width: "100%",
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textDecoration: "underline",
                            textAlign: "right",
                            color:
                              highlightedCellIndex == 0 && toggleHighlight
                                ? "#ffb2b2"
                                : HierarchyAllData?.type === "calculator"
                                  ? HierarchyAllData?.status
                                    ? "inherit"
                                    : "grey"
                                  : "inherit",
                            // borderRight: "1px solid #007fff1f",

                            background:
                              highlightedCellIndex == 0 && toggleHighlight
                                ? "#ffb2b2"
                                : "inherit",
                          }}
                          aria-owns={openn ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={(e) => {
                            if (HierarchyAllData?.currency) {
                              handlePopoverOpen(e);
                            }
                          }}
                          onMouseLeave={() => {
                            if (HierarchyAllData?.currency) {
                              handlePopoverClose();
                            }
                          }}
                          onClick={() => {
                            if (HierarchyAllData?.type === "calculator") {
                              handleOpenCalculator();
                            }
                          }}
                        >
                          {!HierarchyAllData?.currency ? (
                            <Tooltip
                              title={
                                typeof HierarchyAllData?.std_result !==
                                  "string" || HierarchyAllData?.std_result === 0
                                  ? HierarchyAllData?.std_result?.toFixed(
                                    decimalPoints
                                  )
                                  : "--"
                              }
                            >
                              <span>Ref</span>
                            </Tooltip>
                          ) : typeof HierarchyAllData?.std_result !==
                            "string" || HierarchyAllData?.std_result === 0 ? (
                            HierarchyAllData?.max_cost &&
                              HierarchyAllData?.max_cost == true ? (
                              <>
                                {/* {toggleHighlight && (
                                  <span style={{ color: "red", marginRight:"0.15rem" }}>[</span>
                                )} */}
                                <span style={{ borderBottom: toggleHighlight ? "0.3px solid red" : "none" }}>{HierarchyAllData?.std_result?.toFixed(
                                  decimalPoints
                                )}</span>
                                {/* {toggleHighlight && (
                                  <span style={{ color: "red", marginLeft:"0.15rem" }}>]</span>
                                )} */}
                              </>
                            ) : (
                              HierarchyAllData?.std_result?.toFixed(
                                decimalPoints
                              )
                            )
                          ) : (
                            "--"
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            padding: "0.3rem 0.2rem",
                            width: "100%",
                            textTransform: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "right",
                            background:
                              highlightedCellIndex == 0 && toggleHighlight
                                ? "#ffb2b2"
                                : "inherit",
                          }}
                          aria-owns={openn ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          {HierarchyAllData?.cost ||
                            HierarchyAllData?.cost === 0
                            ? typeof HierarchyAllData?.cost == "string"
                              ? parseFloat(HierarchyAllData?.cost)?.toFixed(
                                decimalPoints
                              )
                              : (
                                HierarchyAllData?.max_cost &&
                                  HierarchyAllData?.max_cost == true ? (
                                  <>
                                    
                                    <span style={{ borderBottom: toggleHighlight ? "0.3px solid red" : "none" }}>
                                      {HierarchyAllData?.cost?.toFixed(
                                        decimalPoints
                                      )}
                                    </span>
                                   
                                  </>
                                ) : (
                                  HierarchyAllData?.cost?.toFixed(
                                    decimalPoints
                                  )
                                )
                              )


                            : "--"}
                        </Box>
                      )}
                      {HierarchyAllData?.scenario_data?.length > 0 && (
                        <Popover
                          id="mouse-over-popover"
                          sx={{ pointerEvents: "none" }}
                          open={openn}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <ScenarioDeltaTable
                            HierarchyAllData={HierarchyAllData}
                            type={
                              HierarchyAllData?.type === "calculator"
                                ? "calculator"
                                : "no calculator"
                            }
                          />
                        </Popover>
                      )}

                      {HierarchyAllData?.scenario_data?.map(
                        (item: any, index: any) => {
                          return (
                            <ScenarioItem
                              index={index}
                              color={color}
                              editMode={editMode}
                              item={item}
                              HierarchyAllData={HierarchyAllData}
                              setScenarioCount={setScenarioCount}
                              getScenarioTreeData={getScenarioTreeData}
                              calculatorData={calculatorData}
                              setCalculatorData={setCalculatorData}
                              TableData={TableData}
                              decimalPoints={decimalPoints}
                              highlightedCellIndex={highlightedCellIndex}
                              toggleHighlight={toggleHighlight}
                              userProfile={userProfile}
                              reDirectToMainCosting={reDirectToMainCosting}                              
                            />
                          );
                        }
                      )}
                    </Box>
                  </Box>
                </Box>
              </div>
            </Typography>
          }
          sx={{
            borderBottom: "none",
            cursor:
              HierarchyAllData?.type === "parent_type"
                ? "default !important"
                : "pointer",
            marginLeft:
              HierarchyAllData?.name === "Material" ||
                HierarchyAllData?.name === "Process" ||
                HierarchyAllData?.name === "Overheads" ||
                HierarchyAllData?.name === "Child Cost"
                ? "1.4rem"
                : "inherit",
          }}
        >
          {loading
            ? HierarchyAllData?.type !== "calculator" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
                <Skeleton variant="rectangular" width="100%" height={20} />
              </Box>
            )
            : renderData}
        </StyledTreeItem>
      </TreeView>
      <input
        type="file"
        style={{ display: "none" }}
        accept=".enc"
        id="file"
        name="file"
        ref={inputElRef}
        onChange={handleChangeFile}
        onClick={handleInputClick}
        capture="user"
      />

      {/* {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={rightClickedId && rightClickedId}
        />
      )}
      {openFormula.isOpen && (
        <FormulaModal
          isOpen={openFormula.isOpen}
          onCloseModal={openFormula.close}
          data={openFormula.propsId}
          getTreeData={getTreeData}
        />
      )}

      {costModelPaste.isOpen && (
        <CalculatorPasteModal
          isOpen={costModelPaste.isOpen}
          onCloseModal={costModelPaste.close}
          HierarchyAllData={costModelPaste.propsId}
          setCounterApiCall={setCounterApiCall}
        />
      )} */}

      {/* {AddBoughtOutModal.isOpen && (
        <AddBoughtOutRevisionModal
          isOpen={AddBoughtOutModal.isOpen}
          onCloseModal={AddBoughtOutModal.close}
          costDetailsData={AddBoughtOutModal.propsId}
          // getRMData={AddBOPRefreshTree}
          getRMData={() => getScenarioTreeData(type)}
          SelectedmulIds={SelectedmulIds}
        />
      )} */}
    </>
  );
};

export default SceanrioCostingCreateCategoryTree;
