import * as React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./IdeaCreateLandingpage.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { AxiosError, AxiosResponse } from 'axios';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Ideacreatepage from './IdeaCreatepage';
import IdeaSummaryModal from './IdeaSummaryModal';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';


export const UserAction = React.createContext(null)
interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
}


export interface IIdeaCreateLandingpageProps {
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function IdeaCreateLandingpage(props: IIdeaCreateLandingpageProps) {
  const { projectId, projectName, topVault } = useRouteParams<Params>();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useDocumentTitle('Idea')


  const [projectList, setProjectList] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [vehicleList, setVehicleList] = useState<any>();
  const [selectedVehicle, setSelectedVehicle] = useState<any>(topVault);
  const summaryModal = useBaseModal();
  const [PermissionAction,SetPermissionAction]=useState<any>()

  React.useEffect(() => {
    API.get(`/xcpep/project_list_new/`, { module: "Idea-Create" }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);

  React.useEffect(() => {
    // setSelectedProject([projectId]);
    API.get(
      `/xcpep/product_list_new/`,
      { project: projectId, module: "Idea-Create" },
      0
    )
      .then((res: AxiosResponse) => {
        setProductList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);


  const getAllVehicleList = () => {
    API.get(
      "/xcpep/top_vault/",
      {
        project: projectId,
        module: 15,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setVehicleList(res?.data.filter((item: any) => item?.idea_base === true));

        setSelectedVehicle(topVault);
      }
      )
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }

  React.useEffect(() => {
    setSelectedProject([projectId]);
  }, [projectId]);

 
  const selectAllProject = (projectIds: any) => {
    setSelectedProject([projectIds]);
    API.get(
      `/xcpep/product_list_new/`,
      { project: `${projectIds}`, module: "Idea-Create" },
      0
    )
      .then((res: AxiosResponse) => {
        setProductList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  React.useEffect(() => {
    setSelectedProduct([topVault]);
  }, [topVault]);


  const selectAllProduct = (productIds: any) => {    
    setSelectedProduct([productIds]);
    if (selectedProduct?.includes(productIds?.toString())) {
      setSelectedProduct((prev: any) =>
        prev?.filter((item: any) => item !== productIds.toString())
      );
    } else {
      setSelectedProduct((prev: any) => [...prev, productIds?.toString()]);
    }        
    // history.push(`/idea-generation/${selectedProject}/${projectName}/${productIds}/0/0/0`);
  };



  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  React.useEffect(()=>{
    API.get(`/auth/calibration_user_permission/`,{action:true,idea_create:true,top_vault:topVault}).then((res:any)=>{
      SetPermissionAction(res.data)
    }).catch((err:any)=>{
      console.log("Server Error")
    })
  },[topVault])

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };

  const getSelectedPrductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  React.useEffect(() => {
    getAllVehicleList();
  }, [projectId]);



  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box sx={{ marginTop: "1rem" }}>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }} key={index + 1}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Checkbox
                            style={{ padding: "3px" }}
                            checked={
                              selectedProject?.includes(item.id) ? true : false
                            }
                            onClick={() => {
                              return selectAllProject(item?.id);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 24 },
                              color: "primary.main",
                            }}
                          />
                          <Box
                            onClick={() => {
                              return selectAllProject(item?.id);
                            }}
                            key={item?.id}
                            className={styles.selectVehBtn}
                            sx={{
                              // margin: "0rem 2rem",
                              padding: "0.2rem 0.5rem",
                              backgroundColor: getselectedProjectBackgroundColor(
                                item.id
                              ),
                              color: getSelectedProjectTextColor(item.id),
                              textAlign: "left",
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "0.87rem", display: "flex" }}
                            >
                              <span>{item.name}</span>
                              <span style={{ marginLeft: "1rem" }}>
                                [ {item.description} ]
                              </span>
                            </Typography>
                          </Box>
                        </Box>                       
                            {productList &&
                              productList?.filter((itemVal: any) => {
                                return itemVal?.project == item?.id;
                              })
                              ?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "2rem",
                                        width: "90%",
                                      }}
                                    >
                                      <Checkbox
                                        style={{ padding: "3px" }}
                                        checked={
                                          selectedProduct?.includes(item.id)
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          return selectAllProduct(item?.id);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: { lg: "24", xl: "30" },
                                            color: "#2b9cf6eb",
                                          },
                                        }}
                                      />
                                      <Box
                                        onClick={() => {
                                          return selectAllProduct(item?.id);
                                        }}
                                        key={item?.id}
                                        className={styles.selectVehBtn}
                                        sx={{
                                          // margin: "0rem 2rem",
                                          padding: "0.2rem 0.5rem",
                                          backgroundColor:
                                            getselectedProductBackgroundColor(
                                              item.id
                                            ),
                                          color: getSelectedPrductTextColor(
                                            item.id
                                          ),
                                          textAlign: "left",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "0.87rem",
                                            display: "flex",
                                          }}
                                        >
                                          <span>{item.name}</span>
                                          {/* <span style={{ marginLeft: '1rem' }}>[ {item.description} ]</span> */}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}                         
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const handleClick = () => {
    
    history.push(`/internallanding`)
  };

  const redirectToProject: any = () => {
    
    setSelectedVehicle(vehicleList && vehicleList?.filter((item: any, index: any) => {
      return item?.id == selectedProduct[0]
    })[0]?.id)

    // setSelectedVehicle(topVault)
    history.push(`/idea-generation/${selectedProject}/${projectName}/${selectedProduct[0]}/0/0/0`);      
  };





  const handleChange = (event: SelectChangeEvent) => {
    
    setSelectedVehicle(event.target.value);
    history.push(`/idea-generation/${projectId}/${projectName}/${event.target.value}/0/0/0`);
  };
let vehicleName=vehicleList && vehicleList?.filter((item:any,index:any)=>{return item?.id==topVault})[0]

  return (
    <div>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
              Idea Generation ({projectName} {`>`} {vehicleName?.name} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            <Button title='Idea Summary' size='small' variant='contained' onClick={() => summaryModal.open()} 
            sx={{
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
               }
            }}>Summary</Button>
            {vehicleList && vehicleList?.length > 1 ? <FormControl variant='standard'>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedVehicle}
                size='small'
                onChange={handleChange}
                sx={{
                  minWidth: '14rem',
                  // width:'fit-content',
                  "&:before": {
                    borderBottomColor: "transparent",
                    borderRadius: "5px",
                  },
                  "&:after": {
                    borderBottomColor: "transparent",
                    borderRadius: "5px",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: '1rem',
                    borderRadius: "5px",
                    backgroundColor: "primary.main",
                    padding: { lg: "0.4rem 0.5rem", xl: '0.25rem 0.5rem' },
                    color: "white",
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'white' },
                  // overflow: "hidden",
                }}
              >
                {vehicleList && vehicleList?.map((item: any, index: any) => {
                  return (
                    <MenuItem sx={{ fontSize: '1rem' }} key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> : <Box sx={{
              border: '1px solid #007fff',borderRadius:'0.4rem'
            }}><Typography sx={{
              fontSize: "1rem", whiteSpace: "nowrap", borderRadius: "5px",
              color: "primary.main",
              padding: { lg: "0.4rem 1rem", xl: '0.25rem 1rem' },
            }}>{vehicleList?.[0]?.name}</Typography></Box>}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        
        <Box
          sx={{
            width: { xs: "80vw", sm: "96%", md: "96%", lg: "96%", xl: "97%" },
            // height: { xs: "87vh", lg: "86vh", xl: "90vh" },
            // paddingRight: "0.5rem",
            // overflow: { xs: "auto", lg: "visible", xl: "visible" },
          }}
        // adl-scrollbar="true"
        // adl-scrollbar-width="0.3"
        >
          <UserAction.Provider value={PermissionAction}>
          <Ideacreatepage />
          </UserAction.Provider>

        </Box>
      </Box>
      {summaryModal.isOpen && <IdeaSummaryModal isOpen={summaryModal.isOpen} onCloseModal={summaryModal.close} />}
    </div>
  );
}