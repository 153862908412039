import * as React from "react";
import styles from "./CreateFeaturePage.module.scss";
import {
  Autocomplete,
  Badge,
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";
import { CreateFeature } from "./CreateFeature";
import { EditFeatureName } from "./EditFeatureName";
import { useDispatch, useSelector } from "react-redux";
import { increaseCounter } from "../../Redux/Actions/compareopen.action";
import Swal from "sweetalert2";
import { EditGroupName } from "./EditGroupName";
import recyleBin from "../../Assets/images/deletefill.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AUTH } from "../../Redux/Services/auth.service";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

interface Params {
  projectId: any;
  projectname: any;
  featureId: any;
  feId: any;
  type: any;
  parent: any;
  child: any;
  pageNumber: any;
}

export interface ICreateFeaturePageProps {}

type Anchor = "top" | "left" | "bottom" | "right";

export function CreateFeaturePage(props: ICreateFeaturePageProps) {
  // '/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber'
  const {
    projectId,
    projectname,
    featureId,
    feId,
    pageNumber,
    parent,
    child,
    type,
  } = useRouteParams<Params>();
  const [projectList, setProjectList] = useState<any>();
  useDocumentTitle("Feature Create");
  const store: any = useSelector((state) => state);
  const [validatedStatus, setValidatedStatus] = useState<any>();
  const [allFeatureList, setAllFeatureList] = useState<any>(null);
  const [search, setSearch] = React.useState<any>({});
  const [permission, setPermission] = React.useState<any>("");
  const [dbpermission, setDBPermission] = React.useState<any>("");
  const [recyclebinCount, setRecyclebinCount] = useState<any>();
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  React.useEffect(() => {
    API.get(`/xcpep/feature_project/`, { level: "allproject" }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);
  const getType = () => {
    if (type == 1) {
      return 0;
    } else if (type == 2) {
      return 1;
    } else if (type == 3) {
      return 2;
    }
  };

  React.useEffect(() => {
    API.get(`/xcpep/feature_project/`, {
      level: "search",
      project: projectId,
      type: getType(),
    })
      .then((res: AxiosResponse) => {
        // setAllFeatureList(res?.data);
        let features: any = [];
        let resolve = res.data?.map((item: any) => {
          features.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setAllFeatureList(features);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [projectId, type]);
  React.useEffect(() => {
    API.get("/xcpep/feature_project/", { level: "bin", project: projectId })
      .then((res: any) => {
        setRecyclebinCount(res?.data[0]?.count);
      })
      .catch((err: any) => {});
  }, [projectId, store?.is_compare?.recyclebinCounter]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        project: projectId,
        feature_create: true,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data[0]?.action);
      })
      .catch((err: any) => {});
  }, [projectId]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
      },
      0
    )
      .then((res: any) => {
        setDBPermission(res.data.action);
      })
      .catch((err: any) => {});
  }, []);

  React.useEffect(() => {
    API.get(
      `xcpep/product_feature/`,
      {
        id: feId,
      },
      0
    )
      .then((res: any) => {
        setValidatedStatus(res?.data[0]?.status);
      })
      .catch((err: any) => {});
  }, [feId, store?.is_compare?.validationCounter]);
  const RecycleBinHanlder = () => {
    history.push(`/createfeature/recyclebin/${projectId}`);
  };

  const handleChangeValidateStatus = (e: any) => {
    const { value } = e?.target;
    API.put(
      `xcpep/product_feature/${feId}/`,
      {
        validated: true,
      },
      {},
      0
    )
      .then((res: any) => {
        var random: any = Math.floor(Math.random() * 1000000 + 1);
        setValidatedStatus(+value);
        dispatch(increaseCounter(random));
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
        });
      });
  };

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };

  const handleChangeSearch = (event: any, newValue: any | null) => {
    setSearch(newValue);
    // const { projectId, projectname, type, parent, child, featureId, feId, pageNumber } = useRouteParams<any>();
    if (newValue !== null) {
      API.get(
        "/xcpep/feature_project/",
        {
          search: newValue?.value,
          level: "search",
          type: parseInt(type) - 1,
        },
        0
      )
        .then((res: any) => {
          if (res?.data?.length > 0) {
            var data: any = res.data[0];
            history.push(
              `/createfeature/${projectId}/${projectname}/${type}/${
                data?.abbreviation
              }/${data?.group}/${data?.name}/${data?.id}/${1}`
            );
          } else {
          }
        })
        .catch((err: any) => {});
      // history.push(`/user-permissions/${projectId}/0/${filterOPtion}/${routeType}`)
      // dispatch(setUserSerach(newValue?.value));
    } else {
      // dispatch(setUserSerach(""));
    }
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      if (open) {
        setSelectedProject([Number(projectId)]);
      }
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box sx={{ marginTop: "1rem" }}>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          //   selectedProject?.includes(idd)
                          checked={selectedProject == item.id ? true : false}
                          onClick={() => selectAllProject(item?.id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id)}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.87rem", display: "flex" }}
                          >
                            {item.name} (F-{item?.feature_count}, S-
                            {item?.specification_count}, I-
                            {item?.information_count})
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                // fontSize: "0.75rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  // fontSize: "0.75rem",
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const redirectToProject: any = () => {
    history.push(
      `/createfeature/${selectedProject[0]}/${
        projectList &&
        projectList?.filter((item: any) => item?.id == selectedProject[0])[0]
          ?.name
      }/1/0/0/0/0/0`
    );
  };

  const handleClick = () => {
    if (AUTH.user_category === "Internal") {
      history.push("/internallandingpage");
    } else {
      history.push("/landingpage");
    }
  };

  const gotoDBHandler = () => {
    history.push(`/data-base/Feature-library/0/0`);
  };
  return (
    <div>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
              {projectname} [ Create-Features ]
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {feId != 0 ? (
              <Typography
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginRight: "1rem",
                }}
              >
                <EditFeatureName
                  value={featureId}
                  idd={feId}
                  permission={permission && permission}
                />
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {child != 0 && (
                  <EditGroupName
                    value={sessionStorage.getItem("groupNameFeature")}
                    idd={child}
                    permission={permission && permission}
                  />
                )}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              {
                validatedStatus !== undefined &&
                  permission &&
                  permission?.includes("U") && (
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      Status:
                      {validatedStatus !== undefined && validatedStatus == 0 ? (
                        <Box
                          sx={{
                            border: "2px solid #d32f2f",
                            color: "#d32f2f",
                            borderRadius: "5px",
                            height: { lg: "2rem", xl: "1.85rem" },
                            padding: "0.15rem 0.5rem",
                            width: {
                              lg: "9.2rem",
                              xl: "8rem",
                              textAlign: "center",
                            },
                            whiteSpace: "nowrap",
                          }}
                        >
                          Not Started
                        </Box>
                      ) : validatedStatus !== undefined &&
                        validatedStatus == 1 ? (
                        <Box
                          sx={{
                            border: "2px solid #ffc800",
                            color: "#ffc800",
                            borderRadius: "5px",
                            height: { lg: "2rem", xl: "1.85rem" },
                            padding: "0.1rem 0.5rem",
                            width: {
                              lg: "9.2rem",
                              xl: "8rem",
                              textAlign: "center",
                            },
                            whiteSpace: "nowrap",
                          }}
                        >
                          In Progress
                        </Box>
                      ) : validatedStatus !== undefined &&
                        validatedStatus == 2 ? (
                        <FormControl
                          sx={{
                            minWidth: 110,
                            cursor:
                              validatedStatus !== undefined &&
                              validatedStatus == 3
                                ? "default"
                                : "pointer",
                          }}
                          size="small"
                          variant="standard"
                        >
                          <LoadingButton
                            // loading={loadingStatus
                            // }
                            sx={{ textTransform: "capitalize", padding: "0" }}
                          >
                            {
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={
                                  validatedStatus !== undefined &&
                                  validatedStatus
                                }
                                label="Status"
                                onChange={(e: any) =>
                                  handleChangeValidateStatus(e)
                                }
                                // disabled={bomStatus && BOMStatus == 3 ? true : false}
                                sx={{
                                  width: { lg: "9rem", xl: "8rem" },
                                  cursor:
                                    validatedStatus !== undefined &&
                                    validatedStatus == 3
                                      ? "default"
                                      : "pointer",
                                  "&:before": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "&:after": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                    {
                                      fontSize: "1rem",
                                      border: "2px solid",
                                      borderRadius: "5px",
                                      height: "1rem",
                                      padding: "0.1rem 0.5rem 0.1rem",
                                      color:
                                        validatedStatus !== undefined &&
                                        validatedStatus == 0
                                          ? "red"
                                          : validatedStatus !== undefined &&
                                            validatedStatus == 1
                                          ? "#ffc800"
                                          : validatedStatus !== undefined &&
                                            validatedStatus == 2
                                          ? "#007fff"
                                          : validatedStatus !== undefined &&
                                            validatedStatus == 3
                                          ? "#008000"
                                          : "red", //status Color update
                                    },
                                  "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                                    {
                                      border: "2px solid",
                                      borderColor: "red !important", //status Color update
                                    },
                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                    color:
                                      validatedStatus !== undefined &&
                                      validatedStatus == 0
                                        ? "red"
                                        : validatedStatus !== undefined &&
                                          validatedStatus == 1
                                        ? "#ffc800"
                                        : validatedStatus !== undefined &&
                                          validatedStatus == 2
                                        ? "#007fff"
                                        : validatedStatus !== undefined &&
                                          validatedStatus == 3
                                        ? "#008000"
                                        : "red", //status Color update
                                  },
                                }}
                              >
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "red",
                                      display: "none",
                                    }}
                                    value={0}
                                  >
                                    Not Started
                                  </MenuItem>
                                }
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#ffc800",
                                      display: "none",
                                    }}
                                    value={1}
                                  >
                                    In Progress
                                  </MenuItem>
                                }
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#ffc800",
                                      display: "none",
                                    }}
                                    value={2}
                                  >
                                    Completed
                                  </MenuItem>
                                }
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    color: "#007fff",
                                    display:
                                      validatedStatus !== undefined &&
                                      validatedStatus === 2
                                        ? ""
                                        : "none",
                                  }}
                                  value={3}
                                >
                                  Validated
                                </MenuItem>
                              </Select>
                            }
                          </LoadingButton>
                        </FormControl>
                      ) : validatedStatus !== undefined &&
                        validatedStatus == 3 ? (
                        <Box
                          sx={{
                            border: "2px solid #008000",
                            color: "#008000",
                            borderRadius: "5px",
                            height: { lg: "2rem", xl: "1.85rem" },
                            padding: "0.15rem 0.5rem",
                            width: {
                              lg: "9.2rem",
                              xl: "8rem",
                              textAlign: "center",
                            },
                          }}
                        >
                          Validated
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  )

                // (

                //   <Checkbox
                //     style={{ padding: "3px" }}
                //     onChange={() => handleChangeValidateStatus()}
                //     checked={validatedStatus && validatedStatus}
                //     value={validatedStatus}
                //     checkedIcon={
                //       <img src={validated} style={{ height: "1.5rem" }} />
                //     }
                //     title={
                //       validatedStatus == true ? "Validated" : "Not Validated"
                //     }
                //     color="success"
                //     sx={{
                //       "& .MuiSvgIcon-root": { fontSize: 22 },
                //       color: "#007fff",
                //     }}
                //   />
                // )
              }

              {/* {dbpermission &&
                dbpermission?.includes("U") && (
                <Button endIcon={<img src={rightArrow} style={{height:'1.3rem',marginTop:'-3px'}} />} size="small" sx={{whiteSpace:'nowrap',lineHeight: 1,}} onClick={()=>gotoDBHandler()}>GO to Feature DB</Button>)} */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid",
                  borderBottomColor: "primary.light",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  // value={search}
                  onChange={handleChangeSearch}
                  options={allFeatureList}
                  sx={{
                    width: 300,
                    ".MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      sx={{
                        borderBottom: "none",
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      {...params}
                      placeholder="Search"
                    />
                  )}
                />
              </Box>
              {recyclebinCount && recyclebinCount > 0 ? (
                <Typography
                  title="Recycle bin"
                  style={{ cursor: "pointer", marginTop: "-0.2rem" }}
                  onClick={() => RecycleBinHanlder()}
                >
                  <Badge
                    badgeContent={`${recyclebinCount && recyclebinCount}`}
                    color="error"
                  >
                    <img
                      src={recyleBin}
                      style={{ height: "1.5rem", cursor: "pointer" }}
                    />
                  </Badge>
                </Typography>
              ) : (
                <DeleteOutlineIcon
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                    fontSize: "1.8rem",
                  }}
                  onClick={() => RecycleBinHanlder()}
                  titleAccess="Recycle bin"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: { xs: "80vw", sm: "96%", md: "96%", lg: "96%", xl: "97%" },
            height: { xs: "87vh", lg: "86vh", xl: "90vh" },
            paddingRight: "0.5rem",
            overflow: { xs: "auto", lg: "visible", xl: "visible" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {/* sx={{ width: { lg: "96%", xl: "96.5%" } }}> */}
          <CreateFeature />
        </Box>
      </Box>
    </div>
  );
}
