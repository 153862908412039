import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  Dialog,
  DialogContent,
  Table,
  TableCell,
  TableRow,
  Chip,
  Skeleton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AnalysisWeightLandingPage.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
  Cell,
  Surface,
  Symbols,
  Label,
} from "recharts";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import TableIcon from "../../Assets/images/svgs/tableIcon.svg";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AnalyseModal from "./AnalyseModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { ReactComponent as GotoIcon } from "../../Assets/images/rightArrows.svg";

//Dilalog Box
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CurrencyChange } from "../../ViewModule/CostingView/CurrencyChange";
import { currencyFormat } from "../../utlis/CurrencyFormat";
import { AUTH } from "../../Redux/Services/auth.service";
import CostingAnalysisPareto from "./CostingAnalysisPareto";
import { ButtonProps } from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import SyncIcon from '@mui/icons-material/Sync';

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  topVault: any;
}

const csvOptions: GridCsvExportOptions = {
  fileName: `Pareto Analysis Table In CSV`,
  delimiter:",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props: any) => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    {/* <h4 style={{ position: "absolute", left: "40%" }}>{props}</h4> */}
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AnalysisCostInnerPageProps {}

const AnalysisCostInnerPage: React.FC<AnalysisCostInnerPageProps> = (props) => {
  const { projectId, topVault } = useRouteParams<Params>();
  const [analyseWeight, setAnalyseWeight] = useState<any>([]);
  const [okButtonLoader, setOKButtonLoader] = useState<boolean>(false);
  const [redirectRoute, setRedirectRoute] = useState<any>();
  const [redirectInnerRoute, setRedirectInnerRoute] = useState<any>();
  const [analyseProjects, setAnalyseProjects] = useState<any>();
  const [analyseProduct, setAnalyseProduct] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [apiLoader, setApiLoader] = useState<boolean>(false);
  const currencyModal = useBaseModal();
  const [count, setCount] = React.useState<any>(0);
  const [AllProjectdata, setAllProjectdata] = useState();
  const [Counter, setCounter] = useState(0);
  const [closeRedirectPopup, setCloseRedirectPopup] = useState<boolean>(false);
  const [analysisModuleName, setAnalysisModuleName] = useState<any>();
  const [typeBopDc, setTypeBopDc] = useState<any>("dc");

  const [tableOpenParetoAnalysis,setTableOpenParetoAnalysis] = React.useState(false);
  const [typeOfTable,setTypeOfTable] = useState<any>();
  const [topVaultColorMap, setTopVaultColorMap] = useState<any>();

  const [moduleApi, setModuleApi] = useState<any>();

  const [openMoreGraphPopUp, setOpenMoreGraphPopUp] = React.useState(false);

  const [loaderTotalCost    , setLoaderTotalCost   ] = useState<boolean>(false);
  const [loaderMaterialCost , setLoaderMaterialCost] = useState<boolean>(false);
  const [loaderProcessCost  , setLoaderProcessCost ] = useState<boolean>(false);
  const [loaderOverheadCost , setLoaderOverheadCost] = useState<boolean>(false);
  const [loaderBopDcCost    , setLoaderBopDcCost   ] = useState<boolean>(false);
  const [isTopVaultColorFetched, setIsTopVaultColorFetched] = useState<boolean>(false);
  const [loaderkgCost , setLoaderkgCost] = useState<boolean>(false);

  const getPermissionCurrencyButton = () => {
    if (AUTH.isSuperUser) {
      return true;
    } else if (AUTH.user_category !== "Internal") {
      return true;
    } else {
      return false;
    }
  };

  const analyseModal = useBaseModal();
  const history = useHistory();
  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];

  const { url } = useRouteMatch();
  useEffect(() => {
    if (url?.includes(`analysis`)) {
      API.get(
        "/customer/costing_conversion/",
        { get_conversion_factor: true, module: "Analysis" },
        0
      ).then((res: any) => {
        sessionStorage.setItem(
          "currency_analysis",
          res.data[0]?.current_currency
        );
        url?.includes("analysis")
          ? sessionStorage.setItem(
              "actionkey_analysis",
              res.data[0]?.is_manual ? "mannual" : "auto"
            )
          : sessionStorage.setItem(
              "actionKey",
              res.data[0]?.is_manual ? "mannual" : "auto"
            );
        if (res.data[0]?.is_manual) {
          url?.includes("analysis")
            ? sessionStorage.setItem(
                "currencyrate_analysis_mannual",
                res.data[0]?.conversion_factor
              )
            : sessionStorage.setItem(
                "currencyrate_mannual",
                res.data[0]?.conversion_factor
              );
        } else {
          url?.includes("analysis")
            ? sessionStorage.setItem(
                "currencyrate_analysis",
                (1 / res.data[0]?.conversion_factor).toString()
              )
            : sessionStorage.setItem(
                "currencyrate",
                (1 / res.data[0]?.conversion_factor).toString()
              );
        }
      });
    }
  }, [count]);

  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];
  const projectIdParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) {
    return a - b;
  });

  var projectLength = AllProjectdata && Object.keys(AllProjectdata[0])?.length;

  const getColorCode = () => {
    API.get("/customer/get_product_color/", {
      product_ids: topVault
    }, 0)
      .then((res) => {
        let topVaultColorObj: any = {};
        
        res?.data?.forEach((item: any, index: any) => {
          topVaultColorObj[item?.product_id] = item?.color;
        })
        console.log(topVaultColorObj, "topVaultColorObjCheck")
        setTopVaultColorMap(topVaultColorObj);
        setIsTopVaultColorFetched(true);
      })
      .catch((e) => {
        console.log(e);
      }
      )



  }

  React.useEffect(() => {
    getColorCode()
  }
    , [topVault, Counter]);

  const deleteProject = (item: any) => {
    setCounter(Counter + 1);
    var dataArray = [item];
    const filteredProject =
      AllProjectdata &&
      AllProjectdata[0] &&
      Object.keys(AllProjectdata[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = AllProjectdata[0][key];
          return obj;
        }, {});
    const FilterTopVault =
      filteredProject &&
      Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);

    let valProject: any = [];
    let abc: any =
      filteredProject &&
      Object.entries(filteredProject).forEach(([key, value]: any) => {
        valProject.push(
          filteredProject && filteredProject[key][0]["project_id"]
        );
      });
    //  @ts-ignore
    history.push(`/analysis_costing/${url?.includes("manufacturing")? "manufacturing": url?.includes("kgCost")? "kgCost": "bopdc"}/${valProject?.length > 0 && valProject && valProject.toString()}/${FilterTopVault?.toString()}`);
  };

  const handleDelete = (item1: any) => {
    setCounter(Counter + 1);
    var ProjectIdsdata = AllProjectdata && AllProjectdata[0];
    var CollectionProjectids =
      ProjectIdsdata &&
      Object.values(ProjectIdsdata)
        .map((item: any, index: any) => {
          return item.map((item2: any, index1: any) => {
            return item2?.id;
          });
        })
        .flat(1)
        .filter((item3: any, index: any) => {
          return item3 != item1;
        });

    var CollectionProjectidsABC =
      ProjectIdsdata &&
      Object.values(ProjectIdsdata).map((item: any, index: any) => {
        return item.map((item2: any, index1: any) => {
          return item2?.id;
        });
      });

    let vaultsData: any = [];
    let vaultVal: any = [];
    let abc: any =
      ProjectIdsdata &&
      Object.entries(ProjectIdsdata).forEach(([key, value]: any) => {
        vaultsData.push(ProjectIdsdata && ProjectIdsdata[key]);
      });

    const leftProduct: any = vaultsData
      ?.flat()
      ?.filter((item: any) => item?.id != item1);

    let projectIDLeft: any = [];
    let mapleftProduct: any =
      leftProduct &&
      leftProduct?.map((item: any) => {
        if (projectIDLeft.includes(item?.project_id)) {
          return;
        } else {
          projectIDLeft.push(item?.project_id);
        }
      });
    // @ts-ignore
    history.push(`/analysis_costing/${url?.includes("manufacturing")? "manufacturing": url?.includes("kgCost")? "kgCost": "bopdc"}/${projectIDLeft.toString()}/${CollectionProjectids?.toString()}`);
  };

  const ChipContainer = (chipItem: any) => {
    var chipdata = AllProjectdata && AllProjectdata[0][chipItem];
    // @ts-ignore
    var mapChipData = chipdata?.map((item: any, index: any) => {
      return (
        <>
          {filterDataIds && filterDataIds?.length <= 2 ? (
            <Chip
              label={item?.name}
              sx={{
                fontSize: "0.87rem",
                height: { xs: "30px", lg: "20px", xl: "30px" },
                color: "primary.light !important",
                display: "flex",
                columnGap: "5px",
                backgroundColor: "#007fff14 !important",
                "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChip-deleteIconColorPrimary":
                  {
                    fontSize: "1rem !important",
                    color: "primary.main !important",
                  },
              }}
            />
          ) : (
            <Chip
              icon={
                <CancelIcon
                  onClick={() => handleDelete(item?.id)}
                  color="primary"
                  sx={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    color: "primary.main !important",
                    opacity: 0.8,
                    "& :hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  titleAccess="Delete"
                />
              }
              label={item?.name}
              sx={{
                fontSize: "0.87rem",
                height: { xs: "30px", lg: "20px", xl: "30px" },
                margin: "2px 0",
                color: "primary.light !important",
                backgroundColor: "#007fff14 !important",
                "& :hover": {
                  opacity: 1,
                },
              }}
            />
          )}
        </>
      );
    });
    return mapChipData;
  };

  const handleKgCost = () => {
    if(isTopVaultColorFetched){
    API.get(
      `/customer/analysis/`,
      {
        vehicle_ids: topVault,
        analysis_type: "cost_per_kg_subsystemwise",
        colors: topVault?.split(',')?.map((item: any, index: any) => {
            
          if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
            return topVaultColorMap[item];
          }
          else {
            return topVaultColor[index];
          }})?.toString()
      },
      0
    )
      .then((res: any) => {
        setApiLoader(true);
        setAnalyseWeight(res?.data);
      })
      .catch((err: any) => {
        setApiLoader(true);
        console.log(err, "error");
      })
    }
  }

  const handleBOPDC = () => {
    if(isTopVaultColorFetched){
      API.get(
        `/customer/analysis/`,
        {
          vehicle_ids: topVault,
          analysis_type: "bop_dc_analysis_subsystemwise",
          colors: topVault?.split(',')?.map((item: any, index: any) => {
        
            if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
              return topVaultColorMap[item];
            }
            else {
              return topVaultColor[index];
            }})?.toString(),
          costing_type: typeBopDc,
        },
        0
      )
        .then((res: any) => {
          setApiLoader(true);
          setAnalyseWeight(res?.data);
        })
        .catch((err: any) => {
          setApiLoader(true);
          console.log(err, "error");
        });
    }
  }

  useEffect(() => {
    API.get("/customer/analysis/", { get_drawer_values: "modules" }, 0).then(
      (res: any) => {
        setModuleApi(res?.data);
      }
    );
  }, []);

  useEffect(() => {
    API.get("customer/analysis/", {
      product_ids: topVault,
      get_drawer_values: url?.includes("analysis_costing") ? "Costing" : "BOM",
    }).then((res: any) => {
      var datavalues = res?.data
        ?.map((item: any, index: any) => {
          return Object.values(item).map((item1: any, index: any) => {
            return item1.map((item2: any, index: any) => {
              return item2?.project_id;
            });
          });
        })
        .flat(2);
      //  @ts-ignore
      setAllProjectdata(res.data);
    });
  }, [topVault]);

  const selectAllProject = (productIds: any) => {
    if (selectedProject?.includes(productIds?.toString())) {
      let letProjects: any =
        selectedProject &&
        selectedProject?.filter((item: any) => item !== productIds?.toString());
      let remainingProductData: any =
        analyseProduct &&
        analyseProduct?.filter((item: any) => {
          return selectedProduct?.includes(item?.id?.toString());
        });
      let includedProductData: any =
        analyseProduct &&
        remainingProductData?.filter((item: any) => {
          return letProjects?.includes(item?.project?.toString());
        });
      let remainingProduct: any =
        analyseProduct &&
        remainingProductData &&
        includedProductData &&
        includedProductData?.map((item: any) => {
          return item?.id?.toString();
        });
      analyseProduct &&
        remainingProductData &&
        includedProductData &&
        remainingProduct &&
        setSelectedProduct(remainingProduct);
      setSelectedProject((prev: any) =>
        prev?.filter((item: any) => item !== productIds?.toString())
      );
    } else {
      setSelectedProject((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  useEffect(() => {
    setAnalysisModuleName(
      url?.includes("analysis_costing") ? "analysis_costing" : "analysis"
    );
  }, [projectId, topVault]);

  useEffect(() => {
    if (selectedProject.length > 0 && selectedProject != undefined) {
      API.get(
        `customer/analysis/`,
        {
          get_drawer_values: "top_vault",
          project: selectedProject.toString(),
          module: analysisModuleName == "analysis_costing" ? "Costing" : "BOM",
        },
        0
      )
        .then((res: any) => {
          setAnalyseProduct(res?.data);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [selectedProject, analysisModuleName]);

  useEffect(() => {
    API.get(
      `customer/analysis/`,
      {
        get_drawer_values: "project",
        module: analysisModuleName == "analysis_costing" ? "Costing" : "BOM",
      },
      0
    )
      .then((res: any) => {
        setAnalyseProjects(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [analysisModuleName]);

  useEffect(() => {
    setApiLoader(false);
    {
      url?.includes("manufacturing")
        ? API.get(
            `/customer/analysis/`,
            {
              vehicle_ids: topVault,
              analysis_type: "cost_subsystemwise",
              colors: topVault?.split(',')?.map((item: any, index: any) => {
            
                if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                  return topVaultColorMap[item];
                }
                else {
                  return topVaultColor[index];
                }})?.toString()
            },
            0
          )
            .then((res: any) => {
              setApiLoader(true);
              setAnalyseWeight(res?.data);
            })
            .catch((err: any) => {
              setApiLoader(true);
              console.log(err, "error");
            })
        : url?.includes("kgCost")
        ? handleKgCost()
        : handleBOPDC()
    }
  }, [projectId, topVault, count, typeBopDc, topVaultColorMap, Counter, isTopVaultColorFetched]);

  const handleChange = (event: SelectChangeEvent) => {
    setTypeBopDc(event.target.value as string);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSelectedProject(
        projectId && projectId.toString()?.includes(",")
          ? projectId?.split(",")
          : [projectId?.toString()]
      );
      setSelectedProduct(
        topVault && topVault.toString()?.includes(",")
          ? topVault?.split(",")
          : [topVault?.toString()]
      );
      setState({ ...state, [anchor]: open });
      if (open) {
        setAnalysisModuleName(
          url?.includes("analysis_costing") ? "analysis_costing" : "analysis"
        );
      }
    };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const selectAllProduct = (productIds: any) => {
    if (selectedProduct?.includes(productIds?.toString())) {
      setSelectedProduct((prev: any) =>
        prev?.filter((item: any) => item !== productIds.toString())
      );
    } else {
      setSelectedProduct((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push(`/analysis_costing/${projectId}/${topVault}`);
  };

  let disableOkBtn = () => {
    let temp = false;
    if (selectedProject?.length >= 1) {
      if (selectedProduct?.length >= 1) {
        if (selectedProduct?.length < 8) {
          temp = false;
        } else {
          temp = true;
        }
      } else {
        temp = true;
      }
    } else {
      temp = true;
    }
    return temp;
  };

  const changeOkButton = () => {
    if (analysisModuleName == "analysis") {
      history.push(
        `/${analysisModuleName}/${selectedProject.toString()}/${selectedProduct.toString()}`
      );
    } else {
      history.push(
        `/${analysisModuleName}/${
          url?.includes("manufacturing")
            ? "manufacturing"
            : url?.includes("kgCost")
            ? "kgCost"
            : "bopdc"
        }/${selectedProject.toString()}/${selectedProduct.toString()}`
      );
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <List>
        <Box sx={{ width: "100%" }}>
          <div style={{ display: "flex", columnGap: "5px" }}>
            {moduleApi && moduleApi?.includes("BOM") && (
              <div
                onClick={() => {
                  return (
                    setAnalysisModuleName("analysis"),
                    setSelectedProject([]),
                    setSelectedProduct([]),
                    setAnalyseProduct(undefined)
                  );
                }}
                style={
                  analysisModuleName && analysisModuleName == "analysis"
                    ? {
                        width: `${
                          moduleApi && moduleApi?.length > 1 ? "50%" : "100%"
                        }`,
                        backgroundColor: "#007fff",
                        padding: "0.2rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }
                    : {
                        width: "50%",
                        backgroundColor: "#007fff14",
                        padding: "0.2rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }
                }
              >
                <Typography
                  style={
                    analysisModuleName && analysisModuleName == "analysis"
                      ? { color: "white", fontSize: "1rem" }
                      : { color: "#007fff", fontSize: "1rem" }
                  }
                >
                  BOM
                </Typography>
              </div>
            )}
            {moduleApi && moduleApi?.includes("Costing") && (
              <div
                style={
                  analysisModuleName && analysisModuleName == "analysis_costing"
                    ? {
                        width: `${
                          moduleApi && moduleApi?.length > 1 ? "50%" : "100%"
                        }`,
                        backgroundColor: "#007fff",
                        padding: "0.2rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }
                    : {
                        width: "50%",
                        backgroundColor: "#007fff14",
                        padding: "0.2rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }
                }
              >
                <Typography
                  onClick={() => {
                    return (
                      setAnalysisModuleName("analysis_costing"),
                      setSelectedProject([]),
                      setSelectedProduct([]),
                      setAnalyseProduct(undefined)
                    );
                  }}
                  style={
                    analysisModuleName &&
                    analysisModuleName == "analysis_costing"
                      ? { color: "white", fontSize: "1rem" }
                      : { color: "#007fff", fontSize: "1rem" }
                  }
                >
                  Costing
                </Typography>{" "}
              </div>
            )}
          </div>
        </Box>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Project Name
              </Typography>
              {analyseProjects &&
                analyseProjects?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            checked={
                              selectedProject?.includes(item.id?.toString())
                                ? true
                                : false
                            }
                            onClick={() =>
                              selectAllProject(item?.id?.toString())
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          />
                          <Box
                            onClick={() =>
                              selectAllProject(item?.id?.toString())
                            }
                            key={index}
                            className={styles.selectVehBtn}
                            sx={{
                              // margin: "0rem 2rem",
                              padding: "0.2rem 0.5rem",
                              backgroundColor:
                                getselectedProjectBackgroundColor(
                                  item.id?.toString()
                                ),
                              color: getSelectedProjectTextColor(
                                item.id?.toString()
                              ),
                              textAlign: "center",
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1rem",
                                textAlign: "left",
                                display: "flex",
                              }}
                            >
                              {item.name}{" "}
                              <span
                                style={{
                                  marginLeft: "0.5rem",
                                  display: "inline-block",
                                  width: "14rem",
                                }}
                              >
                                [ {item.description} ]
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <div style={{ marginLeft: "2rem" }}>
                          {analyseProduct &&
                            analyseProduct
                              ?.filter((itemVal: any) => {
                                return itemVal?.project == item?.id;
                              })
                              ?.map((itemVal: any, indexVal: any) => {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        width: "18rem",
                                      }}
                                    >
                                      <Checkbox
                                        checked={
                                          selectedProduct?.includes(
                                            itemVal?.id?.toString()
                                          )
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          selectAllProduct(itemVal?.id)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 24,
                                            color: "#2b9cf6eb",
                                          },
                                        }}
                                      />
                                      <Box
                                        onClick={() =>
                                          selectAllProduct(itemVal?.id)
                                        }
                                        key={indexVal}
                                        className={styles.selectVehBtn}
                                        sx={{
                                          // margin: "0rem 2rem",
                                          padding: "0.2rem 0.5rem",
                                          backgroundColor:
                                            getselectedProductBackgroundColor(
                                              itemVal.id
                                            ),
                                          color: getSelectedProductTextColor(
                                            itemVal.id
                                          ),
                                          textAlign: "center",
                                          cursor: "pointer",
                                          display: "inline-block",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "1rem",
                                            textAlign: "left",
                                          }}
                                        >
                                          {itemVal.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                        </div>
                      </Box>
                    </>
                  );
                })}
            </div>
          </Box>
        </ListItem>
      </List>
      <div
        style={{
          marginTop: "1rem",
          paddingBottom: "1rem",
          position: "sticky",
          bottom: "0",
          backgroundColor: "aliceblue",
        }}
      >
        <Box>
          {selectedProject?.length < 1 && (
            <Typography
              style={{
                margin: "0",
                marginLeft: "1.8rem",
                color: "red",
                fontSize: "1rem",
              }}
            >
              * Please select more than one project
            </Typography>
          )}
          {selectedProject?.length >= 1 && selectedProduct?.length < 1 && (
            <Typography
              style={{
                margin: "0",
                marginLeft: "1.8rem",
                color: "red",
                fontSize: "1rem",
              }}
            >
              * Please select more than one project
            </Typography>
          )}
          {selectedProduct?.length > 7 && (
            <Typography
              style={{
                margin: "0",
                marginLeft: "1.8rem",
                color: "red",
                fontSize: "1rem",
              }}
            >
              * Please select less than eight project
            </Typography>
          )}
        </Box>
        <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
          <Button
            className={styles.drawBtn}
            sx={{ marginRight: "1rem" }}
            variant="contained"
            onClick={toggleDrawer(anchor, false)}
            size="small"
          >
            Close
          </Button>
          <LoadingButton
            style={
              disableOkBtn()
                ? { backgroundColor: "aliceblue", cursor: "not-allowed" }
                : {}
            }
            loading={okButtonLoader}
            size="small"
            disabled={disableOkBtn()}
            onClick={changeOkButton}
          >
            <Button
              style={
                disableOkBtn()
                  ? {
                      backgroundColor: "#ddd",
                      cursor: "not-allowed",
                      color: "grey",
                    }
                  : {}
              }
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Ok
            </Button>
          </LoadingButton>
        </Box>
      </div>
    </Box>
  );

  const AnalysisVehicleName: any =
    analyseWeight.length > 0 &&
    Object.keys(analyseWeight[0])?.filter((item: any) => {
      return (
        item != "subsytem_name" &&
        item != "abbreviation" &&
        item != "top_vault_details"
      );
    });

  let [x, setX] = useState<any>();
  let [y, setY] = useState<any>();
  const valBarRedirect: any = (e: any, itemSelected: any) => {
    setRedirectRoute(itemSelected);
    setRedirectInnerRoute(e);
    setCloseRedirectPopup(true);
    setX(e?.x);
    setY(e?.y);
  };

  const valRedirect: any = (moduleType: any) => {
    const valueFiltered: any =
      redirectInnerRoute &&
      redirectInnerRoute?.top_vault_details?.filter((item: any) => {
        return item?.top_vault_name == redirectRoute;
      });
    sessionStorage.setItem(
      "node",
      JSON.stringify(valueFiltered[0]?.ancestor_tree)
    );
    valueFiltered &&
      valueFiltered.length > 0 &&
      redirectInnerRoute &&
      window.open(
        `/#/view/${valueFiltered[0]?.project_id}/${valueFiltered[0]?.top_vault_id}/${valueFiltered[0]?.vault_id}/${redirectInnerRoute?.abbreviation}/costing`
      );
    setCloseRedirectPopup(false);
  };

  const valOpenMoreGraphPopUpDialog: any = () => {
    setOpenMoreGraphPopUp(true);
    setCloseRedirectPopup(false);
  };

  const handleMoreGraphPopUpClose: any = () => {
    setOpenMoreGraphPopUp(false);
    setFullscreen(false)
    setTableOpenParetoAnalysis(false);
    setTypofAnalysisData(undefined);
    setParetoTotalCostAnalysis(undefined);
  };

  const renderCusomizedLegend: any = (props: any) => {
    const { payload } = props;
    return (
      <div
        className="customized-legend"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          margin: "1rem",
        }}
      >
        {payload?.map((entry: any) => {
          return (
            <span className="legend-item" style={{ marginRight: "0.5rem" }}>
              <Surface width={10} height={10} style={{ marginRight: "0.5rem" }}>
                <Symbols
                  cx={5}
                  cy={5}
                  type="square"
                  size={120}
                  fill={
                    analyseWeight[0]?.top_vault_details?.filter((item: any) => {
                      return item?.top_vault_name == entry?.dataKey;
                    })[0]?.color
                  }
                />
              </Surface>
              <span style={{ color: "black", fontSize: "12px" }}>
                {entry?.dataKey}
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let valueNonZero: any =
        payload &&
        payload?.filter((item: any) => {
          return item?.value != 0;
        });
      return (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
              {label}
            </span>
          </div>
          {valueNonZero &&
            valueNonZero?.map((item: any) => {
              return (
                <>
                  <Table>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0 0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <Box
                          sx={{
                            width: "0.75rem",
                            height: "0.75rem",
                            lineHeight: "1",
                            backgroundColor:
                              analyseWeight[0]?.top_vault_details?.filter(
                                (itemColor: any) => {
                                  return (
                                    itemColor?.top_vault_name == item?.dataKey
                                  );
                                }
                              )[0]?.color,
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          display: "flex",
                          justifyContent: "space-between",
                          columnGap: "10px",
                          alignItems: "center",
                          minWidth: "12rem",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            color: "black",
                            lineHeight: "1.2",
                          }}
                        >{`${item?.dataKey}`}</Typography>{" "}
                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            color: "black",
                            lineHeight: "1.2",
                          }}
                        >
                          {currencyFormat(
                            item?.value,
                            sessionStorage.getItem("currency_analysis")
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          minWidth: "4rem",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "0.75rem",
                            color: "black",
                            lineHeight: "1.2",
                          }}
                        >
                          {url?.includes(`/manufacturing/`)
                            ? (
                                (item?.value /
                                  (analyseWeight[0]?.top_vault_details?.filter(
                                    (itemColor: any) => {
                                      return (
                                        itemColor?.top_vault_name ==
                                        item?.dataKey
                                      );
                                    }
                                  )[0]["total_vehicle_cost"] == 0
                                    ? 1
                                    : analyseWeight[0]?.top_vault_details?.filter(
                                        (itemColor: any) => {
                                          return (
                                            itemColor?.top_vault_name ==
                                            item?.dataKey
                                          );
                                        }
                                      )[0]["total_vehicle_cost"])) *
                                100
                              ).toFixed(1) + " %"
                            : url?.includes(`/kgCost/`)
                            ? (
                              <div></div>
                            )
                            : (
                                (item?.value /
                                  (analyseWeight[0]?.top_vault_details?.filter(
                                    (itemColor: any) => {
                                      return (
                                        itemColor?.top_vault_name ==
                                        item?.dataKey
                                      );
                                    }
                                  )[0]["total_cost_per_kg"] == 0
                                    ? 1
                                    : analyseWeight[0]?.top_vault_details?.filter(
                                        (itemColor: any) => {
                                          return (
                                            itemColor?.top_vault_name ==
                                            item?.dataKey
                                          );
                                        }
                                      )[0]["total_cost_per_kg"])) *
                                100
                              ).toFixed(1) + " %"} {" "}
                          
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              );
            })}
        </div>
      );
    }
    return null;
  };

  const [paretoTotalCostAnalysis, setParetoTotalCostAnalysis] = useState<any>();
  const [paretoMaterialCostAnalysis, setParetoMaterialCostAnalysis] =
    useState<any>();
  const [paretoProcessCostAnalysis, setParetoProcessCostAnalysis] =
    useState<any>();
  const [paretoOverheadCostAnalysis, setParetoOverheadCostAnalysis] =
    useState<any>();
  const [fullscreen,setFullscreen] =
  useState<boolean>(false);
  const [typeofAnalysisData,setTypofAnalysisData] =
  useState<any>();
  const [paretoBopDcCostAnalysis, setParetoBopDcCostAnalysis] =
    useState<any>();
  const [paretokgCostAnalysis, setParetokgCostAnalysis] =
    useState<any>();

  useEffect(() => {
    if (openMoreGraphPopUp == true) {
      setLoaderTotalCost(false);  
      setLoaderMaterialCost(false);
      setLoaderProcessCost(false); 
      setLoaderOverheadCost(false);
      setLoaderkgCost(false); 
      setLoaderBopDcCost(false);   
      const valueFiltered: any =
      redirectInnerRoute &&
      redirectInnerRoute?.top_vault_details?.filter((item: any) => {
        return item?.top_vault_name == redirectRoute;
      });
      if (url?.includes("manufacturing")) {
        API.get(
          `customer/pareto_analysis/`,
          { subsytem: valueFiltered[0]?.vault_id, total_cost: true },
          0
        )
          .then((res: any) => {
            setParetoTotalCostAnalysis(res?.data);
            setLoaderTotalCost(true);
          })
          .catch((err: any) => {
            console.log(err, "error");
          });

        API.get(
          `customer/pareto_analysis/`,
          { subsytem: valueFiltered[0]?.vault_id, material_cost: true },
          0
        )
          .then((res: any) => {
            setLoaderMaterialCost(true);
            setParetoMaterialCostAnalysis(res?.data);
          })
          .catch((err: any) => {
            console.log(err, "error");
          });

        API.get(
          `customer/pareto_analysis/`,
          { subsytem: valueFiltered[0]?.vault_id, process_cost: true },
          0
        )
          .then((res: any) => {
            setLoaderProcessCost(true)
            setParetoProcessCostAnalysis(res?.data);
          })
          .catch((err: any) => {
            console.log(err, "error");
          });

        API.get(
          `customer/pareto_analysis/`,
          { subsytem: valueFiltered[0]?.vault_id, overhead_cost: true },
          0
        )
          .then((res: any) => {
            setLoaderOverheadCost(true)
            setParetoOverheadCostAnalysis(res?.data);
          })
          .catch((err: any) => {
            console.log(err, "error");
          });
      }
      else if(url?.includes("bopdc")){
        if(typeBopDc=='dc'){
          API.get(
            `customer/pareto_analysis/`,
            { subsytem: valueFiltered[0]?.vault_id, dc_cost: true },
            0
          )
            .then((res: any) => {
              setLoaderBopDcCost(true)  
              setParetoBopDcCostAnalysis(res?.data);
            })
            .catch((err: any) => {
              console.log(err, "error");
            });
        }
        else{
          API.get(
            `customer/pareto_analysis/`,
            { subsytem: valueFiltered[0]?.vault_id, bop_cost: true },
            0
          )
            .then((res: any) => {
              setLoaderBopDcCost(true) 
              setParetoBopDcCostAnalysis(res?.data);
            })
            .catch((err: any) => {
              console.log(err, "error");
            });
        }
      }
      else if(url?.includes("kgCost")){
        API.get(
          `customer/pareto_analysis/`,
          { subsytem: valueFiltered[0]?.vault_id, cost_per_kg: true },
          0
        )
          .then((res: any) => {
            setLoaderkgCost(true); 
            setParetokgCostAnalysis(res?.data);
          })
          .catch((err: any) => {
            console.log(err, "error");
          });
      }
    }
  }, [openMoreGraphPopUp]);

  const handleFullScreen=(typeAnalysis:any)=>{
    setFullscreen(!fullscreen);
    setTypofAnalysisData(typeAnalysis);
  }

  let columnsPerKgCost: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
      field: "parentName",
      headerName: "Parent Name",
      width: 320,
      flex: 1
      },
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretokgCostAnalysis && paretokgCostAnalysis?.unit}/kg)`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsPerKgCost: any = [];

  const dataGridDataGeneratorPerKgCost: any = paretokgCostAnalysis && paretokgCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      parentName:val?.parent_name,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsPerKgCost.push(objShared);
  })

  let dataStructureDataGridPerKgCost: any = {
    columns: columnsPerKgCost,
    rows: rowsPerKgCost,
  };

  let columnsTotal: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.unit})`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsTotal: any = [];

  const dataGridDataGeneratorTotal: any = paretoTotalCostAnalysis && paretoTotalCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsTotal.push(objShared);
  })

  let dataStructureDataGridTotal: any = {
    columns: columnsTotal,
    rows: rowsTotal,
  };

  let columnsMaterial: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretoMaterialCostAnalysis && paretoMaterialCostAnalysis?.unit})`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsMaterial: any = [];

  const dataGridDataGeneratorMaterial: any = paretoMaterialCostAnalysis && paretoMaterialCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsMaterial.push(objShared);
  })
  

  let dataStructureDataGridMaterial: any = {
    columns: columnsMaterial,
    rows: rowsMaterial,
  };

  let columnsProcess: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretoProcessCostAnalysis && paretoProcessCostAnalysis?.unit})`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsProcess: any = [];

  const dataGridDataGeneratorProcess: any = paretoProcessCostAnalysis && paretoProcessCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsProcess.push(objShared);
  })
  

  let dataStructureDataGridProcess: any = {
    columns: columnsProcess,
    rows: rowsProcess,
  };

  let columnsOverhead: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretoOverheadCostAnalysis && paretoOverheadCostAnalysis?.unit})`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsOverhead: any = [];

  const dataGridDataGeneratorOverhead: any = paretoOverheadCostAnalysis && paretoOverheadCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsOverhead.push(objShared);
  })
  

  let dataStructureDataGridOverhead: any = {
    columns: columnsOverhead,
    rows: rowsOverhead,
  };

  let columnsBopDc: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1},
    {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
    },
    {
    field: "valueRs",
    headerName:`Value (${paretoBopDcCostAnalysis && paretoBopDcCostAnalysis?.unit})`,
    width: 90,
    flex: 1},
    {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rowsBopDc: any = [];

  const dataGridDataGeneratorBopDc: any = paretoBopDcCostAnalysis && paretoBopDcCostAnalysis?.pareto_data?.map((val: any,index:number) => {
    let objShared: any = {
      id:index+1,
      partNo:val.part_no,
      partName:val?.part_name,
      valueRs:(val?.param_value).toFixed(2),
      impact:(val?.impact).toFixed(2)
    };
    rowsBopDc.push(objShared);
  })
  

  let dataStructureDataGridBopDc: any = {
    columns: columnsBopDc,
    rows: rowsBopDc,
  };

  const handleTableOpen = (typeOfTableVal:any)=>{
    if(typeOfTableVal != "zoomOut"){
    setTableOpenParetoAnalysis(!tableOpenParetoAnalysis);
    setTypeOfTable(typeOfTableVal);
    }
    else{
      setTableOpenParetoAnalysis(!tableOpenParetoAnalysis);
      setTypeOfTable(undefined)
    }
  }

  const handleTableExceptMaterial = ()=>{
      setTableOpenParetoAnalysis(!tableOpenParetoAnalysis);
  }



  return (
    <>
      <AnalyseModal
        isOpen={analyseModal.isOpen}
        onClose={analyseModal.close}
        analyseWeight={analyseWeight}
        typeBopDc={typeBopDc}
      />
      <Dialog
        open={openMoreGraphPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleMoreGraphPopUpClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            minWidth: "80%",
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem',
              marginTop: '0.5rem'
            }}
          >
           {url?.includes("manufacturing") ? typeOfTable==undefined ? `${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.subsystem_name} - Subsystem Pareto Analysis` : typeOfTable =='totalCost' ? `${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.subsystem_name} - Total cost (${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.unit}) Pareto Analysis`: typeOfTable =='materialCost' ?`${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.subsystem_name} - Material cost (${paretoMaterialCostAnalysis && paretoMaterialCostAnalysis?.unit}) Pareto Analysis` : typeOfTable =='processCost'? `${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.subsystem_name} - Process cost (${paretoProcessCostAnalysis && paretoProcessCostAnalysis?.unit}) Pareto Analysis`:`${paretoTotalCostAnalysis && paretoTotalCostAnalysis?.subsystem_name} - Overhead cost (${paretoOverheadCostAnalysis && paretoOverheadCostAnalysis?.unit}) Pareto Analysis` : url?.includes("bopdc")? typeBopDc=='dc'? `${paretoBopDcCostAnalysis && paretoBopDcCostAnalysis?.subsystem_name} - Detailed Costing Subsystem Pareto Analysis` : `${paretoBopDcCostAnalysis && paretoBopDcCostAnalysis?.subsystem_name} - Bought-out Part Costing Subsystem Pareto Analysis` : url?.includes("kgCost") ? loaderkgCost ?`${paretokgCostAnalysis && paretokgCostAnalysis?.subsystem_name} -  Pareto Analysis`: "Pareto Analysis" : "Subsystem Pareto Analysis"}
          </Typography>
          {url?.includes("manufacturing") ? typeOfTable!=undefined && <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('zoomOut')} /> : url?.includes("kgCost") ? <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('kgCost')} /> : <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={handleTableExceptMaterial} />}
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleMoreGraphPopUpClose} />
        </Box>
        <DialogContent>
        {!tableOpenParetoAnalysis ? 
        <>
         {!fullscreen ?
         <>
          { url?.includes("manufacturing") ?
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {loaderTotalCost ?
              paretoTotalCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
              <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('totalCost')} />
              <ZoomOutMapIcon style={{cursor:"pointer"}} onClick={()=>{handleFullScreen('totalCost')}}/>
              <CostingAnalysisPareto fullscreen={fullscreen} dataIncoming={paretoTotalCostAnalysis} />
              </Box>
            ): <Skeleton sx={{ width: "50rem", height: "26rem" }} />}
            {loaderMaterialCost ?
            paretoMaterialCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('materialCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('materialCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen}
                dataIncoming={paretoMaterialCostAnalysis}
              />
              </Box>
            ) : <Skeleton sx={{ width: "50rem", height: "26rem" }} />}
            {loaderProcessCost ?
            paretoProcessCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('processCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('processCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen} dataIncoming={paretoProcessCostAnalysis} />
              </Box>
            ) : <Skeleton sx={{ width: "50rem", height: "26rem" }} />}
            {loaderOverheadCost?
            paretoOverheadCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('overheadCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('overheadCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen}
                dataIncoming={paretoOverheadCostAnalysis}
              />
              </Box>
            ):<Skeleton sx={{ width: "50rem", height: "26rem" }} />}
          </div>
          : 
          url?.includes("kgCost") ?
          loaderkgCost ? paretokgCostAnalysis && (
            <Box sx={{textAlign:'right'}}>
            <CostingAnalysisPareto fullscreen={true} dataIncoming={paretokgCostAnalysis} />
            </Box>
          )
        : <Skeleton sx={{ width: "100rem", height: "52rem" }} />

        :
          loaderBopDcCost ? paretoBopDcCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
              <CostingAnalysisPareto fullscreen={true} dataIncoming={paretoBopDcCostAnalysis} />
              </Box>
            )
          : <Skeleton sx={{ width: "100rem", height: "52rem" }} />
          }
        </>
         : <div>
          {typeofAnalysisData == "totalCost" ?
          <>
          {paretoTotalCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
              <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('totalCost')} />
              <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('totalCost')}}/>
              <CostingAnalysisPareto fullscreen={fullscreen} dataIncoming={paretoTotalCostAnalysis} />
              </Box>
            )}
          </>
          : typeofAnalysisData == "materialCost" ?
          <>
            {paretoMaterialCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('materialCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('materialCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen}
                dataIncoming={paretoMaterialCostAnalysis}
              />
              </Box>
            )}
          </>
          : typeofAnalysisData == "processCost" ?
          <>
            {paretoProcessCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('processCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('processCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen} dataIncoming={paretoProcessCostAnalysis} />
              </Box>
            )}
          </>
          : 
          <>
            {paretoOverheadCostAnalysis && (
              <Box sx={{textAlign:'right'}}>
               <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={()=>handleTableOpen('overheadCost')} />
               <ZoomOutMapIcon style={{cursor:"pointer"}}  onClick={()=>{handleFullScreen('overheadCost')}}/>
               <CostingAnalysisPareto fullscreen={fullscreen}
                dataIncoming={paretoOverheadCostAnalysis}
              />
              </Box>
            )}
          </>
            }
          </div>
        }
        </>
        :
        url?.includes("manufacturing") ?
        typeOfTable == "totalCost"?
        <div style={{width:'100%',height:'77vh'}}>
         {<DataGrid
            headerHeight={48}
            rowHeight={36}
              {...dataStructureDataGridTotal}
              disableSelectionOnClick={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar("");
                },
              }}
              getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
              sx={{
                height:'100%',
                fontSize: "1rem",
                "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                {
                  borderBottomColor: "primary.light",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
              density={"compact"}
              pageSize={28}
              rowsPerPageOptions={[28]}
            />}
          </div>
          : typeOfTable == "materialCost"
          ?
          <div style={{width:'100%',height:'77vh'}}>
          {<DataGrid
             headerHeight={48}
             rowHeight={36}
               {...dataStructureDataGridMaterial}
               disableSelectionOnClick={true}
               components={{
                 Toolbar: () => {
                   return CustomToolbar("");
                 },
               }}
               getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
               sx={{
                 height:'100%',
                 fontSize: "1rem",
                 "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                 {
                   borderBottomColor: "primary.light",
                 },
                 "&.MuiDataGrid-root .MuiDataGrid-cell": {
                   borderBottomColor: "primary.light",
                 },
               }}
               density={"compact"}
               pageSize={28}
               rowsPerPageOptions={[28]}
             />}
           </div>
           : typeOfTable == "processCost"
           ?
           <div style={{width:'100%',height:'77vh'}}>
           {<DataGrid
              headerHeight={48}
              rowHeight={36}
                {...dataStructureDataGridProcess}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar("");
                  },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                sx={{
                  height:'100%',
                  fontSize: "1rem",
                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                  {
                    borderBottomColor: "primary.light",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
                density={"compact"}
                pageSize={28}
                rowsPerPageOptions={[28]}
              />}
            </div> :
            <div style={{width:'100%',height:'77vh'}}>
            {<DataGrid
               headerHeight={48}
               rowHeight={36}
                 {...dataStructureDataGridOverhead}
                 disableSelectionOnClick={true}
                 components={{
                   Toolbar: () => {
                     return CustomToolbar("");
                   },
                 }}
                 getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                 sx={{
                   height:'100%',
                   fontSize: "1rem",
                   "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                   {
                     borderBottomColor: "primary.light",
                   },
                   "&.MuiDataGrid-root .MuiDataGrid-cell": {
                     borderBottomColor: "primary.light",
                   },
                 }}
                 density={"compact"}
                 pageSize={28}
                 rowsPerPageOptions={[28]}
               />}
             </div>
        : 
        typeOfTable == "kgCost" ?
        <div style={{width:'100%',height:'77vh'}}>
           {<DataGrid
              headerHeight={48}
              rowHeight={36}
                {...dataStructureDataGridPerKgCost}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar("");
                  },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                sx={{
                  height:'100%',
                  fontSize: "1rem",
                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                  {
                    borderBottomColor: "primary.light",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
                density={"compact"}
                pageSize={28}
              rowsPerPageOptions={[28]}
              />}
        </div> 
        :
        <div style={{width:'100%',height:'77vh'}}>
           {<DataGrid
              headerHeight={48}
              rowHeight={36}
                {...dataStructureDataGridBopDc}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar("");
                  },
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                sx={{
                  height:'100%',
                  fontSize: "1rem",
                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                  {
                    borderBottomColor: "primary.light",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
                density={"compact"}
                pageSize={28}
                rowsPerPageOptions={[28]}
              />}
        </div> 
        }
        </DialogContent>
      </Dialog>
      <Box>
        <Box
          sx={{
            padding: "0 0.5rem",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Box
            sx={{
              padding: "0 0.5rem",
              display: "flex",
              alignItems: "center",
              columnGap: "5px",
            }}
          >
            <Button variant="contained" size="small">
              Default Analysis
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: "3rem",
            display: "flex",
            alignItems: "center",
            marginLeft: "4.4rem",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
          >
            {/* @ts-ignore */}
            {AllProjectdata && AllProjectdata?.map((item: any, index: any) => {
                return Object.keys(item).map((key: any, index: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "5px",
                        alignItems: "center",
                        border: "1px solid",
                        borderColor: "primary.main",
                        borderRadius: "1.5rem",
                      }}
                    >
                      <>
                        {" "}
                        {filterDataIds ? (
                          <>
                            {(filterDataIds &&
                              filterDataIds?.length <= 2 &&
                              projectLength &&
                              projectLength <= 2) ||
                            (projectLength &&
                              projectLength == 1 &&
                              filterDataIds?.length > 2) ||
                            (projectLength &&
                              projectLength == 2 &&
                              filterDataIds?.length < 5) ? (
                              " "
                            ) : (
                              <CancelIcon
                                titleAccess="Delete"
                                sx={{
                                  "& :hover": {
                                    transform: "scale(1.1)",
                                    transition: "transform 0.5s ease",
                                  },
                                }}
                                onClick={() => deleteProject(key)}
                                style={{ cursor: "pointer" }}
                              />
                            )}{" "}
                            <Typography
                              style={{ fontSize: "1rem", paddingLeft: "5px" }}
                            >
                              {key}
                            </Typography>
                            <Box sx={{ display: "flex", columnGap: "5px" }}>
                              {ChipContainer(key)}
                            </Box>
                          </>
                        ) : (
                          <div>
                            <Skeleton />
                          </div>
                        )}
                      </>
                    </Box>
                  );
                });
              })}
          </Box>
        </Box>
      </Box>
      <div style={{ display: "grid", gridTemplateColumns: "4rem 1fr" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select to Change Analysis
                      <br /> ( Project / Products )
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "49vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select to Change Analysis ( Project / Products )
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box>
          <>
            {" "}
            {apiLoader ? (
              <Box>
                {analyseWeight.length > 0 &&
                  AnalysisVehicleName?.length > 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        height: {
                          sm: "79vh",
                          md: "79vh",
                          lg: "79vh",
                          xl: "79vh",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0 0.5rem",
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            width: "92%",
                            display: "inline-block",
                            fontSize: "1rem",
                            fontWeight: "600",
                          }}
                        >
                          {url?.includes("kgCost")
                            ? "Cost/Kg Comparison"
                            : url?.includes("manufacturing")
                            ? "Manufacturing Cost Comparison"
                            : "Parts Cost Distribution Comparison"}
                        </Typography>
                        <SyncIcon sx={{cursor:'pointer'}} onClick={()=>{
                           setApiLoader(false);
                           {
                             url?.includes("manufacturing")
                               ? API.get(
                                   `/customer/analysis/`,
                                   {
                                     vehicle_ids: topVault,
                                     sync:true,
                                     analysis_type: "cost_subsystemwise",
                                     colors: topVault?.split(',')?.map((item: any, index: any) => {
                                   
                                       if (topVaultColorMap && topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
                                         return topVaultColorMap[item];
                                       }
                                       else {
                                         return topVaultColor[index];
                                       }})?.toString()
                                   },
                                   0
                                 )
                                   .then((res: any) => {
                                     setApiLoader(true);
                                     setAnalyseWeight(res?.data);
                                   })
                                   .catch((err: any) => {
                                     setApiLoader(true);
                                     console.log(err, "error");
                                   })
                               : url?.includes("kgCost")
                               ? handleKgCost()
                               : handleBOPDC()
                           }
                      }} />
                        {url?.includes("bopdc") && (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeBopDc}
                            label="Select Cost Type"
                            onChange={handleChange}
                            size="small"
                            style={{ marginRight: "10px", minWidth: "220px" }}
                            variant="standard"
                          >
                            <MenuItem sx={{ fontSize: "1rem" }} value={"bop"}>
                              Bought-out Part Costing
                            </MenuItem>
                            <MenuItem sx={{ fontSize: "1rem" }} value={"dc"}>
                              Detailed Costing
                            </MenuItem>
                          </Select>
                        )}
                        {getPermissionCurrencyButton() && (
                          <Button
                            variant="contained"
                            className={styles.downloadBtn}
                            size="small"
                            endIcon={<ArrowDropDownIcon />}
                            style={{ fontSize: "12px", marginRight: "1rem" }}
                            onClick={() => currencyModal.open()}
                          >
                            {sessionStorage.getItem("currency_analysis")}
                          </Button>
                        )}
                        <img
                          src={TableIcon}
                          style={{
                            cursor: "pointer",
                            marginRight: "0.5rem",
                            width: "1.7rem",
                            height: "1.8rem",
                          }}
                          onClick={() => {
                            analyseModal.open();
                          }}
                        />
                        <ZoomInMapIcon
                          titleAccess="Minimize"
                          onClick={() =>
                            history.push(
                              `/analysis_costing/${projectId}/${topVault}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <ResponsiveContainer>
                        <BarChart
                          layout="vertical"
                          data={analyseWeight}
                          margin={{ left: 20, right: 50 }}
                          stackOffset="expand"
                        >
                          <Legend
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            content={renderCusomizedLegend}
                          />
                          <XAxis hide type="number" stroke="#000000">
                            <Label
                              style={{
                                textAnchor: "middle",
                                fontSize: "100%",
                                fontWeight: "600",
                              }}
                              angle={270}
                              dx={-22}
                              value={"Part ( Qty )"}
                            />
                          </XAxis>
                          <YAxis
                            type="category"
                            dataKey="subsytem_name"
                            interval={0}
                            dx={-5}
                            width={180}
                            fontSize="0.75rem"
                            stroke="#000000"
                          />
                          <Tooltip
                            cursor={false}
                            content={<CustomizedCostTooltip />}
                          />
                          {AnalysisVehicleName?.length > 0 &&
                            AnalysisVehicleName?.map(
                              (item: any, index: number) => {
                                return (
                                  <Bar
                                    dataKey={item}
                                    stackId="a"
                                    textAnchor="middle"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e: any) =>
                                      valBarRedirect(e, item)
                                    }
                                  >
                                    {analyseWeight?.map((entry: any) => {
                                      return (
                                        <Cell
                                          fill={
                                            entry?.top_vault_details?.filter(
                                              (itemInside: any) => {
                                                return (
                                                  itemInside?.top_vault_name ==
                                                  item
                                                );
                                              }
                                            )[0]?.color
                                          }
                                        />
                                      );
                                    })}
                                  </Bar>
                                );
                              }
                            )}
                        </BarChart>
                      </ResponsiveContainer>
                      {x && y && closeRedirectPopup && (
                        <Box
                          sx={{
                            left: x + 66 + "px",
                            position: "fixed",
                            top: y + 210 + "px",
                            zIndex: 1000,
                            width: "fit-content",
                            background: "#ffffc9",
                            borderRadius: "5px",
                            padding: "0.5rem",
                            boxShadow:
                              "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              left: 8,
                              width: 20,
                              height: 18,
                              bgcolor: "#ffffc9",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          }}
                        >
                          <Box sx={{ display: "flex", columnGap: "8px" }}>
                            <Button
                              color="primary"
                              size="small"
                              variant="contained"
                              onClick={() =>
                                valRedirect(
                                  url?.includes("cost") ? "cost" : "bom"
                                )
                              }
                            >
                              {url?.includes("cost")
                                ? "Go to Costing"
                                : "Go to BOM"}
                              <GotoIcon style={{width:'1rem',height:'1rem',marginLeft:'3px'}} fill="white" stroke="white" />
                            </Button>
                            {<Button
                              color="primary"
                              size="small"
                              variant="contained"
                              onClick={valOpenMoreGraphPopUpDialog}
                            >
                             Pareto Analysis
                            </Button>}
                            <IconButton
                              title="Close"
                              onClick={() => {
                                setCloseRedirectPopup(false);
                              }}
                            >
                              <CancelIcon
                                titleAccess="Close"
                                sx={{ fontSize: "1.5rem" }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
              </Box>
            ) : (
              <Box sx={{ margin: "0.5 rem", padding: "0 10rem" }}>
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
                <Skeleton sx={{ height: "3rem", width: "100%" }} />
              </Box>
            )}
          </>
        </Box>
      </div>
      <CurrencyChange
        isOpen={currencyModal.isOpen}
        onCloseModal={currencyModal.close}
        setCount={setCount}
      />
    </>
  );
};
export default AnalysisCostInnerPage;
