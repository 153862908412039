import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50rem",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};

interface ArchInfoModalProps {
  onCloseModal: any;
  isOpen: any;
}

const ArchInfoModal = (props: ArchInfoModalProps) => {
  const {
    projectId,
    projectname,
    productpage,
    groupId,
    abbr,
    groupName,
    pageNumber,
    topvoult,
    subSystem,
    collapestatus,
  } = useRouteParams<any>();
  const { onCloseModal, isOpen } = props
  const [DataList, setDataList] = React.useState<any>([])

  useEffect(() => {

    API.get(`/circuit_diagram/arch_subsystem/`, {
      project: projectId,
      abbreviation: abbr
    }
    ).then((res: any) => {
      setDataList(res.data)
    })
  }, [isOpen])

  console.log(DataList, "DataListDataListDataList")
  return (
    <div>

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Diagrams  Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 0.5rem 0.5rem' }}>
            <Table >
              <TableHead sx={{ backgroundColor: 'primary.light', position: 'sticky', top: 0, }}>
                <TableRow>
                  <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Product Name</TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Diagrams Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DataList && DataList?.map((item: any) => {
                  return (<>
                    <TableRow>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}> {item?.name}</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>  {item?.schematic}</TableCell>

                    </TableRow>
                  </>)
                })}

              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ArchInfoModal;