import Autocomplete from "@mui/material/Autocomplete";
import styles from "./ProjectmailselectionPage.module.scss";
import './ProjectmailLandingpage.scss';
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import sendIcon from "../../Assets/images/sendOutline.png";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ErrorModalFilesMail from "./ErrorModalFilesMail";
import MailAttchmentS3Upload from "./MailAttchmentS3Upload";
import { PreviewMailModal } from "./PreviewMailModal";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { MailSucessModal } from "./MailSuccessModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import editmail from "../../Assets/images/mailEdit.png"
import { AUTH } from "../../Redux/Services/auth.service";
import { editorConfig } from "./EditorConfig";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

// @ts-ignore
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// // @ts-ignore
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
interface ProjectmailLandingPagerProps {
  initialValue?: any;
}

const ProjectOthermailLandingPage: React.FC<ProjectmailLandingPagerProps> = ({
  initialValue,
}) => {

  const { emailtype, projectId, projectname, productIds, status, ccIds, date } =
    useParams<any>();
    useDocumentTitle( "Email" );
  const { height, width } = useWindowDimensions();
  const [content, setContent] = useState("");
  const [Selceteddate, setSelceteddate] = useState<any>(date);



  const [userList, setUserList] = useState<any>([]);

  const [usersCC, setUsersCC] = React.useState<any>([]);


  const [SubjectData, setSubjectData] = useState<any>("");
  const [value, setValue] = React.useState([]);


  const [SelectedIds, setSelectedIds] = useState<any>([]);
  const [EmailDatalist, setEmaildataList] = useState<any>();

  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const [AttchmentDatalist, setAttchmentDatalist] = useState<any>([])

  const [ImageUploadCounter, setImageUploadCounter] = useState<any>(0)
  const [attachLoader, setAttchLoader] = useState<any>(false)
  const [UserManualEamil, setUserManualEamil] = useState<any>()

  const [ContentLoader, setConentLoader] = useState<any>(false)
  const InputRef = useRef<any>();
  const editor = useRef(null);
  const history = useHistory<any>();
  const PreviewModal = useBaseModal()
  const successModal = useBaseModal()
  const [SendEmail, setSendEmail] = useState<any>(false)
  var ErrorModalFiles = useBaseModal();


  const [personName, setPersonName] = React.useState<string[]>([]);
  const [ProductDateList, setProductDateList] = useState<any>([]);

  useEffect(() => {
    API.get("/analytics/sent_mail_list/", { project: projectId })
      .then((res: any) => {
        // console.log(res.data, "fgurfurfiugriugfrf");
        setProductDateList(res?.data);
      })
      .catch((err: any) => {
        console.log("Server ERROR");
      });
  }, [projectId]);

  useEffect(() => {
    API.get("/analytics/user_access_list/", { project_id: projectId }).then(
      (res: any) => {
        setUserList(res?.data);
      }
    );
  }, [projectId]);




  // console.log(AttchmentDatalist, "AttchmentDatalistAttchmentDatalist")




  useEffect(() => {

    setAttchLoader(true)
    API.get("/analytics/store_attachment_details/", { project: projectId, mail_type: 6 }).then((res: any) => {
      setAttchmentDatalist(res?.data)
      setAttchLoader(false)
    }).catch((err: any) => {
      setAttchLoader(false)
      console.log("Server Error")
    })
  }, [projectId, ImageUploadCounter])



  var TouserListOption = () => {
    let ccemailId = usersCC?.map((itm: any) => itm.email);
    let tomailId: any = value?.map((itm: any) => itm.email);
    return (
      userList &&
      userList?.filter((item: any) => ![...ccemailId, ...tomailId]?.includes(item.email))?.map((item: any, index: any) => {
        return { ...item, id: index + 1 };
      })
    );
  };


  const SubjectHandler = (e: any) => {
    setSubjectData(e?.target?.value);
  };

  const userProfilePic = useSelector(userSelectors.selectAll);





  const handleDelete = (FileIds: any) => {
    setAttchLoader(true)
    API.delete("/analytics/store_attachment_details/", { id: FileIds, mail_type: 6 }).then((res: any) => {
      setImageUploadCounter((prev: any) => prev + 1)
    })

  };


  const handleSelectChange = (touser: any) => {
    var ToIds =
      touser &&
      touser?.map((item: any, index: any) => {
        return item?.id;
      });


    // @ts-ignore
    setValue([...touser]);
  };


  const PreviewHandler = () => {
    PreviewModal.open()
  }
  const handleSelectChangeCC = (ccuser: any) => {
    // @ts-ignore




    setUsersCC([...ccuser]);
  };

  const browreFilehandler = () => {
    return InputRef?.current?.click();
  };



  const PartNameHandler = (item: any) => {


    setUserManualEamil(item)
  }

  const NormalUser = (userData: any, domain: any) => {
    if (domain == "advancedstructures.in") {
      var newdata = { email: userData, full_name: "", user_color: "#a6f0b9" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })

      //  console.log(emailfilter,"emailfilteremailfilter")
      setUserList([newdata, ...emailfilter]);
    } else {
      var newdata = { email: userData, full_name: "", user_color: "#fbe5d6" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })
      setUserList([newdata, ...emailfilter]);
    }
  };



  const ManualEmailHandler = (e: any) => {

    if (e.key == "Enter") {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      var EmailValidation = regex.test(UserManualEamil)
      const domain = UserManualEamil?.split("@")?.pop();
      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com"

      if (EmailValidation) {
        if (
          !domindata


        ) {


          if (domain == "advancedstructures.in") {

            NormalUser(UserManualEamil, domain)

          } else {
            NormalUser(UserManualEamil, domain)
          }

        }
      }
    }

  }


  const PasteEmailHandler = (data: any) => {

    if (data) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      var EmailValidation = regex.test(data)
      const domain = data?.split("@")?.pop();

      var domindata = domain == "gmail.com" || domain == "yahoo.com" || domain == "hotmail.com"
      if (EmailValidation) {
        if (


          !domindata


        ) {


          if (domain == "advancedstructures.in") {

            NormalUser(data, domain)

          } else {
            NormalUser(data, domain)
          }

        }
      }
    }

  }






  const filehandlechnage = (e: any) => {
    var compreFileSize = 7564320;

    var datalength = AttchmentDatalist?.length + e.target.files?.length;
    if (datalength <= 4) {
      var fileSizeData = Object.values(e.target.files).map(
        (item: any, index: any) => {
          return item.size;
        }
      );

      var getFileSize = AttchmentDatalist && AttchmentDatalist?.map((item: any, index: any) => {
        { return item?.file_size }
      })

      var GetTotalFileSize = getFileSize?.reduce((accumulator: any, currentValue: any) => {
        return accumulator + currentValue;
      },
        0
      );
      const TotalFileSize = fileSizeData.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        },
        0
      );
      var finalSize = GetTotalFileSize + TotalFileSize;



      if (finalSize <= compreFileSize) {



        MailAttchmentS3Upload([...e?.target?.files], projectId, setImageUploadCounter, TotalFileSize, setAttchLoader, 6)

      }


      else {

        setErrorModalFileMsg("You can select upto 7.5Mb files.")
        ErrorModalFiles.open()
      }
    }

    else {

      setErrorModalFileMsg("You can select upto 4 files.")
      ErrorModalFiles.open()
    }

  }



  useEffect(() => {




  }, [])


  const FinalEmailSendHandler = () => {

    successModal.open()
    var ToIds = value && value?.map((item: any, index: any) => { return item?.email });
    var CCuser = usersCC && usersCC?.map((item: any, index: any) => { return item?.email });
    var attachedFile = AttchmentDatalist && AttchmentDatalist?.map((item: any, index: any) => { return item?.file_path })
    setSendEmail(true)
    API.post("/analytics/dashboard_mail/",
      {
        to_mail: ToIds,
        cc_mail: CCuser,
        data: content,
        project: projectId,
        subject: SubjectData,
        dash_data: [],
        attachment: attachedFile,
        mail_type: 6
      },
      {},
      0
    ).then((res: any) => {


      setSubjectData("")
      setAttchmentDatalist([])
      setValue([])
      setUsersCC([])
      setContent("")

      API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 6 }).then((res: any) => {
        setAttchmentDatalist([])
        setAttchLoader(false)
      }).catch((err: any) => {
        setAttchLoader(false)
        console.log("Server Error")
      })
      setSendEmail(false)
    }).catch((err: any) => {
      setSendEmail(true)
    })
  }
  const maillogdirection = () => {
    window.open(`/#/Project-mail-logs/${projectId}/${6}/${"Other"}`, "_blank")
  }

  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>
      <div>
        <p> Dear User, </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style={{ fontSize: "1rem", }}>
          <br />
          <span>Thanks & Regards</span>
          <br />
          <span>
            {userProfilePic && `${userProfilePic[0]?.first_name} ${userProfilePic[0]?.last_name} `}
          </span>
          <br />
          <span>
            {userProfilePic && `${AUTH.email}`}
          </span>
          <br />
          <span>
            {userProfilePic && `${AUTH.phone}`}
          </span>
          <br />
          <span>
            <a href="https://advancedstructures.in/">
              www.advancedstructures.in
            </a>
          </span>
          <br />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    setContent(htmlElement);
  }, [htmlElement]);



  return (
    <div>
      <input
        type="file"
        id="files"
        name="files"
        style={{ display: "none" }}
        onChange={(e: any) => filehandlechnage(e)}
        ref={InputRef}
        multiple
      />
      <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />

      {successModal.isOpen &&
        <MailSucessModal

          isOpen={successModal.isOpen}
          onCloseModal={successModal.close}
          SendEmail={SendEmail}
          parentsClosed={PreviewModal.close}
          Project={projectId}
          ProjectName={projectname}
          emailType={"Other"}
        />

      }
      <PreviewMailModal
        isOpen={PreviewModal.isOpen}
        onCloseModal={PreviewModal.close}
        Tousers={value && value}
        CCusers={usersCC}
        Subject={SubjectData}
        AttchmentData={AttchmentDatalist}
        emailContent={content}
        FinalEmailSendHandler={FinalEmailSendHandler}


      />
      <div className={styles.ProductContainer}>
        <div className={styles.ProductInnerContainer}>
          <IconButton onClick={() => history.push("/internallanding")}>
            <ArrowBackOutlinedIcon sx={{ cursor: "pointer", color: 'primary.main' }} />
          </IconButton>
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '1rem' }}>
            Other email ({projectname})
          </Typography>
        </div>
        <div style={{ marginRight: '1.5rem', display: 'flex', columnGap: '2rem', alignItems: 'center' }}>
        <Typography variant='body2' sx={{color: "#ef5350"}}>Max 50 recipients*
 </Typography>
          <Button variant="contained" size="small" onClick={() => maillogdirection()}>Email logs</Button>
          {/* {(value?.length > 0 && content?.length > 0 && SubjectData?.length > 0) && */}
          <img src={sendIcon} title="Preview Email" style={{
            cursor: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? "pointer" : "not-allowed", width: '1.7rem',
            opacity: value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 ? '1' : '0.4'
          }}
            onClick={() => value?.length > 0 && content?.length > 0 && SubjectData?.length > 0 && PreviewHandler()} />
          {/* } */}
        </div>
      </div>

      <div className={styles.parents_userContainer}>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>To :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            value={value}
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: "93vw", }}

            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            onChange={(event, newValue) => {
              handleSelectChange(newValue);
            }}

            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }
            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  //  @ts-ignore
                  sx={{ color: "#007fff", background: `${option?.user_color}`, fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            onKeyDown={(e: any) => ManualEmailHandler(e)}

            renderInput={(params) => (
              // @ts-ignore
              <TextField
                {...params}
                // @ts-ignore
                variant="standard"
                // @ts-ignore
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                  '.MuiSvgIcon-root': { color: 'primary.main' },
                  // @ts-ignore
                  // backgroundColor: `${params?.user_color}`,
                }}
              //   InputProps={{
              //     // background: `${params?.user_color}`
              //     // @ts-ignore
              //     style: "background-color: ${params?.user_color",

              //  }}
              // sx={{ background: `${params?.user_color}` }}
              />
            )}
          />
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Cc :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: "93vw", }}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            value={usersCC}
            onChange={(event, newValue) => {
              handleSelectChangeCC(newValue);

            }}

            onKeyDown={(e: any) => ManualEmailHandler(e)}
            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }
            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            // renderOption={(props, option) => (
            //   <Box
            //     component="li"
            //     sx={{
            //       // color:`${option?.user_color}`,

            //       marginBottom: "0.5rem",
            //       background: "white",
            //     }}
            //     {...props}
            //   >
            //     {option.email}
            //   </Box>
            // )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  //  @ts-ignore
                  size="small"
                  sx={{ color: "primary.main", background: `${option?.user_color}`, fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }

            // style={{ width: "93vw" }}
            renderInput={(params) => (
              // @ts-ignore
              <TextField
                {...params}
                // @ts-ignore
                variant="standard"
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                  '.MuiSvgIcon-root': { color: 'primary.main' },
                  // @ts-ignore
                  // backgroundColor: `${params?.user_color}`,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.userContainersubject}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Subject:</Typography>

        <TextField
          value={SubjectData}
          id="outlined-size-small"
          onChange={(e: any) => SubjectHandler(e)}
          // defaultValue="Small"
          size="small"
          variant="standard"
          InputProps={{
            style: { fontSize: '1rem' },
          }}
          sx={{
            width: '93vw',
            fontSize: '1rem',
            '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
            '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
          }}
        />
      </div>
      <div className={styles.attchmentcontainer}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right', }}>Attachments:</Typography>
        {/* User can attach file upto 25 mb and max 4 files in mail, Give Error if user exceeds the limit */}
        <div style={{ width: "95%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
          <Box sx={{ width: "100%", display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            {attachLoader ? <> <Skeleton animation="wave" sx={{ width: '98%' }} /> </> :
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                  {AttchmentDatalist && AttchmentDatalist?.length == 0 &&
                    <span style={{ color: "#007fff80" }}>
                      User can attach file upto 7.5 mb and max 4 files .
                    </span>
                  }
                  {<>
                    {AttchmentDatalist && AttchmentDatalist.map((item: any, index: any) => {
                      return (<>
                        <Chip
                          label={item?.file_name}
                          size="small"
                          variant="outlined"
                          onClick={() => window.open(item?.file_path, "_blank")}
                          onDelete={() => handleDelete(item?.id)}
                          sx={{ marginRight: "1rem", borderColor: 'primary.main', fontSize: '1rem' }}
                        />
                      </>)
                    })}
                  </>}
                </Box>
                <Box>
                  {/* {productIds?.length !== 1 && */}
                  <AttachFileIcon
                    titleAccess={"Attachments"}
                    onClick={() => browreFilehandler()}
                    sx={{ cursor: "pointer", color: 'primary.main', marginRight: '1.6rem' }} />
                  {/* } */}
                </Box>
              </Box>
            }
          </Box>

        </div>
      </div>

      <div style={{ padding: '0 1rem' }}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
          <img src={editmail} style={{ width: '1.4rem', height: 'auto' }} />
        </Box> */}
        {ContentLoader ? <><Skeleton variant="text" height={"10rem"} sx={{ fontSize: '1rem', heigth: "10rem !important" }} /><Skeleton variant="rounded" width={"96vw"} height={"30rem"} /></>
          :
          <Box>
            <JoditEditor
              // @ts-ignore
              config={editorConfig}
              className="OtherJoditEditor"
              ref={editor}
              value={content}
              onBlur={(newContent) => setContent(newContent)}
            // onChange={(newContent) => setContent(newContent)}
            />
          </Box>
        }
      </div>
    </div>
  );
};

export default ProjectOthermailLandingPage;
