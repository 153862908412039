// react
import React, { useState, useEffect, useMemo, useRef } from "react";
//
// import ImageAnnotate from 'app/Shared/ImageTag/ImageAnnotate';
import RViewerJS from "viewerjs-react";
import { useSelector } from "react-redux";

import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./ArchImageTaggingModal.module.scss";
import { RootState } from "../../Redux/Store/storeConfigurations";
import { imageRepositorySelectors } from "../../Redux/Reducers/imageRepository.reducer";
import { subsystemReducerSelectors } from "../../Redux/Reducers/subsytem.reducer";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArchImageAnnotate from "./ArchImageAnnotate";
import { API } from "../../api-services";


// interfaces and types
// lazy
// ----------------------------------------------------------------------------------
interface ImageTaggingModalProps {
  projectId: any;
  vaultId: any;
  tagModal: any;
  setTagModal: any;
  isLoaded: any;
  selectedsceImages?: any;
  topVaultIdMove?: any;
  selectedTopVaultIds?: any;
  topVaultId: any;
  userPermissionTopvaluewise: any;
}

const ArchImageTaggingModal: React.FC<ImageTaggingModalProps> = (props) => {
  const {
    projectId,
    vaultId,
    isLoaded,
    setTagModal,
    tagModal,
    selectedsceImages,
    topVaultIdMove,
    selectedTopVaultIds,

    topVaultId, userPermissionTopvaluewise
  } = useMemo(() => props, [props]);

  //states:
  const {
    projectname,

    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
    groupName,
  } = useRouteParams<any>();
  const [showPath, setShowPath] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>();
  const [topvaultId, setTopVaultId] = useState<number>();
  const [ancestorGet, setAncestorGet] = useState<any>();
  const [parentId, setParentId] = useState<number>();
  const [task, setTask] = useState<any>("");
  const [annotationState, setAnnotationState] = useState<any>();
  const [actioncount, setActiocnount] = useState<any>(0);
  const [dataTagging, setDataTagging] = useState<any>();
  const [MoveNumberImageIndex, setMoveNumberImageIndex] = useState<any>(0);
  const [imageGetFromParent, setImageGetFromParent] = useState<any>();

  const [AllImagesList, setAllImageList] = useState<any>([]);
  const [CopyData, setCopyData] = useState<any>()
  const [selectedsceImagesViewer, setSelectedsceImagesViewer] = useState<any>(
    []
  );
  const [partNumber, setPartNumber] = useState<any>("");
  const [Counter, setCounter] = useState<any>(-1);
  const imgRef = useRef<any>();
  const [Loader, setLoader] = useState<any>(false)
  // ----------------------------------------------------------------------------------

  const allimages: any = useSelector((state: RootState) => {
    return imageRepositorySelectors.selectAllImages(state, topVaultId);
  });
  const imageExpandClick = (topVault: any) => {
    let topVaultIdGet: any = [];
    let a: any = topVaultIdGet?.push(
      AllImagesList.slice(
        Counter + 1,
        AllImagesList.length
      )
    )[0];



    let b: any = topVaultIdGet?.push(
      AllImagesList.slice(0, Counter + 1)
    )[0];

    let topVaultIds: any = topVaultIdGet?.flat();


    setSelectedsceImagesViewer(topVaultIds);
  };

  // selectors
  const subsystemList = useSelector(subsystemReducerSelectors.selectAll);
  const permissionCheck =
    subsystemList.length != 0
      ? subsystemList[subsystemList.length - 1].allowed_actions
      : "CURD";
  const permission = false;

  var permissionData = userPermissionTopvaluewise && userPermissionTopvaluewise?.filter((item: any, index: any) => {
    return (item?.top_vault !== "all" && item?.action !== null) && item?.top_vault == AllImagesList[Counter]?.top_vault
  })[0]


  // console.log(permissionData && permissionData?.action?.includes("C"), "permissionDatapermissionData", userPermissionTopvaluewise)


  useEffect(() => {
    setLoader(true)
    API.get("/circuit_diagram/architecture_Tag_View/", {
      abbreviation: abbr,

      project: projectId,

      group_id: groupId,

      sequence: selectedsceImages?.sequence,

      categories: 3,
    }).then((res: any) => {
      const position = res?.data?.map((e: any) => e?.top_vault).indexOf(selectedsceImages?.top_vault);
      setCounter(position)
      setLoader(false)
      var filterdata = res.data.filter(
        (item: any) => item?.top_vault == selectedsceImages?.top_vault
      )[0];
      var filterdata2 = res.data.filter(
        (item: any) => item?.top_vault !== selectedsceImages?.top_vault
      );
      var data = [filterdata, ...filterdata2];

      setAllImageList(res.data);
    }).catch((err: any) => {
      setLoader(false)
    })
  }, [selectedsceImages]);

  console.log(Counter, AllImagesList, "AllImagesListAllImagesList")

  useEffect(() => {
    {
      if (AllImagesList.length > 0 && tagModal) {

        API.get(
          "/xcpep/vault_descendants_part_detail/",
          {
            id: AllImagesList[Counter] && AllImagesList[Counter]?.vault,
            media_id: AllImagesList[Counter] && AllImagesList[Counter]?.id,
          },
          0
        ).then((res: any) => {

          setPartNumber(res.data);
        }).catch((err: any) => {

        })
      }


    }

  }, [vaultId, tagModal, actioncount, AllImagesList]);
  console.log(AllImagesList[Counter], "AllImagesListAllImagesList");
  const copyPartName = (value: any) => {

    setCopyData(value);
    copyToClipboard(value);
  };
  const copyToClipboard = (text: any) => {
    var dummy = document.createElement("textArea");
    document.body.appendChild(dummy);
    //@ts-ignore
    dummy.value = text;
    //@ts-ignore
    dummy.select();
    //@ts-ignore
    dummy.setSelectionRange(0, 99999);
    //@ts-ignore
    navigator.clipboard.writeText(dummy.value);
  };
  //handlers:
  const bomPath = () => {
    ancestorGet && sessionStorage.setItem("node", JSON.stringify(ancestorGet));
    window.open(`/#/view/${projectId}/${topvaultId}/${productId}/null/true`);
  };
  const costPath = () => {
    ancestorGet && sessionStorage.setItem("node", JSON.stringify(ancestorGet));
    window.open(`/#/view/${projectId}/${topvaultId}/${productId}/null/costing`);
  };

  const displayImage = () => imgRef.current?.click?.();

  const CloseModalTagging: any = () => {
    setMoveNumberImageIndex(0);
    setImageGetFromParent(undefined);
    setTagModal(false);
  };

  const PreviousHandler = () => {
    setCounter((prev: any) => prev - 1);

    if (AllImagesList.length > 0 && tagModal) {
      setLoader(true)
      API.get(
        "/xcpep/vault_descendants_part_detail/",
        {
          id: AllImagesList[Counter - 1] && AllImagesList[Counter - 1]?.vault,
          media_id: AllImagesList[Counter - 1] && AllImagesList[Counter - 1]?.id,
        },
        0
      ).then((res: any) => {
        setLoader(false)
        setPartNumber(res.data);
      }).catch((err: any) => {
        setLoader(false)
      })
    }

  };
  const NextHandler = () => {
    setCounter((prev: any) => prev + 1);
    if (AllImagesList.length > 0 && tagModal) {
      setLoader(true)
      API.get(
        "/xcpep/vault_descendants_part_detail/",
        {
          id: AllImagesList[Counter + 1] && AllImagesList[Counter + 1]?.vault,
          media_id: AllImagesList[Counter + 1] && AllImagesList[Counter + 1]?.id,
        },
        0
      ).then((res: any) => {
        setPartNumber(res.data);
        setLoader(false)
      }).catch((err: any) => {
        setLoader(false)
      })
    }
  };


  console.log(Counter, "counter", isLoaded)

  // ----------------------------------------------------------------------------------
  // jsx
  return (
    <Modal
      open={tagModal}
      onClose={CloseModalTagging}
      //   onOpen={ () => setTagModal( true ) }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={styles.archModalCont}>
        <Typography id="modal-modal-description" sx={{ mb: 2 }}>
          <div>
            <div>
              <Box
                sx={
                  dataTagging && dataTagging.length > 0
                    ? {
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: "5",
                      top: "0.2rem",
                      marginBottom: "0.5rem",
                    }
                    : {
                      height: { lg: "2.75rem", xl: "2.6rem" },
                      marginBottom: "0.5rem",
                    }
                }>
                {dataTagging && dataTagging.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "1rem",
                      alignItems: "center",
                    }}>
                    <Box
                      sx={{
                        width: "3rem",
                        height: "2rem",
                        border: "2px dashed #007fff",
                      }}></Box>
                    <ArrowBackIcon />
                    <Typography>
                      Click on tag to navigate to Costing / BOM
                    </Typography>
                  </Box>
                )}
                <span style={{ float: "right" }}>
                  <Button
                    disabled={Counter > 0 ? false : true}
                    onClick={() => PreviousHandler()}>
                    <ChevronLeftIcon />
                    Prev
                  </Button>
                  <Button
                    disabled={
                      AllImagesList?.length - 1 > Counter ? false : true
                    }
                    onClick={() => NextHandler()}>
                    Next
                    <ChevronRightIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      return (
                        imageExpandClick(
                          AllImagesList[Counter]
                          &&
                          AllImagesList[Counter].top_vault
                        ),
                        displayImage()
                      );
                    }}
                    title="View Image">
                    <ZoomOutMapIcon />
                  </Button>
                </span>
              </Box>
              {

                Loader ? <Box sx={{ width: '60vw', height: { lg: '84vh', xl: '88vh' } }}>
                  <Skeleton sx={{ width: '100%', height: '100%' }} variant="rectangular" />
                </Box> : <>

                  {AllImagesList.length > 0 && (
                    <ArchImageAnnotate
                      s3Url={
                        isLoaded
                          ? AllImagesList[Counter] && AllImagesList[Counter]
                          : ""
                      }
                      setShowPath={setShowPath}
                      setProductId={setProductId}
                      setTopVaultId={setTopVaultId}
                      setParentId={setParentId}
                      projectId={projectId}
                      vaultId={
                        AllImagesList[Counter] && AllImagesList[Counter]?.object_id
                      }
                      task={task}
                      setTask={setTask}
                      annotationState={annotationState}
                      setAnnotationState={setAnnotationState}
                      abbreviation={abbr}
                      partNumber={partNumber}
                      setPartNumber={setPartNumber}
                      copyPartName={copyPartName}
                      bomPath={bomPath}
                      costPath={costPath}
                      setCopyData={setCopyData}
                      CopyData={CopyData}
                      allowed={permissionData}
                      setActiocnount={setActiocnount}
                      setDataTagging={setDataTagging}
                      setAncestorGet={setAncestorGet}
                    />
                  )}
                </>
              }
            </div>
            <div style={{ display: "none" }}>
              {/* @ts-ignore */}
              <RViewerJS>
                <div>
                  {selectedsceImagesViewer.length > 0 &&
                    selectedsceImagesViewer?.map((item: any) => {
                      return (
                        <img
                          src={item?.original}
                          alt={item && item?.product_name}
                          ref={imgRef}
                        />
                      );
                    })}
                </div>
              </RViewerJS>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
// ----------------------------------------------------------------------------------
export default React.memo(ArchImageTaggingModal);
