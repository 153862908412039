import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AccordionDetails, Pagination, Skeleton } from "@mui/material";
// import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import styles from "./SearchComponent.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import { isNull, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useBaseModal } from "./useBaseModal";
import SearchModal from "./SearchModal";
import Chip from "@mui/material/Chip";
// import EditIcon from '@mui/icons-material/Edit';
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Button } from "@mui/material";
// import { Divider } from "material-ui";
import Nosearch from "../../Assets/images/No_search.png";
import Popover from "@mui/material/Popover";
import { TableCell } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import PushPinIcon from "@mui/icons-material/PushPin";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UnpinImg from "../../Assets/images/Unpin.svg";
import Box from "@mui/system/Box";
import RViewerJS from "viewerjs-react";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

export interface IAppProps { }
export interface params {
  searchKey: any;
  type: any;
}
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    margin: "0.5rem 0",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    minHeight: "6rem",
  },
  customTableContainer: {
    overflowX: "initial",
  },
  ModuleImagesContainer: {
    display: "flex",
    gap: "1rem",
  },
  moduleImage: {
    width: "100%",
    height: "17vh",
    objectFit: 'cover'
  },
  moduleLink: {
    display: "flex",
    gap: "0.5rem",
    color: "#007fff !important",
  },
  imageSepration: {
    marginRight: "0.2rem",
    cursor: "pointer",
  },
  cellsColor: {
    backgroundColor: "#007fff !important",
    color: "white !important",
    padding: "0.5rem 1rem !important",
    whiteSpace: "nowrap",
  },
  searchdata: {
    color: "#007fff !important",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "transform 0.5s ease",
    },
  },
  searchtext: {
    color: "#007fff !important",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform 0.5s ease",
    },
  },
  searchHistory: {
    // boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    borderBottom: "1.1px solid #bbdefb !important",
    color: "#007fff",
    "&:hover": {
      transition: "transform 0.5s ease",
      // transform: "scale(1.02)",
      // boxShadow: "0 3px 5px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22)",
      color: "#007fff",
    },
  },
  searchHistory_pinned: {
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    borderBottom: "1.1px solid #bbdefb !important",
    borderLeft: "3px solid blue",
    backgroundColor: "aliceblue",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23)",
    color: "#007fff",
    zIndex: 0,
    "&:hover": {
      transition: "transform 0.5s ease",
      // transform: "scale(1.02)",
      borderLeft: "3px solid blue",
      backgroundColor: "aliceblue",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23)",
      color: "#007fff",
      // boxShadow: "0 3px 5px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22)",
    },
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    // height:"2rem"
  },
}));
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
export default function SearchComponent(props: IAppProps) {
  useDocumentTitle( "Search" );
  // @ts-ignore
  const classes = useStyles();
  const [handleChangeClickCount, setHandleChangeClickCount] =
    React.useState<number>(1);
  const [isOpen, setIsOPen] = React.useState<boolean>(false);
  const [allowModuleDetails, setAllowModuleDetails] = React.useState<any>({});
  const { searchKey, type } = useParams<params>();
  const history = useHistory();
  const [characters, setCharacters] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedValue, setSelectedValue] = React.useState<any>();
  // @ts-ignore
  const [page, setPages] = React.useState<any>(1);

  const [PageCount, setPageCount] = React.useState<any>();
  const [moduleData, setModuleData] = useState<any>();
  const [ModuleImages, setModuleImages] = useState<any>();
  const [Expend, setExpends] = useState<any | false>(false);
  const [SelectedProject, setSelectedProject] = useState<any>();
  const [ProjectInfo, setProjectInfo] = useState<any>();
  const [SearchLogs, setSearchLogs] = useState<any>();
  const [ProductData, setProductData] = useState<any>();
  var FreezeStatusdata: any = sessionStorage.getItem("FreeseStatus");
  const [ProductIds, setProductIds] = useState<any>();
  const [RecentSearchId, setRecentSearchId] = useState<any>();
  const [searchHistory, setSearchHistory] = useState<any>();
  const [searchHistoryLoader, setsearchHistoryLoader] = useState<any>(false);
  const [searchHistoryAllId, setsearchHistoryAllId] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const ModalOpen = useBaseModal();
  const [count, setCount] = useState<any>(0);
  const [FreezeStatus, setFreezeStatus] = useState<any>(false);
  const [loaderImages, setLoaderImages] = useState<any>(true);
  const [Hide, setHide] = useState<any>(false);

  const [partName, setPartName] = useState<any>()
  // **************************************** Accrodian API calls *********************************************************************//
  const Handelchanges =
    (searchId: any, model_type: any, part: any,current_model:any) =>
      (event: React.SyntheticEvent, expanded: boolean, isExpanded: boolean) => {

        // console.log(part, "partpartpart")
        setExpends(expanded ? searchId : false);
        setPartName(part)
        if (expanded === true) {
          // setExpends()
          API.get("customer/vault/", {
            id: searchId,
            data: true,
            model_type: model_type,
            current_model:current_model
          })
            .then((res: any) => {
              setProjectInfo(res?.data);
            })
            .catch((err: any) => {
              // ADMIN.toast.throwError(err, "Server Error");
            });
          API.get("customer/vault/", {
            id: searchId,
            module: true,
            model_type: model_type,
            current_model:current_model
          })
            .then((res: any) => {
              setModuleData(res?.data);
            })
            .catch((err: any) => {
              // ADMIN.toast.throwError(err, "Server Error");
            });
          setLoaderImages(false);
          API.get("customer/vault/", {
            id: searchId,
            images: true,
            model_type: model_type,
            current_model:current_model
          })
            .then((res: any) => {
              sessionStorage.getItem("PageCount");
              setModuleImages(res?.data);
              setLoaderImages(true);
            })
            .catch((err: any) => {
              // ADMIN.toast.throwError(err, "Server Error");
              setLoaderImages(true);
            });
        }
      };

  // console.log(partName, "partNamepartName")
  // **********************************************Pagination Handler**********************************************************************//
  const PaginationHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPages(value);

    if (VoultIdKey == null) {
      if (
        !isUndefined(valueData) &&
        valueData?.length > 0 &&
        !isNull(valueData) &&
        ProjectSearchKey == null &&
        recentKey == null
      ) {
        search(valueData, value, null, null, null);
      }
      if (
        !isUndefined(ProjectSearchKey) &&
        ProjectSearchKey?.length > 0 &&
        !isNull(ProjectSearchKey) &&
        recentKey == null &&
        valueData == null
      ) {
        search(null, value, ProjectIdsData, null, ProjectSearchKey);
      }
      if (
        !isUndefined(recentKey) &&
        !isNull(recentKey) &&
        ProjectSearchKey == null &&
        valueData == null
      ) {
        // @ts-ignore
        search(null, value, ProjectIdsData, recentKey, null);
      }
    } else if (VoultIdKey !== null) {
      setLoading(false);
      API.get("/customer/exact_search/", {
        get_search_result: VoultIdKey,
        page_no: value,
      })
        .then((res: any) => {
          setLoading(true);
          setSelectedProject(
            res?.data?.selected_vehicles.map(
              (item: any, index: any) => item?.id
            )
          );
          var topVaultId = res?.data?.selected_vehicles.map(
            (item: any, index: any) => item?.id
          );

          sessionStorage.setItem("ProjectIds", topVaultId);
          setCharacters(res?.data?.data);
          setPageCount(res?.data?.page_count);
        })
        .catch((err) => {
          console.log("Server Error");
          setLoading(true);
        });
      // /customer/exact_search/?get_search_result=66446&page_no=1
    }
  };
  const searchHistoryHandler = (
    recentSearchKeyId: any,
    recentSearchKey: any
  ) => {
    sessionStorage.setItem("recentKey", recentSearchKey);
    history.push("/search");
    sessionStorage.setItem("ProjectIds", recentSearchKeyId);

    sessionStorage.removeItem("SearchKey");
    sessionStorage.removeItem("projectKey");
    sessionStorage.removeItem("VoultId");
    sessionStorage.removeItem("ExactSearchKey");
    // @ts-ignore
    sessionStorage.setItem("FreeseStatus", false);
    setsearchHistoryAllId(recentSearchKeyId);
    handleClosePopup();
    setPages(1);
    //  setSearchLoader(recentSearchKeyId)
  };
  //************************************************All sessionStorage data get here ***************************************************************************** */
  // @ts-ignore
  var valueData: any = sessionStorage.getItem("SearchKey")?.split("[")?.shift();
  var allIds: any = sessionStorage.getItem("ProjectId");
  var ProjectSearchKey: any = sessionStorage.getItem("projectKey");
  const saveProjectId: any = allIds && allIds?.split(",")?.map(Number);
  var recentKey: any = sessionStorage.getItem("recentKey");
  var recentSearchId_Data: any = sessionStorage.getItem("recentSearchId");

  var ProjectIdsData: any = sessionStorage.getItem("ProjectIds");
  var ExactSearchKey: any = sessionStorage.getItem("ExactSearchKey");
  var VoultIdKey: any = sessionStorage.getItem("VoultId");
  var ModuleKey: any = sessionStorage.getItem("moduletype");

  // ***********************************************************************selected topvault*****************************************************************************
  useEffect(() => {
    setSearchHistory(null)
    if (SelectedProject?.length > 0 && SelectedProject?.length !== 0) {

      API.get(
        "customer/dashboard/",
        {
          fetch_vehicle_names:
            ProjectIdsData?.toString() || SelectedProject?.toString(),
          search_key:
            ProjectSearchKey || valueData || recentKey || ExactSearchKey,
        },
        0
      ).then((res: any) => {
        setProductData(res?.data);
      });
    } else if (searchHistoryAllId?.length > 0 && ProductData?.length > 0) {
      API.get("customer/dashboard/", {
        fetch_vehicle_names: searchHistoryAllId.toString(),
      }).then((res: any) => {
        setProductData(res?.data);
      });
    }
    if (SelectedProject?.length == 0) {
      setProductData([]);
    }
  }, [
    allIds,
    SelectedProject,
    valueData,
    ProjectSearchKey,
    recentKey,
    ProjectIdsData,
  ]);

  // **************************************************** search Handler***************************************************************//
  function search(
    criteria: any,
    pages: any,
    ids: any,
    recentId: any,
    projectKeySearch: any
  ) {
    setLoading(false);
    if (criteria == null && projectKeySearch == null) {
      // @ts-ignore

      API.get(
        `customer/search/`,
        {
          search: recentId,
          page: pages,
          vehicle_ids: ids.toString(),
          freeze_status: FreezeStatusdata,
        },
        0
      )
        .then((res: any) => {
          setLoading(true);
          var topVaultId = res?.data?.selected_vehicles.map(
            (item: any, index: any) => item?.id
          );

          sessionStorage.setItem("ProjectIds", topVaultId);
          setSelectedProject(topVaultId);
          setCharacters(res?.data?.data);
          setPageCount(res?.data?.page_count);

          // @ts-ignore
          sessionStorage.setItem("PageCount", 1);
        })
        .catch((err) => {
          setLoading(true);
          // ADMIN.toast.throwError(err, "Server Error");
        });
    } else if (recentId == null && projectKeySearch == null) {
      API.get(
        `customer/search/`,
        {
          search: criteria,
          page: pages,
        },
        0
      )
        .then((res: any) => {
          setLoading(true);
          setSelectedProject(
            res?.data?.selected_vehicles.map(
              (item: any, index: any) => item?.id
            )
          );
          var topVaultId = res?.data?.selected_vehicles.map(
            (item: any, index: any) => item?.id
          );

          sessionStorage.setItem("ProjectIds", topVaultId);
          setCharacters(res?.data?.data);
          setPageCount(res?.data?.page_count);

          // @ts-ignore
          sessionStorage.setItem("PageCount", 1);
        })
        .catch((err) => {
          setLoading(true);
          // ADMIN.toast.throwError(err, "Server Error");
        });
    } else if (recentId == null && criteria == null) {
      API.get(
        `customer/search/`,
        {
          search: projectKeySearch,
          page: pages,
          vehicle_ids: ids?.toString(),
          freeze_status: FreezeStatusdata,
        },
        0
      )
        .then((res: any) => {
          setLoading(true);
          setSelectedProject(
            res?.data?.selected_vehicles.map(
              (item: any, index: any) => item?.id
            )
          );
          var topVaultId = res?.data?.selected_vehicles.map(
            (item: any, index: any) => item?.id
          );

          sessionStorage.setItem("ProjectIds", topVaultId);
          setCharacters(res?.data?.data);
          setPageCount(res?.data?.page_count);
        })
        .catch((err) => {
          setLoading(true);
          // ADMIN.toast.throwError(err, "Server Error");
        });
    }
  }

  // *********************************************************************Single Filter Topvault in vehicle*****************************************************//

  const handleClick = (id: any) => {
    const data = [...SelectedProject];
    const filterProduct: any = data.filter(
      (item: any, index: any) => item !== id
    );
    setSelectedProject(filterProduct);
    setCount((prev: any) => prev + 1);
    sessionStorage.removeItem("SearchKey");
    sessionStorage.removeItem("recentKey");
    sessionStorage.removeItem("ExactSearchKey");
    sessionStorage.removeItem("VoultId");
    sessionStorage.setItem(
      "projectKey",
      ProductData && ProductData[0]["search_key"]
    );
    sessionStorage.setItem("ProjectIds", filterProduct);

    if (filterProduct?.length == 0) {
      setProductData([]);

      sessionStorage.removeItem("projectKey");
      setCharacters([]);
    }
  };

  const ProjectDeleteHandler = (item: any) => {
    var dataArray = [item, "freeze_status", "search_key"];

    const filteredProject =
      ProductData &&
      ProductData[0] &&
      Object.keys(ProductData[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = ProductData[0][key];

          return obj;
        }, {});

    const FilterTopVault =
      filteredProject &&
      Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);

    setSelectedProject(FilterTopVault);
    setCount((prev: any) => prev + 1);
    sessionStorage.removeItem("SearchKey");
    sessionStorage.removeItem("recentKey");
    sessionStorage.removeItem("ExactSearchKey");
    sessionStorage.removeItem("VoultId");
    sessionStorage.setItem(
      "projectKey",
      ProductData && ProductData[0]["search_key"]
    );
    sessionStorage.setItem("ProjectIds", FilterTopVault);
    if (FilterTopVault?.length == 0) {
      setProductData([]);
      sessionStorage.removeItem("projectKey");
      setCharacters([]);
    }
  };

  const ProjectInfoData = (keys: any) => {
    const datavalue =
      keys == "bom_parameter" && ProjectInfo && ProjectInfo[keys];
    const BomArry: any = [];
    if (!isUndefined(ProjectInfo) || !isNull(ProjectInfo)) {
      Object.entries(datavalue).forEach(([key, value]: any) => {
        BomArry?.push(
          <TableRow>
            <TableCell className={styles.tableBCell}>{key}</TableCell>
            <TableCell className={styles.tableBCell}>{value}</TableCell>
          </TableRow>
          // <div>
          //   <span>{key}</span>&nbsp;:&nbsp;{/* @ts-ignore */}
          //   <span>{value}</span>,
          // </div>
        );
      });
    }
    return BomArry;
  };

  const ProjectInfoFeaturesData = (keys: any) => {
    const datavalue =
      keys == "features_parameter" && ProjectInfo && ProjectInfo[keys];
    const BomArry: any = [];
    if (!isUndefined(ProjectInfo) || !isNull(ProjectInfo)) {
      Object.entries(datavalue).forEach(([key, value]: any) => {
        BomArry?.push(
          <TableRow>
            <TableCell className={styles.tableBCell}>{key}</TableCell>
            <TableCell className={styles.tableBCell}>{value}</TableCell>
          </TableRow>
        );
      });
    }
    return BomArry;
  };

  const ProjectInfoCostData = (keys: any) => {
    const datavalue =
      keys == "cost_parameter" && ProjectInfo && ProjectInfo[keys];
    const BomArry: any = [];
    if (!isUndefined(ProjectInfo) || !isNull(ProjectInfo)) {
      Object.entries(datavalue).forEach(([key, value]: any) => {
        BomArry?.push(
          <TableRow>
            <TableCell className={styles.tableBCell}>{key}</TableCell>
            <TableCell className={styles.tableBCell}>{value}</TableCell>
          </TableRow>
        );
      });
    }
    return BomArry;
  };

  const IdeaParameterInfo = (keys: any) => {
    const datavalue =
      keys == "idea_parameter" && ProjectInfo && ProjectInfo[keys];
    const BomArry: any = [];
    if (!isUndefined(ProjectInfo) || !isNull(ProjectInfo)) {
      var data =
        datavalue &&
        datavalue?.map((item: any, index: any) => {
          return (
            <TableRow>
              <TableCell sx={{ verticalAlign: 'top' }} className={styles.tableBCell}>
                {"Idea Id"} : {item?.idea_id}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} className={styles.tableBCell}>
                {"Saving Value"} : {item?.saving_value}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} className={styles.tableBCell}>
                {Object.values(item?.text_parameter)}
              </TableCell>
            </TableRow>
          );
        });
    }
    return data;
  };
  const handlePopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setsearchHistoryLoader(true);
    API.get("/customer/search_logs/", { all_logs: true })
      .then((res: any) => {
        setSearchHistory(res.data);
        setsearchHistoryLoader(false);
      })
      .catch((err: any) => {
        setsearchHistoryLoader(false);
        // ADMIN.toast.throwError(err, "Server Error");
      });
  };
  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  const openPopUp = Boolean(anchorEl);
  const id = openPopUp ? "simple-popover" : undefined;
  const CounterValue = sessionStorage.getItem("Counter");

  // console.log("searchData", VoultIdKey);

  // ****************************************all search function  call***************************************************************************************//
  //@ts-ignore
  useEffect(() => {
    if (page == 1) {
      if (VoultIdKey == null) {
        if (
          !isUndefined(valueData) &&
          valueData?.length > 0 &&
          !isNull(valueData) &&
          ProjectSearchKey == null &&
          recentKey == null
        ) {
          search(valueData, 1, null, null, null);
        }
        if (
          !isUndefined(ProjectSearchKey) &&
          ProjectSearchKey?.length > 0 &&
          !isNull(ProjectSearchKey) &&
          recentKey == null &&
          valueData == null
        ) {
          search(null, 1, ProjectIdsData, null, ProjectSearchKey);
        }
        if (
          !isUndefined(recentKey) &&
          !isNull(recentKey) &&
          ProjectSearchKey == null &&
          valueData == null
        ) {
          // @ts-ignore
          search(null, 1, ProjectIdsData, recentKey, null);
        }
      } else if (VoultIdKey !== null) {
        setLoading(false);
        API.get("/customer/exact_search/", {
          get_search_result: VoultIdKey,
          page_no: 1,
          model_ob: ModuleKey,
        })
          .then((res: any) => {
            setLoading(true);
            setSelectedProject(
              res?.data?.selected_vehicles.map(
                (item: any, index: any) => item?.id
              )
            );
            var topVaultId = res?.data?.selected_vehicles.map(
              (item: any, index: any) => item?.id
            );

            sessionStorage.setItem("ProjectIds", topVaultId);
            setCharacters(res?.data?.data);
            setPageCount(res?.data?.page_count);
          })
          .catch((err) => {
            console.log("Server Error");
            setLoading(true);
          });
       
      }
    }
  }, [
    valueData,
    page,
    allIds,
    recentKey,
    count,
    ProjectSearchKey,
    FreezeStatusdata,
    VoultIdKey,
    sessionStorage.getItem("Counter"),
  ]);
  const FreezeHandler = (FreezeKey: any) => {
    setFreezeStatus(!FreezeStatus);
    if (ProjectSearchKey == null) {
      // @ts-ignore
      sessionStorage.setItem("FreeseStatus", true);
      sessionStorage.setItem("projectKey", FreezeKey);
      sessionStorage.removeItem("ExactSearchKey");
      // if(FreezeStatus==true){
      sessionStorage.removeItem("SearchKey");
     
    } else {
      // @ts-ignore
      sessionStorage.setItem("FreeseStatus", true);
      sessionStorage.setItem("projectKey", ProjectSearchKey);
      sessionStorage.removeItem("ExactSearchKey");
   
    }
  };

  const unFreeZeFreezeHandler = (FreezeKey: any) => {
    setFreezeStatus(!FreezeStatus);
    if (ProjectSearchKey == null) {
      // @ts-ignore
      sessionStorage.setItem("FreeseStatus", false);
      sessionStorage.setItem("projectKey", FreezeKey);
      sessionStorage.removeItem("ExactSearchKey");
     
      sessionStorage.removeItem("SearchKey");
     
    } else {
      // @ts-ignore
      sessionStorage.setItem("FreeseStatus", false);
      sessionStorage.setItem("projectKey", ProjectSearchKey);
      sessionStorage.removeItem("ExactSearchKey");
      // sessionStorage.removeItem("VoultId")
      // sessionStorage.removeItem("SearchKey");
    }
  };

  useEffect(() => {
    if (CounterValue) {
      setPages(1);
    }
  }, [CounterValue]);
  const PinHistoryHandler = (Id: any, pinLog: any) => {
    API.put(`/customer/search_logs/${Id}/`, { pin_log: pinLog }).then(
      (res: any) => {
        API.get("/customer/search_logs/", { all_logs: true })
          .then((res: any) => {
            setSearchHistory(res.data);
            setsearchHistoryLoader(false);
          })
          .catch((err: any) => {
            setsearchHistoryLoader(false);
            // ADMIN.toast.throwError(err, "Server Error");
          });
      }
    );
  };
  const SingleDeleteHistoryHandler = (Id: any) => {
    API.put(`/customer/search_logs/${Id}/`, { clear_log: true }).then(
      (res: any) => {
        API.get("/customer/search_logs/", { all_logs: true })
          .then((res: any) => {
            setSearchHistory(res.data);
            setsearchHistoryLoader(false);
          })
          .catch((err: any) => {
            setsearchHistoryLoader(false);
            // ADMIN.toast.throwError(err, "Server Error");
          });
      }
    );
  };

  const HideSelectionHanlder = () => {
    setHide(!Hide);
  };

  // console.log(Hide, "hidededede");
  const DeleteAllHistoryHandler = () => {
    API.post(`/customer/search_logs/`, { clear_all_logs: true }).then(
      (res: any) => {
        API.get("/customer/search_logs/", { all_logs: true })
          .then((res: any) => {
            setSearchHistory(res.data);
            setsearchHistoryLoader(false);
          })
          .catch((err: any) => {
            setsearchHistoryLoader(false);
            // ADMIN.toast.throwError(err, "Server Error");
          });
      }
    );
  };
  const MediaRouteHandler = () => {
    if (moduleData?.abbreviation == "product") {
      window.open(
        `/#/media-repository/image/${moduleData?.project_id}/0/1/0/${moduleData?.top_vault_id}`,
        "_blank"
      );
    } else {
      window.open(
        `/#/media-repository/image/${moduleData?.project_id}/${moduleData?.abbreviation}/2/0/${moduleData?.top_vault_id}`,
        "_blank"
      );
    }
  };

  const FeatureRouteHandler = () => {
    sessionStorage.setItem("feature","Feature");


     if(moduleData?.model_type== "featuredetails"){
      API.get('/analytics/fetch_features/',{sub_system:moduleData?.abbreviation == "product"?moduleData?.top_vault_id:moduleData?.vault_info_id,top_vault:moduleData?.abbreviation == "product"?true:false,new_ui:true,category:moduleData?.feature_category}).then((res:any)=>{
        API.get("/customer/feature_view_data/",{project:moduleData?.project_id,analysis_type:19,subsystem_list:true}).then((res1:any)=>{
         var datafilter:any=Object.values(res?.data).flat(1).filter((item:any,index:any)=> {return item?.feature_name==moduleData?.feature_name})
   
         var filterIds:any=res1?.data?.sub_system?.filter((item:any,index:any)=>{return item?.abbreviation==moduleData?.abbreviation})
   
     
   
         if (moduleData?.abbreviation == "product") {
         
           // @ts-ignore
              window.open(
                `/#/featurelandingPage/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.top_vault_id}/${moduleData?.abbreviation}/${moduleData?.feature_category}/${ filterIds && filterIds[0]?.id }/${datafilter && datafilter[0]?.id}/${moduleData?.feature_name}`
              );
            } else {
              window.open(
                `/#/featurelandingPage/${moduleData?.project_id}/${moduleData?.top_vault_id}/${filterIds && filterIds[0]?.vault_id}/${moduleData?.abbreviation}/${moduleData?.feature_category}/${filterIds && filterIds[0]?.id}/${datafilter && datafilter[0]?.id}/${moduleData?.feature_name}`
              );
            }
         
        })
   
   
   
         
       })
     }
     if(moduleData?.model_type== "vaultinfo"){
      API.get('/analytics/fetch_features/',{sub_system:moduleData?.abbreviation == "product"?moduleData?.top_vault_id:moduleData?.vault_info_id,top_vault:moduleData?.abbreviation == "product"?true:false,new_ui:true,category:0,get_first_feature: true}).then((res:any)=>{
        API.get("/customer/feature_view_data/",{project:moduleData?.project_id,analysis_type:19,subsystem_list:true}).then((res1:any)=>{
         var datafilter:any=res?.data?.feature_name;
         var FeatureId:any=res?.data?.id;
    


         var filterIds:any=res1?.data?.sub_system?.filter((item:any,index:any)=>{return item?.abbreviation==moduleData?.abbreviation})
   
     
   
         if (moduleData?.abbreviation == "product") {
         
           // @ts-ignore
              window.open(
                `/#/featurelandingPage/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.top_vault_id}/${moduleData?.abbreviation}/${0}/${ filterIds && filterIds[0]?.id }/${datafilter && FeatureId}/${datafilter}`
              );
            } else {
              window.open(
                `/#/featurelandingPage/${moduleData?.project_id}/${moduleData?.top_vault_id}/${filterIds && filterIds[0]?.vault_id}/${moduleData?.abbreviation}/${0}/${filterIds && filterIds[0]?.id}/${datafilter && FeatureId}/${datafilter}`
              );
            }
         
        })
   
   
   
         
       })
     }
   








   
  };
  const ArchRouteHandler = () => {
    if (moduleData?.abbreviation == "product") {
      window.open(
        `/#/architecture/${moduleData?.project_id}/0/0/${moduleData?.top_vault_id}`,
        "_blank"
      );
    } else {
      window.open(
        `/#/architecture/${moduleData?.project_id}/${moduleData?.abbreviation}/0/${moduleData?.top_vault_id}`,
        "_blank"
      );
    }
  };

  const BomRouteHandler = () => {


    //  API.get("/customer/costing_module/",{get_children_estimation:true,vault_id:moduleData?.vault_info_id}).then((res:any)=>{
    // var filterData=res.data.filter((item:any,index:any)=>item.part_no==partName)




    var dataNodes = moduleData && moduleData?.ancestor;
    sessionStorage.setItem("node", JSON.stringify(dataNodes));


    moduleData?.model_type !== "topvault"
      ? window.open(
        `/#/view/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.vault_info_id}/${moduleData?.abbreviation}/true`,
        "_blank"
      )
      : window.open(
        `/#/view/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.vault_info_id}/${moduleData?.abbreviation}/true`,
        "_blank"
      );



  };
  const CostingRouteHandler = () => {



    var dataNodes = moduleData && moduleData?.ancestor;
    sessionStorage.setItem("node", JSON.stringify(dataNodes));

    moduleData?.model_type !== "topvault"
      ? window.open(
        `/#/view/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.vault_info_id}/${moduleData?.abbreviation}/costing`,
        "_blank"
      )
      : window.open(
        `/#/view/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.vault_info_id}/${moduleData?.abbreviation}/costing`,
        "_blank"
      );
  };

  const ChipContainer = (value: any) => {
    return (
      ProductData &&
      ProductData[0][value].map((item: any, index: any) => {
        return (
          <>
            {VoultIdKey == null ? (
              <>
                {FreezeStatusdata == "true" ? (
                  <>
                    <Chip
                      label={item.name}
                      sx={{
                        fontSize: "0.87rem",
                        height: { xs: "30px", lg: "20px", xl: "30px" },
                        color: "primary.light !important",
                        backgroundColor: "#007fff14 !important",
                        // '& :hover':{
                        //   transform:'scale(1.1)',
                        //   transition:'transform 0.5s ease'
                        // }
                        "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChip-deleteIconColorPrimary":
                        {
                          fontSize: "1rem !important",
                          color: "primary.main !important",
                        },
                      }}
                      disabled={loading == true ? false : true}
                    />
                  </>
                ) : (
                  <Chip
                    icon={
                      <CancelIcon
                        onClick={() => handleClick(item?.id)}
                        color="primary"
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "primary.main !important",
                          opacity: 0.8,
                          "& :hover": {
                            transform: "scale(1.1)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                        titleAccess="Delete"
                      />
                    }
                    // onDelete={() => handleClick(item?.id)}
                    label={item.name}
                    sx={{
                      fontSize: "0.87rem",
                      height: { xs: "30px", lg: "20px", xl: "30px" },
                      margin: "2px 0",
                      color: "primary.light !important",
                      backgroundColor: "#007fff14 !important",
                      "& :hover": {
                        opacity: 1,
                        // transform:'scale(1.1)',
                        // transition:'transform 0.5s ease'
                      },
                    }}
                    disabled={loading == true ? false : true}
                  />
                )}
              </>
            ) : (
              <Chip
                label={item.name}
                sx={{
                  fontSize: "0.87rem",
                  height: { xs: "30px", lg: "20px", xl: "30px" },
                  color: "primary.main",
                  backgroundColor: "#007fff14 !important",
                  ".MuiChip-root .MuiChip-icon .MuiSvgIcon-root": {
                    color: "primary.main !important",
                  },
                  ".MuiChip-root": {
                    backgroundColor: "#007fff14 !important",
                  },
                }}
                disabled={loading == true ? false : true}
              />
            )}
          </>
        );
      })
    );
  };

  return (
    <div>
      {/* @ts-ignore */}
      {/* <Header setHandleChangeClickCount={setHandleChangeClickCount} /> */}
      <SearchModal
        isOpen={ModalOpen.isOpen}
        onCloseModal={ModalOpen.close}
        type="editModal"
        ProductData={ProductIds}
      />
      <div
      // className={styles.searchCont}
      >
        <div>
          <div className={styles.searchresultHeader}>
            <div className={styles.history_pagination}>
              <div>
                <Button
                  variant="contained"
                  className={styles.searchHistoryBtn}
                  onClick={handlePopup}
                  size="small"
                >
                  Search History
                </Button>
                <Popover
                  id={id}
                  open={openPopUp}
                  anchorEl={anchorEl}
                  onClose={handleClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: { marginTop: "0.5rem", width: "50rem" },
                  }}
                >
                  <div>
                    <Table classes={{ root: classes.customTableContainer }}>
                      <TableHead style={{ position: "sticky", top: 0, borderRadius: '5px' }}>
                        <TableRow>
                          <TableCell
                            style={{ width: "8rem" }}
                            className={classes.cellsColor}
                          >
                            <Typography>Search Text </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              padding: "0.5rem 0 !important",
                            }}
                            className={classes.cellsColor}
                          >
                            <Typography> Search Vehicle</Typography>
                          </TableCell>
                          <TableCell className={classes.cellsColor}></TableCell>
                          <TableCell
                            style={{ textAlign: "right" }}
                            className={classes.cellsColor}
                          >
                            {/* <CancelIcon
                            onClick={handleClosePopup}
                            fontSize="small"
                            titleAccess="Close"
                            className={styles.clearBtnsearchHistory}
                          /> */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={4}>
                            <div
                              style={{ height: "27rem", overflowY: "scroll" }}
                              adl-scrollbar="true"
                              adl-scrollbar-width="0.6"
                            >
                              <Table>
                                <TableBody>
                                  {searchHistory &&
                                    searchHistory.map(
                                      (Item: any, index: any) => {
                                        return (
                                          <>
                                            <TableRow
                                              className={
                                                Item?.is_pinned
                                                  ? classes.searchHistory_pinned
                                                  : classes.searchHistory
                                              }
                                            // style={{ cursor: "pointer" }}
                                            >
                                              <TableCell
                                                style={{ width: "7rem" }}
                                                className={classes.searchtext}
                                              >
                                                {Item?.search_key}
                                              </TableCell>
                                              <TableCell
                                                className={classes.searchdata}
                                                onClick={() => {
                                                  searchHistoryHandler(
                                                    Item?.search_filter,
                                                    Item?.search_key
                                                  );
                                                }}
                                              >
                                                {Item?.top_vault_name}
                                              </TableCell>
                                              {Item?.is_pinned ? (
                                                <TableCell
                                                  onClick={() =>
                                                    PinHistoryHandler(
                                                      Item?.id,
                                                      false
                                                    )
                                                  }
                                                >
                                                  <img
                                                    title="Unpin"
                                                    src={UnpinImg}
                                                    alt="unpinImg"
                                                    className={styles.pinIcon}
                                                    style={{
                                                      width: "1.2rem",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </TableCell>
                                              ) : (
                                                <TableCell>
                                                  <PushPinIcon
                                                    titleAccess="Save search for future use"
                                                    color="primary"
                                                    className={styles.pinIcon}
                                                    onClick={() =>
                                                      PinHistoryHandler(
                                                        Item?.id,
                                                        true
                                                      )
                                                    }
                                                    sx={{ cursor: "pointer" }}
                                                  />
                                                </TableCell>
                                              )}
                                              <TableCell>
                                                <DeleteForeverIcon
                                                  sx={{ cursor: "pointer" }}
                                                  titleAccess="Delete"
                                                  className={styles.pinIcon}
                                                  color="primary"
                                                  onClick={() =>
                                                    SingleDeleteHistoryHandler(
                                                      Item?.id
                                                    )
                                                  }
                                                />
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {searchHistory && searchHistory.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => DeleteAllHistoryHandler()}
                        className={styles.clearbtn}
                      >
                        Clear All
                      </Button>
                    )}
                    {/* <SearchHistory searchHistorydata={searchHistory}/>  */}
                  </div>
                </Popover>
              </div>
              <Pagination
                count={PageCount}
                page={page}
                color="primary"
                sx={{
                  fontSize: "1rem",
                  ".MuiButtonBase-root .MuiPaginationItem-root:hover": {
                    transform: "Scale(1.1) !important",
                    transition: "transform 0.5s ease !important",
                  },
                }}
                onChange={PaginationHandler}
              />
            </div>
          </div>
          <Box
            className={
              Hide == false ? styles.searchVehicle : styles.hideSearchVehicle
            }
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box className={styles.searchFilter}>
              {ProductData?.map((itemData: any, index: any) => {
                return Object.keys(itemData).map((item: any, index: any) => {
                  return (
                    <>
                      {VoultIdKey == null && (
                        <>
                          {!index && (
                            <Chip
                              sx={{
                                marginLeft: "0.3rem",
                                marginBottom: "0.3rem",
                                fontWeight: "600",
                                borderRadius: "5px",
                                color: "white",
                                borderColor: "primary.main",
                                fontSize: "1rem",
                                height: "2.5rem",
                                "&:hover": {
                                  transform: "scale(1.1)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              label={
                                FreezeStatusdata == "true" ||
                                  FreezeStatusdata == true
                                  ? "Click to Unfreeze Selection"
                                  : " Click to Freeze Selection"
                              }
                              color={
                                FreezeStatusdata == "true" ||
                                  FreezeStatusdata == true
                                  ? "success"
                                  : "primary"
                              }
                              onClick={() =>
                                FreezeStatusdata == "true" ||
                                  FreezeStatusdata == true
                                  ? unFreeZeFreezeHandler(itemData?.search_key)
                                  : FreezeHandler(itemData?.search_key)
                              }
                            />
                          )}
                        </>
                      )}
                      {item !== "freeze_status" && item !== "search_key" && (
                        <>
                          <Box className={styles.chipContainer}>
                            <Typography className={styles.searchGroupClear}>
                              {VoultIdKey == null && (
                                <>
                                  {FreezeStatusdata == "false" && (
                                    <Button
                                      disabled={loading == true ? false : true}
                                      color="primary"
                                      className={styles.btn}
                                      sx={{
                                        width: "fit-content",
                                        padding: "0",
                                        minWidth: "2rem",
                                      }}
                                    >
                                      <CancelIcon
                                        onClick={() =>
                                          ProjectDeleteHandler(item)
                                        }
                                        className={styles.clearIcon}
                                        titleAccess="Delete"
                                      />
                                    </Button>
                                  )}
                                </>
                              )}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1rem",
                                margin: "0 0.5rem",
                                fontWeight: "500",
                                lineHeight: "1.2",
                              }}
                            >
                              {item}
                            </Typography>
                            <span style={{ display: "flex", columnGap: "5px" }}>
                              {ChipContainer(item)}
                            </span>
                          </Box>
                        </>
                      )}
                    </>
                  );
                });
              })}
            </Box>
          </Box>
        </div>
        <Box className={styles.searchData}>
          <Typography sx={{ color: "primary.main" }}>Search Result</Typography>
          <Box>
            <Box
              sx={{
                backgroundColor: "primary.light",
                color: "primary.main",
                alignItems: "center",
                display: "flex",
                padding: "0.2rem 0.5rem",
                borderRadius: "0.1rem",
              }}
              onClick={() => HideSelectionHanlder()}
            >
              <Typography style={{ cursor: "pointer", marginRight: "0.3rem" }}>
                {Hide == false ? "Hide Selection " : "Show Selection"}{" "}
              </Typography>{" "}
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              >
                {Hide == false ? (
                  <KeyboardArrowUpIcon
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  />
                )}
              </Box>{" "}
            </Box>
          </Box>
        </Box>
        <div
          className={Hide == false ? styles.searchCont : styles.searchContfull}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <div className={styles.accContainer}>
            {loading ? (
              characters && characters.length > 0 ? (
                characters?.map((searchValue: any, index: any) => {
                  return (
                    <>
                      <Accordion
                        // @ts-ignore
                        onChange={Handelchanges(
                          searchValue?.id,
                          searchValue?.model_type, searchValue?.part_no,
                          searchValue?.current_model
                        )}
                        expanded={Expend === searchValue?.id}
                        className={styles.accordContainer}
                      >
                        <AccordionSummary
                          sx={{
                            minHeight: { xs: "26px", lg: "26px", xl: "36px" },
                          }}
                          aria-controls="panel1d-content"
                          id={index + 1}
                        >
                          <div className={styles.searchModTitle}>
                            <Typography style={{ fontSize: "1rem" }}>
                             {searchValue?.current_model == searchValue?.model_type && searchValue?.current_model=='topvault' &&<span>{ searchValue?.name}</span>}
                             {searchValue?.current_model == searchValue?.model_type && searchValue?.current_model=='vaultinfo' &&<span>{` ${searchValue?.name} (${searchValue?.part_no}) | ${searchValue?.top_vault_name}`}</span>}
                             {searchValue?.current_model != searchValue?.model_type && searchValue?.current_model=='topvault' &&<span>{` ${searchValue?.name} | ${searchValue?.top_vault_name}`}</span>}
                             {searchValue?.current_model != searchValue?.model_type && searchValue?.current_model=='vaultinfo' && <span>{` ${searchValue?.name} (${searchValue?.part_no}) | ${searchValue?.top_vault_name}`}</span>}
                              {/* <span> {searchValue?.name} </span>
                              {searchValue?.model_type == "topvault" ? (
                                ""
                              ) : (
                                <span> ({searchValue?.part_no}) |</span>
                              )}
                              {searchValue?.model_type == "topvault" ? (
                                ""
                              ) : (
                                <span> {searchValue.top_vault_name}</span>
                              )} */}
                              {searchValue?.model_type == 'featuredetails' ? <span style={{position:'absolute',right:'1rem',fontWeight:'bold'}}>Feature</span> : searchValue?.model_type == 'ideastorage' ? <span style={{position:'absolute',right:'1rem',fontWeight:'bold'}}>Idea</span> : searchValue?.model_type == 'purchasingitem' || searchValue?.model_type == 'material' ? <span style={{position:'absolute',right:'1rem',fontWeight:'bold'}}>Costing</span> : ''}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {loaderImages ? (
                            <>
                             {searchValue?.model_type != 'ideastorage' &&  <div className={styles.accordianInnerDetails}>                  
                             <Box
                                  sx={{
                                    display: "flex",
                                    columnGap: "15px",
                                    marginBottom: "0.5rem",
                                  }}
                                >
                                  {!isUndefined(moduleData) &&
                                    moduleData?.project_modules.map(
                                      (item: any, index: any) => {
                                        return (
                                          <>
                                            {!isUndefined(item) &&
                                              Object.keys(item) &&
                                              Object.keys(item).includes(
                                                "BOM"
                                              ) &&
                                              item?.BOM == true && (
                                                <>
                                                  {ProjectInfo &&
                                                    Object.keys(
                                                      ProjectInfo
                                                    ).map(
                                                      (
                                                        keys: any,
                                                        index: any
                                                      ) => {
                                                        return (
                                                          <>
                                                            {keys ==
                                                              "bom_parameter" && Object.keys(
                                                                ProjectInfo[
                                                                "bom_parameter"
                                                                ]
                                                              )
                                                                .length >
                                                              0 && (
                                                                <Box
                                                                  sx={{
                                                                    maxHeight:
                                                                      "15rem",
                                                                    width: '100%',
                                                                  }}
                                                                  adl-scrollbar="true"
                                                                  adl-scrollbar-width="0.3"
                                                                >
                                                                  <Table>
                                                                    <TableHead
                                                                      sx={{ position: "sticky", top: 0, }}
                                                                    >
                                                                      <TableRow>
                                                                        <TableCell colSpan={2}
                                                                          sx={{ backgroundColor: "primary.main", color: "white", borderRadius: '5px',padding:'0 0.5rem' }}
                                                                          className={styles.tableHcell}
                                                                        >
                                                                          {keys == "bom_parameter" && Object.keys(ProjectInfo["bom_parameter"]).length > 0 && (
                                                                            <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", padding: '0.2rem 0', }}                                                                            >
                                                                              <span>
                                                                                {
                                                                                  "BOM Parameter"
                                                                                }
                                                                              </span>
                                                                              {/* <Typography
                                                                                sx={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              > */}
                                                                                <ReplyIcon
                                                                                  titleAccess="GO to BOM"
                                                                                  sx={{
                                                                                    fontSize:
                                                                                      "1.5rem",
                                                                                    transform:
                                                                                      "scaleX(-1)",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    BomRouteHandler()
                                                                                  }
                                                                                />
                                                                              {/* </Typography> */}
                                                                            </Box>
                                                                          )}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                      {keys ==
                                                                        "bom_parameter" &&
                                                                        ProjectInfoData(keys)}
                                                                    </TableBody>
                                                                  </Table>
                                                                </Box>
                                                              )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </>
                                        );
                                      }
                                    )}

                                  {!isUndefined(moduleData) &&
                                    moduleData?.project_modules.map(
                                      (item: any, index: any) => {
                                        return (
                                          <>
                                            {
                                              // @ts-ignore
                                              isUndefined(item) && Object.keys(item) && Object.keys(item).includes("BOM") && (item?.BOM == false) && (
                                                <Box
                                                  sx={{
                                                    textAlign: "center",
                                                    color: "red",
                                                    fontSize: " 0.9rem",
                                                    marginTop: "3rem"
                                                  }}

                                                >
                                                  You do not have permission
                                                  for Bom module
                                                </Box>
                                              )
                                            }
                                          </>
                                        );
                                      }
                                    )}

                                  {!isUndefined(moduleData) &&
                                    moduleData?.project_modules.map(
                                      (item: any, index: any) => {
                                        return (
                                          <>
                                            {!isUndefined(item) &&
                                              Object.keys(item) &&
                                              Object.keys(item).includes(
                                                "Costing"
                                              ) &&
                                              item?.Costing == true && (
                                                <>
                                                  {ProjectInfo &&
                                                    Object.keys(
                                                      ProjectInfo
                                                    ).map(
                                                      (
                                                        keys: any,
                                                        index: any
                                                      ) => {
                                                        return (
                                                          <>
                                                            {keys ==
                                                              "cost_parameter" &&
                                                              Object.keys(
                                                                ProjectInfo[
                                                                "cost_parameter"
                                                                ]
                                                              )
                                                                .length >
                                                              0 && (
                                                                <Box
                                                                  sx={{
                                                                    maxHeight:
                                                                      "15rem",
                                                                    width: '100%',
                                                                  }}
                                                                  adl-scrollbar="true"
                                                                  adl-scrollbar-width="0.3"
                                                                >
                                                                  <Table>
                                                                    <TableHead
                                                                      sx={{
                                                                        position:
                                                                          "sticky",
                                                                        top: 0,
                                                                      }}
                                                                    >
                                                                      <TableRow>
                                                                        <TableCell
                                                                          colSpan={
                                                                            2
                                                                          }
                                                                          sx={{
                                                                            backgroundColor:
                                                                              "primary.main",
                                                                            color:
                                                                              "white",
                                                                            borderRadius: '5px'
                                                                          }}
                                                                          className={
                                                                            styles.tableHcell
                                                                          }
                                                                        >
                                                                          {keys ==
                                                                            "cost_parameter" &&
                                                                            Object.keys(
                                                                              ProjectInfo[
                                                                              "cost_parameter"
                                                                              ]
                                                                            )
                                                                              .length >
                                                                            0 && (
                                                                              <Box
                                                                                sx={{
                                                                                  justifyContent:
                                                                                    "space-between",
                                                                                  display:
                                                                                    "flex",
                                                                                  alignItems:
                                                                                    "center",
                                                                                  padding: '0.2rem 0',
                                                                                }}
                                                                              >
                                                                                <span>
                                                                                  {
                                                                                    "Cost Parameter"
                                                                                  }
                                                                                </span>                                                                                
                                                                                  <ReplyIcon
                                                                                    titleAccess="GO to Costing"
                                                                                    style={{
                                                                                      cursor:
                                                                                      "pointer",
                                                                                      fontSize:
                                                                                        "1.5rem",
                                                                                      transform:
                                                                                        "scaleX(-1)",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      CostingRouteHandler()
                                                                                    }
                                                                                  />                                                                                
                                                                              </Box>
                                                                            )}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                      {keys ==
                                                                        "cost_parameter" &&
                                                                        ProjectInfoCostData(
                                                                          keys
                                                                        )}
                                                                    </TableBody>
                                                                  </Table>
                                                                </Box>
                                                              )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </>
                                        );
                                      }
                                    )}

                                  {!isUndefined(moduleData) &&
                                    moduleData?.project_modules.map(
                                      (item: any, index: any) => {
                                        // @ts-ignore
                                        // console.log( !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Costing") && item?.Costing == false,"chfvvdcdvydv")
                                        return (
                                          <>
                                            {
                                              // @ts-ignore
                                              !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Costing") && item?.Costing == false && (
                                                <Box

                                                  sx={{
                                                    textAlign: "center",
                                                    color: "red",
                                                    fontSize: " 0.9rem",
                                                    marginTop: "3rem"
                                                  }}

                                                >
                                                  You do not have permission
                                                  for Costing module
                                                </Box>
                                              )
                                            }
                                          </>
                                        );
                                      }
                                    )}

                                  {ProjectInfo &&
                                    Object.keys(ProjectInfo).length == 0 && (
                                      <p>No Data</p>
                                    )}
                                </Box>
                                <Box>

                                  <div className={classes.moduleLink}>

                                    {moduleData?.project_modules.map(
                                      (item: any, index: any) => {

                                        return (
                                          <>

                                            <div className={styles.searchTab}>

                                              <div>

                                                {
                                                  // @ts-ignore

                                                  !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Media") && item?.Media == true && (
                                                    <Button
                                                      className={
                                                        styles.modulelinkItem
                                                      }
                                                      sx={{ width: '14rem', display: 'flex', justifyContent: 'space-between' }}
                                                      variant="contained"
                                                      onClick={() =>
                                                        MediaRouteHandler()
                                                      }
                                                      size="small"
                                                    >

                                                      Go to media
                                                      <ReplyIcon

                                                        sx={{
                                                          marginLeft: '1rem',
                                                          fontSize:
                                                            "1.5rem",
                                                          transform:
                                                            "scaleX(-1)",
                                                        }}
                                                      />
                                                    </Button>
                                                  )}


                                                { // @ts-ignore

                                                  !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Media") && item?.Media == false && (
                                                    <Box



                                                      sx={{
                                                        textAlign: "center",
                                                        color: "red",
                                                        fontSize: " 0.9rem",
                                                        marginTop: "3rem"
                                                      }}


                                                    >

                                                      You do not have permission
                                                      for Media module
                                                    </Box>
                                                  )}

                                                <div>
                                                  {
                                                    // @ts-ignore

                                                    !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Features") && item?.Features == true && ProjectInfo && Object.keys(ProjectInfo["features_parameter"]).length == 0 && (
                                                      <Button
                                                        className={styles.modulelinkItem}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between',cursor:"pointer" }}
                                                        onClick={() => FeatureRouteHandler()}
                                                      >
                                                        Go to Features
                                                        <ReplyIcon

                                                          sx={{
                                                            marginLeft: '1rem',
                                                            fontSize:
                                                              "1.5rem",
                                                            transform:
                                                              "scaleX(-1)",
                                                          }}
                                                        />
                                                      </Button>) }
                                                        <>
                                                          {ProjectInfo &&
                                                            Object.keys(
                                                              ProjectInfo
                                                            ).map(
                                                              (
                                                                keys: any,
                                                                index: any
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    {keys ==
                                                                      "features_parameter" && Object.keys(
                                                                        ProjectInfo[
                                                                        "features_parameter"
                                                                        ]
                                                                      )
                                                                        .length >
                                                                      0 && (
                                                                        <Box
                                                                          sx={{
                                                                            maxHeight:
                                                                              "15rem",
                                                                            width: '100%',
                                                                          }}
                                                                          adl-scrollbar="true"
                                                                          adl-scrollbar-width="0.3"
                                                                        >
                                                                          <Table>
                                                                          <TableHead
                                                                      sx={{ position: "sticky", top: 0, }}
                                                                    >
                                                                      <TableRow>
                                                                        <TableCell colSpan={2}
                                                                          sx={{ backgroundColor: "primary.main", color: "white", borderRadius: '5px',padding:'0 0.5rem' }}
                                                                          className={styles.tableHcell}
                                                                        >
                                                                          {keys == "features_parameter" && Object.keys(ProjectInfo["features_parameter"]).length > 0 && (
                                                                            <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", padding: '0.2rem 0', }}                                                                            >
                                                                              <span>
                                                                                {
                                                                                  "Features Parameter"
                                                                                }
                                                                              </span>
                                                                              {/* <Typography
                                                                                sx={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              > */}
                                                                                <ReplyIcon
                                                                                  titleAccess="Go to Feature"
                                                                                  sx={{
                                                                                    fontSize:
                                                                                      "1.5rem",
                                                                                    transform:
                                                                                      "scaleX(-1)",

                                                                                      cursor:"pointer"
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    FeatureRouteHandler()
                                                                                  }
                                                                                />
                                                                              {/* </Typography> */}
                                                                            </Box>
                                                                          )}
                                                                        </TableCell>
                                                                      </TableRow>
                                                                    </TableHead>
                                                                            <TableBody>
                                                                              {keys ==
                                                                                "features_parameter" &&
                                                                                ProjectInfoFeaturesData(keys)}
                                                                            </TableBody>
                                                                          </Table>
                                                                        </Box>
                                                                      )}
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                        </>

                                                  {
                                                    // @ts-ignore

                                                    !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Features") && item?.Features == false && (

                                                      <Box



                                                        sx={{
                                                          textAlign: "center",
                                                          color: "red",
                                                          fontSize: " 0.9rem",
                                                          marginTop: "3rem"
                                                        }}

                                                      >

                                                        You do not have permission
                                                        for Features module
                                                      </Box>
                                                    )}
                                                  {

                                                    //  @ts-ignore
                                                    !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Architecture") && item?.Architecture == true && (
                                                      <Button
                                                        className={
                                                          styles.modulelinkItem
                                                        }
                                                        sx={{ width: '14rem', display: 'flex', justifyContent: 'space-between' }}
                                                        variant="contained"
                                                        onClick={() =>
                                                          ArchRouteHandler()
                                                        }
                                                        size="small"
                                                      >

                                                        Go to Architecture
                                                        <ReplyIcon

                                                          sx={{
                                                            marginLeft: '1rem',
                                                            fontSize:
                                                              "1.5rem",
                                                            transform:
                                                              "scaleX(-1)",
                                                          }}
                                                        />
                                                      </Button>
                                                    )}

                                                  {

                                                    // @ts-ignore

                                                    !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Architecture") && item?.Architecture == false && (
                                                      <Box
                                                        sx={{
                                                          textAlign: "center",
                                                          color: "red",
                                                          fontSize: " 0.9rem",
                                                          marginTop: "3rem"
                                                        }}

                                                      >

                                                        You do not have permission
                                                        for Architecture module
                                                      </Box>
                                                    )}
                                                </div>

                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </Box>
                              </div>}
                              <Box sx={{ margin: '0.5rem 1rem 0.5rem 0' }}>
                                {!isUndefined(moduleData) &&
                                  moduleData?.project_modules.map(
                                    (item: any, index: any) => {
                                      return (
                                        <>
                                          {!isUndefined(item) &&
                                            Object.keys(item) &&
                                            Object.keys(item).includes(
                                              "Ideas"
                                            ) &&
                                            item?.Ideas == true && (
                                              <>
                                                {ProjectInfo &&
                                                  Object.keys(
                                                    ProjectInfo
                                                  ).map(
                                                    (
                                                      keys: any,
                                                      index: any
                                                    ) => {
                                                      return (
                                                        <>
                                                          {keys ==
                                                            "idea_parameter" && ProjectInfo?.[
                                                              "idea_parameter"
                                                            ]
                                                              ?.length >
                                                            0 && (
                                                              <Box
                                                                sx={{

                                                                  maxHeight:
                                                                    "15rem",
                                                                  width: '100%',
                                                                }}
                                                                adl-scrollbar="true"
                                                                adl-scrollbar-width="0.3"
                                                              >
                                                                <Table>
                                                                  <TableHead
                                                                    sx={{
                                                                      position:
                                                                        "sticky",
                                                                      top: 0,
                                                                    }}
                                                                  >
                                                                    <TableRow>
                                                                      <TableCell
                                                                        colSpan={
                                                                          3
                                                                        }
                                                                        sx={{
                                                                          backgroundColor:
                                                                            "primary.main",
                                                                          color:
                                                                            "white",
                                                                          borderRadius: '5px'
                                                                        }}
                                                                        className={
                                                                          styles.tableHcell
                                                                        }
                                                                      >
                                                                        {keys ==
                                                                          "idea_parameter" &&
                                                                          ProjectInfo?.[
                                                                            "idea_parameter"
                                                                          ]
                                                                            ?.length >
                                                                          0 && (
                                                                            <Box
                                                                              sx={{
                                                                                justifyContent:
                                                                                  "space-between",
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                padding: '0.2rem 0'
                                                                              }}
                                                                            >
                                                                              <span>
                                                                                {
                                                                                  "Idea Parameter"
                                                                                }
                                                                              </span>
                                                                              <Typography
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                <ReplyIcon
                                                                                  titleAccess="GO to Idea"
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "1.5rem",
                                                                                    transform:
                                                                                      "scaleX(-1)",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    window.open(
                                                                                      `/#/idea/${moduleData?.project_id}/${moduleData?.top_vault_id}/${moduleData?.idea_abbreviation}/${moduleData?.vault_info_id}/${moduleData?.idea_obj_id}`
                                                                                    )
                                                                                  }
                                                                                />
                                                                              </Typography>
                                                                            </Box>
                                                                          )}
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  </TableHead>
                                                                  <TableBody>
                                                                    {keys ==
                                                                      "idea_parameter" &&
                                                                      IdeaParameterInfo(
                                                                        keys
                                                                      )}
                                                                  </TableBody>
                                                                </Table>
                                                              </Box>
                                                            )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </>
                                            )}
                                        </>
                                      );
                                    }
                                  )}

                                {!isUndefined(moduleData) &&
                                  moduleData?.project_modules.map(
                                    (item: any, index: any) => {
                                      return (
                                        <>
                                          {
                                            // @ts-ignore
                                            !isUndefined(item) && Object.keys(item) && Object.keys(item).includes("Ideas") && item?.Ideas == false && (
                                              <Box>
                                                You do not have permission
                                                for Ideas module
                                              </Box>
                                            )
                                          }
                                        </>
                                      );
                                    }
                                  )}
                              </Box>
                              <>
                                {loaderImages ? (
                                  <div className={classes.root}>
                                    <div>
                                      {/* @ts-ignore */}
                                      <RViewerJS
                                        className={
                                          classes.ModuleImagesContainer
                                        }
                                      >
                                        {ModuleImages &&
                                          ModuleImages["bom_images"].map(
                                            (ItemImage: any, index: any) => {
                                              return (
                                                <>
                                                  <span
                                                    className={
                                                      classes.imageSepration
                                                    }
                                                    key={index}
                                                  >
                                                    <Image
                                                      src={ItemImage}
                                                      placeholderImg={
                                                        NoImgLoader
                                                      }
                                                      className={
                                                        classes.moduleImage
                                                      }
                                                      alt="module"
                                                    />
                                                  </span>
                                                </>
                                              );
                                            }
                                          )}
                                        {ModuleImages &&
                                          ModuleImages["feature_images"].map(
                                            (ItemImage: any, index: any) => {
                                              return (
                                                <>
                                                  <span
                                                    className={
                                                      classes.imageSepration
                                                    }
                                                    key={index}
                                                  >
                                                    <Image
                                                      src={ItemImage}
                                                      placeholderImg={
                                                        NoImgLoader
                                                      }
                                                      className={
                                                        classes.moduleImage
                                                      }
                                                      alt="module"
                                                    />
                                                  </span>
                                                </>
                                              );
                                            }
                                          )}
                                        {ModuleImages &&
                                          ModuleImages["ideas_images"].map(
                                            (ItemImage: any, index: any) => {
                                              return (
                                                <>
                                                  <span
                                                    className={
                                                      ItemImage &&
                                                      classes.imageSepration
                                                    }
                                                    key={index}
                                                  >
                                                    <Image
                                                      src={ItemImage}
                                                      placeholderImg={
                                                        NoImgLoader
                                                      }
                                                      className={
                                                        ItemImage &&
                                                        classes.moduleImage
                                                      }
                                                      alt="module"
                                                    />
                                                  </span>
                                                </>
                                              );
                                            }
                                          )}
                                      </RViewerJS>
                                    </div>
                                  </div>
                                ) : (
                                  <Box sx={{ width: 500 }}>

                                  </Box>
                                )}
                              </>
                            </>
                          ) : (
                            <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                              <Box>
                                <Skeleton sx={{ height: '3rem', width: '33vw' }} />
                                <Skeleton sx={{ height: '3rem', width: '33vw' }} />
                                <Skeleton sx={{ height: '3rem', width: '33vw' }} />
                              </Box>
                              <Skeleton sx={{ height: '15rem', width: '15rem' }} animation="wave" />
                              <Skeleton sx={{ height: '15rem', width: '15rem' }} animation={false} />
                              <Skeleton sx={{ height: '15rem', width: '15rem' }} animation="wave" />
                              <Skeleton sx={{ height: '15rem', width: '15rem' }} animation={false} />
                            </Box>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })
              ) : (
                <span className={styles.Noserach}>
                  <Typography className={styles.noresult}>
                    No results for "
                    {recentKey !== null ? `${recentKey}` : `${valueData}`}"
                  </Typography>
                  <img src={Nosearch} style={{ width: "4rem" }} />
                </span>
              )
            ) : (
              <SkeletonLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
