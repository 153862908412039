import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { Divider, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { API } from "../../api-services";
import PlantlistRow from "./PlantlistRow";
import CancelIcon from '@mui/icons-material/Cancel';
import { useRouteMatch } from "react-router-dom";

const styles = {
    table: {
        maxHeight: '90rem', // Set the desired height here
        // Add vertical scroll if needed
    },
};
interface OEMInfomodalProps {
    onCloseModal: any;
    isOpen: any;
    rowIds: any;
    supplierDataApiCall?:any;
    setSupplierDataApiCall?:any;
    onCloseModalSupplier?:any;
    useraction?:any;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    borderRadius: '10px',
    p: 1,
};


const PlantModal = (props: OEMInfomodalProps) => {
    const { onCloseModal, isOpen, rowIds, supplierDataApiCall, setSupplierDataApiCall,onCloseModalSupplier,useraction } = props;
    const [Infolist, setInfolist] = useState<any>([]);
   
    const [loader, setLoader] = useState<any>(false)
    const [plantListCounter, setplantListCounter] = useState<any>(0)
    const {url} = useRouteMatch();
console.log(useraction && useraction?.includes("C"),url,"vfhvkvfvfkvnfvnj",url?.includes('supplier-database'))
    useEffect(() => {

        if (plantListCounter == 0) {
            setLoader(true)
            API.get("/api/db/supplier_plant/", { supplier: rowIds })
                .then((res: any) => {
                    setInfolist(res?.data);
                    setLoader(false)
                })
                .catch((err: any) => {
                    setLoader(false)
                    console.log("Server errort");
                });
        } else {
            API.get("/api/db/supplier_plant/", { supplier: rowIds })
                .then((res: any) => {
                    setInfolist(res?.data);
                    setLoader(false)
                    url?.includes('supplier-database') && setSupplierDataApiCall(!supplierDataApiCall)
                })
                .catch((err: any) => {
                    setLoader(false)
                    console.log("Server errort");
                });
        }

    }, [rowIds, plantListCounter]);


    var AddRow = () => {
        var obj: any = { id: 0, city: "", state: "", latitude: "", longitude: "", rowstatus: true }
        var addrowstatus = Infolist && Infolist?.map((item: any, index: any) => ({ ...item, rowstatus: false }))
        setInfolist([obj, ...addrowstatus])

    }


    return (
        <div>
            <Modal open={isOpen}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                           Manufacturing Plant Information
                        </Typography>
                        <Box sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',}}>
                            {url?.includes('supplier-database')? <>
                        {useraction && useraction?.includes("C") && <Button>
                        <AddIcon titleAccess="add"  sx={{ cursor: "pointer" }} onClick={() => AddRow()} />
                          
                        </Button>}</>:<AddIcon titleAccess="add"  sx={{ cursor: "pointer" }} onClick={() => AddRow()} />}
                    
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={()=>{return onCloseModal(),url?.includes('supplier-database') && onCloseModalSupplier(),url?.includes('supplier-database') && setSupplierDataApiCall(!supplierDataApiCall)}} />
                    </Box>
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />                    
                    <Box sx={{ width: "100%" ,padding:'0 1rem 1rem'}}>
                        {/* @ts-ignore */}
                        <TableContainer style={styles.table}>
                            <Table sx={{ maxHeight: "20rem !important" }} stickyHeader={true}>
                                <TableHead sx={{ backgroundColor: "primary.light" }}>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>SL.NO.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>Town/city</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>State</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>Longitude</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}> Latitude</TableCell>

                                    {url?.includes('supplier-database')? <>
                                    {(useraction && useraction?.includes("U") || useraction && useraction?.includes("D") ) &&
                                    
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>Actions</TableCell>}

                                    </>:
                                    
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor: "primary.light" }}>Actions</TableCell>
                                    }
                                </TableHead>
                                <TableBody>
                                    {loader ?
                                        <>
                                            <TableRow>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                                <TableCell sx={{ padding: '0.5rem' }}><Skeleton></Skeleton></TableCell>
                                            </TableRow>
                                        </>
                                        :
                                        (Infolist && Infolist?.map((item: any, index: any) => {
                                            return (
                                                <PlantlistRow  useraction={useraction} rowId={rowIds} setplantListCounter={setplantListCounter} rowItem={item} keys={index + 1}/>
                                            )
                                        }))
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default PlantModal;
