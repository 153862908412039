import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loadingImage from "../../../Assets/images/loading-screen.gif";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { useHistory, useParams } from "react-router-dom";
import unmerge from "../../../Assets/images/Unmerge.svg";

export interface IIdeaInfoModalProps {
  onCloseModal: any;
  isOpen: any;
  setCounter: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "40vw", xl: "35vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function IdeaInfoModal(props: IIdeaInfoModalProps) {
  const { onCloseModal, isOpen, setCounter } = props;
  const history = useHistory();
  const {
    projectId,
    projectName,
    topVault,
    subSystem,
    subSystemId,
    expandTable,
    abbr,
    partId,
    depth,
    vault_id,
  } = useParams<any>();
  const [ideaAllDetails, setIdeaAllDetails] = React.useState<any>({});
  const getIdeaDetailsData = () => {
    API.get(
      "/idea/pool/",
      {
        module: 15,
        page: 1,
        // table: true,
        single_idea: vault_id,
      },
      0
    )
      .then((res: any) => {
        setIdeaAllDetails(res?.data[0]);
      })
      .catch((err: any) => {
        if (err.response.data) {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  React.useEffect(() => {
    if (vault_id != 0) {
      getIdeaDetailsData();
    }
  }, [vault_id]);

  const unMergeIdea = (id: any) => {
    API.put(`/idea/pool/${id}/`, { merge: null }, {}, 0)
      .then((res: any) => {
        getIdeaDetailsData();
        setCounter((prev: any) => prev + 1);
        ADMIN.toast.info("Idea Unmerged Successfully");
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Additional Idea Info
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ width: "100%", height: "fit-content", padding: "0 0.5rem" }}
          >
            {(ideaAllDetails?.origin !== 2 || ideaAllDetails?.origin !== 5) && (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        width: "50%",
                      }}
                    >
                      {" "}
                      Idea ID :
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        width: "50%",
                      }}
                    >
                      {" "}
                      {Object.keys(ideaAllDetails)?.length > 0 &&
                        ideaAllDetails?.idea_id}{" "}
                    </TableCell>
                  </TableRow>
                  {ideaAllDetails?.origin !== 2 &&
                    ideaAllDetails?.origin !== 5 && (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            width: "50%",
                          }}
                        >
                          {" "}
                          Parameter :
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            width: "50%",
                          }}
                        >
                          {" "}
                          {Object.keys(ideaAllDetails)?.length > 0 &&
                            ideaAllDetails?.parameter}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  {ideaAllDetails?.origin !== 2 &&
                    ideaAllDetails?.origin !== 5 && (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            width: "50%",
                          }}
                        >
                          {" "}
                          Unit :
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            width: "50%",
                          }}
                        >
                          {" "}
                          {Object.keys(ideaAllDetails)?.length > 0 &&
                            ideaAllDetails?.unit}{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            )}
            {ideaAllDetails?.origin === 1 && ideaAllDetails?.base  && (
              <Box
                sx={{
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
               {ideaAllDetails?.origin ===1 &&  <Table>
                  <TableHead>
                    <TableRow>
                      {ideaAllDetails?.base && <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',backgroundColor: "primary.light",}}>
                      <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Base Vehicle
                    </Typography>
                      </TableCell>}
                     {ideaAllDetails?.competitor &&  <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',backgroundColor: "primary.light",}}>
                         <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Competitor Vehicle
                    </Typography>
                      </TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                     {ideaAllDetails?.base &&  <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',cursor:'pointer'}}
                      onClick={() =>
                        {
                          if(ideaAllDetails?.base?.abbreviation==="product"){
                            window.open(
                              `/#/view/${projectId}/${
                                ideaAllDetails?.base?.top_vault
                              }/${0}/${
                                ideaAllDetails?.base?.abbreviation || "null"
                              }/true`
                            )
                          }else{
                            sessionStorage.removeItem("node");
                            sessionStorage.setItem("node", JSON.stringify(ideaAllDetails?.base?.ancestors));
                          window.open(
                          `/#/view/${projectId}/${
                            ideaAllDetails?.base?.top_vault
                          }/${ideaAllDetails?.base?.id}/${
                            ideaAllDetails?.base?.abbreviation || "null"
                          }/true`
                        )}}
                      }>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "600",
                          textAlign: "center",
                          width: "100%",                    
                          textDecoration:'underline',
                        }}
                  >
                    {ideaAllDetails?.base?.name}
                  </Typography>
                      </TableCell>}
                      {ideaAllDetails?.competitor && <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',cursor:'pointer'}}
                       onClick={() =>
                        {
                          if(ideaAllDetails?.competitor?.abbreviation==="product"){
                            window.open(
                              `/#/view/${projectId}/${
                                ideaAllDetails?.competitor?.top_vault
                              }/${0}/${
                                ideaAllDetails?.competitor?.abbreviation || "null"
                              }/true`
                            )
                          }else{
                            sessionStorage.removeItem("node");
                            sessionStorage.setItem("node", JSON.stringify(ideaAllDetails?.competitor?.ancestors));
                          window.open(
                          `/#/view/${projectId}/${
                            ideaAllDetails?.competitor?.top_vault
                          }/${ideaAllDetails?.competitor?.id}/${
                            ideaAllDetails?.competitor?.abbreviation || "null"
                          }/true`
                        )}}
                      }>
                <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      textAlign: "center",
                      width: "100%",
                      textDecoration:'underline',                  
                    }}
                  >
                    {ideaAllDetails?.competitor?.name}
                  </Typography>  
                      </TableCell>}
                    </TableRow>
                  </TableBody>

                </Table>}
              </Box>
            )}
            {(ideaAllDetails?.origin === 2 || ideaAllDetails?.origin === 5) && (
              <Box
                sx={{
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Base Vehicle
                  </Typography>
                  <Image
                    src={
                      Object.keys(ideaAllDetails) && ideaAllDetails?.base_image
                    }
                    alt="Base-image"
                    placeholderImg={loadingImage}
                    style={{
                      width: "auto",
                      height: "10rem",
                      maxWidth: "15vw",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Competitor Vehicle
                  </Typography>
                  <Image
                    src={
                      Object.keys(ideaAllDetails) &&
                      ideaAllDetails?.competitor_image
                    }
                    alt="Competitor-image"
                    placeholderImg={loadingImage}
                    style={{
                      width: "auto",
                      height: "10rem",
                      maxWidth: "15vw",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box
              sx={{ width: "100%", maxHeight: "50vh", marginTop: "0.5rem" }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {ideaAllDetails && ideaAllDetails?.merge_idea?.length > 0 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ fontSize: "1rem", width: "50%", fontWeight: "600" }}
                  >
                    Idea Merged List
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", width: "50%", fontWeight: "600" }}
                  >
                    Action
                  </Typography>
                </Box>
              )}

              {(ideaAllDetails?.origin === 2 ||
                ideaAllDetails?.origin === 5) && (
                <Divider
                  sx={{ borderColor: "primary.light", margin: "0.2rem 0" }}
                />
              )}
              {Object.keys(ideaAllDetails)?.length > 0 &&
                ideaAllDetails?.merge_idea?.length > 0 &&
                ideaAllDetails?.merge_idea?.map((item: any) => {
                  return (
                    <Box sx={{ width: "100%", marginBottom: "0.4rem" }}>
                      {/* <Typography sx={{fontWeight:'600',fontSize:'1rem'}}>Idea Merge List</Typography> */}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            width: "50%",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `/#/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/${expandTable}/${abbr}/${partId}/${0}/${
                                item?.id
                              }/0/0`
                            );
                          }}
                        >
                          {item?.idea_id}
                        </Typography>
                        <img
                          src={unmerge}
                          title="Undo Merge Idea"
                          style={{
                            cursor: "pointer",
                            width: "1.5rem",
                            transform: "rotate(180deg)",
                          }}
                          onClick={() => unMergeIdea(item?.id)}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
