import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { Divider, Typography } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from "recharts";
import { API } from "../../api-services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "70%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  rowItems: any;
}

function RawMaterialGraph(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, rowItems } = props;

  const [GraphList, setGraphList] = React.useState<any>([]);
  const [GraphListRM, setGraphListRM] = React.useState<any>([]);

  React.useEffect(() => {
    API.get("/api/db/revision_graph/", {
      model_type: "materialcost",
      id: rowItems?.material_id,
    })
      .then((res: any) => {
        setGraphList(res?.data);
      })
      .catch((err: any) => { });
  }, [rowItems]);
  React.useEffect(() => {
    API.get("/api/db/revision_graph/", {
      model_type: "rawmaterialcost",
      id: rowItems?.material_id,
    })
      .then((res: any) => {
        setGraphListRM(res?.data);
      })
      .catch((err: any) => { });
  }, [rowItems]);

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return (
      <path
        d={getPath(x, y, width, height)}
        stroke="none"
        fill={fill}
      />
    );
  };
  const renderCustomizedTotalCostLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
        textRendering="center"
      >
        {value}
      </text>
    );
  };
  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {

    console.log(payload, "payloadpayloadpayload")
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
            border: "1px solid"
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: '0.75rem', minWidth: '8rem', width: '100%', textAlign: 'center' }}>{`${label}`}</Typography>

          {payload.map((item: any, index: any) => {


            return (<>

              <Typography sx={{ fontSize: '0.75rem', width: '100%', display: 'flex', columnGap: '0.5rem', alignItems: 'center', }}><span style={{ width: '6rem', }}>Name:</span>{item.payload?.name}</Typography>
              <Typography sx={{ fontSize: '0.75rem', width: '100%', display: 'flex', columnGap: '0.5rem', alignItems: 'center', }}><span style={{ width: '6rem', }}>Date:</span>{item.payload?.revised_on} </Typography>
              <Typography sx={{ fontSize: '0.75rem', width: '100%', display: 'flex', columnGap: '0.5rem', alignItems: 'center', }}><span style={{ width: '6rem', }}>Project:</span>{item.payload?.project}</Typography>
              <Typography sx={{ fontSize: '0.75rem', width: '100%', display: 'flex', columnGap: '0.5rem', alignItems: 'center', }}><span style={{ width: '6rem', }}>Revision name:</span> {item.payload?.revision_name}</Typography>
              <Typography sx={{ fontSize: '0.75rem', width: '100%', display: 'flex', columnGap: '0.5rem', alignItems: 'center', }}><span style={{ width: '6rem', }}>Value:</span>{item.payload?.value} INR</Typography>
            </>)
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description">
        {GraphList && GraphList && (
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
           
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}>
                Scrap Price variations
              </Typography>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}>
                Raw Material Price variations
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>

            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box width="100%" height="100%" sx={{display:'flex'}}>
            <ResponsiveContainer width="50%" height="80%">
              {GraphList && (
                <BarChart
                  height={270}
                  data={GraphList && GraphList}
                  margin={{
                    top: 40,
                    left: 20,
                    right: 20,
                    bottom: 130,
                  }}>
                  <YAxis stroke="#000000"
                  fontSize="0.75rem">
                    <Label fill="#000" value={"INR/Kg"} offset={0} position="center"
                      angle={270}
                      dy={30}
                      dx={-20}
                      fontSize="0.75rem" />
                  </YAxis>

                  <XAxis
                    dataKey="revision_name"
                    angle={90}
                    textAnchor="bottom"
                    fontSize="0.75rem"
                    fontStyle="bold"
                    stroke="#000000"
                    interval={0}>
                    <Label fontSize="0.75rem" fill="#000" offset={0} position="insideBottom" />
                  </XAxis>

                  <Tooltip cursor={false} content={<CustomizedCostTooltip />} />

                  <Bar
                    barSize={100}
                    isAnimationActive={true}
                    dataKey="value"
                    stackId="a"
                    shape={<TriangleBar />}

                  >
                    {GraphList &&
                      GraphList?.map((entry: any) => (
                        <Cell fill={entry?.colors} />
                      ))}

                    <LabelList dataKey="value" position="top" fill="black"
                      content={renderCustomizedTotalCostLabel}
                    />
                  </Bar>
                </BarChart>
              )}
            </ResponsiveContainer>
            <ResponsiveContainer width="50%" height="80%">
              {GraphListRM && (
                <BarChart
                  height={270}
                  data={GraphListRM && GraphListRM}
                  margin={{
                    top: 40,
                    left: 20,
                    right: 20,
                    bottom: 130,
                  }}>
                  <YAxis stroke="#000000"
                  fontSize="0.75rem">
                    <Label fill="#000" value={"INR/Kg"} offset={0} position="center"
                      angle={270}
                      dy={30}
                      dx={-20}
                      fontSize="0.75rem" />
                  </YAxis>

                  <XAxis
                    dataKey="revision_name"
                    angle={90}
                    textAnchor="bottom"
                    fontSize="0.75rem"
                    fontStyle="bold"
                    stroke="#000000"
                    interval={0}>
                    <Label fontSize="0.75rem" fill="#000" offset={0} position="insideBottom" />
                  </XAxis>

                  <Tooltip cursor={false} content={<CustomizedCostTooltip />} />

                  <Bar
                    barSize={100}
                    isAnimationActive={true}
                    dataKey="value"
                    stackId="a"
                    shape={<TriangleBar />}

                  >
                    {GraphListRM &&
                      GraphListRM?.map((entry: any) => (
                        <Cell fill={entry?.colors} />
                      ))}

                    <LabelList dataKey="value" position="top" fill="black"
                      content={renderCustomizedTotalCostLabel}
                    />
                  </Bar>
                </BarChart>
              )}
            </ResponsiveContainer>
            </Box>
           
          </Box>
        )}
      </Modal>
    </div>
  );
}
export default RawMaterialGraph;
