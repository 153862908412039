import React from 'react'
import styles from "./ViewComponents.module.scss";
import QuickCompareLanding from '../ComponentUI/QuickCompare/QuickCompareLanding';

type Props = {}

export default function Footer({ }: Props) {
  const [closeCount,setCloseCounts]=React.useState<any>(true)
  
  return (
    <div className={styles.footer} >
      {/* <span className={styles.fooCont} >Switch to old User Interface</span> */}
      {<QuickCompareLanding setCloseCounts={setCloseCounts}  />}
    </div>
  )
}
