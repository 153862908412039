import * as React from 'react';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import styles from './CostingCreateCommodityAnalysisDrawer.module.scss';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { CostingCreateCommodityAnalysisDrawer } from './CostingCreateCommodityAnalysisDrawer';
import { API } from '../../../api-services';
import { AxiosError, AxiosResponse } from 'axios';
import CommodityWiseAnalysisPageCostingCreate from './CommodityWiseAnalysis/CommodityWiseAnalysisPageCostingCreate';

export interface ICostingCreateCommodiyWiseAnalysisProps {
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Params {
    projectId: any;
    projectName: any;
    topVaultIds: any;
    expandTable: any;
    initialTopVault: any;
    abbr: any;
    partId: any;
    depth: any;
    projectIdOld: any;
    projectNameOld: any;
    vault_id: any;
  }

export default function CostingCreateCommodiyWiseAnalysisCreateCosting (props: ICostingCreateCommodiyWiseAnalysisProps) {
    const { projectId, projectName, topVaultIds,
      expandTable,
      initialTopVault,
      abbr,
      partId,
      depth,
      projectIdOld,
      projectNameOld, vault_id} = useRouteParams<Params>();
    const [productList, setProductList] = useState<any>([]);
    const history = useHistory();
    React.useEffect(() => {
      API.get(
        `xcpep/product_list_new/`,
        { project: projectId, module: 'Costing-Create' },
        0
      )
        .then((res: AxiosResponse) => {
          setProductList(res?.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, 'error');
        });
    }, [projectId]);
    
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

    const list = (anchor: Anchor) => (
        <CostingCreateCommodityAnalysisDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
    );
  
    // const handleClickBack = () => {
    //   if(productList.length > 0){
    //     history.push(`/costingqualitycheck/${projectIdOld}/${projectNameOld}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbr}/${partId}/${depth}/${vault_id}`);
    //   }
    // };

    // const analysisSummary = () => {
    //   console.log(`/CostingAnalysisSummary/${projectId}/${projectName}`);
    //   history.push(`/CostingAnalysisSummary/${projectId}/${projectName}`);
    // }
      
  return (
    <>
      <Box sx={{ padding: "0 0.5rem", display: 'flex', alignItems: 'center' }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft} >
            {/* <IconButton sx={{ cursor: "pointer", }} onClick={handleClickBack}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton> */}
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block' }}>Commodity wise Cost/Kg Analysis ( {projectName} - {topVaultIds} )</Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {/* <Button size='small' variant='contained' onClick={analysisSummary}>Analysis Summary</Button> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ maxHeight: { lg: '86vh', xl: '90vh' }, width: '98%' }}
          // sx={String(topVaultIds)?.includes(',') ? topVaultIds?.split(',')?.length == 2 ? {display:'grid', gridTemplateColumns:`1.5fr 0.5fr 1fr`, width:'98%', maxHeight:"90vh"} : {display:'grid', gridTemplateColumns:`1.5fr 0.5fr repeat(${topVaultIds?.split(',')?.length-1},1fr)`, width:'98%', maxHeight:"90vh"} : {display:'grid', gridTemplateColumns:`1fr`, width:'98%', maxHeight:"90vh"}}  
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
         <CommodityWiseAnalysisPageCostingCreate />
        </Box>
      </Box>
    </>
  );
}
