import {
  Badge,
  Box,
  Button,
  Skeleton,
  TableContainer,
  TableRow,

} from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from "react";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { Table, TableBody, TableCell, TableHead } from "@mui/material";
import OEMlistRow from "./OEMlistRow";

import OEMInfomodal from "./OEMInfomodal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import PlantModal from "./PlantModal";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory, useParams } from "react-router-dom";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import recyleBin from "../../Assets/images/deletefill.svg";
import recyleBin from "../../Assets/images/deletefill.svg"
const styles = {
  table: {
    padding: '0 1rem',
    maxHeight: { lg: '77vh', xl: '85vh' }, // Set the desired height here
    // overflowY: 'scroll', // Add vertical scroll if needed
  },
};
interface OEMlistProps { }

const OEMlist = (props: OEMlistProps) => {
  const { projectId } = useParams<any>();
  const [OEmData, setOEmData] = useState<any>([]);
  const [vehicleData, setVehicleData] = useState<any>([]);
  const [PageCount, setPageCount] = useState<any>();
  const [count, setCount] = useState<any>(1);
  const [loader, setLoader] = useState<any>(false);
  const [OemListCounter, setOemListCounter] = useState<any>(0);
  const [rowIds, setrowIds] = useState<any>();
  const oemmodal = useBaseModal();
  const [searchvalue, setsearchvalue] = useState<any>(null);
  const [searchkey, setSearchkey] = useState<any>(null);
  const plantpopup = useBaseModal();
  const filterpopup = useBaseModal()
  const [SearchCount, setSearchCount] = useState<any>(0)
  const [FilterTypecounter, setFilterTypecounter] = useState<any>(0)
  const { height, width } = useWindowDimensions();

  const [Filterkey, setFilterKey] = useState({})
  const [FilterType, setFilterType] = useState(null)


  const [OemType, setOemtype] = React.useState<any>([])
  const [name, setname] = React.useState<any>("")
  const [Oemcity, setOemcity] = React.useState<any>([])
  const [city, setcity] = React.useState<any>("")
  const [Oemstate, setOemstate] = React.useState<any>([])
  const [state, setstate] = React.useState<any>("")
  const [OemVehicle, setOemVehicle] = React.useState<any>([])
  const [vehicle, setvehicle] = React.useState<any>("")
  const [resverUpload, setReverseUpload] = useState<any>(false)
  const inputElRefPdf = useRef<any>()
  const [recyclebinItemCount, setRecyclebinItemCount] = useState<any>();
  const history = useHistory<any>();
  useEffect(() => {

    if (searchkey == null || searchkey?.length == 0 || searchkey == undefined)
      if (OemListCounter == 0) {
        setLoader(true)
        setSearchCount(0)
        API.get(`/api/db/supplier/`, { is_oem: true, key: count - 1, pagenation: true, page_items: width > 1400 ? 19 : 13, }, 0).then((res: any) => {
          setLoader(false)
          setOEmData(res?.data)
        }).catch((err: any) => {
          setLoader(false)
        })
      } else {
        setSearchCount(0)
         setLoader(true)
        API.get(`/api/db/supplier/`, { is_oem: true, key: count - 1, pagenation: true, page_items: width > 1400 ? 19 : 13, }, 0).then((res: any) => {
          setLoader(false)
          setOEmData(res?.data)
        }).catch((err: any) => {

        })
      }


  }, [count, OemListCounter, searchkey])


  useEffect(() => {
    API.get("/api/db/vehicle_type/").then((res: any) => {
      setVehicleData(res?.data);
    });
  }, []);



  useEffect(() => {
    API.get("/api/db/OEM_List_Count/", {
      is_oem: true,

      page_items: width > 1400 ? 19 : 13,
    }).then((res: any) => {
      setPageCount(res?.data?.page_count);

    });
  }, []);

  useEffect(() => {
    if (searchkey !== null) {
      if (SearchCount == 0) {
        setLoader(true);
        API.get("/api/db/OEM_name_search/", { search_key: searchkey })
          .then((res: any) => {
            setOEmData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      } else {
        setLoader(true);
        API.get("/api/db/OEM_name_search/", { search_key: searchkey })
          .then((res: any) => {
            setOEmData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }

    }
  }, [searchkey, SearchCount]);


  const handlechange = (event: any) => {

    setsearchvalue(event?.target.value);
    if (event?.target.value.length == 0) {
      setsearchvalue(null)
      setSearchkey(null)
      setLoader(true);
      API.get(
        `/api/db/supplier/`,
        { is_oem: true, key: count - 1, pagenation: true, page_items: width > 1400 ? 19 : 13 },
        0
      )
        .then((res: any) => {
          setLoader(false);
          setOEmData(res?.data);
        })
        .catch((err: any) => {
          setLoader(false);
        });
    }


  };
  var AddRow = () => {
    var obj: any = {
      id: 0,
      contact_info: "",
      supplier_details: "",
      name: "",
      plants: {},
      vehicle_type: [],
      rowstatus: true,
    };
    var addrowstatus =
      OEmData &&
      OEmData?.map((item: any, index: any) => ({ ...item, rowstatus: false }));
    setOEmData([obj, ...addrowstatus]);
  };


  const PrveCount = () => {
    if (count !== 1) {
      setCount((prev: any) => prev - 1);
    }
  };

  const NextCount = () => {
    if (count <= PageCount - 1) {
      setCount((prev: any) => prev + 1);
    }
  };

  const InfoHandler = (id: any) => {
    setrowIds(id);
    oemmodal.open();
  };

  const getPlantIds = (id: any) => {
    plantpopup.open();

    setrowIds(id);
  };


  const handlechangeDown = (e: any) => {


    if (e?.key == "Enter") {
      if (searchvalue !== null) {


        setSearchkey(searchvalue);
      }
    }
  }

  const SearchHandler = () => {
    if (searchvalue !== null) {


      setSearchkey(searchvalue);
    }
  }

  const FilterModalHandler = () => {
    filterpopup.open()
  }

  const filterhandler = (filterkey: any, type: any, Oem: any, Oemcity: any, Oemstate: any, OemVehicle: any, city: any, name: any, state: any, vehicle: any) => {


    setOemtype(Oem)
    setOemcity(Oemcity)
    setOemstate(Oemstate)
    setOemVehicle(OemVehicle)
    setname(name)
    setcity(city)
    setstate(state)
    setvehicle(vehicle)

    setFilterKey(filterkey)
    setFilterType(type)
    //     if(type=="filter"){
    //         setLoader(true);
    //         API.post("/api/db/supplier_database_filter/",{...filterkey},{page:1,pageSize:30,filter:"oem"}).then((res:any)=>{
    //             setOEmData(res?.data)
    //             setLoader(false);
    //             }).catch((err:any)=>{
    //                 setLoader(false);
    //             })
    //     }
    //   else{
    //     setLoader(true);
    //     API.get(
    //       `/api/db/supplier/`,
    //       { is_oem: true, key: count - 1, pagenation: true,page_items:20 },
    //       0
    //     )
    //       .then((res: any) => {
    //         setLoader(false);
    //         setOEmData(res?.data);
    //       })
    //       .catch((err: any) => {
    //         setLoader(false);
    //       });
    //   }

  }



  useEffect(() => {
    {
      if (FilterType == "filter") {
        if (FilterTypecounter == 0) {

          setLoader(true);
          API.post("/api/db/supplier_database_filter/", { ...Filterkey }, { page: 1, pageSize: 30, filter: "oem" }).then((res: any) => {
            setOEmData(res?.data)
            setLoader(false);
          }).catch((err: any) => {
            setLoader(false);
          })
        } else {

          API.post("/api/db/supplier_database_filter/", { ...Filterkey }, { page: 1, pageSize: 30, filter: "oem" }).then((res: any) => {
            setOEmData(res?.data)
            setLoader(false);
          }).catch((err: any) => {
            setLoader(false);
          })
        }


      } if (FilterType == "remove") {
        setLoader(true);
        API.get(
          `/api/db/supplier/`,
          { is_oem: true, key: count - 1, pagenation: true, page_items: 20 },
          0
        )
          .then((res: any) => {
            setLoader(false);
            setOEmData(res?.data);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }
    }
  }, [Filterkey, FilterTypecounter])




  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  }

  const UploadFile = (e: any) => {

    const formData = new FormData();

    formData.append("file", e?.target?.files[0])
    setReverseUpload(true)
    API.post("/api/db/OEM_reverse_uploadView/", formData, { is_oem: true }).then((res: any) => [
      setReverseUpload(false)
    ]).catch((err: any) => {
      setReverseUpload(false)
    })

  }

  useEffect(() => {
    API.get("/auth/mui_trash_view/", { is_oem: false, bin_type: "oemdatabase", count: true }, 0).then((res: any) => {
      setRecyclebinItemCount(res?.data?.count)
    })
  }, [OemListCounter,SearchCount])
 


  const RecyleBinRoute=()=>{
   history.push("/oem/OEMrecycleBin")
  }
  return (
    <Box sx={{ height: { lg: '87vh', xl: '90vh' } }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      {oemmodal.isOpen && (
        <OEMInfomodal
          onCloseModal={oemmodal.close}
          isOpen={oemmodal.isOpen}
          rowIds={rowIds}
        />
      )}

      {plantpopup.isOpen && (
        <PlantModal
          onCloseModal={plantpopup.close}
          isOpen={plantpopup.isOpen}
          rowIds={rowIds}
        />
      )}



      <input

        id="PDF Upload"
        accept=".csv,.xlsx,.zip"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        ref={inputElRefPdf}
      />
      {/* { filterpopup.isOpen &&
    <FilterModalOem

    storedata={Filterkey}
    defaultOemstate={Oemstate}
    defaultOemVehicle={OemVehicle}
    defaultvehicle={vehicle}
    defaultstate={state}
    defaultOemType={OemType}
    defaultname={name}
    defaultOemcity={Oemcity}
    defaultcity={city}
    onCloseModal={filterpopup.close}
    isOpen={filterpopup.isOpen}
    filterhandler={filterhandler}
   />
} */}
      {loader == false && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: " auto 1rem",
            alignItems: 'center', columnGap: '1rem',
            paddingTop: '0.2rem',
          }}
        >
          <Button style={{ cursor: "pointer", minWidth: '1rem' }}>
            <AddIcon onClick={() => AddRow()} />
            {/* AddRow */}
          </Button>

          <TextField
            type="search"
            placeholder="Search"
            value={searchvalue}
            style={{ color: "#007fff", fontSize: "0.9rem" }}
            onKeyDown={(event: any) => handlechangeDown(event)}
            onChange={(event: any) => handlechange(event)}
            variant="standard"
            sx={{
              "& .MuiInput-underline:before": {
                borderBottomColor: 'primary.main'
              }
            }}
          />

          <SearchIcon sx={{ color: "#007fff", cursor: "pointer", marginLeft: '-1rem', fontSize: '2.3rem', borderBottom: '1px solid', }} onClick={() => SearchHandler()} />

          <Box sx={{width:'2.5rem'}}>
              {recyclebinItemCount && recyclebinItemCount > 0 ?
                <span title="Recycle bin" style={{ cursor: 'pointer',zIndex:1 }} onClick={()=>RecyleBinRoute()}>
                  <Badge badgeContent={`${recyclebinItemCount && recyclebinItemCount}`} color="error">
                    <img src={recyleBin} style={{ height: '1.6rem' }} />
                  </Badge>
                </span>
                : <DeleteOutlineIcon sx={{ color: "primary.main", fontSize: '2rem' }} />}
            </Box>
          {/* <FilterAltIcon sx={{ color: "#007fff", cursor: "pointer" }} onClick={()=>FilterModalHandler()}/> */}
          <LoadingButton
          sx={{minWidth:'1rem'}}
            loading={resverUpload}
          >
            <FileUploadIcon titleAccess="Upload" sx={{ cursor: "pointer" }} onClick={() => ReverseUpload()} />
          </LoadingButton>

        </div>
      )}


      {/* @ts-ignore */}
      <TableContainer style={styles.table}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Table aria-label="simple table" sx={
          { height: "20rem !important" }
        } stickyHeader={true}>
          <TableHead sx={{ backgroundColor: "primary.light !important" }}>

            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>SL.NO.</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>OEM</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>About Us</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>Contact Us</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>Product Category</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>Plant</TableCell>
            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', }}>Actions</TableCell>
          </TableHead>
          <TableBody>
            {loader ?
              <>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                  <TableCell sx={{ padding: '0.5rem', borderBottomColor: 'primary.light' }}><Skeleton></Skeleton></TableCell>
                </TableRow>

              </>

              :
              (OEmData?.length > 0 && OEmData && OEmData?.map((item: any, index: any) => {

                return (
                  <OEMlistRow width={width}  setFilterTypecounter={setFilterTypecounter} FilterType={FilterType} searchkey={searchvalue} setSearchCount={setSearchCount} getPlantIds={getPlantIds} InfoHandler={InfoHandler} setOemListCounter={setOemListCounter} rowItem={item} count={count-1} keys={index + 1} vehicleList={vehicleData && vehicleData} />

                )
              }))
            }
          </TableBody>

        </Table>
      </TableContainer>


      <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        columnGap: '0.5rem',
      }}>
        {searchvalue == null &&

          <>                <Button sx={{ cursor: "pointer", padding: '0.5rem', minWidth: '1rem' }} disabled={count !== 1 ? false : true}>
            <ChevronLeftIcon onClick={() => PrveCount()} />
          </Button>
            <Box sx={{ backgroundColor: 'primary.light', borderRadius: '50%', width: '2rem', height: '2rem', justifyContent: "center", alignItems: 'center', display: 'flex', }}>{count}</Box>

            <Button sx={{ cursor: "pointer", padding: '0.5rem', minWidth: '1rem' }} disabled={count <= PageCount - 1 ? false : true}>
              <ChevronRightIcon onClick={() => count <= PageCount && NextCount()} />
            </Button></>


        }
      </Box>
    </Box>
  );
};


export default OEMlist;
