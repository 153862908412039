// // react
// import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import { ID } from "../../../../../utlis/commonInterfaces";
import {
  UserInput,
  userInputSelectors,
} from "../../../../../Redux/Reducers/userInput.reducer";
import { Fragment, useEffect, useState } from "react";
import { RootState } from "../../../../../Redux/Store/storeConfigurations";
import { CALCULATOR_DATABASES_MAP } from "../../../../../Configuration/Costing/calculator.constant";
import { shallowDifference } from "../../../../../utlis/other";
import { updateUserInput } from "../../../../../Redux/Actions/userInput.actions";
import { useQueryDispatch } from "../../../../../CustomHook/useQueryDispatch";
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toExponentialResultParam } from "../../../../../utlis/toExponential";
import calcIcon from "../../../../../Assets/images/calculator.svg";
import update from "immutability-helper";

// // services
// // utils
import { useDatabaseSelectionModal } from "./DatabaseSelectionModal/useDatabaseSelectionModal";
import { useFlaggedAssumptionFileModal } from "./FlaggedAssumptionFileModal/useFlaggedAssumptionFileModal";
// import FlaggedAssumptionFileModal from './FlaggedAssumptionFileModal/FlaggedAssumptionFileModal';

// // styles
import styles from "./Calculator.module.scss";
import { inputCalculationSelectors } from "../../../../../Redux/Reducers/inputCalculation.reducer";
import {
  getInputCalculations,
  resetInputCalculations,
} from "../../../../../Redux/Actions/inputCalculation.actions";
import { LoadingButton } from "@mui/lab";
import {
  getFlaggedAssumptions,
  resetFlaggedAssumptions,
} from "../../../../../Redux/Actions/flaggedAssumption.actions";
import DatabaseSelectionModal from "./DatabaseSelectionModal/DatabaseSelectionModal";
import StrokeDatabaseModal from "./DatabaseSelectionModal/StrokeOptions/StrokeDatabaseModal";
import { useStrokeDatabaseModal } from "./DatabaseSelectionModal/StrokeOptions/useStrokeDatabaseModal";
import { useTableFieldRowSelectionModal } from "./TableFieldRowSelectionModal/useTableFieldRowSelectionModal";
import TableFieldRowSelectionModal from "./TableFieldRowSelectionModal/TableFieldRowSelectionModal";
import { useSelectedDatabaseRecordInfoModal } from "./CalculatorRow/SelectedDatabaseRecordInfoModal/useSelectedDatabaseRecordInfoModal";
import SelectedDatabaseRecordInfoModal from "./CalculatorRow/SelectedDatabaseRecordInfoModal/SelectedDatabaseRecordInfoModal";
import { useBaseModal } from "../../../../SearchPage/useBaseModal";
import swal from "sweetalert";
import { API } from "../../../../../api-services";
import ConstantmsgModal from "./ConstantmsgModal";
import React from "react";
import DatabaseCurrencySelectionModel from "./DatabaseSelectionModal/DatabaseCurrencySelectionModel";
import BOMTableCalculatorFetch from "./BOMTableCalculatorFetch";
import { useBaseParamsModal } from "../../../../../CustomHook/useBaseParamsModal";
import { isShowUserInputVal } from "../../CostingCalculatorConfig";
import { costingDetails } from "../../../../NewScenariosCostingCreate/ScenarioCalculatorModel";
import { useHistory } from "react-router-dom";
import FlaggedAssumptionFileModalC0nfig from "./FlaggedAssumptionFileModal/FlaggedAssumptionFileModalConfig";
import ScenarioCalculatorRowConfig from "./CalculatorRow/ScenarioCalculatorRowConfig";




type CalculatorItem = {
  id: number;
  calculator_id: number;
  sequence_id: string;
  type: string;
  ancestors: string[];
  parent_id: any;
  category: any;
};

interface CalculatorProps {
  /** active calculatorId(menu) */
  calculatorId: ID;
  CopyAliasHandler: any;
  setActive: any;
  status: any;
  handleSave?: any;
  calculators: any;
  handleChangeFinalformula: any;
  result: any;
  isWeightInput: any;
  costModelCalculatorId: any;
  setCurrentCalc: any;
  itemCategory: any;
  scenarioId?: any;
  condition?: any;
  tableId?: any;
  calcultorId?: any;
  conditionPopup?: any
  costEstimationId?: any
  HierarchyAllData?: any
  onCloseModal?: any
  parentId?: any
  revision_id?: any
  assumption?: any
  getScenarioTreeData?: any
  category_type?: any;
  costData?: any;
  refreshPArent?: any,
  userProfile?: any;
  PermissionDataa?: any
  reDirectToMainCosting?: any;
  CostingType?: any
}

const ScenarioCalculatorConfig: React.FC<CalculatorProps> = (props) => {
  const history = useHistory();
  const {
    calculatorId,
    setActive,
    handleSave,
    CopyAliasHandler,
    status,
    handleChangeFinalformula,
    result,
    calculators,
    isWeightInput,
    costModelCalculatorId,
    setCurrentCalc,
    itemCategory,
    scenarioId,
    condition,
    tableId,
    calcultorId,
    conditionPopup,
    costEstimationId,
    HierarchyAllData,
    onCloseModal,
    parentId,
    revision_id,
    assumption,
    getScenarioTreeData,
    category_type,
    costData,
    refreshPArent,
    PermissionDataa,
    userProfile,
    reDirectToMainCosting,
    CostingType
  } = props;
  const {
    vault,
    topVault,
    projectId,
    projectName,
    productId,
    abbrebation,
    cardtype,
    // tableId,
    // costEstimationId,
    tableCounter,
    // condition,
    calctype,
    // calcultorId,
    // scenarioId,
    // conditionPopup
  } = useRouteParams<any>();
  const costDetails = React.useContext(costingDetails);
  function findObjectAndNeighbors(
    data: CalculatorItem[],
    id: number,
    calculator_id: number
  ) {
    // Find the index of the item matching the id and calculator_id
    const index = data.findIndex(
      (item) => item.parent_id === id && item.calculator_id === calculator_id
    );

    // If not found, return null for all
    if (index === -1) return { current: null, prev: null, next: null };

    // Current, Previous, and Next items
    const current = data[index];
    const prev = index > 0 ? data[index - 1] : null;
    const next = index < data.length - 1 ? data[index + 1] : null;

    return { current, prev, next };
  }
  const isShowUserInput = React.useContext(isShowUserInputVal);
  const userInputShow = sessionStorage.getItem("userInput");

  const [Loader, setLoader] = useState<any>(false);
  const [MsgType, setMsgType] = useState<any>();
  const dispatch = useDispatch();
  const [CopyStatus, setCopyStatus] = useState<any>(false);
  const [testInput, setTestInput] = useState<any>(false);
  const [selectedRevision, setSelectedRevision] = useState(null);
  const [Counter, setCounter] = useState<any>(0);
  const inputCalculationIds: any = useSelector(
    inputCalculationSelectors.selectIds
  );
  const userInputt = useSelector((state: RootState) => state.userInputt);
  const [Defaultdata, setDefaultdata] = useState<any>([]);
  const [currencyDBExistCheck, setCurrencyDBExistCheck] = useState<any>();

  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getAssumptionList = () => {
    if (assumption) {
      API.get(`cost/assumption/`, { id: assumption })
        .then((res: any) => {
          const rev = res?.data?.find((item: any) => item?.id == assumption)
          setSelectedRevision(rev);

        })
        .catch((err: any) => {

        });
    }

  }

  useEffect(() => {
    getAssumptionList();
  }, [assumption])




  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const poid = open ? "simple-popover" : undefined;

  // const readonly = useCostingReadonly();

  // ----------------------------------------------------------------------------------
  // state

  const [data, setData] = useState<UserInput>();
  const [SelectedId, setSelectedId] = useState<any>([]);
  const [constantmsg, setConstantmsg] = useState<any>();
  const [statustracker, setStatustracker] = useState<any>(status);

  // ----------------------------------------------------------------------------------
  // selectors

  const userInput = useSelector((state: RootState) => {
    return userInputSelectors.selectByCalculatorId(state, calculatorId);
  });
  /** are just input ids */
  // @ts-ignore
  useEffect(() => {
    var filterdata: any = data && data?.constant_input;
    var obj: any = {};

    for (let key in filterdata) {
      if (filterdata[key] !== null) {
        obj[key] = filterdata[key];

        // console.log(`${key}: ${filterdata[key]}`);
      }
    }

    setSelectedId(data?.constant_input && Object?.keys(obj));
  }, [data, Counter]);
  // ----------------------------------------------------------------------------------
  // modal props

  const databaseModal = useDatabaseSelectionModal();
  const tableFieldRow = useTableFieldRowSelectionModal();
  // const calculatorResult = useCaclulatorResultSelectionModal();
  const databaseRecordInfo = useSelectedDatabaseRecordInfoModal();
  const flaggedAssumptionFile = useFlaggedAssumptionFileModal();
  const strokeDatabaseModal = useStrokeDatabaseModal();
  const canstantmodal = useBaseModal();
  const BOMtableCalculatorModal = useBaseParamsModal();
  const [ReplayLoader, setReplayLoader] = useState<any>(false);
  const [permissionroute, setpermissionroute] = useState<any>();
  const [calculatorData, setCalculatorData] = useState<any>();
  const [removeSwitch, setRemoveSwitch] = useState<any>();
  const [PermissionData, setPermissionData] = useState<any>();
  const [nextCalc, setNextCal] = React.useState<any>(null);
  const [revisionId, setRevisionId] = React.useState<any>(null);
  const [dbFetchCount, setDbFetchCount] = React.useState<any>(0);
  const [prevCalc, setPrevCal] = React.useState<any>(null);
  const [calcDataRedirect, setCalcDataRedirect] = React.useState<any>([]);

  const getExtraData = () => {
    API.get(
      "cost/get_next_previous_calculation_info/",
      {
        cost_estimation_id: costDetails?.cost_estimation,
      },
      0
    )
      .then((res: any) => {
        setCalcDataRedirect(res?.data);
        if (tableId != 0 && calcultorId != 0 && res.data.length > 0) {
          const { current, prev, next } = findObjectAndNeighbors(
            res.data,
            tableId,
            calcultorId
          );
          setNextCal(next);
          setPrevCal(prev);
          setCurrentCalc(current);
        }
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getExtraData();
  }, [condition, costDetails?.cost_estimation]);

  const getRevisionId = (id: any) => {


    setRevisionId(id)
  }


  React.useEffect(() => {
    if (tableId != 0 && calcultorId != 0 && calcDataRedirect.length > 0) {
      const { current, prev, next } = findObjectAndNeighbors(
        calcDataRedirect,
        tableId,
        calcultorId
      );
      setNextCal(next);
      setPrevCal(prev);
      setCurrentCalc(current);
    }
  }, [tableId, calcultorId, calcDataRedirect, condition]);

  // const strokeDataBaseModelInfo = useStrokeDatabaseRecordInfoModal();
  // // ----------------------------------------------------------------------------------
  // callbacks

  const onSelectDatabase = (record: any) => {
    let key = "id";

    /** save machine_id, labour_id, material_id instead of mhr, lhr & materiaCost id
     * so that records need not be re-assigned on assumption card change
     */
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.MACHINE)
      key = "machine_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.LABOUR)
      key = "labour_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.MATERIAL)
      key = "material_id";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.STROKE_RATE)
      key = "stroke_rate";
    if (databaseModal.database === CALCULATOR_DATABASES_MAP.CURRENCY)
      key = "RevisionCurrency";
    handleUserInputValueChangeDB(databaseModal.inputId)(record);
    setStatustracker(true);

    databaseModal.close();
    strokeDatabaseModal.close();
  };

  const onSelectTableFieldRow = (index: number) => {
    handleUserInputValueChangeDB(tableFieldRow.inputId)(index);
    tableFieldRow.close();
  };
  // const userProfilePic = useSelector(userSelectors.selectAll);



  // ----------------------------------------------------------------------------------
  // handlers

  useEffect(() => {
    setCalculatorData(calculators && calculators?.[0]);
  }, [calculators]);

  const handleChange = (e: any) => {
    setData((prev) =>
      Object.assign({}, prev, { [e?.target?.name]: e?.target?.value })
    );
    setStatustracker(true);
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setpermissionroute(res?.data);
      })
      .catch(() => {
        console.log("Srver Error");
      });
  }, [vault]);

  const getId = (id: any) => {
    setRemoveSwitch(id)
  }

  const ToggleSwitch = () => {
    API.get("/cost/cost_constant_message/", { calculation: data?.id })
      .then((res: any) => {
        var data2 = res?.data?.filter((item: any) => {
          return item?.input_field == removeSwitch;
        });
        API.delete(`/cost/cost_constant_message/${data2[0]?.id}/`)
          .then(() => {
            console.log("server Error");
          })
          .catch(() => { });
      })
      .catch(() => {
        console.log("Sever error");
      });

    setSelectedId((prev: any) =>
      prev.filter((item: any, index: any) => {
        return item !== removeSwitch;
      })
    );
  }






  const getToggleIds = (Ids: any, value: any, currentvalue: any, type: any) => {
    setStatustracker(true);
    if (data) {
      {
        const updated = update(data, {
          //@ts-ignore
          constant_input: {
            [Ids]: { $set: type ? data.constant_input[Ids] : null },
          },
        });
        setData(updated);
      }

      if (type == true) {
        canstantmodal.open();
        setConstantmsg(Ids);
        {
          API.get("/cost/cost_constant_message/", { calculation: data?.id })
            .then(() => { })
            .catch(() => { });
        }
        setMsgType("msg");
      } else {
        swal({
          title: "Remove Changes?",
          text: "We will delete existing value in this field and default constant value will come in this field!",
          buttons: ["Cancel", "Yes"],
          icon: "warning",
        }).then((confirm) => {
          if (confirm) {
            API.get("/cost/cost_constant_message/", { calculation: data?.id })
              .then((res: any) => {
                var data2 = res?.data?.filter((item: any) => {
                  return item?.input_field == Ids;
                });
                API.delete(`/cost/cost_constant_message/${data2[0]?.id}/`)
                  .then(() => {
                    console.log("server Error");
                  })
                  .catch(() => { });
              })
              .catch(() => {
                console.log("Sever error");
              });
          }

          if (confirm == null) {
            setCounter((prev: any) => prev + 1);
            const updated = update(data, {
                //@ts-ignore
                constant_input: {
                    [Ids]: { $set: data.constant_input[Ids] }
                }
            });

            setData(updated);
          }
        });
      }
      // @ts-ignore
    }
  };

  //   const refreshPArent = () => {
  // if (category_type == "material") {
  //       getScenarioTreeData("material", "update");
  //       getScenarioTreeData("header", "update");
  //     } else if (category_type == "process") {
  //       getScenarioTreeData("process", "update");
  //       getScenarioTreeData("header", "update");
  //     } else if (category_type == "overhead") {
  //       getScenarioTreeData("overhead", "update");
  //       getScenarioTreeData("header", "update");
  //     }
  //   }

  const handleUserInputValueChange = (inputId: ID, type?: any) => (value: any, typee?:any) => {
       setStatustracker(true);
    if (!data) return;
    const updated = update(data, {
      user_input: {
        [inputId]: { $set: value },
      },
      constant_input: typee === "constantt_input" ? {
        [inputId]: { $set: value },
      } : {},      
    });
    refreshPArent()
    setData(updated);



  };


  const handleUserInputValueChangeWithoutUpdate = (inputId: ID, type?: any) => (value: any) => {
    setStatustracker(true);
    if (!data) return;
    const updated = update(data, {
      user_input: {
        [inputId]: { $set: value },
      },
    });


    setData(updated);



  };

  const handleUserInputValueChangeDB = (inputId: ID) => async (value: any) => {
    setStatustracker(true);
    if (!data) return;
    const updated = update(data, {
      user_input: {
        [inputId]: { $set: value },
      },
    });

    // setData(updated);
    if (!updated || !HierarchyAllData) return;
    const result = shallowDifference(updated, HierarchyAllData);
    // setLoader(true);
    // update diff object
    const action: any = await dispatch(
      updateUserInput({
        id: costEstimationId,
        updates: result,
      })
    );

    if (updateUserInput.fulfilled.match(action)) {
      setLoader(false);

      setStatustracker(false);
      if (setDbFetchCount) {
        setDbFetchCount(dbFetchCount + 1);
      }

      // refreshPArent();

      //  onCloseModal();
      // history.push(
      //   `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}`
      // );
      handleSave && handleSave();
    }
    if (updateUserInput.fulfilled.match(action) == false) {
      refreshPArent();
      if (setDbFetchCount) {
        setDbFetchCount(dbFetchCount + 1);
      }

      setLoader(false);
      setStatustracker(true);
    }



    // fetch updated calculations to display in ui
    setActive(calculatorId);
  };

  const handleUserConstantValueChange = (inputId: ID) => (value: any) => {
    setStatustracker(true);

    if (!data) return;
    const updated = update(data, {
      constant_input: {
        [inputId]: { $set: value },
      },
    });

    setData(updated);
    // refreshPArent()
  };

  // console.log(data);

  const getoggleconst = () => { };

  const handleApply = async () => {
    if (!data || !HierarchyAllData) return;
    const result = shallowDifference(data, HierarchyAllData);
    setLoader(true);
    // update diff object
    const action: any = await dispatch(
      updateUserInput({
        id: costEstimationId,
        updates: result,
      })
    );

    if (updateUserInput.fulfilled.match(action)) {
      setLoader(false);

      setStatustracker(false);

      handleSave && handleSave();
    }
    if (updateUserInput.fulfilled.match(action) == false) {
      setLoader(false);
      setStatustracker(true);
    }

    // fetch updated calculations to display in ui
    setActive(calculatorId);
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        costing_create: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);

  // ----------------------------------------------------------------------------------
  // side effects


  useQueryDispatch({
    query: {
      action: getInputCalculations,
      arg: { userInputId: costEstimationId || 0, get_user_input: conditionPopup && conditionPopup == "true" ? true : false },
      condition: Boolean(HierarchyAllData),
    },
    cleanup: { action: resetInputCalculations, args: [] },
    dependency: [costEstimationId, conditionPopup, data?.result],
  });

  useQueryDispatch({
    query: {
      action: getFlaggedAssumptions,
      arg: { userInputId: costEstimationId || 0 },
      condition: Boolean(HierarchyAllData),
    },
    cleanup: { action: resetFlaggedAssumptions, args: [] },
    dependency: [costEstimationId],
  });

  // sync local data w/ redux
  useEffect(() => {
    if (userInput) setData(userInput);
  }, [userInput, Counter]);

  function CopyData(value: any) {
    setStatustracker(true);

    setCopyStatus(true);
    var modifyData = `val.${value}`;

    CopyAliasHandler(modifyData);
    copyToClipboard(value);
  }

  function copyToClipboard(text: any) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    dummy.setSelectionRange(0, 99999);
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(dummy.value);
    document.body.removeChild(dummy);
  }

  // ----------------------------------------------------------------------------------
  // jsx abstraction
  const Infohaandler = (ids: any) => {
    setMsgType("Info");
    canstantmodal.open();
    setConstantmsg(ids);
  };

  const openFetchBOMTableCalc = (tableId: any) => {
    BOMtableCalculatorModal.open(tableId);
  };

  const CostingConfigurationRoute = () => {
    var calIds =
      data && data?.calculator?.parent == null
        ? data?.calculator?.id
        : data?.calculator?.parent;

    setReplayLoader(true);
    API.get("/config/costing_configuration/", {
      project: productId,
      calulator_id: calIds,
    })
      .then((res: any) => {
        sessionStorage.setItem(
          "Costingconfigurationnode",
          JSON?.stringify(res?.data)
        );
        setReplayLoader(false);

        window.open(
          `/#/costingconfiguration/${projectId}/${projectName}/${parseInt(
            calIds
          )}/calculator/`
        );
      })
      .catch(() => {
        setReplayLoader(false);
      });
  };

  const renderRow = (inputCalculationId: ID) => {


    // if (!data) return null;

    // NOTE: inputCalculationId & inputId are same
    return (
      <ScenarioCalculatorRowConfig
        // key={ inputCalculationId }
        inputCalculationId={inputCalculationId}
        getToggleIds={getToggleIds}
        getId={getId}
        openDatabaseModal={databaseModal.open(inputCalculationId)}
        strokeDatabaseModal={strokeDatabaseModal.open(inputCalculationId)}
        openTable={tableFieldRow.open(inputCalculationId)}
        Defaultdata={Defaultdata}
        // openCalculatorResult={ calculatorResult.open( inputCalculationId ) }
        onValueChange={handleUserInputValueChange(inputCalculationId)}
        onValueChangeWthoutUpdate={handleUserInputValueChangeWithoutUpdate(inputCalculationId)}
        onConstValueChange={handleUserConstantValueChange(inputCalculationId)}
        userInputValue={data && data.user_input[inputCalculationId] !== undefined ? data.user_input[inputCalculationId] : 0}
        ConstantValue={data && data.constant_input[inputCalculationId]}
        constantIds={SelectedId}
        getoggleconst={getoggleconst}
        Infohaandler={Infohaandler}
        setSelectedId={setSelectedId}
        isWeightInput={isWeightInput}
        openDatabaseInfoModal={databaseRecordInfo.open}
        calculatorData={calculatorData}
        costModelCalculatorId={costModelCalculatorId}
        // openStrokeDatabaseInfoModal={ strokeDataBaseModelInfo.open }
        userInputId={data?.id ?? 0}
        openFlaggedAssumptionFileModal={flaggedAssumptionFile.open}
        setCurrencyDBExistCheck={setCurrencyDBExistCheck}
        openFetchBOMTableCalc={openFetchBOMTableCalc}
        dataOBj={data && data}
        handleSave={handleSave}
        PermissionData={PermissionData && PermissionData}
        revision_id={revision_id}
        getScenarioTreeData={getScenarioTreeData}
        category_type={category_type}
        userProfile={userProfile}
        PermissionDataa={PermissionDataa}
        CostingType={CostingType} />
    );
  };

  // ----------------------------------------------------------------------------------
  // jsx

  if (!HierarchyAllData) {
    return (
      !Loader ? <Box
        sx={{
          marginTop: "4rem",
          width: "100%",
          textAlign: "center",
          height: { lg: "65vh", xl: "75vh" },
        }}
      >
        <img src={calcIcon} style={{ height: "5rem" }} />
        <Typography>Select Cost Model</Typography>
      </Box> :
        <Box>
          <Skeleton variant="rectangular" width="100%" height="100vh" />
        </Box>
    );
  }


  return (
    <Fragment>
      {databaseModal.isOpen && databaseModal.database == 8 ? (
        <DatabaseCurrencySelectionModel
          isOpen={databaseModal.isOpen}
          onClose={databaseModal.close}
          revisiondata={databaseModal?.assumption ? databaseModal?.assumption : selectedRevision}
          database={databaseModal.database}
          onSubmit={onSelectDatabase}
          calInfo={databaseModal.CalData}
          calculatorId={calculatorId}
          userInput={userInput}
          revision_id={revision_id}
          getRevisionId={getRevisionId}
        />
      ) : (
        <DatabaseSelectionModal
          isOpen={databaseModal.isOpen}
          onClose={databaseModal.close}
          revisionId={databaseModal.revisionId ? databaseModal.revisionId : revisionId}
          revisiondata={databaseModal?.assumption ? databaseModal?.assumption : selectedRevision}
          database={databaseModal.database}
          calInfo={databaseModal.CalData}
          onSubmit={onSelectDatabase}
          calculatorId={calculatorId}
          userInput={userInput}
          itemCategory={itemCategory}
          revision_id={revision_id}
          getRevisionId={getRevisionId}
          dbFetchCount={dbFetchCount} setDbFetchCount={setDbFetchCount}
          assumptionList={PermissionDataa?.assumption_list}
          mainAssumption={PermissionDataa?.assumption}
        />
      )}

      <StrokeDatabaseModal
        isOpen={strokeDatabaseModal.isOpen}
        onClose={strokeDatabaseModal.close}
        project={projectId}
        onSubmit={onSelectDatabase}
        showTable={strokeDatabaseModal.showtable}
        selectedCard={strokeDatabaseModal.selectedCard}
        type={strokeDatabaseModal.type}
        path={strokeDatabaseModal.path}
      />
      <TableFieldRowSelectionModal
        isOpen={tableFieldRow.isOpen}
        onClose={tableFieldRow.close}
        inputType={tableFieldRow.inputType}
        parameterId={tableFieldRow.parameterId}
        onSubmit={onSelectTableFieldRow}
        selectedRowIndex={data?.user_input[tableFieldRow.inputId]}
      />
      <SelectedDatabaseRecordInfoModal
        isOpen={databaseRecordInfo.isOpen}
        onClose={databaseRecordInfo.close}
        database={databaseRecordInfo.database}
        recordId={databaseRecordInfo.recordId}
        userInput={userInput}
        assumptionn={selectedRevision}
        type="Scenario"
        reDirectToMainCosting={reDirectToMainCosting}
        assumptionListt={PermissionDataa?.assumption_list}
        mainAssumption={PermissionDataa?.assumption}

      />
      {canstantmodal.isOpen && (
        <ConstantmsgModal
          type={MsgType}
          onCloseModal={canstantmodal.close}
          isOpen={canstantmodal.isOpen}
          Item={data?.id}
          values={parseInt(constantmsg)}
          ToggleSwitch={ToggleSwitch}
        />
      )}
      {BOMtableCalculatorModal.isOpen && (
        <BOMTableCalculatorFetch
          onCloseModal={BOMtableCalculatorModal.close}
          isOpen={BOMtableCalculatorModal.isOpen}
          data={data}
          Item={BOMtableCalculatorModal.propsId}
          handleSave={handleSave}
        />
      )}

      <FlaggedAssumptionFileModalC0nfig
        isOpen={flaggedAssumptionFile.isOpen}
        onClose={flaggedAssumptionFile.close}
        flaggedAssumptionId={flaggedAssumptionFile.flaggedAssumptionId}
        userInputId={data?.id}
      />
      <div data-testid="calculator" className={styles.container}>
        <Box
          sx={{ height: { lg: "69vh", xl: "73vh" }, marginBottom: "0.1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={styles.tableHead}
                  sx={{ width: "2rem" }}
                ></TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "14rem" }}>
                  Parameter
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "10rem" }}>
                  Value
                </TableCell>
                <TableCell className={styles.tableHead} sx={{ width: "10rem" }}>
                  {" "}
                  <p></p>
                </TableCell>
                <TableCell
                  className={styles.tableHead}
                  sx={{
                    paddingLeft: { lg: "2rem", xl: "5rem" },
                    width: "6rem",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "7rem",
                    }}
                  >
                    <span> Unit</span>
                    {((permissionroute &&
                      permissionroute?.action?.includes("C")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("D")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("U")) ||
                      (permissionroute &&
                        permissionroute?.action?.includes("R"))) && userProfile?.[0]?.user_category !== "External" && (
                        <>
                          {ReplayLoader ? (
                            <CircularProgress size={"1rem"} color="inherit" />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: { lg: "1rem", xl: "2rem" },
                              }}
                            >
                              {/* <ReplyIcon
                              onClick={() => {
                                if(CostingType && CostingType == "main"){
                                  
                                } else{
                                  CostingConfigurationRoute();
                                }
                              } }
                              titleAccess="Go to Costing calculator"
                              sx={{
                                fontSize: "1.5rem",
                                transform: "scaleX(-1)",
                                cursor: (CostingType && CostingType == "main") ? "default" : "pointer",
                                color:(CostingType && CostingType == "main") ? "#d9dbdb" : "inherit"
                              }}
                            /> */}
                            </Box>
                          )}
                        </>
                      )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(inputCalculationIds) && inputCalculationIds?.length > 0 ?

                inputCalculationIds.map(renderRow)
                : <>
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                  <Skeleton sx={{ height: "2rem", width: "80rem" }} />
                </>

              }
            </TableBody>
            <TableFooter
              sx={{
                backgroundColor: "primary.light",
                position: "sticky",
                bottom: "-1px",
                zIndex: "1",
              }}
            >
              <TableRow>
                <TableCell className={styles.tableStyleFooter}> </TableCell>
                <TableCell className={styles.tableStyleFooter}>
                  Result
                </TableCell>
                <TableCell className={styles.tableStyleValue}>
                  <span className={styles.tableStyleFooterValue}>
                    {toExponentialResultParam(data?.result ?? 0)}
                  </span>
                </TableCell>
                <TableCell className={styles.tableStyleFooter}>
                  {((PermissionData && PermissionData?.action?.includes("C")) ||
                    (PermissionData && PermissionData?.action?.includes("U"))) && (<>
                      {<LoadingButton
                        
                        disabled={
                          statustracker == false && status == false ? true : false
                        }
                        loading={Loader}
                        size="small"
                        variant="contained"
                        sx={{
                          cursor: "pointer",
                          marginRight: "0.5rem",
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                        onClick={handleApply}
                      >
                        Apply
                      </LoadingButton>}</>
                    )}
                </TableCell>
                <TableCell className={styles.tableStyleFooter}>
                  {/* <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <ArrowCircleLeftIcon
              sx={{
                cursor: "pointer",
                opacity: prevCalc ? 1 : 0.5,
              }}
              onClick={() => {
                if (prevCalc) {
                  if(toExponentialResultParam(data?.result ?? 0) == 0){
                    Swal.fire({
                      title: "Are you sure?",
                      text: "Cost Model final result is zero, do you want to proceed for the previous cost model?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result: any) => {
                      if (result.isConfirmed == true) {
                        sessionStorage.setItem(
                          "Costingconfigurationnodeconfig",
                          JSON.stringify(prevCalc?.ancestors)
                        );
                        onCloseModal()
                        // history.push(
                        //   `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${prevCalc?.id}/${prevCalc?.parent_id}/${costEstimationId}/${tableCounter}/${condition}/${prevCalc?.category}/${prevCalc?.calculator_id}/${scenarioId}/${conditionPopup}/true`
                        // );
                        // handleApply()
                      }
                    });
                  }else{
                    sessionStorage.setItem(
                      "Costingconfigurationnodeconfig",
                      JSON.stringify(prevCalc?.ancestors)
                    );
                    onCloseModal()
                    // history.push(
                    //   `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${prevCalc?.id}/${prevCalc?.parent_id}/${costEstimationId}/${tableCounter}/${condition}/${prevCalc?.category}/${prevCalc?.calculator_id}/${scenarioId}/${conditionPopup}/true`
                    // );
                  }
                  
                }
              }}
            />{" "}
            CM{" "}
            <ArrowCircleRightIcon
              sx={{
                cursor: "pointer",
                opacity: nextCalc ? 1 : 0.5,
              }}
              onClick={() => {
                if (nextCalc) {
                  if(toExponentialResultParam(data?.result ?? 0) == 0){
                    Swal.fire({
                      title: "Are you sure?",
                      text: "Cost Model final result is zero, do you want to proceed for the next cost model?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result: any) => {
                      if (result.isConfirmed == true) {
                        sessionStorage.setItem(
                          "Costingconfigurationnodeconfig",
                          JSON.stringify(nextCalc?.ancestors)
                        );
                        onCloseModal();
                        // history.push(
                        //   `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${nextCalc?.id}/${nextCalc?.parent_id}/${costEstimationId}/${tableCounter}/${condition}/${nextCalc?.category}/${nextCalc?.calculator_id}/${scenarioId}/${conditionPopup}/true`
                        // );
                        // handleApply()
                      }
                    });
                  }else{
                    sessionStorage.setItem(
                      "Costingconfigurationnodeconfig",
                      JSON.stringify(nextCalc?.ancestors)
                    );
                    onCloseModal();
                    // history.push(
                    //   `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${nextCalc?.id}/${nextCalc?.parent_id}/${costEstimationId}/${tableCounter}/${condition}/${nextCalc?.category}/${nextCalc?.calculator_id}/${scenarioId}/${conditionPopup}/true`
                    // );
                  }
                  
                }
              }}
            />
          </Box> */}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>


      </div>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------------

export default ScenarioCalculatorConfig;