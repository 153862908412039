import { Box, Checkbox, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import styles from './IdeaCreatepage.module.scss';
import rightArrow from '../../Assets/images/rightArrows.svg';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import IdeaCreateFormModal from './IdeaCreateForm/IdeaCreateFormModal';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';
import IdeaGraphModal from "./IdeaGraphModal";
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import Tooltip from '@mui/material/Tooltip';
import { UserAction } from './IdeaCreateLandingpage';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../Redux/Reducers/user.reducer';
import { ADMIN } from '../../Redux/Services/admin.service';


interface Params {
    projectId: any;
    projectName: any;
    topVault: any;
}

export interface IIdeacreatepageProps { }

export default function Ideacreatepage(props: IIdeacreatepageProps) {
    const { projectId, projectName, topVault } = useRouteParams<Params>();
    // const {topVault} = useRouteParams<any>()
    const IdeaFormModal = useBaseParamsModal();
    const IdeaGraph = useBaseModal();
    const [ideaTableData, setIdeaTableData] = React.useState<any>([]);
    const [subSystem, setSubSystem] = React.useState<any>([]);
    const [subSystemId, setSubSystemId] = React.useState<any>();
    const [abbrev, setAbbrev] = React.useState<any>();
    const [loading, setLoading] = React.useState<any>(true);
    const PermissionAction = React.useContext<any>(UserAction)
    const [checked, setChecked] = React.useState<any>([]);
    const IdeaFormHandler = (selectedsubSystem: any, subSystemId: any, item: any) => {
        setSubSystem(selectedsubSystem);
        history.push(`/idea-generation/${projectId}/${projectName}/${topVault}/${typeof item?.id === "number" ? item?.id : 0}/0/0`)
        IdeaFormModal.open(item);
    }
    const history = useHistory();
    const getTableData = () => {
        setLoading(true);
        API.get("/idea/idea_system_dashboard/", {
            top_vault: topVault
        }, 0).then((res: any) => {
            let FilterDashboardStatus=res?.data?.filter((item:any)=>{return item?.dashboard_status==true})?.map((item1:any,index1:any)=>{return item1?.id})
            setLoading(false);
            setChecked(FilterDashboardStatus)
            setIdeaTableData(res.data)
        }).catch((err: any) => {
            setLoading(false);
            console.log(err)
        })
    }
    React.useEffect(() => {
        getTableData()
    }, [topVault])


    console.log(checked,"FilterDashboardStatusFilterDashboardStatus")
    const IdeaViewPageHandler = (subSystem: any, subSystemId: any, item: any) => {
        history.push(`/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/0/0/${subSystemId}/0/0/0/0`)
    }


    const AutogenerateHandler = (subSystem: any, abbrevID: any) => {
        history.push(`/idea-auto-generation/${projectId}/${projectName}/${topVault}/${subSystem}/${abbrevID}/3/0/0`)
    }


const queries=(item:any,status:any)=>{

    
    if (item?.abbreviation=="PRODUCT"){
        return {top_vault:item?.id,status:status}
    }else{
        return {vault_info:item?.id,status:status}
    }
}



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>,id:any) => {
         if(event?.target?.checked==true){
            API.post("/idea/idea_submission_status/",{...queries(id,true)},{},0).then((res:any)=>{
                console.log(res.data,"datattatatat")
                 setChecked([...checked,id?.id])
                ADMIN.toast.success("System/Product updated successfully.")
            }).catch((err:any)=>{
                ADMIN.toast.error("Server Error")
            })
         }
         else if (event?.target?.checked==false){
            API.post("/idea/idea_submission_status/",{...queries(id,false)},{},0).then((res:any)=>{
                ADMIN.toast.success("System/Product updated successfully.")
                setChecked((prev:any)=>prev?.filter((item:any)=>item!==id?.id))
            }).catch((err:any)=>{
                ADMIN.toast.error("Server Error")
            })
         }


  
        // setChecked(event.target.checked);
    };
    const [productList, setProductList] = React.useState<any>([]);

    const handleDashboardRedirection = () => {
        const url = `/#/idea_dashboard/${projectId}/${topVault}`
        window.open(url, '_blank');
    }

    const handleGraph = (item: any) => {
        setSubSystem(item?.name);
        setAbbrev(item?.abbreviation);
        IdeaGraph.open();
    }

    const userProfilePic = useSelector(userSelectors.selectAll);
    let useraction = PermissionAction && PermissionAction?.action?.includes("C")


    return (
        <Box
            sx={{
                width: '100%',
                height: { xs: "87vh", lg: "86vh", xl: "90vh" },
                paddingRight: "0.5rem",
                // overflow: { xs: "auto", lg: "visible", xl: "visible" },
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {IdeaFormModal.isOpen && (
                <IdeaCreateFormModal
                    onCloseModal={IdeaFormModal.close}
                    isOpen={IdeaFormModal.open}
                    subSystem={subSystem}
                    subSystemId={IdeaFormModal.propsId}
                    getTableData={getTableData}
                />
            )}
            {
                IdeaGraph.isOpen && (
                    <IdeaGraphModal
                        onCloseModal={IdeaGraph.close}
                        isOpen={IdeaGraph.open}
                        subSystem={subSystem}
                        abbrev={abbrev}
                    />
                )
            }

            <Table className={styles.landTable} stickyHeader aria-label="sticky table" sx={{ border: '1px solid #007fff !important', borderTop: 'none !important' }}>
                <TableHead sx={{ borderBottom: '1px solid #007fff !important' }}>
                    <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid', backgroundColor: 'primary.main', borderRight: '1px solid', }} colSpan={2} rowSpan={2}><Box className={styles.columnDash} onClick={handleDashboardRedirection}>Dashboard <InsightsIcon sx={{ cursor: 'pointer' }} /></Box></TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', }} colSpan={2}>Initiated</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', backgroundColor: '#ffe699' }} colSpan={2}>In-Review</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', backgroundColor: '#bbdbf7' }} colSpan={2}>Submitted</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', backgroundColor: '#7afba9' }} colSpan={2}>Accepted</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', backgroundColor: '#fbe5d6' }} colSpan={2}>Rejected by ASI</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', borderTop: '1px solid #007fff', backgroundColor: '#eb9ba0', color: '#bf141f' }} colSpan={2}>Rejected by Client</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={styles.columnHeaderCount} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} align='right'>Savings Value</TableCell>
                        <TableCell className={styles.columnHeaderCount} sx={{ backgroundColor: '#ffe699' }} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} sx={{ backgroundColor: '#ffe699' }} align='right'>Savings Value</TableCell>
                        <TableCell className={styles.columnHeaderCount} sx={{ backgroundColor: '#bbdbf7' }} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} sx={{ backgroundColor: '#bbdbf7' }} align='right'>Savings Value</TableCell>
                        <TableCell className={styles.columnHeaderCount} sx={{ backgroundColor: '#7afba9' }} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} sx={{ backgroundColor: '#7afba9' }} align='right'>Savings Value</TableCell>
                        <TableCell className={styles.columnHeaderCount} sx={{ backgroundColor: '#fbe5d6' }} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} sx={{ backgroundColor: '#fbe5d6' }} align='right'>Savings Value</TableCell>
                        <TableCell className={styles.columnHeaderCount} sx={{ backgroundColor: '#eb9ba0', color: '#bf141f' }} align='center'>Count</TableCell>
                        <TableCell className={styles.columnHeaderValue} sx={{ backgroundColor: '#eb9ba0', color: '#bf141f' }} align='right'>Savings Value</TableCell>
                    </TableRow>
                </TableHead>
                {loading ? <TableBody>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"} /></TableCell>
                    </TableRow>
                </TableBody> : <TableBody>
                    {<TableRow sx={{ ':hover': { backgroundColor: 'aliceblue' } }}>
                        <TableCell className={styles.columnPart}>
                            <Box className={styles.columnSubsytem}>
                                {ideaTableData[0]?.abbreviation} <BarChartIcon sx={{ cursor: 'pointer' }} titleAccess='Graph' onClick={() => handleGraph(ideaTableData?.[0])} />
                            </Box>
                        </TableCell>
                        <TableCell className={styles.columnPart1} align='center'>Total</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[0]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[0]?.value}</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[1]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[1]?.value}</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[2]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[2]?.value}</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[3]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[3]?.value}</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[4]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[4]?.value}</TableCell>
                        <TableCell className={styles.columnCount} sx={{ backgroundColor: 'primary.light' }} align='center'>{ideaTableData[0]?.data[5]?.count}</TableCell>
                        <TableCell className={styles.columnValue} sx={{ backgroundColor: 'primary.light' }} align='right'>{ideaTableData[0]?.data[5]?.value}</TableCell>
                    </TableRow>}
                    {ideaTableData && ideaTableData?.filter((item: any, index: any) => index !== 0)?.map((item: any, index: any) => {
                        return <TableRow>
                            <TableCell className={styles.columnPart}>
                                <Box className={styles.columnSubsytem}>
                                    <Tooltip placement="right-start" title={item?.name}><span>{item?.abbreviation}</span></Tooltip>

                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: "0.5rem",
                                    }}>

                                        { (userProfilePic && userProfilePic[0]?.is_superuser &&  item?.show_check_box==true) &&
                                        <Checkbox
                                            checked={checked?.includes(item?.id)}
                                            onChange={(e:any)=>handleChange(e,item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size='small'
                                            title={`${' Dashboard Status - No idea to be generated'}`}
                                      
                                            sx={{ color: "#007fff",padding:'3px' }}
                                        />
                    }

                                        <BarChartIcon sx={{ cursor: 'pointer' }} titleAccess='Graph' onClick={() => handleGraph(item)} />
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell className={styles.columnPart1}>
                                <Box className={styles.columnSubsytem}>
                                    <img src={rightArrow} style={{ height: '1.5rem', cursor: 'pointer' }} onClick={() => IdeaViewPageHandler(item?.abbreviation, item?.id, item)} />
                                    {useraction && useraction &&
                                        <AddIcon titleAccess='Add Idea' sx={{ cursor: 'pointer' }} onClick={() => IdeaFormHandler(item?.abbreviation, item?.id, item)} />}
                                    {item?.abbreviation != "PRODUCT" ?
                                        <AutoAwesomeIcon titleAccess='Auto Generate Ideas' sx={{ cursor: 'pointer' }} onClick={() => AutogenerateHandler(item?.abbreviation, item?.id)} /> :
                                        <AutoAwesomeIcon sx={{ cursor: 'default', opacity: 0 }} />}
                                </Box>
                            </TableCell>
                            <TableCell className={styles.columnCount} align='center'>{item?.data[0]?.count}</TableCell>
                            <TableCell className={styles.columnValue} align='right'>{item?.data[0]?.value}</TableCell>
                            <TableCell className={styles.columnCount} sx={{ backgroundColor: '#ffe699' }} align='center'>{item?.data[1]?.count}</TableCell>
                            <TableCell className={styles.columnValue} sx={{ backgroundColor: '#ffe699' }} align='right'>{item?.data[1]?.value}</TableCell>
                            <TableCell className={styles.columnCount} sx={{ backgroundColor: '#bbdbf7' }} align='center'>{item?.data[2]?.count}</TableCell>
                            <TableCell className={styles.columnValue} sx={{ backgroundColor: '#bbdbf7' }} align='right'>{item?.data[2]?.value}</TableCell>
                            <TableCell className={styles.columnCount} sx={{ backgroundColor: '#7afba9' }} align='center'>{item?.data[3]?.count}</TableCell>
                            <TableCell className={styles.columnValue} sx={{ backgroundColor: '#7afba9' }} align='right'>{item?.data[3]?.value}</TableCell>
                            <TableCell className={styles.columnCount} sx={{ backgroundColor: '#fbe5d6' }} align='center'>{item?.data[4]?.count}</TableCell>
                            <TableCell className={styles.columnValue} sx={{ backgroundColor: '#fbe5d6' }} align='right'>{item?.data[4]?.value}</TableCell>
                            <TableCell className={styles.columnCount} sx={{ backgroundColor: '#eb9ba0', color: '#bf141f' }} align='center'>{item?.data[5]?.count}</TableCell>
                            <TableCell className={styles.columnValue} sx={{ backgroundColor: '#eb9ba0', color: '#bf141f' }} align='right'>{item?.data[5]?.value}</TableCell>
                        </TableRow>
                    })}

                </TableBody>}
            </Table>

        </Box>
    );
}