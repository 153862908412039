import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { getVideoCover } from "../../CustomHook/CreateVideoThumbnail";
import { ADMIN } from "../../Redux/Services/admin.service";
import { s3Url } from "../../utlis/s3url.utils";
import { uploadRepoImages } from "../../Redux/Actions/imageRepository.actions";


const mediaUpload = (
   fileList: any,
   group: any,
   top_vault: any,
   vault: any,
   dispatch: any,
   imageCategory: any,
   onClose: any,
   setIsLoading: any,
   mediaType: any,
   abbreviation: any,
   projectId: any,
   category:any,
   groupId:any,
   productList:any,
   pageNumber:any,
   apiCall:any,
   projectName:any,
   history:any,
   callGroupAPI:any,
   setCallGroupAPI:any,
   checked2?:any
) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );
   var obj: Array<any> = [];
   const sortAlphaNum = ( a: any, b: any ) => a.localeCompare( b, 'en', { numeric: true } );
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
   API.get( "cad/cad_upload/", {
      module: MODULES.CAD,
      product: top_vault == 0 ? vault : top_vault,
   } ).then( res => {

      const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
         const formData = new FormData();
         formData.append(
            "key",
            `${group
            }/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name}`
         );
         formData.append( "Content-Type", file?.type );
         formData.append( "x-amz-server-side-encryption", "AES256" );
         formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
         formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
         formData.append( "X-Amz-Date", res.data.data.x_amz_date );
         formData.append(
            "X-Amz-Signature",
            res.data.data.aws_policy_signature
         );
         formData.append( "Policy", res.data.data.aws_policy );
         formData.append( "file", file, file?.name );

         axios
            .post( s3Url, formData, {
               onUploadProgress: data => {
                  //Set the progress value to show the progress bar
               },
            } )
            .then( async ( res: any ) => {
               if ( res.status === 204 ) {
                  if ( mediaType === "video" ) {
                     try {
                        // get the frame at 1.5 seconds of the video file
                        const cover = await getVideoCover( file, 1.5 );
                        //@ts-ignore
                        formData.set( "file", cover, cover?.name );
                        formData.set(
                           "key",
                           `${group
                           }/${top_vault}/${vault ? vault : "Vehicle"}/${random}/thumb/${file.name.replace(
                              ".mp4",
                              ".jpeg"
                           )}`
                        );
                        formData.set( "Content-Type", "image/jpeg" );
                        axios.post( s3Url, formData );
                        // print out the result image blob
                     } catch ( ex ) {
                       return ADMIN?.toast?.error(`${file?.name}`);
                     }
                  }

                  if ( mediaType === "video" ) {
                     let customObj: any = {
                        image: `/${group
                           }/${top_vault}/${vault ? vault : "Vehicle"
                           }/${random}/${file.name}`,
                        thumbnail: `/${group
                           }/${top_vault}/${vault ? vault : "Vehicle"
                           }/${random}/thumb/${file.name.replace(
                              ".mp4",
                              ".jpeg"
                           )}`,
                     };
                     obj.push( customObj );
                  } else {
                     console.log("hi");
                     
                     obj.push(
                        `/${group
                        }/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name}`
                     );
                  }

                  if ( obj.length === fileList.length ) {
                     await dispatch(
                        uploadRepoImages( {
                           images: mediaType === "video" ? obj : obj.sort( sortAlphaNum ),
                           topVaultId: top_vault,
                           vaultId: vault,
                           imageCategory: +imageCategory,
                           rgb: false,
                           media_type: mediaType,
                           group: group,
                           is_mute : mediaType === "video" ? checked2 : null
                        } )
                     ).then( ( res: any ) => {
                        setCallGroupAPI(!callGroupAPI)
                        history.push(
                           `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
                         );       
                        onClose();
                        setIsLoading( false );
                     })
                  }
               }
            } ).catch((err)=>{return ADMIN?.toast?.error(`Files not uploaded are : ${file?.name}`);});
      } );
   } );
};

// ----------------------------------------------------------------------------------

export default mediaUpload;
