import React, { useRef, useState } from "react";
import { debounce } from "lodash";
import styles from "../../ComponentUI/Header/Header.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { API } from "../../api-services";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

function ScenarioSearch({ viewSwitchInput }: any) {
  const descRef = useRef<any>();
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const {
    vault,
    topVault,
    projectId,
    projectname,
    projectName,
    productId,
    abbrebation,
    cardtype,
    tableCounter,
    condition,
    calctype,
    scenarioId,
    costEstimationId,
    treeabbr,
    newUI,
    scenarioType,
  } = useRouteParams<any>();
  const ExactDebounce = React.useRef(
    debounce(async (criteria: string) => {
      if (!criteria) {
        setSearchResults([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const queryParams: any = { search_query: criteria };
      if (!viewSwitchInput?.vault) {
        // Add `top_vault` if `vault` is null
        queryParams.top_vault = viewSwitchInput?.top_vault;
      } else {
        // Add `vault` if `vault` is not null
        queryParams.vault = viewSwitchInput?.vault;
      }

      try {
        const response = await API.get("/cost/scneario_list_data_view/", queryParams);
        setSearchResults(response.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 500)
  ).current;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    setSearchResults([]); // Clear previous results while typing
    ExactDebounce(value.split("(").shift() || "");
  };

  // console.log(searchResults);


  return (
    <Box sx={{ position: "relative", width: { lg: '13rem', xl: "18rem" } }}>
      <TextField
        variant="standard"
        type="search"
        placeholder="Search Part"
        value={selectedValue}
        onChange={handleOnChange}
        onFocus={() => {
          if (selectedValue) {
            ExactDebounce(selectedValue.split("(").shift() || "");
          }
        }}
        ref={descRef}
        sx={{
          width: "100%",
          fontSize: "0.9rem",
          "& .MuiInputBase-input": {
            color: "#007fff",
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "1rem", // Reduce the placeholder font size
          },
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: '1rem !important'
          },
          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
          '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'primary.main', fontSize: "1.35rem", marginTop: "0.2rem" }} />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "100%",
          left: 0,
          right: 0,
          maxHeight: "20rem",
          overflowY: "auto",
          // border: "1px solid #f2f2f2",
          bgcolor: "#fff",
          zIndex: 10,
          width: "150%",
        }}
      >
        {!loading && searchResults?.length > 0 ? (
          searchResults.map((item: any, index: number) => (
            <Box
              key={index}
              className={styles.innserSearchSubText}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,

                cursor: "pointer",
                "&:hover": {
                  bgcolor: "#f9f9f9",
                },
              }}
              onClick={() => {
                window.open(
                  `/#/costingscenario/${projectId}/${projectName}/${item?.topvault_id}/${item?.id}/${scenarioType}/${item?.abbreviation ? item?.abbreviation : "none"}/${scenarioId}`
                );
              }}
            >
              <Typography variant="body2" sx={{ textDecoration: "underline" }}>
                {item?.name}
              </Typography>
              <SearchIcon />
            </Box>
          ))
        ) : (
          !loading && selectedValue && (
            <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
              No Part Found
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
}

export default ScenarioSearch;

