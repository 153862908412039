import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js";
import LetSuspense from "../../../LetSuspense";
import BouncingLoader from "../../../ui-reusable-component/BouncingLoader";
import styles from "./CircleComponent.module.scss";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import IdeaShowModal from "./IdeaShowModal/IdeaShowModal";
import { numberFormat } from "../../../utlis/valueFormat";
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';

const Plot = createPlotlyComponent(Plotly);
interface Params {
  projectId: any;
  topVaultId: any;
}
interface CircleComponentI {
}
const CircleComponent: React.FC<CircleComponentI> = (props) => {
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [scatterIdeaData, setScatterIdeaData] = useState<any>();
  const [scatterIdeaDataApiLoader, setScatterIdeaDataApiLoader] =
    useState<boolean>(false);
  const [ideaBubbleData, setIdeaBubbleData] = useState<any>({
    arrX: [],
    arrY: [],
    textData: [],
    bubbleBorder: [],
    bubbleBorderSize: [],
    bubbleSize: [],
    bubbleColor: [],
    maxCost: 0,
  });
  const [bubbleInfo, setBubbleInfo] = useState<any>({});
  const priorityMatrixModal = useBaseModal();
  const [maxValue, setMaxvalue] = useState<any>({
    maxValue: 1,
    maxCost: 1,
  });
  const { height, width } = useWindowDimensions();


  useEffect(() => {
    API.get(
      `/customer/idea_data/`,
      {
        top_vault_id: topVaultId,
        idea_dashboard: "graph",
      },
      0
    ).then((res: any) => {
      setScatterIdeaDataApiLoader(true);
      setScatterIdeaData(res?.data);
    });
  }, [topVaultId]);
  const filterData = (ideas: any) => {
    const bubbleColors: Array<any> = [];
    const bubbleTimeData: Array<any> = [];
    const bubbleEaseData: Array<any> = [];
    const bubbleCost: Array<any> = [];
    const bubbleBorder: Array<any> = [];
    const bubbleBorderSize: Array<any> = [];
    const textData: Array<any> = [];
    if (scatterIdeaData?.length !== 0) {
      scatterIdeaData?.map((ele: any) => {
        bubbleTimeData.push(ele?.days_of_implementation);
        bubbleEaseData.push(ele?.ease_of_implementation);
        bubbleCost.push(ele?.cost_of_implementation);
        if (ele?.idea_status == "Rejected") {
          bubbleColors.push("red");
          bubbleBorder.push("red");
          textData.push( `Time: ${ele?.days_of_implementation} Days <br> Ease: ${ele?.ease_of_implementation}/10 <br> Cost Saving: ${numberFormat( ele?.cost_of_implementation )}` );
        } else if (ele?.idea_status == "Submitted") {
          bubbleColors.push("#007fff");
          bubbleBorder.push("#007fff");
          textData.push(
            `Time: ${ele?.days_of_implementation} Days <br> Ease: ${ele?.ease_of_implementation}/10 <br> Cost Saving: ${numberFormat( ele?.cost_of_implementation )}`
          );
        } else if (ele?.idea_status == "Accepted") {
          bubbleColors.push("#a3c182");
          bubbleBorder.push("#a3c182");
          textData.push(
            `Time: ${ele?.days_of_implementation} Days <br> Ease: ${ele?.ease_of_implementation}/10 <br> Cost Saving: ${numberFormat( ele?.cost_of_implementation )}`
          );
        }
        bubbleBorderSize.push(2);
      });
    }
    setIdeaBubbleData((data: any) => ({
      ...data,
      arrX: bubbleTimeData,
      bubbleBorder: bubbleBorder,
      textData: textData,
      bubbleBorderSize: bubbleBorderSize,
      arrY: bubbleEaseData,
      bubbleSize: bubbleCost,
      bubbleColor: bubbleColors,
    }));
  };
  const findMax = (ideas: any, type: string) => {
    let maxTime: number = 1;
    let maxtCost: number = 1;
    if (type === "time") {
      ideas?.map((ele: any) => {
        if (maxTime < ele?.days_of_implementation) {
          maxTime = ele?.days_of_implementation;
        }
      });
      return maxTime;
    }
    if (type === "cost") {
      ideas?.map((ele: any) => {
        if (maxTime < ele?.cost_of_implementation) {
          maxtCost = ele?.cost_of_implementation;
        }
      });
      return maxtCost;
    }
  };
  useEffect(() => {
    if (scatterIdeaData?.length > 0) {
      const maxTime = findMax(scatterIdeaData, "time");
      const maxCost = findMax(scatterIdeaData, "cost");
      const max = {
        maxTime: maxTime,
        maxCost: maxCost,
      };
      setMaxvalue({ ...max });
      filterData(scatterIdeaData);
    }
  }, [scatterIdeaData]);
  const handleClick = (e: any) => {
    const point = {
      ease: e.points[0].y,
      duration: e.points[0].x,
    };
    setBubbleInfo({ ...point });
    priorityMatrixModal.open();
  };
  return (
    <LetSuspense
      loadingPlaceholder={BouncingLoader}
      condition={scatterIdeaDataApiLoader}>
      
      <IdeaShowModal
        isOpen={priorityMatrixModal.isOpen}
        onClose={priorityMatrixModal.close}
        bubblePoints={bubbleInfo}
        scatterIdeaData={scatterIdeaData && scatterIdeaData}
      />
      <div id="bubbleChart" className={styles?.bubbleCharHolder}>
        <Plot
          style={{ width: "100%", height: width > 1440 ? "800px" : '570px',padding:'1rem'}}
          useResizeHandler={true}
          data={[
            {
              x: ideaBubbleData?.arrX,
              y: ideaBubbleData?.arrY,
              text: ideaBubbleData?.textData,
              mode: "markers",
              type: "scatter",
              opacity: 0.9,
              marker: {
                size: ideaBubbleData?.bubbleSize,
                sizeref: Math.floor((2 * maxValue.maxCost) / 18 ** 2),
                sizemode: "area",
                sizemin: 2,
                color: ideaBubbleData?.bubbleColor,
                line: {
                  color: ideaBubbleData?.bubbleBorder,
                  width: ideaBubbleData?.bubbleBorderSize,
                },
              },
            },
            {
              x: [maxValue.maxTime / 2, maxValue.maxTime / 2],
              y: [0, 10],
              hoverinfo: "skip",
              mode: "lines",
              opacity: 0.5,
              name: "dot",
              line: {
                dash: "dot",
                width: 1,
              },
              marker: {
                color: "#007fff",
                size: 12,
              },
            },
            {
              x: [0, maxValue.maxTime],
              y: [5, 5],
              hoverinfo: "skip",
              mode: "lines",
              opacity: 0.5,
              name: "dot",
              line: {
                dash: "dot",
                width: 1,
              },
              marker: {
                color: "#007fff",
                size: 12,
              },
            },
          ]}
          layout={{
            font: { size: 10 },
            xaxis: {
              title: {
                text: "Time Of Implementation (In Days)",
              },
              fixedrange: false,
              showgrid: false,
              color: "#007fff",
            },
            yaxis: {
              title: {
                text: "Ease Of Implementation (Difficulty)",
              },
              showgrid: false,
              color: "#007fff",
              range: [0, 10],
            },
            autosize: true,
            showlegend: false,
            hovermode: "closest",
            //@ts-ignore                    align: "right",
            margin: {
              l: 70,
              t: 10,
              r: 10,
              b: 95,
            },
          }}
          config={{
            responsive: true,
            modeBarButtonsToRemove: [
              "zoom2d",
              "pan2d",
              "select2d",
              "lasso2d",
              "zoomIn2d",
              "zoomOut2d",
              "autoScale2d",
              "resetScale2d",
              "hoverClosestGl2d",
              "hoverClosestPie",
              "toggleHover",
              "resetViews",
              "sendDataToCloud",
              //@ts-ignore                            "resetViewMapbox",
              "hoverClosestCartesian",
              "hoverCompareCartesian",
              "toggleSpikelines",
              "toImage",
            ],
            displaylogo: false,
          }}
          onClick={handleClick}
        />
      </div>
    </LetSuspense>
  );
};
export default CircleComponent;
