
import React, { useRef, useState } from 'react';
import scanner from '../../Assets/images/scanner.png';
import { Box, Dialog, DialogContent, Divider, IconButton, Slide, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from '@mui/icons-material/Cancel';
import { TransitionProps } from '@mui/material/transitions';
import { QrReader } from "react-qr-reader";
import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../../api-services';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import SearchIcon from "@mui/icons-material/Search";
import styles from "./CostingCreateScannerPage.module.scss";
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface Params {
  projectId: any;
  projectName: any;
}

interface ICostingCreateScannerPageProps { }

const CostingCreateScannerPage: React.FC<ICostingCreateScannerPageProps> = (props) => {
  const { projectId, projectName } = useRouteParams<Params>();
  const [openQRScanner, setOpenQRScanner] = React.useState(false);
  const [searchVaultsOptions, setSearchVaultsOptions] = useState<any>([]);
  const [searchVaultValue, setSearchVaultValue] = useState<any>();
  const OuterRef = React.useRef<any>();


  const [selectedValue, setSelectedValue] = React.useState<any>(null)
  const [SearchValue, setSearchValue] = React.useState<any>([])
  const inputRef = React.useRef<any>(null);
  const descRef = React.useRef<any>();
  const timeout = useRef<any>(null);
  const { height, width } = useWindowDimensions();
  const history = useHistory();

  const handleQRScannerClose = () => {
    setOpenQRScanner(false);
  }

  const handleScan = async (vaultId: any) => {
    if (!vaultId) return;
    API.get(`/xcpep/vault_info/`, { vault: vaultId?.text, type: 'costing', project: projectId, cost_exist: true }, 0)
      .then((res: AxiosResponse) => {
        sessionStorage.setItem("CostingCreateCalc", JSON.stringify(res?.data?.[0]?.ancestors));
        history.push(`/createcostingpart/${projectId}/${projectName}/${res?.data?.[0]?.top_vault_name}/${res?.data?.[0]?.top_vault}/${res?.data?.[0]?.id}/${res?.data?.[0]?.abbreviation == null ? "none" : res?.data?.[0]?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
        setOpenQRScanner(false);
      }).catch((error: AxiosError) => {
        setOpenQRScanner(false);
        const { data }: any = error?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"red">${data[0]}</p>   
                 </div>`,
        });
      });;
  };

  const ExactDebounce = React.useRef(

    debounce(async (criteria: any, project: any) => {
      setSearchVaultsOptions([])
      const vault = API.get(`/xcpep/vault_descendants/`, { search: criteria,
         project: projectId
         , cost_exist: true }, 0)
      .then((res) => {
          setSearchVaultsOptions(res.data);
      }).catch((err: AxiosError) => {
        setSearchVaultsOptions([]);
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                  <br />
                  <p style="color:"red">${data[0]}</p>   
                </div>`,
        });
      })
    }, 500)
  ).current;

  const handleClickOutside = (event: any) => {
    // if (!isUndefined(projectCount) && projectCount > 0)


    {
      if (OuterRef?.current && !OuterRef.current.contains(event?.target)) {
        inputRef.current.style.display = "none";
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [OuterRef]);

  // const handleOnChange = (event: any) => {
  //   console.log(event,"event2");
  //   setSearchVaultValue(event)
  //   if (event.length < 1) {
  //     setSearchVaultsOptions([]);
  //   }
  //   if (event.length > 5) {
  //     clearInterval(timeout.current);
  //     timeout.current = setTimeout(() => {
  //       const vault = API.get(`/xcpep/vault_descendants/`, { search: event, project: projectId, cost_exist: true }, 0)
  //         .then((res) => {
  //             setSearchVaultsOptions(res.data);
  //         }).catch((err: AxiosError) => {
  //           setSearchVaultsOptions([]);
  //           const { data }: any = err?.response;
  //           Swal.fire({
  //             icon: "error",
  //             html: `<div>
  //                     <br />
  //                     <p style="color:"red">${data[0]}</p>   
  //                   </div>`,
  //           });
  //         })
  //     }, 1000)
  //   }
  // }


  const handleClickPart = (event: any) => { 
    if(event!=null){
    API.get(`/xcpep/vault_descendants/`, { vault:event?.id }, 0)
          .then((res) => {
            history.push(`/createcostingpart/${projectId}/${projectName}/${event?.name}/${event?.top_vault}/${event?.id}/${event?.abbreviation == null ? "none" : event?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
          }).catch((err: AxiosError) => {
            setSearchVaultsOptions([]);
            const { data }: any = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
            });
          })
        }
  }
  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);

    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit, projectId);
    }



  };
  return (
    <>
      {openQRScanner && <Dialog
        open={openQRScanner}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={width > 650? {
          ".MuiPaper-root.MuiDialog-paper": {
            minWidth: "40vw",
          },
        } : {
          ".MuiPaper-root.MuiDialog-paper": {
            maxWidth: {xs:'90vw',lg:"70vw"},
          },
        }}
      >
         <DialogContent sx={{ padding: "0rem", minWidth: {xs:'20rem',lg:'44rem'}}}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              QR Scanner
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleQRScannerClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "1rem",
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <QrReader
              scanDelay={300}
              constraints={{ facingMode: "environment" }}
              onResult={handleScan}
              className={styles.videoStyle}
              videoContainerStyle={{ width: "100%" }}
              containerStyle={{ width: "100%" }}
            />
          </Box>
        </DialogContent>
      </Dialog>}
      <Box sx={{ padding: "0 0.5rem" }}>
        <IconButton sx={{ cursor: "pointer", }} onClick={() => history.goBack()}>
          <ArrowBackIcon
            color="primary"
            titleAccess="Go Back"
          />
        </IconButton>
        <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block', marginLeft: '1rem' }}>Estimate Cost ( {projectName} )</Typography>
      </Box>
      <Box className={styles.qrScannerdiv}>
        <div className={styles.qrScannerleft}>
          <img src={scanner} draggable="false" className={styles.qrScannerimg} title='Scan Barcode' onClick={() => setOpenQRScanner(true)} />
          <Typography className={styles.titletext}>Allow camera access for QR Scanning</Typography>
        </div>
        <Divider orientation="vertical" flexItem sx={{ borderColor: 'primary.light' }} />
        <div className={styles.qrScannerright}>
          <Typography className={styles.titletext}>Search Part Number/Name</Typography>


          <div
          className={styles.inputbox}
          ref={OuterRef}
        // tabIndex={1} onKeyDown={(event:any)=>HandleKeyDown(event)}
        >
          {
            <input
              type="search"
              placeholder="Search Part Number/Name"
              value={selectedValue}
              className={styles.searchInput}
              autoFocus

              style={{ color: "#007fff" }}
              // @ts-expect-
              ref={descRef}
              onKeyPress={(event: any) => handleOnChange(event)}
              onChange={(event: any) => handleOnChange(event)}
            // onFocus={(e) => FocusHandler(e)}
            />
          }

          <div
            style={{ display: "none", zIndex: 900, position: 'absolute' }}
            className={styles.AutoSearchBox}
            ref={inputRef}>
            <>
              <div className={styles.searchSubText}>
                {searchVaultsOptions.map((item: any, index: any) => {
                  return (
                    <div>
                      <div
                        key={index}
                        //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                        className={styles.innserSearchSubText}
                        onClick={() => {
                          setSelectedValue(item.name);
                          inputRef.current.style.display = "none";
                          if(item!=null){
                            API.get(`/xcpep/vault_descendants/`, { vault:item?.id }, 0)
                                  .then((res) => {
                                    history.push(`/createcostingpart/${projectId}/${projectName}/${item?.name}/${item?.top_vault}/${item?.id}/${item?.abbreviation == null ? "none" : item?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
                                  }).catch((err: AxiosError) => {
                                    setSearchVaultsOptions([]);
                                    const { data }: any = err?.response;
                                    Swal.fire({
                                      icon: "error",
                                      html: `<div>
                                              <br />
                                              <p style="color:"red">${data[0]}</p>   
                                            </div>`,
                                    });
                                  })
                                }
                        
                          
                        }}
                      >
                        <span
                        // onClick={() => {
                        //   handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob);
                        // }}
                        >

                          <>
                            {/* <span>{item?.value} </span> */}
                            <span>
                             
                              <span>
                                <span>(</span>
                                {item?.part_no}
                                <span>)</span>
                              </span>
                              | <span>{item?.name}</span>
                            </span>
                          </>
                        </span>
                        <span
                          style={{
                            // marginRight: "0.5rem",
                            paddingTop: "5px",
                          }}>
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  );
                })}
                {searchVaultsOptions && searchVaultsOptions?.length == 0 && (
                  <p
                    style={{
                      paddingLeft: "1rem",
                      fontSize: "0.9rem",
                    }}>
                    No Data
                  </p>
                )}
              </div>
            </>
          </div>
        </div>
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={searchVaultValue}
            options={searchVaultsOptions}
            // @ts-ignore
            getOptionLabel={(option) => option.part_no}
            onChange={(event, newValue) => {
              handleClickPart(newValue);
            }}
            sx={{

              '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                color: 'primary.main'
              },
              '.MuiButtonBase-root.MuiChip-root': {
                backgroundColor: 'primary.light',
              },
            }}
            onInputChange={(event, newInputValue) => {
              handleOnChange(newInputValue);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                style={{
                  display: "flex",
                  fontSize: "1rem",
                  // marginBottom: "1rem",
                }}
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
              
                <div
                  style={{
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  //@ts-ignore
                >{`(${option.part_no}) | ${
                  //@ts-ignore
                  option?.name
                }
                
               
                
                `}
                </div>
              </Box>
            )}
            renderInput={(params) => <TextField
              sx={{
                width: '300px',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
              }}
              {...params} variant="standard" />}
          /> */}
        </div>
      </Box>
    </>
  )
}

export default CostingCreateScannerPage;