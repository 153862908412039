import * as React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./IdeaAutoGenerate.module.scss";
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import rightArrow from '../../../Assets/images/rightArrows.svg';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { API } from '../../../api-services';
import InfoIcon from '@mui/icons-material/Info';
import swal from 'sweetalert';
import IdeaPromoteFormModal from './IdeaPromoteFrom/IdeaPromoteFormModal';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { useDocumentTitle } from '../../../ui-reusable-component/useDocumentTitle';

export interface IIdeaAutoGenerateDetailsPageProps {
}

interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
  subSystem: any;
  combinationsId: any;
  abbrevID: any;
}

export default function IdeaAutoGenerateDetailsPage(props: IIdeaAutoGenerateDetailsPageProps) {
  const { projectId, projectName, topVault, subSystem, combinationsId, abbrevID } = useRouteParams<Params>();
  const [categoryList, setCategoryList] = React.useState<any>(null);
  const [categorySelected, setCategorySelected] = React.useState<any>(null);
  const [combinations, setCombinations] = React.useState<any>(null);
  const [ideaGenerated, setIdeaGenerated] = React.useState<any>(null);
  const [ideaPromoted, setIdeaPromoted] = React.useState<any>(null);
  const [initDashboardData, setInitDashboardData] = React.useState<any>(null);
  const [ideaDetailedData, setIdeaDetailedData] = React.useState<any>([]);
  const [ancestorData, setAncestorData] = React.useState<any>(null);
  const [ideaPromoteData, setIdeaPromoteData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [PermissionAction, SetPermissionAction] = React.useState<any>()
  const ideaPromoteModal = useBaseModal();
  useDocumentTitle('Idea');

  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  const getAncestorData = () => {
    API.get(`/xcpep/vault_info/`, {
      vault: abbrevID,
    }, 0)
      .then((res: any) => {

        // console.log(res?.data?.[0]?.ancestors);
        setAncestorData(res?.data?.[0]?.ancestors);
        sessionStorage.setItem('BOMCreateNode', JSON.stringify(res?.data?.[0]?.ancestors));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const handleRedirectionBOM = (id: any) => {
    const url = `/#/bomentry/${projectId}/${projectName}/${topVault}/${id}/${subSystem}/313/false/false`;
    window.open(url, '_blank');
  }

  const handleRedirectionCosting = (name: any, id: any) => {
    const url = `/#/createcostingpart/${projectId}/${projectName}/${name}/${topVault}/${id}/none/0/0/0/0/0/0/0/0/0/true`;
    window.open(url, '_blank');
  }
  const handleRedirectionCostingCompetitor = (name: any, id: any) => {
    const url = `/#/createcostingpart/${projectId}/${projectName}/${name}/${topVault}/${id}/none/0/0/0/0/0/0/0/0/0/true`;
    window.open(url, '_blank');
  }
  const handleRedirectionBOMCompetitor = (name: any, id: any) => {
    const url = `/#/bomentry/${projectId}/${projectName}/${name}/${id}/${subSystem}/313/false/false`;
    window.open(url, '_blank');
  }


  React.useEffect(() => {
    API.get(`/auth/calibration_user_permission/`, { action: true, idea_create: true, top_vault: topVault }).then((res: any) => {
      SetPermissionAction(res.data)
    }).catch((err: any) => {
      console.log("Server Error")
    })
  }, [topVault])
  const getCategoryList = () => {
    API.get(`/idea/idea_combinations/`, {
      top_vault: topVault,
      abbreviation: subSystem,
    }, 0)
      .then((res: any) => {

        setCategoryList(res?.data?.response);
        setCombinations(res?.data?.combinations);
        setIdeaGenerated(res?.data?.idea_generated);
        setIdeaPromoted(res?.data?.idea_promoted);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const getIdeaDetailedData = () => {
    setLoading(true);
    API.get(`/idea/pool/`, {
      vault: abbrevID,
      combination: combinationsId,
      page: 1,
    }, 0)
      .then((res: any) => {

        // console.log(res?.data);
        setIdeaDetailedData(res?.data?.results);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }


  const getIdeaInitData = () => {
    API.get(`/idea/initiation_dashboard/`, {
      combination: combinationsId,
    }, 0)
      .then((res: any) => {

        // console.log(res?.data);
        setInitDashboardData(res?.data[0]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }


  React.useEffect(() => {
    getCategoryList();
    getIdeaInitData();
    getIdeaDetailedData();
    getAncestorData();
  }, []);



  const columns: GridColDef[] = [
    {
      field: 'basepartname',
      headerName: 'Base Part Name',
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}><Tooltip title={cellValues?.row?.base?.name} placement="left-start"><span >{cellValues?.row?.base?.name}</span></Tooltip></Box>
        )
      }
    },
    {
      field: 'basevehiclepartid',
      headerName: 'Base Vehicle Part ID',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        if (cellValues?.row?.origin === 1) {
          return (
            <Box sx={{ cursor: PermissionAction && PermissionAction?.bom ? "pointer" : "default", textDecoration: "underline" }} onClick={() => PermissionAction && PermissionAction?.bom && handleRedirectionBOM(cellValues?.row?.base?.id)}>{cellValues?.row?.base?.part_no}</Box>
          )
        }
        else if (cellValues?.row?.origin === 4) {
          return (
            <Box sx={{ cursor: PermissionAction && PermissionAction?.costing ? "pointer" : "default", textDecoration: "underline" }} onClick={() => PermissionAction && PermissionAction?.costing &&
              handleRedirectionCosting(cellValues?.row?.base?.top_vault_name, cellValues?.row?.base?.id)}>{cellValues?.row?.base?.part_no}</Box>
          )
        }
        else {
          return (
            <Box>{cellValues?.row?.base?.part_no}</Box>
          )
        }
      }
    },
    {
      field: 'parameters',
      headerName: 'Parameters',
      minWidth: 110,
      flex: 1,
      renderCell: (cellValues: any) => {

        const unit = cellValues?.row?.unit != "" ? `(${cellValues?.row?.unit})` : ""

        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}>{cellValues?.row?.parameter} {unit}</Box>
        )
      }
    },
    {
      field: 'basevalue',
      headerName: 'Base Value',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}>{cellValues?.row?.base_value}</Box>
        )
      }
    },
    {
      field: 'details',
      headerName: 'Delta Value',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        if (cellValues?.row?.details?.length > 0) {
          return (

            <Box><Tooltip title={cellValues?.row?.details.map((item: any) => item.map((e: any) => e).join(' - '))} placement="top-start"><span><InfoIcon /></span></Tooltip></Box>
          )
        }

      }
    },
    {
      field: 'competitorpartname',
      headerName: 'Competitor Part Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 250,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}><Tooltip title={cellValues?.row?.competitor?.name} placement="top-start"><span >{cellValues?.row?.competitor?.name}</span></Tooltip></Box>
        )
      }
    },
    {
      field: 'competitorvehicle',
      headerName: 'Competitor Vehicle',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}>{cellValues?.row?.competitor?.top_vault_name}</Box>
        )
      }
    },
    {
      field: 'competitorvehiclepartid',
      headerName: 'Competitor Vehicle Part ID',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);

        if (cellValues?.row?.origin === 1) {
          return (
            <Box sx={{ cursor: PermissionAction && PermissionAction?.bom ? "pointer" : "default", textDecoration: "underline" }} onClick={() =>  PermissionAction && PermissionAction?.bom  &&  handleRedirectionBOMCompetitor(cellValues?.row?.competitor?.top_vault, cellValues?.row?.competitor?.id)}>{cellValues?.row?.competitor?.part_no}</Box>
          )
        }
        else if (cellValues?.row?.origin === 4) {
          return (
            <Box sx={{ cursor: PermissionAction && PermissionAction?.costing ? "pointer" : "default", textDecoration: "underline" }} onClick={() => PermissionAction && PermissionAction?.costing && handleRedirectionCostingCompetitor(cellValues?.row?.competitor?.top_vault, cellValues?.row?.competitor?.id)}>{cellValues?.row?.competitor?.part_no}</Box>
          )
        }
        else {
          return (
            <Box>{cellValues?.row?.base?.part_no}</Box>
          )
        }
      }
    },
    {
      field: 'competitorvalue',
      headerName: 'Competitor Value',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.4)" : "primary.main" }}>{cellValues?.row?.competitor_value}</Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
            {PermissionAction && PermissionAction?.action?.includes("U") &&
              <IconButton title='Promote' sx={{
                cursor: 'pointer',
                color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.6)" : "primary.main",
                padding: '0',

              }}
                disabled={cellValues?.row?.rejected}
                onClick={() => {
                  swal({
                    title: `Promote Idea`,
                    text: `Are you sure you want to promote ?`,
                    icon: 'warning',
                    buttons: ['Cancel', 'Yes, Promote'],
                  }).then((confirm: any) => {
                    if (confirm) {
                      ideaPromoteModal.open();
                      setIdeaPromoteData(cellValues?.row);
                    }
                  });
                }}
              >
                <img src={rightArrow} style={{ height: '1.5rem', cursor: 'pointer' }} />
              </IconButton>
            }




            {PermissionAction && PermissionAction?.action?.includes("D") &&
              <IconButton
                title='Reject Idea'
                sx={{
                  cursor: 'pointer',
                  color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.6)" : "primary.main",
                  padding: '0',

                }}
                disabled={cellValues?.row?.rejected}
                onClick={() => {
                  swal({
                    title: `Reject Idea`,
                    text: `Are you sure you want to Reject Idea ?`,
                    icon: 'error',
                    buttons: ['Cancel', 'Yes, Reject Idea'],
                  }).then((confirm: any) => {
                    if (confirm) {
                      API.put(
                        `idea/pool/${cellValues.row.id}/`,
                        {
                          rejected: true,
                        },
                        0
                      )
                        .then((res: any) => {

                          getCategoryList();
                          getIdeaInitData();
                          getIdeaDetailedData();
                        })
                        .catch((err: any) => {
                          console.log(err);
                        });
                    }
                  });
                }}
              >
                <ClearIcon sx={{color:'red'}} />
              </IconButton>

            }
            {PermissionAction && PermissionAction?.action?.includes("U") &&
              <IconButton
                title='Revert Idea'
                sx={{ cursor: 'pointer', color: cellValues?.row?.rejected ? "rgba(0, 0, 0, 0.6)" : "primary.main", padding: '0' }}
                disabled={cellValues?.row?.rejected == false}
                onClick={() => {
                  swal({
                    title: `Revert Idea`,
                    text: `Are you sure you want to Revert Idea ?`,
                    icon: 'warning',
                    buttons: ['Cancel', 'Yes, Revert Idea'],
                  }).then((confirm: any) => {
                    if (confirm) {
                      API.put(
                        `idea/pool/${cellValues.row.id}/`,
                        {
                          rejected: false,
                        },
                        0
                      )
                        .then((res: any) => {

                          getCategoryList();
                          getIdeaInitData();
                          getIdeaDetailedData();
                        })
                        .catch((err: any) => {
                          console.log(err);
                        });
                    }
                  });
                }}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            }

          </Box>
        );
      },
      flex: 1,
    },
  ];


  console.log(ideaDetailedData);

  let ideaDataGeneratedDatagrid: any = {
    columns: columns,
    rows: ideaDetailedData && ideaDetailedData,
  };


  return (
    <div>
      <Box sx={{ padding: "0 0.5rem", }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
              Ideas Initiation Dashboard ({projectName} {'>'} {subSystem})
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: 'primary.light' }}>Combinations : {combinations}</Typography>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: '#ffee93' }}>Idea Generated : {ideaGenerated}</Typography>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: '#adf7b6' }}>Idea Promoted : {ideaPromoted}</Typography>
          </Box>
        </Box>
        <Box>
          {/* <Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}} adl-scrollbar='true'
						adl-scrollbar-width='0.2'>
						<>
              {
                categoryList && categoryList?.map((item: any, index: any) => {
                  return <Box
                    key={index}
                    sx={{
                      color: '#007fff',
                      // height: '3rem',
                      minHeight: '2.1rem',
                      maxWidth: '100% !important',
                      padding: '0',
                      fontSize: '1rem',
                      width: '100%',
                      backgroundColor: categorySelected === item?.origin ? 'primary.main' : 'primary.light',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderTopRightRadius: '0.5rem',
                      borderTopLeftRadius: '0.5rem',
                    }}
                    onClick={() => handleCategoryClick(item)}
                  >
                    <Typography
                      sx={{
                        color: categorySelected === item?.origin ? '#fff' : '#007fff',
                        // height: '3rem',
                        minHeight: '2.1rem',
                        maxWidth: '100% !important',
                        padding: '0 0.5rem',
                        fontSize: '1rem',
                        width: '100%',
                        // textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'capitalize',
                      }}>
                      <Typography sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', }}>{item?.name} <AddIcon /></Typography>
                      <Typography sx={{ fontSize: '1rem', width: '100%', }}>( {item?.combinations} Combinations, {item?.total_idea} Ideas, {item?.promoted} Promoted)</Typography>
                    </Typography>
                  </Box>
                })}
            </>					
					</Box>	 */}
          <Box sx={{ padding: '0.2rem 0' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHead}>
                    Category
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.category}
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHead}>
                    Analysis Type
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.analysis_type}
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHead}>
                    Total Auto Generated
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.total_idea}
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHeadpromoted}>
                    Promoted
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.promoted}
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHead}>
                    Present
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.present}
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }} className={styles.rowHeadrejected}>
                    Rejected
                  </TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottom: 'none' }}>
                    {initDashboardData?.rejected}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              height: { lg: '83vh', xl: '87vh' },
              width: '100%',
              '& .super-app-theme--cell': {
                backgroundColor: '#ddefff !important',
                textAlign: 'center !important',
              },
            }}>
            <DataGrid
              {...ideaDataGeneratedDatagrid}
              // rows={rows}
              // columns={columns}                
              // checkboxSelection
              loading={loading}
              headerHeight={42}
              rowHeight={36}
              sx={{
                '& ::-webkit-scrollbar': {
                  width: '0.3rem',
                  height: '0.3rem',
                },
                '& ::-webkit-scrollbar-thumb': {
                  // background: $thumb-color;
                  backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
                  borderRadius: '10px',
                },
                '& ::- webkit-scrollbar-thumb: hover ': {
                  // width: "4px",
                  backgroundColor: '#045DE9 !important',
                },
                '::-webkit-scrollbar-track': {
                  backgroundColor: '#e1e1f3 !important',
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                  borderBottomColor: 'primary.light',
                },
              }}
            />

          </Box>

        </Box>
      </Box>
      {
        ideaPromoteModal.isOpen && ideaPromoteData && (
          <IdeaPromoteFormModal
            isOpen={ideaPromoteModal.isOpen}
            onCloseModal={ideaPromoteModal.close}
            getIdeaPromotedDetails={ideaPromoteData}
            getIdeaDetailedData={getIdeaDetailedData}
            getIdeaInitData={getIdeaInitData}
            getCategoryList={getCategoryList}
          />
        )
      }
    </div>
  );
}
