import { Box, TableCell, TableRow } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import DatabaseInfoModal from "../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/DatabaseInfoModal";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/storeConfigurations";
import { inputCalculationSelectors } from "../../Redux/Reducers/inputCalculation.reducer";
import { useSelectedDatabaseRecordInfoModal } from "../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/useSelectedDatabaseRecordInfoModal";
import SelectedDatabaseRecordInfoModal from "../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/SelectedDatabaseRecordInfoModal";

function CostingViewRow({
  getShowMaterialStatus,
  calcData,
  detailsShow,
  formatNumber,
  childData,
  flag,
  flagRed,
  calculatorId,
   selectedRevision, 
   assumptionDetails,
   viewtype
}: any) {
  const { vault, topVault, projectId, scenarioId } = useRouteParams<any>();
  // const [assumptionDetails, setAssumptionDetails] = useState<any>();
  // const [selectedRevision, setSelectedRevision] = useState<any>(null);
  const [revisionId, setRevisionId] = useState<any>(null);
  const [contentType, setContentType] = useState<any>(null);
  const dbInfoModal:any = useBaseParamsModal();
  const [assumptionCount,setAssumptionCount] = useState<any>({
    details:0,
    list:0
  });
  const databaseRecordInfo:any = useSelectedDatabaseRecordInfoModal();

    useEffect(() => {
    if (calcData?.content_type) {
      setContentType(calcData?.content_type);
    }

  }, [calcData]);

  // const getAssumptionData = () => {
  //   API.get(`/cost/costing_assumption/`, {
  //     vault: vault,
  //     scenario: scenarioId,
  //   }).then((res: any) => {
  //     setAssumptionDetails(res?.data);
  //     setAssumptionCount({...assumptionCount, details:assumptionCount.details+1});
  //   });
  // };

  // const getCalculatorData = () => {
  //   API.get(`/cost/costing_assumption/`, {
  //     vault: vault,
  //     scenario: scenarioId,
  //   }).then((res: any) => {
  //     setAssumptionDetails(res?.data);
  //   });
  // };

  // const getAssumptionList = () => {
  //   if (assumptionDetails?.assumption) {
  //     API.get(`cost/assumption/`, { id: assumptionDetails?.assumption })
  //       .then((res: any) => {
  //         const rev = res?.data?.find(
  //           (item: any) => item?.id == assumptionDetails?.assumption
  //         );
  //         setSelectedRevision(rev);
  //         setAssumptionCount({...assumptionCount, list:assumptionCount.list+1})
  //       })
  //       .catch((err: any) => {});
  //   }
  // };

  // useEffect(() => {
  //   if (calcData?.content_type) {
  //     setContentType(calcData?.content_type);
  //   }
  //   if (getShowMaterialStatus(calcData.content_type)) {
  //     if (assumptionCount.details === 0) {
  //       getAssumptionData();
  //     }
  //   }
  // }, [calcData]);

  // useEffect(() => {
  //   if (assumptionDetails?.assumption && calcData?.content_type) {
  //     if (assumptionCount.list === 0) {
  //       getAssumptionList();
  //     }
  //   }
  // }, [assumptionDetails]);

  const CALCULATOR_DATABASES_MAP = {
    MATERIAL: 1,
    MACHINE: 2,
    LABOUR: 3,
    TOOL: 4,
    FASTENER: 5,
    PURCHASEING_ITEM: 6,
    STROKE_RATE: 7,
    CURRENCY: 8,
  };

  const DB_MAP = {1: 'Material',
    2:'Labour' ,
    3:'Machine',
     4:'ToolDatabase',
     5:'Operation',
     6:'PurchasingItem',
     7: 'StrokeRate'
    }

    // Function to get the ID
  const getIdFromMap = () => {
    if (calcData?.content_type) {
      // Find the key in the DB_MAP where the value matches calcData.id
      const entry = Object.entries(DB_MAP).find(
        ([, value]) => value === calcData.content_type
      );

      // Return the key (which is the ID) or null if not found
      return entry ? Number(entry[0]) : null;
    }
    return null;
  };

  useEffect(() => {
   

    if (selectedRevision && calcData?.content_type) {
      
      if (contentType == "Material") {
        setRevisionId(selectedRevision?.material_revision);
      } else if (contentType == "Machine") {
        setRevisionId(selectedRevision?.machine_revision);
      } else if (contentType == "Labour") {
        setRevisionId(selectedRevision?.labour_revision);
      } else if (contentType == "ToolDatabase") {
        setRevisionId(selectedRevision?.tools_revision);
      } else if (contentType == "Operation") {
        setRevisionId(selectedRevision?.operations_revision);
      } else if (contentType == "PurchasingItem") {
        setRevisionId(selectedRevision?.bop_revision);
      } 
    }
  }, [selectedRevision, calcData, contentType]);

 

  return (
    <>
    {dbInfoModal.isOpen && (
         <SelectedDatabaseRecordInfoModal
         isOpen={dbInfoModal.isOpen}
         onClose={dbInfoModal.close}
         database={dbInfoModal?.propsId?.database}
         recordId={dbInfoModal?.propsId?.recordId}
         userInput={dbInfoModal?.propsId?.userInput}
         revisionId={dbInfoModal?.propsId?.revisionId}
         assumptionList={dbInfoModal?.propsId?.assumptionList}
         calculatorId={dbInfoModal?.propsId?.calculatorId}
         viewtype={viewtype ? null : "costingType"}
         costingcreate={viewtype ? true : false}

       />
    )}
    <TableRow>
      <TableCell
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "1rem",
          padding: "0.2rem 0.5rem 0.2rem 3.5rem",
          borderBottomColor: "primary.light",
          textTransform: "capitalize",
          fontSize: "12px",
        }}
      >
        {calcData?.name}
        {getShowMaterialStatus(calcData.content_type) && (
          <Box>
            <InfoIcon
              sx={{
                fontSize: "1rem",
                color: "#007fff",
                cursor: "pointer",
              }}
              onClick={() => {

                

                dbInfoModal.open({
                    recordId:calcData?.revision_obj_id,
                    database:getIdFromMap(),
                    userInput: {id:calculatorId},
                    revisionId:revisionId,
                    assumptionList:assumptionDetails?.assumption_list,
                    calculatorId:calculatorId

                })

              }}
            />
          </Box>
        )}
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          fontSize: "12px",
          width: "10rem",
          textAlign: "right",
        }}
      >
        {calcData?.value !== null &&
          `${
            typeof calcData?.value === "number"
              ? formatNumber(calcData?.value)
              : calcData?.value
          }`}
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          fontSize: "12px",
          width: {lg:'12rem',xl:"18rem"},
          textAlign: "right",
        }}
      >
        {calcData?.unit ? calcData?.unit : ""}
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          fontSize: "12px",
          width: "7rem",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "10px",
            lineHeight: "1",
          }}
        >
          {calcData?.flag && (
            <img
              src={flag}
              style={{
                width: "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                API.get("/customer/costing_module/",{
                  calculation:calculatorId,
                  field:calcData?.id,
                  download_flag:true 
                },0).then((res:any)=>{
                  window.open(res.data, "_blank", " noopener")
                }).catch((err:any)=>{})
              }}
               
            />
          )}
          {calcData?.db_file && (
            <img
              title="Machine Cost"
              src={flagRed}
              style={{
                width: "1.5rem",
                color: "red",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(calcData?.db_file, "_blank", " noopener")
              }
            />
          )}

          {calcData?.db_spcification_file && (
            <img
              title="Machine Specification"
              src={flagRed}
              style={{
                width: "1.5rem",
                color: "red",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  calcData?.db_spcification_file,
                  "_blank",
                  " noopener"
                )
              }
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
    </>
  );
}

export default CostingViewRow;
