// styles
import styles from "./RawMaterialCard.module.scss";
import { useAppDispatch } from "../../../../../AppRouter";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useQueryDispatch } from "../../../../CustomHook/useQueryDispatch";
import { ID } from "../../../../utlis/commonInterfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { processCardSelectors } from "../../../../Redux/Reducers/processCard.reducer";
import { getProcessCardCosts, resetProcessCard } from "../../../../Redux/Actions/processCard.action";
import ProcessCardCostRow from "./RawMaterialCardCostRow/ProcessCardCostRow";

// lazy

// ----------------------------------------------------------------------------------
// params

interface Params {
  projectId: string;
  topVaultId: string;
  vaultId: string;
  subsystemId: string;
  scenarioId: string;
}

// ----------------------------------------------------------------------------------

interface RawMaterialCardProps {}

const ProcessCard: React.FC<RawMaterialCardProps> = () => {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    condition,
    calctype,
    calcultorId,
  } = useRouteParams<any>();

  const dispatch = useAppDispatch();
  // const readonly = useCostingReadonly();

  // ----------------------------------------------------------------------------------
  // state

  const [showCode, setShowCode] = useState<any>(false);
  const [activeTab, setActiveTab] = useState<any>();

  // ----------------------------------------------------------------------------------
  // context

  // const costEstimationId = useContext( CostEstimationIdContext );

  // ----------------------------------------------------------------------------------
  // selectors

  /** raw material card cost ids */
  const costIds = useSelector(processCardSelectors.selectIds);
  var SelectedTableIds =
    costIds &&
    costIds?.filter((item: any, index: any) => {
      return item == tableId;
    });


  // ----------------------------------------------------------------------------------
  // material cost selection props

  // const materialSelection = useMaterialSelectionModal();
  // const newMaterialSelection = useMaterialSelectionModal();
  // const fastenerSelection = useFastenersSelectionModal();
  // const purchasingSelection = usePurchasingItemSelectionModal();

  // ----------------------------------------------------------------------------------
  // boughtout selection props

  // const boughtOutSelection = useBaseModal();

  // ----------------------------------------------------------------------------------
  // handlers

  const handleChange = () => setShowCode((prev:any) => !prev);

  const handleNewSelect = async (selection: any) => {
    let action;
    // action = await dispatch( updateRawMaterialCardCost( {
    //     id: newMaterialSelection.cardCostId,
    //     updates: {
    //         material: selection
    //     }
    // } ) );

    // close modal on success
    // if ( updateRawMaterialCardCost.fulfilled.match( action ) ) {
    //     newMaterialSelection.close();
    // }
  };

  const handleSelect = async (selection: any) => {
    let action;
    // action = await dispatch( createRawMaterialCardCosts( {
    //     costEstimationId,
    //     materials: [selection]
    //     // materials: selection.map( material => material.id )
    // } ) );

    // close modal on success
    // if ( createRawMaterialCardCosts.fulfilled.match( action ) ) {
    //     materialSelection.close();
    // }
  };

  // ----------------------------------------------------------------------------------
  // side effects

  const costsFetched = useQueryDispatch({
    // @ts-ignore
    query: { action: getProcessCardCosts, arg: { costEstimationId,tableId:tableId } },
    cleanup: { action: resetProcessCard, args: [] },
    dependency: [tableId, vault],
  });

  const getName = () => {
    switch (calctype) {
      case 1:
        return "material";
      case 2:
        return "boughtout";
      case 3:
        return "process";
      case 4:
        return "process";
      case 5:
        return "process";

      case 6:
        return "process";

      default:
        return "overhead";
    }
  };

  // ----------------------------------------------------------------------------------
  // jsx abstraction

  const renderRow = (costId: ID) => {
    return (
      <ProcessCardCostRow
        key={costId}
        costId={costId}
        showCode={showCode}
        // handleChangeMaterial={ materialSelection.open( costId ) && newMaterialSelection.open( costId ) }
        // handleChangeFastener={ fastenerSelection.open( costId ) }
        // handleChangePurchasingItem={ purchasingSelection.open( costId ) }
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
    );
  };

  // ----------------------------------------------------------------------------------
  // jsx

  return (
    <Fragment>
      <div data-testid="raw-material-card" className={styles.container}>
        {/* <Divider /> */}
        <div className={styles.main}>
          <div
            className={styles.tableContainer}
            adl-scrollbar="true"
            adl-scrollbar-width="0.6"
          >
            {/* <LetSuspense
                            loadingPlaceholder={ BouncingLoader }
                            condition={ costsFetched.match( "TRUE" ) }
                            errorCondition={ costsFetched.match( "ERROR" ) }
                            errorPlaceholder={ <Retry onClick={ costsFetched.fetch } /> }
                        > */}
            {!SelectedTableIds?.length ? (
              <Typography>No {getName()} added yet</Typography>
            ) : (
              // <NoData
              //     iconClass="none"
              //     label="No materials added yet"
              //     subheading="Try adding material/bought out"
              // />
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        width: "3rem",
                      }}
                    >
                      Seq
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Commodity
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Process
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Machine
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Labour
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Tool
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Set-up
                    </TableCell>
                    {/* <TableCell sx={{padding:'0.2rem 0.5rem'}}>RM</TableCell>

                                            <TableCell sx={{padding:'0.2rem 0.5rem'}}>BOP</TableCell> */}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        width: "3rem",
                      }}
                    >
                      Qty.
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                        lineHeight: "1.2",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{SelectedTableIds.map(renderRow)}</TableBody>
              </Table>
            )}
            {/* </LetSuspense> */}
          </div>
          {/* <div className={ styles.calculator }>
                        <CalculatorRoutes calculatorType={ CALCULATOR_TYPE.RAW_MATERIAL } />
                    </div> */}
        </div>
      </div>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------------

export default ProcessCard;
