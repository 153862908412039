// react

import React, { useContext, useEffect, useState } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ID } from "../../../../../utlis/commonInterfaces";
import { useAppDispatch } from "../../../../../../AppRouter";
import { RootState } from "../../../../../Redux/Store/storeConfigurations";
import { rawMaterialCardSelectors } from "../../../../../Redux/Reducers/rawMaterialCard.reducer";
import { deleteSwal } from "../../../../../utlis/deleteSwal";
import {
  deleteRawMaterialCardCost,
  updateRawMaterialCardCost,
} from "../../../../../Redux/Actions/rawMaterialCard.actions";
import { useBaseModal } from "../../../../SearchPage/useBaseModal";
import { TableCell, TableRow, TextField } from "@mui/material";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import { userPermission } from "../../CreateCalculatorWithCategoryModal";
import { useBaseParamsModal } from "../../../../../CustomHook/useBaseParamsModal";
import { AddGradeModal } from "./AddGradeModal";
import { userSelectors } from "../../../../../Redux/Reducers/user.reducer";

// services
// utils

// constants
// import { CALCULATOR_CATEGORY } from 'app/constants/calculator.constants';

// factories
// core
// redux

// import { rawMaterialCardSelectors } from 'app/Redux/Reducers/rawMaterialCard.reducer';
// import { deleteRawMaterialCardCost, updateRawMaterialCardCost } from 'app/Redux/Actions/rawMaterialCard.actions';

// // ui-components
// // others
// import { useAppDispatch } from 'AppRouter';
// import RawMaterialCostRowInfo from './RawMaterialCostRowInfo/RawMaterialCostRowInfo';
// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';

// // styles

// import MaterialCopyModal from './MaterialCopyModal/MaterialCopyModal';
// import { useBaseModal } from 'app/Shared/useBaseModal';
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface RawMaterialCardCostRowProps {
  costId: ID;
  showCode?: boolean;
  handleChangeMaterial?: () => void;
  handleChangeFastener?: () => void;
  handleChangePurchasingItem?: () => void;
  setActiveTab?: any;
  activeTab?: any;
  costDetailsData?: any;
  calctype?:any
  decimalPoints?:any;
  CostingType?:any
}

const ScenarioRawMaterialCardCostRowConfig: React.FC<RawMaterialCardCostRowProps> = (props) => {
  const {
    costId,
    showCode,
    handleChangeMaterial,
    handleChangeFastener,
    handleChangePurchasingItem,
    setActiveTab,
    activeTab,
    costDetailsData,
    calctype,
    decimalPoints,
    CostingType
  } = props;
  const dispatch = useAppDispatch();

  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    // calctype,
    calcultorId,
    scenarioId,
    conditionPopup
  } = useRouteParams<any>();
  const addGradeOpen = useBaseParamsModal();
  const [Counter, setCounter] = useState<any>(0);
  const [sequence, setSequence] = useState<any>(1);
  const userProfilePic = useSelector(userSelectors.selectAll);
  const history = useHistory();
  const { url } = useRouteMatch();
  // const readonly = useCostingReadonly();
  const [highlightTab, setActive] = React.useState<boolean>(false);
  const [Color, setColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  // ----------------------------------------------------------------------------------
  // state

  const [alias, setAlias] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [grade, setGrade] = useState<any>("");
  const [type, setType]: any = useState();
  const useraction = useContext<any>(userPermission);
  // ----------------------------------------------------------------------------------
  // selectors

  const cost = useSelector((state: RootState) => {
    return rawMaterialCardSelectors.selectById(state, costId);
  });

//   console.log(cost, "calctype");
// console.log(costDetailsData, "calctype");
  // console.log(userProfilePic, "userProfilePic");

  useEffect(() => {
    if (cost && calctype == 1 && costDetailsData) {
      setSequence(cost?.sequence);
      if ((cost.grade === null || cost.grade?.length === 0) && costDetailsData?.costing_status !== 'validated' && userProfilePic && userProfilePic?.[0].user_category === 'Internal') {
        // addGradeOpen.open(cost);
      }
    }
  }, [cost, costDetailsData]);



  // ----------------------------------------------------------------------------------
  // handlers

  const handleChange = (e: any) => {
    //@ts-ignore
    setColor(true);
    if (e?.target?.name === "alias") {
      setAlias(e?.target?.value);
    } else if (e?.target?.name === "sequence") {
      setSequence(+e?.target?.value);
    } else if (e?.target?.name === "grade") {
      setGrade(e?.target?.value);
    }
    else {
      setQuantity(e?.target?.value as any);
    }
  };

  const handleDelete = async () => {
    const confirm = await deleteSwal("Delete Raw Material Data?");
    if (confirm) await dispatch(deleteRawMaterialCardCost({ id: costId }));
    var random = Math?.random();
    setCounter((prev: any) => prev + 1);
    // history.push(
    //   `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${0}/${0}/${Counter == 0 ? random : Counter
    //   }/0/${calctype}/0/${scenarioId}/${conditionPopup}/true`
    // );

    // @ts-ignore
    // sessionStorage.setItem("calCounter", Math?.random())

    // history.push( url );
  };


  const handleSave = async () => {
    // setCounter((prev: any) => prev + 1);
    setLoader(true);
    const action = await dispatch(
      updateRawMaterialCardCost({
        id: costId,
        updates: { alias, quantity, sequence, grade },
      })
    );

    if (updateRawMaterialCardCost.fulfilled.match(action)) {
      setLoader(false);
      setColor(false);
      var random = Math?.random();
    //   history.push(
    //     `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${random}/0/${calctype}/${calcultorId || 0}/${scenarioId}/${conditionPopup}/true`
    //   );
      // @ts-ignore
      // sessionStorage.setItem("calCounter", Math?.random())
    }
    if (updateRawMaterialCardCost.fulfilled.match(action) == false) {
      setLoader(false);
      setColor(false);
    }
    // dispatch( updateRawMaterialCardCost( {
    //     id: costId,
    //     updates: { alias, quantity }
    // } ) );
  };

  const handleCopy = () => {
    materialCopyModal.open();
  };
  // ----------------------------------------------------------------------------------
  // side effects

  // sync alias key w/ state
  useEffect(() => {
    if (cost?.alias) setAlias(cost.alias);
    if (cost?.quantity) setQuantity(cost.quantity);
    if (cost?.grade) setGrade(cost.grade)
  }, [cost]);
  useEffect(() => {
    if (activeTab && activeTab === costId) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeTab]);

  // ----------------------------------------------------------------------------------
  // jsx helpers

  const getCost = (quantity = 1) => {
    if (!cost) {
      return 0;
    }

    if (calctype == "1" || calctype == 1) {
      const newCost = cost?.material_cost * quantity;
      return newCost?.toFixed(decimalPoints ? decimalPoints : 3)
     // return  toFixed(cost?.material_cost * quantity, decimalPoints);
    } else {
      const newCost = cost?.bop_cost * quantity;
      return newCost?.toFixed(decimalPoints ? decimalPoints : 3)
     // return toFixed(cost?.bop_cost * quantity, decimalPoints);
    }

    // return toFixed( ( cost.material_cost + cost.bop_cost ) * quantity );
  };

  

  //----------------------------------------------------------------------------------
  //popup
  const materialCopyModal = useBaseModal();
  // ----------------------------------------------------------------------------------
  // jsx abstraction
  let handle: any = { title: "", control: "" };

  // const renderCodeOrName = () => {
  //     if ( !cost ) return "-";
  //     if ( cost.material ) { handle.control = handleChangeMaterial; handle.title = "Change Material Info"; return cost.material[showCode ? "material_code" : "category"]; };
  //     if ( cost.fastener ) { handle.control = handleChangeFastener; handle.title = "Change Fastener Info"; return cost.fastener[showCode ? "fastener_code" : "fastener_type"]; };
  //     if ( cost.purchasing_item ) { handle.control = handleChangePurchasingItem; handle.title = " Change Purchasing Info"; return cost.purchasing_item[showCode ? "code" : "name"]; };

  //     return "-";
  // };

  // ----------------------------------------------------------------------------------
  // jsx

  if (!cost) return null;


  return (
    <>
 
      {/* <MaterialCopyModal
                isOpen={ materialCopyModal.isOpen }
                onClose={ materialCopyModal.close }
                costId={ costId }
                onSelect={ handleCopy }
            /> */}
      {calctype == 1 ? (
        <TableRow
          data-testid="raw-material-card-cost-row"
          sx={{ borderLeft: "1px solid", borderColor: "primary.light" }}
        // className={ joinClsx( highlightTab ? styles.active : styles.inactive, styles.container, "fit-input-childeren" ) }
        // active={ highlightTab }
        >
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {/* {cost?.sequence} */}
            <TextField
              placeholder="Sequence"
              name="sequence"
              value={sequence}
              variant="standard"
             
              onBlur={handleSave}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              onChange={handleChange}
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
            {/* { renderCodeOrName() } */}
          </TableCell>
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            <TextField
              placeholder="Sub Partname"
              name="alias"
              value={alias}
              variant="standard"
              onBlur={handleSave}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              onChange={handleChange}
              sx={{
                width: '100%',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {cost?.material?.category}
            {/* { renderCodeOrName() } */}
          </TableCell>
          {calctype == 1 && <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {/* {cost?.grade} */}
            <TextField
              placeholder="Material Grade"
              name="grade"
              value={grade}
              variant="standard"
              onChange={handleChange}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              onBlur={handleSave}
              sx={{
                width: '100%',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
            {/* { renderCodeOrName() } */}
          </TableCell>}

          {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>
                    { toFixed( cost.bop_cost ) }
                </TableCell> */}
          {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>
                    { getCost() }
                </TableCell> */}
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {cost?.material_cost?.toFixed(decimalPoints ? decimalPoints : 3)}
            {/* {toFixed(cost.material_cost, decimalPoints)} */}
          </TableCell>

          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            <TextField
              type="number"
              placeholder="Qty."
              name="quantity"
              value={quantity}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              variant="standard"
              onChange={handleChange}
              onBlur={handleSave}
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            // className={ styles.quantityInput }
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {getCost(quantity)}
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          data-testid="raw-material-card-cost-row"
          sx={{ borderLeft: "1px solid", borderColor: "primary.light" }}
        // className={ joinClsx( highlightTab ? styles.active : styles.inactive, styles.container, "fit-input-childeren" ) }
        // active={ highlightTab }
        >
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            <TextField
              placeholder="Sequence"
              name="sequence"
              value={sequence}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              onBlur={handleSave}
              variant="standard"
              onChange={handleChange}
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
            {/* { renderCodeOrName() } */}
          </TableCell>
          <TableCell
            title={alias}
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            <TextField
              title={alias}
              placeholder="Sub Partname"
              name="alias"
              value={alias}
              onBlur={handleSave}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              variant="standard"
              onChange={handleChange}
              sx={{
                width: '100%',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {cost?.purchasing_item?.category}
            {/* { renderCodeOrName() } */}
          </TableCell>
          {calctype == 1 && <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {cost?.grade}
            {/* { renderCodeOrName() } */}
          </TableCell>}

          {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>
                    { toFixed( cost.bop_cost ) }
                </TableCell> */}
          {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>
                    { getCost() }
                </TableCell> */}
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {/* @ts-ignore */}
            {cost.bop_cost?.toFixed(decimalPoints ? decimalPoints : 3)}
            {/* {toFixed(cost.bop_cost, decimalPoints)} */}
          </TableCell>

          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            
            <TextField
              type="number"
              placeholder="Qty."
              name="quantity"
              onBlur={handleSave}
              disabled={(useraction && !useraction?.action?.includes("U")) || (CostingType && CostingType == "main")}
              value={quantity}
              variant="standard"
              onChange={handleChange}
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
            // className={ styles.quantityInput }
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0rem 0.5rem",
              borderRight: "1px solid",
              borderColor: "primary.light",
              textAlign: "center",
            }}
          >
            {getCost(quantity)}
          </TableCell>

        </TableRow>
      )}
      {addGradeOpen.isOpen && (
        <AddGradeModal
          isOpen={addGradeOpen.isOpen}
          onCloseModal={addGradeOpen.close}
          Item={addGradeOpen.propsId}
          handleSave={handleSave}
          grade={grade}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

// ----------------------------------------------------------------------------------

export default ScenarioRawMaterialCardCostRowConfig;
