import * as React from 'react';
import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./IdeaAutoGenerate.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import rightArrow from '../../../Assets/images/rightArrows.svg';
import { API } from '../../../api-services';
import {
  getIdeaCombinationTypeName,
  getIdeaFilterName,
} from '../../../utlis/Idea.constant'
import swal from 'sweetalert';
import EditCombinationNameModal from './EditCombinationName';
import AddNewCombination from './AddNewCombination';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';
import { useDocumentTitle } from '../../../ui-reusable-component/useDocumentTitle';



export const UserAction = React.createContext(null)
export interface IIdeaAutoGenerateProps {
}
interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
  subSystem: any;
  abbrevID: any;
  originID: any;
}


export default function IdeaAutoGenerate(props: IIdeaAutoGenerateProps) {
  const { projectId, projectName, topVault, subSystem, abbrevID, originID } = useRouteParams<Params>();
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [combinations, setCombinations] = React.useState<any>();
  const [ideaGenerated, setIdeaGenerated] = React.useState<any>();
  const [ideaPromoted, setIdeaPromoted] = React.useState<any>();
  const [categorySelected, setCategorySelected] = React.useState<any>(originID);
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [rowId, setRowId] = React.useState<any>(null);
  const [editName, setEditName] = React.useState<any>(null);
  const [categoryName, setCategoryName] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const editCombinationName = useBaseModal();
  const addNewCombination = useBaseModal(); 
  const [PermissionAction,SetPermissionAction]=React.useState<any>() 
  useDocumentTitle('Idea');

  const history = useHistory();
  const handleClick = () => {
    history.push(`/idea-generation/${projectId}/${projectName}/${topVault}/${abbrevID}/0/0`)
  };
  const { height, width } = useWindowDimensions(); 
  const getCategoryList = () => {
    API.get(`/idea/idea_combinations/`, {
      top_vault: topVault,
      abbreviation: subSystem,
    }, 0)
      .then((res: any) => {
        setCategoryList(res?.data?.response);
        setCombinations(res?.data?.combinations);
        setIdeaGenerated(res?.data?.idea_generated);
        setIdeaPromoted(res?.data?.idea_promoted);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const getCategoryData = (origin: any, abbrevID: any) => {
    if(subSystem == "PRODUCT"){
      setLoading(true)
      API.get(`/idea/combination/`, {
        base_veh: topVault,
        origin: origin,
      }, 0)
        .then((res: any) => {
          setCategoryData(res?.data);
          setLoading(false)
        })
        .catch((err: any) => {
          console.log(err);
        });
      
    }
    else{
      setLoading(true)
    API.get(`/idea/combination/`, {
      subsystem: abbrevID,
      origin: origin,
    }, 0)
      .then((res: any) => {
        setCategoryData(res?.data);
        setLoading(false)
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
  }

  React.useEffect(() => {
    getCategoryData(categorySelected, abbrevID);
  }, []);

  React.useEffect(() => {
    getCategoryList();
  }, [subSystem]);

  const detailsRedirect = (id:any) => {
    history.push(`/idea-auto-generation-details/${projectId}/${projectName}/${topVault}/${subSystem}/${abbrevID}/${categorySelected}/${id}/0/0`)
  }

  const handleEditCombinationName = (row: any) => {
    setRowId(row?.id);
    setEditName(row?.name);
    editCombinationName.open();
  }

  const handleAddCombination = (e: any, item: any) => {
    e.stopPropagation();
    console.log(item);
    setRowId(item?.origin);
    setCategoryName(item?.name);
    addNewCombination.open();
  }
  React.useEffect(()=>{
    API.get(`/auth/calibration_user_permission/`,{action:true,idea_create:true,top_vault:topVault}).then((res:any)=>{
      SetPermissionAction(res.data)
    }).catch((err:any)=>{
      console.log("Server Error")
    })
  },[topVault])


  const columns: GridColDef[] = [

    {
      field: 'name',
      headerName: 'Combination Name',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'competitor',
      headerName: 'Competitor Vehicle',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'analysistype',
      headerName: 'Analysis Type',
      type: 'number',
      minWidth: width >=1370 ? 150 : 120,
      // flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box>{getIdeaCombinationTypeName(cellValues?.row?.analysis)}</Box>
        )
      }
    },
    {
      field: 'categories',
      headerName: 'Categories',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: width >=1370 ? 320 : 200,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ whiteSpace: "normal", width: "100%", minHeight: "auto" }}>{cellValues?.row?.categories?.join(', ')}</Box>
        )
      }
    },
    {
      field: 'parameters',
      headerName: 'Parameters',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: width >=1370 ? 320 : 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box sx={{ whiteSpace: "normal", width: "100%", height: "auto" }}>{cellValues?.row?.parameters?.map((item:any) => item?.name).join(', ')}</Box>
        )
      }
    },
    {
      field: 'filter',
      headerName: 'Idea Filter',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        console.log(cellValues);
        return (
          <Box>{getIdeaFilterName(cellValues?.row?.filter)}</Box>
        )
      }
    },
    {
      field: 'idea_count',
      headerName: 'Idea Count',
      description: 'Total Idea Count.',
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{padding:"0.4rem", boxSizing:"border-box"}}>
            <Typography>{cellValues?.row?.idea_count?.total_idea} ({cellValues?.row?.idea_count?.idea_promoted} Promoted) </Typography>
            

          </Box>
        )
      }
    },
    {
      field: 'created_by_name',
      headerName: 'Created by',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 80,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
            <img src={rightArrow} style={{ height: '1.5rem', cursor: 'pointer' }} onClick={() => detailsRedirect(cellValues?.row?.id)} />            
            {PermissionAction && PermissionAction?.action?.includes("D") &&
            <IconButton
              title='Delete Idea Combination'
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                padding: '0',
              }}              
              onClick={() => {
                swal({
                  title: `Delete Idea Combination`,
                  text: `Are you sure you want to Delete Idea Combination?`,
                  icon: 'error',
                  buttons: ['Cancel', 'Yes, Delete'],
                }).then((confirm) => {
                  if (confirm) {
                    API.delete(`/idea/combination/${cellValues?.row?.id}/`, {}, 0)
                      .then((res: any) => {
                        getCategoryData(categorySelected, abbrevID);
                        getCategoryList();
                      })
                      .catch((err: any) => {
                        console.log(err);
                      });
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
      }
      {
        PermissionAction && PermissionAction?.action?.includes("U") &&
      

            <IconButton
              title='Edit'
              sx={{ cursor: 'pointer', color: 'primary.main', padding: '0' }}
              onClick={() => handleEditCombinationName(cellValues?.row)}
            >
              <EditIcon />
            </IconButton>
      }
          </Box>
        );
      },
      
    },
  ];

  const handleCategoryClick = (e: any, item: any) => {
    e.stopPropagation();
    history.push(`/idea-auto-generation/${projectId}/${projectName}/${topVault}/${subSystem}/${abbrevID}/${item?.origin}/0/0`)
    setCategorySelected(item?.origin);
    getCategoryData(item?.origin, abbrevID);
  }



  let ideaDataGeneratedDatagrid: any = {
    columns: columns,
    rows: categoryData && categoryData,
  };


  return (
    <div>
      <Box sx={{ padding: "0 0.5rem", }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
              Auto Generate Ideas ({subSystem})
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: 'primary.light' }}>Combinations : {combinations}</Typography>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: '#ffee93' }}>Idea Generated : {ideaGenerated}</Typography>
            <Typography sx={{ fontSize: '1rem', padding: '0 0.5rem', fontWeight: '500', backgroundColor: '#adf7b6' }}>Idea Promoted : {ideaPromoted}</Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '0.5rem',
              width: '100%',
              borderBottom: '1px solid',
            }} adl-scrollbar='true'
            adl-scrollbar-width='0.2'>

            <>
              {
                categoryList && categoryList?.map((item: any, index: any) => {
                  return <Box
                    key={index}
                    sx={{
                      color: '#007fff',
                      // height: '3rem',
                      minHeight: '2.1rem',
                      maxWidth: '100% !important',
                      padding: '0',
                      fontSize: '1rem',
                      width: '100%',
                      backgroundColor: categorySelected === item?.origin ? 'primary.main' : 'primary.light',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      borderTopRightRadius: '0.5rem',
                      borderTopLeftRadius: '0.5rem',
                    }}
                    onClick={(e: any) => handleCategoryClick(e, item)}
                  >
                    <Typography
                      sx={{
                        color: categorySelected === item?.origin ? '#fff' : '#007fff',
                        // height: '3rem',
                        minHeight: '2.1rem',
                        maxWidth: '100% !important',
                        padding: '0 0.5rem',
                        fontSize: '1rem',
                        width: '100%',
                        // textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'capitalize',
                      }}>
                      <Typography sx={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', }} >{item?.name} 
                      
                      {
                        PermissionAction && PermissionAction?.action?.includes("C") &&
                      
                     <>{ categorySelected === item?.origin && <AddIcon titleAccess='Add' onClick={(e: any) => handleAddCombination(e, item)} sx={{cursor:'pointer'}} />}</> }
                      
                      
                      
                      </Typography>
                      <Typography sx={{ fontSize: '1rem', width: '100%', }}>( {item?.combinations} Combinations, {item?.total_idea} Ideas, {item?.promoted} Promoted)</Typography>
                    </Typography>
                  </Box>
                })}
            </>

          </Box>
          <Box
            sx={{
              height: { lg: '81vh', xl: '85vh' },
              width: '100%',
              '& .super-app-theme--cell': {
                backgroundColor: '#ddefff !important',
                textAlign: 'center !important',
              },
            }}>
            <DataGrid
              {...ideaDataGeneratedDatagrid}
              // rows={rows}
              // columns={columns}                
              // checkboxSelection
              loading={loading}
              headerHeight={42}
              getRowHeight={() => 'auto'}
              sx={{
                '& ::-webkit-scrollbar': {
                  width: '0.3rem',
                  height: '0.3rem',
                },
                '& ::-webkit-scrollbar-thumb': {
                  // background: $thumb-color;
                  backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
                  borderRadius: '10px',
                },
                '& ::- webkit-scrollbar-thumb: hover ': {
                  // width: "4px",
                  backgroundColor: '#045DE9 !important',
                },
                '::-webkit-scrollbar-track': {
                  backgroundColor: '#e1e1f3 !important',
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                  borderBottomColor: 'primary.light',
                },
              }}
            />

          </Box>
          {
            editCombinationName.isOpen && <EditCombinationNameModal
              isOpen={editCombinationName.isOpen}
              onCloseModal={editCombinationName.close}
              rowId={rowId}
              editName={editName}
              getCategoryData={getCategoryData}
              categorySelected={categorySelected}
            />
          }
          {
            addNewCombination.isOpen && <AddNewCombination
              isOpen={addNewCombination.isOpen}
              onCloseModal={addNewCombination.close}
              rowId={rowId}
              categoryName={categoryName}
              categorySelected={categorySelected}
              getCategoryData={getCategoryData}
              getCategoryList={getCategoryList}
            />
          }

        </Box>
      </Box>

    </div>
  );
}
