import { useEffect, useState } from 'react';
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from './EIRandWorkshopPage.module.scss';
import WorkShopPDFViewer from './WorkShopPDFViewer';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { WorkshopPresentationFileinfoModal } from './WorkshopPresentationFileinfoModal';
import EIRandWorkshopPageBodyRow from './EIRandWorkshopPageBodyRow';
import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { WorkshopPresentationModal } from './WorkshopPresentationModal';

interface Params{
  projectId:any;
  projectname:any;
}
export interface IEIRandWorkshopPageProps {
  setInfoEIRWorkshop:any;
}

export function EIRandWorkshopPage(props: IEIRandWorkshopPageProps) {
  const {setInfoEIRWorkshop} = props;
  const {projectId,projectname} = useRouteParams<Params>();
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [workshopTableData,setWorkshopTableData] = useState<any>();
  const [pdfFilePassToInfo,setPdfFilePassToInfo] = useState<any>();
  const [abbreviationSendUpload,setAbbreviationSendUpload] = useState<any>();
  const [workshopTableDataLoader,setWorkshopTableDataLoader] = useState<boolean>(false);
  const [apiCallWorkstationData,setApiCallWorkstationData] = useState<boolean>(false);

  const history = useHistory();
  const Workshoppresentupload = useBaseModal();
  const WorkshopFileInfo = useBaseModal();

  useEffect(()=>{
    setWorkshopTableDataLoader(false);
    API.get('workshop/banner_fields/',{banner_data:projectId},0).then((res:AxiosResponse)=>{
      setWorkshopTableData(res?.data?.data);
      setInfoEIRWorkshop({total:res?.data?.total,total_validated:res?.data?.total_validated})
      setWorkshopTableDataLoader(true);
    }).catch((err:AxiosError)=>{
      setWorkshopTableDataLoader(true);
    });
  },[projectId,apiCallWorkstationData])

  const WorkshopFileInfoHandler = (pdfFilesInfo:any) => {
    setPdfFilePassToInfo(pdfFilesInfo)
    WorkshopFileInfo.open();
  }

  const WorkshoppresentuploadHandler = (abbr:any) => {
    setAbbreviationSendUpload(abbr);
    Workshoppresentupload.open();
  }


  const ViewPdfRedirect = (abbr:any,indexFile:any) =>{
      history.push(`/eirandworkshop/${projectId}/${projectname}/${abbr}/${indexFile}/0`)
  }

  const skeletonMultipleTimeLoder = () =>{
    const skeletonArray:any = [];
    for(let i=0;i<=30;i++){
      skeletonArray.push(
        <TableRow>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
          <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}><Skeleton width={"100%"}/></TableCell>
        </TableRow>)
    }
    return skeletonArray;
  }

  return (
    <div className={styles.bannerContainer}>
      {Workshoppresentupload.isOpen &&
        <WorkshopPresentationModal
          onCloseModal={Workshoppresentupload.close}
          isOpen={Workshoppresentupload.open} 
          type={'bannerPresentation'}
          apiCallWorkstationData={apiCallWorkstationData}
          setApiCallWorkstationData={setApiCallWorkstationData}
          abbreviationSendUpload={abbreviationSendUpload}/>
      }
      {WorkshopFileInfo.isOpen &&
        <WorkshopPresentationFileinfoModal
          onCloseModal={WorkshopFileInfo.close}
          isOpen={WorkshopFileInfo.open} 
          pdfFilePassToInfo={pdfFilePassToInfo}
          apiCallWorkstationData={apiCallWorkstationData}
          setApiCallWorkstationData={setApiCallWorkstationData}
          />}

      <Box className={styles.right_container}>
        <Box
          sx={
            isExpand == true
              ? {
                display: "grid",
                gridTemplateColumns: "1fr",
                height: { lg: "92%", xl: "100%" },
              }
              : {
                display: "grid",
                gridTemplateColumns: {
                  sm: "1fr 1fr",
                  md: "2fr 1fr",
                  lg: "2fr 0.7fr",
                  xl: "2fr 0.7fr",
                },
                height: { lg: "92%", xl: "100%" },
              }
          }
        >
          <Box
            className={
              isExpand == true ? styles.removeaccord : styles.mid_container
            }
          >
            <Box
              className={styles.body_container}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Table>
                <TableHead sx={{ backgroundColor: 'primary.light' }}>
                  <TableRow>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>SNo </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>System Name </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>System Code </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>EIR Count </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>View  </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>File Size </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>Status </TableCell>
                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>Actions </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {workshopTableDataLoader ? 
                  workshopTableData && workshopTableData?.map((item:any,index:number)=>{
                   return <EIRandWorkshopPageBodyRow WorkshopFileInfoHandler={WorkshopFileInfoHandler} lineItem={item} indexIncoming={index+1} ViewPdfRedirect={ViewPdfRedirect} WorkshoppresentuploadHandler={WorkshoppresentuploadHandler}/>
                })
                : <>
                  {skeletonMultipleTimeLoder()}
                </>}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box>
            {workshopTableDataLoader ? <WorkShopPDFViewer setIsExpand={setIsExpand} isExpand={isExpand} apiCallWorkstationData={apiCallWorkstationData} setApiCallWorkstationData={setApiCallWorkstationData} /> :
            <div style={{ height: "100vh", padding: "0 1rem" }}>
            <Skeleton variant="rectangular" width="100%" height="92%"
              sx={{ padding:'0 1rem', margin: "0 auto",}}
            />
          </div>}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
