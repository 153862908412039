import * as React from 'react';
import { API } from '../../../api-services';
import Table from '@mui/material/Table';

import SettingsIcon from "@mui/icons-material/Settings";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Skeleton, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import calcIcon from '../../../Assets/images/calculator.svg';
import IdeaCreateCostCalculatorModal from './IdeaCreateCostCalculatorModal';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { useParams } from 'react-router-dom';
import { ICalculator } from '../../../Redux/Reducers/calculator.reducer';
import calculatorService from '../../../Redux/Services/calculator.service';
import useFetch from '../../../Core/CustomHooks/useFetch';
import IdeaCalculatorValueInputModal from './IdeaCalculatorValueInputModal';
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import { UserAction } from './IdeaGenerationViewLanding';
import { rejectedStatus } from './IdeaViewpage';
export interface IdeaViewCostModalComponentsProps {
   
}

export function IdeaViewCostModalComponents(props: IdeaViewCostModalComponentsProps) {
    const { projectId, projectName, topVault, subSystem, subSystemId, expandTable, abbr, partId, depth, vault_id } = useParams<any>()
    const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
    const [CostTableData, setCostTableData] = React.useState<any>([])
    const [Counter, setCounter] = React.useState<any>(0)
    const [RowId, setRowId] = React.useState<any>()
    const [Loader, setLoader] = React.useState<any>(false)


    const [count, setCount] = React.useState<number>(0);
    const [CalculatorItem, setCalculatorItem] = React.useState<any>()
    const useraction=React.useContext<any>(UserAction)
    const [calculatorName, setCalculatorName] = React.useState<
        string | undefined
    >(undefined);
    const costModal = useBaseModal()
    const inputModal = useBaseModal()
    const [CounterTest, setCounterTest] = React.useState<any>(0)
    const [activeCopyId, setActiveCopyId] = React.useState<any>();
    React.useEffect(() => {

        if (vault_id != 0) {
            setLoader(true)
            setCostTableData([])
            API.get(`/idea/idea_cost_model/`, { idea_id: vault_id }, 0).then((res: any) => {

                setCostTableData(res.data)
                setLoader(false)

            }).catch((err: any) => {
                setLoader(false)
            })
        }
    }, [vault_id, Counter])

    const SettingHandler = (id: any) => {

        setRowId(id)
        costModal.open()
    }



    const calcultorRoute = (id: any) => {
        API.get(`/idea/idea_cost_calculation/`, { idea_id: vault_id, config_id: id?.id, calculation: true }, 0).then((res: any) => {
            setCalculatorName(res?.data?.calculator)
            setCounterTest((prev: any) => prev + 1)
            setCalculatorItem(id)

        })


        inputModal.open()
    }


    const { 0: gettingCalvalue, 1: calculator } = useFetch<ICalculator>({
        method: calculatorService.getByIdeadCalcultorIds,
        args: [calculatorName],
        dependencies: [calculatorName, CounterTest],
        condition: calculatorName !== undefined,
    })

        
    const handleCopyClick = async (alias: any,idd:any) => {
  
         setActiveCopyId(idd);
        try {
            await navigator.clipboard.writeText(`${alias}`);

            //   setIsCopied(true);
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    return (
        <div>
            {
                costModal.isOpen &&

                <IdeaCreateCostCalculatorModal

                    onCloseModal={costModal.close}
                    isOpen={costModal.isOpen}
                    Item={RowId}
                    setCounter={setCounter}
                />
            }
            {inputModal.isOpen &&
                <IdeaCalculatorValueInputModal
                    isOpen={inputModal.isOpen}
                    onClose={inputModal.close}
                    fetchCalculator={gettingCalvalue}
                    CalculatorItem={CalculatorItem}
                    calculatorData={calculator}
                    setCount={setCount}
                    setCounter={setCounter}
                    count={count}
                    IdeaType={"Create"}
                />
            }
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light' }}>Name</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light' }}> Action</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light' }}> Result</TableCell>
                </TableHead>
                <TableBody>
                    {
                        Loader ? <>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                            </TableRow> */}
                        </> :
                            <>
                                {
                                    CostTableData && CostTableData?.map((item: any, index: any) => {
                                        return (<>

                                            <TableRow>
                                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}> {item?.name}</TableCell>
                                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                                                        <SettingsIcon
                                                            titleAccess="Setting"
                                                            sx={{ cursor: !rejectedStatusActionParams?"pointer":"not-allowed", color: item?.calculator_status ? "green" : "#007fff" }}
                                                            onClick={() => {
                                                                if(!rejectedStatusActionParams)
                                                                SettingHandler(item)
                                                            }}
                                                        />
                                                        {
                                                            item?.result!=0? <img src={calcIcon}
                                                            title="Calculator"
                                                            style={{ cursor: item?.calculator_status ? "pointer" : "not-allowed", height: '1.4rem', opacity: item?.calculator_status == false ? "0.3" : "",color:"green" ,
                                                        
                                                            filter:  "hue-rotate(-100deg)",
                                                        }}
                                                            onClick={() => item?.calculator_status && calcultorRoute(item)}
                                                        />: <img src={calcIcon}
                                                        title="Calculator"
                                                        style={{ cursor: item?.calculator_status ? "pointer" : "not-allowed", height: '1.4rem', opacity: item?.calculator_status == false ? "0.3" : "",color:"green" ,
                                                    
                                                       
                                                    }}
                                                        onClick={() => item?.calculator_status && calcultorRoute(item)}
                                                    />
                                                        }
                                                       
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                                                        <CurrencyRupeeIcon titleAccess="rupee" sx={{ fontSize: '1.5rem' }} />
                                                        {item?.result}
                                                        {
                                                            item?.result!=0 &&
                                                        
                                                        <FileCopyRoundedIcon titleAccess='Copy Result'  sx={{cursor:"pointer",color:activeCopyId==item?.id?"green":"#007fff"}} onClick={() =>
                                                            handleCopyClick(item?.result,item?.id)
                                                        } />
                                                    }
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </>)
                                    })
                                }
                            </>
                    }

                </TableBody>
            </Table>
        </div>
    );
}
