import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Divider, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { FeatureCreateImageUploadModal } from "./FeatureCreateImageUploadModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AddFeatureModal from "./AddFeatureModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { FeatureMobileImageCaptureModal } from "./FeatureMobileImageCaptureModal";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "86vw", sm: "30vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  name: yup.string().required("Please enter group name"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IAddGroupModalProps {
  isOpen: any;
  onCloseModal: any;
  getSystemData: any;
  abbreviation: any;
  FeatureInfo:any;
  getModule: any;
}

function AddFeatureGroupModal(props: IAddGroupModalProps) {
  const { isOpen, onCloseModal, getSystemData, abbreviation, FeatureInfo , getModule} = props;
  const { projectId, parent, type } = useRouteParams<any>();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const classes = useStyles();
  const FeatureCreateUploadModal = useBaseParamsModal();
  const AddFeature = useBaseModal();
  const [FeatureDetails,setFeatureDetails]=React.useState<any>({})
  React.useEffect(() => {
    formik.setFieldValue("abbreviation", abbreviation);
  }, [abbreviation]);
  const mobileImageCapture = useBaseParamsModal();

  const { height, width } = useWindowDimensions();
  // console.log(FeatureDetails,"FeatureDetails", )

  const formik = useFormik({
    initialValues: {
      name: "",
      project: projectId,
      abbreviation: abbreviation,
      type: parseInt(type) - 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);

      API.post("/xcpep/feature_groups/", values, {}, 0)
        .then((res1: any) => {
          setIsLoadingSave(false);
          getSystemData(parseInt(type) - 1);
         
          // onCloseModal();
          API.post(
            "/xcpep/product_feature/",
            {
              project: projectId,
              group: res1.data?.group,
              abbreviation: res1.data?.abbreviation,
              type: parseInt(type) - 1,
            },
            {},
            0
          )
            .then((res: any) => {
             
              FeatureCreateUploadModal.open(res.data);
              setFeatureDetails({
                abbreviation:res1.data?.abbreviation,
                vehicle:res1.data?.vehicle_type,
                group:res1.data?.group
              })
              // onCloseModal()
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
              });
            });
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
            <br />
            <p style="color:"red">${data[0]}</p>   
             </div>`,
          });
          setIsLoadingSave(false);
          onCloseModal();
        });
    },
  });

  const mobileImageHandler = () => {
    API.post("/xcpep/feature_groups/", { abbreviation: formik.values.abbreviation, name: formik.values.name, project: projectId, type: parseInt(type) - 1 }, {}, 0)
      .then((res: any) => {
        setIsLoadingSave(false);
        getSystemData(parseInt(type) - 1);
        // onCloseModal();
        API.post(
          "/xcpep/product_feature/",
          {
            project: projectId,
            group: res.data?.group,
            abbreviation: res.data?.abbreviation,
            type: parseInt(type) - 1,
          },
          {},
          0
        )
          .then((res: any) => {
            mobileImageCapture.open(res.data);
            // onCloseModal()
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
            });
          });
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
        <br />
        <p style="color:"red">${data[0]}</p>   
         </div>`,
        });
        setIsLoadingSave(false);
        onCloseModal();
      });
  };

  return (
    <div>
      {FeatureCreateUploadModal.isOpen && FeatureCreateUploadModal.isOpen && (
        <FeatureCreateImageUploadModal
          onCloseModal={FeatureCreateUploadModal.close}
          isOpen={FeatureCreateUploadModal.isOpen}
          response={FeatureCreateUploadModal.propsId}
          closeParent={onCloseModal}
          addFeatureAddData={FeatureDetails}
          getModule={getModule}
        />
      )}
      {AddFeature.isOpen && (
        <AddFeatureModal
          onCloseModal={AddFeature.close}
          isOpen={AddFeature.open}
          getSystemData={getSystemData} 
          getModule={getModule}         
        />
      )}

      {mobileImageCapture.isOpen && (
        <FeatureMobileImageCaptureModal
          onCloseModal={mobileImageCapture.close}
          isOpen={mobileImageCapture.isOpen}
          response={mobileImageCapture.propsId}
          closeParent={onCloseModal}
          getModule={getModule}
        />
      )}
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Add {getModule} group
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Group name <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    multiline
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </FormControl>
              </Box>

              <Box sx={{ width: "100%", textAlign: "right" }}>
                {/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
                {width > 600 ? (
                  <LoadingButton
                    size="small"
                    loading={isLoadingsave}
                    variant="contained"
                    type="submit"
                    sx={{ marginTop: "1rem" }}>
                    Add & Proceed to add {getModule}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    size="small"
                    loading={isLoadingsave}
                    variant="contained"
                    onClick={() => mobileImageHandler()}
                    sx={{ marginTop: "1rem" }}>
                    Add & Proceed to add {getModule}
                  </LoadingButton>
                )}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddFeatureGroupModal;
