// import { Box, Modal, Typography } from '@mui/material'
// import React, { useEffect, useRef, useState } from 'react'
// import CancelIcon from "@mui/icons-material/Cancel";
// import ImageEditor from "@toast-ui/react-image-editor";
// import "tui-image-editor/dist/tui-image-editor.css";
// import "tui-color-picker/dist/tui-color-picker.css";
// import { LoadingButton } from '@mui/lab';

// const style = {
//     position: "absolute" as "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "69vw",
//     backgroundColor: "#fff",
//     borderRadius: "10px",
//     p: 1,

//   };

// function PCBImageEditor({isOpen, onCloseModal, image, setImage, color, setColor}:any) {
//     const [editorInitialized, setEditorInitialized] = useState(false); // State to track initialization
// const imageEditor = useRef<any>(null);
// const [loader, setLoader] = useState<any>(false)

// const handleUpdateImage = () => {
//     setLoader(true)
//     const updatedImage = imageEditor?.current?.imageEditorInst;

//     const updatedData = updatedImage.toDataURL().split(",")[1];
//     const byteCharacters = atob(updatedData);
//     const byteNumbers = new Array(byteCharacters.length);
//     for (let i = 0; i < byteCharacters.length; i++) {
//       byteNumbers[i] = byteCharacters.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     const file = new Blob([byteArray], {
//       type: "image/png",
//     });

//     const updatedFile = new File([file], "EditedImage.png", { type: "image/png" });

//     setImage(updatedFile);
//     onCloseModal();

//     setLoader(false);
//     // setImage(file);
//     // closeModal();
//   };

// //   useEffect(() => {
// //     const editorInstance = imageEditor.current?.imageEditorInst;

// //     if (editorInstance) {
// //       console.log(imageEditor);

// //       // Add event listener for when a shape is added
// //       editorInstance.on('objectAdded', (event: any) => {
// //         console.log('hiiiiiii');

// //         if (event?.type === 'rect' || event?.type === 'circle') {
// //           const shapeFillColor = event?.fill || event?.stroke;
// //           if (shapeFillColor) {
// //             setColor(shapeFillColor);
// //           }
// //         }
// //       });

// //       // Example of how to use the image editor methods
// //       editorInstance.addObject({
// //         type: 'rect',
// //         left: 100,
// //         top: 100,
// //         width: 200,
// //         height: 100,
// //         fill: '#0390fc',
// //         stroke: '#0390fc',
// //         strokeWidth: 2,
// //       });

// //       editorInstance.addText('Sample Text', {
// //         left: 100,
// //         top: 200,
// //         fontSize: 24,
// //         fontFamily: 'Arial',
// //         fill: '#000000',
// //       });

// //       // Example of iconCreateEnd event
// //       editorInstance.on('iconCreateEnd', (event: any) => {
// //         console.log('Icon created', event);
// //       });
// //     }

// //     // Cleanup event listener on unmount
// //     return () => {
// //       const editorInstance = imageEditor.current?.imageEditorInst;
// //       editorInstance?.off('objectAdded');
// //     };
// //   }, [imageEditor]);

// useEffect(() => {

//     // Check if the ref is assigned
//     if (imageEditor.current) {
//       const editorInstance = imageEditor.current?.imageEditorInst;

//       // Now that the editorInstance is available, you can access its methods
//       console.log("ImageEditor instance:", editorInstance);

//       // Add event listener for when a shape is added
//       editorInstance.on('objectAdded', (event: any) => {
//         console.log('Shape added:', event);
//       });

//       // Example of using the image editor methods
//       editorInstance.addObject({
//         type: 'rect',
//         left: 100,
//         top: 100,
//         width: 200,
//         height: 100,
//         fill: '#0390fc',
//         stroke: '#0390fc',
//         strokeWidth: 2,
//       });

//       // Example of iconCreateEnd event
//       editorInstance.on('iconCreateEnd', (event: any) => {
//         console.log('Icon created', event);
//       });

//       // Cleanup event listener on unmount
//       return () => {
//         editorInstance?.off('objectAdded');
//         editorInstance?.off('iconCreateEnd');
//       };
//     }
//   }, [imageEditor]); // Dependency on imageEditor will ensure this runs after the first render

//   return (
//     <Modal
//     disableEnforceFocus
//     open={isOpen}
//     onClose={onCloseModal}
//     aria-labelledby="modal-modal-title"
//     aria-describedby="modal-modal-description">
//     <div style={style}>

//     <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
//             <Typography
//               style={{
//                 fontSize: "1rem",
//                 fontWeight: "600",
//                 width: "100%",
//                 textAlign: "center",
//                 marginRight: "-2rem",
//               }}>
//               Edit PCB Image
//             </Typography>
//             <CancelIcon
//               titleAccess="Close"
//               sx={{ cursor: "pointer", margin: "0.5rem" }}
//               onClick={onCloseModal}
//             />
//           </Box>

//           <ImageEditor
//                 includeUI={{
//                     loadImage: {
//                         path: image?.name ? URL.createObjectURL(image) : image,
//                         name: "EditedImage",
//                     },
//                     menu: [
//                         "shape",
//                         // "filter",
//                         "text",
//                         // "mask",
//                         // "icon",
//                         // "draw",
//                         // "crop",
//                         // "flip",
//                         // "rotate",
//                         // "resize",
//                     ],
//                     uiSize: {
//                         width: "100%",
//                         height: "73vh",
//                     },
//                     menuBarPosition: "left",
//                     initMenu: "shape", // Open shape menu by default
//                     shape: {
//                         // Enable only rectangles
//                         rect: false,
//                         // {
//                         //   fill: "#0390fc", // Default fill color for the rectangle
//                         //   stroke: "#0390fc", // Default stroke color for the rectangle
//                         //   strokeWidth: 2,
//                         // },
//                         triangle: false, // Disable triangle option
//                         circle: false,   // Disable circle option
//                         ellipsis: false, // Disable ellipsis (another term for oval/circle)
//                       },
//                 }}
//                 cssMaxHeight={500}
//                 cssMaxWidth={1000}
//                 selectionStyle={{
//                     cornerSize: 20,
//                     rotatingPointOffset: 70,
//                 }}
//                 usageStatistics={true}
//                 ref={imageEditor}

//             />

// <Box
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               marginTop: "1rem",
//             }}>

//             <LoadingButton
//               size="small"
//               sx={{
//                 padding: "0.5rem",
//                 lineHeight: 1,
//                 "&:hover": {
//                   transform: 'Scale(1.05)',
//                   transition: 'transform 0.5s ease',
//                 },
//                 '.MuiCircularProgress-root': {
//                   color: 'primary.main',
//                 },
//               }}
//               variant="contained"
//               onClick={handleUpdateImage}
//               loading={loader}>
//               Save
//             </LoadingButton>
//           </Box>

//     </div>
//     </Modal>
//   )
// }

// export default PCBImageEditor

import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import { ADMIN } from "../../../Redux/Services/admin.service";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "69vw",
  backgroundColor: "#fff",
  borderRadius: "10px",
  p: 2,
};

function PCBImageEditor({
  isOpen,
  onCloseModal,
  image,
  setImage,
  color,
  setColor,
  length,
  setLength,
  width,
  setWidth,
  vault,
  type,
  setPresignedImage
}: any) {
  const imageEditor = useRef<any>(null);
  const [loader, setLoader] = useState<any>(false);
  const [position, setPosition] = useState<any>(null);
  const [canvas, setCanvas] = useState<any>(null);
  const [rgb, setRgb] = useState<any>(null);

  const handleUpdateImage = () => {
    setLoader(true);
    const updatedImage = imageEditor?.current?.imageEditorInst;

    const updatedData = updatedImage.toDataURL().split(",")[1];
    const byteCharacters = atob(updatedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "image/png" });
    const updatedFile = new File([file], "EditedImage.png", {
      type: "image/png",
    });

    console.log(color, rgb);

    const formData = new FormData();
    
    if (type == "top") {
      formData.append("top_reference_image", updatedFile); // Append the file
      formData.append("top_ref_length", length);
      formData.append("top_ref_width", width);
      formData.append("top_rgb_value", rgb);
    } else {
      formData.append("bottom_reference_image", updatedFile); // Append the file
      formData.append("bottom_ref_length", length);
      formData.append("bottom_ref_width", width);
      formData.append("bottom_rgb_value", rgb);
    }

    API.put(`pcb/pcb_info_for_vault/${vault}/`, formData, {}, 0)
      .then((res) => {
        if (type == "top") {
          if (res.data?.top_reference_image) {
            setImage(res.data?.top_reference_image);
            
            onCloseModal();
          } else {
            ADMIN.toast.error("Overlay addition failed");
          }
        } else {
          if (res.data?.bottom_reference_image) {
            setImage(res.data?.bottom_reference_image);
            
            onCloseModal();
          } else {
            ADMIN.toast.error("Reference image creation failed");
          }
        }
      })
      
      .catch((err: any) => {
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
        });
      })
      .finally(() => {
        setLoader(false);
      });

    // setImage(updatedFile);
    // onCloseModal();
    // setLoader(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imageEditor.current) {
        // console.log("ImageEditor ref (using interval):", imageEditor);
        // console.log("ImageEditor current (using interval):", imageEditor.current);

        const editorInstance = imageEditor.current?.imageEditorInst;

        if (editorInstance) {
          console.log("ImageEditor instance:", editorInstance);
          const canvas = editorInstance._graphics?._canvas;
          setCanvas({
            height: canvas?.height,
            width: canvas?.width,
            maxWidth: editorInstance._graphics?.cssMaxWidth,
            maxHeight: editorInstance._graphics?.cssMaxHeight,
          });

          // Add event listener for when a shape is added
          editorInstance.on("objectAdded", (event: any) => {
            console.log("Shape added:", event);

            if (event?.type === "rect" || event?.type === "circle") {
              const shapeFillColor = event?.fill || event?.stroke;
              if (shapeFillColor) {
                setPosition({
                  x: event?.left,
                  y: event?.top,
                  height: event?.height,
                  width: event?.width,
                });
                setColor(shapeFillColor?.color);
              }
            }
          });

          // Add event listener for when a shape is modified
          editorInstance.on("objectModified", (event: any) => {
            console.log("Shape modified:", event);

            if (event?.type === "rect" || event?.type === "circle") {
              const updatedFillColor = event?.fill || event?.stroke;
              if (updatedFillColor) {
                setColor(updatedFillColor);
              }
            }
          });

          editorInstance.on("objectMoved", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });

          editorInstance.on("objectRotated", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });

          // editorInstance.on("undoStackChanged", (event: any) => {
          //   console.log(event, editorInstance?._graphics?._objects);
          //   const transformData = (data: any) => {
          //     return Object.values(data)?.map((innerObj: any) => ({
          //       id: innerObj?.__fe_id,
          //       text: innerObj?.text,
          //       left: innerObj?.left,
          //       top: innerObj?.top,
          //     }));
          //   };
          //   const transformedArray = transformData(
          //     editorInstance?._graphics?._objects
          //   );

          //   const hasRectangle = transformedArray?.some(
          //     (item:any) => !item.hasOwnProperty("type") && !item.hasOwnProperty("text")
          //   );

          //   console.log(hasRectangle);

          // });

          editorInstance.on("objectScaled", (event: any) => {
            const shapeFillColor = event?.fill || event?.stroke;
            if (shapeFillColor) {
              setPosition({
                x: event?.left,
                y: event?.top,
                height: event?.height,
                width: event?.width,
              });
              setColor(shapeFillColor?.color);
            }
          });

          // Cleanup event listeners
          const cleanupListeners = () => {
            editorInstance?.off("objectAdded");
            editorInstance?.off("objectModified");
            editorInstance?.off("objectMoved");
            editorInstance?.off("objectRotated");
            editorInstance?.off("undo");
            editorInstance?.off("objectScaled");
          };

          // Cleanup interval and attach cleanup listeners
          clearInterval(intervalId);
          return cleanupListeners;
        }
      }
    }, 1000); // Poll every 1000ms

    return () => clearInterval(intervalId); // Ensure cleanup on unmount
  }, [imageEditor]);

  useEffect(() => {
    if (imageEditor.current) {
      const editorInstnce = imageEditor.current?.imageEditorInst;
      if (editorInstnce && length && position) {
        const transformData = (data: any) => {
          return Object.values(data)?.map((innerObj: any) => ({
            id: innerObj?.__fe_id,
            text: innerObj?.text,
            left: innerObj?.left,
            top: innerObj?.top,
          }));
        };
        const transformedArray = transformData(
          editorInstnce?._graphics?._objects
        );

        const existsItem = transformedArray?.find((item: any) =>
          item?.text?.includes("Length")
        );

        if (length) {
          // Ensure the addText method exists before calling it
          if (editorInstnce.addText) {
            if (!existsItem) {
              editorInstnce.addText(`Length: ${length} mm`, {
                styles: {
                  fill: "#FFD65A",
                  fontSize: 30,
                  textAlign: "center",
                  fontWeight: "bold",
                },

                position: {
                  x: position?.x - position?.width / 4 + 10,
                  y: position?.y - position?.height / 2 - 50,
                },
              });
            } else {
              editorInstnce.changeText(existsItem?.id, `Length: ${length}`);
            }
          }
        }
      }
    }
  }, [length, imageEditor]);

  useEffect(() => {
    if (imageEditor.current) {
      const editorInstnce = imageEditor.current?.imageEditorInst;
      if (editorInstnce && width && position) {
        const transformData = (data: any) => {
          return Object.values(data)?.map((innerObj: any) => ({
            id: innerObj?.__fe_id,
            text: innerObj?.text,
            left: innerObj?.left,
            top: innerObj?.top,
          }));
        };
        const transformedArray = transformData(
          editorInstnce?._graphics?._objects
        );
        console.log(transformedArray, "objects");

        const existsItem = transformedArray?.find((item: any) =>
          item?.text?.includes("Width")
        );

        if (width) {
          // Ensure the addText method exists before calling it
          if (editorInstnce.addText) {
            if (!existsItem) {
              editorInstnce.addText(`Width: ${width} mm`, {
                styles: {
                  fill: "#FFD65A",
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bold",
                },
                position: {
                  x: position?.x + position?.width / 2 + 10,
                  y: position?.y,
                },
              });
            } else {
              editorInstnce.changeText(existsItem?.id, `Width: ${width} mm`);
            }
          }
        }
      }
    }
  }, [width, imageEditor]);


  const hexToRgb = (hex: string): string | null => {
    // Remove the hash at the start if it's there
    const cleanHex = hex?.replace(/^#/, '');
  
    // Check if the hex value is valid (3 or 6 characters)
    if (cleanHex?.length !== 3 && cleanHex?.length !== 6) {
      return null; // Invalid hex color
    }
  
    // Expand shorthand form (e.g., "03F") to full form (e.g., "0033FF")
    const fullHex =
      cleanHex?.length === 3
        ? cleanHex
            ?.split('')
            ?.map(char => char + char)
            ?.join('')
        : cleanHex;
  
    // Convert to RGB values
    const r = parseInt(fullHex?.substring(0, 2), 16);
    const g = parseInt(fullHex?.substring(2, 4), 16);
    const b = parseInt(fullHex?.substring(4, 6), 16);
  
    return `${r},${g},${b}`;
  };

  useEffect(()=> {
if (color) {
  const rgbColor = hexToRgb(color);
  setRgb(rgbColor)

}
  },[color])



  

  return (
    <Modal
      disableEnforceFocus
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Edit PCB Image
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>

        <ImageEditor
          includeUI={{
            loadImage: {
              path: image?.name ? URL.createObjectURL(image) : image,
              name: "EditedImage",
            },
            menu: ["shape", "text", ],
            uiSize: {
              width: "100%",
              height: "73vh",
            },
            menuBarPosition: "left",
            initMenu: "shape", // Open shape menu by default
            shape: {
              // Default shape configurations
              fill: "blue", // Set default fill color to blue
              stroke: "black", // Optional: Set default stroke color
              strokeWidth: 2, // Optional: Set default stroke width
              rect: false,
              triangle: false,
              circle: false,
              ellipsis: false,
            },
          }}
          cssMaxHeight={500}
          cssMaxWidth={1000}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
          ref={imageEditor}
        />

        <Box sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <Grid item xs={6}>
              <TextField
                label="Length (Along screen width)"
                disabled={!position}
                type="number"
                fullWidth
                value={length || ""}
                onChange={(e) => {
                  setLength(e.target.value);
                }}
                variant="standard"
                InputLabelProps={{
                  sx: {
                    color: "primary.main", // Always apply this color
                    "&.Mui-focused": {
                      color: "primary.main", // Keep the color during active/focused state
                    },
                    "&:hover": {
                      color: "primary.main", // Apply the color on hover
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Width (Along screen height)"
                type="number"
                fullWidth
                disabled={!position}
                value={width || ""}
                onChange={(e) => {
                  setWidth(e.target.value);
                }}
                variant="standard"
                InputLabelProps={{
                  sx: {
                    color: "primary.main", // Always apply this color
                    "&.Mui-focused": {
                      color: "primary.main", // Keep the color during active/focused state
                    },
                    "&:hover": {
                      color: "primary.main", // Apply the color on hover
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1rem",
          }}
        >
          <LoadingButton
            size="small"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            variant="contained"
            onClick={handleUpdateImage}
            loading={loader}
          >
            Save
          </LoadingButton>
        </Box>
      </div>
    </Modal>
  );
}

export default PCBImageEditor;
