import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IFetchfromBOMModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData: any;
  getTableData: any;
}

export function FetchfromBOMModal(props: IFetchfromBOMModalProps) {
  const { onCloseModal, isOpen, costDetailsData, getTableData } = props;
  const { vault } = useRouteParams<any>();
  const [selectedBOMParams, setSelectedBOMParams] = React.useState<any>([]);
  const [BOMCategoryData, setBOMCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>("");
  const [BOMParamsData, setBOMParamsData] = React.useState<any>([]);
  const [selectedParams, setSelectedParams] = React.useState<any>([]);
  const [fetchLoading, setFetchLoading] = React.useState<any>(false);
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
  ];

  const handleSelectChangBOMParams = (event: any, newValue: any) => {
    setSelectedBOMParams(newValue);
  };

  const handleChangeCategory = (event: any) => {
    const { value } = event.target;
    setSelectedCategory(value);
    getBOMParamsData(value);
  };

  const getBOMCategoryData = () => {
    API.get(
      "/xcpep/fetch_table_parameter/",
      {
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setBOMCategoryData(res?.data);
      })
      .catch((err: any) => { });
  };

  const getBOMParamsData = (value: any) => {
    API.get(
      "/xcpep/table_column_name/",
      {
        vault: vault,
        parameter: value,
      },
      0
    )
      .then((res: any) => {
        setBOMParamsData(res?.data);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getBOMCategoryData();
  }, []);
  const getOptionDisabled = (option: any) => {
    if (selectedBOMParams.length < 3) {
      return selectedBOMParams.some(
        (selectedOption: any) => selectedOption.name === option.name
      );
    } else {
      return selectedBOMParams.some((selectedOption: any) => option.name);
    }
  };

  return (
    <div>
      <Modal
        disableEscapeKeyDown={true}
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Fetch from BOM Table
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 0.5rem" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Select Table </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedCategory}
                onChange={handleChangeCategory}
                label="Select Table"
                placeholder="Select Table"
                // displayEmpty
                // renderValue={(selectedCategory) => {
                // if (!selectedCategory) {
                //   return <Typography sx={{ fontSize: '1rem', opacity: '0.5', }}>Select Table</Typography>;
                // } else {
                //   return selectedCategory;
                // }
                // }}
                sx={{
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: '1rem'
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                }}
              >
                {BOMCategoryData?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={`${item?.id}`}>
                      {`${item?.name}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '0.5rem' }}>
              <Autocomplete
                multiple
                id="tags-standard"
                limitTags={1}
                disableClearable
                disableCloseOnSelect
                sx={{
                  ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                  {
                    color: "primary.main",
                  },
                  ".MuiButtonBase-root.MuiChip-root": {
                    backgroundColor: "primary.light",
                    height: "26px",
                  },
                }}
                options={BOMParamsData && BOMParamsData}
                getOptionLabel={(option: any) => option?.name}
                value={selectedBOMParams}
                onChange={(event: any, newSystemValue: any) => {
                  handleSelectChangBOMParams(event, newSystemValue);
                }}
                // getOptionDisabled={getOptionDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Parameters"
                    placeholder="Select Parameters"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </FormControl>
            <Box
              sx={{ width: "100%", textAlign: "right", marginTop: "0.5rem" }}
            >
              <LoadingButton loading={fetchLoading} disabled={selectedCategory !== "" && selectedBOMParams?.length > 0 ? false : true} onClick={() => {
                setFetchLoading(true);

                API.post("/xcpep/create_cost_material_table/", {
                  cost_estimation: costDetailsData?.cost_estimation,
                  table_id: parseInt(selectedCategory),
                  parameters: selectedBOMParams?.map((item: any) => { return item?.name })
                }, {}, 0).then((res: any) => {
                  getTableData();
                  setFetchLoading(false);
                  onCloseModal();
                }).catch((err: any) => {
                  setFetchLoading(false);
                })
              }} variant="contained" size="small">
                Fetch
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
